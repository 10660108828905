import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import cookie from 'isomorphic-cookie'
import { filter, find, map } from 'lodash'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import { userAddressesQuery } from '~/gql/queries'
import { useModalManager, useUser, useVendor } from '~/hooks'
import useFieldValue from '~/hooks/use-field-value'
import Anchor from '~/truck/anchor'
import Label from '~/truck/label'
import theme from '~/truck/theme'
import { cookieNames, shippingTypes as shippingTypeSlugs } from '~/utils/constants'
import { stack } from '~/variables'

import Map from '../address-form/map'
import NoAddressFound from '../address-form/no-address-found'
import PlacesAutocomplete from '../address-form/places-autocomplete'
import Loader from '../loader'

import AddressSuggestion from './address-suggestion'
import Addresses from './addresses'
import CurrentLocation from './current-location'
import { StyledButton, StyledTitle } from './elements'

const GoogleMapsProvider = dynamic(() => import('~/providers/google-maps-provider'), {
  loading: () => <Loader centered />,
  ssr: false,
})

const MapContainer = styled.div`
  margin-bottom: ${theme.spacing.cozy * 2.5}px;
  min-height: unset;

  ${up('lg')} {
    overflow-y: unset;
  }
`

const StyledMap = styled(Map)`
  margin-top: ${theme.spacing.comfortable}px;
`

const Suggestions = styled.div`
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  max-height: ${theme.spacing.cozy * 30}px;
  overflow-y: auto;

  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.colors.gray};
  }
`

const FloatingContainer = styled.div`
  position: relative;
  z-index: ${stack.addressSuggestions};

  > div {
    flex-grow: 1;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
`

const StyledLabel = styled(Label)`
  cursor: pointer;
  user-select: none;
`

export const DeliveryButton = props => {
  const { formProps } = props

  const coordinates = useFieldValue('coordinates')

  return (
    <StyledButton
      loading={formProps.submitting}
      onClick={formProps.handleSubmit}
      disabled={!coordinates}
      color="secondary"
    >
      Continuar
    </StyledButton>
  )
}

const DeliveryContent = props => {
  const { formProps } = props

  const [showSuggestions, setShowSuggestions] = useState(false)

  const { data, loading } = useQuery(userAddressesQuery)

  const { shippingTypes } = useVendor()

  let userAddresses = data?.user?.addresses ?? []

  const shippingTypeId = useFieldValue('shippingTypeId')

  const selectedShippingType = find(shippingTypes, { id: shippingTypeId })

  const isExpressDelivery = selectedShippingType?.slug === shippingTypeSlugs.EXPRESS_DELIVERY

  userAddresses = filter(userAddresses, address => {
    const addressTerritoryShippingTypeSlug = address.territory?.shippingType?.slug

    if (isExpressDelivery) {
      return addressTerritoryShippingTypeSlug === shippingTypeSlugs.EXPRESS_DELIVERY
    }

    return addressTerritoryShippingTypeSlug !== shippingTypeSlugs.EXPRESS_DELIVERY
  })

  const addressLine = useFieldValue('addressLine')

  const coordinates = useFieldValue('coordinates')

  const { isLoggedIn } = useUser()

  const modalManager = useModalManager()

  const [isPickingNewAddress, setIsPickingNewAddress] = useState(false)

  const onLoginClick = () => {
    modalManager.closeModal()

    const callbackUrl = `${window.location.pathname}?open-shipping-modal=`

    cookie.save(cookieNames.CALLBACK_URL, callbackUrl)
  }

  return (
    <>
      <StyledTitle as="h5" $textStyle="h5Semibold">
        ¿Dónde quieres recibir tu pedido?
      </StyledTitle>
      {loading ? (
        <Loader centered style={{ height: '100%' }} />
      ) : (
        <>
          {(isPickingNewAddress || !isLoggedIn) && (
            <MapContainer>
              <GoogleMapsProvider>
                <PlacesAutocomplete
                  name="addressLine"
                  placeholder="Busca tu dirección"
                  onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
                  onFocus={() => setShowSuggestions(true)}
                  onEditClick={() => {
                    formProps.form.change('hasSelectedAddress', false)
                  }}
                  noAddressFound={
                    !coordinates && (
                      <FloatingContainer>
                        <NoAddressFound />
                      </FloatingContainer>
                    )
                  }
                />
                {showSuggestions && !addressLine && (
                  <FloatingContainer>
                    <Suggestions>
                      <CurrentLocation />
                      {map(userAddresses, address => (
                        <AddressSuggestion key={address.id} address={address} />
                      ))}
                    </Suggestions>
                  </FloatingContainer>
                )}
                <StyledMap
                  dynamic
                  initialCoordinates={
                    coordinates && {
                      lat: coordinates.latitude,
                      lng: coordinates.longitude,
                    }
                  }
                />
              </GoogleMapsProvider>
            </MapContainer>
          )}
          {isLoggedIn && (
            <>
              {!isPickingNewAddress && (
                <Addresses addresses={userAddresses} onActionClick={() => setIsPickingNewAddress(true)} />
              )}
              {userAddresses.length > 0 && (
                <StyledLabel
                  $textStyle="h5Semibold"
                  $color="info"
                  onClick={() => setIsPickingNewAddress(!isPickingNewAddress)}
                >
                  {isPickingNewAddress ? 'Volver a mis direcciones' : 'Usar otra dirección'}
                </StyledLabel>
              )}
            </>
          )}
          {!isLoggedIn && (
            <Link href="/ingresar" passHref>
              <Anchor $textStyle="h5Semibold" $color="info" onClick={onLoginClick}>
                Iniciar sesión y ver mis direcciones
              </Anchor>
            </Link>
          )}
        </>
      )}
    </>
  )
}

export default DeliveryContent
