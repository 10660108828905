import { isPlainObject } from 'lodash'

import type { PropKey, ThemeTextStyle } from '~/types/theme'

import themeGetters from './theme-getters'

const { textStyles } = themeGetters as any

interface TextStyle {
  size: number
  weight: number
  lineHeight: number
}

const plainStyles = (style: TextStyle) => `
  font-size: ${style.size}px;
  font-weight: ${style.weight};
  line-height: ${style.lineHeight}px;
`

const getTextStyle = (themeKey: TextStyle | ThemeTextStyle) => {
  if (isPlainObject(themeKey)) {
    return plainStyles(themeKey as TextStyle)
  }

  const styledResolver = (props: PropKey) => {
    const styleObject = textStyles(themeKey)(props)

    return plainStyles(styleObject)
  }

  return styledResolver
}

export default getTextStyle
