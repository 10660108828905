import { partnerVendorsQuery } from '~/gql/queries'

const fetchPartnerVendors = async apolloClient => {
  try {
    const queryConfig = {
      query: partnerVendorsQuery,
      fetchPolicy: 'network-only',
    }

    const response = await apolloClient.query(queryConfig)

    return response.data.manufacturer.distributors
  } catch (error) {
    console.error(error)

    return []
  }
}

export default fetchPartnerVendors
