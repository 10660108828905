import React from 'react'

import LocationSelector from '~/components/location-selector'
import Field from '~/truck/field'
import Label from '~/truck/label'

const AddressPageContent = props => {
  const { coverageTree, forBusinessOnly } = props

  return (
    <>
      <Label as="p" $textStyle="h5Semibold">
        Dirección
      </Label>
      {forBusinessOnly && <Field name="fiscalName" placeholder="Razón social" />}
      <LocationSelector coverageTree={coverageTree} />
      <Field name="line" placeholder="Dirección" />
    </>
  )
}

export default AddressPageContent
