import * as React from 'react'
import styled from 'styled-components'

export interface TagProps {
  children: React.ReactNode
}

const StyledTagContainer = styled.span`
  align-items: center;
  background: ${props => props.theme.palette.primary10};
  border-radius: ${props => props.theme.spacing.cozy / 2}px;
  display: inline-flex;
  padding: ${props => props.theme.spacing.cozy / 2}px ${props => props.theme.spacing.cozy}px;
`

export function Tag(props: TagProps) {
  const { children } = props

  return <StyledTagContainer>{children}</StyledTagContainer>
}
