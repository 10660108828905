// @ts-check
import { useEffect } from 'react'
import { get } from 'lodash'

import { getFlashData } from '~/server/flash-data'
import notifier from '~/truck/notifier'

interface NotifierProps {
  // type of the alert
  type: 'info' | 'success' | 'warning' | 'error'
  // timeout of the alert
  timeout?: number
  // if the alert can be closed by the user
  closable?: boolean
}

/**
 * Shows an alert using flashed data.
 */
const useNotifyFlashData = (dataKey: string, notifierProps: NotifierProps) => {
  const finalNotifierProps = {
    type: 'info',
    timeout: 5 * 1000,
    closable: false,
    ...notifierProps,
  }

  useEffect(() => {
    const flashData = getFlashData()

    const data = get(flashData, dataKey)

    if (!data) {
      return
    }

    notifier[finalNotifierProps.type](data, notifierProps)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useNotifyFlashData
