import React from 'react'
import { useFlag } from 'toggled'

import useExpressDeliverySession from '~/hooks/use-express-delivery-session'
import useModalManager from '~/hooks/use-modal-manager'
import useMultiDistributorSession from '~/hooks/use-multi-distributor-session'
import { flags } from '~/utils/constants'

import ShippingSelectorModal from '../shipping-modal'

function useEnabled() {
  const isPublicPartnerVendor = useFlag(flags.PUBLIC_PARTNER_VENDOR)

  const hasShippingBeforeBuy = useFlag(flags.SHIPPING_BEFORE_BUY)

  const { multiDistributorSession } = useMultiDistributorSession()

  const { expressDeliverySession } = useExpressDeliverySession()

  const hasExpressDelivery = useFlag(flags.EXPRESS_DELIVERY)

  if (!hasShippingBeforeBuy) {
    return false
  }

  if (hasExpressDelivery) {
    return !expressDeliverySession
  }

  return !isPublicPartnerVendor && !multiDistributorSession
}

function useAction() {
  const modalManager = useModalManager()

  function openShippingSelectorModal() {
    modalManager.openModal(ShippingSelectorModal)
  }

  return openShippingSelectorModal
}

const AddToCartBlocker = props => {
  const { component: Wrapped, isRemovable, ...rest } = props

  const enabled = useEnabled()

  const action = useAction()

  if (!enabled) {
    return <Wrapped isRemovable={isRemovable} {...rest} />
  }

  return (
    <Wrapped
      {...rest}
      isRemovable={isRemovable}
      className="add-to-cart-blocker"
      onClick={e => {
        e.stopPropagation()

        e.nativeEvent.stopImmediatePropagation()

        action()
      }}
    />
  )
}

export default AddToCartBlocker
