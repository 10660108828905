import styled from 'styled-components'

import theme from '~/truck/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  > span {
    display: block;
    margin-left: ${theme.spacing.comfortable}px;
    margin-top: ${theme.spacing.compact}px;
  }
`
