import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import theme from '~/truck/theme'

import Price from '../price'

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  > p:not(:last-child) {
    margin-bottom: ${theme.spacing.compact}px;
  }
`

export const Container = styled.div`
  align-items: stretch;
  background-color: ${theme.colors.white};
  display: flex;
  padding: ${theme.spacing.comfortable}px;
  user-select: none;

  > img {
    flex-shrink: 0;
    height: ${theme.spacing.cozy * 9}px;
    margin-right: ${theme.spacing.comfortable}px;
    width: ${theme.spacing.cozy * 10}px;
  }
`

export const OtherContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: space-between;
  text-align: right;
`

export const StyledPrice = styled(Price)`
  > div {
    align-items: flex-end;
    flex-direction: column;

    > strike {
      margin-right: 0;
    }
  }

  ${up('lg')} {
    > div {
      flex-direction: row;

      > strike {
        margin-right: ${theme.spacing.cozy}px;
      }
    }
  }
`
