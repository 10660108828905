import gql from 'graphql-tag'

const signupToSeePricesMutation = gql`
  mutation signupToSeePrices(
    $firstName: String!
    $lastName: String!
    $phone: String!
    $email: String!
    $password: String!
    $deliveryAddress: AddressInput!
    $document: DocumentInput!
  ) {
    signupToSeePrices(
      input: {
        firstName: $firstName
        lastName: $lastName
        phone: $phone
        email: $email
        password: $password
        deliveryAddress: $deliveryAddress
        document: $document
      }
    ) {
      token
      role
    }
  }
`

export default signupToSeePricesMutation
