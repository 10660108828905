import styled from 'styled-components'

import Input from '~/truck/input'
import { headerHeights, stack } from '~/variables'

interface StyledInputProps {
  $isOpen?: boolean
}

function getTranslate({ $isOpen }: StyledInputProps) {
  if ($isOpen) {
    return 'translate(0)'
  }

  return 'translate(100vw)'
}

export const StyledInput = styled(Input)<StyledInputProps>`
  height: ${headerHeights.getMainMobile}px;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transform: ${getTranslate};
  transition: transform 0.25s ease-in-out;
  width: 100%;
  z-index: ${stack.mainHeader + 1};

  > input {
    border-color: transparent !important;
    border-radius: 0;
    height: 100%;
  }
`
