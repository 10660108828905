import * as React from 'react'
import { useFlag } from 'toggled'

import PricingWidget from '~/components/pricing'
import { usePricing } from '~/hooks/use-pricing'
import type { IOrderPriceLine } from '~/types/orders'
import { flags } from '~/utils/constants'

import Panel from '../panel'

interface PricingProps {
  title?: string
  /**
   * @deprecated Use "lines" prop instead of this prop
   */
  order?: { priceLines: IOrderPriceLine[] }
  lines?: IOrderPriceLine[]
  className?: string
  style?: React.CSSProperties
  showTheBestRewardDescription?: boolean
}

function Pricing(props: PricingProps) {
  const { order, className, style, title, lines, showTheBestRewardDescription } = props

  const hidePrices = useFlag(flags.HIDE_PRICES)

  const pricing = usePricing({
    orderPriceLines: order?.priceLines ?? lines,
    settings: {
      showTheBestRewardDescription,
    },
  })

  if (hidePrices) {
    return null
  }

  return (
    <Panel title={title || 'Detalle de precio'} className={className} style={style}>
      <PricingWidget lines={pricing.lines} />
    </Panel>
  )
}

export default Pricing
