import gql from 'graphql-tag'

const socialLoginMutation = gql`
  mutation socialLogin($provider: Provider!, $token: String!) {
    socialLogin(input: { provider: $provider, token: $token }) {
      token
      role
    }
  }
`

export default socialLoginMutation
