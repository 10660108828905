import React from 'react'
import clsx from 'clsx'

import Icon from '~/truck/icon'

interface OpenCartButtonViewProps {
  lineItemsTotalQuantity: number
  onOpenCartDrawer: () => void
  triggerAnimation?: boolean
  onAnimationEnd: () => void
  onAnimationStart: () => void
}

export const OpenCartButtonView = (props: OpenCartButtonViewProps) => {
  const { lineItemsTotalQuantity, onOpenCartDrawer, triggerAnimation, onAnimationEnd, onAnimationStart } = props

  return (
    <div
      className={clsx(
        'flex h-10 items-center gap-2 rounded bg-white/20 pl-2 pr-3',
        triggerAnimation && 'animate-pop-in',
      )}
      onClick={onOpenCartDrawer}
      onAnimationEnd={onAnimationEnd}
      onAnimationStart={onAnimationStart}
      role="button"
    >
      <Icon type="cart" color="currentColor" />
      <span className="text-xl font-semibold">{lineItemsTotalQuantity}</span>
    </div>
  )
}
