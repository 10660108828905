import React, { useRef } from 'react'

import { Media } from '~/components'
import Modal from '~/components/modal'
import Label from '~/truck/label'
import Loader from '~/truck/loader'
import Overlay from '~/truck/overlay'
import type { IProduct } from '~/types/products'

import { BottomSheetStandalone, SuggestedProductsList, SuggestedProductsSlider } from '../../components'
import { useDebounceHandler } from '../../providers'

import {
  StyledGhostButton,
  StyledModalBody,
  StyledModalFooter,
  StyledModalHeader,
  StyledNormalButton,
  StyledSuggestedProductsModal,
} from './SuggestedProductsModal.style'

type SuggestedProductModalViewProps = {
  onClose?: () => void
  loading?: boolean
  products: IProduct[]
  isMobile?: boolean
  productsAdded?: boolean
  onAfterClose?: () => void
}

export const SuggestedProductsModalView = (props: SuggestedProductModalViewProps) => {
  const bodyRef = useRef(null)

  const { isDebouncing } = useDebounceHandler()

  const ModalOrBottomSheet = props.isMobile ? BottomSheetStandalone : StyledSuggestedProductsModal

  return (
    <>
      <Overlay open />
      <ModalOrBottomSheet enablePropagation bodyRef={bodyRef}>
        <StyledModalHeader>
          <Modal.Header
            closable={true}
            title="Te podría interesar"
            onAfterClose={props.onAfterClose}
            disabled={isDebouncing}
          />
        </StyledModalHeader>
        <StyledModalBody ref={bodyRef}>
          {props.loading ? (
            <Loader centered />
          ) : (
            <>
              {props.products?.length === 0 ? (
                <Label $textStyle="h5Regular">
                  No tenemos productos sugeridos para ti ahora, quizás en otra oportunidad
                </Label>
              ) : (
                <Media
                  desktop={<SuggestedProductsSlider products={props.products} />}
                  mobile={<SuggestedProductsList products={props.products} />}
                />
              )}
            </>
          )}
        </StyledModalBody>
        <StyledModalFooter>
          {props.productsAdded ? (
            <StyledNormalButton disabled={isDebouncing} onClick={props.onClose}>
              Volver al carrito
            </StyledNormalButton>
          ) : (
            <StyledGhostButton disabled={isDebouncing} onClick={props.onClose}>
              No, gracias
            </StyledGhostButton>
          )}
        </StyledModalFooter>
      </ModalOrBottomSheet>
    </>
  )
}
