import gql from 'graphql-tag'

const deliveryDateTimeConfigQuery = gql`
  query deliveryDateTimeConfig($territoryId: ID) {
    dateTimeConfig: vendor {
      id
      timezone
      deliveryDates(territoryId: $territoryId)
      deliveryTimeRanges {
        id
        time
      }
    }
  }
`

export default deliveryDateTimeConfigQuery
