/**
 * Inspiration: https://www.npmjs.com/package/fetch-errors
 */

export class HTTPError extends Error {
  public response: Response

  public status: number

  constructor(response: Response) {
    super(response.statusText)

    this.name = this.constructor.name

    this.status = response.status

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor)
    } else {
      this.stack = new Error(response.statusText).stack
    }
  }
}

export class JSONHTTPError extends HTTPError {
  public json: unknown

  constructor(response: Response, json: unknown) {
    super(response)

    this.json = json
  }
}

export class TextHTTPError extends HTTPError {
  public data: unknown

  constructor(response: Response, data: unknown) {
    super(response)

    this.data = data
  }
}
