import * as React from 'react'
import { isNil } from 'lodash'
import styled from 'styled-components'

import Anchor from '~/truck/anchor'
import Label from '~/truck/label'
import { images } from '~/utils/constants'

export interface InfoStatusBoxProps {
  imageSrc?: string
  title?: string
  description?: string
  actionLabel?: string
  actionHref?: string
  children?: React.ReactNode
}

const StyledInfoStatusBox = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  > *:not(:first-child) {
    margin-top: ${props => props.theme.spacing.comfortable}px;
  }
`

const StyledDescription = styled(Label)`
  max-width: 386px;
  width: 100%;
`

export const InfoStatusBox = (props: InfoStatusBoxProps) => {
  const {
    title = 'Oops! Ocurrió un error',
    description,
    imageSrc = images.ILLUSTRATION_FILE_ALERT,
    actionHref,
    actionLabel,
    children,
  } = props

  return (
    <StyledInfoStatusBox>
      <img src={imageSrc} alt={title} />
      {!isNil(title) && <Label $textStyle="h4Bold">{title}</Label>}
      {description && <StyledDescription $textAlign="center">{description}</StyledDescription>}
      {actionHref && (
        <Anchor href={actionHref} $textStyle="h5Semibold">
          {actionLabel}
        </Anchor>
      )}
      {children}
    </StyledInfoStatusBox>
  )
}
