import styled from 'styled-components'

import theme from '~/truck/theme'
import { getTheme } from '~/truck/utils'
import { getTextStyle } from '~/truck/utils'

interface ContainerProps {
  $disabled?: boolean
  $error?: boolean
}

function getIconColor({ $disabled, $error }: ContainerProps) {
  if ($disabled) {
    return theme.colors.blackLight
  }

  if ($error) {
    return theme.colors.error
  }

  return theme.colors.black
}

export const Container = styled.div<ContainerProps>`
  appearance: none;
  height: ${theme.dimensions.inputHeight}px;
  position: relative;

  > svg {
    fill: ${getIconColor};
    pointer-events: none;
    position: absolute;
    right: ${theme.spacing.comfortable}px;
    top: 50%;
    transform: translateY(-50%);
  }

  ${props =>
    props.$disabled &&
    `
    > svg {
      cursor: not-allowed;
    }
  `};
`

interface StyledSelectProps {
  $error?: boolean
}

function getBorderColor({ $error }: StyledSelectProps) {
  if ($error) {
    return theme.colors.error
  }

  return theme.colors.gray
}

export const StyledSelect = styled.select<StyledSelectProps>`
  appearance: none;
  background-color: ${theme.colors.white};
  border: 1px solid ${getBorderColor};
  border-radius: ${theme.dimensions.borderRadius}px;
  box-shadow: none;
  height: ${theme.dimensions.inputHeight}px;
  outline: none;
  padding: 0 ${theme.spacing.comfortable}px;
  transition: border 0.2s ease-in-out;
  width: 100%;
  ${getTextStyle(theme.textStyles.h5Regular)};

  :disabled {
    background: ${theme.colors.grayLight};
    color: ${theme.colors.blackLight};
    cursor: not-allowed;
  }

  ${props =>
    !props.$error &&
    `
    :focus {
      border-color: ${getTheme(props).colors.primary};
    }
  `};
`
