import * as React from 'react'
import styled from 'styled-components'

import Label from '~/truck/label'

import { useDialogState } from '../dialog-provider'

import { BulkDialogCloseButton } from './bulk-dialog-close-button'
import { BulkDialogContent } from './bulk-dialog-content'

export interface BulkDialogResultContentProps {
  imageSrc: string
  description: string
  renderContent?: () => React.ReactNode
  renderFooter: () => React.ReactNode
}

const StyledDialogContent = styled(BulkDialogContent)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.cozy * 3}px;
`

const StyledImage = styled.img`
  display: block;
  gap: ${props => props.theme.spacing.cozy * 4}px;
  height: ${props => props.theme.spacing.cozy * 9}px;
  width: ${props => props.theme.spacing.cozy * 9}px;
`

export function BulkDialogResultContent(props: BulkDialogResultContentProps) {
  const { renderFooter, renderContent, imageSrc, description } = props

  const { onModalClose } = useDialogState()

  return (
    <StyledDialogContent gutterBottom gutterTop gutterXAxis>
      <BulkDialogCloseButton onClick={onModalClose} />
      <StyledImage src={imageSrc} alt={description} />
      <Label as="p" $textAlign="center" $color="grayscale70" $textStyle="h5Regular">
        {description}
      </Label>
      {renderContent && renderContent()}
      {renderFooter()}
    </StyledDialogContent>
  )
}
