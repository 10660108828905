import gql from 'graphql-tag'

import { userFragment } from '../fragments'

const userQuery = gql`
  query user($includesLineOfCredit: Boolean = false, $includesCart: Boolean = false) {
    user {
      ...user
      lineOfCredit @include(if: $includesLineOfCredit) {
        id
        isEnabled
      }
      cart @include(if: $includesCart) {
        id
        lineItems {
          id
          quantity
          product {
            id
            sku
          }
        }
      }
      quotationsPermission
    }
  }
  ${userFragment}
`

export default userQuery
