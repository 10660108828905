import styled from 'styled-components'

import theme from '~/truck/theme'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  > div {
    margin-top: ${theme.spacing.comfortable}px;
  }
`
