import React from 'react'
import styled from 'styled-components'

import theme from '~/truck/theme'
import { getTextStyle } from '~/truck/utils'

const getContainerColor = props => {
  let color: string = theme.colors.error

  if (props.color) {
    color = props.theme.colors[props.color]
  }

  return color
}

export const RibbonContainer = styled.div`
  align-items: center;
  background: ${getContainerColor};
  border-bottom-right-radius: ${theme.spacing.comfortable}px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  color: ${theme.colors.white};
  display: flex;
  justify-content: center;
  left: 0;
  max-height: ${theme.spacing.comfortable * 2}px;
  max-width: ${theme.spacing.comfortable * 9}px;
  overflow: hidden;
  padding: ${theme.spacing.cozy}px ${theme.spacing.comfortable}px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  ${getTextStyle('h6Semibold')};
`

interface RibbonProps {
  color?: string
  label: string
}

const Ribbon = (props: RibbonProps) => {
  const { label, color } = props

  return <RibbonContainer color={color}>{label}</RibbonContainer>
}

export default Ribbon
