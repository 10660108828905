import React, { useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { find, pick } from 'lodash'
import styled from 'styled-components'
import { useFlag } from 'toggled'

import EditModal from '~/components/edit-modal'
import { setDefaultDocumentMutation, upsertDocumentMutation } from '~/gql/mutations'
import { documentTypesQuery, linesOfBusinessQuery, userDocumentsQuery } from '~/gql/queries'
import { useVendor } from '~/hooks'
import useFieldValue from '~/hooks/use-field-value'
import useFormatters from '~/hooks/use-formatters'
import useModalManager from '~/hooks/use-modal-manager'
import Field from '~/truck/field'
import notifier from '~/truck/notifier'
import theme from '~/truck/theme'
import { getValidator, handleSubmissionErrors } from '~/utils'
import { flags } from '~/utils/constants'
import constraints from '~/utils/constraints'
import { checkIfLineOfBusinessIsAvailable } from '~/utils/lines-of-business'

const Container = styled.div`
  > *:not(:last-child) {
    margin-bottom: ${theme.spacing.comfortable}px;
  }
`

const getModalDetails = document => {
  if (!document) {
    return {
      modalTitle: 'Agregar documento',
      message: 'Haz agregado un documento',
    }
  }

  return {
    modalTitle: 'Editar documento',
    message: 'Haz actualizado el documento',
  }
}

const initialFieldNames = ['documentNumber', 'fiscalName', 'fiscalAddress', 'lineOfBusiness', 'isDefault']

const renderDocumentTypeOption = documentType => {
  return (
    <option key={documentType.id} value={documentType.id}>
      {documentType.name}
    </option>
  )
}

const UpsertCustomerDocumentForm = props => {
  const { document, hasLineOfBusiness } = props

  const { data, loading } = useQuery(documentTypesQuery)

  const { linesOfBusinessData } = useQuery(linesOfBusinessQuery, { skip: !hasLineOfBusiness, fetchPolicy: 'no-cache' })

  const formatters = useFormatters()

  const documentTypeId = useFieldValue('documentTypeId')

  const documentNumberFormatter = useMemo(() => {
    if (loading || !documentTypeId) {
      return formatters.numeric
    }

    const documentType = find(data.vendor.documentTypes, { id: documentTypeId })

    return formatters[`${documentType.slug}DocumentNumber`]
  }, [loading, documentTypeId, formatters])

  if (loading) {
    return null
  }

  const { documentTypes } = data.vendor

  const disabledCheckbox = document?.isDefault

  const linesOfBusiness = linesOfBusinessData?.data?.vendor?.linesOfBusiness || []

  return (
    <Container>
      <Field name="documentTypeId" as="select" prompt="Tipo de documento*">
        {documentTypes.map(renderDocumentTypeOption)}
      </Field>
      <Field name="documentNumber" format={documentNumberFormatter} placeholder="Número de documento*" />
      <Field name="fiscalName" placeholder="Razón Social" />
      <Field name="fiscalAddress" placeholder="Dirección Fiscal" />
      {hasLineOfBusiness && (
        <Field as="select" name="lineOfBusiness" prompt="Giro de negocio">
          {linesOfBusiness.map((line, index) => (
            <option key={`${index}-line`} value={line}>
              {line}
            </option>
          ))}
        </Field>
      )}
      <Field name="isDefault" as="checkbox" disabled={disabledCheckbox}>
        Documento predeterminado
      </Field>
    </Container>
  )
}

const UpsertCustomerDocumentModal = props => {
  const { document, ...rest } = props

  const { modalTitle, message } = getModalDetails(document)

  const { closeModal } = useModalManager()

  const vendor = useVendor()

  const hasDevLineOfBusiness = useFlag(flags.DEV_LINE_OF_BUSINESS)

  const hasVendorCountryLineOfBusiness = checkIfLineOfBusinessIsAvailable(vendor.country.code)

  const hasLineOfBusiness = hasDevLineOfBusiness && hasVendorCountryLineOfBusiness

  const options = {
    refetchQueries: [
      {
        query: userDocumentsQuery,
      },
    ],
  }

  const [upsertDocument] = useMutation(upsertDocumentMutation, options)

  const [setDefaultDocument] = useMutation(setDefaultDocumentMutation, options)

  const initialValues = {
    ...pick(document, initialFieldNames),
    documentTypeId: document?.documentType?.id,
  }

  const onValidate = values => {
    const fieldNames = ['documentNumber', 'documentTypeId']

    if (hasLineOfBusiness) {
      fieldNames.push('lineOfBusiness')
    }

    const formValidator = getValidator(pick(constraints, fieldNames))

    return formValidator(values)
  }

  const onSubmit = async values => {
    const upsertOptions = {
      variables: {
        ...values,
        documentId: document?.id,
      },
    }

    try {
      await upsertDocument(upsertOptions)

      if (document && !document.isDefault && values.isDefault) {
        const setDefaultOptions = {
          variables: {
            documentId: document?.id,
          },
        }

        await setDefaultDocument(setDefaultOptions)
      }

      closeModal()

      notifier.success(message)
    } catch (error) {
      return handleSubmissionErrors(error)
    }
  }

  return (
    <EditModal initialValues={initialValues} onSubmit={onSubmit} onValidate={onValidate} title={modalTitle} {...rest}>
      <UpsertCustomerDocumentForm document={document} hasLineOfBusiness={hasLineOfBusiness} />
    </EditModal>
  )
}

export default UpsertCustomerDocumentModal
