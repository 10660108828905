import React from 'react'
import { Field } from 'react-final-form'
import { find, map } from 'lodash'

import RadioGroup from '~/truck/radio-group'

import Action from '../action'

import { Container, RadioGroupContainer } from './elements'

export interface ContentManagerProps<Item = any> {
  dataSource: Item[]
  renderer: (item: Item, onRemove: (id: string) => void) => React.ReactNode
  onSelect?: (item: Item) => void
  onRemove?: (id: string) => void
  fieldName: string
  onCreateClick?: () => void
  action?: string
  style?: React.CSSProperties
  className?: string
}

const ContentManager = ({
  dataSource,
  renderer,
  onSelect,
  onRemove,
  fieldName,
  onCreateClick,
  action,
  style,
  className,
}: ContentManagerProps) => {
  const renderContent = fieldProps => {
    const { input } = fieldProps

    const onChange = (contentId: string) => {
      input.onChange(contentId)

      if (onSelect) {
        const selectedItem = find(dataSource, { id: contentId })

        onSelect(selectedItem)
      }
    }

    return (
      <RadioGroupContainer>
        <RadioGroup {...input} onChange={onChange}>
          {map(dataSource, item => renderer(item, onRemove))}
        </RadioGroup>
      </RadioGroupContainer>
    )
  }

  return (
    <Container style={style} className={className}>
      <Field name={fieldName}>{renderContent}</Field>
      {action && (
        <Action $color="info" onClick={onCreateClick}>
          {action}
        </Action>
      )}
    </Container>
  )
}

export default ContentManager
