import React from 'react'
import { lighten } from 'polished'
import styled from 'styled-components'

import Label from '~/truck/label'
import theme from '~/truck/theme'

const StyledLabel = styled(Label)<{ $color: string }>`
  background-color: ${props => lighten(0.42, props.$color)};
  border-radius: ${theme.dimensions.borderRadius}px;
  color: ${props => props.$color};
  padding: ${theme.spacing.compact}px ${theme.spacing.cozy}px;
  text-transform: uppercase;
`

const OrderStatus = props => {
  const { name, color } = props

  return (
    <StyledLabel $textStyle="h6Semibold" $color={color}>
      {name}
    </StyledLabel>
  )
}

export default OrderStatus
