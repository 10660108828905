export enum IProductFilterType {
  LIST = 'list',
  RANGE = 'range',
}

export enum IProductFilterTarget {
  ATTRIBUTE = 'attribute',
}

export interface IProductFilerListType {
  label: string
  key: string
  count: number
  selected: boolean // only client side
  serializedFilter: string // only client side
}

export interface IProductFilterRangeType {
  label: string
  key: string
  value: number | null // only client side
}

export type IProductFilterValuesType = (IProductFilerListType | IProductFilterRangeType)[]

export interface IProductFilter {
  label: string
  key: string
  type: IProductFilterType
  target: IProductFilterTarget
  serializedFilter: string // only client side for range
  position: number
  values: IProductFilterValuesType
}

export interface IProductFilterQueryData {
  data: IProductFilter[]
}
