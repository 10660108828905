import gql from 'graphql-tag'

const documentTypesQuery = gql`
  query documentTypes {
    vendor {
      id
      documentTypes {
        id
        name
        slug
      }
    }
  }
`

export default documentTypesQuery
