import * as React from 'react'
import { useQuery } from '@apollo/react-hooks'

import { categoriesQuery } from '~/gql/queries'

import Content from './Content'

interface DrawerProps {
  isOpen: boolean
  closeDrawer: () => void
}

function Drawer({ isOpen, closeDrawer }: DrawerProps) {
  const { loading, data: categoryData } = useQuery(categoriesQuery)

  const categories = categoryData?.vendor?.categories ?? []

  if (loading) {
    return null
  }

  return <Content categories={categories} isOpen={isOpen} closeDrawer={closeDrawer} />
}

export default Drawer
