/**
 * The Tippy component doesn't support the `allowHtml` prop
 * https://github.com/atomiks/tippyjs-react/issues/94#issuecomment-498100958
 */
import * as React from 'react'
import Tippy, { TippyProps } from '@tippyjs/react'
import { isNil } from 'lodash'
import { darken, lighten } from 'polished'
import styled, { StyledProps } from 'styled-components'

import { getTextStyle } from '~/truck/utils'

import 'tippy.js/dist/tippy.css'

export interface UnitTooltipProps extends Omit<TippyProps, 'content' | 'theme'> {
  salesUnitFactor: number
  quantity: number
  inventoryUnitCode: string
}

function getBgColor(props: StyledProps<TippyProps>) {
  return lighten(0.4, props.theme.colors.secondary)
}

const StyledTippy = styled(Tippy)`
  background-color: transparent !important;
  filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.05));

  .tippy-content {
    border-radius: ${props => props.theme.spacing.compact}px;
    background-color: ${getBgColor};
    color: ${props => darken(0.05, props.theme.colors.secondary)};

    ${getTextStyle('h5Semibold')}
  }

  .tippy-arrow {
    color: ${getBgColor};
  }
`

export function UnitTooltip(props: UnitTooltipProps) {
  const { children, quantity, salesUnitFactor, inventoryUnitCode, ...tippyProps } = props

  if (isNil(salesUnitFactor) || isNil(inventoryUnitCode)) {
    return children
  }

  const contentHtml = `${(Number(quantity) * Number(salesUnitFactor)).toFixed(2)} ${inventoryUnitCode}`

  return (
    <StyledTippy content={<div dangerouslySetInnerHTML={{ __html: contentHtml }} />} {...tippyProps}>
      <div>{children}</div>
    </StyledTippy>
  )
}
