import { useApolloClient } from '@apollo/react-hooks'
import type { MutationUpdaterFn } from 'apollo-client'
import { isNil, startCase } from 'lodash'
import { useFlag } from 'toggled'

import { DeleteDialog } from '~/components'
import { LoginToContinueModal } from '~/components/login-to-continue-modal'
import { initialProductFragment } from '~/gql/fragments'
import { createUserFavoriteProductMutation, removeUserFavoriteProduct } from '~/gql/mutations'
import { favoriteProductsQuery } from '~/gql/queries'
import { mixpanel } from '~/metrics'
import notifier from '~/truck/notifier'
import type { IProduct } from '~/types/products'
import { events, flags, images } from '~/utils/constants'

import useModalManager from './use-modal-manager'
import useUser from './use-user'

const getOptimistic = (mutationName: string) => ({
  __typename: 'Mutation',
  [mutationName]: {
    success: true,
    __typename: `${startCase(mutationName)}Payload`,
  },
})

const useFavoriteProduct = (product: IProduct) => {
  const { id: productId, originalId, favoriteSince } = product

  const { openModal, closeModal } = useModalManager()

  const apolloClient = useApolloClient()

  const hasMyFavorites = useFlag(flags.MY_FAVORITES)

  const { isLoggedIn } = useUser()

  const variables = { productId }

  const openLoginToContinue = () => {
    openModal(LoginToContinueModal, {
      image: images.ILLUSTRATION_FAVORITE,
      description: 'Inicia sesión para validar la disponibilidad del producto y agregarlo a tus favoritos',
    })
  }

  const getUpdater =
    (isCreating: boolean): MutationUpdaterFn<any> =>
    (proxy, response) => {
      const mutationName = isCreating ? 'createUserFavoriteProduct' : 'removeUserFavoriteProduct'

      const newFavoriteSince = isCreating ? new Date().toISOString() : null

      const isSuccess = response.data[mutationName]?.success

      if (isSuccess) {
        const fragmentOptions = {
          fragment: initialProductFragment,
          id: `Product:${productId}`,
          fragmentName: 'initialProduct',
        }

        const cachedProduct = proxy.readFragment<IProduct>(fragmentOptions)

        cachedProduct.favoriteSince = newFavoriteSince

        try {
          const cachedFavoriteProducts = proxy.readQuery<{ user: { favoriteProducts: IProduct[] } }>({
            query: favoriteProductsQuery,
          })

          if (isCreating) {
            cachedFavoriteProducts.user.favoriteProducts.unshift(cachedProduct)
          } else {
            cachedFavoriteProducts.user.favoriteProducts = cachedFavoriteProducts.user.favoriteProducts.filter(
              itemProduct => itemProduct.id !== productId,
            )
          }

          proxy.writeQuery({
            query: favoriteProductsQuery,
            data: cachedFavoriteProducts,
          })
        } catch (_error) {
          console.log("Can't find field favoriteProducts on object")
        }

        proxy.writeFragment({
          ...fragmentOptions,
          data: cachedProduct,
        })
      }
    }

  const onCreateFavorite = async () => {
    mixpanel.track(events.PRODUCT_ADDED_TO_FAVORITES, {
      productId: originalId ?? productId,
    })

    try {
      await apolloClient.mutate({
        mutation: createUserFavoriteProductMutation,
        variables,
        optimisticResponse: getOptimistic('createUserFavoriteProduct'),
        update: getUpdater(true),
      })

      notifier.success('Producto añadido a tus favoritos')
    } catch (error) {
      console.error(error)

      notifier.error('Error al añadir a favoritos')
    }
  }

  const onRemoveFavorite = async () => {
    mixpanel.track(events.PRODUCT_REMOVED_FROM_FAVORITES, {
      productId: originalId ?? productId,
    })

    try {
      await apolloClient.mutate({
        mutation: removeUserFavoriteProduct,
        variables,
        optimisticResponse: getOptimistic('removeUserFavoriteProduct'),
        update: getUpdater(false),
      })

      notifier.success('Producto eliminado de tus favoritos')
    } catch (error) {
      console.error(error)

      notifier.error('Error al remover de favoritos')
    }
  }

  const onToggleFavorite = () => {
    if (!hasMyFavorites) {
      return
    }

    if (!isLoggedIn) {
      return openLoginToContinue()
    }

    if (isNil(favoriteSince)) {
      return onCreateFavorite()
    }

    return openModal(DeleteDialog, {
      header: 'Eliminar favorito',
      content: '¿Estás seguro de eliminar este producto de tu lista de favoritos?',
      onClose: () => closeModal(),
      onAccept: () => {
        onRemoveFavorite()

        closeModal()
      },
    })
  }

  return { onToggleFavorite }
}

export default useFavoriteProduct
