import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import cookie from 'isomorphic-cookie'
import { find } from 'lodash'

import { setStoreToBuyMutation } from '~/gql/mutations'
import { storesQuery } from '~/gql/queries'
import useBuyingInStore from '~/hooks/use-buying-in-store'
import useModalManager from '~/hooks/use-modal-manager'
import { forceReloadTo } from '~/utils'
import { cookieNames } from '~/utils/constants'

import Modal from '../modal'

import { StyledButton, StyledStoreSelector } from './elements'

const saveStoreId = (storeId: string) => {
  cookie.save(cookieNames.STORE_ID, storeId, { secure: false })
}

const BuyingInStoreSelector = props => {
  const { modalId } = props

  const modalManager = useModalManager()

  const defaultStore = useBuyingInStore()

  const [setStoreToBuy] = useMutation(setStoreToBuyMutation)

  const storesResponse = useQuery(storesQuery, {
    variables: { showAll: false },
  })

  const stores = storesResponse.data?.vendor?.stores?.items

  const hasStoreExclusiveDelivery = !!find(stores, 'makesDelivery')

  const [selectedStore, setSelectedStore] = useState(defaultStore)

  const [loading, setLoading] = useState(false)

  const saveSelectedStore = async () => {
    try {
      saveStoreId(selectedStore.id)

      await setStoreToBuy()
    } catch (error) {
      console.error(error)
    }
  }

  const onContinueClick = async () => {
    setLoading(true)

    await saveSelectedStore()

    setLoading(false)

    if (defaultStore?.id !== selectedStore.id) {
      forceReloadTo()
    } else {
      modalManager.closeModal()
    }
  }

  const getTitle = () => {
    if (storesResponse.loading) {
      return ''
    }

    if (hasStoreExclusiveDelivery) {
      return 'Hola, selecciona donde deseas hacer tu compra'
    }

    return 'Hola, selecciona tu tienda de recojo'
  }

  return (
    <Modal>
      <>
        <Modal.Header modalId={modalId} title={getTitle()} onAfterClose={saveSelectedStore} closable />
        <StyledStoreSelector onlyPickables defaultValue={defaultStore?.id} onSelect={setSelectedStore} />
        <StyledButton onClick={onContinueClick} loading={loading} disabled={!selectedStore}>
          Continuar Comprando
        </StyledButton>
      </>
    </Modal>
  )
}

export default BuyingInStoreSelector
