import React from 'react'
import { Field as FinalFormField } from 'react-final-form'
import { useMutation, useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'

import { Loader } from '~/components'
import EditModal from '~/components/edit-modal'
import ManagementListItem from '~/components/management-list-item'
import { editOrderSetInvoicingMutation } from '~/gql/mutations'
import { userDocumentsQuery } from '~/gql/queries'
import useModalManager from '~/hooks/use-modal-manager'
import Anchor from '~/truck/anchor'
import Label from '~/truck/label'
import notifier from '~/truck/notifier'
import RadioGroup from '~/truck/radio-group'
import theme from '~/truck/theme'
import { handleSubmissionErrors } from '~/utils'

import UpsertCustomerDocumentModal from './upsert-customer-document-modal'

const StyledAnchor = styled(Anchor)`
  display: block;
  margin-top: ${theme.spacing.comfortable}px;
`

const DocumentsContainer = styled.div`
  > *:not(:last-child) {
    margin-bottom: ${theme.spacing.comfortable}px;
  }
`

const SetInvoicingForm = () => {
  const { openModal } = useModalManager()

  const responseDocuments = useQuery(userDocumentsQuery)

  const documents = responseDocuments.data?.user?.documents ?? []

  const renderDocument = document => {
    return (
      <ManagementListItem
        title={document.documentType.name}
        isDefault={document.isDefault}
        defaultText="Documento principal"
        onEdit={() => openModal(UpsertCustomerDocumentModal, { document })}
        isSelectable
        key={document.id}
        id={document.id}
        value={document.id}
      >
        <Label as="p" $textStyle="h5Regular">
          {document.documentNumber}
        </Label>
        {document.fiscalName && (
          <Label as="p" $textStyle="h5Regular">
            {document.fiscalName}
          </Label>
        )}
        {document.fiscalAddress && (
          <Label as="p" $textStyle="h5Regular">
            {document.fiscalAddress}
          </Label>
        )}
        {document.lineOfBusiness && (
          <Label as="p" $textStyle="h5Regular">
            {document.lineOfBusiness}
          </Label>
        )}
      </ManagementListItem>
    )
  }

  if (responseDocuments.loading) {
    return <Loader centered />
  }

  return (
    <FinalFormField name="documentId">
      {fieldProps => {
        const { value, onChange } = fieldProps.input

        return (
          <RadioGroup onChange={onChange} value={value}>
            <DocumentsContainer>{documents.map(renderDocument)}</DocumentsContainer>
          </RadioGroup>
        )
      }}
    </FinalFormField>
  )
}

const SetInvoicingModal = props => {
  const { order, ...rest } = props

  const { closeModal, openModal } = useModalManager()

  const [setInvoicing] = useMutation(editOrderSetInvoicingMutation)

  const initialValues = {
    documentId: order.document.id,
  }

  const onSubmit = async values => {
    const options = {
      variables: {
        documentId: values.documentId,
        orderId: order.id,
      },
    }

    try {
      await setInvoicing(options)

      notifier.success('Haz actualizado el documento')

      closeModal()
    } catch (error) {
      return notifier.error(handleSubmissionErrors(error))
    }
  }

  return (
    <EditModal initialValues={initialValues} onSubmit={onSubmit} title="Editar documento" {...rest}>
      <SetInvoicingForm />
      <StyledAnchor $color="info" $textStyle="h5Regular" onClick={() => openModal(UpsertCustomerDocumentModal)}>
        Agregar documento
      </StyledAnchor>
    </EditModal>
  )
}

export default SetInvoicingModal
