import React from 'react'
import styled from 'styled-components'

import { useModalManager } from '~/hooks'
import theme from '~/truck/theme'

import InterbankIframeModal from '../interbank-iframe-modal'

export const BannerContainer = styled.div`
  padding: ${theme.spacing.comfortable}px;
  padding-bottom: 0;
  img {
    cursor: pointer;
    display: block;
    margin: auto;
    max-width: 100%;
  }
`
const PaymentMethodBanner = props => {
  const { settings } = props

  const modalManager = useModalManager()

  const handleClick = e => {
    e.preventDefault()

    if (!settings.isModal) {
      return window.open(settings.src, '_blank')
    }

    modalManager.openModal(InterbankIframeModal, {
      src: settings.src,
    })
  }

  return (
    <BannerContainer>
      <picture>
        <source media={`(max-width:${theme.breakpoints.lg}px)`} srcSet={settings.banner.mobile} />
        <img src={settings.banner.desktop} alt="Interbank Banner" onClick={handleClick} aria-hidden="true" />
      </picture>
    </BannerContainer>
  )
}

export default PaymentMethodBanner
