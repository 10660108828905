import styled, { keyframes } from 'styled-components'

import theme from '~/truck/theme'
import { stack } from '~/variables'

const showAnimation = keyframes`
0% {
  transform: translateY(100%);
}

100% {
  transform: translateY(0);
}
`

export const StyledSheet = styled.div<{ isDragging: boolean }>`
  animation-duration: 300ms;
  animation-fill-mode: forwards;
  animation-name: ${showAnimation};
  background: ${theme.colors.white};
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  max-height: 100vh;
  overflow-y: hidden;
  position: fixed;

  right: 0;

  touch-action: none;
  transform: translateY(100%);
  user-select: none;

  z-index: ${stack.bottomSheetStandalone};

  ${({ isDragging }) =>
    !isDragging &&
    `
      transition: height 300ms, transform 300ms;
      transition-delay: 0s;
    `}
`
