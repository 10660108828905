import styled from 'styled-components'

import Product from '~/components/product'
import theme from '~/truck/theme'

export const ProductCard = styled(Product.Mobile)`
  :not(:first-child) {
    border-bottom: 1px solid ${theme.colors.gray};
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
`
