import currency from 'currency.js'
import { initial, join, last, split } from 'lodash'

export function formatPrice(value: string | number, currencyConfig: any) {
  const formattedValue = currency(Number(value), currencyConfig).format()

  if (currencyConfig.precision === 0) {
    return [formattedValue]
  }

  const result = split(formattedValue, currencyConfig.decimal)

  if (result.length === 2) {
    return result
  }

  return [join(initial(result), currencyConfig.separator), last(result)]
}

export function formatPriceToString(value: string | number, currencyConfig: any) {
  const [valueInt, valueDecimal] = formatPrice(value, currencyConfig)

  if (!valueDecimal) {
    return valueInt.toString()
  }

  return `${valueInt}${currencyConfig.decimal}${valueDecimal}`
}
