import React from 'react'
import { useFlag } from 'toggled'

import useModalManager from '~/hooks/use-modal-manager'
import { flags } from '~/utils/constants'

import Comments from './comments'
import { Container } from './elements'
import Invoicing from './invoicing'
import LineItems from './line-items'
import { EditLineItemsModal, EditPaymentMethodModal, SetInvoicingModal, SetShippingModal } from './modals'
import Payment from './payment'
import Placeholder from './placeholder'
import Pricing from './pricing'
import Shipping from './shipping'

const Order = props => {
  const { order, style, className, canBeEditable } = props

  const hasEditOrderFlag = useFlag(flags.EDIT_ORDER)

  const hasOrderCommentsFF = useFlag(flags.ORDER_COMMENTS)

  const isEditable = hasEditOrderFlag && canBeEditable && order.isEditable

  const { openModal } = useModalManager()

  return (
    <Container className={className} style={style}>
      <LineItems isEditable={isEditable} onEditClick={() => openModal(EditLineItemsModal, { order })} order={order} />
      {hasOrderCommentsFF && <Comments order={order} />}
      <Shipping order={order} isEditable={isEditable} onEditClick={() => openModal(SetShippingModal, { order })} />
      {order.document && (
        <Invoicing order={order} isEditable={isEditable} onEditClick={() => openModal(SetInvoicingModal, { order })} />
      )}
      <Payment order={order} isEditable={isEditable} onEditClick={() => openModal(EditPaymentMethodModal, { order })} />
      <Pricing order={order} />
    </Container>
  )
}

Order.Container = Container
Order.LineItems = LineItems
Order.Shipping = Shipping
Order.Invoicing = Invoicing
Order.Payment = Payment
Order.Pricing = Pricing
Order.Placeholder = Placeholder

export default Order
