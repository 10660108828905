import gql from 'graphql-tag'

export const editOrderSetLineItemsMutation = gql`
  mutation editOrderSetLineItems($orderId: ID!, $lineItems: [LineItemInput!]) {
    setLineItems(input: { orderId: $orderId, lineItems: $lineItems }) {
      order {
        id
      }
    }
  }
`
