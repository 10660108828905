export const transitions = {
  expand: {
    duration: 200,
    delay: 0,
  },
  collapse: {
    duration: 200,
    delay: 100,
  },
  fadeIn: {
    duration: 100,
    delay: 50,
  },
  fadeOut: {
    duration: 100,
    delay: 100,
  },
  collapseAndFadeOut: {
    duration: 500,
  },
}
