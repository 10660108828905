import { useApolloClient } from '@apollo/react-hooks'

import { calculateCartPricingQuery } from '~/gql/queries'
import { cancellablePromiseAfter } from '~/lib/cancellablePromises'

interface VariablesCalculateCartPricing {
  deliveryCost?: number
  shippingTypeId?: string
  paymentMethodId?: string
  bin?: string
  storeId?: string
}

const useCalculateCartPricing = () => {
  const client = useApolloClient()

  const getCalculateCartPricing = async (variables: VariablesCalculateCartPricing) => {
    return await client.query({
      query: calculateCartPricingQuery,
      fetchPolicy: 'no-cache',
      variables: { ...variables },
    })
  }

  const calculateAndSetCartPricing = async (variables: VariablesCalculateCartPricing, fnCancel?: () => boolean) => {
    return new Promise((resolve, reject) => {
      cancellablePromiseAfter(getCalculateCartPricing(variables), fnCancel ?? (() => false))
        .then(result => {
          return client.writeQuery({ query: calculateCartPricingQuery, data: result.data })
        })
        .then(resolve)
        .catch(reject)
    })
  }

  return {
    getCalculateCartPricing,
    calculateAndSetCartPricing,
  }
}

export default useCalculateCartPricing
