import React, { useEffect, useMemo, useState } from 'react'
import { useField, useForm } from 'react-final-form'
import { useQuery } from '@apollo/react-hooks'
import { compact, filter, flatMap, map, uniqBy } from 'lodash'

import LocationSelector from '~/components/location-selector'
import StoreSelectorComponent from '~/components/store-selector/component'
import activeStoresQuery from '~/gql/queries/active-stores'
import useFieldValue from '~/hooks/use-field-value'
import Loader from '~/truck/loader'
import theme from '~/truck/theme'
import getCoverageTree from '~/utils/get-coverage-tree'

import { StyledButton, StyledTitle } from './elements'

export const PickupButton = props => {
  const { formProps } = props

  const localityId = useFieldValue('localityId')

  const storeId = useFieldValue('storeId')

  const viewStoresField = useField('viewStores')

  if (!viewStoresField.input.value) {
    return (
      <StyledButton
        disabled={!localityId}
        color="secondary"
        onClick={() => {
          viewStoresField.input.onChange(true)
        }}
      >
        Ver Tiendas
      </StyledButton>
    )
  }

  return (
    <StyledButton loading={formProps.submitting} onClick={formProps.handleSubmit} disabled={!storeId} color="secondary">
      Continuar
    </StyledButton>
  )
}

const PickupContent = () => {
  const form = useForm()

  const viewStores = useFieldValue('viewStores')

  const localityId = useFieldValue('localityId')

  const storeId = useFieldValue('storeId')

  const [stores, setStores] = useState([])

  const { loading, data } = useQuery(activeStoresQuery, {
    variables: { onlyPickup: true },
  })

  const distributors = data?.manufacturer?.distributors

  const allStores = useMemo(() => {
    function getActiveStores(distributor) {
      return map(distributor.activeStores, store => ({
        ...store,
        endpoint: distributor.endpoint,
      }))
    }

    return flatMap(distributors, getActiveStores)
  }, [distributors])

  const coverageTree = useMemo(() => {
    const locations = compact(flatMap(allStores, 'address.location'))

    try {
      return getCoverageTree(uniqBy(locations, 'id'))
    } catch (e) {
      return []
    }
  }, [allStores])

  useEffect(() => {
    if (!localityId) {
      return
    }

    function isInLocation(store) {
      const location = store?.address?.location

      return map(location, 'id').includes(localityId)
    }

    setStores(filter(allStores, isInLocation))
  }, [localityId])

  function renderBody() {
    if (loading) {
      return <Loader centered />
    }

    return viewStores ? (
      <StoreSelectorComponent
        defaultValue={storeId}
        dataSource={stores}
        onSelect={store => {
          form.batch(() => {
            form.change('storeId', store.id)
            form.change('storeName', store.name)
            form.change('storeEndpoint', store.endpoint)
          })
        }}
      />
    ) : (
      Boolean(coverageTree.length) && (
        <div style={{ margin: -theme.spacing.cozy }}>
          <LocationSelector coverageTree={coverageTree} />
        </div>
      )
    )
  }

  return (
    <>
      <StyledTitle as="h5" textStyle="h5Semibold">
        {viewStores ? `Se encontraron ${stores.length} tiendas cerca de:` : '¿Dónde quieres recoger tu pedido?'}
      </StyledTitle>
      {renderBody()}
    </>
  )
}

export default PickupContent
