import cookie from 'isomorphic-cookie'

import { getCookieConfig } from '~/utils'
import { cookieNames } from '~/utils/constants'

const getSession = async req => {
  const session = cookie.load(cookieNames.SESSION, req)

  if (session) {
    return session
  }

  const url = `${process.env.API}/visitor-token`

  const response = await fetch(url)

  return response.json()
}

const ensureSession = async (context = {}) => {
  const { req, res } = context

  const session = await getSession(req)

  cookie.save(cookieNames.SESSION, session, getCookieConfig(), res)

  if (req) {
    req.session = session
  }

  return session
}

export default ensureSession
