import React from 'react'

const useCartAnimation = lineItemsTotalQuantity => {
  const prevLineItemsTotalRef = React.useRef(lineItemsTotalQuantity)

  const [triggerAnimation, setTriggerAnimation] = React.useState(false)

  const [animationPlaying, setAnimationPlaying] = React.useState(false)

  React.useEffect(() => {
    if (lineItemsTotalQuantity > prevLineItemsTotalRef.current && !animationPlaying) {
      setTriggerAnimation(prevValue => !prevValue)
    }
    prevLineItemsTotalRef.current = lineItemsTotalQuantity
  }, [lineItemsTotalQuantity, animationPlaying])

  return { triggerAnimation, setTriggerAnimation, setAnimationPlaying }
}

export default useCartAnimation
