import React from 'react'
import { jsonLdScriptProps } from 'react-schemaorg'
import Head from 'next/head'
import type { AggregateRating, Offer, Product } from 'schema-dts'
import { useFlag } from 'toggled'

import { useVendor } from '~/hooks'
import type { IProduct } from '~/types/products'
import { removeHtmlTags } from '~/utils'
import { flags } from '~/utils/constants'

import Meta from './index'

const ProductMeta = (props: IProduct) => {
  const { name, description, seoTitle, seoDescription, slug, stock, pricing, sku, photos, reviewStats } = props

  const hasReviewFlag = useFlag(flags.HAS_REVIEW)

  const productName = removeHtmlTags(name)

  const metaTitle = seoTitle || productName

  const productDescription = description && removeHtmlTags(description)

  const metaDescription = seoDescription || productDescription

  const vendor = useVendor()

  const url = `https://${vendor.manufacturer.endpoint}/p/${slug}`

  const availability = stock > 0 ? 'InStock' : 'OutOfStock'

  const mainImage = photos[0]?.url

  const aggregateRating: AggregateRating = hasReviewFlag
    ? {
        '@type': 'AggregateRating',
        reviewCount: reviewStats?.numOfReviews ?? 0,
        ratingValue: reviewStats?.rating ?? 0,
      }
    : undefined

  const offers: Offer[] = pricing
    ? [
        {
          '@type': 'Offer',
          url,
          availability,
          itemCondition: 'NewCondition',
          price: pricing.price,
          priceCurrency: vendor.country.currencyCode,
        },
      ]
    : undefined

  return (
    <>
      <Meta title={metaTitle} description={metaDescription} image={mainImage} />
      {/* Microdata json-ld for Facebook and Google
      https://developers.facebook.com/docs/marketing-api/catalog/guides/microdata-tags#schema-org 
      https://developers.google.com/search/docs/data-types/product#json-ld */}
      <Head>
        <script
          {...jsonLdScriptProps<Product>({
            '@context': 'https://schema.org',
            '@type': 'Product',
            name: productName,
            description: productDescription,
            brand: vendor.name,
            sku,
            productID: sku,
            url,
            image: mainImage,
            offers,
            aggregateRating,
          })}
        />
      </Head>
    </>
  )
}

export default ProductMeta
