import gql from 'graphql-tag'

const shippingTypeFragment = gql`
  fragment shippingType on ShippingType {
    id
    name
    slug
    description
    icon
    isDelivery
    isPickup
    hasFares @client
    order
  }
`

export default shippingTypeFragment
