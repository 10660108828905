import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import Label from '~/truck/label'

export const BaseContainer = styled.div`
  background-color: ${props => props.theme.colors.grayLight};
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.theme.spacing.cozy * 2.5}px;
  padding: ${props => props.theme.spacing.relaxed}px;
`

export const HeaderContainer = styled.div`
  height: ${props => props.theme.spacing.relaxed * 4.5}px;
  width: 100%;
`

export const ContentContainer = styled.div`
  width: 100%;
`

export const StyledLabel = styled(Label)`
  display: inline-block;
  margin-bottom: ${props => props.theme.spacing.compact}px;
`

export const StyledTitle = styled(Label)`
  margin-bottom: ${props => props.theme.spacing.comfortable}px;
`

export const Separator = styled.div`
  background: ${props => props.theme.colors.gray};
  height: 1px;
  margin: ${props => props.theme.spacing.relaxed}px 0;
`

export const PinContainer = styled.div`
  display: flex;

  input {
    margin-right: ${props => props.theme.spacing.cozy}px;

    ${up('lg')} {
      margin-right: ${props => props.theme.spacing.comfortable}px;
    }
  }
`

export const HStack = styled.div`
  display: flex;
`
