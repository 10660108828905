import React, { useContext, useRef, useState } from 'react'
import { useForm } from 'react-final-form'
import styled from 'styled-components'

import { GoogleMapsContext } from '~/contexts'
import useFieldValue from '~/hooks/use-field-value'
import useGeocoding from '~/hooks/use-geocoding'
import Button from '~/truck/button'
import Icon from '~/truck/icon'
import Input from '~/truck/input'
import Label from '~/truck/label'
import notifier from '~/truck/notifier'
import Overlay from '~/truck/overlay'
import theme from '~/truck/theme'
import { baseUrls } from '~/utils/constants'

import Modal from '../modal'

import { CircularBackground, ErrorLabel } from './elements'
import Map from './map'

const Footer = styled.div`
  display: flex;
  margin-top: ${theme.spacing.relaxed}px;

  > button {
    width: ${theme.spacing.cozy * 22}px;
  }

  > button:first-child {
    margin-right: ${theme.spacing.comfortable}px;
  }
`

const StyledModal = styled(Modal.Standalone)`
  height: auto;
  margin: auto 0;
  overflow-y: hidden;
`

const StyledOverlay = styled(Overlay)`
  align-items: center;
  display: flex;
  padding: 0 ${theme.spacing.comfortable}px;
`

const Container = styled.div`
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.gray};
  cursor: pointer;
  display: flex;
  margin-bottom: ${theme.spacing.comfortable}px;
  padding: ${theme.spacing.cozy * 2.5}px ${theme.spacing.comfortable}px;
`

const ModalTitle = styled(Label)`
  margin-bottom: ${theme.spacing.comfortable}px;
`

const ConfirmAddressModal = props => {
  const { watchedFieldName, onClose } = props

  const form = useForm()

  const watchedFieldValue = useFieldValue(watchedFieldName)

  const initialFieldValueRef = useRef(watchedFieldValue)

  const [hasError, setError] = useState(false)

  const geocoding = useGeocoding()

  const { mapInstance } = useContext(GoogleMapsContext)

  const onChange = e => {
    const { value } = e.target

    form.change(watchedFieldName, value)
  }

  const onCancel = () => {
    onClose()

    form.batch(() => {
      form.change('zipCode', undefined)

      form.change('coordinates', undefined)

      form.change('addressComponents', undefined)

      form.change(watchedFieldName, initialFieldValueRef.current)
    })
  }

  const onConfirm = () => {
    if (!watchedFieldValue) {
      setError(true)

      return
    }

    onClose()

    form.batch(() => {
      form.change('hasSelectedAddress', true)

      form.change('showNoAddressFound', false)
    })
  }

  const onGetPosition = async location => {
    const { latitude: lat, longitude: lng } = location.coords

    form.change('coordinates.latitude', lat)

    form.change('coordinates.longitude', lng)

    if (mapInstance) {
      mapInstance.setCenter({ lat, lng })
    }

    if (geocoding) {
      // @ts-expect-error TS2339: missing types for `geocoding`
      const { address, zipCode, addressComponents } = await geocoding(lat, lng)

      form.change(watchedFieldName, address)

      if (zipCode) {
        form.change('zipCode', zipCode)
      }

      form.change('addressComponents', addressComponents)
    }
  }

  const onSetCurrentLocation = () => {
    const onError = () =>
      notifier.error('No pudimos obtener tu ubicación. Revisa tu configuración de privacidad.', { timeout: 5000 })

    navigator.geolocation.getCurrentPosition(onGetPosition, onError)
  }

  return (
    <StyledOverlay open>
      <StyledModal>
        <ModalTitle as="p" $textStyle="h5Semibold">
          Confirma tu dirección de envío
        </ModalTitle>
        <Input
          name={watchedFieldName}
          placeholder="Dirección"
          autoComplete="off"
          leftItem={<Icon type="location" />}
          value={watchedFieldValue}
          onChange={onChange}
        />
        {hasError && <ErrorLabel>Ingresa una dirección</ErrorLabel>}
        <Container role="button" onClick={onSetCurrentLocation}>
          <CircularBackground>
            <img alt="icon" src={`${baseUrls.CLOUDINARY}/v1582674687/sellers/resources/near-me.svg`} />
          </CircularBackground>
          <Label as="p" $textStyle="h5Regular">
            Mi ubicación actual
          </Label>
        </Container>
        <Map dynamic />
        <Footer>
          <Button.Ghost onClick={onCancel}>Cancelar</Button.Ghost>
          <Button color="primary" onClick={onConfirm}>
            Confirmar
          </Button>
        </Footer>
      </StyledModal>
    </StyledOverlay>
  )
}

export default ConfirmAddressModal
