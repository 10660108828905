import gql from 'graphql-tag'

const userContactPersonQuery = gql`
  query userContactPerson {
    user {
      id
      contactPerson
    }
  }
`

export default userContactPersonQuery
