import * as React from 'react'

import { FiltersContext } from '~/components/filters/components/filters-provider/filters-provider'

export function useFiltersState() {
  const context = React.useContext(FiltersContext)

  if (context === undefined) {
    throw new Error('useFiltersState must be used within a FiltersProvider')
  }

  return context
}
