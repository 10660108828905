import React, { useMemo } from 'react'
import { Form as FinalForm } from 'react-final-form'
import { useApolloClient } from '@apollo/react-hooks'
import cookie from 'isomorphic-cookie'
import { omit, pick } from 'lodash'
import { useRouter } from 'next/router'

import { DeleteDialog } from '~/components'
import { distributorInAttentionQuery } from '~/gql/queries'
import useFieldValue from '~/hooks/use-field-value'
import useModalManager from '~/hooks/use-modal-manager'
import useMultiDistributorSession from '~/hooks/use-multi-distributor-session'
import useVendor from '~/hooks/use-vendor'
import ShippingOptions from '~/screens/shipping/options'
import notifier from '~/truck/notifier'
import type { IShippingType } from '~/types/shipping'
import { forceReloadTo, getCookieConfig, handleSubmissionErrors, storage } from '~/utils'
import { cookieNames, storageKeys } from '~/utils/constants'

import Modal from '../modal'

import DeliveryContent, { DeliveryButton } from './delivery-content'
import { ButtonContainer, ContentContainer, ScrollContainer, StyledModal, StyledOverlay } from './elements'
import getInitialValues from './get-initial-values'
import getShippingTypes, { shippingTypesMap } from './get-shipping-types'
import type { ModalContentProps, ModalProps } from './index'
import PickupContent, { PickupButton } from './pickup-content'

function Content(props: ModalContentProps) {
  const { formProps, shippingTypes, closable } = props

  const shippingTypeId = useFieldValue('shippingTypeId', {})

  const shippingType = shippingTypesMap[shippingTypeId]

  return (
    <StyledModal>
      <ScrollContainer>
        <Modal.Header closable={closable} title="Selecciona tu tipo de despacho" />
        <ShippingOptions dataSource={shippingTypes} />
        <ContentContainer>
          {shippingType.isDelivery ? <DeliveryContent formProps={formProps} /> : <PickupContent />}
        </ContentContainer>
      </ScrollContainer>
      <ButtonContainer>
        {shippingType.isDelivery ? <DeliveryButton formProps={formProps} /> : <PickupButton formProps={formProps} />}
      </ButtonContainer>
    </StyledModal>
  )
}

function getHeaders(oldHeaders) {
  return omit(oldHeaders, [cookieNames.PARTNER_VENDOR_ENDPOINT, cookieNames.STORE_ID])
}

function checkIsEqual(multiDistributorSession, formValues) {
  const { deliveryAddress, store } = multiDistributorSession || {}

  const { shippingTypeId } = formValues

  const shippingType = shippingTypesMap[shippingTypeId]

  if (shippingType.isDelivery) {
    return (
      deliveryAddress && deliveryAddress.id === formValues.addressId && deliveryAddress.line === formValues.addressLine
    )
  }

  return store && store.id === formValues.storeId
}

function MultiDistributorShippingModal(props: ModalProps) {
  const { closable = true } = props

  const router = useRouter()

  const client = useApolloClient()

  const { openModal, closeModal } = useModalManager()

  const { setMultiDistributorSession, multiDistributorSession } = useMultiDistributorSession()

  const vendor = useVendor()

  const manufacturerSettings = vendor.manufacturer.settings

  const shippingTypes: IShippingType[] = getShippingTypes(manufacturerSettings)

  function saveMultiDistributorSession(formValues) {
    const { shippingTypeId, localityId } = formValues

    const shippingType = shippingTypesMap[shippingTypeId]

    const newSession: Record<string, any> = {
      shippingType,
      localityId,
    }

    if (shippingType.isDelivery) {
      newSession.deliveryAddress = {
        id: formValues.addressId,
        coordinates: formValues.coordinates,
        line: formValues.addressLine,
      }
    } else {
      newSession.store = {
        id: formValues.storeId,
        name: formValues.storeName,
      }
    }

    setMultiDistributorSession(newSession)

    storage.set(storageKeys.ADDRESS_COMPONENTS, formValues.addressComponents)
  }

  async function onSubmit(formValues) {
    const { coordinates, shippingTypeId } = formValues

    const shippingType = shippingTypesMap[shippingTypeId]

    if (shippingType.isDelivery && !coordinates) {
      return notifier.error('Ingresa una dirección')
    }

    if (shippingType.isPickup && !formValues.storeId) {
      return notifier.error('Selecciona una tienda')
    }

    function saveCookiesAndReload(endpoint, storeId) {
      saveMultiDistributorSession(formValues)

      cookie.save(cookieNames.PARTNER_VENDOR_ENDPOINT, endpoint, getCookieConfig())

      cookie.save(cookieNames.STORE_ID, storeId, getCookieConfig())

      // check if user is in product page
      // if product exists, reloads product page with new distributor information, otherwise it returns 404
      if (router.pathname === '/product') {
        forceReloadTo(undefined)

        return
      }

      const callbackUrl = cookie.load(cookieNames.CALLBACK_URL)

      if (callbackUrl) {
        cookie.remove(cookieNames.CALLBACK_URL)
      }

      forceReloadTo(callbackUrl ?? '/')
    }

    async function getInfoInAttention() {
      if (shippingType.isPickup) {
        return {
          endpoint: formValues.storeEndpoint,
          storeId: formValues.storeId,
        }
      }

      const {
        data: { distributorInAttention },
      } = await client.query({
        query: distributorInAttentionQuery,
        context: { getHeaders },
        fetchPolicy: 'network-only',
        variables: {
          coordinates: pick(coordinates, ['latitude', 'longitude']),
        },
      })

      return distributorInAttention
    }

    try {
      if (checkIsEqual(multiDistributorSession, formValues)) {
        return closeModal()
      }

      const { endpoint, storeId } = await getInfoInAttention()

      if (!multiDistributorSession) {
        return saveCookiesAndReload(endpoint, storeId)
      }

      const oldEndpoint = cookie.load(cookieNames.PARTNER_VENDOR_ENDPOINT)

      const oldStoreId = cookie.load(cookieNames.STORE_ID)

      const isSameDistributorInAttention = endpoint === oldEndpoint && storeId === oldStoreId

      if (shippingType.isDelivery && isSameDistributorInAttention) {
        saveMultiDistributorSession(formValues)

        closeModal()

        return
      }

      closeModal()

      openModal(DeleteDialog, {
        header: 'Cambiar dirección',
        content: `
          Al hacer este cambio perderás los productos guardados en tu carrito
          de compra. ¿Estás seguro que deseas cambiar tu dirección?
        `,
        onClose: closeModal,
        onAccept: () => saveCookiesAndReload(endpoint, storeId),
      })
    } catch (error) {
      return handleSubmissionErrors(error)
    }
  }

  const initialValues = useMemo(() => {
    return getInitialValues(multiDistributorSession)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledOverlay open $bgImage={!closable && manufacturerSettings?.d2c?.backgroundImage}>
      <FinalForm initialValues={initialValues} onSubmit={onSubmit}>
        {formProps => <Content formProps={formProps} shippingTypes={shippingTypes} closable={closable} />}
      </FinalForm>
    </StyledOverlay>
  )
}

export default MultiDistributorShippingModal
