import React, { useCallback, useState } from 'react'
import Tippy from '@tippyjs/react'
import { findIndex } from 'lodash'
import styled from 'styled-components'

import { StyledButton } from '~/components/store/elements'
import Icon from '~/truck/icon'
import theme from '~/truck/theme'

import { DayHours } from './shop-day-hours'
import type { DayShopHours, ShopHoursPopoverProps } from './types'

import 'tippy.js/dist/tippy.css'

const StyledTippy = styled(Tippy)`
  background-color: ${theme.colors.white};
  border-radius: 3px;
  box-shadow:
    0px 8px 10px rgba(0, 0, 0, 0.14),
    0px 3px 14px rgba(0, 0, 0, 0.12),
    0px 4px 5px rgba(0, 0, 0, 0.2);
  padding: 0;
  pointer-events: all;
  .tippy-content {
    padding: 0;
  }
  transition: visible 0;
`
const StyledTable = styled.table`
  border-collapse: separate;
  border-spacing: ${theme.spacing.cozy}px ${theme.spacing.compact * 3}px;
`

export function ShopHoursPopover(props: ShopHoursPopoverProps) {
  const [visible, setVisible] = useState(false)

  function handleOnClick(event) {
    event.stopPropagation()
    setVisible(!visible)
  }
  const scrollCallback = useCallback(() => {
    setVisible(false)
  }, [setVisible])

  function getDaysHours(days: DayShopHours[]): DayShopHours[] {
    const daysHours: DayShopHours[] = []

    for (let day = 1; day <= 7; day++) {
      const shopDay = findIndex(days, ['dayOfWeek', day])

      if (shopDay == -1) daysHours.push({ id: `${day}`, dayOfWeek: day, openTime: '', closeTime: '' })
      else daysHours.push(days[shopDay])
    }

    return daysHours
  }

  function getContent() {
    return (
      <tbody>
        {getDaysHours(props.shopHours).map((day: DayShopHours) => (
          <DayHours key={day.id} day={day} />
        ))}
      </tbody>
    )
  }

  if (!props.shopHours || props.shopHours?.length == 0) return null

  return (
    <StyledTippy
      placement="bottom"
      content={<StyledTable onClick={e => e.stopPropagation()}>{getContent()}</StyledTable>}
      visible={visible}
      arrow={false}
      onClickOutside={() => setVisible(false)}
      onShown={() => {
        document.addEventListener('scroll', scrollCallback, true)
      }}
      onHide={() => {
        document.removeEventListener('scroll', scrollCallback, true)
      }}
      offset={[theme.spacing.cozy * 3 + 2, 0]}
      appendTo="parent"
      interactive
      duration={[null, 0]}
    >
      <StyledButton onClick={handleOnClick}>
        <Icon type="clock" />
        <span>Ver horarios</span>
      </StyledButton>
    </StyledTippy>
  )
}
