import * as React from 'react'
import { useRouter } from 'next/router'

import useVendor from '~/hooks/use-vendor'
import Icon from '~/truck/icon'

import Logo from '../logo'

import { AnnouncementBar } from './announcement-bar'
import MobileDrawer from './mobile-drawer'
import SearchBar from './search-bar'

function Mobile() {
  const router = useRouter()

  const [state, setState] = React.useState({
    drawerIsOpen: false,
    searchBarIsOpen: router.asPath.startsWith('/b/'),
  })

  const searchBarRef = React.useRef<HTMLInputElement>()

  const vendor = useVendor()

  const openSearchBar = () => {
    searchBarRef.current.focus()

    setState({
      searchBarIsOpen: true,
      drawerIsOpen: false,
    })
  }

  const closeSearchBar = () => {
    setState({ ...state, searchBarIsOpen: false })
  }

  const toggleDrawer = () => {
    setState({ ...state, drawerIsOpen: !state.drawerIsOpen })
  }

  const closeDrawer = () => {
    setState({ ...state, drawerIsOpen: false })
  }

  const getLeftIconType = () => {
    if (state.drawerIsOpen) {
      return 'close'
    }

    return 'hamburger'
  }

  const announcementBarSettings = vendor?.settings?.announcement

  return (
    <>
      {announcementBarSettings && !state?.drawerIsOpen && !state?.searchBarIsOpen && (
        <AnnouncementBar
          className="z-[27]"
          message={announcementBarSettings?.message}
          link={announcementBarSettings?.link}
          shouldOpenInNewTab={announcementBarSettings?.shouldOpenInNewTab}
          foregroundColor={announcementBarSettings?.foregroundColor}
          backgroundColor={announcementBarSettings?.backgroundColor}
        />
      )}
      <header className="sticky inset-x-0 top-0 z-20 flex h-[var(--space-mobile-header-height)] items-center justify-between bg-brand-primary px-4 text-brand-primary-on-bg">
        <Icon type={getLeftIconType()} color="currentColor" onClick={toggleDrawer} />
        <Logo className="flex h-full items-center" vendor={vendor} size="small" />
        <Icon type="search" color="currentColor" onClick={openSearchBar} />
      </header>
      <SearchBar.Mobile
        debounced={!vendor.settings?.disableInstantSearch}
        ref={searchBarRef}
        isOpen={state.searchBarIsOpen}
        rightItem={
          <Icon className="text-brand-primary-on-bg" type="close" color="currentColor" onClick={closeSearchBar} />
        }
      />
      <MobileDrawer isOpen={state.drawerIsOpen} closeDrawer={closeDrawer} />
    </>
  )
}

export default Mobile
