import { isNil, noop } from 'lodash'

import { hasFlag, scripts } from '~/utils'
import { flags } from '~/utils/constants'
import isBrowser from '~/utils/is-browser'

import { TrackedEventError } from './errors'

if (isBrowser && typeof window.mixpanel === 'undefined') {
  window.mixpanel = {
    isMock: true,
    track: noop,
    identify: noop,
    people: {
      set: noop,
    },
    reset: noop,
  }
}

const mixpanelConnector = {
  track: (eventName, metadata) => {
    window.mixpanel.track(eventName, metadata)
  },
  asyncTrack: async (eventName, metadata) => {
    if (window.mixpanel.isMock) {
      return
    }

    function eventTrackPromiseExecutor(resolve, reject) {
      const createErrorTimeout = () => {
        return setTimeout(() => {
          reject(new TrackedEventError(eventName))
        }, 2000)
      }

      const dispatchErrorTimeoutId = createErrorTimeout()

      window.mixpanel.track(eventName, metadata, undefined, (...args) => {
        clearTimeout(dispatchErrorTimeoutId)

        resolve(...args)
      })
    }

    return new Promise(eventTrackPromiseExecutor)
  },
  login: (user = {}) => {
    const { originalId: id } = user

    if (!id) {
      return
    }

    window.mixpanel.identify(id)

    const userInfo = {
      userId: id,
      $email: user.email,
      $firstName: user.firstName,
      $lastName: user.lastName,
    }

    window.mixpanel.people.set(userInfo)
  },
  logout: () => {
    window.mixpanel.reset()
  },
  getHeadScript: features => {
    const hasMixpanelToken = !isNil(process.env.MIXPANEL_TOKEN)

    const isActiveMixpanelTracking = hasMixpanelToken && hasFlag(features, flags.MIXPANEL_TRACKING)

    return isActiveMixpanelTracking && scripts.mixpanel(process.env.MIXPANEL_TOKEN)
  },
}

export default mixpanelConnector
