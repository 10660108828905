import React, { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import styled from 'styled-components'

import Icon from '~/truck/icon'
import Input from '~/truck/input'
import Overlay from '~/truck/overlay'
import theme from '~/truck/theme'

import Menu from './menu'

const Container = styled.div`
  position: relative;

  ul {
    left: 0;
    margin-top: ${theme.spacing.cozy}px;
    position: absolute;
    right: 0;
    top: ${theme.spacing.cozy * 6};
  }
`

const Content = props => {
  const { data, inputRef, ItemComponent, meta, onSearch, placeholder, searching } = props

  const [isOverlayOpen, setIsOverlayOpen] = useState(false)

  const [searchTerm, setSearchTerm] = useState('')

  const [menuState, setMenuState] = useState({ term: '' })

  const onSearchTermChange = useCallback(
    debounce(value => {
      onSearch(value)

      setSearchTerm(value)
    }, 500),
    [onSearch],
  )

  useEffect(() => {
    if (searching) {
      return
    }

    setMenuState({ term: searchTerm, data })
  }, [searching, data])

  const onChange = useCallback(e => onSearchTermChange(e.target.value), [onSearchTermChange])

  const onFocus = useCallback(() => setIsOverlayOpen(true), [])

  const onBlur = useCallback(() => {
    meta.clearSelection()

    meta.clearItems()

    meta.reset()

    setMenuState({
      term: '',
    })

    setSearchTerm('')

    setIsOverlayOpen(false)
  }, [meta])

  const onKeyDown = useCallback(event => {
    if (event.key === 'Escape') {
      event.target.blur()
    }
  }, [])

  return (
    <Container>
      <Overlay open={isOverlayOpen} blocking={false} />
      <Input
        ref={inputRef}
        rightItem={<Icon type="search" />}
        style={{ zIndex: isOverlayOpen ? 99 : undefined }}
        placeholder={placeholder}
        {...meta.getInputProps({
          onBlur,
          onChange,
          onFocus,
          onKeyDown,
        })}
      />
      <Menu
        data={menuState.data}
        getItemProps={meta.getItemProps}
        highlightedIndex={meta.highlightedIndex}
        isOpen={meta.isOpen}
        ItemComponent={ItemComponent}
        searchTerm={menuState.term}
        {...meta.getMenuProps()}
      />
    </Container>
  )
}

export default Content
