import styled from 'styled-components'

import theme from '~/truck/theme'
import { cloudinary } from '~/truck/utils'

export const StaticMap = styled.div`
  align-items: center;
  background: url(${cloudinary.getFullUrl('v1540784993/saas/resources/placeholders/map.jpg')});
  display: flex;
  height: ${theme.spacing.cozy * 16}px;
  justify-content: center;
  padding: ${theme.spacing.comfortable}px;
  position: relative;
  width: 100%;
`

// FIXME: replace colors with indigo-500 and indigo-500/40
export const Circle = styled.div`
  background: rgb(99, 102, 241, 0.4);
  border: 1px solid #6366f1;
  border-radius: 50%;
  height: ${theme.spacing.cozy * 15}px;
  position: absolute;
  width: ${theme.spacing.cozy * 15}px;
  z-index: 1;
`

export const ContentContainer = styled.div`
  align-self: flex-start;
  background: ${theme.colors.white};
  padding: ${theme.spacing.comfortable}px;
  width: 100%;
  z-index: 1;
`
