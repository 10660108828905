import { useCallback, useEffect, useRef } from 'react'
import { find, isEmpty } from 'lodash'
import { useFlag } from 'toggled'

import { flags } from '~/utils/constants'

const getZipCode = component => component?.types?.[0] === 'postal_code'

const extractLocationInfo = geocoderResults => {
  if (isEmpty(geocoderResults)) {
    return {}
  }

  const [geocoderResult] = geocoderResults

  const zipCode = find(geocoderResult.address_components, getZipCode)

  return {
    zipCode: zipCode?.short_name,
    address: geocoderResult.formatted_address,
    addressComponents: geocoderResult.address_components,
  }
}

const useGeocoding = () => {
  const geocoderRef = useRef<any>()

  const geocodingIsEnabled = useFlag(flags.MAP_WITH_GEOCODING)

  const Geocoder = window?.google?.maps?.Geocoder

  useEffect(() => {
    if (geocodingIsEnabled && Geocoder) {
      geocoderRef.current = new window.google.maps.Geocoder()
    }
  }, [geocodingIsEnabled, Geocoder])

  /**
   * @param {number} lat
   * @param {number} lng
   * @returns Promise<{ zipCode?: string, address?: string, addressComponents?: any }>
   */
  function geocoding(lat, lng) {
    if (!geocoderRef.current) {
      return Promise.resolve({})
    }

    const getcoderRequest = {
      location: { lat, lng },
    }

    return new Promise(resolve => {
      geocoderRef.current.geocode(getcoderRequest, results => {
        resolve(extractLocationInfo(results))
      })
    })
  }

  const memorizedGeocoding = useCallback(geocoding, [])

  return geocodingIsEnabled ? memorizedGeocoding : null
}

export default useGeocoding
