import React from 'react'
import styled from 'styled-components'

import useModalManager from '~/hooks/use-modal-manager'
import Icon from '~/truck/icon'
import Label from '~/truck/label'
import theme from '~/truck/theme'
import { stack } from '~/variables'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: ${theme.spacing.relaxed}px;
  position: relative;

  > a {
    margin-bottom: ${theme.spacing.comfortable}px;
  }

  > p {
    margin-right: ${theme.spacing.relaxed}px;
  }

  > svg {
    position: absolute;
    right: 0;
    top: 0;
    z-index: ${stack.modalCloseButton};
  }
`

const Header = props => {
  const { title, closable, onBeforeClose, onAfterClose, disabled, modalId } = props

  const modalManager = useModalManager()

  const onClose = () => {
    if (onBeforeClose) {
      onBeforeClose()
    }

    modalManager.closeModal(modalId)

    if (onAfterClose) {
      onAfterClose()
    }
  }

  return (
    <Container>
      <Label as="p" $textStyle="h4Semibold">
        {title}
      </Label>
      {closable && <Icon type="close" color={disabled ? 'gray' : 'black'} onClick={disabled ? undefined : onClose} />}
    </Container>
  )
}

export default Header
