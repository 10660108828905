import React from 'react'

import TextGroup from '~/truck/text-group'

import { Circle, ContentContainer, StaticMap } from './elements'

interface AddressMapProps {
  className?: string
  style?: React.CSSProperties
  address: string
  reference: string
  apartment: string
}

function AddressMap({ className, style, address, reference, apartment }: AddressMapProps) {
  return (
    <StaticMap style={style} className={className}>
      <Circle />
      <ContentContainer>
        <TextGroup title={address} lines={[reference, apartment]} contentTextStyle="h6Regular" />
      </ContentContainer>
    </StaticMap>
  )
}

export default AddressMap
