import gql from 'graphql-tag'

const resetPasswordMutation = gql`
  mutation resetPassword($email: String!) {
    forgottenPassword(input: { email: $email }) {
      status
    }
  }
`

export default resetPasswordMutation
