import gql from 'graphql-tag'

const priceLineFragment = gql`
  fragment priceLine on PriceLine {
    label
    value
    type
    isHighlighted
    isSubtotal
  }
`

export default priceLineFragment
