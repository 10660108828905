import gql from 'graphql-tag'

const setPaymentMutation = gql`
  mutation setPayment($paymentMethodId: ID!, $configuration: JSON, $orderId: ID, $isOrderEdition: Boolean!) {
    setPayment(input: { paymentMethodId: $paymentMethodId, configuration: $configuration, orderId: $orderId }) {
      job @skip(if: $isOrderEdition) {
        id
        state
        returnValue
        failedReason
      }
      order @include(if: $isOrderEdition) {
        id
      }
    }
  }
`

export default setPaymentMutation
