import React from 'react'

import useFormatters from '~/hooks/use-formatters'
import Field from '~/truck/field'
import Label from '~/truck/label'
import theme from '~/truck/theme'

const UserInfoPageContent = props => {
  const { forBusinessOnly } = props

  const formatters = useFormatters()

  return (
    <>
      <Label as="p" $textStyle="h5Semibold">
        Datos del contacto
      </Label>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Field
          name="firstName"
          placeholder="Nombres"
          style={{
            flex: 1,
            marginRight: theme.spacing.comfortable,
          }}
        />
        <Field name="lastName" placeholder="Apellidos" style={{ flex: 1 }} />
      </div>
      <Field name="phone" placeholder="Número de celular" maxLength="9" format={formatters.numeric} />
      <Field name="email" placeholder={forBusinessOnly ? 'Correo corporativo' : 'Correo electrónico'} />
      <Field name="password" placeholder="Contraseña" type="password" />
      <Field name="confirmPassword" placeholder="Confirmar Contraseña" type="password" />
    </>
  )
}

export default UserInfoPageContent
