import React from 'react'
import renderHTML from 'react-html-parser'

import Anchor from '~/truck/anchor'
import Label from '~/truck/label'
import { orderStatuses } from '~/utils/constants'

import Wysiwyg from '../../wysiwyg'
import Heading from '../heading'
import Panel from '../panel'
import Placeholder from '../placeholder'

import { HtmlContainer, InnerContainer } from './elements'

interface PaymentProps {
  className?: string
  style?: React.CSSProperties
  isEditable?: boolean
  onEditClick?: () => void
  order: any
}

interface CardDetailProps {
  order: any
}

interface ContentProps {
  order: any
  onEmptyClick?: () => void
}

const CardDetail = (props: CardDetailProps) => {
  const { order } = props

  return (
    <InnerContainer>
      <Label as="p" $textStyle="h6Semibold">
        Nombre en tarjeta
      </Label>
      <Label as="p">{order.user.fullName}</Label>
      <Label as="p" $textStyle="h6Semibold">
        Número de tarjeta
      </Label>
      <Label as="p">{order.maskedCardNumber}</Label>
      {order.cardBrand && (
        <>
          <Label as="p" $textStyle="h6Semibold">
            Marca
          </Label>
          <Label as="p">{order.cardBrand}</Label>
        </>
      )}
    </InnerContainer>
  )
}

const Content = (props: ContentProps) => {
  const { order, onEmptyClick } = props

  const { paymentMethod } = order

  if (!paymentMethod) {
    return <Placeholder text="Seleccionar método de pago" onClick={onEmptyClick} />
  }

  return (
    <>
      <Heading iconName="payment-method" title={paymentMethod.name} />
      {paymentMethod.description && (
        <>
          <Wysiwyg />
          <HtmlContainer className="wysiwyg">{renderHTML(paymentMethod.description)}</HtmlContainer>
        </>
      )}
      {order.maskedCardNumber && <CardDetail order={order} />}
      {order.paymentExternalUrl && order.status?.slug === orderStatuses.TO_BE_PAID && (
        <Anchor href={order.paymentExternalUrl} target="_blank" style={{ textAlign: 'center' }}>
          Ver detalle de pago
        </Anchor>
      )}
    </>
  )
}

const Payment = (props: PaymentProps) => {
  const { className, style, isEditable, onEditClick, order } = props

  return (
    <Panel title="Método de pago" className={className} style={style} isEditable={isEditable} onEditClick={onEditClick}>
      <Content order={order} />
    </Panel>
  )
}

export default Payment
