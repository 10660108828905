import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { map, sumBy } from 'lodash'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import { EditModal } from '~/components'
import useModalManager from '~/hooks/use-modal-manager'
import useVendor from '~/hooks/use-vendor'
import notifier from '~/truck/notifier'
import theme from '~/truck/theme'
import { checkAllowPurchase, handleSubmissionErrors } from '~/utils'

import Cart from './cart'
import { editOrderSetLineItemsMutation } from './queries'
import SearchProductAutocomplete from './search-product-autocomplete'
import useCartUpdater from './use-cart-updater'

const StyledModal = styled(EditModal)`
  > form > div:first-of-type {
    margin-left: -${theme.spacing.comfortable}px;
    margin-right: -${theme.spacing.comfortable}px;

    ${up('lg')} {
      margin-left: -${theme.spacing.relaxed}px;
      margin-right: -${theme.spacing.relaxed}px;
    }
  }
`

const getTotal = lineItems => sumBy(lineItems, 'total')

const EditLineItemsModal = props => {
  const { order } = props

  const getInitialState = () => {
    const normalizeLineItems = lineItem => ({
      ...lineItem,
      total: lineItem.quantity * lineItem.price,
      id: lineItem?.product?.id ?? lineItem.id,
    })

    return map(order.lineItems, normalizeLineItems)
  }

  const [lineItems, setLineItems] = useState(getInitialState)

  const [setLineItemsInOrder] = useMutation(editOrderSetLineItemsMutation)

  const { closeModal } = useModalManager()

  const cartUpdater = useCartUpdater(setLineItems)

  const vendor = useVendor()

  const total = getTotal(lineItems)

  const allowPurchase = checkAllowPurchase(total, vendor.minimumOrder)

  const onSelect = selectedProduct => {
    const lineItem = cartUpdater.getLineItemFromProduct(selectedProduct)

    cartUpdater.upsertLineItem(lineItem, 1)
  }

  const onSubmit = async () => {
    const getLineItem = lineItem => {
      return {
        productId: lineItem.product.id,
        quantity: lineItem.quantity,
      }
    }

    const formattedLineItems = map(lineItems, getLineItem)

    const options = {
      variables: {
        orderId: order.id,
        lineItems: formattedLineItems,
      },
      refetchQueries: ['order'],
    }

    try {
      await setLineItemsInOrder(options)

      closeModal()

      notifier.success('Has actualizado la orden.')
    } catch (error) {
      return handleSubmissionErrors(error)
    }
  }

  return (
    <StyledModal
      title="Editar productos"
      disableSubmit={lineItems.length === 0 || !allowPurchase}
      hideContentOverflow
      onSubmit={onSubmit}
    >
      <SearchProductAutocomplete products={lineItems} onSelect={onSelect} />
      <Cart style={{ marginTop: theme.spacing.comfortable }} lineItems={lineItems} {...cartUpdater} />
    </StyledModal>
  )
}

export default EditLineItemsModal
