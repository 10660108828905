const user = {
  isLoggedIn: root => {
    const { role } = root

    return role.slug !== 'visitor'
  },
  birthdate: root => {
    const { _birthdate } = root

    if (!_birthdate) {
      return null
    }

    const date = new Date(_birthdate)

    return {
      year: date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate(),
    }
  },
}

export default user
