import * as React from 'react'
import nanoid from 'nanoid'
import { useRouter } from 'next/router'

import { analytics } from '~/analytics'
import Search from '~/truck/search'

interface SearchBarProps {
  debounced: boolean
  children: (props: any) => React.ReactNode
}

function SearchBar(props: SearchBarProps) {
  const { children, debounced } = props

  const router = useRouter()

  const [key, setKey] = React.useState()

  const mounted = React.useRef<boolean>(false)

  React.useEffect(() => {
    const onRouteChangeComplete = url => {
      if (mounted.current && !url.startsWith('/b/')) {
        setKey(nanoid())
      }
    }

    mounted.current = true

    router.events.on('routeChangeComplete', onRouteChangeComplete)

    return () => {
      mounted.current = false

      router.events.off('routeChangeComplete', onRouteChangeComplete)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChange = term => {
    if (!term) {
      return
    }

    analytics.search(term)

    const encodedTerm = encodeURIComponent(term)

    router.push(`/b/${encodedTerm}`)
  }

  return (
    <Search key={key} onChange={onChange} debounced={debounced} defaultValue={router.query.term as string}>
      {children}
    </Search>
  )
}

export default SearchBar
