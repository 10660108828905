import * as React from 'react'

import { formatSecurityCode } from '~/components/payment-methods/utils'
import Field from '~/truck/field'

interface PaymentSecurityCodeProps {
  id?: string
  name?: string
  placeholder?: string
  format?: any
  maxLength?: number
  [key: string]: any
}

const DEFAULT_MAX_LENGTH = 4

export default function PaymentSecurityCode(props: PaymentSecurityCodeProps) {
  const { id, name, placeholder, format, maxLength, ...rest } = props

  return (
    <Field
      name={name ?? 'securityCode'}
      placeholder={placeholder ?? 'Dígitos de seguridad'}
      format={format ?? formatSecurityCode}
      maxLength={maxLength ?? DEFAULT_MAX_LENGTH}
      {...rest}
    />
  )
}
