import * as React from 'react'
import styled, { css } from 'styled-components'

import { DialogFooter } from '~/components/dialog/footer'

export interface BulkDialogFooterProps {
  children: React.ReactNode
  isMobile: boolean
}

const StyledDialogFooter = styled(DialogFooter)<{ isMobile: boolean }>`
  background-color: ${props => props.theme.palette.grayscale30};
  padding: ${props => props.theme.spacing.cozy * 3}px;
  ${props =>
    props.isMobile &&
    css`
      padding: ${props => props.theme.spacing.cozy}px;
    `}
`

export function BulkDialogFooter(props: BulkDialogFooterProps) {
  const { children, isMobile } = props

  return <StyledDialogFooter isMobile={isMobile}>{children}</StyledDialogFooter>
}
