import gql from 'graphql-tag'

const cartPaymentAttemptSessionQuery = gql`
  query cartPaymentAttemptSession($paymentMethodId: ID!) {
    user {
      id
      cart {
        id
        paymentAttemptSession(paymentMethodId: $paymentMethodId) {
          sessionKey
          publicKey
          code
          extraFields
        }
      }
    }
  }
`

export default cartPaymentAttemptSessionQuery
