import styled from 'styled-components'

import theme from '~/truck/theme'
import { getTextStyle } from '~/truck/utils'

/*
 * TODO: This component is considered as atom by Design team
 * Move to correct place for future uses
 */
export const OneLineTitle = styled.span`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: ${theme.palette.grayscale80};
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  ${getTextStyle(theme.textStyles.h5Regular)};
`
