import React from 'react'
import { Field } from 'react-final-form'
import { map } from 'lodash'
import styled from 'styled-components'

import ManagementListItem from '~/components/management-list-item'
import useModalManager from '~/hooks/use-modal-manager'
import Label from '~/truck/label'
import RadioGroup from '~/truck/radio-group'
import theme from '~/truck/theme'
import type { IAddress } from '~/types/shipping'

import UpsertAddressesModal from '../upsert-addresses-modal'

const Container = styled.div`
  > *:not(:last-child) {
    margin-bottom: ${theme.spacing.comfortable}px;
  }
`

interface AddressesProps {
  data: {
    user: {
      addresses: IAddress[]
    }
  }
}

const Addresses = ({ data }: AddressesProps) => {
  const { openModal } = useModalManager()

  const { addresses } = data.user

  const renderAddress = (address: IAddress) => {
    return (
      <ManagementListItem
        defaultText="Dirección principal"
        id={address.id}
        isDefault={address.isDefault}
        isSelectable
        key={address.id}
        onEdit={() => openModal(UpsertAddressesModal, { address })}
        title={address.line}
        value={address.id}
      >
        <Label as="p">{address.locationLine}</Label>
      </ManagementListItem>
    )
  }

  return (
    <Field name="addressId">
      {fieldProps => {
        return (
          <RadioGroup {...fieldProps.input}>
            <Container>{map(addresses, renderAddress)}</Container>
          </RadioGroup>
        )
      }}
    </Field>
  )
}

export default Addresses
