import * as React from 'react'
import moment from 'moment-timezone'
import Link from 'next/link'
import useVendor from 'src/hooks/use-vendor'

import Icon from '~/truck/icon'
import Label from '~/truck/label'
import { dateFormats } from '~/utils/constants'

import OrderReviewRating from '../order/review/rating'
import OrderStatus from '../order-status'
import Price from '../price'

import { Container, FirstRow, RightSection, SecondRow, StyledAnchor } from './elements'

const OrderCard = props => {
  const { id, code, createdAt, status, total, review, needsReview } = props

  const { timezone } = useVendor()

  const route = `/mi-cuenta/pedidos/${id}`

  const formattedCreationDate = moment.tz(createdAt, timezone).format(dateFormats.dateTime)

  const showReviewRatingStatus = needsReview || !!review

  return (
    <Container>
      <FirstRow>
        <Link href={route} passHref>
          <StyledAnchor as="a" $textStyle="h5Semibold" $color="black">
            {code}
            <Icon type="caret-right" color="black" />
          </StyledAnchor>
        </Link>
        <RightSection>
          {showReviewRatingStatus && <OrderReviewRating rating={review?.rating} />}
          <OrderStatus {...status} />
        </RightSection>
      </FirstRow>
      <SecondRow>
        <Label as="p" $textStyle="h6Regular">
          {`Comprado el ${formattedCreationDate}`}
        </Label>
        <Price color="black" value={total} />
      </SecondRow>
    </Container>
  )
}

export default OrderCard
