import * as React from 'react'
import { useField, useForm } from 'react-final-form'
import { useMutation } from '@apollo/react-hooks'
import { map, reject } from 'lodash'
import styled from 'styled-components'

import { removeAddressMutation } from '~/gql/mutations'
import { userAddressesQuery } from '~/gql/queries'
import { useExpressDeliverySession, useMultiDistributorSession } from '~/hooks'
import Address from '~/truck/address'
import RadioGroup from '~/truck/radio-group'
import theme from '~/truck/theme'
import type { IAddress } from '~/types/shipping'

import EmptyState from '../address-manager/empty-state'

type Props = {
  addresses: IAddress[]
  onActionClick: () => void
}

const Container = styled.div`
  height: ${theme.spacing.cozy * 42}px;
  margin-bottom: ${theme.spacing.cozy * 2.5}px;
  overflow-y: auto;
`

const StyledEmptyState = styled(EmptyState)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: ${theme.spacing.cozy * 42}px;
  justify-content: center;
  margin: 0;
  margin-bottom: ${theme.spacing.cozy * 2.5}px;
`

const StyledAddress = styled(Address)`
  padding-left: 0;
`

const Addresses = (props: Props) => {
  const { addresses, onActionClick } = props

  const [removeAddress] = useMutation(removeAddressMutation)

  const addressField = useField('addressId')

  const form = useForm()

  const onChange = value => {
    addressField.input.onChange(value)

    const address = addresses.find(address => address.id === value)

    form.batch(() => {
      form.change('addressLine', address?.line)

      form.change('addressId', address.id)

      if (address?.coordinates) {
        form.change('coordinates.latitude', address?.coordinates?.latitude)

        form.change('coordinates.longitude', address?.coordinates?.longitude)

        form.change('hasSelectedAddress', true)
      }
    })
  }

  const { expressDeliverySession, setExpressDeliverySession } = useExpressDeliverySession()

  const { multiDistributorSession, setMultiDistributorSession } = useMultiDistributorSession()

  const onRemove = async (addressId: string) => {
    const newAddresses = reject(addresses, { id: addressId })

    const newSession = { ...(multiDistributorSession ?? expressDeliverySession) }

    if (newSession.deliveryAddress.id === addressId) {
      delete newSession.deliveryAddress

      const setSession = multiDistributorSession ? setMultiDistributorSession : setExpressDeliverySession

      setSession(newSession)
    }

    await removeAddress({
      variables: {
        addressId,
      },
      update: store => {
        const cacheData = store.readQuery({
          query: userAddressesQuery,
        })

        ;(cacheData as any).user.addresses = newAddresses

        store.writeQuery({
          query: userAddressesQuery,
          data: cacheData,
        })
      },
      optimisticResponse: {
        __typename: 'Mutation',
        removeAddress: {
          address: {
            id: addressId,
            __typename: 'Address',
          },
          __typename: 'RemoveAddressPayload',
        },
      },
    })
  }

  if (addresses.length === 0) {
    return <StyledEmptyState onCreateClick={onActionClick} actionLabel="Buscar dirección" />
  }

  return (
    <Container>
      <RadioGroup {...addressField.input} onChange={onChange}>
        {map(addresses, address => {
          return <StyledAddress key={address.id} {...address} selectable removable onRemove={onRemove} />
        })}
      </RadioGroup>
    </Container>
  )
}

export default Addresses
