import gql from 'graphql-tag'

const categoryFragment = gql`
  fragment category on Category {
    id
    encodedId
    slug
    name
    image
    description
    isFeatured
    seoTitle
    seoDescription
  }
`

export default categoryFragment
