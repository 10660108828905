import React, { createContext, useContext, useMemo } from 'react'

import OptionButton from '../option-button'

import { Container } from './elements'

const Context = createContext({})

const Option = props => {
  const { value, ...rest } = props

  const context = useContext(Context)

  const { value: contextValue, onChange } = context

  return <OptionButton selected={value === contextValue} onClick={() => onChange(value)} {...rest} />
}

const ButtonGroup = props => {
  const { style, className, value, onChange, children } = props

  const payload = useMemo(() => {
    return {
      value,
      onChange,
    }
  }, [value])

  return (
    <Context.Provider value={payload}>
      <Container style={style} className={className}>
        {children}
      </Container>
    </Context.Provider>
  )
}

ButtonGroup.Option = Option

export default ButtonGroup
