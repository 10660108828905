// @ts-check
import cookie from 'isomorphic-cookie'
import { noop } from 'lodash'

/**
 * @typedef {import("express").Request} Request
 * @typedef {import("express").Response} Response
 * @typedef {import("express").NextFunction} NextFunction
 */

const cookieNames = {
  new: 'flash-new',
  old: 'flash-old',
}

const cookieOptions = { secure: false }

/**
 * Gets data flashed to the current session
 *
 * @param {Request} [req]
 */
export const getFlashData = req => {
  return cookie.load(cookieNames.old, req)
}

/**
 * Flash data to the next session
 *
 * @param {string} key - key of the data to be flashed
 * @param {any} value - data to be flashed
 * @param {Request} [req]
 * @param {Response} [res]
 */
export const flash = (key, value, req, res) => {
  const current = cookie.load(cookieNames.new, req)

  const newData = { ...current, [key]: value }

  cookie.save(cookieNames.new, newData, cookieOptions, res)
}

/**
 * Reflashes this session data to the next one
 *
 * @param {Request} [req]
 * @param {Response} [res]
 */
export const reFlash = (req, res) => {
  const flashData = getFlashData(req)

  if (!flashData) {
    return
  }

  cookie.save(cookieNames.new, flashData, cookieOptions, res)
}

/**
 * Flash data is managed in two stages, `new` and `old`
 *
 * `new` is the data that will be passed to the next session
 * `old` is the data flashed to the current session
 *
 * It converts `new` into `old` to be used in the current session
 *
 * @param {Request} [req]
 * @param {Response} [res]
 * @param {NextFunction} [next]
 */
export const ageFlashData = (req, res, next = noop) => {
  const isNotNextPage = req && req.path.startsWith('/_next')

  if (isNotNextPage) {
    return next()
  }

  const newFlashData = cookie.load(cookieNames.new, req)

  const deleteCookieOptions = { path: '/' }

  if (newFlashData) {
    cookie.save(cookieNames.old, newFlashData, cookieOptions, res)

    cookie.remove(cookieNames.new, deleteCookieOptions, res)
  } else {
    cookie.remove(cookieNames.old, deleteCookieOptions, res)
  }

  next()
}
