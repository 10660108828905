import { each, keys } from 'lodash'

import type TruckTheme from '~/types/theme'
import type { PropKey, ThemeKey } from '~/types/theme'

import getTheme from './get-theme'

const getStyledResolver = (themeKey: keyof TruckTheme, propKey: PropKey) => {
  const styledResolver = props => {
    const theme = getTheme(props)

    return theme[themeKey][propKey]
  }

  return styledResolver
}

const makeThemeGetters = (theme: TruckTheme) => {
  const themeGetters = {}

  const themeKeys = keys(theme)

  each(themeKeys, (key: ThemeKey) => {
    themeGetters[key] = getStyledResolver.bind(null, key)
  })

  return themeGetters
}

export default makeThemeGetters
