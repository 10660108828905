import { useEffect, useRef } from 'react'

const useScrollY = (callback: (current: HTMLInputElement, scrollY: number) => void) => {
  const ref = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const handleScroll = () => {
      callback(ref.current, window.scrollY)
    }

    handleScroll()

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [callback])

  return ref
}

export default useScrollY
