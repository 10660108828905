import styled, { css, keyframes } from 'styled-components'

import { getColor } from '~/truck/utils'
import type { ThemeColor } from '~/types/theme'

const size = 12

const separation = 6

export const width = 12 * 4

const animation = keyframes`
  33% {
    transform: translateY(6px);
  }
  66% {
    transform: translateY(-6px);
  }
  100% {
    transform: translateY(0);
  }
`

function getCircleStyle(index: number) {
  const offset = index - 1

  const delay = offset * -0.5

  return css`
    div:nth-child(${index}) {
      animation: ${animation} 0.6s ${delay}s infinite ease-in-out;
      left: ${offset * (size + separation)}px;
    }
  `
}

interface ContainerProps {
  $color?: ThemeColor
}

export const CenteredContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`

export const Container = styled.div<ContainerProps>`
  align-items: center;
  display: flex;
  height: ${width}px;
  justify-content: space-between;
  min-width: ${width}px;
  width: ${width}px;

  div {
    background: ${props => getColor(props.theme, props.$color ?? 'primary')};
    border-radius: 50%;
    height: ${size}px;
    width: ${size}px;
  }

  ${[1, 2, 3].map(getCircleStyle)};
`
