import React, { useEffect, useRef } from 'react'
import clsx from 'clsx'

import { analytics } from '~/analytics'
import { useIntersectionObserver } from '~/hooks'
import { analyticsCreativeSlot } from '~/utils/constants'

interface AnnouncementBarProps {
  message: string
  link?: string
  shouldOpenInNewTab?: boolean
  foregroundColor?: string
  backgroundColor?: string
  className?: string
}

export function AnnouncementBar({
  message,
  link,
  shouldOpenInNewTab,
  foregroundColor,
  backgroundColor,
  className,
}: AnnouncementBarProps) {
  const target = shouldOpenInNewTab ? '_blank' : '_self'

  const ref = useRef(null)

  const isObserved = useIntersectionObserver(ref, 1)

  const onClick = () => analytics.selectPromotion(analyticsCreativeSlot.MAIN_ANNOUNCEMENT_BAR)

  useEffect(() => {
    if (isObserved) {
      analytics.viewPromotion(analyticsCreativeSlot.MAIN_ANNOUNCEMENT_BAR)
    }
  })

  // FIXME: normalize z-index (announcementBar) in tw
  return (
    <div
      ref={ref}
      className={clsx(
        'relative px-4 py-2 font-semibold leading-tight lg:min-h-10 lg:py-2 lg:text-lg lg:leading-tight',
        className,
      )}
      style={{
        backgroundColor: backgroundColor ?? 'var(--color-brand-secondary)',
        color: foregroundColor ?? 'var(--color-text-brand-secondary-on-bg)',
      }}
    >
      {link ? (
        <a
          className="block break-words text-center no-underline"
          target={target}
          href={link}
          rel="noopener noreferrer nofollow"
          onClick={onClick}
        >
          {message}
        </a>
      ) : (
        <p className="break-words text-center">{message}</p>
      )}
    </div>
  )
}
