import { join, map, take } from 'lodash'
import moment from 'moment-timezone'

import { dateFormats, shippingTypes } from '~/utils/constants'

const maxItemsOfLocation = 2

const getInfo = order => {
  const {
    deliveryAddress,
    store,
    shippingType,
    deliveryDate,
    contactPersonFirstName,
    contactPersonLastName,
    contactPersonDocument,
    contactPersonPhone,
  } = order

  const isExpressDelivery = shippingType.slug === shippingTypes.EXPRESS_DELIVERY

  let deliveryTime = order?.deliveryTimeRange?.time

  if (isExpressDelivery) {
    deliveryTime = deliveryDate && moment(deliveryDate).format(dateFormats.time)
  }

  const isPickupInStore = shippingType.slug === shippingTypes.PICKUP_IN_STORE

  const contactPersonInfo = {
    firstName: contactPersonFirstName ?? deliveryAddress?.contactPerson,
    lastName: contactPersonLastName,
    document: contactPersonDocument,
    phone: contactPersonPhone ?? deliveryAddress?.contactPhone,
  }

  if (isPickupInStore) {
    return {
      deliveryTime,
      deliveryTimeTitle: 'Horario de recojo',
      deliveryDateTitle: 'Fecha de recojo',
      contactPersonTitle: 'Responsable de recoger el pedido',
      deliveryDate: deliveryDate && moment(deliveryDate).format(dateFormats.dateHumanized),
      contactPersonInfo,
      reference: store.name,
      address: store.address?.line ?? null,
      title: shippingType.name,
      iconName: shippingType.icon,
    }
  }

  return {
    deliveryTime,
    reference: deliveryAddress.reference,
    apartment: deliveryAddress.apartment,
    title: shippingType.name,
    iconName: shippingType.icon,
    deliveryTimeTitle: isExpressDelivery ? 'Hora de entrega aproximada' : 'Horario de entrega',
    deliveryDateTitle: `Fecha de entrega`,
    contactPersonTitle: 'Responsable de recibir el pedido',
    contactPersonInfo,
    get address() {
      const divisions = take(deliveryAddress.location, maxItemsOfLocation)

      const addressPath = join(map(divisions, 'name'), ', ')

      return `${deliveryAddress.line}, ${addressPath}`
    },
    get deliveryDate() {
      return deliveryDate && moment(deliveryDate).format(dateFormats.dateHumanized)
    },
  }
}

export default getInfo
