import moment from 'moment-timezone'

import setTime from './set-time'

export interface IsValidTimeRangeOptions {
  deliveryDate: moment.MomentInput
  timeRange: string
  now?: moment.Moment
  preferCheckMinHour?: boolean
}

function isValidTimeRange(options: IsValidTimeRangeOptions) {
  const { deliveryDate, now = moment() } = options

  if (!now.isSame(deliveryDate, 'day')) {
    return true
  }

  const [minHour, maxHour] = options.timeRange.split(' - ')

  if (options.preferCheckMinHour) {
    const nowWithMinHour = setTime(now, minHour)

    return now.isBefore(nowWithMinHour)
  }

  const nowWithMaxHour = setTime(now, maxHour)

  return now.isBefore(nowWithMaxHour)
}

export default isValidTimeRange
