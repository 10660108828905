import gql from 'graphql-tag'

const storeFragment = gql`
  fragment store on Store {
    id
    address {
      id
      line
      coordinates {
        latitude
        longitude
      }
    }
    name
    email
    isPickable
    makesDelivery
    phone
    shopHours {
      id
      dayOfWeek
      openTime
      closeTime
    }
  }
`

export default storeFragment
