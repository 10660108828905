import React from 'react'
import clsx from 'clsx'

import { analytics } from '~/analytics'
import useVendor from '~/hooks/use-vendor'
import Icon from '~/truck/icon'
import { emailUrl, phoneUrl } from '~/utils'

interface ContactHeaderProps {
  className?: string
}

export function DesktopContactHeader({ className }: ContactHeaderProps) {
  const vendor = useVendor()

  const hasSocialMediaLinks = vendor.facebook || vendor.instagram || vendor.youtube

  return (
    <div
      className={clsx(
        'flex h-8 justify-between bg-brand-primary-dark px-4 text-sm text-brand-primary-on-bg',
        className,
      )}
    >
      <div className="flex gap-4">
        {vendor.contactEmail && (
          <a
            className="flex items-center gap-1"
            href={emailUrl(vendor.contactEmail)}
            onClick={() => analytics.contact('mail')}
          >
            <Icon className="size-5" type="mail" interactive={false} color="currentColor" />
            <span>{vendor.contactEmail}</span>
          </a>
        )}
        <a
          className="flex items-center gap-1"
          href={phoneUrl(vendor.phone)}
          onClick={() => analytics.contact('telephone')}
        >
          <Icon className="size-5" type="telephone" interactive={false} color="currentColor" />
          <span>{vendor.phone}</span>
        </a>
        {vendor.whatsapp && (
          <a
            className="flex items-center gap-1"
            href={vendor.whatsappUrl}
            target="_blank"
            rel="noopener noreferrer nofollow"
            onClick={() => analytics.contact('whatsapp')}
          >
            <Icon className="size-5" type="whatsapp" interactive={false} color="currentColor" />
            <span>{vendor.whatsapp}</span>
          </a>
        )}
      </div>
      {hasSocialMediaLinks && (
        <div className="flex items-center gap-2">
          <span>Síguenos en:</span>
          {vendor.facebook && (
            <a
              className="flex items-center"
              href={vendor.facebook}
              target="_blank"
              rel="noopener noreferrer nofollow"
              onClick={() => analytics.socialMedia('facebook')}
            >
              <Icon className="size-5" type="facebook" interactive={false} color="currentColor" />
            </a>
          )}
          {vendor.instagram && (
            <a
              className="flex items-center"
              href={vendor.instagram}
              target="_blank"
              rel="noopener noreferrer nofollow"
              onClick={() => analytics.socialMedia('instagram')}
            >
              <Icon className="size-5" type="instagram" interactive={false} color="currentColor" />
            </a>
          )}
          {vendor.youtube && (
            <a
              className="flex items-center"
              href={vendor.youtube}
              target="_blank"
              rel="noopener noreferrer nofollow"
              onClick={() => analytics.socialMedia('youtube')}
            >
              <Icon className="size-5" type="youtube" interactive={false} color="currentColor" />
            </a>
          )}
        </div>
      )}
    </div>
  )
}
