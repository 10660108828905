import React, { useContext } from 'react'
import { useForm } from 'react-final-form'
import styled from 'styled-components'

import { GoogleMapsContext } from '~/contexts'
import useGeocoding from '~/hooks/use-geocoding'
import Label from '~/truck/label'
import notifier from '~/truck/notifier'
import theme from '~/truck/theme'
import { baseUrls } from '~/utils/constants'

const Container = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  display: flex;
  padding: ${theme.spacing.cozy * 1.5}px ${theme.spacing.comfortable}px;

  &:hover {
    background-color: ${props => props.theme.colors.grayLight};
  }

  > svg {
    margin-right: ${theme.spacing.cozy}px;
  }
`

const CircularBackground = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  height: ${theme.spacing.cozy * 2.5}px;
  justify-content: center;
  margin-right: ${theme.spacing.cozy}px;
  width: ${theme.spacing.cozy * 2.5}px;
`

const CurrentLocation = () => {
  const geocoding = useGeocoding()

  const form = useForm()

  const { mapInstance } = useContext(GoogleMapsContext)

  const onGetPosition = async location => {
    const { latitude: lat, longitude: lng } = location.coords

    form.batch(() => {
      form.change('coordinates.latitude', lat)

      form.change('coordinates.longitude', lng)
    })

    if (mapInstance) {
      mapInstance.setCenter({ lat, lng })
    }

    const { address, addressComponents } = await geocoding(lat, lng)

    form.batch(() => {
      form.change('addressLine', address)

      form.change('hasSelectedAddress', true)

      form.change('addressId', null)

      form.change('addressComponents', addressComponents)
    })
  }

  const onClick = () => {
    const onError = () =>
      notifier.error('No pudimos obtener tu ubicación. Revisa tu configuración de privacidad.', { timeout: 5000 })

    navigator.geolocation.getCurrentPosition(onGetPosition, onError)
  }

  if (!geocoding) {
    return null
  }

  return (
    <Container role="button" onClick={onClick}>
      <CircularBackground>
        <img alt="icon" src={`${baseUrls.CLOUDINARY}/v1582674687/sellers/resources/near-me.svg`} />
      </CircularBackground>
      <Label as="p" $textStyle="h5Regular">
        Mi ubicación actual
      </Label>
    </Container>
  )
}

export default CurrentLocation
