import * as React from 'react'
import moment from 'moment-timezone'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import Label from '~/truck/label'
import { dateFormats } from '~/utils/constants'

const Container = styled.div`
  padding: 0 ${props => props.theme.spacing.relaxed}px ${props => props.theme.spacing.cozy * 4}px;

  > :not(:last-child) {
    margin-bottom: ${props => props.theme.spacing.cozy}px;
  }

  ${up('lg')} {
    padding: 0 ${props => props.theme.spacing.relaxed}px ${props => props.theme.spacing.cozy * 5}px;
  }
`
interface NoticeProps {
  ordersCount: number
  from: string
  to: string
  isInRange: boolean
}

const Notice = (props: NoticeProps) => {
  const { ordersCount, from, to, isInRange } = props

  const fromDateFormatted = moment(from).format(dateFormats.date)

  const toDateFormatted = moment(to).format(dateFormats.date)

  return (
    <Container>
      <Label as="p">
        Exporta tus pedidos en formato xlsx. Recuerda que el rango exportación es de 3 meses como máximo.
      </Label>
      {isInRange && (
        <Label as="p">
          Estás exportando{' '}
          <Label as="span" $textStyle="h5Semibold">
            {ordersCount} pedido{ordersCount > 1 && 's'} del {fromDateFormatted} al {toDateFormatted}.
          </Label>
        </Label>
      )}
    </Container>
  )
}

export default Notice
