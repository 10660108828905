import * as React from 'react'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import Label from '~/truck/label'
import theme from '~/truck/theme'
import { getSizeStyle } from '~/truck/utils'

export interface OnsiteNotificationProps {
  title: string
  description: string
  imageSrc: string
  className?: string
}

const StyledContainer = styled.div`
  align-items: flex-start;
  background: ${props => props.theme.colors.grayLight};
  border-radius: ${props => props.theme.dimensions.borderRadius}px;
  display: flex;
  gap: ${props => props.theme.spacing.comfortable}px;
  padding: ${props => props.theme.spacing.relaxed}px;

  > img {
    ${getSizeStyle(theme.spacing.cozy * 6)};
  }

  ${up('lg')} {
    > img {
      ${getSizeStyle(theme.spacing.cozy * 9)};
    }
  }
`

const StyledInfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

export function OnsiteNotification(props: OnsiteNotificationProps) {
  const { description, imageSrc, title, className } = props

  return (
    <StyledContainer className={className}>
      <img src={imageSrc} alt={title} />
      <StyledInfoContainer>
        <Label as="h5" $textStyle="h5Semibold">
          {title}
        </Label>
        <Label as="p" $color="grayDark">
          {description}
        </Label>
      </StyledInfoContainer>
    </StyledContainer>
  )
}
