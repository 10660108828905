import gql from 'graphql-tag'

import { orderFragment } from '../fragments'

const ordersToBePaidQuery = gql`
  query ordersToBePaid($paymentMethodId: ID!) {
    user {
      id
      ordersToBePaid(paymentMethodId: $paymentMethodId) {
        ...order
      }
    }
  }

  ${orderFragment}
`

export default ordersToBePaidQuery
