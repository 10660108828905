import { create } from 'zustand'

import type { UiStoreState, UiStoreStateData } from './ui-store-types'

const initialState: UiStoreStateData = {
  cart: {
    isOpenDrawer: false,
    isOpenLoadingOverlay: false,
  },
}

export const useUiStore = create<UiStoreState>((set, _get): UiStoreState => {
  return {
    ...initialState,
    closeCartDrawer: () => {
      set(store => {
        return {
          cart: {
            ...store.cart,
            isOpenDrawer: false,
          },
        }
      })
    },
    openCartDrawer: () => {
      set(store => {
        return {
          cart: {
            ...store.cart,
            isOpenDrawer: true,
          },
        }
      })
    },
    closeCartLoadingOverlay: () => {
      set(store => {
        return {
          cart: {
            ...store.cart,
            isOpenLoadingOverlay: false,
          },
        }
      })
    },
    openCartLoadingOverlay: () => {
      set(store => {
        return {
          cart: {
            ...store.cart,
            isOpenLoadingOverlay: true,
          },
        }
      })
    },
  }
})
