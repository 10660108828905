import gql from 'graphql-tag'

import { lineItemFragment, priceLineFragment } from '../fragments'

const sanitizeCartMutation = gql`
  mutation sanitizeCart {
    sanitizeCart(input: {}) {
      changed
      priceChangedProducts {
        id
        name
        oldPrice
        price
        isTaxExemptPrice
        oldIsTaxExemptPrice
      }
      deletedProducts {
        id
        name
      }
      notValidCoupons {
        id
        code
        errorType
      }
      outOfStockProducts {
        id
        name
      }
      notEnoughStockProducts {
        id
        name
        quantity
        oldQuantity
      }
      unpublishedProducts {
        id
        name
      }
      salesQuantityLimitsChangedProducts {
        id
        name
        minimumSalesQuantity
        maximumSalesQuantity
      }
      cart {
        id
        total
        subTotal
        lineItemsTotal
        priceLines {
          ...priceLine
        }
        lineItems {
          ...lineItem
        }
      }
    }
  }
  ${lineItemFragment}
  ${priceLineFragment}
`

export default sanitizeCartMutation
