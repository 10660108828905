import React from 'react'
import styled from 'styled-components'

import { getColor } from '~/truck/utils'
import type { ThemeColor } from '~/types/theme'

import icons from './icons.json'

interface InternalBaseIconProps {
  $size: string
  $color: ThemeColor | 'currentColor' | 'none'
  $stroke: ThemeColor | 'currentColor' | 'none'
  $interactive: boolean
}

const getCursor = ({ $interactive }: InternalBaseIconProps) => {
  if (!$interactive) {
    return null
  }

  return 'cursor: pointer;'
}

const StyledSvg = styled.svg.attrs<InternalBaseIconProps>(props => {
  const { theme, $size, $color, $stroke } = props

  const size = theme.sizes[$size]

  return {
    fill: $color === 'none' ? 'none' : getColor(theme, $color),
    stroke: $stroke && ($stroke === 'none' ? 'none' : getColor(theme, $stroke)),
    width: size,
    height: size,
  }
})`
  ${getCursor}
`

type IconProps = React.ComponentPropsWithoutRef<'svg'> & {
  type: string
  color?: ThemeColor | 'currentColor' | 'none'
  stroke?: ThemeColor | 'currentColor' | 'none'
  size?: string
  interactive?: boolean
}

// FIXME: remove icons.json file
const Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ type, color = 'white', stroke, size = 'md', interactive = true, ...iconProps }, ref) => {
    const iconSource = icons[type]

    return (
      <StyledSvg
        ref={ref}
        {...iconProps}
        $size={size}
        $color={color}
        $stroke={stroke}
        $interactive={interactive}
        viewBox={iconSource.viewBox}
        strokeWidth={iconSource.strokeWidth}
      >
        <path d={iconSource.path} />
      </StyledSvg>
    )
  },
)

export default Icon
