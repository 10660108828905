import gql from 'graphql-tag'

import { orderStatusFragment } from '../fragments'

const approveChangesInOrderMutation = gql`
  mutation approveChangesInOrder($orderId: ID!, $approved: Boolean!) {
    approveChangesInOrder(input: { orderId: $orderId, approved: $approved }) {
      order {
        id
        status {
          ...orderStatus
        }
      }
    }
  }
  ${orderStatusFragment}
`

export default approveChangesInOrderMutation
