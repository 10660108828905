import React from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import { useFlagQueryFn } from 'toggled'

import { Drawer } from '~/components'
import { userCartQuery } from '~/gql/queries'
import { flags } from '~/utils/constants'

import { useUiStore } from '../../stores/ui-store'
import { Cart } from '../cart/Cart'

export const CartDrawer = () => {
  const [getUserCartLazyQuery, userCartLazyQueryResult] = useLazyQuery(userCartQuery, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      runPromotions: true,
    },
  })

  const isOpenCartDrawer = useUiStore(store => store.cart.isOpenDrawer)

  const closeCartDrawer = useUiStore(store => store.closeCartDrawer)

  const openCartLoadingOverlay = useUiStore(store => store.openCartLoadingOverlay)

  const closeCartLoadingOverlay = useUiStore(store => store.closeCartLoadingOverlay)

  const flagQueryFn = useFlagQueryFn()

  React.useEffect(() => {
    if (isOpenCartDrawer && !flagQueryFn(flags.SHOW_CART_IN_MAIN_LAYOUT)) {
      getUserCartLazyQuery()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenCartDrawer])

  React.useEffect(() => {
    if (userCartLazyQueryResult.loading) {
      openCartLoadingOverlay()
    } else {
      closeCartLoadingOverlay()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCartLazyQueryResult.loading])

  return (
    <Drawer isOpen={isOpenCartDrawer} headerTitle="Mi carrito" onClose={closeCartDrawer}>
      {() => <Cart style={{ height: '100%' }} />}
    </Drawer>
  )
}
