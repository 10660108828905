import { isNil } from 'lodash'

import { baseUrls } from '~/utils/constants'

const defaultTransformations = 'q_auto,f_auto'

const getUploadUrl = (transformations?: string) => {
  if (!transformations) {
    return `/upload/${defaultTransformations}`
  }

  return `/upload/${transformations},${defaultTransformations}`
}

/**
 * Get a valid cloudinary url with transformations
 * note: some default transformations are always included
 *
 * @param photoUrl - Original cloudinary url
 * @param transformations - e.g: e_trim,e_make_transparent,f_auto
 */
const applyTransformations = (photoUrl?: string, transformations?: string) => {
  if (isNil(photoUrl)) {
    return null
  }

  return photoUrl.replace('/upload', getUploadUrl(transformations))
}

const getFullUrl = (resourceUrl: string) => `${baseUrls.CLOUDINARY}/${resourceUrl}`

/**
 * This method don't support transformations chain
 *
 * @param photoUrl - Original cloudinary url
 */
const removeTransformations = (photoUrl?: string) => {
  if (isNil(photoUrl)) {
    return null
  }

  return photoUrl.replace(/\/upload\/(\w+_\w+,?)+/g, '/upload')
}

export default {
  applyTransformations,
  removeTransformations,
  getFullUrl,
}
