import * as React from 'react'
import { useQuery } from '@apollo/react-hooks'
import clsx from 'clsx'
import { map } from 'lodash'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { categoriesQuery } from '~/gql/queries'
import { useSlider } from '~/hooks'
import Icon from '~/truck/icon'
import type { ICategory } from '~/types/category'

import Category from './Category'

interface SliderProps {
  className?: string
}

function Slider({ className }: SliderProps) {
  const router = useRouter()

  const carousel = useSlider()

  const { data } = useQuery(categoriesQuery)

  const dataSource: ICategory[] | null | undefined = data?.vendor?.categories

  return (
    <div className={clsx('relative overflow-hidden border-b', className)}>
      {carousel.leftArrowEnable && (
        <div
          className="absolute inset-y-0 left-0 z-[1] flex cursor-pointer items-center bg-white px-2 shadow-[2px_0px_6px_1px_rgba(168,168,168,0.2)]"
          onClick={carousel.back}
        >
          <Icon className="size-5" type="caret-left" color="primary" />
        </div>
      )}
      <nav ref={carousel.containerRef} className="flex h-full w-max overflow-hidden">
        {map(dataSource, category => {
          const { isFeatured } = category

          const path = `${category.slug}-${category.encodedId}`

          const isSelected = !router.query.subcategory && router.query.category === path

          return (
            <Link key={category.id} href={`/c/${path}`} passHref>
              <Category className="flex shrink-0 items-center px-3" isSelected={isSelected} isFeatured={isFeatured}>
                {category.name}
              </Category>
            </Link>
          )
        })}
      </nav>
      {carousel.rightArrowEnable && (
        <div
          className="absolute inset-y-0 right-0 z-[1] flex cursor-pointer items-center bg-white px-2 shadow-[-2px_0px_6px_1px_rgba(168,168,168,0.2)]"
          onClick={carousel.next}
        >
          <Icon className="size-5" type="caret-right" color="primary" />
        </div>
      )}
    </div>
  )
}

export default Slider
