import gql from 'graphql-tag'

const recoverPasswordMutation = gql`
  mutation recoverPassword($newPassword: String!, $confirmPassword: String!, $resetPasswordToken: String!) {
    recoverPassword(
      input: { newPassword: $newPassword, confirmPassword: $confirmPassword, resetPasswordToken: $resetPasswordToken }
    ) {
      status
    }
  }
`

export default recoverPasswordMutation
