import * as React from 'react'
import { useQuery } from '@apollo/react-hooks'
import * as Collapsible from '@radix-ui/react-collapsible'
import { useRouter } from 'next/router'
import { useFlag } from 'toggled'

import Action from '~/components/action'
import OrderPricing from '~/components/order/pricing'
import Price from '~/components/price'
import ShippingSelectorModal from '~/components/shipping-modal'
import { isExpressDeliveryAvailableQuery } from '~/gql/queries'
import { useSessionStorage, useUser, useVendor } from '~/hooks'
import useExpressDeliverySession from '~/hooks/use-express-delivery-session'
import useModalManager, { useSecureModalManager } from '~/hooks/use-modal-manager'
import useMultiDistributorSession from '~/hooks/use-multi-distributor-session'
import { useUiStore } from '~/modules/shopping-cart/stores/ui-store'
import { SuggestedProductsModal, useSuggestedProductsPrepare } from '~/modules/suggested-products'
import NewButton from '~/truck/new-button/NewButton'
import notifier from '~/truck/notifier'
import { checkAllowPurchase, forceReloadTo } from '~/utils'
import { flags, sessionStorageKeys, shippingTypes } from '~/utils/constants'

import { AlertContainer, Container, StyledButton, StyledPrice } from './elements'

const getPriceColor = allowProcessOrder => {
  if (allowProcessOrder) {
    return 'white'
  }

  return 'blackLight'
}

function Footer(props) {
  const { total, lineItemsTotal, subTotal, minimumOrder, onAddCouponClick, style, className, priceLines } = props

  let buttonLabel = 'Procesar compra'

  let route = '/comprar'

  const router = useRouter()

  const closeCartDrawer = useUiStore(store => store.closeCartDrawer)

  const { openModal } = useModalManager()

  const [open, setOpen] = React.useState(false)

  const [processLoading, setProcessLoading] = React.useState(false)

  const isOneClickOrder = useFlag(flags.ONE_CLICK_ORDER)

  const showCartInMainLayoutFF = useFlag(flags.SHOW_CART_IN_MAIN_LAYOUT)

  const vendor = useVendor()

  const { quotationsPermission } = useUser()

  if (isOneClickOrder) {
    buttonLabel = 'Resumen de pedido'
    route = '/compra-rapida'
  }

  const minimumOrderValuePropertyMap = {
    total: total,
    subtotal: subTotal,
    products: lineItemsTotal,
  }

  const minimumOrderProperty = vendor.settings?.cart?.minimumOrderProperty || 'products'

  const consideredMinimumValue = showCartInMainLayoutFF
    ? lineItemsTotal
    : minimumOrderValuePropertyMap[minimumOrderProperty]

  const allowPurchase = checkAllowPurchase(consideredMinimumValue, minimumOrder)

  const { expressDeliverySession } = useExpressDeliverySession()

  const { multiDistributorSession } = useMultiDistributorSession()

  const hasSelectedExpressDelivery = expressDeliverySession?.shippingType?.slug === shippingTypes.EXPRESS_DELIVERY

  const hasExpressDeliveryFF = useFlag(flags.EXPRESS_DELIVERY)

  const isExpressDeliveryActive = hasExpressDeliveryFF && hasSelectedExpressDelivery

  const hasShippingBeforeBuyFF = useFlag(flags.SHIPPING_BEFORE_BUY)

  const hasHidePricesFF = useFlag(flags.HIDE_PRICES)

  const { data, loading } = useQuery(isExpressDeliveryAvailableQuery, {
    fetchPolicy: 'network-only',
    skip: !isExpressDeliveryActive,
  })

  const isExpressDeliveryAvailable = data?.vendor?.isExpressDeliveryAvailable

  const suggestedProductsModalFF = useFlag(flags.SUGGESTED_PRODUCTS_MODAL)

  const { open: openSuggestedProductsModal } = useSecureModalManager(SuggestedProductsModal)

  const suggestedProductsPrepare = useSuggestedProductsPrepare()

  const [suggestedModalDisable, setSuggestedModalDisable] = useSessionStorage(
    sessionStorageKeys.SUGGESTED_PRODUCT_MODAL_DISABLE,
  )

  const { id: userId } = useUser()

  const isDisableSuggestedModal = suggestedModalDisable === userId

  if (suggestedProductsModalFF && !isDisableSuggestedModal) {
    buttonLabel = 'Continuar'
  }

  const sessionNotFound =
    (hasExpressDeliveryFF && !expressDeliverySession) || (!hasExpressDeliveryFF && !multiDistributorSession)

  const handleClick = async () => {
    if (isExpressDeliveryActive && !isExpressDeliveryAvailable) {
      notifier.error('El envío express no está disponible, cambia tu tipo de envío.')

      return
    }

    const deliveryAddress = expressDeliverySession?.deliveryAddress ?? multiDistributorSession?.deliveryAddress

    const store = expressDeliverySession?.store ?? multiDistributorSession?.store

    if (hasShippingBeforeBuyFF && (sessionNotFound || (!deliveryAddress && !store))) {
      notifier.error('Necesitas escoger un método de envío y dirección antes de procesar la compra.')

      openModal(ShippingSelectorModal)

      closeCartDrawer()

      return
    }

    if (suggestedProductsModalFF && !isDisableSuggestedModal) {
      setProcessLoading(true)

      const canOpenModal = await suggestedProductsPrepare()

      if (canOpenModal) {
        setSuggestedModalDisable(userId)

        openSuggestedProductsModal()

        setProcessLoading(false)

        return
      }
    }

    forceReloadTo(route)
  }

  const isButtonDisabled = (Number(minimumOrder) === 0 && !allowPurchase) || loading

  const hasPriceQuotationNavigationFF = useFlag(flags.PRICE_QUOTATION_NAVIGATION)

  const showQuotationsButton = quotationsPermission && hasPriceQuotationNavigationFF

  return (
    <Collapsible.Root open={open} onOpenChange={setOpen} style={{ flexShrink: 0 }}>
      {allowPurchase && !showCartInMainLayoutFF && (
        <Collapsible.Content>
          <OrderPricing
            className="border-t border-zinc-200 pt-2"
            title="Precio detallado"
            lines={priceLines}
            showTheBestRewardDescription
          />
        </Collapsible.Content>
      )}
      <Container style={style} className={className} needsPadding={allowPurchase || Number(minimumOrder) === 0}>
        {allowPurchase && (
          <div style={{ display: 'flex' }}>
            {!showCartInMainLayoutFF && !hasHidePricesFF && (
              <Collapsible.Trigger asChild>
                <Action $color="info">{open ? 'Ocultar precio detallado' : 'Ver precio detallado'}</Action>
              </Collapsible.Trigger>
            )}
            <Action $color="info" onClick={onAddCouponClick} style={{ marginLeft: 'auto' }}>
              Agregar cupón
            </Action>
          </div>
        )}
        {!allowPurchase && minimumOrder > 0 ? (
          <AlertContainer>
            <span className="text-base">
              ¡Solo te faltan{' '}
              <StyledPrice
                value={minimumOrder - consideredMinimumValue}
                textStyle="h5Bold"
                symbolTextStyle="h5Bold"
                color="black"
              />{' '}
              para llegar al mínimo de compra!
            </span>
          </AlertContainer>
        ) : (
          <div className="flex flex-col gap-2">
            {showQuotationsButton && (
              <NewButton mode="ghost" disabled={isButtonDisabled} onClick={() => router.push('/cotizar')}>
                Cotizar
              </NewButton>
            )}
            <StyledButton disabled={isButtonDisabled} onClick={handleClick} loading={processLoading}>
              {buttonLabel}
              <Price
                value={showCartInMainLayoutFF ? lineItemsTotal : total}
                textStyle="h5Semibold"
                symbolTextStyle="h5Semibold"
                color={getPriceColor(allowPurchase)}
              />
            </StyledButton>
          </div>
        )}
      </Container>
    </Collapsible.Root>
  )
}

export default Footer
