import type { IVendor } from '~/types/vendors'

export interface GetMetaInfoOption {
  vendor: IVendor
  asPath: string
}

export interface GetMetaInfoResult {
  title: string
  image: string
  description: string
  url: string
  siteName: string
}

export default function getMetaInfo(options: GetMetaInfoOption) {
  const { vendor, asPath } = options

  if (!vendor) return {} as GetMetaInfoResult

  return {
    title: vendor.name,
    image: vendor.ogImage,
    description: vendor.seoDescription ?? `Tienda en línea de ${vendor.name}`,
    url: `http://${vendor.manufacturer.endpoint}${asPath}`,
    siteName: vendor.name,
  }
}
