import { headerHeights } from '~/variables'

import useScrollY from './use-scroll-y'
import useVendor from './use-vendor'

interface MainDesktopHeightProps {
  extra?: number
}

const useMainDesktopHeight = ({ extra = 0 }: MainDesktopHeightProps = {}) => {
  const vendor = useVendor()

  const hasAnnouncement = vendor?.settings?.announcement

  const ref = useScrollY((current, scrollY) => {
    if (scrollY <= headerHeights.announcementBar) {
      current.style.maxHeight = `calc(100vh  - ${headerHeights.getMainDesktop()}px - ${extra}px - ${
        headerHeights.announcementBar - scrollY
      }px)`
    }

    if (scrollY > headerHeights.announcementBar) {
      current.style.maxHeight = `calc(100vh  - ${headerHeights.getMainDesktop()}px - ${extra}px)`
    }
  })

  return [ref, hasAnnouncement]
}

export default useMainDesktopHeight
