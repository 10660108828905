import React from 'react'
import { map } from 'lodash'
import Carousel from 'nuka-carousel'

import theme from '~/truck/theme'

class OwnCarousel extends React.PureComponent {
  state = {
    mounted: false,
  }

  componentDidMount() {
    this.setState({ mounted: true })
  }

  render() {
    const { mounted } = this.state

    const { dataSource = [], renderer, style, className, ...rest } = this.props

    if (!mounted || dataSource.length === 1) {
      const [item = null] = dataSource

      return item && renderer(item)
    }

    return (
      <Carousel
        pauseOnHover
        wrapAround
        renderCenterLeftControls={null}
        renderCenterRightControls={null}
        autoplay
        style={style}
        className={className}
        defaultControlsConfig={{
          pagingDotsStyle: {
            fill: 'white',
            margin: `0 ${theme.spacing.cozy}px`,
          },
        }}
        {...rest}
      >
        {map(dataSource, renderer)}
      </Carousel>
    )
  }
}

export default OwnCarousel
