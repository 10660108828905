import gql from 'graphql-tag'

const documentFragment = gql`
  fragment document on Document {
    id
    documentNumber
    fiscalName
    fiscalAddress
    lineOfBusiness
    isDefault
    documentType {
      id
      name
      slug
    }
  }
`

export default documentFragment
