import gql from 'graphql-tag'

import { pageInfoFragment, storeFragment } from '../fragments'

const storesQuery = gql`
  query stores($limit: Int, $page: Int, $showAll: Boolean) {
    vendor {
      id
      stores(limit: $limit, page: $page, showAll: $showAll) {
        items {
          ...store
        }
        pageInfo {
          ...pageInfo
        }
      }
    }
  }
  ${storeFragment}
  ${pageInfoFragment}
`

export default storesQuery
