import * as React from 'react'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import Label from '~/truck/label'
import theme from '~/truck/theme'
import { getTextStyle } from '~/truck/utils'

export interface InfoErrorBoxProps {
  title: string
  imageSrc: string
  imageAlt?: string
  description: string | React.ReactElement
  action?: React.ReactElement
  [key: string]: any
}

const StyledContent = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  max-width: ${props => props.theme.spacing.cozy * 70.125}px;
  padding-bottom: ${props => props.theme.spacing.relaxed}px;
  padding-top: ${props => props.theme.spacing.relaxed}px;
  width: 100%;

  & > :not(:last-child) {
    margin-bottom: ${props => props.theme.spacing.relaxed}px;
  }
`

const StyledTitle = styled(Label)`
  ${up('lg')} {
    ${getTextStyle(theme.textStyles.h1Semibold)}
  }
`

const StyledImage = styled.img`
  max-width: ${props => props.theme.spacing.cozy * 36.89}px;

  ${up('lg')} {
    max-width: ${props => props.theme.spacing.cozy * 53.42625}px;
  }
`

export const InfoErrorBox = (props: InfoErrorBoxProps) => {
  const { imageSrc, description, title, action, imageAlt, ...rest } = props

  return (
    <StyledContent {...rest}>
      <StyledImage src={imageSrc} alt={imageAlt} />
      <StyledTitle $color="primary" $textStyle="h3Bold">
        {title}
      </StyledTitle>
      <Label as="p" $textAlign="center">
        {description}
      </Label>
      {action}
    </StyledContent>
  )
}
