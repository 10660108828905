import moment from 'moment-timezone'

const isTodayOrTomorrow = (deliveryDate: moment.MomentInput, timezone: string) => {
  const today = moment().tz(timezone)

  const tomorrow = today.clone().add(1, 'day')

  const isToday = moment(deliveryDate).isSame(today, 'days')

  const isTomorrow = moment(deliveryDate).isSame(tomorrow, 'days')

  return isToday || isTomorrow
}

export default isTodayOrTomorrow
