import { useMemo } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useFlag } from 'toggled'

import { userCartQuery } from '~/gql/queries'
import type { ILineItem } from '~/types/line-items'
import { flags } from '~/utils/constants'

export const useCart = () => {
  const showCartInMainLayoutFF = useFlag(flags.SHOW_CART_IN_MAIN_LAYOUT)

  const { data, loading } = useQuery(userCartQuery, {
    variables: {
      runPromotions: !showCartInMainLayoutFF,
    },
  })

  const { cart } = useMemo(() => data?.user ?? {}, [data?.user])

  const lineItems = useMemo<ILineItem[]>(() => {
    return cart?.lineItems || []
  }, [cart])

  return {
    loading: loading,
    cart,
    lineItems,
  }
}
