/**
 * Force a page reload to some location or current by default
 */
const forceReloadTo = (location?: Location | string) => {
  if (location) {
    window.location = location as Location
  } else {
    window.location.reload()
  }
}

export default forceReloadTo
