import React from 'react'
import { isNil } from 'lodash'
import styled from 'styled-components'

import Button from '~/truck/button'
import Icon from '~/truck/icon'
import theme from '~/truck/theme'

interface AddToFavoritesButtonDesktopProps {
  favoriteSince?: moment.MomentInput
  onClick: () => void
  className?: string
}

const StyledButton = styled(Button)`
  border: none;
  height: fit-content;
  justify-content: flex-start;
  margin-top: ${theme.spacing.cozy}px;
  padding: 0;
  text-align: left;

  &:hover svg {
    fill: rgba(255, 128, 152, 1);
  }
`

function AddToFavoritesButtonDesktop(props: AddToFavoritesButtonDesktopProps) {
  const { favoriteSince, onClick, className } = props

  const isFavorite = !isNil(favoriteSince)

  const iconType = isFavorite ? 'heart' : 'heart-outline'

  return (
    <StyledButton onClick={onClick} className={className} plain color="black">
      <Icon type={iconType} color="error" />
      <span>{isFavorite ? `Agregado a favoritos` : 'Agregar a favoritos'}</span>
    </StyledButton>
  )
}

export default AddToFavoritesButtonDesktop
