import gql from 'graphql-tag'

import { checkoutFragment } from '../fragments'

const setShippingMutation = gql`
  mutation setShipping(
    $deliveryDate: Date
    $storeId: ID
    $deliveryAddressId: ID
    $deliveryTimeRangeId: ID
    $shippingTypeId: ID!
    $orderId: ID
    $shipmentQuoteId: ID
    $shipmentFareId: ID
    $contactPerson: ShippingContactPersonInput
  ) {
    setShipping(
      input: {
        deliveryDate: $deliveryDate
        storeId: $storeId
        deliveryAddressId: $deliveryAddressId
        deliveryTimeRangeId: $deliveryTimeRangeId
        shippingTypeId: $shippingTypeId
        orderId: $orderId
        shipmentQuoteId: $shipmentQuoteId
        shipmentFareId: $shipmentFareId
        contactPerson: $contactPerson
      }
    ) {
      order {
        ...checkout
      }
    }
  }
  ${checkoutFragment}
`

export default setShippingMutation
