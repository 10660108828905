import React, { useMemo } from 'react'

import { analytics } from '~/analytics'
import { useIsMobile } from '~/hooks'
import type { SecureModalManagerProps } from '~/hooks/use-modal-manager'
import notifier from '~/truck/notifier'

import { useInitialSuggestedProducts } from '../../controllers'
import { DebounceHandlerProvider } from '../../providers'

import { SuggestedProductsModalView } from './SuggestedProductsModal.view'

type SuggestedProductsModalProps = SecureModalManagerProps

export const SuggestedProductsModal = (props: SuggestedProductsModalProps) => {
  const { onClose } = props

  const { products, initialProductsGroup, lineItems, loading } = useInitialSuggestedProducts()

  const lineItemsAddeds = useMemo(() => {
    if (!initialProductsGroup) {
      return []
    }

    return lineItems.reduce((acc, curr) => (initialProductsGroup[curr.product.id] ? acc : [...acc, curr]), [])
  }, [initialProductsGroup, lineItems])

  const isMobile = useIsMobile()

  const handlerAfterClose = () => {
    const productsWereAdded = lineItemsAddeds.length > 0

    if (productsWereAdded) {
      const total = lineItemsAddeds.reduce((acc, lineItem) => acc + lineItem.quantity, 0)

      notifier.success(`Se ha${total > 1 ? 'n' : ''} añadido ${total} producto${total > 1 ? 's' : ''} a tu carrito.`)
    }

    analytics.closeSuggestedProducts(productsWereAdded)
  }

  const handlerClose = () => {
    handlerAfterClose()

    onClose()
  }

  return (
    <DebounceHandlerProvider>
      <SuggestedProductsModalView
        productsAdded={lineItemsAddeds.length > 0}
        products={products}
        onClose={handlerClose}
        onAfterClose={handlerAfterClose}
        loading={loading}
        isMobile={isMobile}
      />
    </DebounceHandlerProvider>
  )
}
