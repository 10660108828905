import React, { useEffect } from 'react'
import { useForm } from 'react-final-form'
import { useQuery } from '@apollo/react-hooks'
import { find } from 'lodash'

import { documentTypesQuery } from '~/gql/queries'
import useFormatters from '~/hooks/use-formatters'
import Field from '~/truck/field'
import Label from '~/truck/label'
import { documentTypes } from '~/utils/constants'

const BusinessDocumentPageContent = () => {
  const formatters = useFormatters()

  const form = useForm()

  const { data } = useQuery(documentTypesQuery)

  const billDocumentType = find(data?.vendor.documentTypes, {
    slug: documentTypes.BILL,
  })

  useEffect(() => {
    if (billDocumentType?.id) {
      form.change('documentTypeIdWithSlug', `${billDocumentType.id}-${documentTypes.BILL}`)
    }
  }, [billDocumentType?.id, form])

  return (
    <>
      <Label as="p" $textStyle="h5Semibold">
        Dirigido a
      </Label>
      <Field name="documentNumber" placeholder="RUC" maxLength="11" format={formatters.numeric} />
    </>
  )
}

export default BusinessDocumentPageContent
