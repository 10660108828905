import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import { useMutation, useQuery } from '@apollo/react-hooks'
import createDecorator from 'final-form-focus'
import { isEmpty, omit, pick } from 'lodash'
import styled from 'styled-components'
import { useFlag } from 'toggled'

import Action from '~/components/action'
import ShippingModal from '~/components/shipping-modal'
import { upsertAddressMutation } from '~/gql/mutations'
import { coverageQuery } from '~/gql/queries'
import { useBuyingInStore } from '~/hooks'
import useExpressDeliverySession from '~/hooks/use-express-delivery-session'
import { getDivisionLevelsFromLocationSelector } from '~/hooks/use-location-selector'
import useModalManager from '~/hooks/use-modal-manager'
import useMultiDistributorSession from '~/hooks/use-multi-distributor-session'
import useVendor from '~/hooks/use-vendor'
import Address from '~/truck/address'
import notifier from '~/truck/notifier'
import theme from '~/truck/theme'
import { getCoverageTree, getValidator, handleSubmissionErrors, storage } from '~/utils'
import { flags, storageKeys } from '~/utils/constants'
import constraints from '~/utils/constraints'
import getGoogleMapsDivisionLevels from '~/utils/get-division-levels/google-maps'

import AddressForm from '../address-form'
import LegacyAddressForm from '../address-form/legacy'
import Addresses from '../addresses'
import { FormScrollToError } from '../form-scroll-to-error'
import Title from '../title'

import EmptyState from './empty-state'

// TODO: optimistic update for address creation

const StyledTitle = styled(Title)`
  margin-bottom: ${theme.spacing.cozy}px;
  margin-top: ${theme.spacing.comfortable}px;
`

const getCoverage = data => {
  if (data?.vendor) {
    return data.vendor?.coverage
  }

  return data?.buyingInStore?.coverage
}

const focusOnError = createDecorator()

const AddressManager = props => {
  const {
    afterCreate,
    addresses,
    disableRemoveAction,
    disableAddAction,
    openCreateMode,
    onSelect,
    onRemove,
    isCreating,
    closeCreateMode,
    shippingTypeSlug,
    deliveryAddress,
  } = props

  const [upsertAddress] = useMutation(upsertAddressMutation)

  const hasTerritoryGeofences = useFlag(flags.TERRITORY_GEOFENCES)

  const hasStoreNavigation = useFlag(flags.STORE_NAVIGATION)

  const hasContactPerson = useFlag(flags.CONTACT_PERSON)

  const vendor = useVendor()

  const buyingInStore = useBuyingInStore()

  const isBuyingInStore = !!buyingInStore || hasStoreNavigation

  const { data, loading } = useQuery(coverageQuery, {
    variables: {
      isBuyingInStore,
    },
  })

  const allLocalities = React.useMemo(() => getCoverage(data) ?? [], [data])

  const coverageTree = React.useMemo(() => getCoverageTree(allLocalities), [allLocalities])

  const { multiDistributorSession, setMultiDistributorSession } = useMultiDistributorSession()

  const { expressDeliverySession, setExpressDeliverySession } = useExpressDeliverySession()

  const { openModal } = useModalManager()

  const shippingSettings = {
    addressLine: true,
    ...vendor.manufacturer.settings.shipping,
    ...vendor.settings.shipping,
  }

  const hasMapInCreation = useFlag(flags.MAP_IN_ADDRESS_CREATION)

  const hasNewAddressCreationFlow = useFlag(flags.NEW_ADDRESS_CREATION_FLOW)

  const onValidate = (values, form) => {
    const constraintsToGrab = []

    Object.keys(shippingSettings).forEach(shippingKey => {
      if (shippingSettings[shippingKey]) {
        constraintsToGrab.push(shippingKey)
      }
    })

    if (!hasTerritoryGeofences || shippingSettings.locationSelector) {
      constraintsToGrab.push('localityId')
    }

    if (hasMapInCreation && !values.coordinates && values.addressLine) {
      form.change('showNoAddressFound', true)

      constraintsToGrab.push('coordinates')

      notifier.error('Selecciona una dirección de las sugerencias o ubícala en el mapa')
    }

    if (!hasContactPerson) {
      constraintsToGrab.push('contactPerson')

      constraintsToGrab.push('contactPhone')
    }

    const formConstraints = pick(constraints, constraintsToGrab)

    formConstraints.reference = {
      ...formConstraints.reference,
      length: {
        maximum: 80,
        tooLong: 'La referencia puede tener %{count} caracteres como máximo',
      },
    }

    const validator = getValidator(formConstraints)

    return validator(values)
  }

  const onSubmit = async (values, form) => {
    const errors = onValidate(values, form)

    if (!isEmpty(errors)) {
      return errors
    }

    if (!values.addressComponents) {
      values.addressComponents = storage.get(storageKeys.ADDRESS_COMPONENTS)
    }

    const divisionLevels = values.localityId
      ? getDivisionLevelsFromLocationSelector(allLocalities, values)
      : getGoogleMapsDivisionLevels(values.addressComponents)

    const mutationVariables = { ...omit(values, 'addressComponents'), ...divisionLevels }

    if (!hasMapInCreation) {
      delete mutationVariables.coordinates
    }

    if (mutationVariables.coordinates) {
      const coordinatesFields = ['latitude', 'longitude']

      mutationVariables.coordinates = pick(mutationVariables.coordinates, coordinatesFields)
    }

    const mutationOptions = {
      variables: mutationVariables,
    }

    try {
      const response = await upsertAddress(mutationOptions)

      await afterCreate(response.data.upsertAddress.address)

      closeCreateMode()
    } catch (error) {
      return handleSubmissionErrors(error)
    }
  }

  const hasIncompleteAddress = deliveryAddress && !deliveryAddress.id

  const customFieldInputsMap = {
    coordinates: 'googleAddressLine',
    localityId: 'divisionLevel1',
  }

  if ((isCreating || hasIncompleteAddress) && !disableAddAction) {
    const AddressFormComponent = hasNewAddressCreationFlow ? AddressForm : LegacyAddressForm

    const initialValues = {
      addressLine: deliveryAddress?.line,
      coordinates: deliveryAddress?.coordinates,
      hasSelectedAddress: Boolean(deliveryAddress),
    }

    return (
      <FinalForm initialValues={initialValues} onSubmit={onSubmit} validate={() => null} decorators={[focusOnError]}>
        {formProps => {
          const { handleSubmit } = formProps

          return (
            <>
              <FormScrollToError customFields={customFieldInputsMap} addHeaderOffset={true} />
              {hasIncompleteAddress && <StyledTitle style={{ marginBottom: 0 }}>Completa tu dirección</StyledTitle>}
              {!loading && (
                <AddressFormComponent
                  withActions
                  cancellable={!hasIncompleteAddress}
                  onCreateClick={handleSubmit}
                  onCancelClick={closeCreateMode}
                  shippingTypeSlug={shippingTypeSlug}
                  shippingSettings={shippingSettings}
                  coverageTree={coverageTree}
                />
              )}
            </>
          )
        }}
      </FinalForm>
    )
  }

  if (deliveryAddress?.id) {
    return (
      <>
        <>
          <StyledTitle>Dirección seleccionada</StyledTitle>
          <Address
            {...deliveryAddress}
            removable={!disableRemoveAction}
            selectable={false}
            onRemove={id => {
              const session = multiDistributorSession ?? expressDeliverySession

              const newSession = {
                ...session,
                deliveryAddress: {
                  line: deliveryAddress.line,
                  coordinates: deliveryAddress.coordinates,
                },
              }

              if (session.deliveryAddress.id === id) {
                delete newSession.deliveryAddress
              }

              const setSession = multiDistributorSession ? setMultiDistributorSession : setExpressDeliverySession

              setSession(newSession)

              onRemove(id)
            }}
          />
          <Action
            style={{
              paddingLeft: theme.spacing.comfortable,
              paddingBottom: theme.spacing.relaxed,
            }}
            $color="info"
            onClick={() => openModal(ShippingModal)}
          >
            Cambiar
          </Action>
        </>
      </>
    )
  }

  if (addresses.length === 0) {
    return <EmptyState onCreateClick={openCreateMode} disableAddAction={disableAddAction} />
  }

  return (
    <>
      <StyledTitle>Selecciona una dirección</StyledTitle>
      <Addresses
        disableRemoveAction={disableRemoveAction}
        disableAddAction={disableAddAction}
        dataSource={addresses}
        onRemove={onRemove}
        onCreateClick={openCreateMode}
        onSelect={onSelect}
      />
    </>
  )
}

export default AddressManager
