import gql from 'graphql-tag'

import { userFragment } from '../fragments'

const userPopupQuery = gql`
  query userPopupQuery($orderStatusSlug: String!, $skipOrderReviewReminder: Boolean!) {
    user {
      ...user
      needsReminderForOrderReview @skip(if: $skipOrderReviewReminder) {
        status
        orderId
      }
      orders(orderStatusSlug: $orderStatusSlug) {
        items {
          id
          status {
            id
            slug
          }
        }
      }
    }
  }
  ${userFragment}
`

export default userPopupQuery
