import gql from 'graphql-tag'

import { pageInfoFragment, productFragment } from '../fragments'

const catalogSearchQuery = gql`
  query catalogSearch($limit: Int, $page: Int, $term: String, $sortBy: String, $filters: [String]) {
    catalogSearch(limit: $limit, page: $page, term: $term, sortBy: $sortBy, filters: $filters) {
      items {
        ...product
      }
      pageInfo {
        ...pageInfo
      }
      facets
    }
  }
  ${pageInfoFragment}
  ${productFragment}
`

export default catalogSearchQuery
