import React from 'react'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import Label from '~/truck/label'
import theme from '~/truck/theme'

import CircularProgressBar from '../../circular-progress-bar'

export const CircularBarContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  top: -${theme.spacing.cozy * 11}px;
  ${up('lg')} {
    flex-direction: column;
  }
`

const CircularBar = props => {
  const { steps, step, title, diameter, strokeWidth } = props

  return (
    <CircularBarContainer>
      <CircularProgressBar steps={steps} step={step} diameter={diameter} strokeWidth={strokeWidth} />
      <Label as="p" $textStyle="h4Semibold">
        {title}
      </Label>
    </CircularBarContainer>
  )
}

export default CircularBar
