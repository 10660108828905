import * as React from 'react'
import { map } from 'lodash'
import styled from 'styled-components'

import Card from '~/truck/card'

import { PriceLine, PriceLineProps } from './price-line'

export interface PricingProps {
  lines: PriceLineProps[]
  style?: React.CSSProperties
  className?: string
}

const StyledContainer = styled(Card)`
  background-color: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing.comfortable}px;

  > :not(:last-child) {
    margin-bottom: ${props => props.theme.spacing.cozy}px;
  }
`

function Pricing(props: PricingProps) {
  const { style, className, lines } = props

  return (
    <StyledContainer style={style} className={className}>
      {map(lines, (line, index) => (
        <PriceLine key={`price-line-${index}`} lineitem={line} />
      ))}
    </StyledContainer>
  )
}

export default Pricing
