import React from 'react'
import { useRouter } from 'next/router'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import { useFlag } from 'toggled'

import { analytics } from '~/analytics'
import { useCartProps, useFavoriteProduct } from '~/hooks'
import useVendor from '~/hooks/use-vendor'
import Label from '~/truck/label'
import theme from '~/truck/theme'
import { getTextStyle } from '~/truck/utils'
import { cloudinary } from '~/utils'
import { analyticsItemListContexts, flags, transformations } from '~/utils/constants'

import Media from '../media'
import Product from '../product'

const StyledLabel = styled(Label)`
  background-color: ${theme.colors.error};
  padding: ${theme.spacing.compact}px;
  text-align: center;
  ${getTextStyle('h6Semibold')}

  > span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    ${getTextStyle('h6Bold')}

    ${up('lg')} {
      ${getTextStyle('h5Bold')}
    }
  }

  ${up('lg')} {
    padding: ${theme.spacing.cozy}px ${theme.spacing.comfortable}px;
    text-transform: uppercase;
    ${getTextStyle('h6Bold')}
  }
`

const LabelContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  transform: rotate(-11.21deg);
  width: 100%;
`

const CustomLabel = () => (
  <LabelContainer>
    <StyledLabel as="p" $color="white">
      Agotado
      <br />
      <Label as="span" $color="white">
        En reposición
      </Label>
    </StyledLabel>
  </LabelContainer>
)

const selectItemUrlMap = {
  '': analyticsItemListContexts.FEATURED_PRODUCTS,
  c: analyticsItemListContexts.CATEGORY_PAGE,
  p: analyticsItemListContexts.RELATED_PRODUCTS,
  b: analyticsItemListContexts.SEARCH_PAGE,
  'mi-cuenta': analyticsItemListContexts.FAVORITE_PRODUCTS,
}

const getOptimizedPhotos = originalUrl => {
  return {
    desktop: cloudinary.applyTransformations(originalUrl, transformations.PRODUCT),
    mobile: cloudinary.applyTransformations(originalUrl, transformations.TINY_PRODUCT),
  }
}

const OwnProduct = props => {
  const { defaultPhoto, pricing, href, onClick } = props

  const { counterProps, returnedStock: stock, onRemove } = useCartProps({ ...props })

  const allProps = { ...props, counterProps, stock, onRemove }

  const router = useRouter()

  const isNotAvailable = useFlag(flags.NOT_AVAILABLE)

  const vendor = useVendor()

  const { onToggleFavorite } = useFavoriteProduct(props)

  const photos = getOptimizedPhotos(defaultPhoto)

  const triggerAnalyticsSelectItemEvent = () => {
    const currentPath = window.location.pathname.split('/')[1]

    const currentContext = selectItemUrlMap[currentPath]

    analytics.selectItem(allProps, currentContext)
  }

  const handleOnClick = e => {
    triggerAnalyticsSelectItemEvent()

    if (onClick) {
      return onClick(e)
    }

    e.preventDefault()

    return router.push(href).then(() => window.scrollTo(0, 0))
  }

  const hidePresentationInCatalog = vendor.settings?.products?.hidePresentationInCatalog

  const newProps = {
    ...allProps,
    ...pricing,
    hidePresentationInCatalog,
    onClick: handleOnClick,
    hideCounter: isNotAvailable,
    href,
    outOfStockLabel: vendor.settings.alternateOutOfStockStyle && CustomLabel,
    onFavorite: onToggleFavorite,
  }

  return (
    <Media
      desktop={<Product.Desktop {...newProps} photo={photos.desktop} />}
      mobile={<Product.Mobile {...newProps} photo={photos.mobile} withCounter allowTaxLabel />}
    />
  )
}

export default OwnProduct
