import styled, { css, StyledProps } from 'styled-components'

import theme from '~/truck/theme'
import { getColor } from '~/truck/utils'
import type { ThemeColor } from '~/types/theme'

function getCheckedColor({ theme, color = 'secondary' }: StyledProps<{ color?: ThemeColor }>) {
  return getColor(theme, color)
}

export const Input = styled.input.attrs(props => {
  const { type } = props

  return {
    type: type || 'radio',
  }
})`
  appearance: none;
  background-clip: content-box;
  border-radius: 50%;
  border: 1px solid ${theme.colors.gray};
  display: inline-block;
  height: ${theme.spacing.comfortable}px;
  outline: none;
  padding: 3px; /* visual perception */
  transition:
    border-color 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  vertical-align: middle;
  width: ${theme.spacing.comfortable}px;

  :disabled {
    border-color: ${theme.colors.gray};

    :checked {
      background-color: ${theme.colors.gray};
      border-color: ${theme.colors.gray};
    }
  }

  :not(:disabled):hover {
    border-color: ${getCheckedColor};
  }

  :checked {
    background-color: ${getCheckedColor};
    border-color: ${getCheckedColor};
  }
`

interface ContainerProps {
  $hasText?: boolean
  $disabled?: boolean
}

function getChildrenAsTextStyle({ $hasText }: ContainerProps) {
  if ($hasText) {
    return css`
      > label {
        padding-left: ${theme.spacing.cozy}px;
      }
    `
  }

  return css`
    background-color: ${theme.colors.white};
    padding-left: ${theme.spacing.comfortable}px;

    > label {
      flex-grow: 1;
    }
  `
}

function getCursor({ $disabled }: ContainerProps) {
  if ($disabled) {
    return 'not-allowed'
  }

  return 'pointer'
}

export const Container = styled.label<ContainerProps>`
  align-items: center;
  display: flex;

  &,
  * {
    cursor: ${getCursor};
  }

  > input {
    flex-shrink: 0;

    &:disabled + label {
      color: ${theme.colors.blackLight};
      ${props => !props.$hasText && 'opacity: 0.3;'}
    }
  }

  :hover {
    > input:not(:disabled) {
      border-color: ${getCheckedColor};
    }
  }

  ${getChildrenAsTextStyle}
`
