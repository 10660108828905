import gql from 'graphql-tag'

import { priceLineFragment } from '../fragments'

const calculateCartPricingQuery = gql`
  query calculateCartPricing(
    $deliveryCost: Float
    $shippingTypeId: ID
    $paymentMethodId: ID
    $bin: String
    $storeId: ID
  ) {
    calculateCartPricing(
      shippingTypeId: $shippingTypeId
      deliveryCost: $deliveryCost
      paymentMethodId: $paymentMethodId
      bin: $bin
      storeId: $storeId
    ) {
      order {
        id
        priceLines {
          ...priceLine
        }
        subTotal
        tax
        total
        lineItemsTotal
      }
    }
  }

  ${priceLineFragment}
`

export default calculateCartPricingQuery
