import gql from 'graphql-tag'

const lineOfCreditFragment = gql`
  fragment lineOfCredit on LineOfCredit {
    id
    availableCredit
    creditLimit
    isEnabled
    canOverdraft
  }
`

export default lineOfCreditFragment
