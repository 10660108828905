import { DistributorNotAvailableError, VendorNotAvailableError } from '~/errors'
import { appConfigQuery } from '~/gql/queries'

const fetchConfig = async apolloClient => {
  try {
    const queryConfig = {
      query: appConfigQuery,
      fetchPolicy: 'no-cache',
    }

    const response = await apolloClient.query(queryConfig)

    const { vendor, manufacturer, user } = response.data

    await apolloClient.writeQuery({
      query: appConfigQuery,
      data: {
        vendor: {
          ...vendor,
          features: [],
        },
        manufacturer: {
          ...manufacturer,
          features: [],
        },
        user,
      },
    })

    return response.data
  } catch (error) {
    console.error(`[fetch-config]`, error)

    if (error.message.match(/El distribuidor .* ya fue eliminado/g)) {
      throw new DistributorNotAvailableError()
    }

    throw new VendorNotAvailableError()
  }
}

export default fetchConfig
