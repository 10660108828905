import * as React from 'react'
import Link from 'next/link'
import { Flag, useFlag } from 'toggled'

import { BulkOrderingModal } from '~/components/bulk-ordering-modal'
import { DropdownMenu, DropdownMenuItem } from '~/components/dropdown'
import { LoginToContinueModal } from '~/components/login-to-continue-modal'
import ShippingModal from '~/components/shipping-modal'
import { useModalManager, useMultiDistributorSession, useUser } from '~/hooks'
import { flags, images } from '~/utils/constants'

function OptionsDropdown() {
  const user = useUser()

  const { openModal } = useModalManager()

  const hasPublicPartnerVendorFF = useFlag(flags.PUBLIC_PARTNER_VENDOR)

  const hasMultiDistributorD2CFF = useFlag(flags.MULTI_DISTRIBUTOR_D2C)

  const { multiDistributorSession } = useMultiDistributorSession()

  const onFavoritesLoginCLick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()

    openModal(LoginToContinueModal, {
      image: images.ILLUSTRATION_FAVORITE,
      description: 'Inicia sesión para validar la disponibilidad del producto y agregarlo a tus favoritos',
    })
  }

  const onOrdersLoginClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()

    openModal(LoginToContinueModal, {
      image: images.ILLUSTRATION_SHOPPING_CART,
      description: 'Inicia sesión para visualizar y gestionar los pedidos que has hecho',
    })
  }

  const onRepurchaseLoginClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()

    openModal(LoginToContinueModal, {
      image: images.ILLUSTRATION_SHOPPING_CART,
      description: 'Inicia sesión para visualizar los productos que más has comprado y pedirlos nuevamente',
    })
  }

  const onBulkOrderingClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()

    if (hasMultiDistributorD2CFF && !hasPublicPartnerVendorFF && !multiDistributorSession) {
      openModal(ShippingModal)
    } else {
      openModal(BulkOrderingModal)
    }
  }

  return (
    <DropdownMenu className="absolute -right-2 top-full mt-5">
      <Link href="/mi-cuenta/pedidos" passHref={user.isLoggedIn}>
        <DropdownMenuItem
          iconType="order"
          label="Mis pedidos"
          onClick={user.isLoggedIn ? undefined : onOrdersLoginClick}
        />
      </Link>
      <Flag flagQuery={flags.PRICE_QUOTATION_NAVIGATION}>
        {user.isLoggedIn && user.quotationsPermission && (
          <Link href="/mi-cuenta/cotizaciones" passHref={user.isLoggedIn}>
            <DropdownMenuItem iconType="document" label="Mis cotizaciones" />
          </Link>
        )}
      </Flag>
      <Flag flagQuery={flags.MY_FAVORITES}>
        <Link href="/mi-cuenta/favoritos" passHref={user.isLoggedIn}>
          <DropdownMenuItem
            iconType="heart"
            label="Favoritos"
            onClick={user.isLoggedIn ? undefined : onFavoritesLoginCLick}
          />
        </Link>
      </Flag>
      <Flag flagQuery={flags.ALLOW_REPURCHASE}>
        <Link href="/mi-cuenta/mas-comprados" passHref={user.isLoggedIn}>
          <DropdownMenuItem
            iconType="update"
            label="Comprar de nuevo"
            onClick={user.isLoggedIn ? undefined : onRepurchaseLoginClick}
          />
        </Link>
      </Flag>
      <Flag flagQuery={flags.BULK_ORDERING}>
        <DropdownMenuItem iconType="archive-arrow-up" label="Compra masiva" onClick={onBulkOrderingClick} />
      </Flag>
    </DropdownMenu>
  )
}

export default OptionsDropdown
