import React, { useEffect, useState } from 'react'
import { FormSpy } from 'react-final-form'

import { useIsMobile } from '~/hooks'
import theme from '~/truck/theme'

interface FormErrorScrollProps {
  customFields?: { [key: string]: string }
  addHeaderOffset?: boolean
}

interface ScrollToErrorData {
  submitErrors: { [key: string]: string }
}

export function FormScrollToError(props: FormErrorScrollProps) {
  const { customFields, addHeaderOffset } = props

  const [htmlDocument, setHtmlDocument] = useState(null)

  const isMobile = useIsMobile()

  useEffect(() => {
    setHtmlDocument(document)
  }, [])

  function scrollToError(errorData: ScrollToErrorData) {
    const { submitErrors } = errorData

    if (!htmlDocument || !submitErrors) return

    const errorKeys = Object.keys(submitErrors)

    let inputName = errorKeys[0]

    if (customFields && customFields[inputName]) {
      inputName = customFields[inputName]
    }

    const errorElement = htmlDocument.querySelector(`[name="${inputName}"]`)

    if (errorElement) {
      let errorElementYposition = errorElement.getBoundingClientRect().top + window.scrollY

      if (isMobile && addHeaderOffset) {
        const headerSize = theme.spacing.relaxed * 2

        errorElementYposition -= headerSize + theme.spacing.cozy
      }

      window.scrollTo({ top: errorElementYposition, behavior: 'smooth' })
    }
  }

  return <FormSpy subscription={{ submitErrors: true }} onChange={scrollToError} />
}
