import React from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { filter } from 'lodash'

import EditModal from '~/components/edit-modal'
import PaymentMethods from '~/components/payment-methods'
import { editOrderSetPaymentMutation } from '~/gql/mutations'
import { paymentMethodsQuery } from '~/gql/queries'
import useModalManager from '~/hooks/use-modal-manager'
import Loader from '~/truck/loader'
import notifier from '~/truck/notifier'
import { formValidators, handleSubmissionErrors } from '~/utils'

// TODO: Move Loader

const EditPaymentMethodModal = props => {
  const { order } = props

  const { closeModal } = useModalManager()

  const [setPayment] = useMutation(editOrderSetPaymentMutation)

  const { data, loading } = useQuery(paymentMethodsQuery)

  if (loading) {
    return <Loader centered />
  }

  const { paymentMethods } = data.vendor

  const changeablePaymentMethods = filter(paymentMethods, { isCard: false })

  const initialValues = {
    paymentMethodId: order.paymentMethod.id,
  }

  const onSubmit = async values => {
    const options = {
      variables: {
        orderId: order.id,
        paymentMethodId: values.paymentMethodId,
        isOrderEdition: true,
      },
      refetchQueries: ['order'],
    }

    try {
      await setPayment(options)

      notifier.success('Haz actualizado el método de pago')

      closeModal()

      return undefined
    } catch (error) {
      return notifier.error(handleSubmissionErrors(error))
    }
  }

  return (
    <EditModal
      title="Editar método de pago"
      onSubmit={onSubmit}
      initialValues={initialValues}
      onValidate={formValidators.payment}
    >
      <PaymentMethods dataSource={changeablePaymentMethods} order={order} style={{ padding: 0 }} />
    </EditModal>
  )
}

export default EditPaymentMethodModal
