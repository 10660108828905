import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-final-form'
import { debounce } from 'lodash'
import styled from 'styled-components'

import { GoogleMapsContext } from '~/contexts'
import { useFieldValue, useGeocoding } from '~/hooks'
import Field from '~/truck/field'
import Icon from '~/truck/icon'
import Label from '~/truck/label'
import theme from '~/truck/theme'

const EditLabelContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;

  > :first-child {
    margin-right: ${theme.spacing.compact}px;
  }
`

const PlacesAutocomplete = props => {
  const { name, placeholder, floatingLabel, noAddressFound, onEditClick, ...rest } = props

  const form = useForm()

  const autocompleteRef = useRef()

  const { mapInstance } = useContext(GoogleMapsContext)

  const hasSelectedAddress = useFieldValue('hasSelectedAddress')

  const geocoding = useGeocoding()

  const showNoAddressFound = useFieldValue('showNoAddressFound')

  const watchedFieldValue = useFieldValue(name)

  const [noResults, setNoResults] = useState()

  const validateResults = useCallback(
    debounce(value => {
      const container = document.getElementsByClassName('pac-container')

      if (!container) {
        setNoResults(false)

        return
      }

      const results = container.item(container.length - 1)?.childElementCount

      if (value) {
        setNoResults(results === 0)
      }
    }, 500),
    [],
  )

  useEffect(() => {
    validateResults(watchedFieldValue)
  }, [watchedFieldValue])

  const hasNoResults = (noResults && !hasSelectedAddress) || showNoAddressFound

  const onPlaceChanged = useCallback(async () => {
    const place = autocompleteRef.current.getPlace()

    const location = place?.geometry?.location

    if (!location) {
      return
    }

    form.batch(() => {
      form.change('addressId', null)

      form.change('hasSelectedAddress', true)

      form.change('showNoAddressFound', false)

      form.change(name, place.formatted_address)

      form.change('coordinates.latitude', location.lat())

      form.change('coordinates.longitude', location.lng())
    })

    if (geocoding) {
      const { zipCode, addressComponents } = await geocoding(location.lat(), location.lng())

      form.batch(() => {
        form.change('addressComponents', addressComponents)

        if (zipCode) {
          form.change('zipCode', zipCode)
        }
      })
    }

    if (mapInstance) {
      mapInstance.setCenter({ lat: location.lat(), lng: location.lng() })
    }
  }, [geocoding, mapInstance])

  const setNode = useCallback(
    node => {
      if (!node || !window.google.maps.places) {
        return
      }

      if (autocompleteRef.current) {
        autocompleteRef.current.addListener('place_changed', onPlaceChanged)

        return
      }

      const types = { types: ['geocode', 'establishment'] }

      const fields = ['formatted_address', 'place_id', 'geometry']

      autocompleteRef.current = new window.google.maps.places.Autocomplete(node, types)

      const onSuccess = location => {
        const { coords } = location

        const { latitude, longitude } = coords

        const bounds = new google.maps.LatLngBounds()

        const latLng = new google.maps.LatLng(latitude, longitude)

        bounds.extend(latLng)

        autocompleteRef.current.setBounds(bounds)
      }

      navigator.geolocation.getCurrentPosition(onSuccess)

      autocompleteRef.current.setFields(fields)

      autocompleteRef.current.addListener('place_changed', onPlaceChanged)
    },
    [onPlaceChanged, window.google.maps.places],
  )

  const renderRightItem = () => {
    if (hasSelectedAddress) {
      const onClick = () => {
        form.change('hasSelectedAddress', false)
      }

      return (
        <EditLabelContainer onClick={onEditClick || onClick}>
          <Label as="p" $color="primary">
            Editar
          </Label>
        </EditLabelContainer>
      )
    }

    return <Icon type="search" />
  }

  const renderNoAddressFound = () => {
    if (hasNoResults && noAddressFound) {
      return noAddressFound
    }

    return null
  }

  return (
    <>
      <Field
        name={name}
        placeholder={placeholder}
        autocomplete="off"
        rightItem={renderRightItem()}
        ref={setNode}
        disabled={hasSelectedAddress}
        {...rest}
      />
      {renderNoAddressFound()}
    </>
  )
}

export default PlacesAutocomplete
