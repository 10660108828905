import * as React from 'react'
import styled from 'styled-components'

import Loader from '~/components/loader'
import { stack } from '~/variables'

const StyledCartLoadingOverlayContainer = styled.div`
  align-items: center;
  background-color: rgb(255 255 255 / 40%);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: ${stack.cart + 1};
`

export function CartLoadingOverlay() {
  return (
    <StyledCartLoadingOverlayContainer>
      <Loader centered />
    </StyledCartLoadingOverlayContainer>
  )
}
