import gql from 'graphql-tag'

const coverageQuery = gql`
  query coverage($isBuyingInStore: Boolean!) {
    vendor @skip(if: $isBuyingInStore) {
      id
      coverageImage
      coverage {
        id
        name
        division
        parentId
        originalId
      }
    }
    buyingInStore @include(if: $isBuyingInStore) {
      id
      coverage {
        id
        name
        division
        parentId
        originalId
      }
    }
  }
`

export default coverageQuery
