import * as React from 'react'
import useEmblaCarousel, { EmblaOptionsType } from 'embla-carousel-react'

export default function useCarousel(options: EmblaOptionsType) {
  const [viewportRef, emblaApi] = useEmblaCarousel(options)

  const [isPrevBtnEnabled, setIsPrevBtnEnabled] = React.useState(false)

  const [isNextBtnEnabled, setIsNextBtnEnabled] = React.useState(false)

  const scrollToPrev = () => emblaApi && emblaApi.scrollPrev()

  const scrollToNext = () => emblaApi && emblaApi.scrollNext()

  const onSelect = React.useCallback(() => {
    if (emblaApi) {
      setIsPrevBtnEnabled(emblaApi.canScrollPrev())

      setIsNextBtnEnabled(emblaApi.canScrollNext())
    }
  }, [emblaApi])

  React.useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select', onSelect)

      onSelect()

      return () => {
        emblaApi.off('select', onSelect)
      }
    }
  }, [emblaApi, onSelect])

  return {
    emblaApi,
    ui: {
      viewportRef,
      scrollToPrev,
      scrollToNext,
      isPrevBtnEnabled,
      isNextBtnEnabled,
    },
  }
}
