import darken from 'polished/lib/color/darken'

import cloudinary from './cloudinary'

const createTheme = () => {
  const factors = {
    darken: 0.11,
  }

  const palette = {
    primary10: '#DBEAFE', // blue-100
    primary20: '#BFDBFE', // blue-200
    primary30: '#BFDBFE', // blue-200
    primary40: '#3B82F6', // blue-500
    primary50: '#3B82F6', // blue-500
    primary60: '#2563EB', // blue-600
    primary70: '#1E40AF', // blue-800
    primary80: '#1E3A8A', // blue-900
    secondary10: '#FFF7ED', // orange-50
    secondary20: '#FFEDD5', // orange-100
    secondary30: '#FED7AA', // orange-200
    secondary40: '#FDBA74', // orange-300
    secondary50: '#FB923C', // orange-400
    secondary60: '#F97316', // orange-500
    secondary70: '#F97316', // orange-500
    secondary80: '#C2410C', // orange-700
    grayscale30: '#FAFAFA', // zinc-50
    grayscale40: '#E4E4E7', // zinc-200
    grayscale50: '#D4D4D8', // zinc-300
    grayscale60: '#A1A1AA', // zinc-400
    grayscale70: '#52525B', // zinc-600
    grayscale80: '#27272A', // zinc-800
    success: '#2DD4BF', // teal-400
    successDark: '#0D9488', // teal-600
    successLight: '#2DD4BF', // teal-400
    warning: '#FACC15', // yellow-400
    warningDark: '#CA8A04', // yellow-600
    warningLight: '#FDE047', // yellow-300
    error: '#F43F5E', // rose-500
    errorDark: '#FB7185', // rose-400
    errorLight: '#BE123C', // rose-700
  }

  const colors = {
    black: '#27272A', // zinc-800
    black30: 'rgba(39, 39, 42, 0.3)', // zinc-800/30
    blackLight: '#D4D4D4', // neutral-300
    error: '#F43F5E', // rose-500
    errorBg: '#FFF1F2', // rose-50
    gray: '#E5E5E5', // neutral-200
    grayLight: '#F5F5F5', // neutral-100
    grayDark: '#404040', // neutral-700
    grayDarkBg: 'rgba(69, 69, 69, 0.1)',
    graySlate: '#64748B', // slate-500
    info: '#3B82F6', // blue-500
    infoBg: '#EFF6FF', // blue-50
    primary: '#407EFF',
    primary30: '#A5B4FC', // indigo-300
    primary50: '#6366f1', // indigo-500
    get primaryDark() {
      return darken(factors.darken, this.primary)
    },
    secondary: '#ff8000',
    get secondaryDark() {
      return darken(factors.darken, this.secondary)
    },
    success: '#2DD4BF', // teal-400
    successBg: '#F0FDFA', // teal-50
    warning: '#FACC15', // yellow-400
    warningBg: '#FEFCE8', // yellow-50
    white: '#ffffff',
    white70: 'rgba(255, 255, 255, 0.7)',
    white50: 'rgba(255, 255, 255, 0.5)',
    white90: 'rgba(255, 255, 255, 0.9)',
    zumthor: '#A5B4FC', // indigo-300
    charade: '#2d333c',
  }

  const textStyles = {
    h1Bold: { lineHeight: 48, size: 48, weight: 700 }, // text-5xl font-bold
    h2Bold: { lineHeight: 40, size: 36, weight: 700 }, // text-4xl font-bold
    h3Bold: { lineHeight: 36, size: 30, weight: 700 }, // text-3xl font-bold
    h4Bold: { lineHeight: 28, size: 20, weight: 700 }, // text-xl font-bold
    h5Bold: { lineHeight: 24, size: 16, weight: 700 }, // text-base font-bold
    h6Bold: { lineHeight: 16, size: 12, weight: 700 }, // text-xs font-bold
    h1Semibold: { lineHeight: 48, size: 48, weight: 600 }, // text-5xl font-semibold
    h2Semibold: { lineHeight: 40, size: 36, weight: 600 }, // text-4xl font-semibold
    h3Semibold: { lineHeight: 36, size: 30, weight: 600 }, // text-3xl font-semibold
    h4Semibold: { lineHeight: 28, size: 20, weight: 600 }, // text-xl font-semibold
    h5Semibold: { lineHeight: 24, size: 16, weight: 600 }, // text-base font-semibold
    h6Semibold: { lineHeight: 16, size: 12, weight: 600 }, // text-xs font-semibold
    h1Regular: { lineHeight: 48, size: 48, weight: 400 }, // text-5xl
    h2Regular: { lineHeight: 40, size: 36, weight: 400 }, // text-4xl
    h3Regular: { lineHeight: 36, size: 30, weight: 400 }, // text-3xl
    h4Regular: { lineHeight: 28, size: 20, weight: 400 }, // text-xl
    h5Regular: { lineHeight: 24, size: 16, weight: 400 }, // text-base
    h6Regular: { lineHeight: 16, size: 12, weight: 400 }, // text-xs
  }

  const spacing = {
    compact: 4, // 1
    six: 6, // 1.5
    cozy: 8, // 2
    comfortable: 16, // 4
    relaxed: 24, // 6
  }

  const sizes = {
    sm: 16, // 4
    md: 24, // 6
    lg: 32, // 8
    xl: 48, // 12
    xxl: 72, // 18
  }

  const dimensions = {
    buttonHeight: 48, // 12
    inputHeight: 48, // 12
    borderRadius: 4, // rounded
    headerHeight: 56, // 14
  }

  const branding = {
    isotypeBlack: cloudinary.getFullUrl('saas/resources/isotype-black.svg'),
    isotypeBlue: cloudinary.getFullUrl('saas/resources/isotype-blue.svg'),
    isotypeWhite: cloudinary.getFullUrl('saas/resources/isotype-white.svg'),
    logotypeBlack: cloudinary.getFullUrl('saas/resources/brand/logotype-black.svg'),
    logotypeBlue: cloudinary.getFullUrl('saas/resources/brand/logotype-blue.svg'),
    logotypeWhite: cloudinary.getFullUrl('saas/resources/brand/logotype-white.svg'),
  }

  const breakpoints = {
    xs: '1px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  }

  return {
    breakpoints,
    sizes,
    branding,
    colors,
    palette,
    dimensions,
    spacing,
    textStyles,
    factors,
  }
}

export default createTheme
