import gql from 'graphql-tag'

const partnerVendorsQuery = gql`
  query partnerVendors {
    manufacturer {
      id
      distributors {
        id
        alternativeLogo
        name
        description
        isDisabledForUser
        endpoint
        slug
      }
    }
  }
`

export default partnerVendorsQuery
