import { differenceInYears } from 'date-fns/differenceInYears'
import { isFinite, mapValues, toNumber } from 'lodash'

export function transformBirthdate(birthdate) {
  const { year, month, day } = birthdate

  return new Date(year, month, day)
}

/**
 * @param {{ year: string, month: string, day: string }} birthdate
 * @param {{ message: string, legalAge?: { message: string, value: number } }} options
 */
const birthdateValidator = (birthdate: Record<string, any> = {}, options: Record<string, any>) => {
  const { year, month, day } = mapValues(birthdate, toNumber)

  const isCompleted = isFinite(year) && isFinite(month) && isFinite(day)

  if (!isCompleted) {
    return
  }

  const date = transformBirthdate(birthdate)

  const isValid = date.getFullYear() === year && date.getMonth() === month && date.getDate() === day

  if (!isValid) {
    return options.message
  }

  if (!options.legalAge) {
    return
  }

  const age = differenceInYears(new Date(), date)

  const isOldEnough = age >= options.legalAge.value

  if (!isOldEnough) {
    return options.legalAge.message
  }

  return null
}

export default birthdateValidator
