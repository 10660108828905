import * as React from 'react'
import { map } from 'lodash'
import { useFlag } from 'toggled'

import { cloudinary } from '~/utils'
import { flags, transformations } from '~/utils/constants'

import { Container, ProductCard } from './elements'

const LineItem = ProductCard

const getOptimizedPhoto = originalUrl => {
  return cloudinary.applyTransformations(originalUrl, transformations.TINY_PRODUCT)
}

const LineItems = props => {
  const { dataSource, className, style } = props

  const showCartInMainLayoutFF = useFlag(flags.SHOW_CART_IN_MAIN_LAYOUT)

  const reversedDataSource = [...dataSource].reverse()

  return (
    <Container className={className} style={style}>
      {map(reversedDataSource, lineItem => {
        const product = lineItem.product || {}

        const pricing = product.pricing || {}

        return (
          <LineItem
            {...pricing}
            {...lineItem}
            {...product}
            runPromotions={!showCartInMainLayoutFF}
            quantity={lineItem.originalQuantity}
            photo={getOptimizedPhoto(lineItem.photo)}
            key={lineItem.id}
            style={{ flexShrink: 0 }}
            allowDiscountLabel={true}
            allowTaxLabel
            withCounter
            removable
            shouldEllipsisName={false}
            isCart
          />
        )
      })}
    </Container>
  )
}

export default LineItems
