import { ceil, clamp, isNil } from 'lodash'

function validateIfCanJumpOnce(jumpFactor: number | null, minValue: number | null, maxValue: number | null): boolean {
  const minValueQuantity = minValue ?? 0

  const maxValueQuantity = maxValue ?? Number.MAX_SAFE_INTEGER

  const minQuantity = clamp(jumpFactor, minValueQuantity, maxValueQuantity)

  const minMultipleQuantity = ceil(minQuantity / jumpFactor) * jumpFactor

  return !isNil(jumpFactor) && minValueQuantity <= minMultipleQuantity && minMultipleQuantity <= maxValueQuantity
}

export default validateIfCanJumpOnce
