import gql from 'graphql-tag'

const productPhotoFragment = gql`
  fragment productPhoto on ProductPhoto {
    id
    url
  }
`

export default productPhotoFragment
