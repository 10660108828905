import * as React from 'react'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import Overlay from '~/truck/overlay'

export interface DialogModalProps {
  children: React.ReactNode
  onClose?: () => void
  maxWidth?: number
  className?: string
  blocking?: boolean
}

const StyledContainer = styled.div<{ $maxWidth: number }>`
  left: 50%;
  padding: ${props => props.theme.spacing.comfortable}px;
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  ${up('lg')} {
    width: ${props => props.$maxWidth ?? props.theme.spacing.cozy * 57}px;
  }
`

export function DialogModal(props: DialogModalProps) {
  const { maxWidth, onClose, children, className, blocking = false } = props

  return (
    <Overlay open onMouseDown={onClose} blocking={blocking}>
      <StyledContainer className={className} $maxWidth={maxWidth} onMouseDown={e => e.stopPropagation()}>
        {children}
      </StyledContainer>
    </Overlay>
  )
}
