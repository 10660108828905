import React from 'react'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import Button from '~/truck/button'
import theme from '~/truck/theme'

const Container = styled.div`
  bottom: 0;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: row;
  height: ${theme.spacing.cozy * 10}px;
  left: 0;
  padding: ${theme.spacing.comfortable}px;
  position: fixed;
  right: 0;

  > :first-child {
    color: ${theme.colors.black};
    margin-right: ${theme.spacing.comfortable}px;
  }

  ${up('lg')} {
    justify-content: flex-end;
    padding: ${theme.spacing.comfortable}px ${theme.spacing.relaxed}px;

    > :first-child {
      margin-right: ${theme.spacing.relaxed}px;
    }

    > * {
      flex: 0 0 ${theme.spacing.cozy * 20}px;
    }
  }
`

const Actions = props => {
  const { onSubmit, onClose, submitting, disableSubmit } = props

  return (
    <Container>
      <Button.Ghost color="gray" disabled={submitting} onClick={onClose}>
        Cancelar
      </Button.Ghost>
      <Button color="secondary" disabled={disableSubmit} loading={submitting} onClick={onSubmit}>
        Guardar
      </Button>
    </Container>
  )
}

export default Actions
