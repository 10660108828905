import React from 'react'
import { useField } from 'react-final-form'
import { find, map, sortBy } from 'lodash'
import styled from 'styled-components'

import ButtonGroup from '~/components/button-group'
import Icon from '~/truck/icon'
import Label from '~/truck/label'
import theme from '~/truck/theme'

const Container = styled.div`
  align-items: center;
  display: flex;
  padding: ${theme.spacing.comfortable}px;
`

const renderOption = shippingType => {
  const { id, name, icon } = shippingType

  return <ButtonGroup.Option key={id} text={name} iconName={icon} value={id} />
}

const ShippingOptions = props => {
  const { dataSource, style, className, editable = true } = props

  const shippingTypeIdField = useField('shippingTypeId')

  if (editable) {
    return (
      <ButtonGroup {...shippingTypeIdField.input} style={style} className={className}>
        {map(sortBy(dataSource, 'order'), renderOption)}
      </ButtonGroup>
    )
  }

  const shippingTypeId = shippingTypeIdField.input.value

  const shippingType = find(dataSource, { id: shippingTypeId }) || {}

  return (
    <Container style={style} className={className}>
      <Icon type={shippingType.icon} color="black" style={{ marginRight: theme.spacing.cozy }} />
      <Label as="span">{shippingType.name}</Label>
    </Container>
  )
}

export default ShippingOptions
