import gql from 'graphql-tag'

import { categoryFragment } from '../fragments'

const categoryQuery = gql`
  query categories($encodedId: String) {
    vendor {
      id
      category(encodedId: $encodedId) {
        ...category
        subcategories: subCategories {
          ...category
        }
      }
    }
  }
  ${categoryFragment}
`

export default categoryQuery
