import { createGlobalStyle } from 'styled-components'

import theme from '~/truck/theme'

export default createGlobalStyle`
  .wysiwyg {
    h1 {
      line-height: normal;
    }
    
    a {
      color: ${theme.colors.info};
      text-decoration: none;
    }

    ul {
      list-style-type: initial;
    }

    ul, ol {
      padding-inline-start: ${theme.spacing.cozy * 5}px;
    }

    img {
      max-width: 100%
    }
  }
`
