import * as React from 'react'
import styled from 'styled-components'

import Button from '~/truck/button'
import { images } from '~/utils/constants'

import { BulkDialogResultContent, BulkDialogResultInfo } from '../bulk-dialog'
import { useDialogState } from '../dialog-provider'
import { DialogStep } from '../dialog-provider/types'

const StyledButton = styled(Button.Ghost)`
  width: auto;
`

export function DialogUnsatisfactorilyProcessedStepContent() {
  const { setStep } = useDialogState()

  const onStartProcessAgain = () => {
    setStep(DialogStep.START_PROCESS, [], [])
  }

  return (
    <BulkDialogResultContent
      imageSrc={images.ILLUSTRATION_ERROR}
      description="No se agregaron productos al carrito"
      renderContent={() => <BulkDialogResultInfo />}
      renderFooter={() => (
        <StyledButton type="button" color="grayscale70" onClick={onStartProcessAgain}>
          Volver a carga masiva
        </StyledButton>
      )}
    />
  )
}
