import gql from 'graphql-tag'

const createCustomerComplaintCodeMutation = gql`
  mutation createCustomerComplaintCode {
    createCustomerComplaintCode(input: {}) {
      code
    }
  }
`

export default createCustomerComplaintCodeMutation
