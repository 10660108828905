import { useField } from 'react-final-form'

const defaultOptions = {
  subscription: {
    value: true,
  },
}

const useFieldValue = (fieldName, options = {}) => {
  return useField(fieldName, { ...defaultOptions, ...options }).input.value
}

export default useFieldValue
