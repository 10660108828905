import styled, { css } from 'styled-components'

import Label from '~/truck/label'
import theme from '~/truck/theme'
import { getColor, getTheme } from '~/truck/utils'

export const SquaredFrame = styled.span`
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.gray};
  height: ${theme.spacing.comfortable}px;
  transition:
    border-color 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  width: ${theme.spacing.comfortable}px;
`

function getCheckedColor(props) {
  const { color = 'secondary' } = props

  return getColor(getTheme(props), color)
}

interface InputProps {
  $color?: string
}

export const Input = styled.input.attrs<InputProps, { type: string }>(props => {
  const { type } = props

  return {
    type: type ?? 'checkbox',
  }
})<InputProps>`
  display: none;

  :checked {
    & ~ svg {
      display: inline-block;
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  :checked:not(:disabled) {
    & ~ span {
      background-color: ${getCheckedColor};
      border-color: ${getCheckedColor};
    }
  }

  :checked:disabled {
    & ~ span {
      background-color: ${theme.colors.gray};
      border-color: ${theme.colors.gray};
    }
  }
`

export const InputContainer = styled.span`
  display: inline-flex;
  flex-shrink: 0;
  position: relative;
  vertical-align: middle;

  > svg {
    display: none;
    transform: scale(0.8); /* visual perception */
  }
`

interface ContainerProps {
  $disabled?: boolean
  $hasText?: boolean
  $color?: string
}

function getCursor({ $disabled }: ContainerProps) {
  if ($disabled) {
    return 'not-allowed'
  }

  return 'pointer'
}

function getDisabledStyle({ $disabled, $hasText }: ContainerProps) {
  if (!$disabled) {
    return null
  }

  return css`
    > ${Label} {
      color: ${theme.colors.blackLight};
      ${!$hasText && 'opacity: 0.3;'}
    }
  `
}

function getChildrenAsTextStyle({ $hasText }: ContainerProps) {
  if ($hasText) {
    return css`
      > ${Label} {
        padding-left: ${theme.spacing.cozy}px;
      }
    `
  }

  return css`
    background-color: ${theme.colors.white};
    padding-left: ${theme.spacing.comfortable}px;

    > ${Label} {
      flex-grow: 1;
    }
  `
}

export const Container = styled.label<ContainerProps>`
  align-items: center;
  display: flex;

  &,
  * {
    cursor: ${getCursor};
  }

  :hover {
    input:not(:disabled) {
      & ~ span {
        border-color: ${getCheckedColor};
      }
    }
  }

  ${getDisabledStyle}
  ${getChildrenAsTextStyle}
`
