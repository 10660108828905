import styled, { StyledProps } from 'styled-components'

import type { ThemeColor } from '~/types/theme'

import { getColor, getTextStyle, getTheme } from '../utils'

interface AnchorProps {
  $disabled?: boolean
  $color?: ThemeColor | 'currentColor'
  $textStyle?: string
  color?: never
}

const getDisabledStyle = (props: StyledProps<AnchorProps>) => {
  const { colors } = getTheme(props)

  return `
    color: ${colors.blackLight};
    pointer-events: none;
  `
}

const getNormalStyle = (props: StyledProps<AnchorProps>) => {
  const theme = getTheme(props)
  const { $color: key = 'primary' } = props
  const color = getColor(theme, key)

  let hoverStyle = ''
  if (key === 'primary') {
    hoverStyle = `
      &:hover {
        color: ${theme.colors.primaryDark};
      }
    `
  }

  return `
    color: ${color};
    ${hoverStyle}
  `
}

const getColorStyle = (props: StyledProps<AnchorProps>) => {
  const { $disabled } = props

  if ($disabled) {
    return getDisabledStyle(props)
  }

  return getNormalStyle(props)
}

const resolveTextStyle = (props: StyledProps<AnchorProps>) => {
  const theme = getTheme(props)
  const { $textStyle: key = 'h5Regular' } = props
  const textStyle = theme.textStyles[key]

  return getTextStyle(textStyle)
}

const Anchor = styled.a<AnchorProps>`
  text-decoration: none;
  transition: color 0.2s ease-in-out;
  user-select: none;
  ${resolveTextStyle}
  ${getColorStyle}
`

Anchor.displayName = 'Anchor'

export default Anchor
