export interface DownloadOrderParams {
  orderStatusIds?: string[] | null
  from?: string
  to?: string
}

export enum ExportSteps {
  START_EXPORT = 0,
}

export interface IExportState {
  button: {
    isLoading: boolean
    disabled: boolean
    wording: 'Exportar'
  }
  step: ExportSteps
  params: DownloadOrderParams
  createdId: string | null
}

interface IFile {
  url: string
  name: string
}
export interface CreateExportMutationResult {
  data: {
    file: IFile
  }
}
