import { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'

import { orderStatusesQuery, userOrdersQuery } from '~/gql/queries'
import ExportOrdersModal from '~/screens/orders/export-orders-modal'

import useDateRangeFilter from './use-date-range-filter'
import useModalManager from './use-modal-manager'

const defaultConnection = {
  items: [],
  pageInfo: {
    total: 0,
  },
}

interface UseOrdersOptions {
  initialOrderStatusId?: string
}

const useOrders = (options?: UseOrdersOptions) => {
  const [orderStatusId, setOrderStatusId] = useState(options.initialOrderStatusId || '')

  const { from, to, onChange } = useDateRangeFilter()

  const variables = {
    orderStatusId,
    from: from.toISOString(),
    to: to.toISOString(),
  }

  const userOrdersResponse = useQuery(userOrdersQuery, { variables })

  const orderStatusesResponse = useQuery(orderStatusesQuery, {
    variables: { showAll: true },
  })

  const orderStatuses = orderStatusesResponse?.data?.vendor?.orderStatuses ?? []

  const { openModal } = useModalManager()

  const userOrdersData = userOrdersResponse?.data?.user?.orders ?? defaultConnection

  const ordersCount = userOrdersData.pageInfo.total

  const onExportModalOpen = () => {
    openModal(ExportOrdersModal, {
      ...variables,
      ordersCount,
    })
  }

  return {
    from,
    onChange,
    onExportModalOpen,
    ordersCount,
    orderStatuses,
    orderStatusId,
    setOrderStatusId,
    to,
    userOrdersData,
    userOrdersResponse,
    variables,
  }
}

export default useOrders
