import gql from 'graphql-tag'

import addressFragment from './address'
import documentFragment from './document'
import orderStatusFragment from './order-status'
import paymentMethodFragment from './payment-method'
import priceLineFragment from './price-line'
import productAttributesFragment from './product-attributes'
import shippingTypeFragment from './shipping-type'
import storeFragment from './store'

const orderFragment = gql`
  fragment order on Order {
    id
    code
    uniqueCode
    createdAt
    dedicationMessage
    deliveryDate
    deliveryCost
    tax
    cardBrand
    maskedCardNumber
    paymentVoucherUrl
    total
    extraFields
    contactPersonFirstName
    contactPersonLastName
    contactPersonDocument
    contactPersonPhone
    shipmentTrackingUrl
    comments
    deliveryTimeRange {
      id
      time
    }
    lineItems {
      id
      name
      photo
      price
      oldPrice
      isTaxExempt
      quantity
      originalQuantity
      inventoryQuantity
      inventoryUnitCode
      presentation
      product {
        id
        name
        sku
        slug
        category {
          id
          name
          parent {
            id
            name
          }
        }
        defaultPhoto
        presentation
        description
        stock
        hasVariants
        pricing {
          oldPrice
          price
          ribbonLabel
        }
        attributes {
          ...productAttributes
        }
        attributesSummary
      }
      adjustments {
        label
      }
    }
    deliveryAddress {
      ...address
    }
    store {
      ...store
    }
    document {
      ...document
    }
    billingAddress {
      ...address
    }
    paymentMethod {
      ...paymentMethod
    }
    priceLines {
      ...priceLine
    }
    status {
      ...orderStatus
    }
    user {
      id
      fullName
    }
    transactionType
    shippingType {
      ...shippingType
    }
    shipment: shipmentData {
      id
      revalidate
      fare {
        id
        amount
        currency
        durationTerms
        maxShippingDays
        provider
        serviceLevel
      }
      tracking {
        id
        originalId
        providerUrl
      }
    }
    review {
      id
      rating
      comment
    }
    needsReview
  }
  ${storeFragment}
  ${addressFragment}
  ${documentFragment}
  ${paymentMethodFragment}
  ${priceLineFragment}
  ${shippingTypeFragment}
  ${orderStatusFragment}
  ${productAttributesFragment}
`

export default orderFragment
