import React from 'react'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import { useFlag } from 'toggled'

import LocationSelector from '~/components/location-selector'
import Media from '~/components/media'
import useFormatters from '~/hooks/use-formatters'
import useVendor from '~/hooks/use-vendor'
import Field from '~/truck/field'
import theme from '~/truck/theme'
import { storage } from '~/utils'
import { flags, storageKeys } from '~/utils/constants'

import Map from '../../map'
import { StyledFormActions, StyledGridColumn } from '../elements'

import { FormWrapper } from './elements'
import useGoogleMap from './use-google-map'

const StyledFormLegacyActions = styled(StyledFormActions)`
  ${up('lg')} {
    margin-top: ${theme.spacing.comfortable}px;
  }
`

const AddressForm = props => {
  const { withActions, onCancelClick, onCreateClick, style, className, coverageTree } = props

  const vendor = useVendor()

  const hasExtraContactFields = vendor?.settings?.shipping?.hasExtraContactFields

  const hasMapInCreation = useFlag(flags.MAP_IN_ADDRESS_CREATION)

  const hasAllowApartmentInAddress = useFlag(flags.ALLOW_APARTMENT_IN_ADDRESS)

  const { loadingScript, divMapRef, mapRef } = useGoogleMap({
    enabled: hasMapInCreation,
    loadAddressComponents: !storage.has(storageKeys.ADDRESS_COMPONENTS),
  })

  const formatters = useFormatters()

  const hasTerritoryGeofences = useFlag(flags.TERRITORY_GEOFENCES)

  const hasContactPersonFeature = useFlag(flags.CONTACT_PERSON)

  if (loadingScript) {
    return null
  }

  const commonProps = { onCancelClick, onCreateClick }

  return (
    <>
      <Media desktop={withActions && <StyledFormLegacyActions {...commonProps} />} />

      <FormWrapper style={style} className={className}>
        {!hasTerritoryGeofences && <LocationSelector coverageTree={coverageTree} />}
        {hasMapInCreation && (
          <StyledGridColumn>
            <Map divMapRef={divMapRef} mapRef={mapRef} />
          </StyledGridColumn>
        )}
        <StyledGridColumn>
          <Field name="addressLine" placeholder="Dirección" />
        </StyledGridColumn>
        {hasAllowApartmentInAddress && (
          <StyledGridColumn>
            <Field name="apartment" placeholder="Interior / Oficina / Piso" />
          </StyledGridColumn>
        )}
        <StyledGridColumn>
          <Field name="reference" placeholder="Referencia" />
        </StyledGridColumn>
        {!hasContactPersonFeature && (
          <>
            <StyledGridColumn xs={6}>
              <Field name="contactPerson" placeholder="Persona de contacto" />
            </StyledGridColumn>
            <StyledGridColumn xs={6}>
              <Field name="contactPhone" placeholder="Celular de contacto" parse={formatters.phone} type="tel" />
            </StyledGridColumn>
            {hasExtraContactFields && (
              <>
                <StyledGridColumn xs={6}>
                  <Field name="contactDocumentNumber" placeholder="DNI del contacto" />
                </StyledGridColumn>
                <StyledGridColumn xs={6}>
                  <Field name="contactEmail" placeholder="Correo de contacto" />
                </StyledGridColumn>
              </>
            )}
          </>
        )}
      </FormWrapper>

      <Media mobile={withActions && <StyledFormActions {...commonProps} />} />
    </>
  )
}

export default AddressForm
