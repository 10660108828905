import styled from 'styled-components'

import theme from '~/truck/theme'

export const Container = styled.div`
  background-color: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  padding-top: ${theme.spacing.comfortable}px;

  > *:not(:last-child) {
    margin-bottom: ${theme.spacing.comfortable}px;
  }
`
