import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import Price from '~/components/price'
import NewButton from '~/truck/new-button/NewButton'

export const Container = styled.div<{ needsPadding: boolean }>`
  background: ${props => props.theme.colors.grayLight};
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  left: 0;
  padding: ${props => (props.needsPadding ? `${props.theme.spacing.comfortable}px` : '0')};
  position: sticky;
  right: 0;

  ${up('lg')} {
    position: static;
  }

  > *:first-child {
    margin-bottom: ${props => props.theme.spacing.comfortable}px;
  }
`

export const StyledButton = styled(NewButton)`
  justify-content: ${props => (props.loading ? 'center' : 'space-between')};
  padding: 0 ${props => props.theme.spacing.comfortable}px;
`

export const StyledPrice = styled(Price)`
  display: inline-flex;
`

export const AlertContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  margin-top: ${props => props.theme.spacing.comfortable}px;
  padding: ${props => props.theme.spacing.relaxed}px;
`
