import React from 'react'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import Icon from '~/truck/icon'
import Label from '~/truck/label'
import theme from '~/truck/theme'

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;

  > svg:first-of-type {
    margin-right: ${theme.spacing.cozy}px;
  }

  > svg:last-of-type {
    display: none;
  }

  ${up('lg')} {
    justify-content: space-between;

    > svg:first-of-type {
      display: none;
    }

    > svg:last-of-type {
      display: block;
    }
  }
`

const Header = props => {
  const { title, onClose } = props

  return (
    <Container>
      <Icon color="black" onClick={onClose} type="arrow-left" />
      <Label as="h4" $textStyle="h4Semibold">
        {title}
      </Label>
      <Icon color="black" onClick={onClose} type="close" />
    </Container>
  )
}

export default Header
