import { useCallback } from 'react'
import { findIndex, omit, pick } from 'lodash'

import { cloudinary } from '~/utils'

const useCartUpdater = setLineItems => {
  const getLineItemFromProduct = useCallback(product => {
    const baseProductFields = ['id', 'name', 'sku', 'slug', 'presentation', 'description', 'stock', 'pricing']

    const actualPhoto = product.defaultPhoto || product.photo

    const processedProduct = {
      ...pick(product, baseProductFields),
      attributes: product.attributes || null,
      price: product.price || product.pricing.price,
      oldPrice: product.oldPrice || product.pricing.oldPrice,
      defaultPhoto: cloudinary.removeTransformations(actualPhoto),
      hasVariants: product.hasVariants || false,
    }

    const productOnlyAttributes = ['sku', 'slug', 'stock', 'defaultPhoto', 'hasVariants']

    return {
      ...omit(processedProduct, productOnlyAttributes),
      photo: processedProduct.defaultPhoto,
      product: processedProduct,
      total: +processedProduct.price,
      quantity: 1,
    }
  }, [])

  const upsertLineItem = useCallback(
    (lineItem, newQuantity) => {
      const predicate = ['product.id', lineItem.product.id]

      setLineItems(prevLineItems => {
        const targetIndex = findIndex(prevLineItems, predicate)

        if (targetIndex === -1) {
          return [...prevLineItems, lineItem]
        }

        if (newQuantity === 0) {
          prevLineItems.splice(targetIndex, 1)

          return [...prevLineItems]
        }

        const target = prevLineItems[targetIndex]

        target.quantity = newQuantity

        target.total = newQuantity * target.price

        // eslint-disable-next-line no-param-reassign
        prevLineItems[targetIndex] = target

        return [...prevLineItems]
      })
    },
    [setLineItems],
  )

  const removeLineItem = useCallback(lineItem => upsertLineItem(lineItem, 0), [setLineItems])

  return {
    getLineItemFromProduct,
    upsertLineItem,
    removeLineItem,
  }
}

export default useCartUpdater
