import styled, { css } from 'styled-components'

import theme from '~/truck/theme'

interface ContainerProps {
  $open: boolean
}

const resolveStyles = ({ $open }: ContainerProps) => {
  let pointerEvents = 'none'

  if ($open) {
    pointerEvents = 'auto'
  }

  return css`
    opacity: ${Number($open)};
    pointer-events: ${pointerEvents};
  `
}

export const Container = styled.div<ContainerProps>`
  background: ${theme.colors.black30};
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.2s ease-in-out;
  z-index: 99;
  ${resolveStyles};
`
