import gql from 'graphql-tag'

import { checkoutFragment } from '../fragments'

const setPurchaseOrderMutation = gql`
  mutation setPurchaseOrder($purchaseOrderUrl: String, $purchaseOrderNumber: String) {
    setPurchaseOrder(input: { purchaseOrderUrl: $purchaseOrderUrl, purchaseOrderNumber: $purchaseOrderNumber }) {
      order {
        ...checkout
      }
    }
  }
  ${checkoutFragment}
`

export default setPurchaseOrderMutation
