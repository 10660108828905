import React from 'react'
import styled from 'styled-components'

import Icon from '~/truck/icon'
import Label from '~/truck/label'
import theme from '~/truck/theme'

interface PropertyProps {
  label: string
  iconType: string
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;

  > svg {
    margin-right: ${theme.spacing.compact}px;
  }
`

export function Property(props: PropertyProps) {
  const { label, iconType } = props

  return (
    <Container>
      <Icon type={iconType} color="black" size="md" />
      <Label as="span" $textStyle="h6Semibold" $color="black">
        {label}
      </Label>
    </Container>
  )
}
