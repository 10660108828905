import * as React from 'react'
import { useQuery } from 'react-query'
import { assign, filter, flatMap, forEach, groupBy, isEmpty, isNil, map, orderBy, sortBy } from 'lodash'
import { useFlag } from 'toggled'

import {
  deserializeFilterValues,
  serializeFilterValues,
} from '~/components/filters/components/filters-provider/filters-serialize'
import { getProductFiltersQueryKey } from '~/components/filters/filters-queries'
import type { HTTPError } from '~/errors'
import {
  IProductFilerListType,
  IProductFilterQueryData,
  IProductFilterRangeType,
  IProductFilterTarget,
  IProductFilterType,
} from '~/types/product-filters'
import { formatPriceToString } from '~/utils'
import { flags } from '~/utils/constants'

import useCurrencyConfig from './use-currency-config'
import { useFiltersState } from './use-filters-state'

export function useFilters() {
  const currencyConfig = useCurrencyConfig()

  const hasAdvancedFiltersFF = useFlag(flags.ADVANCED_FILTERS)

  const { serializedFilters, fixedSerializedFilters, term, setNoFiltersAvailable } = useFiltersState()

  const queryKey = React.useMemo(() => {
    return getProductFiltersQueryKey(fixedSerializedFilters, term)
  }, [fixedSerializedFilters, term])

  const productFiltersQuery = useQuery<IProductFilterQueryData, HTTPError>(queryKey, {
    staleTime: Infinity,
    keepPreviousData: true,
    enabled: hasAdvancedFiltersFF,
  })

  const productFilters = React.useMemo(() => {
    const allProductFilters = orderBy(productFiltersQuery.data?.data ?? [], ['position'], ['asc'])

    return allProductFilters.map(productFilter => {
      if (productFilter.type !== IProductFilterType.LIST) {
        forEach(productFilter.values, (range: IProductFilterRangeType, index) => {
          const [serializedFilter] = filter(serializedFilters, item => item.startsWith(productFilter.target))

          assign(productFilter, {
            serializedFilter,
          })

          if (!serializedFilter) {
            assign(range, {
              value: null,
            })
          } else {
            const { values } = deserializeFilterValues(serializedFilter)

            const rangeValue = values[index]

            if (rangeValue) {
              assign(range, {
                value: rangeValue,
              })
            } else {
              assign(range, {
                value: null,
              })
            }
          }
        })

        return productFilter
      }

      if (productFilter.target === IProductFilterTarget.ATTRIBUTE) {
        const sortedProductFilterValues = sortBy(productFilter.values, 'label')

        assign(productFilter, {
          values: sortedProductFilterValues,
        })
      }

      productFilter.values.forEach(productFilterValue => {
        const valuesToSerialize: string[] = []

        if (productFilter.target === IProductFilterTarget.ATTRIBUTE) {
          valuesToSerialize.push(productFilter.key, productFilterValue.key)
        } else {
          valuesToSerialize.push(productFilterValue.key)
        }

        const serializedFilter = serializeFilterValues({
          target: productFilter.target,
          values: valuesToSerialize,
        })

        const selected = serializedFilters.includes(serializedFilter)

        assign(productFilterValue, {
          serializedFilter,
          selected,
        })

        return productFilterValue
      })

      return productFilter
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productFiltersQuery.dataUpdatedAt, serializedFilters])

  const selectedTags = React.useMemo(() => {
    const { list: lists, range: ranges } = groupBy(productFilters, 'type')

    const allFilterListValues = flatMap(lists, 'values') as IProductFilerListType[]

    const selectedFilterListValues = map(filter(allFilterListValues, 'selected'), item => ({
      serializedFilter: item.serializedFilter,
      label: item.label,
      type: IProductFilterType.LIST,
    }))

    const rangeFiltersSelected = map(ranges, rangeFilter => {
      const [minimum, maximum] = rangeFilter.values as IProductFilterRangeType[]

      if (isNil(maximum.value) && isNil(minimum.value)) {
        return null
      }

      let label: string

      if (isNil(maximum.value) && !isNil(minimum.value)) {
        label = `De ${formatPriceToString(minimum.value, currencyConfig)}`
      } else if (!isNil(maximum.value) && isNil(minimum.value)) {
        label = `A ${formatPriceToString(maximum.value, currencyConfig)}`
      } else {
        label = `De ${formatPriceToString(minimum.value, currencyConfig)} a ${formatPriceToString(
          maximum.value,
          currencyConfig,
        )}`
      }

      return {
        serializedFilter: `${rangeFilter.target}:|`,
        label: label,
        type: IProductFilterType.RANGE,
      }
    }).filter(Boolean)

    return [...selectedFilterListValues, ...rangeFiltersSelected]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productFilters])

  React.useEffect(() => {
    if (productFiltersQuery.isSuccess) {
      setNoFiltersAvailable(isEmpty(productFilters))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productFilters, productFiltersQuery.isSuccess])

  return {
    productFilters,
    selectedTags,
    isLoading: productFiltersQuery.isLoading,
  }
}
