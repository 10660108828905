import * as React from 'react'
import cookie from 'isomorphic-cookie'

import { ExpressDeliverySessionContext } from '~/contexts'
import type { IAddress, IShippingType, IStore } from '~/types/shipping'
import { getCookieConfig } from '~/utils'
import { cookieNames } from '~/utils/constants'

export interface ExpressDeliverySession {
  shippingType?: IShippingType
  deliveryAddress?: Pick<IAddress, 'id' | 'line' | 'coordinates'>
  store?: Pick<IStore, 'id' | 'name'>
}

export interface ExpressDeliveryContextValue {
  expressDeliverySession: ExpressDeliverySession
  setExpressDeliverySession: (value: ExpressDeliverySession) => void
}

const ExpressDeliverySessionProvider = props => {
  const { session, children } = props

  const [value, setValue] = React.useState<ExpressDeliverySession>(session)

  const contextValue = React.useMemo(() => {
    return {
      expressDeliverySession: value,
      setExpressDeliverySession: (newValue: ExpressDeliverySession) => {
        cookie.save(cookieNames.EXPRESS_DELIVERY_SESSION, newValue, getCookieConfig())

        setValue(newValue)
      },
    }
  }, [value])

  return (
    <ExpressDeliverySessionContext.Provider value={contextValue}>{children}</ExpressDeliverySessionContext.Provider>
  )
}

export default ExpressDeliverySessionProvider
