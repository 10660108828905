import type TruckTheme from '~/types/theme'

import defaultTheme from '../theme'

interface GetThemeProps {
  theme?: TruckTheme
}

const isEmpty = (theme: TruckTheme) => {
  return Object.keys(theme).length === 0
}

export default (props: GetThemeProps) => {
  const { theme } = props
  if (!theme || isEmpty(theme)) {
    return defaultTheme
  }

  return theme
}
