import * as React from 'react'
import { isEqual } from 'lodash'
import { useRouter } from 'next/router'

import type { IProductFilterTarget } from '~/types/product-filters'
import { queryString } from '~/utils'

import { SortSelectTypes } from '../sort-select'

import filtersReducer from './filters-reducer'
import type { FiltersState } from './types'

export interface FiltersProviderProps {
  term: string
  sortBy: string
  serializedInitialFilters: string[]
  serializedFixedInitialFilters: string[]
  children: React.ReactNode
}

export interface FiltersContextValue extends FiltersState {
  toggleListFilterValue: (value: string) => void
  setRangeFilter: (target: IProductFilterTarget, minimumValue?: number | string, maximumValue?: number | string) => void
  clearFilters: () => void
  setTotalOfResults: (totalOfResults: number) => void
  setNoFiltersAvailable: (noFiltersAvailable: boolean) => void
  setSortBy: (newSortBy: string) => void
}

const initialState: FiltersState = {
  noFiltersAvailable: false,
  totalOfResults: 0,
  sortBy: SortSelectTypes.PRODUCT_NAME_ASC,
  term: null,
  serializedFilters: [],
  fixedSerializedFilters: [],
}

export const FiltersContext = React.createContext<FiltersContextValue>(initialState as FiltersContextValue)

export function FiltersProvider(props: FiltersProviderProps) {
  const { children, serializedInitialFilters = [], serializedFixedInitialFilters = [] } = props

  const router = useRouter()

  const [state, dispatch] = React.useReducer(filtersReducer, {
    noFiltersAvailable: false,
    totalOfResults: initialState.totalOfResults,
    sortBy: props.sortBy || initialState.sortBy,
    term: props.term,
    serializedFilters: serializedInitialFilters,
    fixedSerializedFilters: serializedFixedInitialFilters,
  })

  const toggleListFilterValue = React.useCallback(
    (serializedFilter: string) => {
      dispatch({ type: 'TOGGLE_LIST_FILTER_VALUE', serializedFilter })
    },
    [dispatch],
  )

  const setRangeFilter = React.useCallback(
    (target: IProductFilterTarget, minimumValue?: number, maximumValue?: number) => {
      dispatch({ type: 'SET_RANGE_FILTER', target, minimumValue, maximumValue })
    },
    [dispatch],
  )

  const clearFilters = React.useCallback(() => {
    dispatch({ type: 'CLEAR_FILTERS' })
  }, [dispatch])

  const setTotalOfResults = React.useCallback(
    (totalOfResults: number) => {
      dispatch({ type: 'SET_TOTAL_OF_RESULTS', totalOfResults })
    },
    [dispatch],
  )

  const setNoFiltersAvailable = React.useCallback(
    (noFiltersAvailable: boolean) => {
      dispatch({ type: 'SET_NO_FILTERS_AVAILABLE', noFiltersAvailable })
    },
    [dispatch],
  )

  const setSortBy = React.useCallback(
    (newSortBy: string) => {
      dispatch({ type: 'SET_SORT_BY', sortBy: newSortBy })
    },
    [dispatch],
  )

  const contextValue = React.useMemo(
    (): FiltersContextValue => {
      return {
        ...state,
        toggleListFilterValue,
        setRangeFilter,
        clearFilters,
        setTotalOfResults,
        setNoFiltersAvailable,
        setSortBy,
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state],
  )

  React.useEffect(() => {
    const currentEndpoint = router.asPath

    const [currentPathname, currentQueryStrings] = currentEndpoint.split('?')

    /**
     * We need to support arrays when parsing the query string,
     * and that's why we're using `queryString.parse` and
     * because next-js doesn't support it correctly.
     */
    const currentParams = queryString.parse(currentQueryStrings)

    const currentFilters = (currentParams.filters ?? []).sort()

    const newFilters = state.serializedFilters.sort()

    const isSameFilters = isEqual(currentFilters, newFilters)

    const isSameSortBy = state.sortBy === currentParams.sortBy

    const isEqualToInitialSortBy = initialState.sortBy === state.sortBy

    const newParams = {
      ...currentParams,
      filters: newFilters,
      sortBy: isEqualToInitialSortBy ? null : state.sortBy,
    }

    if (isSameFilters && isSameSortBy) {
      return
    }

    const stringifiedQuery = queryString.stringify(newParams, {
      encodeValuesOnly: true,
      skipNulls: true,
    })

    const finalEndpoint = `${currentPathname}${stringifiedQuery ? '?' + stringifiedQuery : ''}`

    router.push(finalEndpoint, finalEndpoint, {
      shallow: true,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.serializedFilters, state.sortBy])

  return <FiltersContext.Provider value={contextValue}>{children}</FiltersContext.Provider>
}
