import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import theme from '~/truck/theme'

export const Container = styled.div`
  display: flex;
  gap: ${theme.spacing.comfortable}px;
  justify-content: center;
  margin: 0 ${theme.spacing.comfortable}px;

  ${up('lg')} {
    align-self: center;
    margin: 0;
  }
`
