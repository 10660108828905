import React, { useContext } from 'react'
import { map } from 'lodash'

import { ModalContext } from '~/contexts'

const renderModal = modal => {
  const { Modal, modalProps, id } = modal

  return <Modal {...{ ...modalProps, modalId: id }} key={id} />
}

const ModalCanvas = () => {
  const { state: modals } = useContext(ModalContext)

  if (!modals.length) {
    return null
  }

  return <>{map(modals, renderModal)}</>
}

export default ModalCanvas
