import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { includes } from 'lodash'

import { Loader } from '~/components'
import { documentTypesQuery } from '~/gql/queries'
import useFieldValue from '~/hooks/use-field-value'
import useFormatters from '~/hooks/use-formatters'
import useStrings from '~/hooks/use-strings'
import Field from '~/truck/field'
import Label from '~/truck/label'
import { documentTypes } from '~/utils/constants'

const DocumentFields = () => {
  const documentTypeIdWithSlug = useFieldValue('documentTypeIdWithSlug')

  const formatters = useFormatters()

  if (!documentTypeIdWithSlug) {
    return null
  }

  const isTicket = includes(documentTypeIdWithSlug, documentTypes.TICKET)

  if (isTicket) {
    return <Field name="documentNumber" placeholder="Número de documento" format={formatters.ticketDocumentNumber} />
  }

  return (
    <>
      <Field name="documentNumber" placeholder="Número de documento" format={formatters.billDocumentNumber} />
      <Field name="fiscalName" placeholder="Nombre comercial de la empresa" />
    </>
  )
}

const DocumentPageContent = () => {
  const strings = useStrings()

  const { data, loading } = useQuery(documentTypesQuery)

  if (loading) {
    return <Loader />
  }

  const documentTypesArray = data?.vendor?.documentTypes ?? []

  const renderOption = documentType => {
    const { id, slug } = documentType

    const isTicket = slug === documentTypes.TICKET

    const usage = isTicket ? 'Persona Natural' : 'Empresa'

    const key = `documentTypes.${slug}.identifier`

    const identifier = strings.getString(key)

    return (
      <option key={id} value={`${id}-${slug}`}>
        {`${usage} (${identifier})`}
      </option>
    )
  }

  return (
    <>
      <Label as="p" $textStyle="h4Semibold">
        Dirigido a
      </Label>
      <Field name="documentTypeIdWithSlug" as="select" prompt="Tipo de Documento">
        {documentTypesArray.map(renderOption)}
      </Field>
      <DocumentFields />
    </>
  )
}

export default DocumentPageContent
