import styled from 'styled-components'

import Anchor from '~/truck/anchor'
import Icon from '~/truck/icon'
import Label from '~/truck/label'
import theme from '~/truck/theme'

export const Container = styled.div`
  background-color: ${theme.colors.white};
  display: flex;
  flex-shrink: inherit;
  overflow: hidden;
  padding: ${theme.spacing.cozy}px;
  position: relative;
  user-select: none;

  > a {
    align-items: center;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    height: ${theme.spacing.cozy * 15}px;
    justify-content: center;
    width: ${theme.spacing.cozy * 16}px;

    > img {
      height: 100%;
      width: 100%;
    }
  }

  > span:last-of-type {
    bottom: ${theme.spacing.comfortable}px;
    position: absolute;
    right: ${theme.spacing.comfortable}px;
  }
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin-left: ${theme.spacing.comfortable}px;

  > p:not(:last-child) {
    margin-bottom: ${theme.spacing.compact}px;
  }
`

export const LabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
`

export const Paragraph = styled.p`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  text-align: center;

  > span:not(:last-child) {
    margin-bottom: ${theme.spacing.compact}px;
  }
`

export const StyledLabel = styled(Label)`
  margin-top: ${props => props.theme.spacing.compact}px;
`

export const ImageContainer = styled.div`
  align-items: center;
  display: flex;
  position: relative;
`

export const ContentContainer = styled.div`
  align-items: flex-start;
  flex: 1;
  position: relative;
`

export const StyledAnchor = styled(Anchor)<{ ellipsis?: boolean }>`
  ${({ ellipsis }) =>
    ellipsis &&
    `
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      overflow: hidden;
  `}
`

export const StyledTrashIcon = styled(Icon)`
  height: ${theme.spacing.relaxed}px;
  position: absolute;
  right: ${theme.spacing.compact}px;
  top: 0;
`

export const StyledTotalPriceContainer = styled.div`
  align-items: center;
  border-top: 1px solid ${theme.palette.grayscale40};
  bottom: 0;
  display: flex;
  justify-content: space-between;
  margin-top: ${theme.spacing.compact}px;
  padding-top: ${theme.spacing.cozy}px;
  position: relative;
  width: calc(100% - ${theme.spacing.cozy}px);
`
