import * as React from 'react'

import { StyledInput } from './elements.mobile'
import SearchBar from './index.base'

interface MobileProps {
  isOpen?: boolean
  debounced?: boolean
  [key: string]: unknown
}

function Mobile(props: MobileProps, ref: React.ForwardedRef<HTMLInputElement>) {
  const { isOpen, debounced, ...rest } = props

  return (
    <SearchBar debounced={debounced}>
      {metaProps => {
        return (
          <StyledInput
            ref={ref}
            $isOpen={isOpen}
            type="search"
            placeholder="¿Qué estás buscando?"
            {...metaProps}
            {...rest}
          />
        )
      }}
    </SearchBar>
  )
}

Mobile.displayName = 'SearchBar.Mobile'

export default React.forwardRef<HTMLInputElement, MobileProps>(Mobile)
