import styled from 'styled-components'

import Anchor from '~/truck/anchor'
import theme from '~/truck/theme'

import { Counter } from '../counter'

export const Container = styled.div`
  background-color: ${theme.colors.white};
  border: 1px solid #dfdfdf;
  border-radius: ${theme.dimensions.borderRadius}px;
  display: flex;
  flex-direction: column;
  height: ${theme.spacing.cozy * 53}px;
  overflow: hidden;
  padding: ${theme.spacing.comfortable}px;
  position: relative;
  user-select: none;
  width: 100%;

  a {
    cursor: pointer;
  }

  img {
    align-self: center;
    width: 100%;
  }

  > p {
    margin-bottom: ${theme.spacing.cozy}px;
  }
`

export const LabelsContainer = styled.div`
  align-items: stretch;
  background-color: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  margin-bottom: ${theme.spacing.compact}px;
  margin-top: ${theme.spacing.cozy}px;
  position: relative;
  z-index: 4;
`

export const StyledCounter = styled(Counter)`
  align-self: flex-end;
  justify-content: flex-end;
`

export const StyledAnchor = styled(Anchor)`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;

  /* We are assigning 2px of padding to give a little space to the letters that go down */
  padding-bottom: 2px;
`
