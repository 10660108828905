import React from 'react'
import { remove } from 'lodash'
import nanoid from 'nanoid'

import Alert from '../alert'

import { Container } from './elements'

// TODO: extend getNotification to return Messages too. Reference: src/Field

interface Notification {
  key: string
}

export interface ManagerProps {
  maxCount?: number
}

const getNotification = props => {
  const { notificationKey } = props

  return <Alert key={notificationKey} {...props} />
}

class Manager extends React.Component {
  state = {
    notifications: [],
  }

  props: ManagerProps

  componentWillUnmount() {
    this.timerIds.forEach(timerId => {
      clearTimeout(timerId)
    })
  }

  timerIds = []

  popNotification = notificationKey => {
    this.setState((prevState: { notifications: Notification[] }) => {
      const { notifications } = prevState

      remove(notifications, { key: notificationKey })

      return { notifications }
    })
  }

  addNotification = notificationProps => {
    const { maxCount = 10 } = this.props

    const { timeout = 3000 } = notificationProps

    const notificationKey = nanoid()

    const timerId = setTimeout(() => {
      this.popNotification(notificationKey)
    }, timeout)

    const onClose = () => {
      this.popNotification(notificationKey)
      clearTimeout(timerId)
    }

    const notification = getNotification({
      ...notificationProps,
      onClose,
      notificationKey,
    })

    const updateState = prevState => {
      const { notifications } = prevState

      notifications.unshift(notification)

      if (notifications.length > maxCount) {
        notifications.pop()
      }

      return { notifications }
    }

    this.setState(updateState)

    this.timerIds.push(timerId)
  }

  render() {
    const { notifications } = this.state

    return <Container>{notifications}</Container>
  }
}

export default Manager
