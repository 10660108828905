import gql from 'graphql-tag'

import { lineItemForCartFragment, priceLineFragment } from '../fragments'

const userCartQuery = gql`
  query userCart($runPromotions: Boolean) {
    user {
      id
      cart(runPromotions: $runPromotions) {
        id
        lineItemsTotal
        subTotal
        total
        lineItems: transactionItems {
          ...lineItemForCart
        }
        priceLines {
          ...priceLine
        }
      }
    }
  }
  ${priceLineFragment}
  ${lineItemForCartFragment}
`

export default userCartQuery
