import { down, up } from 'styled-breakpoints'
import styled from 'styled-components'

import Grid from '~/truck/grid'
import theme from '~/truck/theme'

export const FormWrapper = styled(Grid)`
  padding-top: ${theme.spacing.cozy}px;

  ${up('lg')} {
    padding-top: 0;
  }
`
export const StyledGridColumn = styled(Grid.Column)`
  ${down('sm')} {
    margin: ${props => props.theme.spacing.cozy}px;
  }
`
