import * as React from 'react'
import styled from 'styled-components'

import { useNanoid } from '~/hooks'
import useCounter, { UseCounterOptions } from '~/hooks/use-counter'
import Label from '~/truck/label'
import { reactStopPropagation } from '~/utils'

import { transitions } from './extended-counter/constants'
import ExtendedCounter from './extended-counter'
import { Knob } from './knob'

export interface CounterProps extends UseCounterOptions {
  style?: React.CSSProperties
  className?: string
  decimalLimit?: number
  onOpen?: () => void
  onClose?: () => void
  onClick?: () => void
  alwaysOpen?: boolean
  disableAnimation?: boolean
}

interface CounterErrorProps {
  message: string
  className?: string
}

const StyledContainer = styled.div<{ isOpen?: boolean }>`
  border-radius: ${props => props.theme.spacing.compact * 7}px;
  box-shadow: ${props => props.isOpen && '0px 1px 3px rgba(0, 0, 0, 0.2)'};
  display: flex;
  flex-direction: column;
  width: fit-content;

  > span {
    margin-top: ${props => props.theme.spacing.compact}px;
  }

  > button > svg {
    pointer-events: none;
  }
`

let counterLastId: string

const dataId = 'knob'

function useClickOutside(callback: (event: any) => void) {
  React.useEffect(() => {
    const listener = (event: MouseEvent) => callback(event)

    document.addEventListener('click', listener)

    return () => {
      document.removeEventListener('click', listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export function Counter(props: CounterProps) {
  const {
    style,
    className,
    decimalLimit,
    onOpen,
    onClose,
    onClick,
    alwaysOpen = false,
    jumpFactor,
    // FIXME: we have some issues with animations so we will temporarily disable them
    disableAnimation = true,
  } = props

  const [isOpen, setIsOpen] = React.useState(alwaysOpen)

  const extendedCounterRef = React.useRef(null)

  const {
    state: { value, displayValue, isMinValue },
    handlers,
  } = useCounter(props)

  const id = useNanoid()

  const open = () => {
    counterLastId = id

    setIsOpen(true)

    if (value === 0) {
      handlers.setValue(jumpFactor)
    }

    if (onOpen) {
      onOpen()
    }
  }

  const close = () => {
    if (alwaysOpen) {
      return
    }

    if (extendedCounterRef.current && !disableAnimation) {
      extendedCounterRef.current?.collapse()

      setTimeout(() => {
        setIsOpen(false)
      }, transitions.collapseAndFadeOut.duration)
    } else {
      setIsOpen(false)
    }

    if (onClose) {
      onClose()
    }
  }

  useClickOutside(event => {
    const isKnob = event.target.dataset.id === dataId

    if (counterLastId !== id || !isKnob) {
      close()
    }
  })

  const onMinusClick = (event: React.MouseEvent<SVGSVGElement | HTMLDivElement>) => {
    reactStopPropagation(event)

    if (isMinValue) {
      close()
    }

    handlers.decrement()
  }

  const onPlusClick = (event: React.MouseEvent<SVGSVGElement | HTMLDivElement>) => {
    reactStopPropagation(event)

    handlers.increment()
  }

  return (
    <StyledContainer style={style} className={className} onClick={onClick} isOpen={isOpen}>
      {isOpen ? (
        <ExtendedCounter
          ref={extendedCounterRef}
          value={value}
          displayValue={displayValue}
          decimalLimit={decimalLimit}
          isMinValue={isMinValue}
          onChange={value => handlers.setValue(value, true)}
          onBlur={value => handlers.setValue(value)}
          onPlusClick={onPlusClick}
          onMinusClick={onMinusClick}
          disableAnimation={disableAnimation}
        />
      ) : (
        <Knob dataId={dataId} value={value} onClick={open} />
      )}
    </StyledContainer>
  )
}

Counter.Error = (props: CounterErrorProps) => {
  const { message = null, className } = props

  return (
    message && (
      <Label as="span" $color="error" $textStyle="h6Semibold" $textAlign="center" className={className}>
        {message}
      </Label>
    )
  )
}
