import * as React from 'react'
import styled, { css } from 'styled-components'

export interface DialogContentProps {
  children: React.ReactNode
  className?: string
  gutterTop?: boolean
  gutterBottom?: boolean
  gutterXAxis?: boolean
}

const StyledContentWrapper = styled.div<{
  $gutterTop: boolean
  $gutterBottom: boolean
  $gutterXAxis: boolean
}>`
  position: relative;

  ${props =>
    props.$gutterXAxis &&
    css`
      padding-left: ${props => props.theme.spacing.cozy * 3}px;
      padding-right: ${props => props.theme.spacing.cozy * 3}px;
    `}

  ${props =>
    props.$gutterBottom &&
    css`
      padding-bottom: ${props => props.theme.spacing.cozy * 3}px;
    `}

  ${props =>
    props.$gutterTop &&
    css`
      padding-top: ${props => props.theme.spacing.cozy * 3}px;
    `}
`

export function BulkDialogContent(props: DialogContentProps) {
  const { children, gutterBottom, gutterTop, gutterXAxis, className } = props

  return (
    <StyledContentWrapper
      $gutterBottom={gutterBottom}
      $gutterTop={gutterTop}
      $gutterXAxis={gutterXAxis}
      className={className}
    >
      {children}
    </StyledContentWrapper>
  )
}
