import React, { useState } from 'react'
import { Form as FinalForm } from 'react-final-form'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import useVendor from '~/hooks/use-vendor'
import Anchor from '~/truck/anchor'
import Button from '~/truck/button'
import Field from '~/truck/field'
import Label from '~/truck/label'
import theme from '~/truck/theme'

import CircularBar from './circular-bar'

export const SectionContainer = styled.div`
  height: auto;
  position: relative;

  > input,
  > div {
    margin: 0;
    max-width: none;
    width: 100%;
  }

  > *:not(:last-child) {
    margin-bottom: ${theme.spacing.comfortable}px;
  }
`

export const Block = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: ${theme.spacing.cozy * 4}px;
  margin-top: ${theme.spacing.cozy * 4}px;
  width: 100%;

  ${up('lg')} {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 0;

    > button {
      width: fit-content;
    }
  }
`

export const ButtonsContainer = styled.section`
  margin-bottom: ${theme.spacing.comfortable}px;
  width: 100%;

  > button {
    padding: 0 ${theme.spacing.cozy * 4}px;
  }

  ${up('lg')} {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;

    > button {
      margin-right: 0;
      width: fit-content;
    }
  }
`

export const StyledLabel = styled(Label)`
  display: flex;
  width: fit-content;

  ${up('lg')} {
    display: flex;
    flex-shrink: 0;
    width: fit-content;
  }
`

const Wizard = props => {
  const { children, onSubmit } = props

  const [state, setState] = useState({
    pageIndex: 0,
    initialValues: {},
  })

  const vendor = useVendor()

  const pages = React.Children.toArray(children)

  const isLastPage = state.pageIndex === pages.length - 1

  const activePage = pages[state.pageIndex]

  function goNextPage(initialValues) {
    setState(current => ({
      pageIndex: Math.min(current.pageIndex + 1, pages.length - 1),
      initialValues: {
        ...current.initialValues,
        ...initialValues,
      },
    }))
  }

  function validate(values) {
    return activePage.props.validate?.(values) ?? {}
  }

  async function handleSubmit(values) {
    if (isLastPage) {
      return onSubmit(values)
    }

    goNextPage(values)
  }

  return (
    <FinalForm initialValues={state.initialValues} onSubmit={handleSubmit} validate={validate}>
      {formProps => {
        const canSubmit = !isLastPage || formProps.values.acceptedPrivacyPolicy

        return (
          <form onSubmit={formProps.handleSubmit}>
            {React.cloneElement(activePage, {
              step: state.pageIndex + 1,
              steps: pages.length,
            })}
            {isLastPage && (
              <Field
                style={{ marginTop: theme.spacing.cozy * 3 }}
                as="checkbox"
                name="acceptedPrivacyPolicy"
                hasText
                textStyle="h5Regular"
              >
                {'Declaro que he leído y acepto la '}
                <Anchor href="/politica-de-privacidad" target="_blank" $textStyle="h5Regular" $color="info">
                  Política de Privacidad
                </Anchor>
                {` de ${vendor.name}`}
              </Field>
            )}
            <Block>
              <StyledLabel as="p" $textStyle="h5Semibold">
                ¿Ya tienes una cuenta? &nbsp;
                <Anchor href="/ingresar" $textStyle="h5Semibold" $color="info">
                  Iniciar sesión
                </Anchor>
              </StyledLabel>
              <ButtonsContainer>
                <Button type="submit" color="secondary" loading={formProps.submitting} disabled={!canSubmit}>
                  {isLastPage ? 'Finalizar' : 'Siguiente'}
                </Button>
              </ButtonsContainer>
            </Block>
          </form>
        )
      }}
    </FinalForm>
  )
}

Wizard.Page = props => {
  const { steps, step, children } = props

  return (
    <SectionContainer>
      <CircularBar title="Regístrate para ver los precios" steps={steps} step={step} diameter={56} strokeWidth={5} />
      {children}
    </SectionContainer>
  )
}

export default Wizard
