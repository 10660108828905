const matchedCase = [new RegExp('[$@$!%*#?&!_.-]'), new RegExp('[A-Z]'), new RegExp('[0-9]'), new RegExp('[a-z]')]

const VALID_MIN_LENGTH = 8

const VALID_MEDIUM_LENGTH = 12

const getPasswordStrength = (password: string) => {
  const text = password.trim()

  if (!text) {
    return 0
  }

  if (text.length <= VALID_MIN_LENGTH) {
    return 1
  }

  const strength = matchedCase.reduce((value, regex) => {
    let matches = value

    if (regex.test(text)) {
      matches += 1
    }

    return matches
  }, -1)

  if (strength <= 1 && text.length >= VALID_MEDIUM_LENGTH) {
    return 2
  }

  return strength < 1 ? 1 : strength
}

export default getPasswordStrength
