import * as React from 'react'
import { Form as FinalForm, useForm } from 'react-final-form'
import { useApolloClient, useQuery } from '@apollo/react-hooks'
import cookie from 'isomorphic-cookie'
import { omit, pick } from 'lodash'
import { useRouter } from 'next/router'

import { clearCartMutation } from '~/gql/mutations'
import {
  deliveryAttentionQuery,
  isExpressDeliveryAvailableQuery,
  storeForExpressDeliveryAttentionQuery,
} from '~/gql/queries'
import { useFieldValue, useModalManager } from '~/hooks'
import useExpressDeliverySession from '~/hooks/use-express-delivery-session'
import useVendor from '~/hooks/use-vendor'
import type { ExpressDeliverySession } from '~/providers/express-delivery-session'
import Image from '~/truck/image'
import notifier from '~/truck/notifier'
import theme from '~/truck/theme'
import type { IShippingType } from '~/types/shipping'
import { forceReloadTo, getCookieConfig, handleSubmissionErrors, storage } from '~/utils'
import { cookieNames, images, shippingTypes as shippingTypeSlugs, storageKeys } from '~/utils/constants'

import Modal from '../modal'
import StoreSelector from '../store-selector'
import { DeleteDialog, Loader } from '..'

import DeliveryContent from './delivery-content'
import {
  AlertContainer,
  AlertTextContainer,
  ButtonContainer,
  ContentContainer,
  ScrollContainer,
  StyledAlertHeader,
  StyledAlertText,
  StyledButton,
  StyledDescription,
  StyledModal,
  StyledOverlay,
  StyledShippingOptions,
} from './elements'
import type { ModalContentProps, ModalProps } from './index'

interface IFormValues {
  addressId: string
  addressLine: string
  coordinates: any
  storeId: string
  storeName: string
  shippingTypeId: string
  addressComponents: any
}

function Content(props: ModalContentProps<IFormValues>) {
  const { formProps, shippingTypes, closable } = props

  const shippingTypeId = useFieldValue('shippingTypeId', {})

  const shippingType = shippingTypes.find(type => type.id === shippingTypeId)

  const { data, loading } = useQuery(isExpressDeliveryAvailableQuery, {
    fetchPolicy: 'network-only',
  })

  const hasSelectedExpressDelivery = shippingType.slug === shippingTypeSlugs.EXPRESS_DELIVERY

  const form = useForm()

  const hasRequiredLocationData = formProps.values?.storeId || formProps.values?.coordinates

  const isExpressDeliveryAvailable = data?.vendor?.isExpressDeliveryAvailable

  const isButtonDisabled = hasSelectedExpressDelivery ? !isExpressDeliveryAvailable : !hasRequiredLocationData

  const isRegularDelivery = shippingType.isDelivery && !hasSelectedExpressDelivery

  const { expressDeliverySession } = useExpressDeliverySession()

  React.useEffect(() => {
    if (expressDeliverySession?.shippingType?.id !== shippingTypeId) {
      form.change('addressLine', null)
    } else {
      form.change('addressLine', expressDeliverySession?.deliveryAddress?.line)
    }
  }, [expressDeliverySession, form, shippingTypeId])

  return (
    <StyledModal>
      <ScrollContainer>
        <Modal.Header closable={closable} title="Selecciona tu tipo de despacho" />
        <StyledShippingOptions dataSource={shippingTypes} />
        {loading ? (
          <Loader />
        ) : (
          <ContentContainer>
            {shippingType.description && (
              <StyledDescription $textStyle="h5Regular">{shippingType.description}</StyledDescription>
            )}
            {hasSelectedExpressDelivery && !isExpressDeliveryAvailable && (
              <AlertContainer $withDescription={!!shippingType.description}>
                <Image
                  src={images.ILLUSTRATION_FILE_ALERT}
                  height={theme.spacing.relaxed * 3}
                  width={theme.spacing.relaxed * 3}
                />
                <AlertTextContainer>
                  <StyledAlertHeader $textStyle="h5Semibold">{shippingType.name}</StyledAlertHeader>
                  <StyledAlertText $textStyle="h5Regular">No disponible en este momento.</StyledAlertText>
                </AlertTextContainer>
              </AlertContainer>
            )}
            {(isRegularDelivery || (hasSelectedExpressDelivery && isExpressDeliveryAvailable)) && (
              <DeliveryContent formProps={formProps} />
            )}
            {shippingType.isPickup && (
              <StoreSelector
                onlyPickables={true}
                onSelect={store => {
                  form.change('storeId', store.id)

                  form.change('storeName', store.name)
                }}
              />
            )}
          </ContentContainer>
        )}
      </ScrollContainer>
      <ButtonContainer>
        <StyledButton
          loading={formProps.submitting}
          onClick={formProps.handleSubmit}
          disabled={isButtonDisabled}
          color="secondary"
        >
          Continuar
        </StyledButton>
      </ButtonContainer>
    </StyledModal>
  )
}

function getInitialValues(session: ExpressDeliverySession, shippingTypes: IShippingType[]) {
  let initialValues: Partial<IFormValues> = {
    shippingTypeId: shippingTypes[0]?.id,
  }

  const { deliveryAddress, store, shippingType } = session ?? {}

  if (deliveryAddress) {
    initialValues = {
      ...initialValues,
      addressId: deliveryAddress.id,
      coordinates: deliveryAddress.coordinates,
      addressLine: deliveryAddress.line,
    }
  }

  if (store) {
    initialValues = {
      ...initialValues,
      storeId: store.id,
      storeName: store.name,
    }
  }

  if (shippingType) {
    initialValues.shippingTypeId = shippingType.id
  }

  return initialValues
}

function getHeaders(oldHeaders) {
  return omit(oldHeaders, [cookieNames.PARTNER_VENDOR_ENDPOINT])
}

function hasSessionSameValues(
  expressDeliverySession: ExpressDeliverySession = {},
  formValues: IFormValues,
  selectedShippingType: IShippingType,
) {
  const { deliveryAddress, store } = expressDeliverySession

  const isSameShippingType = selectedShippingType.id === expressDeliverySession?.shippingType?.id

  if (selectedShippingType.isDelivery && isSameShippingType && deliveryAddress) {
    return deliveryAddress.id === formValues.addressId && deliveryAddress.line === formValues.addressLine
  }

  if (selectedShippingType.isPickup && store) {
    return store.id === formValues.storeId
  }

  return false
}

function ExpressDeliveryShippingModal(props: ModalProps) {
  const { closable = true } = props

  const { shippingTypes } = useVendor()

  const { closeModal, openModal } = useModalManager()

  const { expressDeliverySession, setExpressDeliverySession } = useExpressDeliverySession()

  const apolloClient = useApolloClient()

  function saveExpressDeliverySession(values: IFormValues) {
    const { shippingTypeId } = values

    const shippingType = shippingTypes.find(shippingType => shippingType.id === shippingTypeId)

    const newSession: ExpressDeliverySession = {
      shippingType,
    }

    if (shippingType.isDelivery) {
      newSession.deliveryAddress = {
        id: values.addressId,
        coordinates: values.coordinates,
        line: values.addressLine,
      }
    } else {
      newSession.store = {
        id: values.storeId,
        name: values.storeName,
      }
    }

    setExpressDeliverySession(newSession)

    if (values.addressComponents) {
      storage.set(storageKeys.ADDRESS_COMPONENTS, values.addressComponents)
    }
  }

  const router = useRouter()

  const onSubmit = async (values: IFormValues) => {
    const shippingType = shippingTypes.find(shippingType => shippingType.id === values.shippingTypeId)

    const hasSelectedExpressDelivery = shippingType.slug === shippingTypeSlugs.EXPRESS_DELIVERY

    const hasCompleteAddress = values.coordinates && values.addressLine

    if (shippingType.isDelivery && !hasCompleteAddress) {
      return notifier.error('Ingresa una dirección')
    }

    if (shippingType.isPickup && !values.storeId) {
      return notifier.error('Selecciona una tienda')
    }

    async function saveSessionAndReload(storeId: string) {
      saveExpressDeliverySession(values)

      cookie.save(cookieNames.STORE_ID, storeId, getCookieConfig())

      await apolloClient.mutate({ mutation: clearCartMutation })

      if (router.pathname === '/product') {
        forceReloadTo('')

        return
      }

      const callbackUrl = cookie.load(cookieNames.CALLBACK_URL)

      if (callbackUrl) {
        cookie.remove(cookieNames.CALLBACK_URL)
      }

      forceReloadTo(callbackUrl ?? '/')
    }

    async function getStoreInAttention() {
      if (!hasSelectedExpressDelivery) {
        return {
          storeId: values.storeId,
        }
      }

      const {
        data: { storeForExpressDeliveryAttention },
      } = await apolloClient.query({
        query: storeForExpressDeliveryAttentionQuery,
        context: { getHeaders },
        fetchPolicy: 'network-only',
        variables: {
          coordinates: pick(values.coordinates, ['latitude', 'longitude']),
        },
      })

      return storeForExpressDeliveryAttention
    }

    async function getDeliveryAttention(shippingTypeId) {
      const response = await apolloClient.query({
        query: deliveryAttentionQuery,
        context: { getHeaders },
        fetchPolicy: 'network-only',
        variables: {
          coordinates: pick(values.coordinates, ['latitude', 'longitude']),
          shippingTypeId,
        },
      })

      return response.data.vendor.deliveryAttention
    }

    try {
      if (shippingType.slug === shippingTypeSlugs.DELIVERY) {
        const deliveryAttention = await getDeliveryAttention(shippingType.id)

        if (!deliveryAttention.coverageIsAvailable) {
          return notifier.error('La dirección se encuentra fuera de nuestra zona de cobertura.')
        }
      }

      if (hasSessionSameValues(expressDeliverySession, values, shippingType)) {
        return closeModal()
      }

      const isInExpressDeliveryMode = expressDeliverySession?.shippingType?.slug === shippingTypeSlugs.EXPRESS_DELIVERY

      const { storeId } = await getStoreInAttention()

      const oldStoreId = cookie.load(cookieNames.STORE_ID)

      const isSameAttentionStore = storeId === oldStoreId

      if (
        (!isInExpressDeliveryMode && !hasSelectedExpressDelivery) ||
        (isInExpressDeliveryMode && hasSelectedExpressDelivery && isSameAttentionStore)
      ) {
        saveExpressDeliverySession(values)

        return closeModal()
      }

      closeModal()

      const isSameShippingType = shippingType.id === expressDeliverySession?.shippingType?.id

      openModal(DeleteDialog, {
        header: isSameShippingType ? 'Cambiar dirección' : 'Cambiar método de envío',
        content: `
          Al hacer este cambio perderás los productos guardados en tu carrito
          de compra. ¿Estás seguro que deseas realizar el cambio?
        `,
        onClose: closeModal,
        onAccept: async () => await saveSessionAndReload(storeId),
      })
    } catch (error) {
      return handleSubmissionErrors(error)
    }
  }

  const initialValues = React.useMemo(
    () => getInitialValues(expressDeliverySession, shippingTypes),
    [expressDeliverySession, shippingTypes],
  )

  return (
    <StyledOverlay open>
      <FinalForm<IFormValues> initialValues={initialValues} onSubmit={onSubmit}>
        {formProps => <Content formProps={formProps} shippingTypes={shippingTypes} closable={closable} />}
      </FinalForm>
    </StyledOverlay>
  )
}

export default ExpressDeliveryShippingModal
