import gql from 'graphql-tag'

const storeForExpressDeliveryAttention = gql`
  query storeForExpressDeliveryAttention($coordinates: CoordinatesInput!) {
    storeForExpressDeliveryAttention(coordinates: $coordinates) {
      storeId
    }
  }
`

export default storeForExpressDeliveryAttention
