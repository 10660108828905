import gql from 'graphql-tag'

import { lineOfCreditFragment } from '../fragments'

const userLineOfCreditQuery = gql`
  query userLineOfCredit {
    user {
      id
      lineOfCredit {
        ...lineOfCredit
      }
    }
  }

  ${lineOfCreditFragment}
`

export default userLineOfCreditQuery
