import { cancellableError } from './cancellableError'

/**
 * Send a promise and a function to cancel it,
 * if after completing the promise the function returns true,
 * then the promise fails with `error.isCanceled = true`.
 *
 * @param promise Promise to call
 * @param fnCancel Function to cancel
 *
 * @returns Promise with the value or error returning `promise`, or an error if cancelled.
 */
export function cancellablePromiseAfter<T>(promise: Promise<T>, fnCancel: () => boolean): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    promise
      .then(result => {
        const isCanceled = fnCancel()

        if (isCanceled) {
          reject(cancellableError)

          return
        }

        resolve(result)
      })
      .catch(reject)
  })
}
