import * as React from 'react'
import styled from 'styled-components'
import { useFlagQueryFn } from 'toggled'

import { useIsMobile } from '~/hooks'
// TODO(refactor/cart): this is temporal, we need create a controller inside shopping cart module to open cart drawer
import { useUiStore } from '~/modules/shopping-cart/stores/ui-store'
import Button from '~/truck/button'
import { forceReloadTo } from '~/utils'
import { flagQueries, images } from '~/utils/constants'

import { BulkDialogResultContent, BulkDialogResultInfo } from '../bulk-dialog'
import { useDialogState } from '../dialog-provider'
import { DialogStep } from '../dialog-provider/types'

const StyledButton = styled(Button)`
  width: auto;
`

const StyledGhostButton = styled(Button.Ghost)`
  width: auto;
`

const StyledActionsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${props => props.theme.spacing.cozy * 2}px;
`

export function DialogPartiallyProcessedStepContent() {
  const { setStep, onModalClose } = useDialogState()

  const openCartDrawer = useUiStore(store => store.openCartDrawer)

  const isMobile = useIsMobile()

  const flagQuery = useFlagQueryFn()

  const onSeeCartClick = () => {
    if (isMobile || !flagQuery(flagQueries.HAS_FULL_WIDTH)) {
      forceReloadTo('/carrito')
    } else {
      openCartDrawer()
    }

    onModalClose()
  }

  const onStartProcessAgain = () => {
    setStep(DialogStep.START_PROCESS, [], [])
  }

  return (
    <BulkDialogResultContent
      imageSrc={images.ILLUSTRATION_WARNING}
      description="Se han agregado al carrito menos productos y cantidades de las solicitadas"
      renderContent={() => <BulkDialogResultInfo />}
      renderFooter={() => (
        <StyledActionsContainer>
          <StyledGhostButton type="button" color="grayscale70" onClick={onStartProcessAgain}>
            {isMobile ? 'Volver' : 'Volver a carga masiva'}
          </StyledGhostButton>
          <StyledButton type="button" color="primary" onClick={onSeeCartClick}>
            Ver carrito
          </StyledButton>
        </StyledActionsContainer>
      )}
    />
  )
}
