import { filter, isNaN, isNil, size, sumBy } from 'lodash'
import validate from 'validate.js'

import { mixpanel } from '~/metrics'
import type { IVendor } from '~/types/vendors'
import { events } from '~/utils/constants'
import { getBasicEventPayloadFromVendor } from '~/utils/mixpanel/get-basic-event-payload-from-vendor'

import { BulkOrderingResultErrorType } from '../../types'

import type {
  FormValueFieldGroup,
  FormValueFieldGroupObject,
  FormValues,
  PutCartMutationResult,
  UpdatedCart,
} from './types'

export const MAX_NUMBER_OF_LINES = 50

const INVALID_FORMAT_ERROR_MESSAGE = 'El formato ingresado es incorrecto'

export function parseInputToJSON(input: string) {
  const lines = input.split('\n').slice(0, MAX_NUMBER_OF_LINES)

  if (lines[lines.length - 1] === '') {
    lines.pop()
  }

  const errors = []

  const result = []

  lines.forEach((line, index) => {
    const values = line.split('\t')

    const lineNumber = index + 1

    if (values.length !== 2) {
      errors.push({
        lineNumber,
        message: INVALID_FORMAT_ERROR_MESSAGE,
      })

      return
    }

    const [inputSku, inputQuantity] = values as [string, string]

    const quantityTrimmed = inputQuantity.trim()

    if (quantityTrimmed === '') {
      errors.push({
        lineNumber,
        message: INVALID_FORMAT_ERROR_MESSAGE,
      })

      return
    }

    const quantityNumber = Number(quantityTrimmed)

    if (isNaN(quantityNumber)) {
      errors.push({
        lineNumber,
        message: INVALID_FORMAT_ERROR_MESSAGE,
      })

      return
    }

    if (quantityNumber <= 0) {
      errors.push({
        lineNumber,
        message: INVALID_FORMAT_ERROR_MESSAGE,
      })

      return
    }

    result.push({
      sku: inputSku,
      quantity: quantityNumber,
    })
  })

  return { result, errors }
}

export function parseInputsToJSON(formValues: FormValueFieldGroupObject): UpdatedCart {
  const response: UpdatedCart = { errors: [], result: [] }

  Object.values(formValues).forEach((formValue: FormValueFieldGroup, index) => {
    const { sku, quantity: stringQuantity } = formValue

    const quantity = Number(stringQuantity)

    if (sku && quantity) {
      response.result.push({ sku, quantity })
    }

    if ((sku && !stringQuantity) || (stringQuantity && !sku)) {
      const lineNumber = index + 1

      response.errors.push({ lineNumber, message: INVALID_FORMAT_ERROR_MESSAGE })
    }
  })

  return response
}

export function onValidate(formValues: FormValues) {
  const input = validate.single(formValues.input, {
    presence: {
      message: 'Este campo es requerido',
    },
  })

  if (!isNil(input)) {
    return { input }
  }

  const { errors } = parseInputToJSON(formValues.input)

  if (errors.length > 0) {
    return {
      input: errors[0].message,
    }
  }

  return null
}

export function onValidateInputs(formValues: FormValueFieldGroupObject) {
  const { result, errors } = parseInputsToJSON(formValues)

  if (result.length === 0) {
    return { errors: 'No se ha completado ningún campo' }
  }

  if (errors.length > 0) {
    return { errors: errors[0].message }
  }

  return null
}

export function trackBulkOrderingProcessFinished(processResponse: PutCartMutationResult, vendor: IVendor): void {
  const errors = processResponse.data.errors

  const operations = processResponse.data.operations

  const payload = getBasicEventPayloadFromVendor(vendor)

  payload.orderId = processResponse.data.cartId

  payload.numberOfItemsTotal = size(operations) + size(errors)

  payload.numberOfItemsAddedToCart = size(operations)

  payload.numberOfItemsNotAddedToCart = size(errors)

  payload.numberOfItemsWithoutStock = size(
    filter(errors, {
      type: BulkOrderingResultErrorType.PRODUCT_WITHOUT_STOCK,
    }),
  )

  payload.numberOfItemsNotAvailable = size(
    filter(errors, {
      type: BulkOrderingResultErrorType.PRODUCT_NOT_AVAILABLE,
    }),
  )

  payload.numberOfItemsValidatedWithMinimumSalesQuantityRule = size(
    filter(errors, {
      type: BulkOrderingResultErrorType.PRODUCT_VALIDATED_WITH_MINIMUM_SALES_QUANTITY_RULE,
    }),
  )

  const operationsWithAffectedQuantity = filter(
    operations,
    operation => operation.data.appliedQuantity !== operation.data.proposalQuantity,
  )

  const operationsWithInsufficientStock = filter(operationsWithAffectedQuantity, {
    data: {
      appliedMaximumStockQuantityRule: true,
    },
  })

  payload.numberOfItemsWithInsufficientStock = size(operationsWithInsufficientStock)

  const operationsWithInsufficientSalesQuantity = filter(operationsWithAffectedQuantity, {
    data: {
      appliedMaximumSalesQuantityRule: true,
    },
  })

  payload.numberOfItemsWithInsufficientSalesQuantity = size(operationsWithInsufficientSalesQuantity)

  payload.quantityTotalAddedToCart = sumBy(operations, 'data.appliedQuantity')

  payload.quantityTotalNotAddedToCart =
    sumBy(errors, 'data.proposalQuantity') +
    sumBy(operationsWithInsufficientStock, operation => {
      return operation.data.proposalQuantity - operation.data.appliedQuantity
    })

  mixpanel.track(events.BULK_ORDERING_PROCESS_FINISHED, payload)
}
