import { AnalyticsCollector, AnalyticsCollectorOptions, AnalyticsEvents } from './analytics-types'

function logger(event: string) {
  return function (variables: Record<string, any>, options?: AnalyticsCollectorOptions) {
    console.log(`[analytics-debug][${event}]`, { variables, options })
  }
}

export const analyticsDebug: AnalyticsCollector = {
  pageView: logger(AnalyticsEvents.PAGE_VIEW),
  search: logger(AnalyticsEvents.SEARCH),
  selectItem: logger(AnalyticsEvents.SELECT_ITEM),
  viewItem: logger(AnalyticsEvents.VIEW_ITEM),
  viewItemList: logger(AnalyticsEvents.VIEW_ITEM_LIST),
  addToCart: logger(AnalyticsEvents.ADD_TO_CART),
  removeFromCart: logger(AnalyticsEvents.REMOVE_FROM_CART),
  viewCart: logger(AnalyticsEvents.VIEW_CART),
  beginCheckout: logger(AnalyticsEvents.BEGIN_CHECKOUT),
  checkoutProgress: logger(AnalyticsEvents.CHECKOUT_PROGRESS),
  purchase: logger(AnalyticsEvents.PURCHASE),
  login: logger(AnalyticsEvents.LOGIN),
  signUp: logger(AnalyticsEvents.SIGN_UP),
  // @ts-expect-error ts(2322)
  preSignUpAttempt: logger(AnalyticsEvents.PRE_SIGN_UP_ATTEMPT),
  paymentError: logger('payment_error'),
  contact: logger(AnalyticsEvents.CONTACT),
  socialMedia: logger(AnalyticsEvents.SOCIAL_MEDIA),
  viewPromotion: logger(AnalyticsEvents.VIEW_PROMOTION),
  selectPromotion: logger(AnalyticsEvents.SELECT_PROMOTION),
  closeSuggestedProducts: logger(AnalyticsEvents.CLOSE_SUGGESTED_PRODUCTS),
}
