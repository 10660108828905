import { buyingInStoreQuery } from '~/gql/queries'

const queryConfig = {
  query: buyingInStoreQuery,
}

const fetchBuyingInStore = async apolloClient => {
  const response = await apolloClient.query(queryConfig)

  return response.data.buyingInStore
}

export default fetchBuyingInStore
