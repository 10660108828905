import gql from 'graphql-tag'

const cartTotalQuantityQuery = gql`
  query cartTotalQuantity {
    user {
      id
      cart {
        id
        lineItems: transactionItems {
          id
          originalQuantity
        }
      }
    }
  }
`

export default cartTotalQuantityQuery
