import { filter, isEmpty, map, pick, values } from 'lodash'

import { divisionLevelsFields } from '~/hooks/use-location-selector'

const address = {
  locationLine: root => {
    const divisionLevels = filter(values(pick(root, divisionLevelsFields)))

    if (!isEmpty(divisionLevels)) {
      return divisionLevels.join(', ')
    }

    return map(root.location, 'name').join(', ')
  },
}

export default address
