function decorator(fn) {
  return (...args) => {
    try {
      return fn(...args)
    } catch (e) {
      console.error('localStorage is disabled or unsupported')
    }
  }
}

function isValidJSONString(jsonString: string) {
  try {
    JSON.parse(jsonString)
  } catch (e) {
    return false
  }

  return true
}

const storage = {
  get: decorator((key, defaultValue) => {
    if (!(key in localStorage)) {
      return defaultValue
    }

    const item = localStorage.getItem(key)

    if (isValidJSONString(item)) {
      return JSON.parse(item)
    }

    return item
  }),
  set: decorator((key, value) => {
    localStorage.setItem(key, JSON.stringify(value))
  }),
  has: decorator(key => {
    return Boolean(storage.get(key))
  }),
}

export default storage
