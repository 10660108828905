import * as React from 'react'
import { useQuery } from '@apollo/react-hooks'

import { coverageQuery } from '~/gql/queries'
import useBuyingInStore from '~/hooks/use-buying-in-store'
import { getCoverageTree } from '~/utils'

function useCoverage() {
  const buyingInStore = useBuyingInStore()

  const { data, loading } = useQuery(coverageQuery, {
    variables: {
      isBuyingInStore: !!buyingInStore,
    },
  })

  const allLocalities = React.useMemo(() => (data?.vendor?.coverage || data?.buyingInStore?.coverage) ?? [], [data])

  const coverageTree = React.useMemo(() => getCoverageTree(allLocalities), [allLocalities])

  const coverageImage = data?.vendor?.coverageImage

  return {
    loading,
    coverageTree,
    coverageImage,
    allLocalities,
  }
}

export default useCoverage
