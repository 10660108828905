import React, { useState } from 'react'
import { useField } from 'react-final-form'
import { clsx } from 'clsx'

import TextField from '~/components/text-field'
import Icon from '~/truck/icon'

import PasswordStrength from './PasswordStrength'

interface ChangePasswordFieldsProps {
  className?: string
}

function ChangePasswordFields({ className }: ChangePasswordFieldsProps) {
  const { input } = useField('newPassword')

  const [showPassword, setShowPassword] = useState(false)

  const onShowPassword = () => setShowPassword(!showPassword)

  const iconType = showPassword ? 'eye' : 'eye-off'

  const inputType = showPassword ? 'text' : 'password'

  return (
    <div className={clsx('grid grid-cols-1 gap-4 lg:grid-cols-2', className)}>
      <TextField
        as="input"
        name="newPassword"
        type={inputType}
        label="Nueva contraseña"
        rightItem={<Icon type={iconType} onClick={onShowPassword} />}
      />
      <TextField
        as="input"
        name="confirmPassword"
        type={inputType}
        label="Confirmar contraseña"
        rightItem={<Icon type={iconType} onClick={onShowPassword} />}
      />
      <PasswordStrength className="lg:col-span-2" value={input.value} />
    </div>
  )
}

export default ChangePasswordFields
