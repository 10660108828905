import React from 'react'

import { ErrorComponent } from '~/components'
import DefaultLayout from '~/layouts/default'

const ErrorScreen = props => {
  return (
    <DefaultLayout>
      <ErrorComponent {...props} />
    </DefaultLayout>
  )
}

export default ErrorScreen
