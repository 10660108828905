import gql from 'graphql-tag'

const jobQuery = gql`
  query job($id: ID!) {
    job: node(id: $id) {
      ... on Job {
        id
        state
        returnValue
        failedReason
      }
    }
  }
`

export default jobQuery
