import * as React from 'react'
import styled from 'styled-components'

import Icon from '~/truck/icon'

interface TagCloseButtonProps {
  onClick: () => void
}

const StyledButton = styled.button`
  background: transparent;
  border: none;
  display: flex;
  margin-left: ${props => props.theme.spacing.cozy / 2}px;
`

const StyledIcon = styled(Icon)`
  padding: ${props => props.theme.spacing.cozy * 0.125}px;
`

export function TagCloseButton(props: TagCloseButtonProps) {
  const { onClick } = props

  return (
    <StyledButton onClick={onClick}>
      <StyledIcon size="sm" type="close" color="primary40" />
    </StyledButton>
  )
}
