import { up } from 'styled-breakpoints'
import styled, { ThemedStyledProps } from 'styled-components'

import theme from '~/truck/theme'

interface ContainerProps {
  $selected?: boolean
}

function getColorStyle(props: ThemedStyledProps<ContainerProps, any>) {
  let color = props.theme.colors.black30

  if (props.$selected) {
    color = props.theme.colors.primary
  }

  return `
    border: 1px solid ${color};

    > svg {
      fill: ${color};
    }

    > span {
      color: ${color};
    }
  `
}

export const Container = styled.button<ContainerProps>`
  align-items: center;
  background: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.cozy}px;
  justify-content: center;
  outline: none;
  padding: ${theme.spacing.comfortable}px;
  transition: all 150ms ease-in-out;
  width: ${theme.spacing.cozy * 19}px;

  &,
  * {
    cursor: pointer;
  }

  > svg {
    height: ${theme.sizes.lg}px;
    width: ${theme.sizes.lg}px;

    ${up('lg')} {
      height: ${theme.sizes.xl}px;
      width: ${theme.sizes.xl}px;
    }
  }

  > span {
    font-size: ${theme.spacing.compact * 3}px;

    ${up('lg')} {
      font-size: ${theme.spacing.comfortable}px;
    }
  }

  ${getColorStyle};
`
