import gql from 'graphql-tag'

const signupMutation = gql`
  mutation signup(
    $firstName: String!
    $lastName: String!
    $phone: String!
    $email: String!
    $password: String!
    $acceptedTermsAndConditions: Boolean
    $allowAdvertising: Boolean
    $acceptedPrivacyPolicy: Boolean
    $birthdate: Date
  ) {
    signup(
      input: {
        firstName: $firstName
        lastName: $lastName
        phone: $phone
        email: $email
        password: $password
        acceptedTermsAndConditions: $acceptedTermsAndConditions
        allowAdvertising: $allowAdvertising
        acceptedPrivacyPolicy: $acceptedPrivacyPolicy
        birthdate: $birthdate
      }
    ) {
      token
      role
    }
  }
`

export default signupMutation
