import gql from 'graphql-tag'

const cartLineItemsTotalQuery = gql`
  query cartLineItemsTotal {
    user {
      id
      cart {
        id
        lineItems {
          id
          name
          quantity
          price
          product {
            id
            sku
            category {
              id
              name
              parent {
                id
                name
              }
            }
          }
        }
        total
        subTotal
        lineItemsTotal
      }
    }
  }
`

export default cartLineItemsTotalQuery
