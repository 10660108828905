import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import ShippingOptions from '~/screens/shipping/options'
import Button from '~/truck/button'
import Label from '~/truck/label'
import Overlay from '~/truck/overlay'
import theme from '~/truck/theme'
import { stack } from '~/variables'

import Modal from '../modal'

export const StyledShippingOptions = styled(ShippingOptions)`
  /* To increase specificity */
  & {
    gap: ${theme.spacing.compact * 3}px;
    margin: 0;
  }

  > button {
    padding: ${theme.spacing.compact * 3.25}px;
  }

  > button > svg {
    height: ${theme.spacing.cozy * 5}px;
    width: ${theme.spacing.cozy * 5}px;
  }

  > button > span {
    font-size: ${theme.sizes.sm}px;
  }
`

export const StyledTitle = styled(Label)`
  margin-bottom: ${theme.spacing.comfortable}px;
  min-height: auto;

  ${up('lg')} {
    margin-bottom: ${theme.spacing.cozy}px;
  }
`

export const StyledButton = styled(Button)`
  ${up('lg')} {
    width: ${theme.spacing.cozy * 26}px;
  }
`

export const StyledOverlay = styled(Overlay)<{ $bgImage?: string }>`
  height: 100%;

  ${props =>
    props.$bgImage &&
    `
    background: url(${props.$bgImage}) no-repeat center;
    background-size: cover;
  `}
`

export const StyledModal = styled(Modal.Standalone)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: unset;
  padding: 0;

  ${up('lg')} {
    height: ${theme.spacing.cozy * 106}px;
    max-height: calc(100vh - ${theme.spacing.cozy * 4}px);
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  padding: ${theme.spacing.comfortable}px;
  z-index: ${stack.mapMarker + 1};

  ${up('lg')} {
    justify-content: flex-end;
    padding: ${theme.spacing.comfortable}px ${theme.spacing.cozy * 4}px;
  }
`

export const ScrollContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  padding: ${theme.spacing.comfortable}px;
  padding-bottom: 0;

  > div:first-child {
    ${up('lg')} {
      margin-bottom: ${theme.spacing.cozy * 4}px;
    }
  }

  ${up('lg')} {
    padding: ${theme.spacing.cozy * 4}px;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: ${theme.spacing.relaxed}px;

  position: relative;

  ${up('lg')} {
    margin-top: ${theme.spacing.comfortable * 2}px;
  }
`

export const AlertContainer = styled.div<{ $withDescription: boolean }>`
  align-items: center;
  background: ${theme.palette.grayscale30};
  border-radius: ${theme.dimensions.borderRadius}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${({ $withDescription }) => ($withDescription ? 0 : theme.spacing.compact * 5)}px;

  min-height: auto;

  padding: ${theme.spacing.relaxed}px;

  ${up('lg')} {
    flex-direction: row;
    margin-top: ${({ $withDescription }) => ($withDescription ? 0 : theme.spacing.compact * 7)}px;
  }
`

export const AlertTextContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin-top: ${theme.spacing.cozy}px;
  white-space: nowrap;

  ${up('lg')} {
    align-items: start;
    margin-left: ${theme.spacing.relaxed}px;
    margin-top: 0;
  }
`
export const StyledAlertText = styled(Label)`
  color: ${theme.palette.grayscale60};
  margin-top: ${theme.spacing.compact}px;
`

export const StyledAlertHeader = styled(Label)`
  color: ${theme.palette.grayscale60};
`

export const StyledDescription = styled(Label)`
  margin-bottom: ${theme.spacing.compact * 5}px;
  min-height: auto;
  text-align: center;

  ${up('lg')} {
    margin-bottom: ${theme.spacing.compact * 7}px;
  }
`
