import React from 'react'

import Image from '~/truck/image'
import { cloudinary } from '~/utils'

import Overlay from '../overlay'

import { Container } from './elements'

const getPhotoUrl = props => {
  const { photo, unavailable } = props

  if (!unavailable) {
    return photo
  }

  return cloudinary.applyTransformations(photo, 'e_grayscale')
}

const renderOutOfStock = props => {
  const { stock, unavailable, asLineItem, label, variantInStock, canJumpAtLeastOnce } = props

  const canSkip = (stock > 0 && canJumpAtLeastOnce) || stock === null || unavailable || asLineItem || variantInStock

  if (canSkip) {
    return null
  }

  return (
    <>
      <Overlay style={{ zIndex: 0 }} />
      {label}
    </>
  )
}

const ProductImage = props => {
  const { href, name, height, width, unavailable, onClick, style, className } = props

  if (unavailable) {
    return (
      <Image
        src={getPhotoUrl(props)}
        alt={name}
        height={height}
        width={width}
        style={{ ...style, flexShrink: 0 }}
        onClick={onClick}
        className={className}
      />
    )
  }

  return (
    <Container href={href} style={style} className={className}>
      {renderOutOfStock(props)}
      <Image src={getPhotoUrl(props)} alt={name} height={height} width={width} style={{ minHeight: height }} />
    </Container>
  )
}

export default ProductImage
