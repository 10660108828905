import maskMaker from 'text-mask-addons/dist/createNumberMask'

export const normalizeAmount = (decimalLimit = 0, value) => {
  if (value.endsWith('.')) {
    return value
  }

  const [integers, decimals = ''] = value.split('.')

  const digits = decimals.slice(0, decimalLimit)

  const newValue = parseFloat(`${integers}.${digits}`)

  if (Number.isNaN(newValue)) {
    return ''
  }

  return newValue
}

export const createNumberMask = (integerLimit = null, decimalLimit = 0) => {
  return maskMaker({
    prefix: '',
    suffix: '',
    allowDecimal: decimalLimit > 0,
    thousandsSeparatorSymbol: '',
    allowLeadingZeroes: true,
    decimalLimit,
    integerLimit,
  })
}

export const createPlaceholder = (decimalLimit = 0) => {
  let placeholder = '1234'

  if (decimalLimit > 0) {
    placeholder = `${placeholder}.${'0'.repeat(decimalLimit)}`
  }

  return placeholder
}
