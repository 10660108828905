import React from 'react'
import Media from 'react-media'
import styled from 'styled-components'

import Modal from '~/components/modal'
import { useModalManager } from '~/hooks'
import Icon from '~/truck/icon'
import theme from '~/truck/theme'

const Container = styled(Modal)`
  height: 100%;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;

  iframe {
    border: none;
    height: calc(100% + 522px);
    margin-top: -522px;
    width: 100%;
  }

  @media (max-width: 767px) {
    iframe {
      height: calc(100% + 435px);
      margin-top: -435px;
    }
  }

  /* Desktop */
  @media (min-width: 960px) {
    max-width: 1100px;
    max-height: 700px;
    z-index: 500;
    border-radius: ${theme.spacing.cozy}px;
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.06);

    iframe {
      height: calc(100% + 88px);
      margin-top: -88px;
    }
  }
`

const ModalCloseButton = styled.div`
  padding: ${theme.spacing.cozy}px;
  position: absolute;
  right: ${theme.spacing.comfortable}px;
  top: ${theme.spacing.cozy}px;

  svg {
    display: block;
    fill: #9fa4a8;
  }

  /* Desktop */
  @media (min-width: 960px) {
    right: ${theme.spacing.cozy}px;
  }
`

const Content = props => (
  <iframe
    frameBorder="0"
    allowFullScreen
    scrolling="no"
    seamless="seamless"
    title="Interbank IFrame Modal"
    {...props}
  />
)

const InterbankIframeModal = props => {
  const { src } = props

  const modalManager = useModalManager()

  return (
    <Container>
      <ModalCloseButton
        onClick={() => {
          modalManager.closeModal()
        }}
        role="button"
      >
        <Icon type="close" />
      </ModalCloseButton>
      <Media
        queries={{
          desktop: '(min-width: 960px)',
        }}
      >
        {matches => {
          if (matches.desktop) {
            return <Content src={src} />
          }

          return <Content src={src} scrolling="yes" />
        }}
      </Media>
    </Container>
  )
}

export default InterbankIframeModal
