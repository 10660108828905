import * as React from 'react'
import styled from 'styled-components'
import { useFeature, useFlag } from 'toggled'

import { useCurrencyConfig, useVendor } from '~/hooks'
import Label from '~/truck/label'
import theme from '~/truck/theme'
import { formatPrice, getTaxLabel } from '~/utils'
import { getPriceDiscountPercentage } from '~/utils'
import { flags } from '~/utils/constants'

import DiscountLabel from '../discount-label'
import TierPricing from '../tier-pricing'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const AlignedCenterContainer = styled.div`
  align-items: center;
  display: flex;
`

const InnerContainer = styled(AlignedCenterContainer)`
  strike {
    margin-right: ${theme.spacing.cozy}px;
  }
`

const StyledDiscountLabel = styled(DiscountLabel)`
  margin-left: ${theme.spacing.cozy}px;
`

const Price = props => {
  const {
    value,
    oldValue,
    color = 'error',
    textStyle = 'h5Semibold',
    textAlign,
    decimalTextStyle = textStyle,
    allowTaxLabel,
    isTaxExempt,
    style,
    className,
    tierPricing,
    allowDiscountLabel,
    innerContainerStyle,
  } = props

  const currencyConfig = useCurrencyConfig()

  const showTaxLabelFF = useFeature(flags.SHOW_TAX_LABEL)

  const vendor = useVendor()

  const hidePrice = useFlag(flags.HIDE_PRICES)

  const showDiscountLabelFF = useFlag(flags.SHOW_DISCOUNT_LABEL)

  const [oldValueInt, oldValueDecimal] = formatPrice(oldValue, currencyConfig)

  const [valueInt, valueDecimal] = formatPrice(value, currencyConfig)

  if (value === null || hidePrice) {
    return null
  }

  const discountPercentage = getPriceDiscountPercentage(oldValue, value)

  return (
    <Container style={style} className={className}>
      {!!oldValue && (
        <Label as="span" $textStyle="h6Regular" $color="grayscale60" style={{ textDecoration: 'line-through' }}>
          <>
            {oldValueInt}
            {oldValueDecimal && `${currencyConfig.decimal}${oldValueDecimal}`}
          </>
        </Label>
      )}
      <InnerContainer style={innerContainerStyle}>
        <Label as="span" $color={color} $textStyle={textStyle} $textAlign={textAlign}>
          {valueInt}
          {valueDecimal && (
            <>
              {currencyConfig.decimal}
              <Label as="span" $color={color} $textStyle={decimalTextStyle}>
                {valueDecimal}
              </Label>
            </>
          )}
        </Label>
        {allowDiscountLabel && showDiscountLabelFF && discountPercentage && (
          <StyledDiscountLabel value={discountPercentage} />
        )}
      </InnerContainer>
      {tierPricing?.length > 1 && (
        <div onClick={event => event.stopPropagation()}>
          <TierPricing tierPricing={tierPricing} />
        </div>
      )}
      {allowTaxLabel && !!showTaxLabelFF && (
        <Label as="span" $textStyle="h6Regular" style={{ marginTop: theme.spacing.compact }}>
          {getTaxLabel(vendor.settings, isTaxExempt)}
        </Label>
      )}
    </Container>
  )
}

export default Price
