import React, { useContext } from 'react'
import { useForm } from 'react-final-form'
import styled from 'styled-components'

import { GoogleMapsContext } from '~/contexts'
import Icon from '~/truck/icon'
import Label from '~/truck/label'
import theme from '~/truck/theme'

const Container = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  display: flex;
  padding: ${theme.spacing.cozy * 1.5}px ${theme.spacing.comfortable}px;

  &:hover {
    background-color: ${props => props.theme.colors.grayLight};
  }

  > svg {
    margin-right: ${theme.spacing.cozy}px;
  }
`

const AddressSuggestion = props => {
  const { address } = props

  const { coordinates, line } = address

  const { mapInstance } = useContext(GoogleMapsContext)

  const form = useForm()

  const onClick = () => {
    form.batch(() => {
      form.change('addressLine', line)

      form.change('addressId', address.id)

      if (coordinates) {
        form.change('coordinates.latitude', coordinates.latitude)

        form.change('coordinates.longitude', coordinates.longitude)

        form.change('hasSelectedAddress', true)

        if (mapInstance) {
          mapInstance.setCenter({
            lat: coordinates.latitude,
            lng: coordinates.longitude,
          })
        }
      }
    })
  }

  return (
    <Container onClick={onClick}>
      <Icon type="location" size="sm" color="gray" style={{ flexShrink: 0 }} />
      <Label as="p">{address.line}</Label>
    </Container>
  )
}

export default AddressSuggestion
