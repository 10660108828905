import NextRouter from 'next/router'

import forceReloadTo from './force-reload-to'

interface RedirectOptions {
  force: boolean
}

const redirectTo = (
  route: string,
  context: PageContext = {} as PageContext,
  options: RedirectOptions = {} as RedirectOptions,
) => {
  const { res } = context
  if (res) {
    return res.redirect(route)
  }

  if (options.force) {
    forceReloadTo(route)
  } else {
    NextRouter.push(route)
  }
}

export default redirectTo
