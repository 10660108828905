import gql from 'graphql-tag'

// TODO: Update TransactionItem to LineItem when the API support it

const lineItemForCartFragment = gql`
  fragment lineItemForCart on TransactionItem {
    id
    name
    photo
    price
    oldPrice
    isTaxExempt
    quantity
    originalQuantity
    inventoryQuantity
    inventoryUnitCode
    presentation
    product {
      id
      name
      sku
      slug
      jumpFactor
      salesUnitFactor
      minimumSalesQuantity
      maximumSalesQuantity
      inventoryUnit {
        id
        code
      }
      defaultPhoto
      presentation
      stock
      hasVariants
      attributesSummary
      pricing {
        oldPrice
        price
        ribbonLabel
        isTaxExempt
      }
      tierPricing {
        oldPrice
        price
        minimumQuantity
      }
    }
  }
`

export default lineItemForCartFragment
