import { useEffect, useMemo } from 'react'
import { debounce, isNil } from 'lodash'

import type { CounterProps } from '~/components'
// TODO(refactor/cart): this is temporal, we need move this hook inside shopping cart module
import { useCounterLastValueStore } from '~/modules/shopping-cart/stores/cart-counter-last-value-store'
import { validateIfCanJumpOnce } from '~/utils'
import { timingDelays } from '~/utils/constants'

import useCartUpdater from './use-cart-updater'
import useSalesQuantity from './use-sales-quantity'

const useCartProps = props => {
  const {
    quantity,
    stock,
    minimumSalesQuantity,
    maximumSalesQuantity,
    jumpFactor,
    id,
    onDebounceStart,
    onDebounceEnd,
  } = props

  const wrappedProps = { ...props }

  const { setLastValue, getLastValue } = useCounterLastValueStore()

  const { handleSalesQuantity } = useSalesQuantity()

  const { update, remove } = useCartUpdater({ product: wrappedProps, runPromotions: props.runPromotions })

  useEffect(() => {
    if (quantity > 0) {
      setLastValue(id, quantity)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const debounceFn = useMemo(() => debounce((callback: () => void) => callback(), timingDelays.ADD_PRODUCT_TO_CART), [])

  const readerContentHelper = (updater, remover) => {
    const {
      stock: productStock,
      counterMinValue,
      counterMaxValue,
      counterMaxMessage,
    } = handleSalesQuantity({
      stock,
      minimumSalesQuantity,
      maximumSalesQuantity,
    })

    const onChange = (value: number) => {
      const lastValue = getLastValue(id)

      updater(value, lastValue)

      setLastValue(id, value)
    }

    const onRemove = () => {
      setLastValue(id, 0)

      remover()
    }

    const counterProps: CounterProps = {
      value: quantity,
      jumpFactor,
      canJumpAtLeastOnce: validateIfCanJumpOnce(jumpFactor, counterMinValue, counterMaxValue),
      minValue: counterMinValue,
      maxValue: counterMaxValue,
      allowZeroToEliminate: true,
      maxMessage: counterMaxMessage,
      onChange: value => {
        onDebounceStart?.(id)

        debounceFn(() => {
          onDebounceEnd?.(id)

          onChange(value)
        })
      },
    }

    return { counterProps, productStock, onRemove }
  }

  const { counterProps, productStock, onRemove } = readerContentHelper(update, remove)

  let returnedStock = stock

  if (!isNil(productStock)) {
    returnedStock = productStock
  }

  return { counterProps, returnedStock, onRemove }
}

export default useCartProps
