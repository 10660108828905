import gql from 'graphql-tag'

import { checkoutFragment } from '../fragments'

const setCustomizationMutation = gql`
  mutation setCustomization($dedicationMessage: String) {
    setCustomization(input: { dedicationMessage: $dedicationMessage }) {
      order {
        ...checkout
      }
    }
  }
  ${checkoutFragment}
`

export default setCustomizationMutation
