import gql from 'graphql-tag'

import { productFragment } from '../fragments'

const relatedProductsQuery = gql`
  query relatedProducts($productId: ID!) {
    product: node(id: $productId) {
      ... on Product {
        id
        relatedProducts {
          ...product
        }
      }
    }
  }
  ${productFragment}
`

export default relatedProductsQuery
