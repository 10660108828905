import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-final-form'
import { noop } from 'lodash'

import { GoogleMapsContext } from '~/contexts'
import useVendor from '~/hooks/use-vendor'
import { baseUrls, defaultCoordinates } from '~/utils/constants'

const GoogleMapsProvider = props => {
  const { children } = props

  const [mapInstance, setMapInstance] = useState()

  const [loading, setLoading] = useState(true)

  const vendor = useVendor()

  const isGoogleMapsAvailable = !!window?.google?.maps?.location || !loading

  const setState = useCallback(setMapInstance, [])

  const form = useForm()

  useEffect(() => {
    if (isGoogleMapsAvailable) {
      setLoading(false)

      return
    }

    const callbackName = 'initMap'

    window[callbackName] = () => {
      setLoading(false)
    }

    const script = document.createElement('script')

    script.src = `${baseUrls.GOOGLE_MAPS}&key=${vendor.googleMapsApiKey}&callback=${callbackName}`

    script.async = true

    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script)
    }
  }, [])

  const loadMap = useCallback(
    async (mapNode, initialCoordinates, onDragEnd = noop) => {
      const mapCoordinates = vendor?.settings?.shipping?.mapCoordinates ?? defaultCoordinates

      const initialCenter = initialCoordinates || mapCoordinates

      const mapOptions = {
        zoom: 15,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        center: initialCenter,
      }

      const map = mapInstance || new window.google.maps.Map(mapNode, mapOptions)

      const setCoordinates = (lat, lng) => {
        form.change('coordinates.latitude', lat)

        form.change('coordinates.longitude', lng)
      }

      const onSuccess = location => {
        const { coords } = location

        const { latitude: lat, longitude: lng } = coords

        const newCenter = { lat, lng }

        map.setCenter(newCenter)

        setCoordinates(lat, lng)
      }

      const onError = () => {
        const { lat, lng } = defaultCoordinates

        map.setCenter({ lat, lng })

        setCoordinates(lat, lng)
      }

      if (!mapInstance) {
        if (!initialCoordinates) {
          navigator.geolocation.getCurrentPosition(onSuccess, onError)
        } else {
          const { lat, lng } = initialCoordinates

          setCoordinates(lat, lng)
        }
      }

      const onMapDragEnd = () => {
        const location = map.getCenter()

        if (location.lat() === initialCenter.lat && location.lng() === initialCenter.lng) {
          return
        }

        setCoordinates(location.lat(), location.lng())

        onDragEnd(location.lat(), location.lng())
      }

      map.addListener('dragend', onMapDragEnd)

      if (!mapInstance) {
        setMapInstance(map)
      }
    },
    [mapInstance, vendor, form],
  )

  const value = useMemo(
    () => ({
      mapInstance,
      setMapInstance: setState,
      loadMap,
    }),
    [mapInstance, setState, loadMap],
  )

  if (loading) {
    return null
  }

  return <GoogleMapsContext.Provider value={value}>{children}</GoogleMapsContext.Provider>
}

export default GoogleMapsProvider
