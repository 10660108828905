import Address from './address'
import Mutation from './mutation'
import Order from './order'
import ShippingType from './shipping-type'
import User from './user'
import Vendor from './vendor'

const resolvers = {
  ShippingType,
  Address,
  Mutation,
  User,
  Vendor,
  Order,
}

export default resolvers
