import * as React from 'react'
import nanoid from 'nanoid'

function useNanoid() {
  const valueRef = React.useRef()

  if (!valueRef.current) {
    valueRef.current = nanoid()
  }

  return valueRef.current
}

export default useNanoid
