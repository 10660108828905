import styled from 'styled-components'

export const DialogFooter = styled.div`
  border-top: 1px solid ${props => props.theme.colors.gray};
  display: flex;
  justify-content: flex-end;
  padding: ${props => props.theme.spacing.comfortable}px;

  > button {
    min-width: ${props => props.theme.spacing.cozy * 15}px;
    width: unset;

    &:not(:last-child) {
      margin-right: ${props => props.theme.spacing.comfortable}px;
    }
  }
`
