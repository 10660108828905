import styled from 'styled-components'

import theme from '~/truck/theme'

export const Container = styled.div`
  align-items: center;
  background: ${theme.colors.grayLight};
  display: flex;
  flex-shrink: 0;
  height: ${theme.spacing.cozy * 6}px;
  padding: 0 ${theme.spacing.comfortable}px;

  > svg {
    margin-right: ${theme.spacing.cozy}px;
  }

  > :not(span):last-child {
    display: flex;
    margin-left: auto;
  }
`
