import React, { useState } from 'react'
import { useField } from 'react-final-form'
import { find, map } from 'lodash'
import moment from 'moment-timezone'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import { Datepicker, TextField } from '~/components'
import { useCanSelectTimeRange, useFormatters, useVendor } from '~/hooks'
import theme from '~/truck/theme'
import type { IShippingType, ITimeRange } from '~/types/shipping'
import type { IVendor } from '~/types/vendors'
import { isTodayOrTomorrow, isValidTimeRange as timeRangeChecker } from '~/utils'
import { shippingTypes } from '~/utils/constants'

export interface DateTimeSelectorProps {
  shippingType: IShippingType
  calendarDates: string[]
  timeRanges: ITimeRange[]
  showLabelOnInputs?: boolean
  dateFormatter?: (value?: string) => string
  className?: string
  style?: React.CSSProperties
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: ${theme.spacing.comfortable}px;
  }

  ${up('lg')} {
    flex-direction: row;

    > * {
      flex: 1 1 50%;
    }

    > *:not(:last-child) {
      margin-bottom: 0;
      margin-right: ${theme.spacing.comfortable}px;
    }
  }
`

function isTimeRangeValid(timeRange: ITimeRange, date: Date, vendor: IVendor) {
  const preferCheckMinHour = vendor.settings?.deliveryTimeRange?.preferCheckMinHour

  return timeRangeChecker({ deliveryDate: date, preferCheckMinHour, timeRange: timeRange.time })
}

function CalendarDateSelector(props: DateTimeSelectorProps) {
  const { dateFormatter, calendarDates, timeRanges, shippingType, showLabelOnInputs } = props

  const vendor = useVendor()

  const formatters = useFormatters()

  const calendarDateField = useField('deliveryDate')

  const [firstValidDate] = calendarDates ?? []

  function getValidDeliveryDate() {
    const dateValue = calendarDateField.input.value

    const isValid = find(calendarDates, date => moment(date).isSame(dateValue, 'day'))

    if (!isValid && firstValidDate) {
      calendarDateField.input.onChange(firstValidDate)

      return firstValidDate
    }

    return dateValue
  }

  const calendarDateValue = getValidDeliveryDate()

  const timeRangeField = useField('deliveryTimeRangeId')

  const [datepickerIsOpen, setDatepickerIsOpen] = useState(false)

  function onCalendarDateChange(date: Date) {
    calendarDateField.input.onChange(date.toISOString())

    const currentTimeRange = find(timeRanges, { id: timeRangeField.input.value })

    if (currentTimeRange && !isTimeRangeValid(currentTimeRange, date, vendor)) {
      const timeRange = find(timeRanges, (timeRange: ITimeRange) => {
        return isTimeRangeValid(timeRange, date, vendor)
      })

      timeRangeField.input.onChange(timeRange?.id)
    }

    setDatepickerIsOpen(false)
  }

  // required `div` to work with css selector `> *:not(:last-child)`
  return (
    <div>
      <TextField
        as="input"
        autoComplete="off"
        format={dateFormatter || formatters.date}
        label={showLabelOnInputs && 'Fecha de entrega'}
        name="deliveryDate"
        onFocus={() => setDatepickerIsOpen(true)}
        placeholder={shippingType.isPickup ? 'Fecha de recojo' : 'Fecha de entrega'}
        readOnly
      />
      <Datepicker
        isOpen={datepickerIsOpen}
        onClose={() => setDatepickerIsOpen(false)}
        value={calendarDateValue}
        onChange={onCalendarDateChange}
        availableDates={calendarDates}
      />
    </div>
  )
}

function TimeRangeSelector(props: DateTimeSelectorProps) {
  const { timeRanges, shippingType, showLabelOnInputs } = props

  const vendor = useVendor()

  const calendarDateField = useField('deliveryDate')

  const canSelectTimeRange = useCanSelectTimeRange()

  function renderTimeRange(timeRange: ITimeRange) {
    const isValid = isTimeRangeValid(timeRange, calendarDateField.input.value, vendor)

    return (
      <option key={timeRange.id} value={timeRange.id} disabled={!isValid}>
        {timeRange.time}
      </option>
    )
  }

  const selectPrompt = shippingType.isPickup ? 'Horario de recojo' : 'Horario de entrega'

  if (!canSelectTimeRange(shippingType)) {
    return null
  }

  if (shippingType.slug === shippingTypes.CHAZKI) {
    const disabled = isTodayOrTomorrow(calendarDateField.input.value, vendor.timezone)

    return (
      <TextField
        as="select"
        name="deliveryTimeRangeId"
        prompt={disabled ? 'El envío se realizará durante el día' : selectPrompt}
        label={showLabelOnInputs && 'Horario de entrega'}
        disabled={disabled}
      >
        {disabled ? null : map(timeRanges, renderTimeRange)}
      </TextField>
    )
  }

  return (
    <TextField
      as="select"
      name="deliveryTimeRangeId"
      prompt={selectPrompt}
      label={showLabelOnInputs && 'Horario de entrega'}
    >
      {map(timeRanges, renderTimeRange)}
    </TextField>
  )
}

export default function DateTimeSelector(props: DateTimeSelectorProps) {
  const { className, style } = props

  return (
    <StyledContainer className={className} style={style}>
      <CalendarDateSelector {...props} />
      <TimeRangeSelector {...props} />
    </StyledContainer>
  )
}
