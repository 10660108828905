import gql from 'graphql-tag'

import { lineItemForCartFragment } from '../fragments'

const removeProductFromCartMutation = gql`
  mutation removeProductFromCart($productSlug: String!) {
    removeProductFromCart(input: { productSlug: $productSlug }) {
      lineItem: transactionItem {
        ...lineItemForCart
      }
    }
  }
  ${lineItemForCartFragment}
`

export default removeProductFromCartMutation
