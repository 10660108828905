import React, { useContext } from 'react'

import Radio, { RadioProps } from '~/truck/radio'

interface ContextProps {
  name?: string
  value?: any
  onChange?: (value: any) => void
}

const Context = React.createContext<ContextProps>({})

interface RadioItemProps extends RadioProps {
  value: any
}

const RadioItem = ({ value, ...rest }: RadioItemProps) => {
  const provided = useContext(Context)

  return (
    <Radio
      name={provided.name}
      checked={provided.value === value}
      onChange={() => provided.onChange(value)}
      {...rest}
    />
  )
}

interface RadioGroupProps {
  name?: string
  onChange: (value: any) => void
  value: any
  children: React.ReactNode
}

const RadioGroup = ({ name, onChange, value, children }: RadioGroupProps) => {
  return <Context.Provider value={{ name, value, onChange }}>{children}</Context.Provider>
}

RadioItem.displayName = 'Radio'

RadioGroup.Radio = RadioItem

export default RadioGroup
