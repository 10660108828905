import gql from 'graphql-tag'

import { initialProductFragment } from '../fragments'

const favoriteProductsQuery = gql`
  query favoriteProducts {
    user {
      id
      favoriteProducts {
        ...initialProduct
      }
    }
  }

  ${initialProductFragment}
`

export default favoriteProductsQuery
