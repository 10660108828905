import * as React from 'react'

import Media from '../media'

import Desktop from './index.desktop'
import Mobile from './index.mobile'

function MainHeader() {
  return <Media desktop={<Desktop />} mobile={<Mobile />} />
}

export default MainHeader
