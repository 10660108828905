import gql from 'graphql-tag'

import productAttributesFragment from './product-attributes'

// TODO: Update TransactionItem to LineItem when the API support it

const lineItemFragment = gql`
  fragment lineItem on TransactionItem {
    id
    name
    photo
    price
    oldPrice
    isTaxExempt
    quantity
    originalQuantity
    inventoryQuantity
    inventoryUnitCode
    presentation
    product {
      id
      name
      sku
      slug
      jumpFactor
      salesUnitFactor
      minimumSalesQuantity
      maximumSalesQuantity
      inventoryUnit {
        id
        code
      }
      defaultPhoto
      presentation
      description
      stock
      hasVariants
      pricing {
        oldPrice
        price
        ribbonLabel
      }
      attributes {
        ...productAttributes
      }
      attributesSummary
      tierPricing {
        oldPrice
        price
        minimumQuantity
      }
      category {
        id
        name
        parent {
          id
          name
        }
      }
    }
  }
  ${productAttributesFragment}
`

export default lineItemFragment
