import gql from 'graphql-tag'

import { addressFragment } from '../fragments'

const upsertAddressMutation = gql`
  mutation upsertAddress(
    $addressLine: String!
    $contactPerson: String
    $contactPhone: String
    $contactDocumentNumber: String
    $contactEmail: String
    $localityId: ID
    $apartment: String
    $reference: String
    $zipCode: String
    $coordinates: CoordinatesInput
    $addressComponents: JSON
    $addressId: ID
    $isDefault: Boolean
    $divisionLevel1: String
    $divisionLevel2: String
    $divisionLevel3: String
    $divisionLevel4: String
  ) {
    upsertAddress(
      input: {
        line: $addressLine
        contactPerson: $contactPerson
        contactPhone: $contactPhone
        localityId: $localityId
        apartment: $apartment
        reference: $reference
        coordinates: $coordinates
        addressId: $addressId
        isDefault: $isDefault
        contactDocumentNumber: $contactDocumentNumber
        contactEmail: $contactEmail
        zipCode: $zipCode
        addressComponents: $addressComponents
        divisionLevel1: $divisionLevel1
        divisionLevel2: $divisionLevel2
        divisionLevel3: $divisionLevel3
        divisionLevel4: $divisionLevel4
      }
    ) {
      address {
        ...address
        territory {
          id
        }
      }
    }
  }
  ${addressFragment}
`

export default upsertAddressMutation
