import axios from 'axios'

type SignedForm = {
  url?: string
  fields: Record<string, string>
}

async function uploadToS3(signedForm: SignedForm, file: File) {
  const { url, fields } = signedForm

  const form = new FormData()

  Object.keys(fields).forEach(key => {
    form.append(key, fields[key])
  })

  form.append('file', file)

  await axios.post(url, form)
}

export default uploadToS3
