import { noop } from 'lodash'

import {
  AnalyticsCollector,
  AnalyticsCollectorAddToCartVariables,
  AnalyticsCollectorBeginCheckoutVariables,
  AnalyticsCollectorCheckoutProgressVariables,
  AnalyticsCollectorCloseSuggestedProductsVariables,
  AnalyticsCollectorLoginVariables,
  AnalyticsCollectorPageViewVariables,
  AnalyticsCollectorPaymentErrorVariables,
  AnalyticsCollectorPurchaseVariables,
  AnalyticsCollectorRemoveFromCartVariables,
  AnalyticsCollectorSearchVariables,
  AnalyticsCollectorSelectItemVariables,
  AnalyticsCollectorSelectPromotionVariables,
  AnalyticsCollectorSignUpVariables,
  AnalyticsCollectorViewCartVariables,
  AnalyticsCollectorViewItemListVariables,
  AnalyticsCollectorViewItemVariables,
  AnalyticsCollectorViewPromotionVariables,
  AnalyticsEvents,
} from './analytics-types'

if (typeof window !== 'undefined') {
  window.collectDataLayer = window.collectDataLayer || noop
}

export const analyticsDataLayer: AnalyticsCollector = {
  pageView: (variables: AnalyticsCollectorPageViewVariables) => {
    window.collectDataLayer({
      event: AnalyticsEvents.PAGE_VIEW,
      page_title: variables.title,
      page_path: variables.path,
      page_referrer: variables.referrer,
      page_location: variables.location,
    })
  },
  search: (variables: AnalyticsCollectorSearchVariables) => {
    window.collectDataLayer({
      event: AnalyticsEvents.SEARCH,
      search_term: variables.term,
    })
  },
  selectItem: (variables: AnalyticsCollectorSelectItemVariables) => {
    window.collectDataLayer({
      event: AnalyticsEvents.SELECT_ITEM,
      items: [variables.product],
      item_list_id: variables.itemListId,
      item_list_name: variables.itemListName,
    })
  },
  viewItem: (variables: AnalyticsCollectorViewItemVariables) => {
    window.collectDataLayer({
      event: AnalyticsEvents.VIEW_ITEM,
      items: [variables.product],
      value: variables.value,
      currency: variables.currency_code,
      context: variables.context,
    })
  },
  viewItemList: (variables: AnalyticsCollectorViewItemListVariables) => {
    window.collectDataLayer({
      event: AnalyticsEvents.VIEW_ITEM_LIST,
      item_list_id: variables.itemListId,
      item_list_name: variables.itemListName,
      items: variables.products,
      context: variables.context,
    })
  },
  addToCart: (variables: AnalyticsCollectorAddToCartVariables) => {
    window.collectDataLayer({
      event: AnalyticsEvents.ADD_TO_CART,
      items: [variables.product],
      value: variables.value,
      currency: variables.currency_code,
      context: variables.context,
    })
  },
  removeFromCart: (variables: AnalyticsCollectorRemoveFromCartVariables) => {
    window.collectDataLayer({
      event: AnalyticsEvents.REMOVE_FROM_CART,
      items: [variables.product],
      value: variables.value,
      currency: variables.currency_code,
      context: variables.context,
    })
  },
  viewCart: (variables: AnalyticsCollectorViewCartVariables) => {
    window.collectDataLayer({
      event: AnalyticsEvents.VIEW_CART,
      items: variables.products,
      value: variables.value,
      currency: variables.currency_code,
    })
  },
  beginCheckout: (variables: AnalyticsCollectorBeginCheckoutVariables) => {
    window.collectDataLayer({
      event: AnalyticsEvents.BEGIN_CHECKOUT,
      items: variables.products,
      value: variables.value,
      currency: variables.currency_code,
    })
  },
  checkoutProgress: (variables: AnalyticsCollectorCheckoutProgressVariables) => {
    window.collectDataLayer({
      event: AnalyticsEvents.CHECKOUT_PROGRESS,
      checkout_step: variables.step_number,
    })
  },
  purchase: (variables: AnalyticsCollectorPurchaseVariables) => {
    window.collectDataLayer({
      event: AnalyticsEvents.PURCHASE,
      transaction_id: variables.order_id,
      affiliation: variables.seller_name,
      currency: variables.currency_code,
      value: variables.order_total,
      shipping: variables.order_delivery_cost,
      tax: variables.order_tax,
      items: variables.order_products,
      phone: variables.phone,
      email: variables.email,
      context: variables.context,
    })
  },
  login: (variables: AnalyticsCollectorLoginVariables) => {
    window.collectDataLayer({
      event: AnalyticsEvents.LOGIN,
      method: variables.method,
      context: variables.context,
    })
  },
  signUp: (variables: AnalyticsCollectorSignUpVariables) => {
    window.collectDataLayer({
      event: AnalyticsEvents.SIGN_UP,
      method: variables.method,
      context: variables.context,
    })
  },
  preSignUpAttempt: () => {
    window.collectDataLayer({
      event: AnalyticsEvents.PRE_SIGN_UP_ATTEMPT,
    })
  },
  paymentError: (variables: AnalyticsCollectorPaymentErrorVariables) => {
    window.collectDataLayer({
      event: variables.event_name,
      event_label: variables.error_message,
      event_category: 'error',
      payment_method: variables.payment_method,
      payment_provider: variables.payment_method,
      context: variables.context,
    })
  },
  contact: noop,
  socialMedia: noop,
  viewPromotion: (variables: AnalyticsCollectorViewPromotionVariables) => {
    window.collectDataLayer({
      event: AnalyticsEvents.VIEW_PROMOTION,
      creative_slot: variables.creative_slot,
    })
  },
  selectPromotion: (variables: AnalyticsCollectorSelectPromotionVariables) => {
    window.collectDataLayer({
      event: AnalyticsEvents.SELECT_PROMOTION,
      creative_slot: variables.creative_slot,
    })
  },
  closeSuggestedProducts: (variables: AnalyticsCollectorCloseSuggestedProductsVariables) => {
    window.collectDataLayer({
      event: AnalyticsEvents.CLOSE_SUGGESTED_PRODUCTS,
      products_were_added: variables.products_were_added,
    })
  },
}
