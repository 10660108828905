import React from 'react'

import { MainHeader } from '~/components'

import { Content } from './elements'

const DefaultLayout = props => {
  const { children } = props

  return (
    <>
      <MainHeader />
      <Content>{children}</Content>
    </>
  )
}

export default DefaultLayout
