import { assign, each, groupBy } from 'lodash'

import type { ILineItem } from '~/types/line-items'
import type { IOrder } from '~/types/orders'
import type { IProduct } from '~/types/products'

const getQuantity = (lineItem: ILineItem) => {
  if (!lineItem) {
    return 0
  }

  return lineItem.originalQuantity
}

const updateQuantitiesInProducts = (products: IProduct[], cart: IOrder) => {
  const lineItems = groupBy<ILineItem>(cart?.lineItems, 'product.id')

  const updateLineItem = (product: IProduct) => {
    const lineItem = lineItems[product.id]?.[0]

    const quantity = getQuantity(lineItem)

    assign(product, { quantity })
  }

  each(products, updateLineItem)
}

export default updateQuantitiesInProducts
