import * as React from 'react'

import type { ExpressDeliveryContextValue } from './providers/express-delivery-session'
import type { IStore } from './types/shipping'
import type { IVendor } from './types/vendors'

const defaultConfig = {
  decimal: '.',
  formatWithSymbol: true,
  separator: ',',
  symbol: 'S/ ',
}

export const CurrencyConfigContext = React.createContext<typeof defaultConfig & { [key: string]: any }>(defaultConfig)

export const VendorContext = React.createContext<IVendor>(null)

export const UserContext = React.createContext(null)

export const IsMobileContext = React.createContext(false)

export const ModalContext = React.createContext(null)

export const BuyingInStoreContext = React.createContext<IStore>(null)

export const CheckoutContext = React.createContext(null)

export const StringsContext = React.createContext(null)

export const GoogleMapsContext = React.createContext(null)

export const MultiDistributorSessionContext = React.createContext(null)

export const ExpressDeliverySessionContext = React.createContext<ExpressDeliveryContextValue>(null)
