import * as React from 'react'

import { analytics } from '~/analytics'
import Anchor from '~/truck/anchor'
import Icon from '~/truck/icon'

interface MetricLinkProps {
  href: string
  type?: string
  external?: boolean
  children?: React.ReactNode
  color?: string
  textStyle?: string
  rel?: string
  target?: string
}

/** @deprecated create your own links */
function MetricLink(props: MetricLinkProps) {
  const { href, type, external, children, textStyle, color, ...rest } = props

  if (!href) {
    return null
  }

  const onClick = () => {
    if (external) {
      analytics.socialMedia(type)

      return
    }

    analytics.contact(type as 'mail' | 'telephone' | 'whatsapp')
  }

  const rel = external ? 'noopener noreferrer nofollow' : 'nofollow'

  const target = external && '_blank'

  return (
    <Anchor href={href} rel={rel} target={target} onClick={onClick} $textStyle={textStyle} $color={color} {...rest}>
      {children || <Icon type={type} />}
    </Anchor>
  )
}

export default MetricLink
