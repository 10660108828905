import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import theme from '~/truck/theme'
import { themeGetters } from '~/truck/utils'
import { viewportHeights } from '~/variables'

import { LayoutContent } from '../elements'

const { colors, dimensions, spacing } = themeGetters

export const Content = styled(LayoutContent)`
  background-color: ${colors('white')};
  padding: ${spacing('comfortable')}px;

  ${up('lg')} {
    border-radius: ${dimensions('borderRadius')}px;
    min-height: ${viewportHeights.getDefaultLayout};
    width: ${theme.spacing.cozy * 128}px;
  }
`
