import React from 'react'

import { CircleBackground, CircleProgress, Text } from './elements'

const CircularProgressBar = props => {
  const { diameter, strokeWidth, steps, step } = props

  const progressPercentage = (100 / steps) * step
  const radius = (diameter - strokeWidth) / 2
  const viewBox = `0 0 ${diameter} ${diameter}`
  const dashArray = radius * Math.PI * 2
  const dashOffset = dashArray - (dashArray * progressPercentage) / 100
  const pivot = diameter / 2

  if (progressPercentage > 100) {
    console.warn('Percentage cannot be higher than 100')

    return null
  }

  if (strokeWidth > radius) {
    console.warn(`strokeWidth (${strokeWidth}) cannot be higher than radius (${radius})`)

    return null
  }

  return (
    <svg width={diameter} height={diameter} viewBox={viewBox}>
      <CircleBackground cx={pivot} cy={pivot} r={radius} strokeWidth={`${strokeWidth}px`} />
      <CircleProgress
        cx={pivot}
        cy={pivot}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        // Start progress marker at 12 O'Clock
        transform={`rotate(-90 ${pivot} ${pivot})`}
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffset,
        }}
      />
      <Text x="50%" y="50%" dy=".3em" textAnchor="middle">
        {`${step}/${steps}`}
      </Text>
    </svg>
  )
}

export default CircularProgressBar
