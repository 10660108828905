import type { BulkOrderingResultError, BulkOrderingResultOperation } from '../../types'

export enum DialogStep {
  START_PROCESS = 'START_PROCESS',
  SUCCESSFULLY_PROCESSED = 'SUCCESSFULLY_PROCESSED',
  PARTIALLY_PROCESSED = 'PARTIALLY_PROCESSED',
  UNSATISFACTORILY_PROCESSED = 'UNSATISFACTORILY_PROCESSED',
}

export interface DialogState {
  step: DialogStep
  errors: BulkOrderingResultError[]
  operations: BulkOrderingResultOperation[]
}

export type DialogStateAction = {
  type: 'SET_STEP'
  payload: {
    step: DialogStep
    errors: BulkOrderingResultError[]
    operations: BulkOrderingResultOperation[]
  }
}

export interface DialogContextValue extends DialogState {
  setStep: (step: DialogStep, errors: BulkOrderingResultError[], operations: BulkOrderingResultOperation[]) => void
  onModalClose: () => void
}
