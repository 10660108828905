import gql from 'graphql-tag'

const bannersQuery = gql`
  query banners {
    vendor {
      id
      banners {
        id
        link
        desktopImage
        mobileImage
      }
    }
  }
`

export default bannersQuery
