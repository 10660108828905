import * as React from 'react'

import Field from '~/truck/field'

interface PaymentEmailProps {
  id?: string
  name?: string
  placeholder?: string
  [key: string]: any
}

export default function PaymentEmail(props: PaymentEmailProps) {
  const { id, name, placeholder, ...rest } = props

  return <Field name={name ?? 'email'} placeholder={placeholder ?? 'Correo electrónico'} {...rest} />
}
