import { isNil } from 'lodash'
import { useFlag } from 'toggled'

import { flags } from '~/utils/constants'

interface UseSalesQuantityOptions {
  minimumSalesQuantity?: number
  maximumSalesQuantity?: number
  stock?: number
}

function useSalesQuantity() {
  const hasHandleStock = useFlag(flags.HANDLE_STOCK)

  const handleSalesQuantity = (options: UseSalesQuantityOptions) => {
    const { maximumSalesQuantity, minimumSalesQuantity, stock: productStock } = options

    const returnData = {
      counterMaxValue: maximumSalesQuantity,
      counterMinValue: minimumSalesQuantity,
      counterMaxMessage: null,
      stock: null,
    }

    if (hasHandleStock) {
      if (isNil(productStock)) {
        return returnData
      }

      returnData.stock = productStock

      if (productStock < minimumSalesQuantity) {
        returnData.stock = 0
      }

      if (productStock <= maximumSalesQuantity || isNil(maximumSalesQuantity)) {
        returnData.counterMaxValue = productStock

        returnData.counterMaxMessage = '{value} disponibles'
      }
    }

    return returnData
  }

  return { handleSalesQuantity }
}

export default useSalesQuantity
