import React, { Children, cloneElement, forwardRef, useRef } from 'react'

import Field from '~/truck/field'

import { StyledPinInputField } from './elements'
import { PinInputProvider, usePinInput, usePinInputContext, UsePinInputProps } from './use-pin-input'

export interface PinInputProps extends UsePinInputProps {
  children: JSX.Element | JSX.Element[]
  name?: string
  id?: string
  [key: string]: any
}

export const PinInput = (props: PinInputProps) => {
  const { children, id, name, value, type, onChange, ...rest } = props

  const refs = useRef([]).current

  const clones = Children.map(props.children, (child, index) =>
    cloneElement(child, { ...rest, index, key: index, ref: (node: any) => (refs[index] = node) }),
  )

  const { value: pinValue, getInputProps } = usePinInput(props, refs)

  return (
    <PinInputProvider value={{ getInputProps }}>
      {clones}
      <Field hidden value={pinValue} name={name} id={id} onChange={() => void 0} {...rest} standalone />
    </PinInputProvider>
  )
}

export interface PinInputFieldProps {
  index?: number
}

export const PinInputField = forwardRef<HTMLInputElement, PinInputFieldProps>(({ index }, ref) => {
  const { getInputProps } = usePinInputContext()

  return <StyledPinInputField ref={ref} {...getInputProps(index)} onChange={() => void 0} />
})
