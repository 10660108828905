import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import { isNil, noop } from 'lodash'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import Modal from '~/components/modal'
import useModalManager from '~/hooks/use-modal-manager'
import theme from '~/truck/theme'

import Actions from './actions'
import Header from './header'

const StyledModal = styled(Modal)`
  border-radius: ${theme.dimensions.borderRadius}px;
  overflow-y: hidden;

  > :first-child {
    margin-bottom: ${theme.spacing.relaxed}px;
  }

  ${up('lg')} {
    padding: ${theme.spacing.relaxed}px;
  }
`

const ContentContainer = styled.div`
  height: calc(100vh - ${theme.spacing.cozy * 22}px);
  overflow-y: ${props => (props.hideContentOverflow ? 'hidden' : 'auto')};

  ${up('lg')} {
    height: ${theme.spacing.cozy * 47}px;
  }
`

const EditModal = props => {
  const {
    children,
    initialValues,
    onClose,
    onSubmit = noop,
    onValidate,
    disableSubmit,
    title,
    style,
    hideContentOverflow = false,
    className,
    ...rest
  } = props

  const modalManager = useModalManager()

  const onInternalClose = () => {
    modalManager.closeModal()

    if (onClose) {
      onClose()
    }
  }

  return (
    <StyledModal style={style} className={className} {...rest}>
      <Header onClose={onInternalClose} title={title} />
      <FinalForm initialValues={initialValues} onSubmit={onSubmit} validate={onValidate}>
        {formProps => {
          const { handleSubmit, submitting, pristine } = formProps

          return (
            <form onSubmit={handleSubmit}>
              <ContentContainer hideContentOverflow={hideContentOverflow}>{children}</ContentContainer>
              <Actions
                onClose={onInternalClose}
                onSubmit={handleSubmit}
                disableSubmit={isNil(disableSubmit) ? pristine : disableSubmit}
                submitting={submitting}
              />
            </form>
          )
        }}
      </FinalForm>
    </StyledModal>
  )
}

export default EditModal
