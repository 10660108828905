import cookie from 'isomorphic-cookie'

import { cookieNames } from './constants'
import getCookieConfig from './get-cookie-config'
import isProduction from './is-production'

const getProductionEndpoint = (req: PageContext['req']) => {
  if (!req) {
    return window.location.hostname
  }

  return req?.integrationStatus?.endpoint ?? req.hostname
}

const getHostname = endpoint => {
  try {
    return new URL(endpoint).hostname
  } catch (error) {
    return endpoint
  }
}

const getNonProductionEndpoint = (req: PageContext['req']) => {
  if (!req) {
    const { searchParams } = new URL(window.location as any)

    return getHostname(searchParams.get(cookieNames.ENDPOINT))
  }

  if (req.integration) {
    return req.integration.endpoint
  }

  return getHostname(req.query[cookieNames.ENDPOINT])
}

const getEndpoint = (req?: PageContext['req'], res?: PageContext['res']) => {
  if (isProduction) {
    return getProductionEndpoint(req)
  }

  const oldEndpoint = cookie.load(cookieNames.ENDPOINT, req)

  let currentEndpoint = getNonProductionEndpoint(req)

  if (!currentEndpoint) {
    currentEndpoint = oldEndpoint
  }

  const needsFallback = !oldEndpoint && !currentEndpoint

  if (needsFallback) {
    currentEndpoint = 'companyname.riqra.com'
  }

  cookie.save(cookieNames.ENDPOINT, currentEndpoint, getCookieConfig(), res)

  return currentEndpoint
}

export default getEndpoint
