import * as React from 'react'
import clsx from 'clsx'

import Icon from '~/truck/icon'

interface FeaturedItemProps {
  label: string
  icon: string
  href?: string
  isPrimary?: boolean
  onClick: () => void
}

const FeaturedItem = React.forwardRef<HTMLAnchorElement, FeaturedItemProps>(({ label, icon, isPrimary, href }, ref) => {
  return (
    <a className="flex flex-col items-center gap-1 text-xs" href={href}>
      <div
        className={clsx(
          'flex size-12 items-center justify-center rounded',
          isPrimary ? 'bg-brand-primary text-brand-primary-on-bg' : 'bg-brand-secondary text-brand-secondary-on-bg',
        )}
      >
        <Icon type={icon} color="currentColor" />
      </div>
      {label}
    </a>
  )
})

export default FeaturedItem
