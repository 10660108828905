import { indexOf, keys } from 'lodash'
import { down } from 'styled-breakpoints'
import styled, { css } from 'styled-components'

const defaultColumns = 12

const getColumns = (measure, props) => {
  const { breakpoints } = props.theme

  const measures = keys(breakpoints)

  const { [measure]: columnsWanted } = props

  if (columnsWanted) {
    return columnsWanted
  }

  const index = indexOf(measures, measure) - 1

  if (index === -1) {
    return defaultColumns
  }

  return getColumns(measures[index], props)
}

const makeGrid = (breakpoint, props) => {
  const comfortable = props.theme.spacing.comfortable
  const columns = getColumns(breakpoint, props)

  const ratio = `100% / ${defaultColumns}`
  const width = `${ratio} * ${columns}`

  return `
    flex-basis: calc(${width} - ${comfortable}px);
    max-width: calc(${width} - ${comfortable}px);
    min-width: calc(${ratio} - ${comfortable}px);
  `
}

const getMediaQueries = props => {
  const { breakpoints } = props.theme

  return css`
    @media (min-width: ${breakpoints.sm}) {
      ${makeGrid.bind(null, 'sm')}
    }

    @media (min-width: ${breakpoints.md}) {
      ${makeGrid.bind(null, 'md')}
    }

    @media (min-width: ${breakpoints.lg}) {
      ${makeGrid.bind(null, 'lg')}
    }

    @media (min-width: ${breakpoints.xl}) {
      ${makeGrid.bind(null, 'xl')}
    }
  `
}

export const Column = styled.div<{ xs?: number; sm?: number; md?: number; lg?: number; xl?: number }>`
  flex-grow: 1;
  flex-shrink: 0;
  margin: ${props => props.theme.spacing.cozy}px;
  word-wrap: break-word;

  ${down('sm')} {
    margin: 0;
    max-width: unset;
  }

  ${props => makeGrid.bind(null, 'xs', props)}

  ${props => getMediaQueries(props)}
`

export const StyledGrid = styled.div<{ xs?: number; sm?: number; md?: number; lg?: number; xl?: number }>`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  padding: 0 ${props => props.theme.spacing.cozy}px;
  position: relative;
  width: 100%;

  ${down('sm')} {
    padding: 0;
  }
`
