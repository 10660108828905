import gql from 'graphql-tag'

import { userFragment } from '../fragments'

const clearCartMutation = gql`
  mutation clearCart {
    clearCart(input: {}) {
      user {
        ...user
      }
    }
  }
  ${userFragment}
`

export default clearCartMutation
