import cookie from 'isomorphic-cookie'

import { mixpanel } from '~/metrics'

import { cookieNames, storageKeys } from './constants'
import forceReloadTo from './force-reload-to'
import isBrowser from './is-browser'
import isProduction from './is-production'

const redirectRoutePath = '/'

const logout = (context = {} as PageContext) => {
  const { res, req } = context

  let allCookiesKeys: string[]

  if (isBrowser) {
    allCookiesKeys = document.cookie.split(';').map(cookie => cookie.split('=')[0].trim())

    localStorage.removeItem(storageKeys.MAIN_POP_UP)
  } else {
    allCookiesKeys = Object.keys(req.cookies)
  }

  for (const cookieKey of allCookiesKeys) {
    if (!isProduction && cookieKey === cookieNames.ENDPOINT) {
      continue
    }

    cookie.remove(cookieKey, { path: '/' }, res)
  }

  if (!res) {
    mixpanel.logout()
  }

  if (res) {
    res.redirect(redirectRoutePath)
  } else {
    forceReloadTo(redirectRoutePath)
  }
}

export default logout
