import type { DialogState, DialogStateAction } from './types'

function dialogReducer(state: DialogState, action: DialogStateAction): DialogState {
  switch (action.type) {
    case 'SET_STEP':
      return {
        ...state,
        step: action.payload.step,
        errors: action.payload.errors,
        operations: action.payload.operations,
      }
  }
}

export default dialogReducer
