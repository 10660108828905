import type { CookieParseOptions, CookieSerializeOptions } from 'cookie'
import { assign } from 'lodash'

const getCookieConfig = (options?: CookieSerializeOptions & CookieParseOptions) => {
  const defaultOptions = { secure: false }

  return assign(defaultOptions, options)
}

export default getCookieConfig
