import { toNumber } from 'lodash'
import moment from 'moment-timezone'

function setTime(date: moment.MomentInput | Date, time: string) {
  // prettier-ignore
  const [hours, minutes, seconds = 0] = time
    .split(':')
    .map(toNumber)

  return moment(date).hours(hours).minutes(minutes).seconds(seconds)
}

export default setTime
