import React from 'react'
import styled from 'styled-components'

import theme from '~/truck/theme'
import type { IProduct } from '~/types/products'

import { SuggestedProduct } from './SuggestedProduct'

const StyledList = styled.div`
  & > div:not(:last-child) {
    border-bottom: 1px solid ${theme.colors.gray};
    margin-bottom: ${theme.spacing.cozy}px;
  }
`

type SuggestedProductsListProps = {
  products: IProduct[]
}

export const SuggestedProductsList = (props: SuggestedProductsListProps) => {
  if (!props.products) {
    return null
  }

  return (
    <StyledList>
      {props.products.map(product => (
        <SuggestedProduct {...product} photo={product.defaultPhoto} key={product.slug} />
      ))}
    </StyledList>
  )
}
