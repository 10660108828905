import gql from 'graphql-tag'

export const checkoutPopUpQuery = gql`
  query checkoutPopUp {
    getPopups {
      popups {
        checkout {
          image
          link
        }
      }
    }
  }
`
