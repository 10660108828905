import * as React from 'react'
import InfiniteScroller from 'react-infinite-scroller'
import * as Collapsible from '@radix-ui/react-collapsible'
import cookie from 'isomorphic-cookie'
import { assign, map, size } from 'lodash'
import { down } from 'styled-breakpoints'
import styled from 'styled-components'
import { useFeature, useFlagQueryFn } from 'toggled'

import { analytics } from '~/analytics'
import { useCart, useMultiDistributorSession, useUser } from '~/hooks'
import useModalManager from '~/hooks/use-modal-manager'
import Grid from '~/truck/grid'
import { redirectTo, updateQuantitiesInProducts } from '~/utils'
import { analyticsItemListContexts, flagQueries, flags } from '~/utils/constants'

import { cookieNames } from '../../utils/constants'
import Action from '../action'
import Loader from '../loader'
import ShippingSelectorModal from '../shipping-modal'
import SignupToSeePricesForm from '../signup-to-see-prices-form'

import Product from './product'

const StyledGrid = styled(Grid)`
  ${down('sm')} {
    > ${Grid.Column}:not(:last-child) {
      margin-bottom: 1px;
    }
  }
`

const CollapsedContent = styled(Collapsible.Content)`
  margin-top: 1px;
`

const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${props => props.theme.spacing.comfortable}px;
`

const defaultStyle = {
  width: '100%',
}

const TOP_ITEMS_COUNT = 4

const selectItemUrlMap = {
  '': analyticsItemListContexts.FEATURED_PRODUCTS,
  c: analyticsItemListContexts.CATEGORY_PAGE,
  p: analyticsItemListContexts.RELATED_PRODUCTS,
  b: analyticsItemListContexts.SEARCH_PAGE,
  'mi-cuenta': analyticsItemListContexts.FAVORITE_PRODUCTS,
}

const Products = props => {
  const { dataSource, product: Component, breakpoints, collapsed, isStandalone, productOnClick, ...rest } = props

  const { openModal } = useModalManager()

  const [isCollapsed, setIsCollapsed] = React.useState(true)

  const flagQueryFn = useFlagQueryFn()

  const shippingBeforeBuyFF = useFeature(flags.SHIPPING_BEFORE_BUY)

  const showPriceAfterLoginFeature = useFeature(flags.SHOW_PRICE_AFTER_LOGIN)

  const { multiDistributorSession } = useMultiDistributorSession()

  const user = useUser()

  const isVisitor = !user.isLoggedIn

  const isFullWidth = flagQueryFn(flagQueries.HAS_FULL_WIDTH)

  const hidePrice =
    (flagQueryFn(flags.SHOW_PRICE_AFTER_LOGIN) && isVisitor) ||
    (!!shippingBeforeBuyFF?.settings?.hidePrices && !multiDistributorSession)

  const onSeePriceClick = () => {
    if (shippingBeforeBuyFF?.settings?.hidePrices && !multiDistributorSession) {
      openModal(ShippingSelectorModal)

      return
    }

    if (showPriceAfterLoginFeature?.settings?.legacy) {
      openModal(SignupToSeePricesForm)

      return
    }
    const callbackUrl = window.location.pathname

    cookie.save(cookieNames.CALLBACK_URL, callbackUrl)

    redirectTo('/ingresar')
  }

  const ProductComponent = Component || Product

  const GridColumn = isStandalone ? 'div' : StyledGrid.Column

  const GridContainer = isStandalone ? 'div' : StyledGrid

  const renderProductWrapper = product => {
    const hasStock = product.stock > 0

    const getSlug = () => {
      if (!hasStock) {
        return product.variantInStock?.slug ?? product.slug
      }

      return product.slug
    }

    const productUrl = `/p/${getSlug()}`

    const columnXl = isFullWidth ? 2.4 : 3

    const columnLg = isFullWidth ? 3 : 4

    const renderProduct = ref => {
      return (
        <GridColumn sm={6} md={6} lg={columnLg} xl={columnXl} {...breakpoints} ref={ref}>
          <ProductComponent
            {...product}
            href={productUrl}
            photo={product.defaultPhoto}
            hidePrice={hidePrice}
            onSeePriceClick={() => onSeePriceClick()}
            showMyFavorites={flagQueryFn(flags.MY_FAVORITES)}
            onClick={typeof productOnClick === 'function' ? e => productOnClick(e, productUrl) : undefined}
          />
        </GridColumn>
      )
    }

    return <React.Fragment key={product.id}>{renderProduct()}</React.Fragment>
  }

  if (!collapsed || size(dataSource) <= TOP_ITEMS_COUNT) {
    // TODO: use css-grid instead of these grid components
    return <GridContainer {...rest}>{map(dataSource, renderProductWrapper)}</GridContainer>
  }

  const topItems = dataSource.slice(0, TOP_ITEMS_COUNT)

  const bottomItems = dataSource.slice(TOP_ITEMS_COUNT)

  return (
    <Collapsible.Root open={!isCollapsed} onOpenChange={v => setIsCollapsed(!v)}>
      <StyledGrid>{map(topItems, renderProductWrapper)}</StyledGrid>
      <CollapsedContent>
        <StyledGrid>{map(bottomItems, renderProductWrapper)}</StyledGrid>
      </CollapsedContent>
      <Collapsible.Trigger asChild>
        <ActionContainer>
          <Action $color="info">{isCollapsed ? 'Ver más' : 'Ver menos'}</Action>
        </ActionContainer>
      </Collapsible.Trigger>
    </Collapsible.Root>
  )
}

const Catalog = props => {
  const { products, pagination, collapsed, product, breakpoints, style, className, isStandalone, productOnClick } =
    props

  const ensuredStyle = assign(style, defaultStyle)

  let currentContext = undefined

  const { cart } = useCart()

  updateQuantitiesInProducts(products, cart)

  React.useEffect(() => {
    const currentPath = window.location.pathname.split('/')[1]

    currentContext = selectItemUrlMap[currentPath]

    analytics.viewItemList(products, currentContext)
  }, [])

  if (!pagination) {
    return (
      <Products
        style={ensuredStyle}
        className={className}
        dataSource={products}
        product={product}
        breakpoints={breakpoints}
        collapsed={collapsed}
        isStandalone={isStandalone}
        productOnClick={productOnClick}
      />
    )
  }

  return (
    <InfiniteScroller
      loader={<Loader key="catalog-loader" />}
      style={ensuredStyle}
      className={className}
      {...pagination}
    >
      <Products dataSource={products} product={product} breakpoints={breakpoints} isStandalone={isStandalone} />
    </InfiniteScroller>
  )
}

export default Catalog
