import gql from 'graphql-tag'

const setComments = gql`
  mutation setComments($comments: String!) {
    setComments(input: { comments: $comments }) {
      order {
        id
        comments
      }
    }
  }
`

export default setComments
