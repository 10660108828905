import gql from 'graphql-tag'

const updateUserContactPersonMutation = gql`
  mutation UpdateUserContactPerson($contactPerson: UserContactPersonInput) {
    updateUserContactPerson(input: { contactPerson: $contactPerson }) {
      userId
      contactPerson {
        firstName
        lastName
        document
        phone
      }
    }
  }
`

export default updateUserContactPersonMutation
