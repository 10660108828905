import { each } from 'lodash'

interface SendFormOptions {
  method: string
  action: string
  fields: Record<string, unknown>
}

function createInput(name: string, value: unknown) {
  const input = document.createElement('input')

  input.type = 'hidden'
  input.value = JSON.stringify(value)
  input.name = name

  return input
}

function sendForm(options: SendFormOptions) {
  const { method, action, fields } = options

  const form = document.createElement('form')

  form.method = method

  form.action = action

  each(fields, (value, key) => {
    if (value) {
      form.appendChild(createInput(key, value))
    }
  })

  document.body.appendChild(form)

  form.submit()
}

export default sendForm
