import React from 'react'
import styled from 'styled-components'

import Button from '~/truck/button'
import Icon from '~/truck/icon'
import theme from '~/truck/theme'
import { getSizeStyle } from '~/truck/utils'

interface AddToFavoritesButtonCircularProps {
  favoriteSince?: moment.MomentInput
  onClick: (event: any) => void
  className?: string
}

const StyledButton = styled(Button.Circular)`
  border-radius: 50%;
  box-shadow:
    0px 1px 8px 0px rgba(0, 0, 0, 0.08),
    0px 3px 4px 0px rgba(0, 0, 0, 0.03),
    0px 3px 3px 0px rgba(0, 0, 0, 0.06);
  z-index: 1;

  &:hover svg {
    fill: rgba(255, 128, 152, 1);
  }

  ${getSizeStyle(theme.spacing.cozy * 5)}
`

function AddToFavoritesButtonCircular(props: AddToFavoritesButtonCircularProps) {
  const { favoriteSince, onClick, className } = props

  const iconType = favoriteSince ? 'heart' : 'heart-outline'

  return (
    <StyledButton
      plain
      onClick={event => {
        event.stopPropagation()

        event.nativeEvent.stopImmediatePropagation()

        onClick(event)
      }}
      className={className}
    >
      <Icon type={iconType} color="error" />
    </StyledButton>
  )
}

export default AddToFavoritesButtonCircular
