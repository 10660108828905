import * as React from 'react'
import clsx from 'clsx'

import Icon from '~/truck/icon'
import NewButton from '~/truck/new-button/NewButton'

export interface KnobProps {
  value: number
  dataId: string
  onClick: () => void
}

export function Knob(props: KnobProps) {
  const { value, dataId, onClick } = props

  const numOfDigits = String(value).length

  if (value !== 0) {
    return (
      <NewButton className="m-0.5" data-id={dataId} variant="secondary" size="icon" onClick={onClick}>
        <span className={clsx('pointer-events-none overflow-hidden whitespace-nowrap', numOfDigits > 4 && 'text-xs')}>
          {value}
        </span>
      </NewButton>
    )
  }

  return (
    <NewButton className="m-0.5" data-id={dataId} variant="secondary" size="icon" onClick={onClick}>
      <Icon type="plus" color="currentColor" />
    </NewButton>
  )
}
