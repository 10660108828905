import React, { Fragment, useState } from 'react'
import dynamic from 'next/dynamic'
import { useFlag } from 'toggled'

import LocationSelector from '~/components/location-selector'
import Media from '~/components/media'
import ShippingModal from '~/components/shipping-modal'
import useExpressDeliverySession from '~/hooks/use-express-delivery-session'
import useFieldValue from '~/hooks/use-field-value'
import useFormatters from '~/hooks/use-formatters'
import useModalManager from '~/hooks/use-modal-manager'
import useMultiDistributorSession from '~/hooks/use-multi-distributor-session'
import Field from '~/truck/field'
import Grid from '~/truck/grid'
import Icon from '~/truck/icon'
import { storage } from '~/utils'
import { flags, storageKeys } from '~/utils/constants'

import ConfirmAddressModal from './confirm-address-modal'
import { FormWrapper, StyledFormActions, StyledGridColumn } from './elements'
import Map from './map'
import NoAddressFound from './no-address-found'
import PlacesAutocomplete from './places-autocomplete'

const GoogleMapsProvider = dynamic(() => import('~/providers/google-maps-provider'), {
  loading: () => null,
  ssr: false,
})

const getCoordinates = (lat, lng) => {
  if (lat && lng) {
    return {
      lat,
      lng,
    }
  }
}

const AddressForm = props => {
  // prettier-ignore
  const { cancellable, withActions, onCancelClick, onCreateClick, style, className, shippingSettings, coverageTree } =
   props

  const { openModal } = useModalManager()

  const hasMapInCreation = useFlag(flags.MAP_IN_ADDRESS_CREATION)

  const hasAllowApartmentInAddress = useFlag(flags.ALLOW_APARTMENT_IN_ADDRESS)

  const hasTerritoryGeofences = useFlag(flags.TERRITORY_GEOFENCES)

  const hasContactPersonFeature = useFlag(flags.CONTACT_PERSON)

  const { multiDistributorSession } = useMultiDistributorSession()

  const { expressDeliverySession } = useExpressDeliverySession()

  const formatters = useFormatters()

  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const hasSelectedAddress = useFieldValue('hasSelectedAddress')

  const latitude = useFieldValue('coordinates.latitude')

  const longitude = useFieldValue('coordinates.longitude')

  const MapContainer = hasMapInCreation ? GoogleMapsProvider : Fragment

  const AddressField = hasMapInCreation ? PlacesAutocomplete : Field

  const showLocationSelector = !hasTerritoryGeofences || shippingSettings.locationSelector

  const onEditClick = () => openModal(ShippingModal)

  const formActions = withActions && (
    <StyledFormActions cancellable={cancellable} onCancelClick={onCancelClick} onCreateClick={onCreateClick} />
  )

  return (
    <FormWrapper>
      <Media desktop={formActions} />
      <Grid style={style} className={className}>
        {showLocationSelector && <LocationSelector coverageTree={coverageTree} />}
        <MapContainer>
          <StyledGridColumn>
            <AddressField
              name="addressLine"
              placeholder="Dirección"
              onEditClick={(multiDistributorSession || expressDeliverySession) && onEditClick}
              noAddressFound={
                hasMapInCreation && (
                  <NoAddressFound
                    onClick={() => setShowConfirmModal(true)}
                    rightIcon={<Icon type="caret-right" size="sm" color="primary" />}
                  />
                )
              }
            />
            {hasMapInCreation && showConfirmModal && (
              <ConfirmAddressModal watchedFieldName="addressLine" onClose={() => setShowConfirmModal(false)} />
            )}
          </StyledGridColumn>
          {hasMapInCreation && hasSelectedAddress && (
            <StyledGridColumn>
              <Map
                initialCoordinates={getCoordinates(latitude, longitude)}
                loadAddressComponent={!storage.has(storageKeys.ADDRESS_COMPONENTS)}
              />
            </StyledGridColumn>
          )}
        </MapContainer>
        {(hasAllowApartmentInAddress || hasMapInCreation) && (
          <StyledGridColumn>
            <Field name="apartment" placeholder="Interior / Oficina / Piso" />
          </StyledGridColumn>
        )}
        <StyledGridColumn>
          <Field name="reference" placeholder="Referencia" />
        </StyledGridColumn>
        {!hasContactPersonFeature && (
          <>
            <StyledGridColumn xs={6}>
              <Field name="contactPerson" placeholder="Persona de contacto" />
            </StyledGridColumn>
            <StyledGridColumn xs={6}>
              <Field name="contactPhone" placeholder="Celular de contacto" parse={formatters.phone} type="tel" />
            </StyledGridColumn>
            {shippingSettings.contactDocumentNumber && (
              <StyledGridColumn xs={6}>
                <Field name="contactDocumentNumber" placeholder="DNI del contacto" />
              </StyledGridColumn>
            )}
            {shippingSettings.contactEmail && (
              <StyledGridColumn xs={6}>
                <Field name="contactEmail" placeholder="Correo de contacto" />
              </StyledGridColumn>
            )}
          </>
        )}
        {shippingSettings.zipCode && (
          <StyledGridColumn xs={6}>
            <Field name="zipCode" placeholder="Código postal" />
          </StyledGridColumn>
        )}
      </Grid>
      <Media mobile={formActions} />
    </FormWrapper>
  )
}

export default AddressForm
