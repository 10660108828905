import type { IVendor } from '~/types/vendors'

import localeData from './locale.json'
import vendorData from './vendor.json'

const defaultLocale = localeData.PE

const getStrings = (vendor: IVendor) => {
  const { code } = vendor.country

  return {
    ...defaultLocale,
    ...localeData[code],
    ...vendorData.default,
    ...vendorData[vendor.slug],
  }
}

export default getStrings
