export class TrackedEventError extends Error {
  public eventName: string

  constructor(eventName: string) {
    const message = `The event "${eventName}" can not be tracked`

    super(message)

    this.name = this.constructor.name

    this.eventName = eventName

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor)
    } else {
      this.stack = new Error(message).stack
    }
  }
}
