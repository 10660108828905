import * as React from 'react'

import {
  PaymentCardNumber,
  PaymentExpirationMonth,
  PaymentExpirationYear,
  PaymentOwnerName,
  PaymentSecurityCode,
} from '~/components'
import { useCalculateCartPricing, useFieldValue } from '~/hooks'
import Image from '~/truck/image'
import Label from '~/truck/label'
import theme from '~/truck/theme'
import { baseUrls } from '~/utils/constants'

import {
  Container,
  DebitCardImagesContainer,
  FooterContainer,
  FormContainer,
  HeaderContainer,
  ImageContainer,
  StyledImage,
  StyledLabel,
  TwoColumns,
} from './elements'

const OpenpayForm = props => {
  const { paymentMethodId } = props

  const cardNumber = useFieldValue('cardNumber')

  const { calculateAndSetCartPricing } = useCalculateCartPricing()

  React.useEffect(() => {
    const cardFormat = cardNumber?.replace(/\s/g, '') ?? ''

    if (cardFormat.length === 6) {
      calculateAndSetCartPricing({ paymentMethodId, bin: cardFormat.slice(0, 6) })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardNumber])

  return (
    <Container>
      <HeaderContainer>
        <div>
          <StyledLabel as="p">Tarjetas de crédito</StyledLabel>
          <Image
            style={{ maxWidth: theme.spacing.comfortable * 12, paddingTop: '16px' }}
            src={`${baseUrls.CLOUDINARY}/v1661451820/sellers/resources/tarjetas-openpay.png`}
          />
        </div>
        <div>
          <StyledLabel as="p">Tarjetas de débito</StyledLabel>
          <DebitCardImagesContainer>
            <StyledImage src={`${baseUrls.CLOUDINARY}/v1662926916/sellers/resources/BBVA.png`} />
            <StyledImage src={`${baseUrls.CLOUDINARY}/v1662929202/sellers/resources/citibanamex1.png`} />
            <StyledImage src={`${baseUrls.CLOUDINARY}/v1662926916/sellers/resources/bancoAzteca.png`} />
            <StyledImage src={`${baseUrls.CLOUDINARY}/v1662926916/sellers/resources/scotiabank.png`} />
            <StyledImage src={`${baseUrls.CLOUDINARY}/v1662926916/sellers/resources/inbursa.png`} />
            <StyledImage src={`${baseUrls.CLOUDINARY}/v1662926916/sellers/resources/santander.png`} />
          </DebitCardImagesContainer>
        </div>
      </HeaderContainer>
      <FormContainer>
        <PaymentOwnerName />
        <PaymentCardNumber />
        <TwoColumns>
          <PaymentExpirationMonth />
          <PaymentExpirationYear />
        </TwoColumns>
        <TwoColumns>
          <PaymentSecurityCode maxLength={4} />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ImageContainer>
              <Image
                style={{ marginRight: theme.spacing.relaxed }}
                src={`${baseUrls.CLOUDINARY}/v1592271029/sellers/resources/card-form/card-2.png`}
              />
              <Image src={`${baseUrls.CLOUDINARY}/v1592271029/sellers/resources/card-form/card-1.png`} />
            </ImageContainer>
          </div>
        </TwoColumns>
        <FooterContainer>
          <div style={{ display: 'flex', marginBottom: theme.spacing.cozy * 3 }}>
            <ImageContainer style={{ marginRight: theme.spacing.cozy }}>
              <Image
                style={{
                  height: '100%',
                }}
                src={`${baseUrls.CLOUDINARY}/v1592271029/sellers/resources/card-form/secure.png`}
              />
            </ImageContainer>
            <Label as="p" $textStyle="h6Regular" $color="black30">
              Tus pagos se realizan de forma segura con encriptación de 256 bits
            </Label>
          </div>
          <Image src={`${baseUrls.CLOUDINARY}/v1661451684/sellers/resources/logotipo-openpay.png`} />
        </FooterContainer>
      </FormContainer>
    </Container>
  )
}

export default OpenpayForm
