import * as React from 'react'
import styled from 'styled-components'

import Overlay from '~/truck/overlay'
import theme from '~/truck/theme'

import { FloatingHeader } from '../floating-header'

export interface DrawerProps {
  /**
   * @description Function to close the drawer
   */
  onClose: () => void
  /**
   * @description Children of the drawer
   */
  children: () => React.ReactNode
  /**
   * @description The flag to open the drawer
   * @default false
   */
  isOpen: boolean
  /**
   * @description The title of the header.
   */
  headerTitle?: string
  /**
   * @description The width of the drawer in desktop mode.
   * @default 8 * 62 = 496px
   */
  desktopWidth?: number
}

const StyledContainer = styled.div<{ $desktopWidth?: number }>`
  background: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100dvh;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  width: ${props => (props.$desktopWidth ? props.$desktopWidth : props.theme.spacing.cozy * 62)}px;
  z-index: 99;
`

export function Drawer(props: DrawerProps) {
  const { children, isOpen, onClose, headerTitle, desktopWidth } = props

  if (!isOpen) {
    return null
  }

  return (
    <>
      <Overlay onClick={onClose} open />
      <StyledContainer $desktopWidth={desktopWidth}>
        {headerTitle ? <FloatingHeader title={headerTitle} onClose={onClose} /> : null}
        <div style={{ flex: 1 }}>{children()}</div>
      </StyledContainer>
    </>
  )
}
