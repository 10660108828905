import styled from 'styled-components'

function resolveUnit(value: any) {
  if (typeof value === 'number') {
    return `${value}px`
  }

  return value
}

interface DivProps {
  $height: number | string
  $width: number | string
  $src: string
  $size?: string
}

export const Div = styled.div<DivProps>`
  background-image: url(${props => props.$src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: ${props => props.$size ?? 'cover'};
  height: ${props => resolveUnit(props.$height)};
  width: ${props => resolveUnit(props.$width)};
`

export const Img = styled.img`
  object-fit: contain;
`
