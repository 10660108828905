import gql from 'graphql-tag'

import { lineItemForCartFragment } from '../fragments'

const upsertProductInCartMutation = gql`
  mutation upsertProductInCart($productSlug: String!, $quantity: Float!) {
    addOrUpdateProductInCart(input: { productSlug: $productSlug, quantity: $quantity }) {
      lineItem: transactionItem {
        ...lineItemForCart
      }
    }
  }
  ${lineItemForCartFragment}
`

export default upsertProductInCartMutation
