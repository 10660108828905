function getPriceDiscountPercentage(oldPrice: string, price: string) {
  if (!oldPrice || Number(oldPrice) <= Number(price)) {
    return
  }

  const percentage = Math.round((1 - Number(price) / Number(oldPrice)) * 100)

  return `- ${percentage}%`
}

export default getPriceDiscountPercentage
