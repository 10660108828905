import * as React from 'react'
import styled from 'styled-components'

import theme from '~/truck/theme'
import { stack } from '~/variables'

export interface DropdownMenuProps {
  children: React.ReactNode
  className?: string
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  width?: number
}

const StyledDropdownMenuContainer = styled.div<{ $width: number }>`
  background-color: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.dimensions.borderRadius}px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding-bottom: ${props => props.theme.spacing.cozy}px;
  padding-top: ${props => props.theme.spacing.cozy}px;
  width: ${props => props.$width}px;
  z-index: ${stack.dropdownMenu};

  &::before {
    background-color: white;
    content: '';
    height: ${props => props.theme.spacing.comfortable}px;
    position: absolute;
    right: ${props => props.theme.spacing.comfortable}px;
    top: -${props => props.theme.spacing.cozy}px;
    transform: rotate(45deg);
    width: ${props => props.theme.spacing.comfortable}px;
  }

  &::after {
    bottom: 100%;
    clip-path: polygon(95% 0%, 0 100%, 100% 100%);
    content: '';
    height: ${props => props.theme.spacing.cozy * 5}px;
    position: absolute;
    width: 100%;
  }
`

export function DropdownMenu(props: DropdownMenuProps) {
  const { children, className, onMouseEnter, onMouseLeave, width = theme.spacing.cozy * 30 } = props

  return (
    <StyledDropdownMenuContainer
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      $width={width}
    >
      {children}
    </StyledDropdownMenuContainer>
  )
}
