import gql from 'graphql-tag'

const setStoreToBuyMutation = gql`
  mutation setStoreToBuy {
    setStoreToBuy(input: {}) {
      order {
        id
        store {
          id
        }
      }
    }
  }
`

export default setStoreToBuyMutation
