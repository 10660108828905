import gql from 'graphql-tag'

import { productFragment } from '~/gql/fragments'

export const suggestedProductsQuery = gql`
  query suggestedProducts {
    suggestedProducts {
      ...product
    }
  }

  ${productFragment}
`
