import React, { useMemo, useState } from 'react'
import { debounce } from 'lodash'

interface SearchProps {
  onChange?: (term: string) => void
  children: (props: any) => React.ReactNode
  defaultValue?: string
  debounced?: boolean
}

function Search({ onChange: originalOnChange, children, defaultValue, debounced = true }: SearchProps) {
  const [term, setTerm] = useState(defaultValue || '')

  const debouncedOnChange = useMemo(() => {
    if (originalOnChange) {
      return debounce(originalOnChange, 600)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderProps = {
    value: term,
    reset: () => setTerm(''),
    onChange: e => {
      const newTerm = e.currentTarget.value

      setTerm(newTerm)

      if (debounced && debouncedOnChange) {
        debouncedOnChange(newTerm)
      }
    },
    onKeyDown: e => {
      if (e.keyCode === 13) {
        originalOnChange(term)
      }
    },
  }

  return <>{children(renderProps)}</>
}

export default Search
