import qs from 'qs'

export function stringify(obj: any, options?: qs.IStringifyOptions) {
  return qs.stringify(obj, {
    indices: false,
    arrayFormat: 'brackets',
    ...options,
  })
}

export function parse<T = any>(
  query: string,
  options?: qs.IParseOptions & {
    decoder?: undefined
  },
): T {
  return qs.parse(query, {
    parseArrays: true,
    ...options,
  }) as unknown as T
}
