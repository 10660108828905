import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import theme from '~/truck/theme'
import { viewportHeights } from '~/variables'

export const LayoutContent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: ${viewportHeights.getMainMobile};
  width: 100%;

  ${up('lg')} {
    margin: ${theme.spacing.comfortable}px auto;
    min-height: ${viewportHeights.getMainDesktop};
  }
`
