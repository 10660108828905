import cookie from 'isomorphic-cookie'

import { cookieNames } from '~/utils/constants'
import redirectTo from '~/utils/redirect-to'

const ensureMultidistributorRouting = context => {
  const { req, res, asPath } = context

  const session = cookie.load(cookieNames.MULTI_DISTRIBUTOR_SESSION, req)

  const isInTargetPath = asPath === '/elegir-despacho'

  const redirectToChooseShippingType = !session && !isInTargetPath

  if (redirectToChooseShippingType) {
    if (!isInTargetPath) {
      cookie.save(cookieNames.CALLBACK_URL, asPath, { secure: false }, res)
    }

    return redirectTo('/elegir-despacho', context)
  }

  if (session && isInTargetPath) {
    return redirectTo('/', context)
  }
}

export default ensureMultidistributorRouting
