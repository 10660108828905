import React from 'react'

import Anchor from '~/truck/anchor'
import Card from '~/truck/card'

interface PlaceholderProps {
  text: string
  onClick?: () => void
  href?: string
  style?: React.CSSProperties
  className?: string
}

const Placeholder = (props: PlaceholderProps) => {
  const { text, onClick, href, style, className } = props

  return (
    <Card
      style={{
        display: 'flex',
        padding: 16,
        ...style,
        justifyContent: 'flex-end',
      }}
      className={className}
    >
      <Anchor onClick={onClick} href={href}>
        {text}
      </Anchor>
    </Card>
  )
}

export default Placeholder
