import React from 'react'
import { find } from 'lodash'
import styled from 'styled-components'

import Label from '~/truck/label'
import theme from '~/truck/theme'

import Price from '../price'

interface AvailableCreditProps {
  usedCredit?: number
  totalCredit?: number
  color?: string
  message?: string
  description?: string
  style?: React.CSSProperties
  className?: string
}

const percentageColors = {
  0: 'error',
  100: 'info',
}

const ProgressBar = styled.div<{ percentage: number }>`
  background-color: ${props => props.theme.colors.gray};
  border-radius: ${theme.spacing.cozy}px;
  height: 6px;
  position: relative;

  &::after {
    background-color: ${props => props.theme.colors[props.color]};
    border-radius: ${theme.spacing.cozy}px;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: ${props => props.percentage}%;
  }
`

const AmountsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const Container = styled.div`
  ${ProgressBar} {
    margin: ${theme.spacing.cozy}px 0;
    margin-top: ${theme.spacing.compact}px;
  }

  ${AmountsContainer} {
    margin-bottom: ${theme.spacing.comfortable}px;
  }
`

function AvailableCredit(props: AvailableCreditProps) {
  const { style, className, usedCredit = 0, totalCredit = 0, color, message, description } = props

  const availableCreditPercentage = 100 - (usedCredit / totalCredit) * 100

  const statusColor =
    color || find(percentageColors, (_, percentage) => availableCreditPercentage <= Number(percentage))

  return (
    <Container style={style} className={className}>
      {message && (
        <Label as="span" $textStyle="h5Regular" $textAlign="center">
          {message}
        </Label>
      )}
      <Price textStyle="h3Semibold" color={statusColor} value={totalCredit - usedCredit} />
      <ProgressBar percentage={availableCreditPercentage} color={statusColor} />
      <AmountsContainer>
        <div>
          <Price textStyle="h5Regular" color="black" value={+usedCredit} />
          <Label as="span" $textStyle="h6Regular" $color="graySlate">
            Utilizado
          </Label>
        </div>
        <div>
          <Price textStyle="h5Regular" value={+totalCredit} color="black" textAlign="right" />
          <Label as="span" $textStyle="h6Regular" $color="graySlate">
            Línea de crédito
          </Label>
        </div>
      </AmountsContainer>
      {description && (
        <Label as="p" $textStyle="h5Regular">
          {description}
        </Label>
      )}
    </Container>
  )
}

export default AvailableCredit
