import gql from 'graphql-tag'

const shipmentQuote = gql`
  query shipmentQuote($deliveryAddressId: ID!) {
    shipmentQuote(addressId: $deliveryAddressId) {
      id
      fares {
        id
        amount
        currency
        durationTerms
        maxShippingDays
        provider
        serviceLevel
      }
    }
  }
`

export default shipmentQuote
