import styled from 'styled-components'

import Label from '~/truck/label'
import theme from '~/truck/theme'

interface ActionProps {
  $color?: string
  $disabled?: boolean
  $as?: string
  $textStyle?: string
  color?: never
}

const Action = styled(Label).attrs((props: ActionProps) => ({
  as: props.$as ?? 'span',
  $textStyle: props.$textStyle ?? 'h5Semibold',
}))`
  cursor: pointer;

  ${(props: ActionProps) =>
    props.$disabled &&
    `
    color: ${theme.colors.blackLight};
    cursor: not-allowed;
  `}
`

export default Action
