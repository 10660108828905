import getCardInfo, { types as CardTypes } from 'credit-card-type'

function clearNumber(value = ''): string {
  return value.replace(/\D+/g, '')
}

export function formatCreditCardNumber(value: string): string {
  if (!value) {
    return ''
  }

  const cardInfo = getCardInfo(value)

  const clearValue = clearNumber(value)

  const cardType = cardInfo?.[0]?.type

  let nextValue

  switch (cardType) {
    case CardTypes.AMERICAN_EXPRESS:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 15)}`
      break
    case CardTypes.DINERS_CLUB:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 14)}`
      break
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(8, 12)} ${clearValue.slice(
        12,
        19,
      )}`
      break
  }

  return nextValue.trim()
}

export function formatExpirationDate(value: string): string {
  return clearNumber(value).slice(0, 2)
}

export function formatSecurityCode(value: string): string {
  return clearNumber(value).slice(0, 4)
}
