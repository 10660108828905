import { createGlobalStyle } from 'styled-components'

interface GlobalStyleProps {
  $customFontFamily?: string
  $bgColor?: string
}

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  ${props =>
    props.$customFontFamily
      ? `
html, :host
{
  font-family: ${props.$customFontFamily}, "Open Sans", sans-serif;
}
`
      : ''}
${props =>
  props.$bgColor
    ? `
  html, body {
    background-color: ${props.$bgColor};
  }
`
    : ''}
`

export default GlobalStyle
