import * as React from 'react'
import { ThemeProvider } from 'styled-components'

import { createTheme } from '~/truck/utils'

export interface StandaloneAppProviderProps {
  children: React.ReactNode
}

const StandaloneAppProvider = (props: StandaloneAppProviderProps) => {
  const { children } = props

  const theme = React.useMemo(() => createTheme(), [])

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default StandaloneAppProvider
