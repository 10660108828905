import styled from 'styled-components'

export const Container = styled.a`
  flex-shrink: 0;
  position: relative;

  > p {
    left: 50%;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`
