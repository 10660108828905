import styled from 'styled-components'

import theme from '~/truck/theme'

export const Container = styled.div`
  background-color: ${theme.colors.grayLight};
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing.cozy * 2.5}px;
  padding: ${theme.spacing.relaxed}px;
`

export const Cards = styled.div`
  display: flex;
  margin-top: ${theme.spacing.relaxed}px;

  > img {
    margin-right: ${theme.spacing.comfortable}px;
    opacity: 0.3;
    width: ${theme.spacing.cozy * 5}px;
  }
`

export const FormContainer = styled.div`
  border-top: 1px solid ${theme.colors.gray};
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing.relaxed}px;
  padding-top: ${theme.spacing.relaxed}px;
  width: 100%;

  > *:not(:last-child) {
    margin-bottom: ${theme.spacing.comfortable}px;
  }
`

export const ImageContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  height: ${theme.spacing.cozy * 4}px;

  > img {
    height: 100%;
  }
`

export const TwoColumns = styled.div`
  display: flex;

  > * {
    flex: 1;
  }

  > *:first-child {
    margin-right: ${theme.spacing.comfortable}px;
  }
`
