import React from 'react'
import MaskedInput from 'react-text-mask'

import { StyledInput } from './elements'
import { createNumberMask, createPlaceholder, normalizeAmount } from './helpers'

type NumericInputProps = React.ComponentPropsWithoutRef<'input'> & {
  onChange: (value: string) => void
  integerLimit?: number | null
  decimalLimit?: number
  placeholder?: string | null
}

function getPlaceholder(decimalLimit = 0, placeholder?: string | null) {
  if (placeholder === null) {
    return null
  }

  return placeholder || createPlaceholder(decimalLimit)
}

function NumericInput({
  onChange,
  integerLimit = null,
  decimalLimit = 0,
  placeholder,
  ...inputProps
}: NumericInputProps) {
  const newPlaceholder = getPlaceholder(decimalLimit, placeholder)

  return (
    <MaskedInput
      {...inputProps}
      onChange={e => {
        const {
          currentTarget: { value },
        } = e

        if (onChange) {
          const normalizedAmount = normalizeAmount(decimalLimit, value)

          onChange(normalizedAmount)
        }
      }}
      placeholder={newPlaceholder}
      mask={createNumberMask(integerLimit, decimalLimit)}
      render={(ref, meta) => {
        return <StyledInput type="tel" {...meta} ref={ref} />
      }}
    />
  )
}

export default NumericInput
