import { map, noop } from 'lodash'

import type {
  AnalyticsCollector,
  AnalyticsCollectorAddToCartVariables,
  AnalyticsCollectorBeginCheckoutVariables,
  AnalyticsCollectorContactVariables,
  AnalyticsCollectorPurchaseVariables,
  AnalyticsCollectorSocialMediaVariables,
  AnalyticsCollectorViewItemVariables,
} from './analytics-types'

if (typeof window !== 'undefined') {
  window.fbq = window.fbq || noop
}

export const analyticsFbq: AnalyticsCollector = {
  pageView: noop,
  search: noop,
  selectItem: noop,
  viewItem: (variables: AnalyticsCollectorViewItemVariables) => {
    fbq('track', 'ViewContent', {
      content_ids: [variables.product.item_id],
      content_category: variables.product.item_category,
      content_name: variables.product.item_name,
      content_type: 'product',
      value: variables.product.price,
      currency: variables.currency_code,
    })
  },
  viewItemList: noop,
  addToCart: (variables: AnalyticsCollectorAddToCartVariables) => {
    fbq('track', 'AddToCart', {
      content_ids: [variables.product.item_id],
      content_name: variables.product.item_name,
      content_type: 'product',
      currency: variables.currency_code,
      value: variables.product.price,
    })
  },
  removeFromCart: noop,
  viewCart: noop,
  beginCheckout: (variables: AnalyticsCollectorBeginCheckoutVariables) => {
    fbq('track', 'InitiateCheckout', {
      content_category: variables.step_name,
    })
  },
  checkoutProgress: noop,
  purchase: (variables: AnalyticsCollectorPurchaseVariables) => {
    fbq('track', 'Purchase', {
      value: variables.order_total,
      currency: variables.currency_code,
      contents: variables.order_products,
      content_ids: map(variables.order_products, 'id'),
      content_type: 'product',
    })
  },
  login: noop,
  signUp: noop,
  preSignUpAttempt: noop,
  paymentError: noop,
  contact: (variables: AnalyticsCollectorContactVariables) => {
    fbq('track', 'Contact', {
      type: variables.type,
    })
  },
  socialMedia: (variables: AnalyticsCollectorSocialMediaVariables) => {
    fbq('trackCustom', 'SocialMediaClick', {
      type: variables.type,
    })
  },
  viewPromotion: noop,
  selectPromotion: noop,
  closeSuggestedProducts: noop,
}
