import React from 'react'
import { useQuery } from '@apollo/react-hooks'

import { storesQuery } from '~/gql/queries'

import Loader from '../loader'

import Component from './component'

interface StoreSelectorProps {
  onSelect?: (store: any) => void
  defaultValue?: string
  onlyPickables?: boolean
  style?: React.CSSProperties
  className?: string
}

function StoreSelector(props: StoreSelectorProps) {
  const { onSelect, defaultValue, onlyPickables, style, className } = props

  const { loading, data } = useQuery(storesQuery, {
    variables: { showAll: !onlyPickables, limit: 50 },
  })

  if (loading) {
    return <Loader />
  }

  const stores = data?.vendor?.stores?.items

  return (
    <Component
      style={style}
      defaultValue={defaultValue}
      className={className}
      dataSource={stores}
      onSelect={onSelect}
    />
  )
}

export default StoreSelector
