import * as React from 'react'
import clsx from 'clsx'

import Icon from '~/truck/icon'
import { reactStopPropagation } from '~/utils'

import { ExpandContainer, StyledContainerIcon, StyledNumericInput, StyledRectangle } from './styles'

interface ExtendedCounterProps {
  value: number
  displayValue: number
  decimalLimit?: number
  isMinValue?: boolean
  disableAnimation: boolean
  onChange?: (number) => void
  onBlur?: (number) => void
  onMinusClick?: (e: React.MouseEvent<SVGSVGElement | HTMLDivElement>) => void
  onPlusClick?: (e: React.MouseEvent<SVGSVGElement | HTMLDivElement>) => void
}

const ExtendedCounter = React.forwardRef((props: ExtendedCounterProps, ref) => {
  const {
    value,
    displayValue,
    decimalLimit,
    onChange,
    onBlur,
    onMinusClick,
    onPlusClick,
    isMinValue,
    disableAnimation = false,
  } = props

  const [isOpen, setIsOpen] = React.useState(true)

  const hasZero = Number(value) === 0

  const expandContainerRef = React.useRef(null)

  React.useEffect(() => {
    expandContainerRef.current.style.width = '128px'

    expandContainerRef.current.style.opacity = 1
  }, [])

  React.useImperativeHandle(
    ref,
    () => ({
      collapse() {
        expandContainerRef.current.style.width = '0px'

        expandContainerRef.current.style.opacity = 0

        setIsOpen(false)
      },
    }),
    [],
  )

  return (
    <StyledRectangle>
      <ExpandContainer ref={expandContainerRef} $isOpen={isOpen} $disableAnimation={disableAnimation}>
        <StyledContainerIcon
          disabled={hasZero}
          isMinValue={isMinValue}
          onClick={event => !hasZero && onMinusClick(event)}
        >
          <Icon
            className={clsx(
              hasZero ? 'text-zinc-400' : isMinValue ? 'text-brand-secondary' : 'text-brand-secondary-on-bg',
            )}
            interactive={false}
            color="currentColor"
            type={isMinValue ? 'trash' : 'minus'}
          />
        </StyledContainerIcon>
        <StyledNumericInput
          value={displayValue}
          decimalLimit={decimalLimit}
          onChange={value => onChange(value)}
          onBlur={event => onBlur(Number(event.target.value))}
          onClick={reactStopPropagation}
        />
      </ExpandContainer>
      <StyledContainerIcon onClick={onPlusClick}>
        <Icon className="text-brand-secondary-on-bg" color="currentColor" interactive={false} type="plus" />
      </StyledContainerIcon>
    </StyledRectangle>
  )
})

export default ExtendedCounter
