import React from 'react'

import Loader from '~/truck/loader'

import { Base } from './elements'

export type BaseButtonProps = React.ComponentProps<'button'> & {
  as?: React.ElementType
  forwardedRef?: React.Ref<HTMLButtonElement>
  transparent?: boolean
  plain?: boolean
  ghost?: boolean
  circular?: boolean
  loading?: boolean
  color?: string
}

function getLoaderColor(props: BaseButtonProps) {
  const { ghost, plain, disabled, transparent } = props

  if (disabled) {
    return 'gray'
  }

  if (ghost ?? plain ?? transparent) {
    return 'primary'
  }

  return 'white'
}

function renderContent(props: BaseButtonProps) {
  const { loading, children, circular } = props

  const loaderColor = getLoaderColor(props)

  if (loading && !circular) {
    return (
      <>
        <Loader style={{ position: 'absolute' }} color={loaderColor} />
        <div style={{ visibility: 'hidden' }}>{children}</div>
      </>
    )
  }

  return children
}

function BaseButton(props: BaseButtonProps) {
  // eslint-disable-next-line react/prop-types
  const { loading, forwardedRef, onClick, transparent, plain, ghost, circular, color, ...rest } = props

  return (
    <Base
      {...rest}
      ref={forwardedRef}
      onClick={loading ? undefined : onClick}
      $loading={loading}
      $transparent={transparent}
      $plain={plain}
      $ghost={ghost}
      $circular={circular}
      $color={color}
    >
      {renderContent(props)}
    </Base>
  )
}

export default BaseButton
