import { noop, pick } from 'lodash'

import {
  AnalyticsCollector,
  AnalyticsCollectorAddToCartVariables,
  AnalyticsCollectorCheckoutProgressVariables,
  AnalyticsCollectorCloseSuggestedProductsVariables,
  AnalyticsCollectorLoginVariables,
  AnalyticsCollectorOptions,
  AnalyticsCollectorPageViewVariables,
  AnalyticsCollectorPaymentErrorVariables,
  AnalyticsCollectorPurchaseVariables,
  AnalyticsCollectorRemoveFromCartVariables,
  AnalyticsCollectorSearchVariables,
  AnalyticsCollectorSelectItemVariables,
  AnalyticsCollectorSignUpVariables,
  AnalyticsCollectorViewItemListVariables,
  AnalyticsCollectorViewItemVariables,
  AnalyticsEvents,
} from '~/analytics/analytics-types'
import type { IVendor } from '~/types/vendors'

import { collectGlobalVariables } from './analytics-utils'

declare let _gtag: Gtag.Gtag

// https://developers.google.com/analytics/devguides/collection/gtagjs/renaming-the-gtag-object
if (typeof window !== 'undefined') {
  window._gtag = window._gtag || noop
}

function getDimensionName(index: number) {
  return `dimension${index}`
}

const vendorDimensionKeys = ['clientId', 'userId', 'sessionStatus', 'partnerVendorEndpoint', 'partnerVendorName']

const riqraDimensions = {
  vendorId: 1,
  vendorName: 2,
  partnerVendorId: 3,
  partnerVendorName: 4,
  context: 5,
  paymentProvider: 6,
  paymentMethod: 7,
}

const riqraAnalyticsId = process.env.GOOGLE_ANALYTICS_ID

export function analyticsGtagDimensions(vendor: IVendor, user) {
  const vendorDimensions = vendor.settings?.analytics?.dimensions

  const globalVariables = collectGlobalVariables(vendor, user)

  const collectVendorDimensions = () => {
    const { clientId, userId, sessionStatus, partnerVendorEndpoint, partnerVendorName } = pick(
      vendorDimensions,
      vendorDimensionKeys,
    )

    const dimensions: Record<string, string> = {}

    if (partnerVendorEndpoint && globalVariables.partner_vendor_endpoint) {
      dimensions[getDimensionName(partnerVendorEndpoint)] = globalVariables.partner_vendor_endpoint
    }

    if (partnerVendorName && globalVariables.partner_vendor_name) {
      dimensions[getDimensionName(partnerVendorName)] = globalVariables.partner_vendor_name
    }

    if (clientId && globalVariables.client_id) {
      dimensions[getDimensionName(clientId)] = globalVariables.client_id
    }

    if (userId && globalVariables.user_code) {
      dimensions[getDimensionName(userId)] = globalVariables.user_code
    }

    if (sessionStatus && globalVariables.session_status) {
      dimensions[getDimensionName(sessionStatus)] = globalVariables.session_status
    }

    return dimensions
  }

  const collectRiqraDimensions = () => {
    const dimensions: Record<string, string | number> = {}

    dimensions.vendorId = globalVariables.vendor_id

    dimensions.vendorName = globalVariables.vendor_name

    if (globalVariables.partner_vendor_id) {
      dimensions.partnerVendorId = globalVariables.partner_vendor_id
    }

    if (globalVariables.partner_vendor_name) {
      dimensions.partnerVendorName = globalVariables.partner_vendor_name
    }

    return dimensions
  }

  if (vendorDimensions) {
    // set dimensions for all events directed to this analytics id
    _gtag('set', vendor.googleAnalyticsId, collectVendorDimensions())
  }

  if (riqraAnalyticsId) {
    _gtag('config', riqraAnalyticsId, {
      custom_map: {
        [getDimensionName(riqraDimensions.vendorId)]: 'vendorId',
        [getDimensionName(riqraDimensions.vendorName)]: 'vendorName',
        [getDimensionName(riqraDimensions.partnerVendorId)]: 'partnerVendorId',
        [getDimensionName(riqraDimensions.partnerVendorName)]: 'partnerVendorName',
        [getDimensionName(riqraDimensions.context)]: 'context',
        [getDimensionName(riqraDimensions.paymentProvider)]: 'paymentProvider',
        [getDimensionName(riqraDimensions.paymentMethod)]: 'paymentMethod',
      },
      ...collectRiqraDimensions(),
    })
  }
}

/** @deprecated universal analytics will be removed in the future */
export const analyticsGtag: AnalyticsCollector = {
  pageView: (variables: AnalyticsCollectorPageViewVariables, options: AnalyticsCollectorOptions) => {
    const { googleAnalyticsId } = options.vendor

    if (googleAnalyticsId) {
      _gtag('config', googleAnalyticsId, {
        page_title: variables.title,
        page_path: variables.path,
      })
    }

    if (riqraAnalyticsId) {
      _gtag('config', riqraAnalyticsId, {
        page_title: variables.title,
        page_path: variables.path,
        send_to: 'riqra',
      })
    }
  },
  search: (variables: AnalyticsCollectorSearchVariables) => {
    _gtag('event', AnalyticsEvents.SEARCH, {
      search_term: variables.term,
    })

    if (riqraAnalyticsId) {
      _gtag('event', AnalyticsEvents.SEARCH, {
        search_term: variables.term,
        send_to: 'riqra',
      })
    }
  },
  selectItem: (variables: AnalyticsCollectorSelectItemVariables) => {
    _gtag('event', AnalyticsEvents.SELECT_ITEM, {
      items: [variables.product],
      item_list_name: variables.itemListName,
      item_list_id: variables.itemListId,
    })

    if (riqraAnalyticsId) {
      _gtag('event', AnalyticsEvents.SELECT_ITEM, {
        items: [variables.product],
        send_to: 'riqra',
      })
    }
  },
  viewItem: (variables: AnalyticsCollectorViewItemVariables) => {
    _gtag('event', AnalyticsEvents.VIEW_ITEM, {
      items: [variables.product],
    })

    if (riqraAnalyticsId) {
      _gtag('event', AnalyticsEvents.VIEW_ITEM, {
        items: [variables.product],
        send_to: 'riqra',
        context: variables.context,
      })
    }
  },
  viewItemList: (variables: AnalyticsCollectorViewItemListVariables) => {
    _gtag('event', AnalyticsEvents.VIEW_ITEM_LIST, {
      items: variables.products,
    })

    if (riqraAnalyticsId) {
      _gtag('event', AnalyticsEvents.VIEW_ITEM_LIST, {
        items: variables.products,
        send_to: 'riqra',
        context: variables.context,
      })
    }
  },
  addToCart: (variables: AnalyticsCollectorAddToCartVariables) => {
    _gtag('event', AnalyticsEvents.ADD_TO_CART, {
      items: [variables.product],
      currency: variables.currency_code,
    })

    if (riqraAnalyticsId) {
      _gtag('event', AnalyticsEvents.ADD_TO_CART, {
        items: [variables.product],
        currency: variables.currency_code,
        send_to: 'riqra',
        context: variables.context,
      })
    }
  },
  removeFromCart: (variables: AnalyticsCollectorRemoveFromCartVariables) => {
    _gtag('event', AnalyticsEvents.REMOVE_FROM_CART, {
      items: [variables.product],
      currency: variables.currency_code,
    })

    if (riqraAnalyticsId) {
      _gtag('event', AnalyticsEvents.REMOVE_FROM_CART, {
        items: [variables.product],
        currency: variables.currency_code,
        send_to: 'riqra',
        context: variables.context,
      })
    }
  },
  viewCart: noop,
  beginCheckout: () => {
    _gtag('event', AnalyticsEvents.BEGIN_CHECKOUT)

    if (riqraAnalyticsId) {
      _gtag('event', AnalyticsEvents.BEGIN_CHECKOUT, {
        send_to: 'riqra',
      })
    }
  },
  checkoutProgress: (variables: AnalyticsCollectorCheckoutProgressVariables) => {
    _gtag('event', AnalyticsEvents.CHECKOUT_PROGRESS, {
      checkout_step: variables.step_number,
    })

    if (riqraAnalyticsId) {
      _gtag('event', AnalyticsEvents.CHECKOUT_PROGRESS, {
        checkout_step: variables.step_number,
        send_to: 'riqra',
      })
    }
  },
  purchase: (variables: AnalyticsCollectorPurchaseVariables) => {
    const gtagPayload = {
      transaction_id: variables.order_id,
      affiliation: variables.seller_name,
      currency: variables.currency_code,
      value: variables.order_total,
      shipping: variables.order_delivery_cost,
      items: variables.order_products,
    }

    _gtag('event', AnalyticsEvents.PURCHASE, gtagPayload)

    if (riqraAnalyticsId) {
      _gtag('event', AnalyticsEvents.PURCHASE, {
        ...gtagPayload,
        send_to: 'riqra',
        context: variables.context,
      })
    }
  },
  login: (variables: AnalyticsCollectorLoginVariables) => {
    _gtag('event', AnalyticsEvents.LOGIN, {
      method: variables.method,
    })

    if (riqraAnalyticsId) {
      _gtag('event', AnalyticsEvents.LOGIN, {
        method: variables.method,
        send_to: 'riqra',
        context: variables.context,
      })
    }
  },
  signUp: (variables: AnalyticsCollectorSignUpVariables) => {
    _gtag('event', AnalyticsEvents.SIGN_UP, {
      method: variables.method,
    })

    if (riqraAnalyticsId) {
      _gtag('event', AnalyticsEvents.SIGN_UP, {
        method: variables.method,
        send_to: 'riqra',
        context: variables.context,
      })
    }
  },
  preSignUpAttempt: () => {
    _gtag('event', AnalyticsEvents.PRE_SIGN_UP_ATTEMPT)

    if (riqraAnalyticsId) {
      _gtag('event', AnalyticsEvents.PRE_SIGN_UP_ATTEMPT, {
        send_to: 'riqra',
      })
    }
  },
  paymentError: (variables: AnalyticsCollectorPaymentErrorVariables) => {
    if (riqraAnalyticsId) {
      _gtag('event', variables.event_name, {
        event_label: variables.error_message,
        event_category: 'error',
        paymentMethod: variables.payment_method,
        paymentProvider: variables.payment_method,
        context: variables.context,
        send_to: 'riqra',
      })
    }
  },
  closeSuggestedProducts: (variables: AnalyticsCollectorCloseSuggestedProductsVariables) => {
    _gtag('event', AnalyticsEvents.CLOSE_SUGGESTED_PRODUCTS, {
      products_were_added: variables.products_were_added,
    })

    if (riqraAnalyticsId) {
      _gtag('event', AnalyticsEvents.CLOSE_SUGGESTED_PRODUCTS, {
        products_were_added: variables.products_were_added,
      })
    }
  },
  contact: noop,
  socialMedia: noop,
  viewPromotion: noop,
  selectPromotion: noop,
}
