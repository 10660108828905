import React from 'react'
import styled from 'styled-components'
import { useFlag } from 'toggled'

import Price from '~/components/price'
import useVendor from '~/hooks/use-vendor'
import Label from '~/truck/label'
import theme from '~/truck/theme'
import { flags } from '~/utils/constants'

const Container = styled.div`
  align-items: center;
  background: ${theme.colors.grayLight};
  display: flex;
  height: ${theme.spacing.cozy * 6}px;
  justify-content: space-between;
  padding: 0 ${theme.spacing.comfortable}px;

  > div {
    display: flex;
    flex-direction: row;
  }
`

const Header = props => {
  const { numOfProducts } = props

  const { minimumOrder } = useVendor()

  const hidePricing = useFlag(flags.HIDE_PRICES)

  return (
    <Container>
      <Label as="span" $textStyle="h5Semibold">
        {`${numOfProducts} productos`}
      </Label>
      {!hidePricing && (
        <div>
          <Label as="span">mínimo &nbsp;</Label>
          <Price value={minimumOrder} color="black" textStyle="h5Regular" />
        </div>
      )}
    </Container>
  )
}

export default Header
