import * as React from 'react'
import clsx from 'clsx'

import useVendor from '~/hooks/use-vendor'
import Icon from '~/truck/icon'

interface TreeTriggerProps {
  className?: string
}

function Trigger({ className }: TreeTriggerProps) {
  const vendor = useVendor()

  let label = 'Todas las categorías'

  if (vendor.settings.categoryAsBrand) {
    label = 'Todas las marcas'
  }

  return (
    <div className={clsx('flex h-full cursor-pointer items-center gap-2 px-4', className)}>
      <span className="text-sm">{label}</span>
      <Icon color="black" type="caret-down" interactive={false} size="sm" />
    </div>
  )
}

export default Trigger
