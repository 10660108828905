import React from 'react'
import Link from 'next/link'

import Icon from '~/truck/icon'
import RadioGroup from '~/truck/radio-group'

import Action from '../action'

import { OneLineTitle, Property, ShopHoursPopover, TwoLinesDescription } from './components/'
import { StyledButton, StyledContainer, StyledPropertiesRow, StyledRow, StyledTitleDescription } from './elements'

const Store = props => {
  const {
    selectable,
    address,
    id,
    name,
    phone,
    shopHours,
    makesDelivery,
    onClick,
    changeable,
    onChange,
    style,
    className,
  } = props

  const Content = (
    <StyledContainer onClick={onClick} style={style} className={className} selectable={selectable}>
      <StyledTitleDescription>
        <OneLineTitle>{name}</OneLineTitle>
        <TwoLinesDescription>{address?.line ?? ''}</TwoLinesDescription>
      </StyledTitleDescription>
      <StyledRow>
        <ShopHoursPopover shopHours={shopHours} />
        {phone && phone !== '0' ? (
          <Link passHref href={`tel:${phone}`}>
            <a>
              <StyledButton>
                <Icon type="telephone" />
                <span>{phone}</span>
              </StyledButton>
            </a>
          </Link>
        ) : null}
      </StyledRow>
      {makesDelivery && (
        <StyledPropertiesRow selectable={selectable}>
          <Property label="Envío a domicilio" iconType="truck" />
        </StyledPropertiesRow>
      )}
      {changeable && (
        <Action $color="info" onClick={onChange}>
          Cambiar
        </Action>
      )}
    </StyledContainer>
  )
  if (selectable)
    return (
      <RadioGroup.Radio id={id} value={id}>
        {Content}
      </RadioGroup.Radio>
    )

  return Content
}

export default Store
