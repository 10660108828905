import gql from 'graphql-tag'

const setPaymentVoucher = gql`
  mutation setPaymentVoucher($paymentVoucherUrl: String!, $orderId: ID!) {
    setPaymentVoucher(input: { paymentVoucherUrl: $paymentVoucherUrl, orderId: $orderId }) {
      order {
        id
        paymentVoucherUrl
      }
    }
  }
`

export default setPaymentVoucher
