import React from 'react'

import { Container, StyledInput } from './elements'

type InputProps = React.ComponentPropsWithoutRef<'input'> & {
  leftItem?: React.ReactNode
  rightItem?: React.ReactNode
  error?: boolean
  as?: React.ElementType
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  // eslint-disable-next-line react/prop-types
  ({ leftItem, rightItem, disabled, error, style, className, ...inputProps }, ref) => {
    // return <Input {...props} forwardedRef={ref} />
    if (!leftItem && !rightItem) {
      return (
        <StyledInput ref={ref} disabled={disabled} $error={error} style={style} className={className} {...inputProps} />
      )
    }

    return (
      <Container
        $hasLeftItem={!!leftItem}
        $hasRightItem={!!rightItem}
        $disabled={disabled}
        $error={error}
        style={style}
        className={className}
      >
        {leftItem}
        <StyledInput ref={ref} disabled={disabled} $error={error} {...inputProps} />
        {rightItem}
      </Container>
    )
  },
)

export default Input
