import React from 'react'

import DateTimeSelector from '~/components/date-time-selector'
import { useCanSelectTimeRange, useFormatters, useIsMobile, useVendor } from '~/hooks'
import theme from '~/truck/theme'
import removeFirstDeliveryDateIfNotValid from '~/utils/remove-first-delivery-date'

const AddressesDateTimeSelector = props => {
  const { shippingType, dateTimeConfig } = props

  const canSelectTimeRange = useCanSelectTimeRange()

  const isMobile = useIsMobile()

  const formatters = useFormatters()

  const { date, basicDate } = formatters

  const dateFormatterInModal = isMobile ? basicDate : date

  const vendor = useVendor()

  const calendarDates = removeFirstDeliveryDateIfNotValid({
    vendor,
    deliveryDates: dateTimeConfig.deliveryDates,
    deliveryTimeRanges: dateTimeConfig.deliveryTimeRanges,
  })

  return (
    <DateTimeSelector
      style={{ paddingTop: theme.spacing.relaxed }}
      shippingType={shippingType}
      calendarDates={calendarDates}
      timeRanges={dateTimeConfig.deliveryTimeRanges}
      dateFormatter={canSelectTimeRange(shippingType) ? dateFormatterInModal : null}
    />
  )
}

export default AddressesDateTimeSelector
