import React from 'react'
import styled from 'styled-components'

import theme from '~/truck/theme'

import type { DayHoursProps } from './types'

const DayItem = styled.td`
  font-size: ${theme.spacing.compact * 3}px;
  text-align: left;
  white-space: nowrap;
`

const DayHoursRange = styled.tr<{ isClosed: boolean }>`
  color: ${props => (props.isClosed ? theme.colors.error : theme.palette.grayscale70)};
`

const DAYS = { 1: 'Lunes', 2: 'Martes', 3: 'Miércoles', 4: 'Jueves', 5: 'Viernes', 6: 'Sábado', 7: 'Domingo' }

export function DayHours(props: DayHoursProps) {
  const { openTime, closeTime, dayOfWeek } = props.day
  const isClosed = openTime === '' && closeTime === ''

  return (
    <DayHoursRange isClosed={isClosed}>
      <DayItem>{`${DAYS[dayOfWeek]}:`}</DayItem>
      {isClosed ? (
        <DayItem>{'Cerrado'}</DayItem>
      ) : (
        <DayItem>{`${openTime.slice(0, 5)} - ${closeTime.slice(0, 5)}`}</DayItem>
      )}
    </DayHoursRange>
  )
}
