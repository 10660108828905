import gql from 'graphql-tag'

const productAttributes = gql`
  fragment productAttributes on ProductAttribute {
    name
    value
  }
`

export default productAttributes
