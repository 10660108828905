import * as React from 'react'
import moment from 'moment-timezone'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import Modal from '~/components/modal'
import useModalManager from '~/hooks/use-modal-manager'
import Button from '~/truck/button'
import Icon from '~/truck/icon'
import theme from '~/truck/theme'

import CreateExport from './components/create-export'
import Notice from './notice'
import { ExportSteps, IExportState } from './types'

export const StyledModal = styled(Modal)`
  border-radius: ${theme.spacing.compact}px;
  height: fit-content;
  left: 50%;
  max-width: ${theme.spacing.cozy * 41}px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;

  ${up('lg')} {
    max-width: ${theme.spacing.cozy * 68}px;
  }
`

export const ModalHeader = styled.div`
  display: flex;
  padding: ${theme.spacing.relaxed}px;

  > svg {
    position: absolute;
    right: ${theme.spacing.relaxed}px;
    top: 28px;
  }
`

export const ModalActions = styled.div`
  border-top: 1px solid ${theme.colors.gray};
  display: flex;
  justify-content: flex-end;
  padding: ${theme.spacing.comfortable}px;

  > button {
    width: fit-content;
  }

  ${up('lg')} {
    padding: ${theme.spacing.comfortable}px ${theme.spacing.relaxed}px;
  }
`

export const StyledButton = styled(Button.Plain)`
  border: 1px solid ${theme.colors.gray};
  border-radius: ${theme.spacing.compact}px;
  color: ${theme.colors.black};
  margin-right: ${theme.spacing.relaxed}px;
`

const initialState: IExportState = {
  createdId: null,
  button: {
    isLoading: false,
    disabled: false,
    wording: 'Exportar',
  },
  step: ExportSteps.START_EXPORT,
  params: {},
}

function reducer(state, action): IExportState {
  switch (action.type) {
    case 'create-export':
      return { ...state, button: { isLoading: true } }

    default:
      throw new Error('Unhandled action')
  }
}

const getChildren = (state: IExportState, dispatch, closeModal) => {
  switch (state.step) {
    case ExportSteps.START_EXPORT:
      return <CreateExport state={state} dispatch={dispatch} closeModal={closeModal} />

    default:
      return new Error('Unhandled step')
  }
}

const ExportingOrdersModal = props => {
  const { from, to, orderStatusId, ordersCount } = props

  const { closeModal } = useModalManager()

  const numberOfMonths = moment(to).diff(moment(from), 'month', true)

  const isInRange = numberOfMonths <= 3

  const orderStatusIds = orderStatusId ? [orderStatusId] : null

  const getInitialState = (): IExportState => {
    const button = {
      ...initialState.button,
      disabled: !isInRange,
    }

    return {
      ...initialState,
      button,
      params: { orderStatusIds, from, to },
    }
  }

  const [state, dispatch] = React.useReducer(reducer, initialState, getInitialState)

  return (
    <StyledModal>
      <ModalHeader>
        <h4 className="text-xl font-semibold">Exportar pedidos</h4>
        <Icon onClick={() => closeModal()} type="close" color="black" />
      </ModalHeader>
      <Notice ordersCount={ordersCount} from={from} to={to} isInRange={isInRange} />
      <ModalActions>
        <StyledButton onClick={() => closeModal()}>Cancelar</StyledButton>
        {getChildren(state, dispatch, closeModal)}
      </ModalActions>
    </StyledModal>
  )
}

export default ExportingOrdersModal
