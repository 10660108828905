import * as React from 'react'
import cookie from 'isomorphic-cookie'
import { omit } from 'lodash'
import dynamic from 'next/dynamic'
import NextError from 'next/error'
import Head from 'next/head'

import { ErrorComponent, Meta } from '~/components'
import { clearCartMutation } from '~/gql/mutations'
import ErrorScreen from '~/screens/error'
import theme from '~/truck/theme'
import { getMetaInfo, notifySentry, redirectTo } from '~/utils'
import { cookieNames, httpStatusCodes } from '~/utils/constants'
import type { GetMetaInfoResult } from '~/utils/get-meta-info'

interface ErrorPageProps {
  metaInfo?: GetMetaInfoResult
  statusCode: number
  err?: Error
  errorName?: string
  hasGetInitialPropsRun?: boolean
}

const ServerErrorScreen = dynamic(() => import('../src/screens/server-error'), {
  loading: () => null,
})

function ErrorPage(props: ErrorPageProps) {
  const { metaInfo, statusCode, err, errorName, hasGetInitialPropsRun } = props

  if (errorName === 'VendorNotAvailableError') {
    return (
      <>
        <Head>
          <title>Tienda no disponible</title>
          <meta name="robots" content="noindex, nofollow" />
        </Head>
        <ServerErrorScreen />
      </>
    )
  }

  if (!hasGetInitialPropsRun && err) {
    notifySentry(err)
  }

  const isNotFound = statusCode === httpStatusCodes.NOT_FOUND

  const title = isNotFound ? 'Página No Encontrada' : 'Error Inesperado'

  return (
    <>
      <Meta {...metaInfo} title={title} noRobots />
      {isNotFound ? (
        <ErrorScreen statusCode={statusCode} />
      ) : (
        <ErrorComponent
          statusCode={statusCode}
          style={{
            height: '100vh',
            justifyContent: 'center',
            padding: theme.spacing.comfortable,
          }}
        />
      )}
    </>
  )
}

ErrorPage.getInitialProps = async (context: PageContext): Promise<ErrorPageProps | void> => {
  const { err, asPath } = context

  let { statusCode } = await NextError.getInitialProps(context)

  const vendorIsNotAvailable = err?.name === 'VendorNotAvailableError'

  const distributorIsNotAvailable = err?.name === 'DistributorNotAvailableError'

  if (distributorIsNotAvailable) {
    cookie.remove(cookieNames.PARTNER_VENDOR_ENDPOINT, {}, context.res)

    cookie.remove(cookieNames.MULTI_DISTRIBUTOR_SESSION, {}, context.res)

    const getHeaders = oldHeaders => {
      return omit(oldHeaders, cookieNames.PARTNER_VENDOR_ENDPOINT)
    }

    await context.apolloClient.mutate({
      mutation: clearCartMutation,
      context: {
        getHeaders,
      },
    })

    redirectTo('/', context)
  }

  if (vendorIsNotAvailable || distributorIsNotAvailable) {
    statusCode = httpStatusCodes.NOT_FOUND
  }

  if (context.res) {
    context.res.statusCode = statusCode
  }

  if (statusCode !== httpStatusCodes.NOT_FOUND) {
    const error = err || new Error(`_error.js getInitialProps missing data at path: ${asPath}`)

    await notifySentry(error, context)
  }

  return {
    statusCode,
    errorName: err?.name,
    hasGetInitialPropsRun: true,
    metaInfo: getMetaInfo(context),
  }
}

export default ErrorPage
