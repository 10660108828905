import { QueryClient, QueryFunctionContext } from 'react-query'
import { merge } from 'lodash'
import { v4 as uuid4 } from 'uuid'

import type { HTTPError } from '~/errors'
import { parseCookies } from '~/lib/cookies'
import { superfetch, SuperfetchOptions, SuperFetchQuery } from '~/lib/superfetch'
import { getHostname, logout } from '~/utils'
import { channel, cookieNames, httpStatusCodes } from '~/utils/constants'

export function getHeaders(ctx?: PageContext) {
  const cookies = parseCookies(ctx)

  const session = (cookies[cookieNames.SESSION] || ctx?.req?.session) as Session

  const hostname = getHostname({ ctx, cookies })

  const partnerVendorEndpoint = cookies[cookieNames.PARTNER_VENDOR_ENDPOINT] as string

  const headers = {
    'X-Request-Id': uuid4(),
    channel,
    endpoint: hostname,
  } as Record<string, string>

  if (partnerVendorEndpoint) {
    headers['partner-vendor-endpoint'] = partnerVendorEndpoint
  }

  if (session?.token) {
    headers.authorization = `Bearer ${session.token}`
  }

  return headers
}

export function createQueryFn(ctx?: PageContext) {
  type QueryKey = [string] | [string, SuperFetchQuery]

  return async function queryFn(queryCtx: QueryFunctionContext<QueryKey>) {
    const {
      queryKey: [endpoint, query],
    } = queryCtx

    return await superfetch({
      endpoint,
      query,
      baseURL: process.env.API,
      headers: getHeaders(ctx),
    })
  }
}

export function createMutationFn(ctx?: PageContext) {
  if (typeof window === 'undefined') {
    return undefined
  }

  return function mutationFn(options: SuperfetchOptions) {
    const defaultOptions = {
      baseURL: process.env.API,
      headers: getHeaders(ctx),
    }

    return superfetch(merge(defaultOptions, options))
  }
}

function shouldRetry(failureCount: number, error: HTTPError, ctx?: PageContext) {
  const isQuotaEnabled = failureCount < 2

  if (error.status === httpStatusCodes.UNAUTHORIZED) {
    logout(ctx)
  }

  if (error.status) {
    return isQuotaEnabled && error.status >= 500
  }

  return isQuotaEnabled
}

export function createQueryClient(ctx?: PageContext) {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        queryFn: createQueryFn(ctx),
        retry: (failureCount: number, error: HTTPError) => shouldRetry(failureCount, error, ctx),
      },
      mutations: {
        mutationFn: createMutationFn(ctx),
      },
    },
  })
}
