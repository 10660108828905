import gql from 'graphql-tag'

import { categoryFragment } from '../fragments'

const categoriesQuery = gql`
  query categories($terms: [String!], $encodedId: String) {
    vendor {
      id
      categories(terms: $terms, encodedId: $encodedId) {
        ...category
        subcategories: subCategories {
          ...category
        }
      }
    }
  }
  ${categoryFragment}
`

export default categoriesQuery
