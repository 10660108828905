import gql from 'graphql-tag'

import { priceLineFragment } from '../fragments'

const priceLinesQuery = gql`
  query priceLines {
    user {
      id
      cart {
        id
        priceLines {
          ...priceLine
        }
        total
      }
    }
  }
  ${priceLineFragment}
`

export default priceLinesQuery
