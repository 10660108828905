import { useMemo } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { sumBy } from 'lodash'

import { cartTotalQuantityQuery } from '~/gql/queries'

interface ILineItem {
  id: number
  originalQuantity: number
}

const useCartTotalQuantity = () => {
  const { data } = useQuery(cartTotalQuantityQuery, { fetchPolicy: 'cache-and-network' })

  const { cart } = useMemo(() => data?.user ?? {}, [data?.user])

  const lineItems = useMemo<ILineItem[]>(() => {
    return cart?.lineItems || []
  }, [cart])

  return sumBy(lineItems, 'originalQuantity')
}

export default useCartTotalQuantity
