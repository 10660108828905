import * as React from 'react'
import Link from 'next/link'

import { DropdownMenu, DropdownMenuItem } from '~/components/dropdown'
import { logout } from '~/utils'

function UserDropdown() {
  return (
    <DropdownMenu className="absolute -right-2 top-full mt-5">
      <Link href="/mi-cuenta" passHref>
        <DropdownMenuItem iconType="profile" label="Mi cuenta" />
      </Link>
      <DropdownMenuItem iconType="logout" label="Cerrar sesión" onClick={() => logout()} />
    </DropdownMenu>
  )
}

export default UserDropdown
