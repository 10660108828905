import React from 'react'
import { compact, map } from 'lodash'
import styled from 'styled-components'

import Button from '~/truck/button'
import Label from '~/truck/label'
import RadioGroup from '~/truck/radio-group'
import theme from '~/truck/theme'

export const Container = styled.div`
  background-color: ${theme.colors.white};
  display: flex
  flex-direction: column;
  padding: ${theme.spacing.comfortable}px;
  position: relative;

  > *:not(:last-child) {
    margin-bottom: ${theme.spacing.compact}px;
  }
`

const StyledButton = styled(Button.Plain)`
  position: absolute;
  right: ${theme.spacing.comfortable}px;
  top: 50%;
  transform: translateY(-50%);
  width: fit-content;
`

interface EntityLinesProps {
  id: string
  lines: string[]
  style?: React.CSSProperties
  className?: string
  selectable?: boolean
  removable?: boolean
  onRemove?: (id: string) => void
}

const EntityLines = ({ id, lines, style, className, selectable, removable, onRemove }: EntityLinesProps) => {
  const renderLine = (line: string, index: number) => {
    const key = `${id}-line-${index}`

    return (
      <Label as="p" key={key} $textStyle={index && 'h6Regular'}>
        {line}
      </Label>
    )
  }

  const compactedLines = compact(lines)

  const content = (
    <Container>
      {map(compactedLines, renderLine)}
      {removable && (
        <StyledButton color="error" onClick={() => onRemove && onRemove(id)}>
          eliminar
        </StyledButton>
      )}
    </Container>
  )

  if (selectable) {
    return (
      <RadioGroup.Radio id={id} value={id} className={className} style={style}>
        {content}
      </RadioGroup.Radio>
    )
  }

  return content
}

export default EntityLines
