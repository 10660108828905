import * as React from 'react'
import NumberFormat, { NumberFormatProps } from 'react-number-format'

import { useCurrencyConfig } from '~/hooks'
import Input from '~/truck/input'

export interface PriceInputProps extends Omit<NumberFormatProps, 'onChange' | 'onChangeValue'> {
  forwardedRef?: React.Ref<HTMLInputElement>
  showPrefix?: boolean
  onChange?: (value: number) => void
}

export function PriceInput(props: PriceInputProps) {
  const { showPrefix, forwardedRef, onChange, name, ...restProps } = props

  const currencyConfig = useCurrencyConfig()

  return (
    <Input
      as={NumberFormat}
      getInputRef={forwardedRef}
      isNumericString
      allowNegative={false}
      allowLeadingZeros={false}
      thousandSeparator={currencyConfig.separator}
      decimalSeparator={currencyConfig.precision === 0 ? null : currencyConfig.decimal}
      decimalScale={currencyConfig.precision ?? 2}
      prefix={showPrefix ? currencyConfig.symbol : ''}
      onValueChange={values => {
        if (onChange) {
          onChange(values.floatValue)
        }
      }}
      {...restProps}
    />
  )
}
