import * as React from 'react'
import styled from 'styled-components'

import Label from '~/truck/label'

import { DialogFooter } from './footer'
import { DialogHeader, DialogHeaderProps, DialogTitle } from './header'
import { DialogModal, DialogModalProps } from './modal'

export interface DialogProps extends Omit<DialogHeaderProps, 'onClose'>, Omit<DialogModalProps, 'children'> {
  header: string | React.ReactElement<DialogHeaderProps>
  content: string | React.ReactElement
  accept: React.ReactElement
  cancel?: React.ReactElement
  className?: string
  style?: React.CSSProperties
}

const StyledDialogContainer = styled.div`
  background: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.dimensions.borderRadius}px;
`

const DialogContent = styled.div`
  padding: ${props => props.theme.spacing.comfortable}px;
`

export function Dialog(props: DialogProps) {
  const { header, content, maxWidth, accept, cancel, closable, className, style, onClose } = props

  return (
    <DialogModal onClose={onClose} maxWidth={maxWidth}>
      <StyledDialogContainer className={className} style={style}>
        {typeof header === 'string' ? (
          <DialogHeader closable={closable} title={header} onClose={onClose} />
        ) : React.isValidElement(header) ? (
          React.cloneElement(header, { onClose })
        ) : (
          header
        )}
        {typeof content === 'string' ? (
          <DialogContent>
            <Label as="p" $color="grayDark">
              {content}
            </Label>
          </DialogContent>
        ) : (
          content
        )}
        <DialogFooter>
          {cancel}
          {accept}
        </DialogFooter>
      </StyledDialogContainer>
    </DialogModal>
  )
}

Dialog.Modal = DialogModal
Dialog.Header = DialogHeader
Dialog.Title = DialogTitle
Dialog.Footer = DialogFooter
Dialog.Content = DialogContent
