import { useEffect, useState } from 'react'

export const useSessionStorage = (key: string): [string, (newValue: string) => void] => {
  const [value, setValue] = useState<string>()

  useEffect(() => {
    setValue(sessionStorage.getItem(key))
  }, [key])

  const handleSet = (newValue: string) => {
    sessionStorage.setItem(key, newValue)

    setValue(newValue)
  }

  return [value, handleSet]
}
