import { down } from 'styled-breakpoints'
import styled from 'styled-components'

import Image from '~/truck/image'
import Label from '~/truck/label'
import theme from '~/truck/theme'

export const Container = styled.div`
  background-color: ${theme.colors.grayLight};
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing.compact * 5}px;
  padding: ${theme.spacing.compact * 6}px;
`

export const FormContainer = styled.div`
  border-top: 1px solid ${theme.colors.gray};
  display: flex;
  flex-direction: column;
  padding-top: ${theme.spacing.cozy * 3}px;
  width: 100%;

  > *:not(:last-child) {
    margin-bottom: ${theme.spacing.comfortable}px;
  }
`

export const TwoColumns = styled.div`
  display: flex;

  > * {
    flex: 1;
  }

  > *:first-child {
    margin-right: ${theme.spacing.comfortable}px;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.spacing.cozy * 3}px;

  > :last-child {
    margin-top: ${theme.spacing.cozy * 3}px;
  }
`

export const DebitCardImagesContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${theme.spacing.cozy * 3}px;
  margin-top: ${theme.spacing.cozy * 2}px;
`

export const StyledImage = styled(Image)`
  max-height: ${theme.spacing.cozy * 3.5}px;
  object-fit: none;

  ${down('sm')} {
    flex: 1 0 25%;
  }
`

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing.compact * 5}px;

  > img {
    height: ${theme.spacing.cozy * 3}px;
  }
`

export const ImageContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  height: ${theme.spacing.cozy * 4}px;

  > img {
    height: 100%;
  }
`

export const StyledLabel = styled(Label)`
  font-weight: 600;
`
