import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import theme from '~/truck/theme'

export const Container = styled.div`
  display: flex;
  flex-grow: 1;

  > div:first-child {
    ${up('lg')} {
      margin-right: ${theme.spacing.comfortable}px;
    }
  }
`
