import gql from 'graphql-tag'

import { documentFragment } from '../fragments'

const setDefaultDocumentMutation = gql`
  mutation setDefaultDocument($documentId: ID!) {
    setDefaultDocument(input: { documentId: $documentId }) {
      document {
        ...document
      }
    }
  }
  ${documentFragment}
`

export default setDefaultDocumentMutation
