import detectMobile from 'is-mobile'
import cookie from 'isomorphic-cookie'
import { assign, uniqBy } from 'lodash'

import { hasFlag } from '~/utils'
import { cookieNames, flags } from '~/utils/constants'

import ensureAuthRouting from './ensure-auth-routing'
import ensureMultidistributorRouting from './ensure-multidistributor-routing'
import ensureSession from './ensure-session'
import fetchBuyingInStore from './fetch-buying-in-store'
import fetchConfig from './fetch-config'
import fetchPartnerVendors from './fetch-partner-vendors'

export async function getAppProps(ctx) {
  const session = await ensureSession(ctx)

  const isMobile = detectMobile({ ua: ctx.req, tablet: true })

  const appConfig = await fetchConfig(ctx.apolloClient, ctx)

  if (ctx.res?.finished) {
    return
  }

  const { vendor, user, manufacturer } = appConfig

  let features = uniqBy(vendor.features.concat(manufacturer.features), 'slug')

  features = features.map(feat => [feat.slug, feat.settings])

  assign(vendor, {
    manufacturer: {
      ...manufacturer,
      features: [],
    },
    features,
  })

  assign(ctx, {
    isMobile,
    vendor,
    user,
    hasFlag: slug => hasFlag(features, slug),
  })

  // Oauth integration requests does not have to pass via auth checking
  if (!ctx?.req?.integration) {
    ensureAuthRouting(session, ctx)
  }

  const hasPartners = ctx.hasFlag(flags.MULTI_VENDOR_NAVIGATION)

  if (hasPartners && user.isLoggedIn) {
    const distributors = await fetchPartnerVendors(ctx.apolloClient)

    vendor.manufacturer.hasOnePartner = distributors.length === 1
  }

  const isMultidistributor = ctx.hasFlag(flags.MULTI_DISTRIBUTOR_D2C)

  const isPublicPartnerVendor = ctx.hasFlag(flags.PUBLIC_PARTNER_VENDOR)

  const requireShipping = ctx.hasFlag(flags.SHIPPING_BEFORE_SEE_PRODUCTS)

  if (!isPublicPartnerVendor && isMultidistributor && requireShipping) {
    ensureMultidistributorRouting(ctx)
  }

  if (ctx.res?.finished) {
    return
  }

  const appProps = {
    isMobile,
    vendor,
    user,
  }

  const hasStoreNavigation = ctx.hasFlag(flags.STORE_NAVIGATION)

  const multiDistributorSession = cookie.load(cookieNames.MULTI_DISTRIBUTOR_SESSION, ctx.req)

  const expressDeliverySession = cookie.load(cookieNames.EXPRESS_DELIVERY_SESSION, ctx.req)

  const sessionStoreId = multiDistributorSession?.store?.id || expressDeliverySession?.store?.id

  if (hasStoreNavigation || sessionStoreId) {
    appProps.buyingInStore = await fetchBuyingInStore(ctx.apolloClient)
  }

  if (multiDistributorSession) {
    appProps.multiDistributorSession = multiDistributorSession
  }

  if (expressDeliverySession) {
    appProps.expressDeliverySession = expressDeliverySession
  }

  return appProps
}
