import gql from 'graphql-tag'

const removeUserFavoriteProduct = gql`
  mutation removeUserFavoriteProduct($productId: ID!) {
    removeUserFavoriteProduct(input: { productId: $productId }) {
      success
    }
  }
`

export default removeUserFavoriteProduct
