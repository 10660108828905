import type { ApolloError } from 'apollo-client'
import type { GraphQLError } from 'graphql'
import { first, mapValues } from 'lodash'

import notifier from '~/truck/notifier'

interface GraphQLCustomError<T> extends GraphQLError {
  state?: Record<keyof T, unknown>
}

interface ApolloClientError<T> extends ApolloError {
  graphQLErrors: ReadonlyArray<GraphQLCustomError<T>>
}

/**
 * Get an object of labeled error messages from the
 * state of the first GraphQLError
 *
 * @example
 *
 * const error = {
 *  graphQLErrors: [{
 *    state: {
 *      username: ['not valid'],
 *      couponCode: ['expired']
 *    }
 *  }]
 * }
 *
 * handleSubmissionErrors(error)
 * // { username: 'not valid', couponCode: 'expired' }
 */
const handleSubmissionErrors = <T = Record<string, unknown>>(error: ApolloClientError<T>) => {
  if (!error) return

  const errors = error?.graphQLErrors?.[0]

  if (errors && !errors.state) {
    notifier.error(errors.message)

    return
  }

  return mapValues(error.graphQLErrors?.[0]?.state, first)
}

export default handleSubmissionErrors
