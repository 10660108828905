import { concat, groupBy, reduce } from 'lodash'

import type { ILineItem } from '~/types/line-items'
import type { IProduct } from '~/types/products'

export function filterProductsByLineItems(products: IProduct[], lineItems: ILineItem[]): IProduct[] {
  const items = groupBy(lineItems, 'product.id')

  return reduce(
    products,
    (acc, product) => {
      if (items[product.id]) {
        return acc
      }

      product.quantity = 0

      return concat(acc, product)
    },
    [],
  )
}
