import styled from 'styled-components'

import Anchor from '~/truck/anchor'

export const Container = styled.div`
  background-color: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.comfortable}px;
  padding: ${props => props.theme.spacing.comfortable}px;

  :not(:last-of-type) {
    border-bottom: 1px solid ${props => props.theme.colors.gray};
  }
`

export const FirstRow = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
`

export const RightSection = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing.cozy}px;
`

export const StyledAnchor = styled(Anchor)`
  align-items: center;
  display: flex;
  width: fit-content;

  > svg {
    height: ${props => props.theme.spacing.comfortable}px;
    margin-left: ${props => props.theme.spacing.cozy}px;
    width: ${props => props.theme.spacing.comfortable}px;
  }
`

export const SecondRow = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
`
