import cookie from 'cookie'
import { each } from 'lodash'

import { cookieNames } from '../constants'
import getEndpoint from '../get-endpoint'

// TODO: report apollo errors when we know a better way to
// omit some validation errors reported by the API. e.g Login
// Some Inspiration: https://github.com/keepfy/keepfy-error-extractor

function getContextExtras(context: PageContext = {} as PageContext) {
  const { req } = context

  const extras = {
    cookies: {},
  }

  const cookies = typeof document !== 'undefined' ? cookie.parse(document.cookie) : req.cookies

  each(cookieNames, cookieName => {
    const cookieValue = cookies[cookieName]

    if (cookieValue) {
      extras.cookies[cookieName] = decodeURIComponent(cookieValue)
    }
  })

  const session = extras.cookies[cookieNames.SESSION]

  if (session && session.role === 'visitor') {
    delete extras.cookies[cookieNames.SESSION]
  }

  return extras
}

function getContextTags(context: PageContext = {} as PageContext) {
  const { req, res } = context

  const endpoint = getEndpoint(req, res)

  const tags: Record<string, unknown> = {
    endpoint,
    ssr: Boolean(req),
  }

  if (req) {
    tags.url = `${req.protocol}://${endpoint}${req.originalUrl}`
  }

  return tags
}

let Sentry

async function notifySentry(error: Error, context?: PageContext) {
  if (!Sentry) {
    if (typeof window !== 'undefined') {
      Sentry = await import(/* webpackChunkName: "sentry" */ './connector').then(mod => mod.default)
    } else {
      // https://arunoda.me/blog/ssr-and-server-only-modules
      // eslint-disable-next-line
      Sentry = eval("require('@sentry/node')")
    }
  }

  const sentryContext = {
    tags: getContextTags(context),
    extra: getContextExtras(context),
  }

  Sentry.captureException(error, sentryContext)
}

export default notifySentry
