export enum BulkOrderingResultErrorType {
  PRODUCT_NOT_AVAILABLE = 'product-not-available',
  PRODUCT_WITHOUT_STOCK = 'product-without-stock',
  PRODUCT_VALIDATED_WITH_MINIMUM_SALES_QUANTITY_RULE = 'product-validated-with-minimum-sales-quantity-rule',
}

export interface BulkOrderingResultError {
  type: BulkOrderingResultErrorType
  data: {
    sku: string
    proposalQuantity: number
  }
}

export interface BulkOrderingResultOperation {
  type: 'product-upserted'
  data: {
    sku: string
    appliedQuantity: number
    proposalQuantity: number
    appliedMaximumSalesQuantityRule: boolean
    appliedMaximumStockQuantityRule: boolean
  }
}
