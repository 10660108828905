import * as React from 'react'
import Link from 'next/link'

import cloudinary from '~/utils/cloudinary'
import { transformations } from '~/utils/constants'

import { StyledImage } from './elements'

const logoTransformations = {
  small: transformations.TINY_LOGO,
  large: transformations.LOGO,
}

const Logo = props => {
  const { size, vendor, alternative, style, className, onClick } = props

  const getLogo = () => {
    if (!alternative) {
      return vendor.logo
    }

    return vendor.alternativeLogo
  }

  const logo = getLogo()

  const transformation = logoTransformations[size]

  return (
    <Link href="/">
      <a style={{ flexShrink: 0, ...style }} className={className}>
        <StyledImage
          flavor="img"
          onClick={onClick}
          $size={size}
          alt={vendor.name}
          src={cloudinary.applyTransformations(logo, transformation)}
        />
      </a>
    </Link>
  )
}

export default Logo
