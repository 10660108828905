import React, { useMemo } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { cloneDeep, findLast, fromPairs, map, pick, reverse } from 'lodash'
import { flags } from 'src/utils/constants'
import { useFlag } from 'toggled'

import EditModal from '~/components/edit-modal'
import { upsertAddressMutation } from '~/gql/mutations'
import useCoverage from '~/hooks/use-coverage'
import { divisionLevelsFields, getDivisionLevelsFromLocationSelector } from '~/hooks/use-location-selector'
import useModalManager from '~/hooks/use-modal-manager'
import notifier from '~/truck/notifier'
import { getValidator, handleSubmissionErrors } from '~/utils'
import constraints from '~/utils/constraints'

import UpsertAddressForm from './upsert-addresses-form'

const getModalDetails = address => {
  if (!address) {
    return {
      modalTitle: 'Agregar dirección',
      message: 'Haz agregado una dirección',
    }
  }

  return {
    modalTitle: 'Editar dirección',
    message: 'Haz actualizado una dirección',
  }
}

const getFormValidator = hasMapInCreation => {
  const fieldsToValidate = ['contactPerson', 'contactPhone', 'localityId']

  if (hasMapInCreation) {
    fieldsToValidate.push('googleAddressLine')
  } else {
    fieldsToValidate.push('addressLine')
  }

  return getValidator(pick(constraints, fieldsToValidate))
}

const getInitialValues = (address = {}) => {
  const { id, location, line, ...rest } = address

  const initialLocations = fromPairs(
    map(reverse(cloneDeep(location)), (_, index) => [divisionLevelsFields[index], _.id]),
  )

  const localityId = findLast(initialLocations)

  return {
    addressLine: line,
    addressId: id,
    googleAddressLine: line,
    localityId,
    ...rest,
    ...initialLocations,
  }
}

const UpsertAddressesModalContent = props => {
  const { coverageTree, allLocalities, address, ...rest } = props

  const [upsertAddress] = useMutation(upsertAddressMutation)

  const { modalTitle, message } = getModalDetails(address)

  const { closeModal } = useModalManager()

  const hasMapInCreation = useFlag(flags.MAP_IN_ADDRESS_CREATION)

  const onSubmit = async values => {
    const fieldsToSubmit = [
      'addressLine',
      'contactPerson',
      'contactPhone',
      'reference',
      'addressId',
      'isDefault',
      'localityId',
    ]

    const addressLine = hasMapInCreation ? values.googleAddressLine : values.addressLine

    const divisionLevels = getDivisionLevelsFromLocationSelector(allLocalities, values)

    const variables = {
      ...pick(values, fieldsToSubmit),
      ...divisionLevels,
      addressLine,
    }

    if (values.coordinates) {
      const coordinatesFields = ['latitude', 'longitude']

      variables.coordinates = pick(values.coordinates, coordinatesFields)
    }

    const options = {
      variables,
      refetchQueries: ['userAddresses'],
    }

    try {
      await upsertAddress(options)

      notifier.success(message)

      closeModal()
    } catch (error) {
      return notifier.error(handleSubmissionErrors(error))
    }
  }

  const initialValues = useMemo(() => getInitialValues(address), [address])

  const onValidate = getFormValidator(hasMapInCreation)

  return (
    <EditModal title={modalTitle} onSubmit={onSubmit} onValidate={onValidate} initialValues={initialValues} {...rest}>
      <UpsertAddressForm coverageTree={coverageTree} address={address} />
    </EditModal>
  )
}

function UpsertAddressesModal(props) {
  const { loading, coverageTree, allLocalities } = useCoverage()

  if (loading) {
    return null
  }

  return <UpsertAddressesModalContent allLocalities={allLocalities} coverageTree={coverageTree} {...props} />
}

export default UpsertAddressesModal
