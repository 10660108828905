import * as React from 'react'
import { Form as FinalForm } from 'react-final-form'
import { Field as FinalFormField } from 'react-final-form'
import { times } from 'lodash'

import { useFormatters, useIsMobile } from '~/hooks'
import Field from '~/truck/field'
import Input from '~/truck/input'
import NewButton from '~/truck/new-button/NewButton'
import { defaultFinalFormDecorators } from '~/utils/constants'

import { BulkDialogContent, BulkDialogFooter } from '../bulk-dialog'
import type { FormValueFieldGroupObject, UpdatedCartItem } from '../dialog-start-process-step-content/types'
import { MAX_NUMBER_OF_LINES, onValidateInputs as onValidate } from '../dialog-start-process-step-content/utils'
import { parseInputsToJSON } from '../dialog-start-process-step-content/utils'

interface BulkOrderingTypeInsertManuallyProps {
  updateCart: (items: UpdatedCartItem[]) => void
}

export function BulkOrderingTypeInsertManually({ updateCart }: BulkOrderingTypeInsertManuallyProps) {
  const [rows, setRows] = React.useState(5)

  const formatters = useFormatters()

  const isMobile = useIsMobile()

  const addNewRow = () => {
    setRows(rows + 1)
  }

  const onSubmit = (formValues: FormValueFieldGroupObject) => {
    const { result: inputParsed } = parseInputsToJSON(formValues)

    updateCart(inputParsed)
  }

  return (
    <FinalForm<FormValueFieldGroupObject>
      onSubmit={onSubmit}
      validate={onValidate}
      decorators={defaultFinalFormDecorators}
    >
      {formProps => (
        <form onSubmit={formProps.handleSubmit} autoComplete="off">
          <BulkDialogContent gutterBottom={!isMobile} gutterXAxis={!isMobile}>
            <div className="bg-zinc-50 p-6 pb-2">
              <p className="mb-6 text-secondary">
                Añade productos uno a uno a tu carrito: Ingresa el código (sku) del producto y la cantidad. Puedes
                ingresar un máximo de 50 registros.{' '}
              </p>
              <p className="mb-6 text-secondary">
                Los productos que agregues y se encuentren en tu carrito serán remplazados con los nuevos valores.
              </p>
              <div className="flex pb-2">
                <span className="w-full text-left text-xs font-semibold text-secondary">SKU</span>
                <span className="w-24 text-left text-xs font-semibold text-secondary">Cantidad</span>
              </div>
              <div className="flex max-h-40 flex-col gap-4 overflow-y-auto md:max-h-52 xl:max-h-96">
                {times(rows, i => (
                  <div key={i} className="flex flex-shrink-0 justify-between gap-2">
                    <Field className="w-full" name={`line${i + 1}.sku`} placeholder={'Ej: 123456'} />
                    <FinalFormField name={`line${i + 1}.quantity`} parse={formatters.numeric}>
                      {props => (
                        <Input
                          className="!w-24 text-right"
                          name={props.input.name}
                          value={props.input.value}
                          onChange={props.input.onChange}
                        />
                      )}
                    </FinalFormField>
                  </div>
                ))}
              </div>
              {rows <= MAX_NUMBER_OF_LINES && (
                <NewButton className="w-fit" type="button" mode="transparent" onClick={addNewRow}>
                  Agregar otra fila
                </NewButton>
              )}
              <BulkDialogFooter isMobile={isMobile}>
                <NewButton
                  className={isMobile ? '!w-full' : undefined}
                  type="submit"
                  loading={formProps.submitting}
                  disabled={formProps.invalid}
                >
                  Agregar al carrito
                </NewButton>
              </BulkDialogFooter>
            </div>
          </BulkDialogContent>
        </form>
      )}
    </FinalForm>
  )
}
