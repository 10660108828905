import styled, { ThemedStyledProps } from 'styled-components'

import { getColor, getTextStyle, getTheme } from '~/truck/utils'
import type { ThemeColor } from '~/types/theme'
import type TruckTheme from '~/types/theme'

//Added never to color property to show an error when trying to use color instead of $color
interface LabelProps {
  $color?: ThemeColor
  $textStyle?: string
  $textAlign?: string
  $ellipsis?: boolean
  color?: never
}

const resolveColor = (props: LabelProps) => {
  const theme = getTheme(props as ThemedStyledProps<LabelProps, TruckTheme>)
  const { $color } = props
  const key = $color || 'black'

  return getColor(theme, key)
}

const resolveTextStyle = (props: LabelProps) => {
  const theme = getTheme(props as ThemedStyledProps<LabelProps, TruckTheme>)
  const { $textStyle } = props
  const key = $textStyle || 'h5Regular'

  return getTextStyle(theme.textStyles[key])
}

const resolveTextAlign = (props: LabelProps) => {
  const { $textAlign } = props

  if (!$textAlign) {
    return ''
  }

  return `
    text-align: ${$textAlign};
    width: 100%;
  `
}

const resolveEllipsisStyle = (props: LabelProps) => {
  const { $ellipsis } = props

  if (!$ellipsis) {
    return ''
  }

  return `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `
}

/** @deprecated Use tw's `text-* font-*` classes */
const Label = styled.label<LabelProps>`
  color: ${resolveColor};
  ${resolveTextAlign}
  ${resolveTextStyle}
  ${resolveEllipsisStyle}
`

Label.displayName = 'Label'

export default Label
