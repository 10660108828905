import React from 'react'

import { useCartTotalQuantity } from '~/hooks'
import useCartAnimation from '~/hooks/use-cart-animation'

import { useUiStore } from '../../stores/ui-store'

import { OpenCartButtonView } from './OpenCartButton.view'

export const OpenCartButton = () => {
  const lineItemsTotalQuantity = useCartTotalQuantity()

  const { triggerAnimation, setTriggerAnimation, setAnimationPlaying } = useCartAnimation(lineItemsTotalQuantity)

  const openCartDrawer = useUiStore(state => state.openCartDrawer)

  const handleOnAnimationStart = () => {
    setAnimationPlaying(true)
  }

  const handleOnAnimationEnd = () => {
    setTriggerAnimation(false)
    setAnimationPlaying(false)
  }

  return (
    <OpenCartButtonView
      lineItemsTotalQuantity={lineItemsTotalQuantity}
      onOpenCartDrawer={() => openCartDrawer()}
      triggerAnimation={triggerAnimation}
      onAnimationStart={handleOnAnimationStart}
      onAnimationEnd={handleOnAnimationEnd}
    />
  )
}
