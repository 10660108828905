import * as React from 'react'
import Link from 'next/link'
import styled from 'styled-components'

import Button from '~/truck/button'
import Image from '~/truck/image'
import Label from '~/truck/label'
import theme from '~/truck/theme'
import type { ThemeColor } from '~/types/theme'

export interface EmptyStateProps {
  image: string
  title: string
  subtitle: string
  actionProps?: {
    route: string
    label: string
    onClick?: () => void
    color?: ThemeColor
  }
  className?: string
  style?: React.CSSProperties
}

export const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;

  > img {
    margin-bottom: ${theme.spacing.comfortable}px;
  }

  > button {
    margin-top: ${theme.spacing.comfortable}px;
    min-width: ${theme.spacing.cozy * 25}px;
    padding: 0 ${theme.spacing.comfortable}px;
    width: fit-content;
  }
`

export const Subtitle = styled(Label)`
  margin-top: ${theme.spacing.cozy}px;
  max-width: ${theme.spacing.cozy * 25}px;
`

export default function EmptyState(props: EmptyStateProps) {
  const { image, title, subtitle, actionProps, className, style } = props

  return (
    <StyledContainer className={className} style={style}>
      <Image src={image} height={theme.spacing.cozy * 9} width={theme.spacing.cozy * 9} />
      <Label as="p" $textStyle="h5Semibold" $textAlign="center">
        {title}
      </Label>
      <Subtitle as="p" $textAlign="center">
        {subtitle}
      </Subtitle>
      {actionProps ? (
        <Link href={actionProps.route}>
          <Button onClick={actionProps.onClick} color={actionProps.color ?? 'primary'}>
            {actionProps.label}
          </Button>
        </Link>
      ) : null}
    </StyledContainer>
  )
}
