import React from 'react'

import type { ThemeColor } from '~/types/theme'

import { CenteredContainer, Container } from './elements'

export interface LoaderProps {
  color?: ThemeColor
  style?: React.CSSProperties
  className?: string
  centered?: boolean
}

function Loader({ color, style, className, centered }: LoaderProps) {
  if (centered) {
    return (
      <CenteredContainer className={className} style={style}>
        <Container $color={color}>
          <div />
          <div />
          <div />
        </Container>
      </CenteredContainer>
    )
  }

  return (
    <Container className={className} style={style} $color={color}>
      <div />
      <div />
      <div />
    </Container>
  )
}

export default Loader
