import styled from 'styled-components'

import theme from '~/truck/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  > div:not(:last-of-type) {
    border-bottom: 1px solid ${theme.colors.gray};
  }

  > span {
    align-self: flex-end;
    margin-right: ${theme.spacing.comfortable}px;
    margin-top: ${theme.spacing.comfortable}px;
  }
`

export const RadioGroupContainer = styled.div`
  margin-right: ${theme.spacing.comfortable}px;
  max-height: ${theme.spacing.relaxed * 13}px;
  overflow-y: auto;
`
