import React from 'react'
import styled from 'styled-components'

import theme from '~/truck/theme'
import { baseUrls } from '~/utils/constants'

import { CircularBackground } from './elements'

const FullWidthButton = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.grayLight};
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  height: ${theme.spacing.cozy * 8}px;
  padding: ${theme.spacing.cozy * 2.5}px;
  position: relative;
  width: 100%;

  > svg:last-child {
    margin-left: auto;
  }
`

const NoAddressFound = props => {
  const { onClick, rightIcon } = props

  return (
    <FullWidthButton onClick={onClick}>
      <CircularBackground>
        <img alt="icon" src={`${baseUrls.CLOUDINARY}/v1582674687/sellers/resources/near-me.svg`} />
      </CircularBackground>
      ¿No encuentras tu dirección? Ubícala en el mapa
      {rightIcon}
    </FullWidthButton>
  )
}

export default NoAddressFound
