import styled from 'styled-components'

import theme from '~/truck/theme'

export const Container = styled.div`
  left: 50%;
  position: fixed;
  top: ${theme.spacing.comfortable}px;
  transform: translateX(-50%);
  width: ${theme.spacing.cozy * 41}px;
  z-index: 99;

  > *:not(:first-child) {
    margin-top: ${theme.spacing.comfortable}px;
  }
`
