import cookie from 'isomorphic-cookie'

import { cookieNames } from './constants'
import forceReloadTo from './force-reload-to'

export const saveSession = (response: Record<string, any>) => {
  cookie.save(cookieNames.SESSION, response, { secure: false })
}

const callbackRedirection = (keepCallbackCookie = false) => {
  const callbackUrl = cookie.load(cookieNames.CALLBACK_URL)

  if (!keepCallbackCookie) {
    cookie.remove(cookieNames.CALLBACK_URL)
  }

  forceReloadTo(callbackUrl ?? '/')
}

export default callbackRedirection
