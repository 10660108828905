import cookie from 'isomorphic-cookie'
import { includes } from 'lodash'

import { cookieNames, flags } from '~/utils/constants'
import redirectTo from '~/utils/redirect-to'

const privateRoutes = [
  '/account',
  '/orders',
  '/order',
  '/checkout',
  '/quotations/checkout',
  '/quotations/thanks',
  '/thanks',
  '/partner-vendors',
  '/pay-process',
  '/one-click-order',
  '/favorites',
  '/line-of-credit',
  '/last-order',
  '/most-purchased',
  '/quotations',
]

const authRoutes = ['/login', '/signup']

const allowedRoutes = ['/login', '/change-password', '/reset-password', '/recover-password']

const shouldSelectPartner = context => {
  const hasPartners = context.hasFlag(flags.MULTI_VENDOR_NAVIGATION)

  const isSelectingPartner = context.pathname === '/partner-vendors'

  const hasPartnerEndpoint = cookie.load(cookieNames.PARTNER_VENDOR_ENDPOINT, context.req)

  return hasPartners && !hasPartnerEndpoint && !isSelectingPartner
}

const ensureAuthRouting = (session, context, redirectOptions = { force: false }) => {
  const { pathname, asPath, res } = context

  const isLoggedIn = session.role === 'user'

  const isAuthRoute = includes(authRoutes, pathname)

  const redirect = (...args) => redirectTo(...args, redirectOptions)

  if (isLoggedIn) {
    if (shouldSelectPartner(context)) {
      return redirect('/distribuidores', context)
    }

    return isAuthRoute && redirect('/', context)
  }

  const isPrivateRoute = includes(privateRoutes, pathname)

  if (isPrivateRoute) {
    cookie.save(cookieNames.CALLBACK_URL, asPath, { secure: false }, res)

    return redirect('/ingresar', context)
  }

  const hasLoginRequiredFF = context.hasFlag(flags.LOGIN_REQUIRED)

  const isAllowedRoute = includes(allowedRoutes, pathname)

  if (hasLoginRequiredFF && !isAllowedRoute) {
    cookie.save(cookieNames.CALLBACK_URL, asPath, { secure: false }, res)

    return redirect('/ingresar', context)
  }
}

export default ensureAuthRouting
