import gql from 'graphql-tag'

const commentsQuery = gql`
  query comments {
    user {
      id
      cart {
        id
        comments
      }
    }
  }
`

export default commentsQuery
