import React, { useRef } from 'react'
import Downshift from 'downshift'

import Content from './content'

const AutocompleteSearch = props => {
  const { className, data, ItemComponent, onSearch, onSelect, placeholder, searching, style } = props

  const inputRef = useRef()

  const onInternalSelect = selectedItem => {
    if (!selectedItem) {
      return
    }

    onSelect(selectedItem)

    inputRef.current.blur()
  }

  return (
    <Downshift itemToString={() => ''} onSelect={onInternalSelect}>
      {meta => (
        <div style={style} className={className}>
          <Content
            data={data}
            inputRef={inputRef}
            ItemComponent={ItemComponent}
            meta={meta}
            onSearch={onSearch}
            placeholder={placeholder}
            searching={searching}
          />
        </div>
      )}
    </Downshift>
  )
}

export default AutocompleteSearch
