import React from 'react'
import { Field as FinalFormField } from 'react-final-form'

interface FieldSubscriptionProps {
  name: string
  children: (value: any) => React.ReactNode
}

function FieldSubscription({ name, children }: FieldSubscriptionProps) {
  return (
    <FinalFormField name={name} subscription={{ value: true }}>
      {fieldProps => children(fieldProps.input.value)}
    </FinalFormField>
  )
}

export default FieldSubscription
