import * as React from 'react'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import Label from '~/truck/label'
import theme from '~/truck/theme'
import { getSizeStyle } from '~/truck/utils'

export interface MessageProps {
  title: string
  content: string
  imageSrc: string
}

const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  > img {
    margin-bottom: ${props => props.theme.spacing.relaxed}px;

    ${getSizeStyle(theme.spacing.cozy * 6)};

    ${up('lg')} {
      ${getSizeStyle(theme.spacing.cozy * 9)};
    }
  }

  > ${Label}:not(:last-child) {
    margin-bottom: ${props => props.theme.spacing.cozy}px;
  }
`

export function Message(props: MessageProps) {
  const { title, content, imageSrc } = props

  return (
    <StyledContainer>
      <img alt="icon" src={imageSrc} />
      <Label as="h5" $textStyle="h5Semibold" $textAlign="center">
        {title}
      </Label>
      <Label as="p" $color="graySlate" $textAlign="center">
        {content}
      </Label>
    </StyledContainer>
  )
}
