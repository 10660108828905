import React from 'react'
import clsx from 'clsx'

const Card = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => (
  <div ref={ref} className={clsx('rounded bg-white', className)} {...props} />
))

Card.displayName = 'Card'

export default Card
