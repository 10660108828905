import React from 'react'
import { GoogleMap, InfoWindow, Marker } from 'react-google-maps'
import { find, map } from 'lodash'

import StoreInfoBox from '../store-info-box'

import getBoundingBox from './get-bounding-box'
import getStoreLocation from './get-store-location'

class BaseMap extends React.Component {
  componentDidMount() {
    const { stores } = this.props

    const boundingBox = getBoundingBox(stores)

    this.googleMapRef.current.fitBounds(boundingBox)
  }

  googleMapRef = React.createRef()

  getGoogleMapProps = () => {
    const { selectedStoreId, stores } = this.props

    const store = find(stores, { id: selectedStoreId })

    if (!store) {
      return { zoom: 8 }
    }

    return {
      zoom: 16,
      center: getStoreLocation(store),
    }
  }

  renderMarker = store => {
    let children = null

    const { selectedStoreId, onStoreClick } = this.props

    const location = getStoreLocation(store)

    if (selectedStoreId === store.id) {
      children = (
        <InfoWindow>
          <StoreInfoBox {...store} />
        </InfoWindow>
      )
    }

    return (
      <Marker key={store.id} position={location} onClick={() => onStoreClick(store.id)}>
        {children}
      </Marker>
    )
  }

  render() {
    const { stores } = this.props

    const props = this.getGoogleMapProps()

    return (
      <GoogleMap ref={this.googleMapRef} {...props}>
        {map(stores, this.renderMarker)}
      </GoogleMap>
    )
  }
}

export default BaseMap
