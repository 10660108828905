import * as React from 'react'
import styled from 'styled-components'

import Label from '~/truck/label'
export interface ProductAttributeItemProps {
  name: string
  value: string
}

const StyledProductAttributeItem = styled.li`
  list-style-type: none;
`

export function ProductAttributeItem(props: ProductAttributeItemProps) {
  return (
    <StyledProductAttributeItem>
      <Label as="b" $textStyle="h6Semibold">
        {props.name}:
      </Label>{' '}
      <Label as="span" $textStyle="h6Regular">
        {props.value}
      </Label>
    </StyledProductAttributeItem>
  )
}
