import React from 'react'
import { Field as FinalFormField } from 'react-final-form'
import type { FieldValidator } from 'final-form'

import getError from '~/truck/utils/get-error'

import ErrorBoundary from './error-boundary'

interface FieldProps {
  name: string
  parse?: (value: any, name: string) => any
  format?: (value: any, name: string) => any
  standalone?: boolean
  value?: any
  validate?: FieldValidator<any>
  validateFields?: string[]

  [key: string]: any
}

const Field = React.forwardRef<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement, FieldProps>((props, ref) => {
  const { name, parse, format, standalone, value, validate, validateFields, ...rest } = props

  if (standalone) {
    return <ErrorBoundary ref={ref} name={name} value={value} {...rest} />
  }

  const render = fieldProps => {
    const { meta, input } = fieldProps

    const error = getError(meta)

    const elementProps = { ...meta, ...input, ...rest, error }

    return <ErrorBoundary ref={ref} {...elementProps} />
  }

  const fieldProps = {
    format,
    name,
    parse,
    render,
    value,
    validate,
    validateFields,
    type: undefined,
  }

  if (['checkbox', 'radio'].includes(rest.as)) {
    fieldProps.type = rest.as
  }

  return <FinalFormField {...fieldProps} />
})

export default Field
