import React from 'react'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import theme from '~/truck/theme'

const Container = styled.div`
  background-color: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;
  padding: ${theme.spacing.comfortable}px;
  position: relative;
  width: 100%;

  ${up('lg')} {
    border-radius: ${theme.spacing.compact}px;
    height: auto;
    left: 50%;
    max-height: ${theme.spacing.cozy * 70}px;
    padding: ${theme.spacing.cozy * 4}px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: ${theme.spacing.cozy * 90}px;
  }
`

const Standalone = props => {
  const { children, style, className, enablePropagation } = props

  return (
    <Container style={style} className={className} onClick={e => !enablePropagation && e.stopPropagation()}>
      {children}
    </Container>
  )
}

export default Standalone
