import React from 'react'

interface ContentProps {
  title: string
  children: React.ReactNode
  onEdit: () => void
  onDelete?: () => void
  isDefault?: boolean
  defaultText?: string
}

function Content({ children, onEdit, onDelete, title, isDefault, defaultText }: ContentProps) {
  return (
    <div className="space-y-4">
      <div>
        <p className="font-semibold">{title}</p>
        {children}
      </div>
      <div className="flex items-end justify-between">
        <div className="flex gap-4">
          <span className="cursor-pointer text-info" onClick={onEdit}>
            Editar
          </span>
          {onDelete && (
            <span className="cursor-pointer text-critical" onClick={onDelete}>
              Eliminar
            </span>
          )}
        </div>
        {isDefault && <p className="text-xs text-tertiary">{defaultText}</p>}
      </div>
    </div>
  )
}

export default Content
