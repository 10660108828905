import gql from 'graphql-tag'

import { initialProductFragment } from '../fragments'

const featuredProductsQuery = gql`
  query featuredProducts {
    vendor {
      id
      featuredProducts {
        ...initialProduct
      }
    }
  }
  ${initialProductFragment}
`

export default featuredProductsQuery
