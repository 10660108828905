import 'react-dates/initialize'

import React from 'react'
import { DayPickerSingleDateController } from 'react-dates'
import { find, some } from 'lodash'
import moment from 'moment-timezone'

import { Container, Overlay } from './elements'

import 'react-dates/lib/css/_datepicker.css'

const Datepicker = props => {
  const { availableDates, isOutsideRange, isOpen, onClose, onChange, value } = props

  const ownIsOutsideRange = momentDate => {
    if (isOutsideRange) {
      return isOutsideRange(momentDate)
    }

    if (!availableDates) {
      return false
    }

    const predicate = date => momentDate.isSame(date, 'day')

    return !some(availableDates, predicate)
  }

  const onDateChange = momentDate => {
    if (!availableDates) {
      return onChange(momentDate)
    }

    const getAvailableDate = date => momentDate.isSame(date, 'day')

    const actualDate = find(availableDates, getAvailableDate)

    onChange(moment(actualDate))
  }

  return (
    <Overlay open={isOpen} onClick={onClose}>
      {isOpen && (
        <Container>
          <DayPickerSingleDateController
            focused
            isFocused
            date={(value && moment(value)) || null}
            isOutsideRange={ownIsOutsideRange}
            onDateChange={onDateChange}
            hideKeyboardShortcutsPanel
            numberOfMonths={1}
            transitionDuration={0}
          />
        </Container>
      )}
    </Overlay>
  )
}

export default Datepicker
