import React from 'react'

import Address from '~/truck/address'
import type { IAddress } from '~/types/shipping'

import ContentManager, { ContentManagerProps } from '../content-manager'

const renderAddress = (address: IAddress, onRemove: (id: string) => void, disableRemoveAction: boolean) => {
  return <Address key={address.id} {...address} selectable removable={!disableRemoveAction} onRemove={onRemove} />
}

interface AddressesProps extends Omit<ContentManagerProps<IAddress>, 'renderer' | 'fieldName' | 'action'> {
  disableRemoveAction?: boolean
  disableAddAction?: boolean
}

const Addresses = (props: AddressesProps) => {
  const { disableRemoveAction, disableAddAction } = props

  return (
    <ContentManager
      {...props}
      fieldName="deliveryAddressId"
      action={!disableAddAction && 'Agregar dirección'}
      renderer={(address: IAddress, onRemove: (id: string) => void) =>
        renderAddress(address, onRemove, disableRemoveAction)
      }
    />
  )
}

export default Addresses
