import styled from 'styled-components'

import theme from '~/truck/theme'

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  > span:first-child {
    margin-right: ${theme.spacing.comfortable}px;
  }
`
