import * as React from 'react'
import { isNil } from 'lodash'
import styled from 'styled-components'

import { useVendor } from '~/hooks'
import Icon from '~/truck/icon'
import theme from '~/truck/theme'
import { getTextStyle } from '~/truck/utils'
import type { ThemeColor } from '~/types/theme'
import { getTaxLabel } from '~/utils'
import { defaultTaxLabel } from '~/utils/constants'

import Price from '../price'

interface CartChangeProps {
  style?: React.CSSProperties
  className?: string
  children: React.ReactNode
  iconColor?: ThemeColor
}

const StyledContainer = styled.div`
  display: flex;

  > svg {
    flex-shrink: 0;
    margin-right: ${theme.spacing.cozy}px;
  }
`

const StyledInnerContainer = styled.div`
  ${getTextStyle('h5Regular')}

  > div {
    display: inline-flex;
  }

  > strong {
    text-transform: uppercase;

    ${getTextStyle('h5Semibold')}
  }
`

function CartChangePrice(props: Record<string, any>) {
  return <Price color="black" textStyle="h5Regular" style={{ fontStyle: 'italic' }} {...props} />
}

function CartChange(props: CartChangeProps) {
  const { style, className, children, iconColor = 'info' } = props

  return (
    <StyledContainer className={className} style={style}>
      <Icon type="info" color={iconColor} />
      <StyledInnerContainer>{children}</StyledInnerContainer>
    </StyledContainer>
  )
}

CartChange.LineItemPriceChanged = props => {
  const { productName, price, oldPrice, style, className, iconColor } = props

  return (
    <CartChange style={style} className={className} iconColor={iconColor}>
      El precio de <strong>{productName}</strong> ha sido actualizado de <CartChangePrice value={oldPrice} /> a{' '}
      <CartChangePrice value={price} />
    </CartChange>
  )
}

// declared as a variable to please eslint hooks rule
const LineItemTaxExemptPriceChanged = props => {
  const { productName, style, className, iconColor, isTaxExemptPrice, oldIsTaxExemptPrice } = props

  const vendor = useVendor()

  const taxLabel = vendor.settings?.taxLabel ?? defaultTaxLabel

  const taxMessages = {
    taxExcludedMessage: `no incluye`,
    taxIncludedMessage: `incluye`,
    taxExemptMessage: `está exento de`,
  }

  return (
    <CartChange style={style} className={className} iconColor={iconColor}>
      El impuesto de <strong>{productName}</strong> ha sido actualizado y ahora{' '}
      {getTaxLabel(vendor.settings, isTaxExemptPrice && !oldIsTaxExemptPrice, taxMessages)} {taxLabel}.
    </CartChange>
  )
}

CartChange.LineItemTaxExemptPriceChanged = LineItemTaxExemptPriceChanged

CartChange.LineItemStockChanged = props => {
  const { productName, quantity, style, className, iconColor } = props

  return (
    <CartChange style={style} className={className} iconColor={iconColor}>
      El stock de <strong>{productName}</strong> ha sido actualizado, solo hay {quantity} disponibles
    </CartChange>
  )
}

CartChange.LineItemRemoved = props => {
  const { productName, style, className, iconColor } = props

  return (
    <CartChange style={style} className={className} iconColor={iconColor}>
      <strong>{productName}</strong> ya no se encuentra disponible y se eliminó de tu carrito de compras
    </CartChange>
  )
}

CartChange.LineItemOutOfStock = props => {
  const { productName, style, className, iconColor } = props

  return (
    <CartChange style={style} className={className} iconColor={iconColor}>
      <strong>{productName}</strong> ya no se encuentra en stock y se eliminó de tu carrito de compras
    </CartChange>
  )
}

CartChange.DeliveryCostChanged = props => {
  const { newAmount, oldAmount, style, className, iconColor } = props

  return (
    <CartChange style={style} className={className} iconColor={iconColor}>
      El precio del delivery ha sido actualizado de <CartChangePrice value={oldAmount} /> a{' '}
      <CartChangePrice value={newAmount} />
    </CartChange>
  )
}

CartChange.LineItemQuantityChanged = props => {
  const { productName, quantity, oldQuantity, style, className, iconColor } = props

  return (
    <CartChange style={style} className={className} iconColor={iconColor}>
      La cantidad de <strong>{productName}</strong> ha sido actualizada de <strong>{oldQuantity}</strong> a{' '}
      <strong>{quantity}</strong>
    </CartChange>
  )
}

CartChange.NotValidCoupon = props => {
  const { code, errorType, style, className, iconColor } = props

  const errorMessages = {
    limitPerUserError: 'que intentas utilizar ya se ha usado anteriormente',
    dateError: 'ya no puede ser utilizado porque no te encuentras en la fecha vigente de uso',
  }

  const message = errorMessages[errorType] || 'ya no puede ser utilizado porque no se encuentra disponible'

  return (
    <CartChange style={style} className={className} iconColor={iconColor}>
      El cupón <strong>{code}</strong> {message}
    </CartChange>
  )
}

CartChange.SalesQuantityLimitsChangedProduct = props => {
  const { productName, minimumSalesQuantity, maximumSalesQuantity, style, className, iconColor } = props

  return (
    <CartChange style={style} className={className} iconColor={iconColor}>
      {isNil(minimumSalesQuantity) ? (
        <>
          La cantidad máxima de compra de <strong>{productName}</strong> ha sido actualizada a{' '}
          <i>{maximumSalesQuantity} unidades</i> y se ha actualizado en el carrito.
        </>
      ) : (
        <>
          La cantidad mínima de compra de <strong>{productName}</strong> ha sido actualizada a{' '}
          <i>{minimumSalesQuantity} unidades</i>, y se ha eliminado del carrito.
        </>
      )}
    </CartChange>
  )
}

export default CartChange
