import * as React from 'react'
import type { QueryClient } from 'react-query'
import { dehydrate, DehydratedState, hydrate } from 'react-query/hydration'
import type { NormalizedCacheObject } from 'apollo-cache-inmemory'
import type ApolloClient from 'apollo-client'

import { createApolloClient } from '~/apollo-client'
import { createQueryClient } from '~/query-client'

import ErrorPage from '../../../pages/_error'

import { getAppProps } from './get-app-props'

let globalApolloClient: ApolloClient<NormalizedCacheObject> | null = null

let globalQueryClient: QueryClient | null = null

const initApolloClient = (initialState: NormalizedCacheObject, pageCtx?: PageContext) => {
  if (typeof window === 'undefined') {
    return createApolloClient(initialState, pageCtx)
  }

  if (!globalApolloClient) {
    globalApolloClient = createApolloClient(initialState, pageCtx)
  }

  return globalApolloClient
}

const initQueryClient = (dehydratedState: DehydratedState = null, pageCtx?: PageContext) => {
  if (typeof window === 'undefined') {
    const queryClient = createQueryClient(pageCtx)

    hydrate(queryClient, dehydratedState)

    return queryClient
  }

  if (!globalQueryClient) {
    globalQueryClient = createQueryClient(pageCtx)

    hydrate(globalQueryClient, dehydratedState)
  }

  return globalQueryClient
}

export const initOnAppContext = (appCtx: AppContext) => {
  const apolloClient = appCtx.ctx.apolloClient || initApolloClient(appCtx.apolloState || {}, appCtx.ctx)

  const queryClient = appCtx.ctx.queryClient || initQueryClient(appCtx.queryState, appCtx.ctx)

  // To avoid calling initApollo() twice in the server we send the Apollo Client as a prop
  // to the component, otherwise the component would have to call initApollo() again but this
  // time without the context, once that happens the following code will make sure we send
  // the prop as `null` to the browser
  // https://nextjs.org/docs/messages/circular-structure

  // @ts-expect-error ts(2339)
  apolloClient.toJSON = () => null

  // @ts-expect-error ts(2339)
  queryClient.toJSON = () => null

  appCtx.ctx.apolloClient = apolloClient

  appCtx.ctx.queryClient = queryClient

  return appCtx
}

export function withApp(AppComponent: (_appProps: RiqraAppProps) => JSX.Element) {
  const WithApp = props => {
    const {
      apolloClient: _apolloClient,
      apolloState,
      queryClient: _queryClient,
      queryState,
      uiStore: _uiStore,
      uiStoreState,
      ...appProps
    } = props

    const apolloClient = _apolloClient ? _apolloClient : initApolloClient(apolloState)

    const queryClient = _queryClient ? _queryClient : initQueryClient(queryState)

    return <AppComponent {...appProps} apolloClient={apolloClient} queryClient={queryClient} />
  }

  if (process.env.NODE_ENV !== 'production') {
    WithApp.displayName = 'withApp(App)'
  }

  WithApp.getInitialProps = async (appCtx: AppContext) => {
    const { ctx, Component: PageComponent } = initOnAppContext(appCtx)

    let appProps: any = {}

    /**
     * https://github.com/vercel/next.js/issues/5400#issuecomment-428853958
     * Do as little as possible when rendering the error page, in case something
     * in this `getInitialProps` function fails. We don't want to error on the
     * error page, so to speak.
     */
    if (PageComponent === ErrorPage) {
      try {
        appProps = await getAppProps(ctx)
      } catch (error) {
        ctx.err = error
      }

      if (PageComponent.getInitialProps) {
        appProps.pageProps = await PageComponent.getInitialProps(ctx)
      }

      return appProps
    }

    appProps = await getAppProps(ctx)

    if (ctx.res?.finished) {
      return {}
    }

    if (PageComponent.getInitialProps) {
      appProps.pageProps = await PageComponent.getInitialProps(ctx)
    }

    if (typeof window === 'undefined') {
      const { AppTree } = ctx

      if (ctx.res?.finished) {
        return appProps.pageProps
      }

      if (AppTree) {
        try {
          const { getDataFromTree } = await import('@apollo/react-ssr')

          await getDataFromTree(<AppTree {...appProps} apolloClient={ctx.apolloClient} />)
        } catch (error) {
          console.error('Error while running `getDataFromTree`', error)
        }
      }
    }

    const apolloState = ctx.apolloClient.cache.extract()

    const queryState = dehydrate(ctx.queryClient)

    return {
      ...appProps,
      apolloState,
      apolloClient: ctx.apolloClient,
      queryState,
      queryClient: ctx.queryClient,
    }
  }

  return WithApp
}
