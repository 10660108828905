import React from 'react'
import ReactDOM from 'react-dom'
import { noop } from 'lodash'

import Alert from '../alert'

import Manager, { ManagerProps } from './manager'

const createNotify = (managerProps?: ManagerProps) => {
  if (typeof window === 'undefined') {
    return noop
  }

  const managerRootEl = document.createElement('div')

  document.body.appendChild(managerRootEl)

  const managerRef = React.createRef<Manager>()

  ReactDOM.render(<Manager {...managerProps} ref={managerRef} />, managerRootEl)

  const notify = notificationProps => {
    managerRef.current.addNotification(notificationProps)
  }

  return notify
}

/**
 * @example
 *  notify({ message: 'unexpected error', type: 'error' })
 */
const notify = createNotify()

const createObject = (result, type) => {
  const showMessage = (message, extras) => {
    notify({ type, message, ...extras })
  }

  return { ...result, [type]: showMessage }
}

/**
 * @example
 *  notifier.error('unexpected error')
 *  notifier.success('everything is ok')
 *  notifier.success("it'll be closed", { timeout: 10000 })
 */
export default Alert.types.reduce(createObject, {})
