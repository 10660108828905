import React, { useMemo, useState } from 'react'
import { findIndex, isString, join, map, pullAt } from 'lodash'
import nanoid from 'nanoid'

import { ModalContext } from '~/contexts'

const ModalProvider = props => {
  const { children } = props

  const [state, setState] = useState([])

  const providedValue = useMemo(() => {
    const openModal = (Modal, modalProps = {}) => {
      const newModal = { Modal, modalProps, id: nanoid() }

      newModal.modalProps.blockScroll = state.length === 0

      setState(prevState => [...prevState, newModal])

      return newModal.id
    }

    const closeModal = modalId => {
      setState(prevState => {
        const newState = [...prevState]

        let modalIndex

        /**
         * It is necessary to validate that the id is a string,
         * since in some places it is passing this function
         * directly as onClick, therefore the event object
         * is obtained instead of an id (nanoid).
         */
        if (isString(modalId)) {
          modalIndex = findIndex(newState, { id: modalId })
        } else {
          modalIndex = newState.length - 1
        }

        if (modalIndex >= 0) {
          pullAt(newState, modalIndex)
        }

        return newState
      })
    }

    return {
      state,
      manager: {
        closeModal,
        openModal,
      },
    }
  }, [join(map(state, 'id'), '-')])

  return <ModalContext.Provider value={providedValue}>{children}</ModalContext.Provider>
}

export default ModalProvider
