import gql from 'graphql-tag'

const addressFragment = gql`
  fragment address on Address {
    id
    contactPhone
    contactPerson
    line
    apartment
    reference
    isDefault
    locationLine @client
    location {
      id
      name
      division
      parentId
    }
    coordinates {
      latitude
      longitude
    }
    divisionLevel1
    divisionLevel2
    divisionLevel3
    divisionLevel4
  }
`

export default addressFragment
