import gql from 'graphql-tag'

import { userFragment } from '../fragments'

const updateUserMutation = gql`
  mutation updateUser(
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
    $telephone: String
    $birthdate: Date
    $acceptedTermsAndConditions: Boolean
    $allowAdvertising: Boolean
    $acceptedPrivacyPolicy: Boolean
    $newPassword: String
    $confirmPassword: String
  ) {
    updateUser(
      input: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        phone: $phone
        telephone: $telephone
        birthdate: $birthdate
        acceptedTermsAndConditions: $acceptedTermsAndConditions
        acceptedPrivacyPolicy: $acceptedPrivacyPolicy
        allowAdvertising: $allowAdvertising
        newPassword: $newPassword
        confirmPassword: $confirmPassword
      }
    ) {
      user {
        ...user
      }
    }
  }

  ${userFragment}
`

export default updateUserMutation
