import { isNil, some } from 'lodash'

import type { IVendor } from '~/types/vendors'
import { flags } from '~/utils/constants'
import isValidTimeRange from '~/utils/is-valid-time-range'

import hasFlag from './has-flag'

export interface removeFirstDeliveryDateParams {
  vendor: IVendor
  deliveryDates: Date[]
  deliveryTimeRanges: Date[]
}

function removeFirstDeliveryDateIfNotValid(params: removeFirstDeliveryDateParams) {
  const { vendor, deliveryDates = [], deliveryTimeRanges } = params

  const { features, settings } = vendor

  const canSelectDeliveryTimeRange = hasFlag(features, flags.SELECT_DELIVERY_TIME_RANGE)

  const canSelectPickupTimeRange = hasFlag(features, flags.SELECT_PICKUP_TIME_RANGE)

  if (!canSelectDeliveryTimeRange && !canSelectPickupTimeRange) {
    return deliveryDates
  }

  const [firstDeliveryDate, ...restDeliveryDates] = deliveryDates

  const preferCheckMinHour = settings?.deliveryTimeRange?.preferCheckMinHour

  const checkIfIsValidTimeRange = timeRange => {
    return isValidTimeRange({
      preferCheckMinHour,
      deliveryDate: firstDeliveryDate,
      timeRange: timeRange.time,
    })
  }

  const hasDeliveryTimeRanges = !isNil(deliveryTimeRanges)

  const isSomeValidTimeRange = some(deliveryTimeRanges, checkIfIsValidTimeRange)

  if (hasDeliveryTimeRanges && !isSomeValidTimeRange) {
    return restDeliveryDates
  }

  return deliveryDates
}

export default removeFirstDeliveryDateIfNotValid
