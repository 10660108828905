// https://stackoverflow.com/a/901144
const getParameterValue = (name: string, url: string) => {
  const newName = name.replace(/[[\]]/g, '\\$&')

  const regex = new RegExp(`[?&]${newName}(=([^&#]*)|&|#|$)`)

  const results = regex.exec(url)

  if (!results) {
    return null
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export default getParameterValue
