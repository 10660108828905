import * as React from 'react'
import styled from 'styled-components'

import Label from '~/truck/label'

interface Props {
  value: string
  className?: string
}

const Triangle = styled.div`
  background-color: ${props => props.theme.palette.errorLight};
  border-radius: ${props => props.theme.dimensions.borderRadius}px;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  height: ${props => props.theme.spacing.cozy * 2.25}px;
  transform: rotate(45deg);
  width: ${props => props.theme.spacing.cozy * 2.25}px;
`

const Rectangle = styled.div`
  background-color: ${props => props.theme.palette.errorLight};
  border-radius: ${props => props.theme.dimensions.borderRadius}px;
  color: ${props => props.theme.colors.white};
  height: ${props => props.theme.spacing.cozy * 3}px;
  margin-left: -${props => props.theme.spacing.cozy * 1.375}px;
  width: ${props => props.theme.spacing.cozy * 4.75}px;
  z-index: 0;
`

const Container = styled.div`
  align-items: center;
  display: flex;
`

function DiscountLabel(props: Props) {
  const { value, className } = props

  return (
    <Container className={className}>
      <Triangle />
      <Rectangle>
        <Label as="span" $color="white" $textStyle="h6Semibold">
          {value}
        </Label>
      </Rectangle>
    </Container>
  )
}

export default DiscountLabel
