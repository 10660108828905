import * as React from 'react'

import Button from '~/truck/button'

import { Dialog, DialogProps } from '../dialog'

export interface DeleteDialogProps extends Omit<DialogProps, 'accept' | 'cancel'> {
  onAccept: () => void
  acceptText?: string
  cancelText?: string
}

export function DeleteDialog(props: DeleteDialogProps) {
  const { onAccept, acceptText = 'Sí', cancelText = 'No', ...rest } = props

  return (
    <Dialog
      {...rest}
      accept={
        <Button color="error" onClick={onAccept}>
          {acceptText}
        </Button>
      }
      cancel={
        <Button.Ghost color="grayDark" onClick={props.onClose}>
          {cancelText}
        </Button.Ghost>
      }
    />
  )
}
