import gql from 'graphql-tag'

import { addressFragment } from '../fragments'

const userAddressesQuery = gql`
  query userAddresses {
    user {
      id
      addresses {
        ...address
        territory {
          id
          shippingType {
            id
            slug
          }
        }
      }
    }
  }
  ${addressFragment}
`

export default userAddressesQuery
