import React from 'react'
import clsx from 'clsx'

interface CategoryHighlightProps {
  isFeatured: boolean
  isSelected: boolean
  className?: string
  children: React.ReactNode
  // Next.js Link props
  href?: string
  onClick?: () => void
}

const Category = React.forwardRef<HTMLAnchorElement, CategoryHighlightProps>(
  ({ className, href, children, isFeatured, isSelected, onClick }, ref) => {
    return (
      <a
        ref={ref}
        className={clsx(
          'block text-sm hover:bg-neutral-200',
          isFeatured && 'text-critical',
          isSelected && 'font-semibold',
          className,
        )}
        href={href}
        onClick={onClick}
      >
        {children}
      </a>
    )
  },
)

export default Category
