import * as React from 'react'
import { useMutation, useQuery } from 'react-query'
import type { AxiosError, AxiosRequestConfig } from 'axios'

import { useFetcher } from '~/hooks'
import Button from '~/truck/button'
import notifier from '~/truck/notifier'

import type { CreateExportMutationResult, IExportState } from '../types'

const MAX_RETRY_COUNT = 3

const RETRY_DELAY = 2 * 1000

const REFETCH_INTERVAL = 2 * 1000

const downloadFile = (fileUrl: string) => {
  const a = document.createElement('a')

  a.href = fileUrl

  a.click()
}

type CreateExportProps = {
  dispatch: React.Dispatch<any>
  state: IExportState
  closeModal: () => void
}

const CreateExport = (props: CreateExportProps) => {
  const { dispatch, state, closeModal } = props

  const createExportfetcher = useFetcher({ endpoint: '/bulk-operations/exports' })

  const { mutateAsync: createExportAsync } = useMutation<CreateExportMutationResult, AxiosError, AxiosRequestConfig>(
    createExportfetcher,
  )

  const [createdId, setCreatedId] = React.useState(null)

  const [refetchInterval, setRefetchInterval] = React.useState<number | false>(REFETCH_INTERVAL)

  const [retryCount, setRetryCount] = React.useState(0)

  const queryKey = React.useMemo(() => {
    return `/bulk-operations/exports/${createdId}/output`
  }, [createdId])

  const donwloadFileFetcher = useFetcher({ endpoint: queryKey })

  const onSettedExportedFile = (response: CreateExportMutationResult, error) => {
    setRetryCount(prevRetryCount => prevRetryCount + 1)

    if (response.data?.file && retryCount < MAX_RETRY_COUNT) {
      setRefetchInterval(false)

      downloadFile(response.data.file.url)

      closeModal()
    }

    if (retryCount >= MAX_RETRY_COUNT || error) {
      closeModal()

      notifier.error('Error al descargar el archivo. Intente nuevamente.')
    }
  }

  const { refetch: getExportedFile } = useQuery(
    queryKey,
    async (): Promise<CreateExportMutationResult> => {
      if (!createdId) {
        return
      }

      const response = await donwloadFileFetcher({ method: 'GET' })

      return response?.data
    },
    {
      enabled: !!createdId,
      retry: MAX_RETRY_COUNT,
      retryDelay: RETRY_DELAY,
      refetchInterval: refetchInterval,
      onSettled: onSettedExportedFile,
    },
  )

  const handleOnClick = async () => {
    dispatch({ type: 'create-export' })

    const response: any = await createExportAsync({
      method: 'POST',
      data: {
        entity: 'order',
        extraParams: state.params,
      },
    })

    setCreatedId(response.data?.createdId)

    await getExportedFile()
  }

  return (
    <Button
      onClick={() => handleOnClick()}
      color="secondary"
      loading={state.button.isLoading}
      disabled={state.button.disabled}
    >
      {state.button.wording}
    </Button>
  )
}

export default CreateExport
