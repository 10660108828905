import * as React from 'react'

import { useModalManager, useVendor } from '~/hooks'
import { mixpanel } from '~/metrics'
import { events } from '~/utils/constants'
import { getBasicEventPayloadFromVendor } from '~/utils/mixpanel/get-basic-event-payload-from-vendor'

import type { BulkOrderingResultError, BulkOrderingResultOperation } from '../../types'

import dialogReducer from './dialog-reducer'
import { DialogContextValue, DialogState, DialogStep } from './types'

export interface DialogProviderProps {
  children: React.ReactNode
}

const initialState: DialogState = {
  step: DialogStep.START_PROCESS,
  errors: [],
  operations: [],
}

const DialogContext = React.createContext<DialogContextValue>(initialState as DialogContextValue)

export function DialogProvider(props: DialogProviderProps) {
  const { children } = props

  const vendor = useVendor()

  const modalManager = useModalManager()

  const [state, dispatch] = React.useReducer(dialogReducer, initialState)

  const setStep = React.useCallback(
    (step: DialogStep, errors: BulkOrderingResultError[], operations: BulkOrderingResultOperation[]) => {
      dispatch({ type: 'SET_STEP', payload: { step, errors, operations } })
    },
    [dispatch],
  )

  const onModalClose = React.useCallback(() => {
    modalManager.closeModal()

    mixpanel.track(events.BULK_ORDERING_MODAL_CLOSED, getBasicEventPayloadFromVendor(vendor))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const contextValue = React.useMemo(() => {
    return {
      ...state,
      setStep,
      onModalClose,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return <DialogContext.Provider value={contextValue}>{children}</DialogContext.Provider>
}

export function useDialogState() {
  const context = React.useContext(DialogContext)

  if (!context) {
    throw new Error('useDialogState must be used within a DialogProvider')
  }

  return context
}
