import gql from 'graphql-tag'

const editOrderSetPaymentMutation = gql`
  mutation editOrderSetPaymentMutation(
    $paymentMethodId: ID!
    $configuration: JSON
    $orderId: ID
    $isOrderEdition: Boolean!
  ) {
    setPayment(input: { paymentMethodId: $paymentMethodId, configuration: $configuration, orderId: $orderId }) {
      job @skip(if: $isOrderEdition) {
        id
        state
        returnValue
        failedReason
      }
      order @include(if: $isOrderEdition) {
        id
      }
    }
  }
`

export default editOrderSetPaymentMutation
