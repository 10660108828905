import styled from 'styled-components'

import Label from '~/truck/label'
import theme from '~/truck/theme'

export const CircleBackground = styled.circle`
  fill: none;
  stroke: ${theme.colors.gray};
`

export const CircleProgress = styled.circle`
  fill: none;
  stroke: ${theme.colors.primary};
  stroke-linecap: round;
  stroke-linejoin: round;
`

export const Text = styled(Label).attrs({
  as: 'text',
  $textStyle: 'h4Semibold',
})``
