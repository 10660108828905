import gql from 'graphql-tag'

import { paymentMethodFragment } from '../fragments'

const paymentMethodsQuery = gql`
  query paymentMethods {
    vendor {
      id
      paymentMethods {
        ...paymentMethod
      }
    }
  }
  ${paymentMethodFragment}
`

export default paymentMethodsQuery
