import styled from 'styled-components'

import Input from '~/truck/input'

export const StyledInput = styled(Input)`
  &[type='tel']::-webkit-outer-spin-button,
  &[type='tel']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
