import * as React from 'react'
import Link, { LinkProps } from 'next/link'
import { useRouter } from 'next/router'

export interface RouterLinkProps extends LinkProps {
  children: React.ReactElement<{ selected?: boolean }>
}

export default function RouterLink(props: RouterLinkProps) {
  const { href, children } = props

  const router = useRouter()

  const selected = router.asPath === href

  return (
    <Link href={href} {...props}>
      {React.cloneElement(children, { selected })}
    </Link>
  )
}
