import React, { useState } from 'react'
import { isEmpty, omit } from 'lodash'
import type { CSSObject } from 'styled-components'
import { useFlag } from 'toggled'

import { EmptyState } from '~/components'
import { CouponModal } from '~/components/coupon-modal/CouponModal'
import { useIsMobile, useMainDesktopHeight } from '~/hooks'
import { flags, images } from '~/utils/constants'

import { CartLoadingOverlay } from '../../components/cart-loading-overlay'
import Footer from '../../components/footer'
import Header from '../../components/header'
import LineItems from '../../components/line-items'

import { Container, MiddleContainer, StyledOverlay } from './Cart.style'

// TODO: use (DeliveryDate) '../delivery-date' when the API support it

export const Content = props => {
  const { lineItems } = props

  const isNotAvailable = useFlag(flags.NOT_AVAILABLE)

  let emptyStateProps: any = {
    image: images.ILLUSTRATION_EMPTY_CART,
    title: 'Tu carrito esta vacío',
    subtitle: 'Parece que aún no has elegido que comprar...',
    actionProps: { route: '/', label: 'Ver productos' },
  }

  if (isNotAvailable) {
    emptyStateProps = omit(emptyStateProps, ['subtitle', 'actionProps'])
  }

  if (isEmpty(lineItems)) {
    return <EmptyState {...emptyStateProps} />
  }

  return (
    <MiddleContainer>
      <LineItems dataSource={lineItems} />
    </MiddleContainer>
  )
}

interface CartViewProps {
  canReturn?: boolean
  style?: CSSObject
  className?: string
  // TODO(refactor/cart): apply types to cart
  cart: any
  showOverlay?: boolean
  minimumOrder?: string
}

export const CartView = (props: CartViewProps) => {
  const { canReturn, style, className, cart, showOverlay, minimumOrder } = props

  const [isCouponModalOpen, setIsCouponModalOpen] = useState(false)

  const showsCartInMainLayout = useFlag(flags.SHOW_CART_IN_MAIN_LAYOUT)

  const isMobile = useIsMobile()

  const [ref, hasAnnouncement] = useMainDesktopHeight()

  return (
    <Container
      style={style}
      className={className}
      ref={!isMobile && showsCartInMainLayout && hasAnnouncement ? ref : null}
    >
      {showOverlay && <CartLoadingOverlay />}
      {isCouponModalOpen && (
        <StyledOverlay open={isCouponModalOpen} blocking={false}>
          <CouponModal
            isStandaloneModal={false}
            onClose={() => {
              setIsCouponModalOpen(false)
            }}
          />
        </StyledOverlay>
      )}
      <Header numOfProducts={cart?.lineItems?.length ?? 0} minimumOrder={minimumOrder} canReturn={canReturn} />
      <Content lineItems={cart?.lineItems} />
      <Footer
        total={cart?.total}
        lineItemsTotal={cart?.lineItemsTotal}
        subTotal={cart?.subTotal}
        priceLines={cart?.priceLines}
        minimumOrder={minimumOrder}
        onAddCouponClick={() => {
          setIsCouponModalOpen(true)
        }}
      />
    </Container>
  )
}
