import gql from 'graphql-tag'

const orderStatusFragment = gql`
  fragment orderStatus on OrderStatus {
    id
    slug
    name
    color
  }
`

export default orderStatusFragment
