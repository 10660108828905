import { merge } from 'lodash'
import { stringify } from 'qs'

import { JSONHTTPError, TextHTTPError } from '~/errors'

export type SuperFetchQuery = any

export interface SuperfetchOptions {
  endpoint?: string
  baseURL?: string
  query?: SuperFetchQuery
  headers?: Record<string, string>
  data?: any
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
}

const defaultOptions: SuperfetchOptions = {
  baseURL: '',
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
  },
  method: 'GET',
}

function getQueryString(endpoint: string, query: SuperFetchQuery) {
  if (!query) {
    return ''
  }

  const startChar = endpoint.includes('?') ? '&' : '?'

  const stringifiedQuery = stringify(query, {
    indices: false,
    arrayFormat: 'brackets',
  })

  return `${startChar}${stringifiedQuery}`
}

function getRequestInit(options: SuperfetchOptions) {
  const reqInit: RequestInit = {
    headers: options.headers,
    method: options.method,
  }

  if (options.data) {
    reqInit.body = JSON.stringify(options.data)
  }

  return reqInit
}

export async function superfetch(injectedOptions: SuperfetchOptions = {}) {
  const options: SuperfetchOptions = merge({}, defaultOptions, injectedOptions)

  const queryString = getQueryString(options.endpoint, options.query)

  const URL = `${options.baseURL}${options.endpoint}${queryString}`

  const response = await fetch(URL, getRequestInit(options))

  const contentType = response.headers.get('Content-Type')

  const isJSON = contentType && contentType.match(/json/)

  const data = isJSON ? await response.json() : await response.text()

  if (response.ok) {
    return data
  }

  if (isJSON) {
    throw new JSONHTTPError(response, data)
  } else {
    throw new TextHTTPError(response, data)
  }
}
