import * as React from 'react'
import { useMutation } from 'react-query'
import { isEmpty, some } from 'lodash'
import styled from 'styled-components'

import { HTTPError, UnsupportedValueError } from '~/errors'
import { useIsMobile, useVendor } from '~/hooks'
import type { SuperfetchOptions } from '~/lib/superfetch'
import { mixpanel } from '~/metrics'
import Button from '~/truck/button'
import notifier from '~/truck/notifier'
import theme from '~/truck/theme'
import { notifySentry } from '~/utils'
import { events } from '~/utils/constants'
import { getBasicEventPayloadFromVendor } from '~/utils/mixpanel/get-basic-event-payload-from-vendor'

import { BulkDialogHeader } from '../bulk-dialog'
import { BulkOrderingTypeCopyFromExcel } from '../bulk-ordering-type/bulk-ordering-type-copy-from-excel'
import { BulkOrderingTypeInsertManually } from '../bulk-ordering-type/bulk-ordering-type-insert-manually'
import { DialogProcessingStepContent } from '../dialog-processing-step-content'
import { useDialogState } from '../dialog-provider'
import { DialogStep } from '../dialog-provider/types'

import { BulkOrderingType, PutCartMutationResult } from './types'
import { trackBulkOrderingProcessFinished } from './utils'

const StyledButton = styled(Button.Plain)<{ $isMobile?: boolean; $isSelected?: boolean }>`
  background-color: ${theme.palette.grayscale40};
  max-width: ${props => props.theme.spacing.comfortable * 16}px;
  ${props =>
    props.$isMobile &&
    `
    padding-bottom: ${theme.spacing.cozy * 5}px;
    padding-top: ${theme.spacing.cozy * 5}px;
  `}
  ${props =>
    props.$isSelected &&
    `
    background-color: ${theme.palette.grayscale30};
  `};
`
const StyledBulkOrderingTypeDiv = styled.div`
  display: flex;
  justify-content: center;
  padding-left: ${theme.spacing.compact * 5}px;
  padding-right: ${theme.spacing.compact * 5}px;
`

export function DialogStartProcessStepContent() {
  const { setStep, onModalClose } = useDialogState()

  const vendor = useVendor()

  const isMobile = useIsMobile()

  const { mutateAsync, isLoading } = useMutation<PutCartMutationResult, HTTPError, SuperfetchOptions>({})

  const [type, setType] = React.useState(BulkOrderingType.INSERT_MANUALLY)

  const updateCart = async inputParsed => {
    try {
      mixpanel.track(events.BULK_ORDERING_PROCESS_STARTED, getBasicEventPayloadFromVendor(vendor))

      const processResponse = await mutateAsync({
        endpoint: '/account/cart',
        data: inputParsed,
        method: 'PUT',
      })

      const responseErrors = processResponse.data.errors

      const responseOperations = processResponse.data.operations

      const hasErrors = !isEmpty(responseErrors)

      const hasOperations = !isEmpty(responseOperations)

      const operationsWithAffectedQuantity = some(
        responseOperations,
        operation => operation.data.appliedQuantity !== operation.data.proposalQuantity,
      )

      if (hasErrors && !hasOperations) {
        setStep(DialogStep.UNSATISFACTORILY_PROCESSED, responseErrors, [])
      } else if (!hasErrors && hasOperations && !operationsWithAffectedQuantity) {
        setStep(DialogStep.SUCCESSFULLY_PROCESSED, [], responseOperations)
      } else if ((hasErrors && hasOperations) || operationsWithAffectedQuantity) {
        setStep(DialogStep.PARTIALLY_PROCESSED, responseErrors, responseOperations)
      } else {
        throw new Error('We cant determine the dialog step')
      }

      trackBulkOrderingProcessFinished(processResponse, vendor)
    } catch (error) {
      console.error(error)

      notifier.error('Ha ocurrido un error al iniciar el proceso')

      await notifySentry(error)
    }
  }

  if (isLoading) {
    return <DialogProcessingStepContent />
  }

  function BulkOrderingTypeContent() {
    switch (type) {
      case BulkOrderingType.INSERT_MANUALLY: {
        return <BulkOrderingTypeInsertManually updateCart={updateCart} />
      }

      case BulkOrderingType.COPY_FROM_EXCEL: {
        return <BulkOrderingTypeCopyFromExcel updateCart={updateCart} />
      }

      default:
        console.error(new UnsupportedValueError(`"${type}" is not supported.`))

        return null
    }
  }

  return (
    <>
      <BulkDialogHeader closable title="Compra masiva" onClose={onModalClose} />
      <StyledBulkOrderingTypeDiv>
        <StyledButton
          color={type === BulkOrderingType.INSERT_MANUALLY ? 'grayscale80' : 'grayscale60'}
          $isSelected={type === BulkOrderingType.INSERT_MANUALLY}
          onClick={() => setType(BulkOrderingType.INSERT_MANUALLY)}
          $isMobile={isMobile}
        >
          Ingresar manualmente
        </StyledButton>
        <StyledButton
          color={type === BulkOrderingType.COPY_FROM_EXCEL ? 'grayscale80' : 'grayscale60'}
          $isSelected={type === BulkOrderingType.COPY_FROM_EXCEL}
          onClick={() => setType(BulkOrderingType.COPY_FROM_EXCEL)}
          $isMobile={isMobile}
        >
          Copiar y pegar desde Excel
        </StyledButton>
      </StyledBulkOrderingTypeDiv>

      <BulkOrderingTypeContent />
    </>
  )
}
