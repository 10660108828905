import React, { ClipboardEvent, createContext, KeyboardEvent, PropsWithChildren, useContext, useState } from 'react'

function validate(value: string, type: UsePinInputProps['type']) {
  const NUMERIC_REGEX = /^[0-9]+$/

  const ALPHA_NUMERIC_REGEX = /^[a-zA-Z0-9]+$/i

  const regex = type === 'alphanumeric' ? ALPHA_NUMERIC_REGEX : NUMERIC_REGEX

  return regex.test(value)
}

export interface PinInputContextProps {
  getInputProps?: ReturnType<typeof usePinInput>['getInputProps']
}

const PinInputContext = createContext<PinInputContextProps>({})

export const PinInputProvider = (props: PropsWithChildren<{ value: PinInputContextProps }>) => (
  <PinInputContext.Provider value={props.value}>{props.children}</PinInputContext.Provider>
)

function arrToString(arr: Array<string | undefined>) {
  let stringResult = ''

  const numberOfEmptyEndSlots = [...arr].reverse().findIndex(value => !!value)

  if (numberOfEmptyEndSlots === -1) {
    return ''
  }

  for (let i = 0; i < arr.length - numberOfEmptyEndSlots; i++) {
    stringResult += arr[i] ? arr[i] : ' '
  }

  return stringResult
}

export const usePinInputContext = () => useContext(PinInputContext)

export const useChildrenInput = (
  props: Omit<UsePinInputProps, 'type'>,
  refs: Array<HTMLInputElement | null | undefined>,
) => {
  const { value, onChange } = props

  const [values, _setValues] = useState<string[]>(value?.split('') ?? [])

  const [stringValue, setStringValue] = useState<string>(value ?? '')

  const setValues = (values: string[]) => {
    const newStringValue = arrToString(values)

    onChange?.(newStringValue)

    setStringValue(newStringValue)

    if (!onChange) {
      _setValues(values)
    }
  }

  const next = (index: number) => {
    if (!refs[index + 1]) {
      return
    }

    return {
      changeFocus: () => {
        refs[index]?.blur?.()
        refs[index + 1]?.focus?.()
      },
    }
  }

  const prev = (index: number) => {
    if (!refs[index - 1]) {
      return
    }

    return {
      changeFocus: () => {
        refs[index]?.blur?.()
        refs[index - 1]?.focus?.()
      },
    }
  }

  return {
    next,
    prev,
    values: value ? value.split('') : values,
    stringValue,
    setValues,
    length: refs.length,
    nodes: refs,
  }
}

export interface UsePinInputProps {
  type?: 'alphanumeric' | 'number'
  value?: string
  onChange?: (value: string) => void
}

export const usePinInput = (props: UsePinInputProps, refs: Array<HTMLInputElement | null | undefined>) => {
  const { type } = props

  const inputs = useChildrenInput(props, refs)

  const setValue = (index: number, char: string) => {
    if (!!char && !validate(char, type)) {
      return
    }

    const values = [...inputs.values]

    if (!char && !inputs.values[index] && index - 1 >= 0) {
      values[index - 1] = ''
    }

    values[index] = char

    if (char) {
      const next = inputs.next(index)

      if (next) {
        requestAnimationFrame(() => next.changeFocus())
      }
    } else if (!inputs.values[index]) {
      const prev = inputs.prev(index)

      if (prev) {
        requestAnimationFrame(() => prev.changeFocus())
      }
    }

    inputs.setValues(values)
  }

  const pasteValues = (index: number, newValues: string) => {
    const stringValue = newValues.substring(0, inputs.length)

    if (!validate(stringValue, type)) {
      return
    }

    inputs.setValues(stringValue.split(''))

    requestAnimationFrame(() => {
      inputs.nodes[index]?.blur?.()

      inputs.nodes[Math.min(newValues?.length - 1 ?? 0, inputs.length - 1)]?.focus?.()
    })
  }

  const getInputProps = (index: number) => {
    const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Backspace') {
        setValue(index, '')
      }
    }

    const onKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
      event.preventDefault()

      if (event.key.length > 1) {
        return
      }

      setValue(index, event.key)
    }

    const onPaste = (event: ClipboardEvent<HTMLInputElement>) => {
      pasteValues(index, event.clipboardData.getData('text'))
    }

    return {
      onPaste,
      onKeyDown,
      onKeyPress,
      value: inputs.values[index] ?? '',
      autocomplete: 'off',
    }
  }

  return {
    getInputProps,
    value: inputs.stringValue,
  }
}
