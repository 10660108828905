import * as React from 'react'
import styled from 'styled-components'

import { UnsupportedValueError } from '~/errors'
import { useVendor } from '~/hooks'
import { mixpanel } from '~/metrics'
import theme from '~/truck/theme'
import { events } from '~/utils/constants'
import { getBasicEventPayloadFromVendor } from '~/utils/mixpanel/get-basic-event-payload-from-vendor'

import { DialogModal } from '../dialog/modal'

import { DialogPartiallyProcessedStepContent } from './components/dialog-partially-processed-step-content'
import { DialogProvider, useDialogState } from './components/dialog-provider'
import { DialogStep } from './components/dialog-provider/types'
import { DialogStartProcessStepContent } from './components/dialog-start-process-step-content'
import { DialogSuccessfullyProcessedStepContent } from './components/dialog-successfully-processed-step-content'
import { DialogUnsatisfactorilyProcessedStepContent } from './components/dialog-unsatisfactorily-processed-step-content'

const StyledDialogContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.dimensions.borderRadius}px;
  max-height: calc(100vh - ${props => props.theme.spacing.cozy * 2}px);
  overflow-y: auto;
  position: relative;
`

function BulkOrderingModalStepContent() {
  const { step } = useDialogState()

  switch (step) {
    case DialogStep.START_PROCESS: {
      return <DialogStartProcessStepContent />
    }
    case DialogStep.PARTIALLY_PROCESSED: {
      return <DialogPartiallyProcessedStepContent />
    }

    case DialogStep.SUCCESSFULLY_PROCESSED: {
      return <DialogSuccessfullyProcessedStepContent />
    }

    case DialogStep.UNSATISFACTORILY_PROCESSED: {
      return <DialogUnsatisfactorilyProcessedStepContent />
    }

    default: {
      console.error(new UnsupportedValueError(`The dialog step "${step}" is not supported.`))

      return null
    }
  }
}

function BulkOrderingModalContent() {
  const { onModalClose } = useDialogState()

  return (
    <DialogModal onClose={onModalClose} maxWidth={theme.spacing.cozy * 86}>
      <StyledDialogContainer>
        <BulkOrderingModalStepContent />
      </StyledDialogContainer>
    </DialogModal>
  )
}

export function BulkOrderingModal() {
  const vendor = useVendor()

  React.useEffect(() => {
    mixpanel.track(events.BULK_ORDERING_MODAL_OPENED, getBasicEventPayloadFromVendor(vendor))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DialogProvider>
      <BulkOrderingModalContent />
    </DialogProvider>
  )
}
