import * as React from 'react'
import styled from 'styled-components'

import { Dialog } from '~/components/dialog'
import type { DialogHeaderProps } from '~/components/dialog/header'

export type BulkDialogHeaderProps = DialogHeaderProps

const StyledDialogHeader = styled(Dialog.Header)`
  height: auto;
  padding: ${props => props.theme.spacing.cozy * 2}px ${props => props.theme.spacing.cozy * 3}px;
  position: relative;
`

export function BulkDialogHeader(props: BulkDialogHeaderProps) {
  return <StyledDialogHeader {...props} />
}
