import styled from 'styled-components'

import Icon from '~/truck/icon'
import theme from '~/truck/theme'

export const types = ['success', 'info', 'warning', 'error']

const getTypeStyle = props => {
  const { type = types[0] } = props

  const borderColor = theme.colors[type]
  const backgroundColor = theme.colors[`${type}Bg`]

  return `
    background: ${backgroundColor};
    border: 1px solid ${borderColor};
  `
}

export const Close = styled(Icon).attrs(props => {
  const { type, color, size } = props

  return {
    type: type || 'close',
    color: color || 'black',
    size: size || 'sm',
  }
})`
  position: absolute;
  right: ${theme.spacing.comfortable}px;
  top: ${theme.spacing.comfortable}px;
`

export const Container = styled.div`
  border-radius: ${theme.dimensions.borderRadius}px;
  color: ${theme.colors.black};
  padding: ${theme.spacing.comfortable}px;
  position: relative;

  ${getTypeStyle};
`
