import styled from 'styled-components'

export const Container = styled.div`
  flex-grow: 1;
`

export const MapElement = styled.div`
  height: 100%;
  width: 100%;
`
