import type { IProductFilterTarget } from '~/types/product-filters'

export interface FilterValuesSerializerData {
  target: IProductFilterTarget
  values: string[]
}

export function serializeFilterValues(data: FilterValuesSerializerData) {
  const { target, values } = data

  return `${target}:${values.join('|')}`
}

export function deserializeFilterValues(serialized: string) {
  const [target, values] = serialized.split(':')

  return {
    target: target as IProductFilterTarget,
    values: values.split('|'),
  }
}
