import React from 'react'

import Icon from '../icon'

import { Container, StyledSelect } from './elements'

type SelectProps = React.ComponentPropsWithoutRef<'select'> & {
  prompt?: string
  error?: boolean
  disabled?: boolean
}

// eslint-disable-next-line react/prop-types
function Select({ style, className, error, disabled, children, prompt, ...selectProps }: SelectProps) {
  return (
    <Container style={style} className={className} $error={error} $disabled={disabled}>
      <StyledSelect disabled={disabled} $error={error} {...selectProps}>
        {prompt && (
          <option value="" hidden>
            {prompt}
          </option>
        )}
        {children}
      </StyledSelect>
      <Icon type="caret-down" size="sm" interactive={false} />
    </Container>
  )
}

export default Select
