import * as React from 'react'
import { filter, isEmpty, map } from 'lodash'
import styled from 'styled-components'

import Label from '~/truck/label'

import { BulkOrderingResultErrorType } from '../../types'
import { useDialogState } from '../dialog-provider'

const VStack = styled.div<{ $spacingNumber?: number }>`
  & > :not(style) ~ :not(style) {
    margin-top: ${props => props.theme.spacing.cozy * props.$spacingNumber}px;
  }
`

const StyledBulkDialogResultInfoContainer = styled(VStack)`
  align-items: center;
  position: relative;
  width: 100%;
`

interface ResultInfoSectionProps {
  title: string
  list: string[]
}

const StyledResultInfoSectionContainer = styled(VStack)`
  width: 100%;
`

const StyledResultInfoSectionList = styled.ul`
  list-style-type: initial;
  margin-inline-start: ${props => props.theme.spacing.cozy * 2.375}px;
`

function ResultInfoSection(props: ResultInfoSectionProps) {
  const { title, list } = props

  return (
    <StyledResultInfoSectionContainer $spacingNumber={1}>
      <Label as="p" $textStyle="h5Semibold">
        {title}
      </Label>
      <StyledResultInfoSectionList>
        {list.map(listItem => (
          <Label as="li" $textStyle="h5Regular" key={listItem}>
            {listItem}
          </Label>
        ))}
      </StyledResultInfoSectionList>
    </StyledResultInfoSectionContainer>
  )
}

export function BulkDialogResultInfo() {
  const { errors, operations } = useDialogState()

  const sections = React.useMemo(() => {
    const sectionsData: ResultInfoSectionProps[] = []

    const productsNotAvailable = filter(errors, {
      type: BulkOrderingResultErrorType.PRODUCT_NOT_AVAILABLE,
    })

    if (!isEmpty(productsNotAvailable)) {
      sectionsData.push({
        title: 'No está publicado o no existe en el catálogo',
        list: map(productsNotAvailable, 'data.sku'),
      })
    }

    const productsWithoutStock = filter(errors, {
      type: BulkOrderingResultErrorType.PRODUCT_WITHOUT_STOCK,
    })

    if (!isEmpty(productsWithoutStock)) {
      sectionsData.push({
        title: 'No se agregaron por falta de stock',
        list: map(productsWithoutStock, 'data.sku'),
      })
    }

    const validatedProductsWithMinimumSalesQuantityRule = filter(errors, {
      type: BulkOrderingResultErrorType.PRODUCT_VALIDATED_WITH_MINIMUM_SALES_QUANTITY_RULE,
    })

    if (!isEmpty(validatedProductsWithMinimumSalesQuantityRule)) {
      sectionsData.push({
        title: 'No se agregaron unidades por la cantidad mínima de compra',
        list: map(validatedProductsWithMinimumSalesQuantityRule, 'data.sku'),
      })
    }

    const operationsWithAffectedQuantityByMaximumStockQuantity = filter(operations, {
      data: {
        appliedMaximumStockQuantityRule: true,
      },
    })

    if (!isEmpty(operationsWithAffectedQuantityByMaximumStockQuantity)) {
      sectionsData.push({
        title: 'Se agregaron menos unidades por falta de stock',
        list: map(
          operationsWithAffectedQuantityByMaximumStockQuantity,
          operation => `${operation.data.sku} (se agregaron ${operation.data.appliedQuantity})`,
        ),
      })
    }

    const operationsWithAffectedQuantityByMaximumSalesQuantity = filter(operations, {
      data: {
        appliedMaximumSalesQuantityRule: true,
      },
    })

    if (!isEmpty(operationsWithAffectedQuantityByMaximumSalesQuantity)) {
      sectionsData.push({
        title: 'Se agregaron menos unidades por la cantidad máxima de compra',
        list: map(
          operationsWithAffectedQuantityByMaximumSalesQuantity,
          operation => `${operation.data.sku} (se agregaron ${operation.data.appliedQuantity})`,
        ),
      })
    }

    return sectionsData
  }, [errors, operations])

  if (isEmpty(sections)) {
    return null
  }

  return (
    <StyledBulkDialogResultInfoContainer $spacingNumber={1.5}>
      {sections.map(section => (
        <ResultInfoSection key={section.title} title={section.title} list={section.list} />
      ))}
    </StyledBulkDialogResultInfoContainer>
  )
}
