import gql from 'graphql-tag'

import { partialProductFragment, productPhotoFragment } from '../fragments'

const manufacturerProductQuery = gql`
  query partialProduct($slug: String!) {
    manufacturer {
      id
      product(slug: $slug) {
        ...partialProduct
        photos {
          ...productPhoto
        }
        mainVariant {
          description
          category {
            name
            parent {
              name
            }
          }
        }
        variants {
          ...partialProduct
          photos {
            ...productPhoto
          }
        }
      }
    }
  }

  ${productPhotoFragment}
  ${partialProductFragment}
`

export default manufacturerProductQuery
