import theme from '~/truck/theme'

export const headerHeights = {
  categories: 40,
  checkout: 48,
  contact: 32,
  search: 80,
  announcementBar: 40,
  getMainMobile() {
    return 56
  },
  getMainDesktop() {
    return headerHeights.contact + headerHeights.search + headerHeights.categories
  },
}

export const dimensions = {
  cartWidth: 360,
}

export const stack = {
  cartButton: 2,
  mapMarker: 1,
  addressSuggestions: 2,
  modalCloseButton: 2,
  cart: 21,
  categoriesMenuOverlay: 25,
  categoriesMenu: 26,
  checkoutFooter: 10,
  arrowSlider: 10,
  mainHeader: 20,
  dropdownMenu: 22,
  cookiesBanner: 25,
  tieredPricingPopper: 21,
  announcementBar: 27,
  vendorInfo: 27,
  suggestedProducModal: 99,
  bottomSheetStandalone: 99,
}

const defaultLayoutHeight = props => headerHeights.getMainDesktop(props) + theme.spacing.cozy * 4

const checkoutLayoutHeight = headerHeights.checkout + theme.spacing.comfortable

export const viewportHeights = {
  checkout: `calc(100vh - ${checkoutLayoutHeight}px)`,
  getDefaultLayout: props => `calc(100vh - ${defaultLayoutHeight(props)}px)`,
  getMainDesktop: props => `calc(100vh - ${headerHeights.getMainDesktop(props)}px)`,
  getMainMobile: props => `calc(100vh - ${headerHeights.getMainMobile(props)}px)`,
}
