import React from 'react'

import { Div, Img } from './elements'

interface CommonProps {
  src: string
  height?: number | string
  width?: number | string
  size?: string
}

type ImageProps =
  | (React.ComponentPropsWithoutRef<'div'> & {
      flavor?: 'div'
    } & CommonProps)
  | (React.ComponentPropsWithoutRef<'img'> & {
      flavor?: 'img'
    } & CommonProps)

function Image({ flavor = 'img', src, height, width, size, ...restProps }: ImageProps) {
  if (flavor === 'div') {
    return <Div $src={src} $height={height} $width={width} $size={size} {...restProps} />
  }

  return <Img alt="" src={src} height={height} width={width} {...restProps} />
}

export default Image
