import type { IVendor } from '~/types/vendors'

export function getBasicEventPayloadFromVendor(vendor: IVendor) {
  const payload: Record<string, any> = {
    vendorId: vendor.originalId,
    vendorName: vendor.name,
  }

  if (vendor.isPartnerVendor) {
    payload.vendorId = vendor.manufacturer.originalId

    payload.vendorName = vendor.manufacturer.name

    payload.partnerVendorId = vendor.originalId

    payload.partnerVendorName = vendor.name
  }

  return payload
}
