import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import theme from '~/truck/theme'

export const InnerPanelContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.comfortable}px;
`

export const TextGroupsContainer = styled.div`
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: ${theme.spacing.comfortable}px;
  }

  ${up('lg')} {
    flex-direction: row;

    > *:not(:last-child) {
      margin-right: ${theme.spacing.cozy * 6}px;
    }
  }
`

export const StyledContactPersonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.compact}px;
  margin-top: ${theme.spacing.comfortable}px;
`
