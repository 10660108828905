import React from 'react'
import styled from 'styled-components'

import Image from '~/truck/image'
import Label from '~/truck/label'
import theme from '~/truck/theme'
import { cloudinary } from '~/utils'

interface EmptyStateProps {
  onCreateClick: () => void
  actionLabel?: string
  disableAddAction?: boolean
  className?: string
}

const Container = styled.div`
  background-color: ${theme.colors.grayLight};
  border-radius: ${theme.spacing.compact}px;
  margin: ${theme.spacing.comfortable}px;
  padding: ${theme.spacing.comfortable}px;
  text-align: center;

  > span {
    cursor: pointer;
  }

  > :not(:last-child) {
    margin-bottom: ${theme.spacing.comfortable}px;
  }
`

const url = cloudinary.getFullUrl('v1580503217/sellers/resources/map.svg')

const imageUrl = cloudinary.applyTransformations(url)

function EmptyState(props: EmptyStateProps) {
  const { onCreateClick, disableAddAction, actionLabel, className } = props

  return (
    <Container className={className}>
      <Image src={imageUrl} />
      <Label as="p">Aún no tienes direcciones.</Label>
      {!disableAddAction && (
        <Label as="span" $color="info" onClick={onCreateClick} $textStyle="h5Semibold">
          {actionLabel ?? 'Agregar dirección'}
        </Label>
      )}
    </Container>
  )
}

export default EmptyState
