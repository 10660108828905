import gql from 'graphql-tag'

import storeFragment from '../fragments/store'

const activeStoresQuery = gql`
  query activeStoresQuery($onlyPickup: Boolean) {
    manufacturer {
      id
      distributors {
        id
        endpoint
        activeStores(onlyPickup: $onlyPickup) {
          ...store
          address {
            id
            line
            coordinates {
              latitude
              longitude
            }
            location {
              id
              name
              originalId
              parentId
              division
            }
          }
        }
      }
    }
  }
  ${storeFragment}
`

export default activeStoresQuery
