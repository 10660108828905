import type React from 'react'

import useIsMobile from '~/hooks/use-is-mobile'

type Renderable = React.ReactNode | (() => React.ReactNode)

export interface MediaProps {
  mobile?: Renderable
  desktop?: Renderable
}

function passOrCall(renderable: Renderable) {
  return typeof renderable === 'function' ? renderable() : renderable
}

function Media(props: MediaProps) {
  const { mobile = null, desktop = null } = props

  const isMobile = useIsMobile()

  return passOrCall(isMobile ? mobile : desktop)
}

export default Media
