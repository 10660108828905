import * as React from 'react'
import { clsx } from 'clsx'
import cookie from 'isomorphic-cookie'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useFlag } from 'toggled'

import Logo from '~/components/logo'
import ShippingSelector from '~/components/shipping-selector'
import { useUser, useVendor } from '~/hooks'
import { OpenCartButton } from '~/modules/shopping-cart/containers/open-cart-button/OpenCartButton'
import Anchor from '~/truck/anchor'
import Icon from '~/truck/icon'
import theme from '~/truck/theme'
import { forceReloadTo } from '~/utils'
import { cookieNames, flags } from '~/utils/constants'

import SearchBar from '../search-bar'

import OptionsDropdown from './OptionsDropdown'
import UserDropdown from './UserDropdown'

interface SearchHeaderProps {
  className?: string
}

function DesktopSearchHeader({ className }: SearchHeaderProps) {
  const router = useRouter()

  const user = useUser()

  const vendor = useVendor()

  const hasStoreNavigatorFF = useFlag(flags.STORE_NAVIGATION)

  const hasListStoresFF = useFlag(flags.LIST_STORES)

  const hasPartnersFF = useFlag(flags.MULTI_VENDOR_NAVIGATION)

  const hasDisableCoverageHeaderFF = useFlag(flags.DISABLE_COVERAGE_HEADER)

  const showCartInMainLayoutFF = useFlag(flags.SHOW_CART_IN_MAIN_LAYOUT)

  const [isOptionsDropdownMenuVisible, setIsOptionsDropdownMenuVisible] = React.useState(false)

  const [isUserDropdownMenuVisible, setIsUserDropdownMenuVisible] = React.useState(false)

  const onRedirectToPartners = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()

    cookie.remove(cookieNames.PARTNER_VENDOR_ENDPOINT, {})

    forceReloadTo('/distribuidores')
  }

  const hidePartnerVendorLink = vendor.manufacturer.hasOnePartner

  return (
    <div className={clsx('flex h-20 items-center gap-4 bg-brand-primary px-4 text-brand-primary-on-bg', className)}>
      <Logo vendor={vendor} size="large" />
      <SearchBar.Desktop
        className="flex-1"
        rightItem={<Icon type="search" color="black" />}
        debounced={!vendor.settings?.disableInstantSearch}
      />
      <nav className="flex items-center gap-4">
        {vendor.beetrackWidgetKey && (
          <Link href="/seguimiento" passHref>
            <div className="flex cursor-pointer items-center gap-2">
              <Icon type="tracking" color="currentColor" />
              <Anchor $color="currentColor">Seguir mi pedido</Anchor>
            </div>
          </Link>
        )}
        <ShippingSelector style={{ alignSelf: 'center', maxWidth: theme.spacing.cozy * 25 }} />
        {hasListStoresFF && !hasStoreNavigatorFF && (
          <Link href="/tiendas" passHref>
            <Anchor $color="currentColor">Tiendas</Anchor>
          </Link>
        )}
        {hasPartnersFF && !hidePartnerVendorLink && (
          <Anchor $color="currentColor" href="/distribuidores" onClick={onRedirectToPartners}>
            Distribuidores
          </Anchor>
        )}
        {!hasDisableCoverageHeaderFF && (
          <Link href="/cobertura" passHref>
            <Anchor $color="currentColor">Cobertura</Anchor>
          </Link>
        )}
        <div
          className="relative flex items-center rounded bg-white/20 p-1"
          onMouseEnter={() => setIsOptionsDropdownMenuVisible(true)}
          onMouseLeave={() => setIsOptionsDropdownMenuVisible(false)}
          role="button"
        >
          <Icon type="archive" color="currentColor" />
          {isOptionsDropdownMenuVisible && <OptionsDropdown />}
        </div>
        <div
          className="relative flex items-center rounded bg-white/20 p-1"
          onMouseEnter={() => {
            if (user.isLoggedIn) {
              setIsUserDropdownMenuVisible(true)
            }
          }}
          onMouseLeave={() => {
            if (user.isLoggedIn) {
              setIsUserDropdownMenuVisible(false)
            }
          }}
          onClick={() => {
            if (!user.isLoggedIn) {
              router.push('/ingresar')
            }
          }}
          role="button"
        >
          <Icon type="profile" color="currentColor" />
          {isUserDropdownMenuVisible && <UserDropdown />}
        </div>
      </nav>
      {!showCartInMainLayoutFF && <OpenCartButton />}
    </div>
  )
}

export default DesktopSearchHeader
