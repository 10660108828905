import * as React from 'react'
import clsx from 'clsx'
import { isNull } from 'lodash'
import { useFlagQueryFn } from 'toggled'

import { useModalManager } from '~/hooks'
import useVendor from '~/hooks/use-vendor'
import { CartDrawer } from '~/modules/shopping-cart/containers/cart-drawer/CartDrawer'
import { getParameterValue } from '~/utils'
import { flagQueries } from '~/utils/constants'

import ShippingModal from '../shipping-modal'

import { AnnouncementBar } from './announcement-bar'
import DesktopCategoryHeader from './desktop-category-header'
import { DesktopContactHeader } from './desktop-contact-header'
import DesktopSearchHeader from './desktop-search-header'

function Desktop() {
  const vendor = useVendor()

  const flagQueryFn = useFlagQueryFn()

  const announcementBarSettings = vendor?.settings?.announcement

  const modalManager = useModalManager()

  React.useEffect(() => {
    const openShippingModal = getParameterValue('open-shipping-modal', window.location.href)

    if (!isNull(openShippingModal)) {
      modalManager.openModal(ShippingModal)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isFullWidth = flagQueryFn(flagQueries.HAS_FULL_WIDTH)

  // FIXME: update z-index (mainHeader, mainHeader + 2, vendorInfo)
  return (
    <>
      {announcementBarSettings && (
        <AnnouncementBar
          className="z-[27]"
          message={announcementBarSettings?.message}
          link={announcementBarSettings?.link}
          shouldOpenInNewTab={announcementBarSettings?.shouldOpenInNewTab}
          foregroundColor={announcementBarSettings?.foregroundColor}
          backgroundColor={announcementBarSettings?.backgroundColor}
        />
      )}
      <header className={clsx('sticky inset-x-0 top-0 flex flex-col', isFullWidth ? 'z-20' : 'z-[22]')}>
        <DesktopContactHeader className="z-[27]" />
        <DesktopSearchHeader className="z-[27]" />
        <DesktopCategoryHeader />
      </header>
      <CartDrawer />
    </>
  )
}

export default Desktop
