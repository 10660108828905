import * as React from 'react'
import styled from 'styled-components'

import Icon from '~/truck/icon'

export interface BulkDialogCloseButtonProps {
  onClick: () => void
}

const StyledCloseIcon = styled(Icon)`
  display: block;
  margin-right: auto;
  position: relative;
`

export function BulkDialogCloseButton(props: BulkDialogCloseButtonProps) {
  const { onClick } = props

  return <StyledCloseIcon role="button" type="close" color="grayscale60" onClick={() => onClick()} />
}
