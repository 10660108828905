import type TruckTheme from '~/types/theme'

import theme from '../theme'

import makeThemeGetters from './make-theme-getters'

type ThemeGetters = {
  [K in keyof TruckTheme]: (propKey: keyof TruckTheme[K]) => TruckTheme[K][keyof TruckTheme[K]]
}

export default makeThemeGetters(theme) as ThemeGetters
