import styled from 'styled-components'

import { default as BaseOverlay } from '~/truck/overlay'
import theme from '~/truck/theme'

const getColorStyle = (background: string, color?: string) => props => {
  const {
    theme: { colors },
  } = props

  const borderColors = {
    primary: colors.primaryDark,
    primaryDark: colors.primary,
    secondary: colors.secondaryDark,
    secondaryDark: colors.secondary,
    zumthor: colors.gray,
  }

  return `
    background: ${colors[background]};
    border: 1px solid ${borderColors[background]};
    color: ${colors[color] ?? colors.white};
  `
}

export const Overlay = styled(BaseOverlay)`
  .CalendarDay__selected {
    ${getColorStyle('secondary')}
  }
  .CalendarDay__selected:active {
    ${getColorStyle('secondary')}
  }
  .CalendarDay__selected:hover {
    ${getColorStyle('secondaryDark')}
  }
  .CalendarDay__selected_span {
    ${getColorStyle('primary')}
  }
  .CalendarDay__selected_span:hover {
    ${getColorStyle('primaryDark')}
  }
  .CalendarDay__hovered_span {
    ${getColorStyle('zumthor', 'black')}
  }
  .CalendarDay__hovered_span:hover {
    ${getColorStyle('primary')}
  }
  .CalendarDay__default:hover {
    border: none;
  }
`

export const Container = styled.div`
  background-color: transparent;
  display: flex;
  height: ${theme.spacing.cozy * 40}px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  > :first-child {
    height: fit-content;
  }
`
