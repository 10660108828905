import gql from 'graphql-tag'

const distributorInAttentionQuery = gql`
  query distributorInAttention($coordinates: CoordinatesInput!) {
    distributorInAttention(coordinates: $coordinates) {
      endpoint
      storeId
    }
  }
`

export default distributorInAttentionQuery
