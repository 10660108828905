import * as React from 'react'
import { ceil, clamp, floor, isNil, isNumber } from 'lodash'

export interface UseCounterOptions {
  value?: number
  defaultValue?: number
  allowZeroToEliminate: boolean
  jumpFactor: number
  canJumpAtLeastOnce?: boolean
  minValue?: number
  maxValue?: number
  minMessage?: string
  maxMessage?: string
  onChange?: (value: number) => void
  onError?: (error: string) => void
}

interface UseCounterState {
  value: number
  displayValue: number
  isMinValue?: boolean
  error: string | null
}

interface IRangeLimits {
  minValue: number
  maxValue: number
}

const MAX_DEFAULT_MESSAGE = 'Máximo {value}'

const MIN_DEFAULT_MESSAGE = 'Mínimo {value}'

const initialZeroState: UseCounterState = {
  value: 0,
  displayValue: 0,
  error: null,
}

export function getQuantityRange(options: UseCounterOptions): IRangeLimits {
  const { minValue, maxValue, jumpFactor } = options

  return {
    minValue: minValue ? ceil(minValue / jumpFactor) * jumpFactor : 1,
    maxValue: maxValue ? floor(maxValue / jumpFactor) * jumpFactor : Number.MAX_SAFE_INTEGER,
  }
}

function minValidValue(value: number, options: UseCounterOptions, rangeLimits: IRangeLimits) {
  const { jumpFactor } = options

  const clampedValue = clamp(value, rangeLimits.minValue, rangeLimits.maxValue)

  return ceil(clampedValue / jumpFactor) * jumpFactor
}

/**
 * Custom hook to notify to the parent component when the
 * state has changed with a valid value
 */
function useNotifyChange(props: UseCounterOptions, state: UseCounterState) {
  React.useEffect(() => {
    const { value: propValue, onChange } = props

    const { value: stateValue } = state

    const hasChanged = propValue !== stateValue

    if (hasChanged && onChange) {
      onChange(state.value)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.value])
}

/**
 * Custom hook to notify to the parent component when
 * the error message has changed
 */
function useNotifyError(props: UseCounterOptions, state: UseCounterState) {
  React.useEffect(() => {
    const { onError } = props

    if (onError) {
      onError(state.error)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.error])
}

/**
 * Custom hook to update the internal state when the parent
 * component send a new `value` as prop
 */
const useUpdateValue = (props: UseCounterOptions, state: UseCounterState, setValue: (value: number) => void) => {
  const { value: propValue } = props

  const stateValueRef = React.useRef<number>()

  stateValueRef.current = state.value

  React.useEffect(() => {
    if (propValue !== stateValueRef.current) {
      setValue(propValue)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propValue])
}

function useCounter(options: UseCounterOptions): {
  state: UseCounterState
  handlers: {
    setValue: (value: number, skipQuantityRulesForDisplayValue?: boolean) => void
    increment: () => void
    decrement: () => void
  }
} {
  const { jumpFactor, value, defaultValue, allowZeroToEliminate } = options

  const rangeLimits = getQuantityRange(options)

  const initialValue = value ? minValidValue(value, options, rangeLimits) : value

  const initialDefaultValue = defaultValue ? minValidValue(defaultValue, options, rangeLimits) : defaultValue

  const [state, setState] = React.useState<UseCounterState>(() => ({
    error: null,
    value: initialValue ?? initialDefaultValue ?? 0,
    displayValue: initialValue ?? initialDefaultValue ?? 0,
  }))

  const getError = (newValue: number) => {
    if (newValue >= rangeLimits.maxValue) {
      return (options.maxMessage || MAX_DEFAULT_MESSAGE).replace('{value}', rangeLimits.maxValue.toString())
    }

    const { minValue: originalMinValue } = options

    if (!isNil(originalMinValue) && newValue <= rangeLimits.minValue) {
      return (options.minMessage || MIN_DEFAULT_MESSAGE).replace('{value}', rangeLimits.minValue.toString())
    }

    return null
  }

  const calculateState = (
    proposalValue: number,
    skipQuantityRulesForDisplayValue = false,
    isDecrement = false,
  ): UseCounterState => {
    if (allowZeroToEliminate && isDecrement && proposalValue < rangeLimits.minValue) {
      return initialZeroState
    }

    if (allowZeroToEliminate && proposalValue === 0) {
      return initialZeroState
    }

    let value = clamp(proposalValue, rangeLimits.minValue, rangeLimits.maxValue)

    let displayValue = clamp(proposalValue, rangeLimits.minValue, rangeLimits.maxValue)

    if (value % jumpFactor !== 0) {
      value = ceil(value / jumpFactor) * jumpFactor

      displayValue = ceil(proposalValue / jumpFactor) * jumpFactor
    }

    return {
      value,
      displayValue: skipQuantityRulesForDisplayValue ? proposalValue : displayValue,
      error: getError(proposalValue),
    }
  }

  const setValue = (newValue: number, skipQuantityRulesForDisplayValue?: boolean) => {
    if (isNumber(newValue)) {
      setState(calculateState(newValue, skipQuantityRulesForDisplayValue))
    }
  }

  const increment = () => {
    setState(currentState => {
      return calculateState(currentState.value + jumpFactor)
    })
  }

  const decrement = () => {
    setState(currentState => {
      return calculateState(currentState.value - jumpFactor, false, true)
    })
  }

  useNotifyError(options, state)

  useNotifyChange(options, state)

  useUpdateValue(options, state, setValue)

  const handlers = {
    setValue,
    increment,
    decrement,
  }

  // Calculates the value if decremented
  const nextDecrementValue = calculateState(state.value - jumpFactor, false, true).value

  // If the next decremented value is 0 and the current value is different from 0,
  // then it is the minimum value.
  const isMinValue = state.value !== 0 && nextDecrementValue === 0

  return { state: { ...state, isMinValue }, handlers }
}

export default useCounter
