import nookies, { destroyCookie, setCookie } from 'nookies'

// TODO: Use a single decodeURIComponent call when isomorphic-cookie is removed

const decode = (cookieValue: string) => JSON.parse(decodeURIComponent(decodeURIComponent(cookieValue)))

export function parseCookies(...[ctx, options]: Parameters<typeof nookies.get>) {
  return nookies.get(ctx, { ...options, decode }) as Record<string, unknown>
}

export { destroyCookie, setCookie }
