import * as React from 'react'

import { formatCreditCardNumber } from '~/components/payment-methods/utils'
import Field from '~/truck/field'

interface PaymentCardNumberProps {
  id?: string
  name?: string
  placeholder?: string
  format?: any
  maxLength?: number
  [key: string]: any
}

const DEFAULT_MAX_LENGTH = 16

export default function PaymentCardNumber(props: PaymentCardNumberProps) {
  const { id, name, placeholder, format, maxLength, ...rest } = props

  // We add 3 to the provided/default length because it's needed for formatting the card number. (e.g.: 4111 1111 1111 1111)
  const cardLength = (maxLength ?? DEFAULT_MAX_LENGTH) + 3

  return (
    <Field
      id={id ?? 'cardNumber'}
      name={name ?? 'cardNumber'}
      placeholder={placeholder ?? 'Número de tarjeta'}
      format={format ?? formatCreditCardNumber}
      maxLength={cardLength}
      {...rest}
    />
  )
}
