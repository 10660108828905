import * as React from 'react'

import Input from '~/truck/input'

import SearchBar from './index.base'

interface DesktopProps {
  debounced?: boolean
  [key: string]: unknown
}

function Desktop(props: DesktopProps) {
  const { debounced, ...rest } = props

  return (
    <SearchBar debounced={debounced}>
      {metaProps => {
        return <Input placeholder="¿Qué producto estás buscando?" type="search" {...metaProps} {...rest} />
      }}
    </SearchBar>
  )
}

Desktop.displayName = 'SearchBar.Desktop'

export default Desktop
