import React from 'react'
import { useTheme } from 'styled-components'

import Label from '~/truck/label'
import type { IOrder } from '~/types/orders'

import Panel from '../panel'

type CommentProps = {
  order: IOrder
}

const Comments = (props: CommentProps) => {
  const { order } = props

  const theme = useTheme()

  if (!order.comments) {
    return null
  }

  return (
    <Panel title="Comentarios" headerStyle={{ marginBottom: `${theme.spacing.comfortable}px` }}>
      <Label
        as="p"
        $textStyle="h5Regular"
        style={{
          padding: `0 ${theme.spacing.comfortable}px`,
          marginBottom: `${theme.spacing.cozy}px`,
          whiteSpace: 'pre-line',
        }}
      >
        {order.comments}
      </Label>
    </Panel>
  )
}

export default Comments
