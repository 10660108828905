import type React from 'react'
import { darken } from 'polished'
import styled, { css, ThemedStyledProps } from 'styled-components'

import { getColor, getTextStyle, getTheme } from '~/truck/utils'
import type TruckTheme from '~/types/theme'
import type { ThemeColor } from '~/types/theme'

type BaseProps = React.ComponentProps<'button'> & {
  $transparent?: boolean
  $plain?: boolean
  $ghost?: boolean
  $circular?: boolean
  $loading?: boolean
  $color?: ThemeColor
}

function resolveTextStyles(props: ThemedStyledProps<BaseProps, any>) {
  const theme = getTheme(props)

  return getTextStyle(theme.textStyles.h5Semibold) as (props: ThemedStyledProps<BaseProps, any>) => string
}

function resolveColor(props: BaseProps, theme: TruckTheme) {
  const color = getColor(theme, props.$color ?? 'primary')

  return {
    color,
    darkColor: darken(theme.factors.darken, color),
  }
}

export function getThemeValues(props: ThemedStyledProps<BaseProps, any>) {
  const theme = getTheme(props)

  const { white, grayLight, gray, blackLight } = theme.colors

  const height = theme.dimensions.buttonHeight

  return {
    white,
    height,
    gray,
    grayLight,
    blackLight,
    ...resolveColor(props, theme),
  }
}

function getDisabledBorder(props: BaseProps) {
  const { $plain, $transparent } = props

  if ($plain || $transparent) {
    return 'unset'
  }

  return '1 px solid'
}

function getDisabledBackground(props: ThemedStyledProps<BaseProps, any>) {
  const { $transparent } = props

  const { grayLight } = getThemeValues(props)

  if ($transparent) {
    return 'unset'
  }

  return grayLight
}

function getDisabledStyles(props: ThemedStyledProps<BaseProps, any>) {
  const { gray, blackLight } = getThemeValues(props)

  const { disabled } = props

  if (!disabled) {
    return ''
  }

  return css`
    background: ${getDisabledBackground};
    border: ${getDisabledBorder};
    border-color: ${gray};
    color: ${blackLight};

    > svg {
      cursor: not-allowed;
      fill: ${gray};
    }
  `
}

function getHoverStyles(props: ThemedStyledProps<BaseProps, any>) {
  const { disabled, $loading, $ghost, $plain, $transparent } = props

  const { color, darkColor, white } = getThemeValues(props)

  let hoverContentColor = white

  let hoverBgColor = darkColor

  if (disabled || $loading) {
    return 'cursor: not-allowed;'
  }

  if ($plain) {
    return ''
  }

  if ($ghost) {
    hoverBgColor = color
  }

  if ($transparent) {
    hoverBgColor = 'transparent'
    hoverContentColor = darkColor
  }

  return css`
    :hover {
      background: ${hoverBgColor};
      color: ${hoverContentColor};

      > svg {
        fill: ${hoverContentColor};
      }
    }
  `
}

function getGhostStyles(props: ThemedStyledProps<BaseProps, any>) {
  const { $ghost } = props

  const { white, color } = getThemeValues(props)

  if (!$ghost) {
    return ''
  }

  return css`
    background: ${white};
    border: 1px solid ${color};
    color: ${color};

    > svg {
      fill: ${color};
    }
  `
}

function getNormalStyles(props: ThemedStyledProps<BaseProps, any>) {
  const { white, color } = getThemeValues(props)

  const theme = getTheme(props)

  // padding comfortable * 2
  const xPadding = theme.spacing.cozy * 4

  const loaderWidth = 48 // 12 * 4

  return css`
    background: ${color};
    border: 1px solid ${color};
    color: ${white};
    min-width: ${loaderWidth + xPadding}px;
    padding: 0 ${theme.spacing.comfortable}px;
  `
}

function getCircularStyles(props: ThemedStyledProps<BaseProps, any>) {
  const { $circular } = props

  const { height } = getThemeValues(props)

  if (!$circular) {
    return ''
  }

  return css`
    border-radius: 50%;
    min-width: auto;
    padding: 0;
    width: ${height}px;
  `
}

function getPlainStyles(props: ThemedStyledProps<BaseProps, any>) {
  const { $plain } = props

  const { color, white } = getThemeValues(props)

  if (!$plain) {
    return ''
  }

  return css`
    background: ${white};
    border: none;
    border-radius: unset;
    color: ${color};
  `
}

function getTransparentStyles(props: ThemedStyledProps<BaseProps, any>) {
  const { $transparent } = props

  if (!$transparent) {
    return ''
  }

  const { color } = getThemeValues(props)

  return css`
    background-color: transparent;
    border: none;
    border-radius: unset;
    color: ${color};
    > svg {
      fill: ${color};
    }
  `
}

export const Base = styled.button<BaseProps>`
  align-items: center;
  border: none;
  border-radius: ${props => getTheme(props).dimensions.borderRadius}px;
  cursor: pointer;
  display: inline-flex;
  height: ${props => getTheme(props).dimensions.buttonHeight}px;
  justify-content: center;
  outline: none;
  position: relative;
  transition: background 0.1s ease-out;
  vertical-align: middle;
  width: 100%;

  > * {
    cursor: inherit;
  }

  > svg + span {
    margin-left: ${props => getTheme(props).spacing.cozy}px;
  }

  ${resolveTextStyles}
  ${getNormalStyles}
  ${getGhostStyles}
  ${getHoverStyles}
  ${getCircularStyles}
  ${getPlainStyles}
  ${getTransparentStyles}
  ${getDisabledStyles}
`
