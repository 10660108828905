import gql from 'graphql-tag'

const deliveryAttentionQuery = gql`
  query VendorDeliveryAttention($shippingTypeId: ID!, $coordinates: CoordinatesInput!) {
    vendor {
      id
      deliveryAttention(shippingTypeId: $shippingTypeId, coordinates: $coordinates) {
        coverageIsAvailable
      }
    }
  }
`

export default deliveryAttentionQuery
