import React from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { useFlag } from 'toggled'

import { Tooltip } from '~/components'
import Price from '~/components/price'
import { useVendor } from '~/hooks'
import Icon from '~/truck/icon'
import { cartMinimumOrderTooltip, flags } from '~/utils/constants'

import { Container } from './elements'

const StyledIcon = styled(Icon)`
  margin-left: ${props => props.theme.spacing.compact * 1.5}px;
`

const minimumPropertyTooltipMap = {
  products: cartMinimumOrderTooltip.PRODUCTS,
  subtotal: cartMinimumOrderTooltip.SUBTOTAL,
  total: cartMinimumOrderTooltip.TOTAL,
}

const Header = props => {
  const { numOfProducts, minimumOrder, canReturn, className, style } = props

  const router = useRouter()

  const vendor = useVendor()

  const minimumOrderProperty = vendor.settings?.cart?.minimumOrderProperty || 'products'

  const toolTipContent = minimumPropertyTooltipMap[minimumOrderProperty]

  const hidePricing = useFlag(flags.HIDE_PRICES)

  const showMinimumOrder = !hidePricing && Number(minimumOrder) !== 0

  return (
    <Container style={style} className={className}>
      {canReturn && <Icon type="arrow-left" color="black" onClick={() => router.back()} />}
      <span className="font-semibold">{`${numOfProducts} productos`}</span>
      {showMinimumOrder && (
        <div>
          <span>mínimo &nbsp;</span>
          <Price value={minimumOrder} color="black" textStyle="h5Regular" />
          <Tooltip content={toolTipContent}>
            <StyledIcon type="info" color="black" />
          </Tooltip>
        </div>
      )}
    </Container>
  )
}

export default Header
