import * as React from 'react'

import Slider from './slider'
import Tree from './tree'

function DesktopCategoryHeader() {
  return (
    <div className="relative flex h-10 w-full bg-white">
      <Tree className="z-[26] shrink-0" />
      <Slider className="grow" />
    </div>
  )
}

export default DesktopCategoryHeader
