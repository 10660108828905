import * as React from 'react'

import Field from '~/truck/field'

interface PaymentOwnerNameProps {
  id?: string
  name?: string
  placeholder?: string
  [key: string]: any
}

export default function PaymentOwnerName(props: PaymentOwnerNameProps) {
  const { id, name, placeholder, ...rest } = props

  return <Field name={name ?? 'ownerName'} placeholder={placeholder ?? 'Nombre del titular'} {...rest} />
}
