import React from 'react'
import { isString } from 'lodash'

import Label from '~/truck/label'
import type { ThemeColor } from '~/types/theme'

import { Container, Input } from './elements'

export type RadioProps = React.ComponentPropsWithoutRef<'input'> & {
  id: string
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
  color?: ThemeColor
}

// eslint-disable-next-line react/prop-types
function Radio({ id, children, className, style, disabled, ...inputProps }: RadioProps) {
  return (
    <Container className={className} style={style} $disabled={disabled} $hasText={isString(children)}>
      <Input id={id} disabled={disabled} {...inputProps} />
      <Label htmlFor={id}>{children}</Label>
    </Container>
  )
}

export default Radio
