import React from 'react'
import { Field as FinalFormField, useForm } from 'react-final-form'
import { filter, find, isEmpty, map } from 'lodash'
import styled from 'styled-components'

import AvailableCredit from '~/components/available-credit'
import Title from '~/components/title'
import Wysiwyg from '~/components/wysiwyg'
import { useCalculateCartPricing, useFieldValue } from '~/hooks'
import FieldSubscription from '~/truck/field-subscription'
import Image from '~/truck/image'
import Label from '~/truck/label'
import Loader from '~/truck/loader'
import RadioGroup from '~/truck/radio-group'
import theme from '~/truck/theme'
import { handleSubmissionErrors } from '~/utils'
import { getPaymentMethodMinimumAmount } from '~/utils'
import cloudinary from '~/utils/cloudinary'
import { paymentMethods, shippingTypes, transformations } from '~/utils/constants'

import { InfoStatusBox } from '../info-status-box'

import { PendingPaymentNotice } from './components'
import CulqiForm from './culqi-form'
import { CulqiYapeForm } from './culqi-yape-form'
import OpenpayForm from './openpay-form'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.comfortable}px;
  padding-bottom: 0;

  & > * {
    padding-bottom: ${props => props.theme.spacing.cozy * 1.5}px;
    padding-top: ${props => props.theme.spacing.cozy * 1.5}px;

    &:not(:last-child) {
      border-bottom: 1px solid ${props => props.theme.colors.grayLight};
    }
  }
`

const StyledAvailableCredit = styled(AvailableCredit)`
  background-color: ${props => props.theme.colors.grayLight};
  padding: ${theme.spacing.comfortable}px;
`

const MethodContainer = styled.div`
  background: ${theme.colors.grayLight};
  padding: ${theme.spacing.relaxed}px ${theme.spacing.cozy * 4}px;
`

const LayoutDescription = props => {
  const { isLoading, error, children } = props

  if (isLoading) {
    return <Loader centered />
  }

  if (error) {
    return <InfoStatusBox description={error} />
  }

  return children
}

const PaymentMethods = props => {
  const { order, deferring, title, style, className } = props

  let { dataSource } = props

  const isExpressDelivery = order.shippingType.slug === shippingTypes.EXPRESS_DELIVERY

  if (isExpressDelivery) {
    dataSource = filter(dataSource, method => !method.isDeferred)
  }

  const form = useForm()

  const paymentMethodId = useFieldValue('paymentMethodId')

  const { calculateAndSetCartPricing } = useCalculateCartPricing()

  // This Ref stores the last paymentMethodId used in a promise.
  const paymentMethodIdGetting = React.useRef(null)

  const updateCartPrice = async () => {
    try {
      paymentMethodIdGetting.current = paymentMethodId

      await calculateAndSetCartPricing({ paymentMethodId }, () => paymentMethodIdGetting.current !== paymentMethodId)
    } catch (err) {
      if (err?.isCanceled) {
        return
      }

      handleSubmissionErrors(err)

      console.error(err)
    }
  }

  const onChangePaymentMethod = async () => {
    form.change('cardNumber', undefined)

    await updateCartPrice()
  }

  React.useEffect(() => {
    const executor = async () => {
      await onChangePaymentMethod()
    }

    executor()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethodId])

  const getPaymentMethod = id => {
    return find(dataSource, { id })
  }

  const renderPaymentMethod = methodId => {
    if (!methodId) {
      return null
    }

    const method = getPaymentMethod(methodId)

    const { slug, description } = method

    const Description = () => (
      <MethodContainer
        className="wysiwyg"
        ref={element => {
          if (element == null) return

          element.attachShadow({ mode: 'open' })

          element.shadowRoot.innerHTML = description
        }}
      />
    )

    const { ordersToBePaid, paymentMethodCurrentData = {} } = props

    const hasOrdersToBePaid = !isEmpty(ordersToBePaid)

    const { isLoading, error, ...paymentMethodCurrentDataRest } = paymentMethodCurrentData

    switch (slug) {
      case paymentMethods.LINE_OF_CREDIT:
        return (
          <LayoutDescription isLoading={isLoading} error={error}>
            <StyledAvailableCredit {...paymentMethodCurrentDataRest} />
          </LayoutDescription>
        )
      default:
        return (
          <>
            <LayoutDescription isLoading={isLoading} error={error}>
              {hasOrdersToBePaid && <PendingPaymentNotice ordersToBePaid={ordersToBePaid} />}
              {description && <Description />}
            </LayoutDescription>
          </>
        )
    }
  }

  const renderOptions = (slugFieldProps, idFieldProps) => {
    const onChange = async id => {
      const method = getPaymentMethod(id)

      idFieldProps.input.onChange(id)

      slugFieldProps.input.onChange(method.slug)
    }

    const transformation = transformations.PAYMENT_METHOD_LOGO

    const { meta } = idFieldProps

    const isCulqiJsCardSelected = slugFieldProps.input.value === paymentMethods.CULQI_JS_CARD

    const isCulqiJsYapeSelected = slugFieldProps.input.value === paymentMethods.CULQI_JS_YAPE

    const isOpenpaySelected = slugFieldProps.input.value === paymentMethods.OPENPAY

    const getOption = item => {
      const paymentMethodMinimum = getPaymentMethodMinimumAmount(item)

      const hasMinimumPaymentMethodValue = order.total >= paymentMethodMinimum

      const isSelected = slugFieldProps.input.value === item.slug

      const shouldShowMinimumValueMessage = !hasMinimumPaymentMethodValue && isSelected

      return (
        <>
          <div
            style={{
              display: 'flex',
              paddingLeft: theme.spacing.cozy,
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <div>
              {item.name}
              {item.image && (
                <Image
                  style={{ marginLeft: theme.spacing.cozy }}
                  height={theme.spacing.comfortable}
                  src={cloudinary.applyTransformations(item.image, transformation)}
                />
              )}
            </div>
            {shouldShowMinimumValueMessage && (
              <Label $color="error" $textStyle="h6Regular">
                Este método de pago requiere al menos S/. {paymentMethodMinimum} de compra.
              </Label>
            )}
          </div>
        </>
      )
    }

    const renderBaseOption = item => (
      <RadioGroup.Radio
        key={item.id}
        id={item.id}
        value={item.id}
        style={{ paddingLeft: 0 }}
        disabled={!item.isEnabled}
      >
        {getOption(item)}
      </RadioGroup.Radio>
    )

    const renderOption = item => {
      switch (item.slug) {
        case paymentMethods.CULQI_JS_CARD:
          return (
            <div key={item.id}>
              {renderBaseOption(item)}
              {isCulqiJsCardSelected && <CulqiForm paymentMethodId={item.id} />}
            </div>
          )

        case paymentMethods.CULQI_JS_YAPE:
          return (
            <div key={item.id}>
              {renderBaseOption(item)}
              {isCulqiJsYapeSelected && <CulqiYapeForm />}
            </div>
          )

        case paymentMethods.CULQI:
          return <div key={item.id}>{renderBaseOption(item)}</div>
        case paymentMethods.OPENPAY:
          return (
            <div key={item.id}>
              {renderBaseOption(item)}
              {isOpenpaySelected && <OpenpayForm paymentMethodId={item.id} />}
            </div>
          )

        default:
          return renderBaseOption(item)
      }
    }

    const children = map(dataSource, renderOption)

    return (
      <RadioGroup {...idFieldProps.input} onChange={onChange}>
        {children}
        {meta.error && meta.touched && (
          <Label $color="error" $textStyle="h6Regular">
            {meta.error}
          </Label>
        )}
      </RadioGroup>
    )
  }

  if (deferring) {
    return <Loader centered />
  }

  return (
    <>
      {title && <Title>{title}</Title>}
      <Content style={style} className={className}>
        <FinalFormField name="paymentMethodSlug">
          {fieldProps => {
            return (
              <FinalFormField name="paymentMethodId">{idProps => renderOptions(fieldProps, idProps)}</FinalFormField>
            )
          }}
        </FinalFormField>
        <Wysiwyg />
        <FieldSubscription name="paymentMethodId">{renderPaymentMethod}</FieldSubscription>
      </Content>
    </>
  )
}

export default PaymentMethods
