import styled from 'styled-components'

import theme from '~/truck/theme'

const Overlay = styled.div`
  background-color: ${theme.colors.white70};
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
`

export default Overlay
