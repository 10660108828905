import type React from 'react'
import styled from 'styled-components'

import theme from '~/truck/theme'

import { getTextStyle, getTheme } from '../utils'

type TextAreaProps = React.ComponentPropsWithoutRef<'textarea'> & {
  error?: any
}

function getBorderColor(props: TextAreaProps) {
  const { error } = props

  if (error) {
    return theme.colors.error
  }

  return theme.colors.gray
}

const TextArea = styled.textarea.attrs<TextAreaProps>(props => {
  const { spellCheck } = props

  return {
    spellCheck: spellCheck || false,
  }
})<TextAreaProps>`
  background: ${theme.colors.white};
  border-radius: ${theme.dimensions.borderRadius}px;
  border: 1px solid ${getBorderColor};
  color: ${theme.colors.black};
  outline: none;
  padding: ${theme.spacing.comfortable}px;
  transition: border 0.2s ease-in-out;
  width: 100%;
  height: ${theme.dimensions.inputHeight * 3}px;
  ${getTextStyle(theme.textStyles.h5Regular)};

  :disabled {
    background: ${theme.colors.grayLight};
    color: ${theme.colors.blackLight};
    cursor: not-allowed;
  }

  ${props =>
    !props.error &&
    `
    :focus {
      border-color: ${getTheme(props).colors.primary};
    }
  `};
`

export default TextArea
