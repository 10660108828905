import * as React from 'react'
import clsx from 'clsx'
import { last, map, split } from 'lodash'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { useMainDesktopHeight, useVendor } from '~/hooks'
import type { ICategory } from '~/types/category'
import { headerHeights } from '~/variables'

interface TreeCategoryProps {
  category: ICategory
  className?: string
}

function Subcategories({ category, className }: TreeCategoryProps) {
  const { encodedId, name, slug, subcategories } = category

  const router = useRouter()

  const subCategoryEncodedId = last(split(router.query.subcategory as string, '-'))

  const categoryPath = `${slug}-${encodedId}`

  const isCategorySelected = router.query.category === categoryPath

  const vendor = useVendor()

  const hasAnnouncement = vendor?.settings?.announcement

  // This is the measurement of the View All option and its surroundng paddings
  const subcategoriesRemainingHeight = 68

  const [ref] = useMainDesktopHeight({ extra: subcategoriesRemainingHeight })

  return (
    <div className={clsx('flex flex-col gap-2 bg-neutral-100 px-3 py-2 text-sm', className)}>
      <Link href={`/c/${categoryPath}`} passHref key={encodedId}>
        <a
          className={clsx(
            'flex shrink-0 rounded px-4 py-2 hover:bg-neutral-200',
            isCategorySelected && !router.query.subcategory && 'font-semibold',
          )}
        >
          <span className="overflow-hidden text-ellipsis whitespace-nowrap">Ver todo {name}</span>
        </a>
      </Link>
      <div className="h-px bg-neutral-200" />
      <div
        style={{
          maxHeight: !hasAnnouncement
            ? `calc(100vh - ${headerHeights.getMainDesktop() + subcategoriesRemainingHeight}px)`
            : null,
        }}
        ref={hasAnnouncement ? ref : null}
        className="overflow-y-auto"
      >
        {map(subcategories, subcategory => {
          const route = `/c/${categoryPath}/${subcategory.slug}-${subcategory.encodedId}`

          const isSelected = subCategoryEncodedId === subcategory.encodedId

          return (
            <Link href={route} passHref key={subcategory.encodedId}>
              <a
                className={clsx(
                  'flex shrink-0 overflow-hidden text-ellipsis whitespace-nowrap rounded px-4 py-2 hover:bg-neutral-200',
                  isSelected && 'font-semibold',
                )}
              >
                <span className="overflow-hidden text-ellipsis whitespace-nowrap">{subcategory.name}</span>
              </a>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default Subcategories
