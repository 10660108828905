import { useContext, useRef } from 'react'

import { ModalContext } from '~/contexts'

export interface SecureModalManagerProps {
  onClose: () => void
}

export interface ModalManagerProps {
  modalId?: () => void
}

/* @deprecated prefer using `useSecureModalManager` */
const useModalManager = () => {
  return useContext(ModalContext).manager
}

/* TODO: Replace existing `useModalManager` calls with this one. Issue #1664 */
export function useSecureModalManager<T extends SecureModalManagerProps>(template: (props: T) => JSX.Element) {
  const modalId = useRef<number | null>(null)

  const { openModal, closeModal } = useModalManager()

  const closeModalHandler = () => {
    closeModal(modalId.current)
  }

  const openModalHandler = (props?: Record<string, any>) => {
    modalId.current = openModal(template, {
      onClose: closeModalHandler,
      ...props,
    })
  }

  return { open: openModalHandler, close: closeModalHandler }
}

export default useModalManager
