import * as React from 'react'
import { filter, findLastIndex, flatten, map } from 'lodash'
import { useFlag } from 'toggled'

import { PriceLineProps, PriceLineType } from '~/components/pricing/price-line'
import type { IOrderPriceLine } from '~/types/orders'
import { flags } from '~/utils/constants'

import useVendor from './use-vendor'

const ORDER_PRICE_LINE_TYPES = [
  'line-items-total',
  'delivery-cost',
  'free-delivery-cost',
  'total',
  'sub-total',
  'tax',
  'adjustment',
]

interface UsePricingOptions {
  orderPriceLines: IOrderPriceLine[]
  settings?: {
    showTheBestRewardDescription?: boolean
  }
}

export function usePricing(options: UsePricingOptions): { lines: PriceLineProps[] } {
  const { settings } = options

  const { showTheBestRewardDescription = false } = settings || {}

  const vendor = useVendor()

  const hasLineItemsTotalBreakdown = useFlag(flags.LINE_ITEMS_TOTAL_BREAKDOWN)

  const hasTheBestRewardFeature = showTheBestRewardDescription
    ? vendor.settings?.promotions?.applyTheBestReward ?? false
    : false

  const lines = React.useMemo(() => {
    const orderPriceLines = [...options.orderPriceLines]

    if (hasTheBestRewardFeature) {
      const orderDeliveryPriceLineIndex = findLastIndex(
        orderPriceLines,
        priceLineProp => priceLineProp.type === 'free-delivery-cost' || priceLineProp.type === 'delivery-cost',
      )

      orderPriceLines.splice(orderDeliveryPriceLineIndex + 1, 0, {
        type: 'the-best-reward', // unknown type
        label: 'Se aplica la promoción o cupón con el mayor descuento.',
      } as any)
    }

    const orderPriceLinesSanitized = filter(orderPriceLines, (priceLineProp, index) => {
      const { value, type } = priceLineProp

      const isTotal = findLastIndex(orderPriceLines) === index

      const isFreeDelivery = type === 'free-delivery-cost'

      if (Number(value) === 0 && !isFreeDelivery && !isTotal) {
        return false
      }

      return true
    })

    return flatten(
      map(orderPriceLinesSanitized, (item, index): PriceLineProps | PriceLineProps[] => {
        const priceLineHasPrice = ORDER_PRICE_LINE_TYPES.includes(item.type)

        const isTotal = findLastIndex(orderPriceLinesSanitized) === index

        if (priceLineHasPrice) {
          const isFreeDelivery = item.type === 'free-delivery-cost'

          const textStyle = isTotal ? 'h5Bold' : 'h5Regular'

          const priceColor = isFreeDelivery || item.isHighlighted ? 'error' : 'black'

          const items: PriceLineProps[] = [
            {
              type: PriceLineType.DEFAULT,
              label: item.label,
              price: isFreeDelivery ? 0 : item.value,
              lineStyle: {
                textStyle,
                priceColor,
              },
            },
          ]

          if ((!hasLineItemsTotalBreakdown && isTotal) || item.isSubtotal) {
            items.unshift({
              type: PriceLineType.SEPARATOR,
            })
          }

          return items
        }

        return {
          type: PriceLineType.LABEL,
          label: item.label,
        }
      }),
    )
  }, [options.orderPriceLines, hasTheBestRewardFeature, hasLineItemsTotalBreakdown])

  return { lines }
}
