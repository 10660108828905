import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import Overlay from '~/truck/overlay'
import theme from '~/truck/theme'
import { stack, viewportHeights } from '~/variables'

export const Container = styled.aside`
  background: ${theme.colors.white};
  border-left: 1px solid ${theme.colors.gray};
  display: flex;
  flex-direction: column;
  height: ${viewportHeights.getMainDesktop()};
  position: relative;
  z-index: ${stack.cart};
`

export const MiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const StyledOverlay = styled(Overlay)`
  align-items: center;
  bottom: 0;
  display: flex;
  height: unset;
  justify-content: center;
  z-index: ${stack.cart + 1};

  ${up('lg')} {
    position: absolute;
  }
`
