import { isObject, isString } from 'lodash'

const getFileExtension = (file: File | string) => {
  if (isString(file)) {
    return file.split('.').pop()
  }

  if (isObject(file)) {
    return file.name.split('.').pop()
  }

  return null
}

export default getFileExtension
