import gql from 'graphql-tag'

const isExpressDeliveryAvailableQuery = gql`
  query isExpressDeliveryAvailableQuery {
    vendor {
      id
      isExpressDeliveryAvailable
    }
  }
`

export default isExpressDeliveryAvailableQuery
