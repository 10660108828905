import React, { useState } from 'react'

import Media from '../media'

import { Container } from './elements'
import Map from './map'
import Stores from './stores'

interface StoreSelectorComponentProps {
  onSelect?: (store: any) => void
  defaultValue?: string
  dataSource: any[]
  style?: React.CSSProperties
  className?: string
}

function StoreSelectorComponent(props: StoreSelectorComponentProps) {
  const { onSelect, defaultValue, dataSource, style, className } = props

  const [value, setValue] = useState(defaultValue)

  const onStoreClick = store => {
    setValue(store.id)

    if (onSelect) {
      onSelect(store)
    }
  }

  return (
    <Container style={style} className={className}>
      <Media
        mobile={<Stores value={value} dataSource={dataSource} onItemClick={onStoreClick} />}
        desktop={
          <>
            <Stores dataSource={dataSource} onItemClick={onStoreClick} value={value} />
            <Map stores={dataSource} onStoreClick={onStoreClick} selectedStoreId={value} />
          </>
        }
      />
    </Container>
  )
}

export default StoreSelectorComponent
