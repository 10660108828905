import { up } from 'styled-breakpoints'
import styled, { css } from 'styled-components'

import Store from '~/components/store'
import theme from '~/truck/theme'

type StyledStoreProps = ThemeProps<{
  selected: boolean
}>

export const Container = styled.div`
  width: 100%;

  ${up('lg')} {
    overflow: auto;
    width: ${theme.spacing.cozy * 40}px;
  }
`

function getStoreBorderColor(props: StyledStoreProps) {
  const { selected, theme: providedTheme } = props

  if (!selected) {
    return ''
  }

  return css`
    border-color: ${providedTheme.colors.primary};
  `
}

export const StyledStore = styled(Store)<StyledStoreProps>`
  background: ${theme.colors.grayLight};
  border: 1px solid transparent;
  cursor: pointer;

  ${getStoreBorderColor}

  :not(:last-child) {
    margin-bottom: ${theme.spacing.comfortable}px;
  }
`
