import React from 'react'
import styled, { css } from 'styled-components'

import RadioGroup from '~/truck/radio-group'
import theme from '~/truck/theme'

import Content from './content'

const containerStyle = css`
  border: 1px solid ${theme.colors.gray};
  border-radius: ${theme.dimensions.borderRadius}px;
  padding: ${theme.spacing.comfortable}px;
  width: 100%;
`

const DefaultContainer = styled.div`
  ${containerStyle};
`

const SelectableContainer = styled(RadioGroup.Radio)`
  background: none;
  ${containerStyle};

  :hover {
    border-color: ${theme.colors.blackLight};
  }

  > *:not(:last-child) {
    margin-right: ${theme.spacing.cozy}px;
  }
`

interface ManagementListItemProps {
  title: string
  children: React.ReactNode
  onEdit: () => void
  onDelete?: () => void
  style?: React.CSSProperties
  isDefault?: boolean
  defaultText?: string
  isSelectable?: boolean
  id?: string
  value?: any
}

// FIXME: move it to src/components/order as it is used only there
const ManagementListItem = ({
  title,
  children,
  onEdit,
  onDelete,
  style,
  isDefault,
  defaultText,
  isSelectable,
  id,
  value,
}: ManagementListItemProps) => {
  if (isSelectable) {
    return (
      <SelectableContainer id={id} value={value} style={style}>
        <Content title={title} onEdit={onEdit} onDelete={onDelete} isDefault={isDefault} defaultText={defaultText}>
          {children}
        </Content>
      </SelectableContainer>
    )
  }

  return (
    <DefaultContainer style={style}>
      <Content title={title} onEdit={onEdit} onDelete={onDelete} isDefault={isDefault} defaultText={defaultText}>
        {children}
      </Content>
    </DefaultContainer>
  )
}

export default ManagementListItem
