import type { IUser } from '~/types/users'
import type { IVendor } from '~/types/vendors'

export enum AnalyticsContexts {
  PAYMENT = 'payment',
  PURCHASE = 'purchase',
  LOGIN = 'login',
  SIGNUP = 'signup',
  ADD_TO_CART = 'addToCart',
  REMOVE_FROM_CART = 'removeFromCart',
  VIEW_PRODUCT = 'viewProduct',
}

export enum AnalyticsEvents {
  // General
  PAGE_VIEW = 'page_view',
  SEARCH = 'search',
  CONTACT = 'contact',
  SOCIAL_MEDIA = 'social_media',

  // Catalog
  VIEW_ITEM = 'view_item',
  VIEW_ITEM_LIST = 'view_item_list',
  SELECT_ITEM = 'select_item',

  // Cart
  ADD_TO_CART = 'add_to_cart',
  REMOVE_FROM_CART = 'remove_from_cart',
  VIEW_CART = 'view_cart',

  // Checkout
  BEGIN_CHECKOUT = 'begin_checkout',
  CHECKOUT_PROGRESS = 'checkout_progress',
  PURCHASE = 'purchase',

  // Authentication
  LOGIN = 'login',
  SIGN_UP = 'sign_up',
  PRE_SIGN_UP_ATTEMPT = 'pre_sign_up_attempt',

  // Payment Providers
  THIRD_PARTY_ASSETS_FAILED = 'third party assets failed',
  PROVIDER_FAILED = 'provider failed',

  // Promotions
  VIEW_PROMOTION = 'view_promotion',
  SELECT_PROMOTION = 'select_promotion',

  // Suggested Products
  CLOSE_SUGGESTED_PRODUCTS = 'close_suggested_products',
}

/**
 * Entity Types
 */

export interface AnalyticsProductEntity {
  item_id: string
  item_name: string
  price: number
  item_category: string
  item_variant?: string
  quantity?: number
  list_position?: number
}

/**
 * Collector Method Types
 */

export interface AnalyticsCollectorPageViewVariables {
  title: string
  path: string
  referrer: string
  location: string
}

export interface AnalyticsCollectorSearchVariables {
  term: string
}

export interface AnalyticsCollectorSelectItemVariables {
  product: AnalyticsProductEntity
  itemListId: string
  itemListName: string
}

export interface AnalyticsCollectorCloseSuggestedProductsVariables {
  products_were_added: boolean
}

export interface AnalyticsCollectorSelectItemOriginData {
  itemListId: string
  itemListName: string
}
export interface AnalyticsCollectorViewItemVariables {
  product: AnalyticsProductEntity
  context: string
  currency_code: string
  value: number
}

export interface AnalyticsCollectorViewItemListVariables {
  products: AnalyticsProductEntity[]
  itemListId: string
  itemListName: string
  context: string
}

export interface AnalyticsCollectorViewItemListOriginData {
  itemListId: string
  itemListName: string
}

export interface AnalyticsCollectorAddToCartVariables {
  product: AnalyticsProductEntity
  currency_code: string
  value: number
  context: string
}

export interface AnalyticsCollectorRemoveFromCartVariables {
  product: AnalyticsProductEntity
  currency_code: string
  value: number
  context: string
}

export interface AnalyticsCollectorViewCartVariables {
  products: AnalyticsProductEntity[]
  currency_code: string
  value: number
}

export interface AnalyticsCollectorBeginCheckoutVariables {
  products: AnalyticsProductEntity[]
  currency_code: string
  value: number
  step_name: string
}

export interface AnalyticsCollectorCheckoutProgressVariables {
  step_number: number
}

export interface AnalyticsCollectorPurchaseVariables {
  order_id: string
  order_total: number
  order_delivery_cost: number
  order_tax: number
  order_products: AnalyticsProductEntity[]
  seller_name: string
  currency_code: string
  phone: string
  email: string
  context: string
}

export interface AnalyticsCollectorLoginVariables {
  method: string | null
  context: string
}

export interface AnalyticsCollectorSignUpVariables {
  method: string | null
  context: string
}

export interface AnalyticsCollectorPaymentErrorVariables {
  event_name: string
  error_message: string
  payment_method: string
  context: string
}

export interface AnalyticsCollectorContactVariables {
  type: string
}

export interface AnalyticsCollectorSocialMediaVariables {
  type: string
}

export interface AnalyticsCollectorViewPromotionVariables {
  creative_slot: string
}

export interface AnalyticsCollectorSelectPromotionVariables {
  creative_slot: string
}

/**
 * Collector Types
 */

export interface AnalyticsCollectorOptions {
  vendor?: IVendor
  user?: IUser
}

export interface AnalyticsCollector {
  pageView: (variables: AnalyticsCollectorPageViewVariables, options: AnalyticsCollectorOptions) => void
  search: (variables: AnalyticsCollectorSearchVariables) => void
  selectItem: (variables: AnalyticsCollectorSelectItemVariables) => void
  viewItem: (variables: AnalyticsCollectorViewItemVariables) => void
  viewItemList: (variables: AnalyticsCollectorViewItemListVariables) => void
  addToCart: (variables: AnalyticsCollectorAddToCartVariables) => void
  removeFromCart: (variables: AnalyticsCollectorRemoveFromCartVariables) => void
  viewCart: (variables: AnalyticsCollectorViewCartVariables) => void
  beginCheckout: (variables: AnalyticsCollectorBeginCheckoutVariables) => void
  checkoutProgress: (variables: AnalyticsCollectorCheckoutProgressVariables) => void
  purchase: (variables: AnalyticsCollectorPurchaseVariables) => void
  login: (variables: AnalyticsCollectorLoginVariables) => void
  signUp: (variables: AnalyticsCollectorSignUpVariables) => void
  preSignUpAttempt: () => void
  paymentError: (variables: AnalyticsCollectorPaymentErrorVariables) => void
  contact: (variables: AnalyticsCollectorContactVariables) => void
  socialMedia: (variables: AnalyticsCollectorSocialMediaVariables) => void
  viewPromotion: (variables: AnalyticsCollectorViewPromotionVariables) => void
  selectPromotion: (variables: AnalyticsCollectorSelectPromotionVariables) => void
  closeSuggestedProducts: (variables: AnalyticsCollectorCloseSuggestedProductsVariables) => void
}
