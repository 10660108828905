// TODO:
// 1. If an element is clicked the position of the slider must be modified to show it

import { useCallback, useEffect, useRef, useState } from 'react'

interface Options {
  speedMs?: number
  arrowDefaultMove?: number
}

let position = 0

export const useSlider = (options: Options = { speedMs: 500 }) => {
  const [leftArrowEnable, setLeftArrowEnable] = useState(false)
  const [rightArrowEnable, setRightArrowEnable] = useState(true)

  const containerRef = useRef<any>(null)

  /** The "actual width" is the subtraction of the width of the objects and the width of the viewport */
  const getRealWidth = (): number => {
    if (!containerRef.current) {
      return
    }

    return containerRef.current.clientWidth - containerRef.current.parentElement.clientWidth
  }

  const updateArrows = useCallback(() => {
    setLeftArrowEnable(position < -1)
    setRightArrowEnable(position * -1 < getRealWidth() - 1)
  }, [setLeftArrowEnable, setRightArrowEnable])

  /** Increase or decrease the position of the slider */
  const changePosition = useCallback(
    (value: number) => {
      if (!containerRef.current) {
        return
      }

      let newPosition = position - value
      newPosition = Math.min(Math.max(newPosition, -getRealWidth()), 0)

      containerRef.current.style.transform = `translateX(${newPosition}px)`

      position = newPosition

      updateArrows()
    },
    [updateArrows],
  )

  /**
   * Advances or backwards the position of a Slider according to the width of the parent
   * or "viewport"
   */
  const nextOrBackPosition = (dir: number) => {
    if (!containerRef.current) {
      return
    }

    const viewportWidth = containerRef.current.parentElement.clientWidth
    const realWidth = getRealWidth()

    // slider movement default value
    const defaultMove = options.arrowDefaultMove ? options.arrowDefaultMove : viewportWidth / 3
    // calculate how many times the slider will move to reach the end
    // and round it up to calculate the exact value later
    const slotsWidth = Math.round(realWidth / defaultMove)
    // to calculate the exact value, the difference is calculated
    const difference = realWidth - defaultMove * slotsWidth
    // add that difference to the default move
    const movePos = defaultMove + difference / slotsWidth

    // apply animation
    containerRef.current.style.transition = `transform ${options.speedMs}ms`
    changePosition(movePos * dir)
  }

  const handlerWheel = useCallback(
    (e: WheelEvent) => {
      // if you are not scrolling horizontally, exit the function
      if (e.deltaX === 0 || !containerRef.current) {
        return
      }

      e.preventDefault()

      // disable animations
      containerRef.current.style.transition = 'transform 0ms'

      changePosition(e.deltaX)
    },
    [changePosition],
  )

  useEffect(() => {
    let lastRef: any = undefined

    if (containerRef.current) {
      // add events
      containerRef.current.addEventListener('wheel', handlerWheel)

      lastRef = containerRef.current

      // reset values
      updateArrows()
      containerRef.current.style.transform = `translateX(${position}px)`
    }

    return () => {
      if (lastRef) {
        lastRef.removeEventListener('wheel', handlerWheel)
      }
    }
  }, [containerRef, updateArrows, handlerWheel])

  return {
    containerRef,
    next: () => nextOrBackPosition(1),
    back: () => nextOrBackPosition(-1),
    leftArrowEnable,
    rightArrowEnable,
  }
}

export default useSlider
