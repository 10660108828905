import React from 'react'
import ReactRating from 'react-rating'
import styled from 'styled-components'

import theme from '~/truck/theme'
import cloudinary from '~/utils/cloudinary'
import { transformations } from '~/utils/constants'

const Container = styled.div`
  text-align: center;
`

const Rating = props => {
  const {
    imageStyle: imageStyleProp,
    style,
    className,
    initialRating,
    readonly = true,
    onClick,
    stop = 5,
    emptyRatingImage,
    ...rest
  } = props

  const filledImageUrl = cloudinary.getFullUrl('v1588088896/sellers/resources/star.png')

  const emptyImageUrl = emptyRatingImage ?? cloudinary.getFullUrl('v1588088896/sellers/resources/star-outline.png')

  const filledImage = cloudinary.applyTransformations(filledImageUrl, transformations.RATING_IMAGE)

  const emptyImage = cloudinary.applyTransformations(emptyImageUrl, transformations.RATING_IMAGE)

  const imageStyle = {
    width: theme.spacing.comfortable,
    height: theme.spacing.comfortable,
    ...imageStyleProp,
  }

  return (
    <Container style={style} className={className}>
      <ReactRating
        emptySymbol={<img style={imageStyle} src={emptyImage} alt="star" />}
        fullSymbol={<img style={imageStyle} src={filledImage} alt="star" />}
        initialRating={initialRating}
        onClick={onClick}
        placeholderSymbol={<img style={imageStyle} src={filledImage} alt="star" />}
        readonly={readonly}
        stop={stop}
        {...rest}
      />
    </Container>
  )
}

export default Rating
