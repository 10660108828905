import React from 'react'
import renderHTML from 'react-html-parser'
import { useFeature } from 'toggled'

import Image from '~/truck/image'
import Label from '~/truck/label'
import { cloudinary } from '~/utils'
import { flags } from '~/utils/constants'

import Price from '../price'

import Adjustments from './adjustments'
import { Container, InfoContainer, OtherContainer, StyledPrice } from './elements'

const LineItem = props => {
  const {
    style,
    className,
    name,
    photo,
    presentation,
    price,
    adjustments,
    quantity,
    oldPrice,
    isTaxExempt,
    inventoryQuantity,
    inventoryUnitCode,
    product,
  } = props

  const attributesSummary = product?.attributesSummary

  const showSkuLabelFeature = useFeature(flags.SHOW_SKU_LABEL)

  return (
    <Container style={style} className={className}>
      <Image src={cloudinary.applyTransformations(photo)} />
      <InfoContainer>
        <p>{renderHTML(name)}</p>
        {presentation && (
          <Label as="p" $textStyle="h6Regular" $ellipsis>
            {presentation}
          </Label>
        )}
        {attributesSummary && (
          <Label as="p" $textStyle="h6Regular" $color="grayscale60" $ellipsis>
            {attributesSummary}
          </Label>
        )}
        {showSkuLabelFeature && (
          <Label as="p" $textStyle="h6Regular" $color="grayscale60" $ellipsis>
            sku: {product?.sku}
          </Label>
        )}
        <Price value={price} color="black" textStyle="h6Regular" allowTaxLabel isTaxExempt={isTaxExempt} />
        <Adjustments adjustments={adjustments} />
      </InfoContainer>
      <OtherContainer>
        <p className="font-semibold">{`x ${quantity}`}</p>
        {inventoryQuantity && inventoryUnitCode ? (
          <p className="text-xs">
            {inventoryQuantity} <span dangerouslySetInnerHTML={{ __html: inventoryUnitCode }} />
          </p>
        ) : null}
        <StyledPrice value={price * quantity} oldValue={oldPrice && oldPrice * quantity} />
      </OtherContainer>
    </Container>
  )
}

export default LineItem
