import * as React from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import type { WatchQueryFetchPolicy } from 'apollo-client'
import { isNil } from 'lodash'

import { userLineOfCreditQuery } from '~/gql/queries'
import type { IQueryData } from '~/types/lines-of-credit'
import { handleSubmissionErrors } from '~/utils'

interface Options {
  orderPriceLineTotal?: number
  fetchPolicy?: WatchQueryFetchPolicy
  considerOverdraft?: boolean
}

enum Statuses {
  INSUFFICIENT,
  AVAILABLE,
  CAN_OVER_DRAFT,
}

const Messages = {
  [Statuses.INSUFFICIENT]: 'Crédito insuficiente',
  [Statuses.AVAILABLE]: 'Crédito disponible',
  [Statuses.CAN_OVER_DRAFT]: 'Crédito insuficiente',
}

const DescriptionMessages = {
  [Statuses.INSUFFICIENT]:
    'El monto del pedido supera la línea disponible. Puedes elegir otro método de pago o contactar a tu vendedor asignado.',
  [Statuses.CAN_OVER_DRAFT]:
    'El monto del pedido supera la línea disponible. Puedes realizar la compra pero la tienda debe confirmar el pedido.',
}

const Colors = {
  [Statuses.INSUFFICIENT]: 'error',
  [Statuses.AVAILABLE]: 'info',
  [Statuses.CAN_OVER_DRAFT]: 'error',
}

const Disabled = {
  [Statuses.INSUFFICIENT]: true,
  [Statuses.AVAILABLE]: false,
  [Statuses.CAN_OVER_DRAFT]: false,
}

const errorMessage = 'No pudimos completar ese paso. Por favor, selecciona otro método de pago.'

const useLineOfCredit = (options: Options = {}) => {
  const { fetchPolicy = 'cache-first', orderPriceLineTotal = 0, considerOverdraft = true } = options

  const [loadLineOfCredit, lineOfCreditResponse] = useLazyQuery<IQueryData>(userLineOfCreditQuery, { fetchPolicy })

  const sanitizeData = React.useMemo(() => {
    const lineOfCreditData = lineOfCreditResponse.data?.user?.lineOfCredit

    if (!lineOfCreditResponse.loading && isNil(lineOfCreditData)) return

    const hasError = handleSubmissionErrors(lineOfCreditResponse.error)

    const totalCredit = Number(lineOfCreditData?.creditLimit)

    const canOverDraft = lineOfCreditData?.canOverdraft

    const availableCredit = Number(lineOfCreditData?.availableCredit)

    const usedCredit = totalCredit - availableCredit

    let statusCode = Statuses.AVAILABLE

    const isOverDraft = availableCredit < Number(orderPriceLineTotal)

    if (isOverDraft && canOverDraft && considerOverdraft) {
      statusCode = Statuses.CAN_OVER_DRAFT
    } else if (isOverDraft) {
      statusCode = Statuses.INSUFFICIENT
    }

    return {
      message: Messages[statusCode],
      description: DescriptionMessages[statusCode],
      totalCredit,
      usedCredit,
      isEnabled: lineOfCreditData?.isEnabled,
      color: Colors[statusCode],
      isLoading: lineOfCreditResponse.loading,
      isDisabled: Disabled[statusCode] || lineOfCreditResponse.loading || hasError,
      error: hasError && errorMessage,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineOfCreditResponse.loading, lineOfCreditResponse.data])

  return [loadLineOfCredit, sanitizeData]
}

export default useLineOfCredit
