import * as React from 'react'
import Link from 'next/link'
import styled from 'styled-components'

import { useModalManager } from '~/hooks'
import { mixpanel } from '~/metrics'
import Button from '~/truck/button'
import Icon from '~/truck/icon'
import Image from '~/truck/image'
import Label from '~/truck/label'
import Overlay from '~/truck/overlay'
import { events } from '~/utils/constants'

import Modal from '../modal'

interface LoginToContinueModalProps {
  image: string
  description: string
}

const StyledOverlay = styled(Overlay)`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 ${props => props.theme.spacing.comfortable}px;
`

const StyledModal = styled(Modal.Standalone)`
  box-shadow:
    0px 16px 24px rgba(0, 0, 0, 0.14),
    0px 6px 30px rgba(0, 0, 0, 0.12),
    0px 8px 10px rgba(0, 0, 0, 0.2);
  height: 100%;
  max-height: ${props => props.theme.spacing.cozy * 44}px;
  max-width: ${props => props.theme.spacing.cozy * 57.125}px;
  overflow-y: unset;
  padding: ${props => props.theme.spacing.relaxed}px;
  padding-top: ${props => props.theme.spacing.cozy * 6}px;
  width: 100%;
`

const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;

  > *:not(:last-child) {
    margin-bottom: ${props => props.theme.spacing.cozy * 4}px;
  }
`

const StyledIcon = styled(Icon)`
  fill: ${props => props.theme.palette.grayscale60};
  height: ${props => props.theme.spacing.cozy * 3}px;
  left: ${props => props.theme.spacing.cozy * 3}px;
  position: absolute;
  top: ${props => props.theme.spacing.cozy * 3}px;
  width: ${props => props.theme.spacing.cozy * 3}px;
`

const StyledButton = styled(Button)`
  text-decoration: none;
  width: auto;
`

const StyledImage = styled(Image)`
  height: ${props => props.theme.spacing.cozy * 9}px;
  width: ${props => props.theme.spacing.cozy * 9}px;
`

export function LoginToContinueModal(props: LoginToContinueModalProps) {
  const { image, description } = props

  const { closeModal: onCloseModal } = useModalManager()

  const onLoginClick = () => {
    mixpanel.track(events.LOGIN_FROM_THE_CONFIRMATION_MODAL)

    onCloseModal()
  }

  return (
    <StyledOverlay open onClick={() => onCloseModal()}>
      <StyledModal>
        <StyledIcon size="sm" type="close" onClick={() => onCloseModal()} />
        <StyledContainer>
          <StyledImage src={image} />
          <Label $textStyle="h5Regular" as="p" $textAlign="center" $color="grayscale70">
            {description}
          </Label>
          <Link href="/ingresar" passHref>
            <StyledButton forwardedAs="a" onClick={onLoginClick}>
              Iniciar Sesión
            </StyledButton>
          </Link>
        </StyledContainer>
      </StyledModal>
    </StyledOverlay>
  )
}
