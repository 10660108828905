import notifier from '~/truck/notifier'
import { forceReloadTo, handleSubmissionErrors } from '~/utils'

const useRecoverPassword = (recoverPasswordMutation, resetPasswordToken: string) => {
  const onSubmit = async (values: Record<string, unknown>) => {
    const variables = {
      ...values,
      resetPasswordToken,
    }

    try {
      await recoverPasswordMutation({ variables })

      notifier.success('Tu contraseña fue actualizada correctamente')

      forceReloadTo('/')
    } catch (error) {
      return handleSubmissionErrors(error)
    }
  }

  return { onSubmit }
}

export default useRecoverPassword
