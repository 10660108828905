import type { IVendorSettings } from '~/types/vendors'

import { defaultTaxLabel } from './constants'

interface TaxLabelOptions {
  taxExemptMessage?: string
  taxIncludedMessage?: string
  taxExcludedMessage?: string
}

const getTaxLabel = (settings: IVendorSettings, isTaxExempt: boolean, options: TaxLabelOptions = {}) => {
  const taxLabel = settings?.taxLabel ?? defaultTaxLabel

  const isTaxIncluded = settings?.pricing?.itemsTaxRate?.includedInPrice ?? !settings?.pricing?.taxExcludedInPrices

  if (!isTaxIncluded && !isTaxExempt) {
    return options.taxExcludedMessage ?? `No incluye ${taxLabel}`
  }

  const taxExemptMessage = options.taxExemptMessage ?? `Exento de ${taxLabel}`

  const taxIncludedMessage = options.taxIncludedMessage ?? `Incluye ${taxLabel}`

  return isTaxExempt ? taxExemptMessage : taxIncludedMessage
}

export default getTaxLabel
