import React from 'react'

interface StoreInfoBoxProps {
  name: string
  address?: {
    line: string
  }
  phone: string
  email: string
}

function StoreInfoBox(props: StoreInfoBoxProps) {
  const { name, address, phone, email } = props

  return (
    <div className="space-y-1">
      <p className="font-semibold">{name}</p>
      <p className="text-xs">{address?.line}</p>
      <p className="text-xs">{phone}</p>
      <p className="text-xs">{email}</p>
    </div>
  )
}

export default StoreInfoBox
