import * as React from 'react'
import { useField } from 'react-final-form'
import { map, range } from 'lodash'
import styled from 'styled-components'

import Field from '~/truck/field'
import Label from '~/truck/label'
import theme from '~/truck/theme'

const Container = styled.div`
  display: flex;
  flex-direction: column;

  > label {
    margin-bottom: ${theme.spacing.compact}px;
  }

  > span {
    display: block;
    margin-left: ${theme.spacing.comfortable}px;
  }
`

const InnerContainer = styled.div`
  display: flex;

  > * {
    flex: 1;

    :not(:last-child) {
      margin-right: ${theme.spacing.cozy}px;
    }
  }
`

const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Setiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
]

const MAX_HUMAN_AGE = 116

const BirthdateField = () => {
  const field = useField('birthdate')

  const { input, meta } = field

  const errorMessage = meta.touched && (meta.error || meta.submitError)

  function onChange(e) {
    const newValue = {
      ...input.value,
      [e.target.name]: e.target.value,
    }

    input.onChange(newValue)
  }

  const options = React.useMemo(() => {
    const dayOptions = map(range(1, 32), day => (
      <option key={`birthdate.day-${day}`} value={day}>
        {day}
      </option>
    ))

    const monthOptions = map(range(0, 12), monthIndex => (
      <option key={`birthdate.month-${monthIndex}`} value={monthIndex}>
        {months[monthIndex]}
      </option>
    ))

    const currentYear = new Date().getFullYear()

    const yearOptions = map(range(currentYear, currentYear - MAX_HUMAN_AGE, -1), year => (
      <option key={`birthdate.year-${year}`} value={year}>
        {year}
      </option>
    ))

    return { dayOptions, monthOptions, yearOptions }
  }, [])

  const common = {
    standalone: true,
    error: Boolean(errorMessage),
    onBlur: input.onBlur,
    onFocus: input.onFocus,
  }

  // FIXME: month field should be wider than other fields
  return (
    <Container name="birthdate">
      <Label htmlFor="birthdate" $textStyle="h6Semibold">
        Fecha de nacimiento
      </Label>
      <InnerContainer>
        <Field {...common} as="select" name="day" prompt="Día" onChange={onChange} value={input.value?.day}>
          {options.dayOptions}
        </Field>
        <Field {...common} as="select" name="month" prompt="Mes" onChange={onChange} value={input.value?.month}>
          {options.monthOptions}
        </Field>
        <Field {...common} as="select" name="year" prompt="Año" onChange={onChange} value={input.value?.year}>
          {options.yearOptions}
        </Field>
      </InnerContainer>
      {errorMessage && (
        <Label as="span" $textStyle="h6Regular" $color="error">
          {errorMessage}
        </Label>
      )}
    </Container>
  )
}

export default BirthdateField
