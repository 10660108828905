import 'moment/locale/es'

import * as React from 'react'
import Media from 'react-media'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ApolloProvider } from '@apollo/react-hooks'
import type { NormalizedCacheObject } from 'apollo-cache-inmemory'
import type ApolloClient from 'apollo-client'
import moment from 'moment-timezone'
import { ThemeProvider } from 'styled-components'
import { FeatureProvider } from 'toggled'

import { BuyingInStoreContext, CurrencyConfigContext, IsMobileContext, UserContext, VendorContext } from '~/contexts'
import ExpressDeliverySessionProvider, { ExpressDeliverySession } from '~/providers/express-delivery-session'
import MultiDistributorSessionProvider from '~/providers/multi-distributor-session'
import StringsProvider from '~/providers/strings-provider'
import { createTheme } from '~/truck/utils'
import type { IVendor } from '~/types/vendors'
import { hasFlag } from '~/utils'
import { flags } from '~/utils/constants'

import Modal from '../modal'

interface AppProviderProps {
  children: React.ReactNode
  apolloClient?: ApolloClient<NormalizedCacheObject>
  queryClient: QueryClient
  isMobile?: boolean
  vendor: IVendor
  user?: Record<string, any>
  multiDistributorSession?: any
  expressDeliverySession?: ExpressDeliverySession
  buyingInStore?: any
}

function getTheme(vendor: IVendor) {
  const theme = createTheme()

  theme.colors.primary = vendor.primaryColor

  theme.colors.secondary = vendor.secondaryColor

  return theme
}

function AppProvider(props: AppProviderProps) {
  const {
    children,
    apolloClient,
    queryClient,
    isMobile: initialIsMobile,
    vendor,
    user,
    multiDistributorSession,
    expressDeliverySession,
    buyingInStore = null,
  } = props

  const { country } = vendor

  const theme = getTheme(vendor)

  if (hasFlag(vendor.features, flags.SHOW_CURRENCY_CODE)) {
    country.currencyConfig.symbol = `${country.currencyCode} `
  }

  const features = vendor.features.map(feat => ({
    slug: feat[0],
    settings: feat[1],
  }))

  moment.locale('es')

  moment.tz.setDefault(vendor.timezone)

  return (
    <ApolloProvider client={apolloClient}>
      <QueryClientProvider client={queryClient}>
        <CurrencyConfigContext.Provider value={country.currencyConfig}>
          <VendorContext.Provider value={vendor}>
            <StringsProvider>
              <UserContext.Provider value={user}>
                <BuyingInStoreContext.Provider value={buyingInStore}>
                  <ExpressDeliverySessionProvider session={expressDeliverySession}>
                    <MultiDistributorSessionProvider session={multiDistributorSession}>
                      <FeatureProvider features={features}>
                        <ThemeProvider theme={theme}>
                          <Modal.Provider>
                            <Media defaultMatches={!initialIsMobile} query={{ minWidth: theme.breakpoints.lg }}>
                              {isDesktop => (
                                <IsMobileContext.Provider value={!isDesktop}>{children}</IsMobileContext.Provider>
                              )}
                            </Media>
                          </Modal.Provider>
                        </ThemeProvider>
                      </FeatureProvider>
                    </MultiDistributorSessionProvider>
                  </ExpressDeliverySessionProvider>
                </BuyingInStoreContext.Provider>
              </UserContext.Provider>
            </StringsProvider>
          </VendorContext.Provider>
        </CurrencyConfigContext.Provider>
      </QueryClientProvider>
    </ApolloProvider>
  )
}

export default AppProvider
