import styled from 'styled-components'

import Icon from '~/truck/icon'
import theme from '~/truck/theme'

export const StyledTrashIcon = styled(Icon)`
  height: ${theme.spacing.relaxed}px;
  position: absolute;
  right: ${theme.spacing.compact}px;
  top: 0;
`
