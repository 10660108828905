import gql from 'graphql-tag'

const initialProductFragment = gql`
  fragment initialProduct on Product {
    id
    originalId
    name
    sku
    slug
    seoTitle
    defaultPhoto
    presentation
    stock
    hasVariants
    attributesSummary
    jumpFactor
    salesUnitFactor
    minimumSalesQuantity
    maximumSalesQuantity
    favoriteSince
    inventoryUnit {
      id
      code
    }
    reviewStats {
      rating
    }
    variantInStock {
      id
      slug
    }
    pricing {
      oldPrice
      price
      ribbonLabel
      isTaxExempt
    }
    tierPricing {
      oldPrice
      price
      ribbonLabel
      minimumQuantity
    }
  }
`

export default initialProductFragment
