import type { BulkOrderingResultError, BulkOrderingResultOperation } from '../../types'

export interface FormValues {
  input: string
}

export interface FormValueFieldGroup {
  sku: string
  quantity: string
}

export interface FormValueFieldGroupObject {
  [key: string]: FormValueFieldGroup
}

export interface PutCartMutationResult {
  data: {
    cartId: number
    errors: BulkOrderingResultError[]
    operations: BulkOrderingResultOperation[]
  }
}

export enum BulkOrderingType {
  INSERT_MANUALLY = 'INSERT_MANUALLY',
  COPY_FROM_EXCEL = 'COPY_FROM_EXCEL',
}

export interface UpdatedCartItem {
  sku: string
  quantity: number
}

export interface UpdatedCart {
  errors: any[]
  result: UpdatedCartItem[]
}
