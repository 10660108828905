import { sumBy } from 'lodash'

import type { ILineItem } from '~/types/line-items'

const getItemTotal = (lineItem: ILineItem) => {
  const { price, originalQuantity } = lineItem

  return Number(price) * Number(originalQuantity)
}

const getLineItemsTotal = (lineItems: ILineItem[]) => {
  const lineItemsTotal = sumBy(lineItems, getItemTotal)

  return lineItemsTotal.toFixed(2)
}

export default getLineItemsTotal
