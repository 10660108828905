import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import Modal from '~/components/modal'
import Button from '~/truck/button'
import theme from '~/truck/theme'
import { stack } from '~/variables'

export const StyledSuggestedProductsModal = styled(Modal.Standalone)`
  background: ${theme.palette.grayscale30};
  display: flex;
  flex-direction: column;
  max-height: calc(100% - ${theme.spacing.comfortable * 2}px);
  max-width: ${theme.spacing.comfortable * 62}px;
  overflow: unset;
  overflow-y: overlay;
  padding: 0;
  position: fixed;
  width: 100%;
  z-index: ${stack.suggestedProducModal};
`

export const StyledModalHeader = styled.div`
  background: ${theme.colors.white};
  border-bottom: 1px solid ${theme.colors.gray};
  min-height: auto;
  min-width: auto;
  padding: ${theme.spacing.comfortable}px;
  z-index: ${stack.suggestedProducModal};

  ${up('lg')} {
    border-bottom: none;
    padding: ${theme.spacing.relaxed}px;
  }

  & > div {
    margin-bottom: 0;
  }
`

export const StyledModalBody = styled.div`
  overflow-y: overlay;
  padding: ${theme.spacing.cozy}px;

  padding-right: ${theme.spacing.cozy * 1.3}px;

  ${up('lg')} {
    overflow-y: overlay;
    padding: ${theme.spacing.relaxed}px ${theme.spacing.relaxed}px;
  }
`

export const StyledModalFooter = styled.div`
  background: white;
  border-top: 1px solid ${theme.colors.gray};
  display: flex;
  justify-content: flex-end;
  min-height: auto;
  min-width: auto;
  padding: ${theme.spacing.comfortable}px;
  z-index: ${stack.suggestedProducModal};

  ${up('lg')} {
    border-top: none;
    padding: ${theme.spacing.relaxed}px;
  }
`

export const StyledGhostButton = styled(Button.Ghost)`
  ${up('lg')} {
    width: ${theme.spacing.comfortable * 12.5}px;
  }
`

export const StyledNormalButton = styled(Button)`
  ${up('lg')} {
    width: ${theme.spacing.comfortable * 12.5}px;
  }
`
