export const shippingTypesMap = {
  delivery: {
    id: 'delivery',
    slug: 'delivery',
    name: 'Envio',
    icon: 'truck',
    isDelivery: true,
    isPickup: false,
  },
  pickup: {
    id: 'pickup',
    slug: 'pickup-in-store',
    name: 'Recojo',
    icon: 'store',
    isDelivery: false,
    isPickup: true,
  },
}

function getShippingTypes(settings) {
  const shippingTypes = []

  if (!settings.d2c) {
    return []
  }

  if (settings.d2c.delivery) {
    shippingTypes.push(shippingTypesMap.delivery)
  }

  if (settings.d2c.pickup) {
    shippingTypes.push(shippingTypesMap.pickup)
  }

  return shippingTypes
}

export default getShippingTypes
