import styled from 'styled-components'

import theme from '~/truck/theme'
import { getTextStyle, getTheme } from '~/truck/utils'

interface StyledInputProps {
  type?: string
  as?: string
  $error?: boolean
}

function getBorderColor({ $error }: StyledInputProps) {
  if ($error) {
    return theme.colors.error
  }

  return theme.colors.gray
}

export const StyledInput = styled.input.attrs<StyledInputProps>(props => {
  const { type, as: asProp } = props

  return {
    type: type ?? 'text',
    as: asProp ?? 'input',
  }
})<StyledInputProps>`
  background: ${theme.colors.white};
  border-radius: ${theme.dimensions.borderRadius}px;
  border: 1px solid ${getBorderColor};
  color: ${theme.colors.black};
  outline: none;
  padding: 0 ${theme.spacing.comfortable}px;
  transition: border 0.2s ease-in-out;
  width: 100%;
  height: ${theme.dimensions.inputHeight}px;
  ${getTextStyle(theme.textStyles.h5Regular)};

  :disabled {
    background: ${theme.colors.grayLight};
    color: ${theme.colors.blackLight};
    cursor: not-allowed;
  }

  ${props =>
    !props.$error &&
    `
    :focus {
      border-color: ${getTheme(props).colors.primary};
    }
  `};
`

interface ContainerProps {
  $disabled?: boolean
  $error?: boolean
  $hasLeftItem?: boolean
  $hasRightItem?: boolean
}

function getPadding({ $hasLeftItem, $hasRightItem }: ContainerProps) {
  let right = theme.spacing.comfortable
  let left = theme.spacing.comfortable

  const iconSize = theme.sizes.md

  if ($hasLeftItem) {
    left += iconSize + theme.spacing.cozy
  }

  if ($hasRightItem) {
    right += iconSize + theme.spacing.cozy
  }

  return `0 ${right}px 0 ${left}px`
}

function getIconColor({ $disabled, $error }: ContainerProps) {
  if ($disabled) {
    return theme.colors.blackLight
  }

  if ($error) {
    return theme.colors.error
  }

  return theme.colors.black
}

export const Container = styled.div<ContainerProps>`
  align-items: center;
  display: inline-flex;
  position: relative;
  width: 100%;

  > svg {
    cursor: auto;
    fill: ${getIconColor};
  }

  > svg:first-child,
  > div:first-child {
    left: ${theme.spacing.comfortable}px;
    position: absolute;
  }

  > svg:last-child,
  > div:last-child {
    position: absolute;
    right: ${theme.spacing.comfortable}px;
  }

  > input {
    padding: ${getPadding};
  }

  ${props =>
    props.$disabled &&
    `
    > svg {
      cursor: not-allowed;
    }
  `};
`
