import React from 'react'
import styled from 'styled-components'

import Label from '~/truck/label'
import theme from '~/truck/theme'
const Container = styled.div`
  align-items: center;
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.gray};
  border-radius: ${theme.spacing.cozy * 3}px;
  cursor: pointer;
  display: flex;
  height: ${theme.spacing.cozy * 6}px;
  padding: 0 ${theme.spacing.comfortable}px;
`

const TextContainer = styled.div`
  flex: 1;
  margin-left: ${theme.spacing.cozy}px;

  > p {
    line-height: ${theme.spacing.comfortable}px;
  }

  > p:last-child {
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
  }
`

const ShippingSelectorDesktop = props => {
  const { style, className, icon, text, title, onClick } = props

  return (
    <Container style={style} className={className} onClick={onClick}>
      {icon}
      <TextContainer>
        <Label as="p" $textStyle="h6Regular">
          {title}
        </Label>
        <Label as="p" $textStyle="h6Bold">
          {text}
        </Label>
      </TextContainer>
    </Container>
  )
}

export default ShippingSelectorDesktop
