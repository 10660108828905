import React, { useContext } from 'react'
import { useForm } from 'react-final-form'
import { useFlag } from 'toggled'

import { GoogleMapsContext } from '~/contexts'
import Field from '~/truck/field'
import { flags } from '~/utils/constants'

function AddressLine() {
  const hasMappingCreation = useFlag(flags.MAP_IN_ADDRESS_CREATION)

  const googleMapsContext = useContext(GoogleMapsContext)

  const form = useForm()

  function onKeyDown(event) {
    if (!googleMapsContext?.mapInstance || event.charCode === 13) {
      return
    }

    form.change('hasSelectedAddress', false)
  }

  if (hasMappingCreation) {
    return (
      <Field
        id="google-address-line"
        name="googleAddressLine"
        placeholder="Dirección"
        onKeyDown={onKeyDown}
        style={{ position: 'relative' }}
      />
    )
  }

  return <Field name="addressLine" placeholder="Dirección" />
}

export default AddressLine
