import React from 'react'
import styled from 'styled-components'

import Anchor from '~/truck/anchor'
import Label from '~/truck/label'
import theme from '~/truck/theme'

interface HeaderProps {
  title: string
  isEditable?: boolean
  onEditClick?: () => void
  className?: string
  style?: React.CSSProperties
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 ${theme.spacing.comfortable}px;

  > a {
    cursor: pointer;
  }
`

const Header = (props: HeaderProps) => {
  const { title, isEditable, onEditClick, className, style } = props

  return (
    <Container className={className} style={style}>
      <Label as="h5" $textStyle="h5Semibold">
        {title}
      </Label>
      {isEditable && (
        <Anchor $color="info" $textStyle="h5Semibold" onClick={onEditClick}>
          Editar
        </Anchor>
      )}
    </Container>
  )
}

export default Header
