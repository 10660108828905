import React from 'react'

import { Column, StyledGrid } from './elements'

function Grid(props) {
  return <StyledGrid {...props} />
}

Grid.Column = Column

export default Grid
