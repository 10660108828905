import React from 'react'
import type { FlattenSimpleInterpolation } from 'styled-components'

import type { ThemeColor } from '~/types/theme'

import BaseButton from './base'

/*
 * TODO: Refactor this component
 * All variants of buttons are defined in the same file (elements.js)
 * Consult the design area if its necessary to update styles of Base button
 */

interface CommonButtonProps {
  loading?: boolean
  color?: ThemeColor
  as?: React.ElementType
  css?: FlattenSimpleInterpolation
}

type NormalButtonProps = React.ComponentPropsWithRef<'button'> &
  CommonButtonProps & {
    children: React.ReactNode

    // Added from "WithIcon" due to misuse in favorites feature
    plain?: boolean
  }

const Normal = React.forwardRef<HTMLButtonElement, NormalButtonProps>((props, ref) => {
  return <BaseButton {...props} forwardedRef={ref} />
})

type GhostButtonProps = React.ComponentPropsWithRef<'button'> &
  CommonButtonProps & {
    children: React.ReactNode
  }

const Ghost = React.forwardRef<HTMLButtonElement, GhostButtonProps>((props, ref) => {
  return <BaseButton {...props} ghost forwardedRef={ref} />
})

type CircularButtonProps = React.ComponentPropsWithRef<'button'> &
  CommonButtonProps & {
    children: React.ReactNode

    // Added from "CircularWithIcon" due to misuse in favorites feature
    plain?: boolean
  }

const Circular = React.forwardRef<HTMLButtonElement, CircularButtonProps>((props, ref) => {
  return <BaseButton {...props} circular forwardedRef={ref} />
})

type PlainButtonProps = React.ComponentPropsWithRef<'button'> &
  CommonButtonProps & {
    children: React.ReactNode
  }

const Plain = React.forwardRef<HTMLButtonElement, PlainButtonProps>((props, ref) => {
  return <BaseButton {...props} plain forwardedRef={ref} />
})

type TransparentButtonProps = React.ComponentPropsWithRef<'button'> &
  CommonButtonProps & {
    children: React.ReactNode
  }

const Transparent = React.forwardRef<HTMLButtonElement, TransparentButtonProps>((props, ref) => {
  return <BaseButton {...props} transparent forwardedRef={ref} />
})

type CompoundButtonType = typeof Normal & {
  Circular: typeof Circular
  Ghost: typeof Ghost
  Plain: typeof Plain
  Transparent: typeof Transparent
}

/** @deprecated use `NewButton` */
const CompoundButton = Normal as CompoundButtonType

CompoundButton.Circular = Circular
CompoundButton.Ghost = Ghost
CompoundButton.Plain = Plain
CompoundButton.Transparent = Transparent

export default CompoundButton
