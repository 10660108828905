import gql from 'graphql-tag'

import { documentFragment } from '../fragments'

const userDocumentsQuery = gql`
  query userDocuments {
    user {
      id
      documents {
        ...document
      }
    }
  }
  ${documentFragment}
`

export default userDocumentsQuery
