import * as React from 'react'
import clsx from 'clsx'

import { useMainDesktopHeight } from '~/hooks'
import Overlay from '~/truck/overlay'
import type { ICategory } from '~/types/category'
import { stack, viewportHeights } from '~/variables'

import Categories from './Categories'
import Subcategories from './Subcategories'

interface TreeProps {
  dataSource: ICategory[]
  className?: string
}

function Content({ dataSource, className }: TreeProps) {
  const [hoveredCategory, setHoveredCategory] = React.useState(dataSource[0])

  const [ref, hasAnnouncement] = useMainDesktopHeight()

  return (
    <div
      ref={hasAnnouncement ? ref : null}
      className={clsx('flex min-h-80 w-[40rem]', className)}
      style={{ maxHeight: viewportHeights.getMainDesktop() }}
    >
      <Overlay
        style={{
          zIndex: stack.categoriesMenuOverlay,
          opacity: 1,
        }}
        open={false}
      />
      <Categories
        className="z-[26] w-72 shrink-0"
        dataSource={dataSource}
        setHoveredCategory={setHoveredCategory}
        hoveredCategory={hoveredCategory}
      />
      {hoveredCategory && <Subcategories className="z-[26] grow" category={hoveredCategory} />}
    </div>
  )
}

export default Content
