import * as React from 'react'
import type { FormRenderProps } from 'react-final-form'
import { useFlag } from 'toggled'

import type { IShippingType } from '~/types/shipping'
import { flags } from '~/utils/constants'

import ExpressDeliveryShippingModal from './express-delivery-modal'
import MultiDistributorShippingModal from './multi-distributor-modal'

export interface ModalProps {
  closable: boolean
}

export interface ModalContentProps<FormValues = Record<string, any>> {
  formProps: FormRenderProps<FormValues>
  shippingTypes: IShippingType[]
  closable: boolean
}

function ShippingModal(props: ModalProps) {
  const hasExpressDelivery = useFlag(flags.EXPRESS_DELIVERY)

  if (hasExpressDelivery) {
    return <ExpressDeliveryShippingModal {...props} />
  }

  return <MultiDistributorShippingModal {...props} />
}

export default ShippingModal
