import type { AxiosInstance } from 'axios'

async function getS3SignedForm(file: File, uploadFolder: string, fetcher: AxiosInstance) {
  const response = await fetcher.get('/media/s3.generateSignedForm', {
    params: {
      folder: uploadFolder,
      contentType: file.type,
    },
  })

  return response.data.data
}

export default getS3SignedForm
