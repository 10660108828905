import styled from 'styled-components'

import NumericInput from '~/truck/numeric-input'
import { getTextStyle } from '~/truck/utils'

import { transitions } from './constants'

export const StyledRectangle = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.spacing.compact * 7}px;
  display: flex;
  flex-grow: 1;
  height: ${props => props.theme.spacing.compact * 13}px;
  justify-content: space-between;
  user-select: none;
  width: ${props => props.theme.spacing.cozy * 22}px;
`

export const ExpandContainer = styled.div<{ $isOpen: boolean; $disableAnimation: boolean }>`
  align-items: center;
  display: flex;
  opacity: 0;
  width: 0px;

  ${props =>
    !props.$disableAnimation &&
    props.$isOpen &&
    `
    transition: width ${transitions.expand.duration}ms ease-out,
      opacity ${transitions.fadeIn.duration}ms ease-out ${
        transitions.expand.duration + transitions.expand.delay + transitions.fadeIn.delay
      }ms;
    -webkit-transition: width ${transitions.expand.duration}ms ease-out,
      opacity ${transitions.fadeIn.duration}ms ease-out ${
        transitions.expand.duration + transitions.expand.delay + transitions.fadeIn.delay
      }ms;
    `}

  ${props =>
    !props.$disableAnimation &&
    !props.$isOpen &&
    `
    transition: opacity ${transitions.fadeOut.duration}ms ease-out ${transitions.fadeOut.delay}ms,
      width ${transitions.collapse.duration}ms ease-out ${
        transitions.fadeOut.duration + transitions.fadeOut.delay + transitions.collapse.delay
      }ms;
    -webkit-transition: opacity ${transitions.fadeOut.duration}ms ease-out ${transitions.fadeOut.delay}ms,
      width ${transitions.collapse.duration}ms ease-out ${
        transitions.fadeOut.duration + transitions.fadeOut.delay + transitions.collapse.delay
      }ms;
    `}
`

export const StyledNumericInput = styled(NumericInput)`
  -moz-appearance: textfield;
  appearance: none;
  background-color: transparent;
  border: none;
  color: ${props => props.theme.palette.grayscale70};
  height: ${props => props.theme.spacing.relaxed}px;
  padding: ${props => props.theme.spacing.compact}px;
  text-align: center;
  width: 100%;

  ${getTextStyle('h5Semibold')};

  ::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
`
export const StyledContainerIcon = styled.div<{ isMinValue?: boolean; disabled?: boolean }>`
  align-items: center;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: ${props => props.theme.spacing.compact * 0.5}px;
  min-height: ${props => props.theme.spacing.relaxed * 2}px;
  min-width: ${props => props.theme.spacing.relaxed * 2}px;

  :hover {
    filter: brightness(80%);
  }

  ${({ isMinValue, disabled }) =>
    (!isMinValue || !disabled) &&
    `
        background-color: var(--color-brand-secondary);
      `}

  ${({ isMinValue, theme }) =>
    isMinValue &&
    `
        background-color: ${theme.colors.white};
        border: none;
    `}

    ${({ disabled, theme }) =>
    disabled &&
    `
        pointer-events: none;
        opacity: 0.5;
        background-color: ${theme.palette.grayscale30};
        border: 1px solid ${theme.palette.grayscale40};
    `}
`
