import { isNil } from 'lodash'

const defaultTransformations = 'q_auto,f_auto'

const getUploadUrl = transformations => {
  if (!transformations) {
    return `/upload/${defaultTransformations}`
  }

  return `/upload/${transformations},${defaultTransformations}`
}

/**
 * Get a valid cloudinary url with transformations
 * note: some default transformations are always included
 *
 * @param {string} photo - Original cloudinary url
 * @param {string} transformations - e.g: e_trim,e_make_transparent,f_auto
 */
const applyFilters = (photo: string, transformations) => {
  if (isNil(photo)) {
    return null
  }

  return photo.replace('/upload', getUploadUrl(transformations))
}

const getFullUrl = (resourceUrl: string) => {
  return `https://res.cloudinary.com/riqra/image/upload/${resourceUrl}`
}

export default {
  applyFilters,
  getFullUrl,
}
