import { useState } from 'react'
import moment from 'moment-timezone'

/*
 * We remove time units to make the same request in the server and client.
 * Without it date ranges sent to the API could vary for a few seconds.
 *
 * With time units:
 * - server: 2020-10-10 09:50:20
 * - client: 2020-10-10 09:50:25
 *
 * Without time units:
 * - server: 2020-10-10 09:00:00
 * - client: 2020-10-10 09:00:00
 */
const normalizeDate = (date: moment.Moment) => {
  return date.minutes(0).seconds(0).milliseconds(0)
}

const useDateRangeFilter = () => {
  const [state, setState] = useState(() => ({
    from: normalizeDate(moment().subtract(1, 'month')),
    to: normalizeDate(moment()),
  }))

  const onChange = (from: moment.Moment, to: moment.Moment) => {
    let tempTo = to

    if (to.isSame(state.to) && from.isAfter(to)) {
      tempTo = from
    }

    let tempFrom = from

    if (from.isSame(state.from) && to.isBefore(from)) {
      tempFrom = to
    }

    setState({
      from: tempFrom,
      to: tempTo,
    })
  }

  return { ...state, onChange }
}

export default useDateRangeFilter
