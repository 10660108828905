import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import cn from '~/lib/cn'
import Loader from '~/truck/loader'

const newButtonVariants = cva(
  'relative inline-flex items-center justify-center gap-2 rounded text-base font-semibold transition-colors ease-out disabled:cursor-not-allowed disabled:text-neutral-300',
  {
    variants: {
      mode: {
        normal: 'disabled:border disabled:bg-neutral-100',
        ghost: 'border disabled:border-neutral-200 disabled:bg-neutral-100',
        plain: 'disabled:bg-neutral-100',
        transparent: '',
      },
      variant: {
        primary: '',
        secondary: '',
        critical: '',
      },
      size: {
        default: 'h-12 w-full min-w-20 px-4',
        icon: 'size-12 rounded-full',
      },
    },
    compoundVariants: [
      {
        mode: 'normal',
        variant: 'primary',
        className: 'bg-brand-primary text-brand-primary-on-bg hover:bg-brand-primary-dark',
      },
      {
        mode: 'normal',
        variant: 'secondary',
        className: 'bg-brand-secondary text-brand-secondary-on-bg hover:bg-brand-secondary-dark',
      },
      {
        mode: 'normal',
        variant: 'critical',
        className: 'bg-critical text-white hover:bg-critical-hover',
      },

      {
        mode: 'ghost',
        variant: 'primary',
        className:
          'border-brand-primary bg-white text-brand-primary hover:bg-brand-primary hover:text-brand-primary-on-bg',
      },
      {
        mode: 'ghost',
        variant: 'secondary',
        className:
          'border-brand-secondary bg-white text-brand-secondary hover:bg-brand-secondary hover:text-brand-secondary-on-bg',
      },
      {
        mode: 'ghost',
        variant: 'critical',
        className: 'border-critical bg-white text-critical hover:bg-critical hover:text-white',
      },
      {
        mode: 'plain',
        variant: 'primary',
        className: 'bg-white text-brand-primary',
      },
      {
        mode: 'plain',
        variant: 'secondary',
        className: 'bg-white text-brand-secondary',
      },
      {
        mode: 'plain',
        variant: 'critical',
        className: 'bg-white text-critical',
      },
      {
        mode: 'transparent',
        variant: 'primary',
        className: 'text-brand-primary hover:text-brand-primary-dark',
      },
      {
        mode: 'transparent',
        variant: 'secondary',
        className: 'text-brand-secondary hover:text-brand-secondary-dark',
      },
      {
        mode: 'transparent',
        variant: 'critical',
        className: 'hover:text-critical-dark text-critical',
      },
    ],
    defaultVariants: {
      mode: 'normal',
      variant: 'primary',
      size: 'default',
    },
  },
)

export interface NewButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof newButtonVariants> {
  asChild?: boolean
  loading?: boolean
}

const NewButton = React.forwardRef<HTMLButtonElement, NewButtonProps>(
  ({ asChild = false, className, disabled, children, loading, mode, variant, size, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'

    return (
      <Comp
        className={cn(newButtonVariants({ mode, variant, size, className }))}
        ref={ref}
        disabled={loading ? true : disabled}
        {...props}
      >
        {loading ? (
          <>
            <Loader className="absolute" color="currentColor" />
            <div className="invisible inline-flex gap-2">{children}</div>
          </>
        ) : (
          children
        )}
      </Comp>
    )
  },
)

NewButton.displayName = 'NewButton'

export default NewButton
