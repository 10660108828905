import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import clsx from 'clsx'

import { categoriesQuery } from '~/gql/queries'

import Content from './Content'
import Trigger from './Trigger'

interface TreeMenuProps {
  className?: string
}

function Tree({ className }: TreeMenuProps) {
  const { data } = useQuery(categoriesQuery)

  const categories = data?.vendor?.categories ?? []

  return (
    <div className={clsx('group', className)}>
      <Trigger className="relative z-[26] border-b border-r bg-white" />
      <Content
        className="invisible absolute top-full opacity-0 transition-opacity ease-in group-hover:visible group-hover:opacity-100"
        dataSource={categories}
      />
    </div>
  )
}

export default Tree
