import React from 'react'
import { isString, some } from 'lodash'

import Anchor from '~/truck/anchor'
import Icon from '~/truck/icon'
import Label from '~/truck/label'

import { Container, Input, InputContainer, SquaredFrame } from './elements'

const componentsWithText = [Label, Anchor]

type CheckboxProps = React.ComponentPropsWithoutRef<'input'> & {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
  disabled?: boolean
  textStyle?: string
  color?: string
}

function Checkbox(props: CheckboxProps) {
  const { children, className, style, disabled, textStyle, color, ...inputProps } = props

  const hasText = some(React.Children.toArray(children), child => {
    return isString(child) || componentsWithText.includes((child as any).type)
  })

  return (
    <Container
      style={style}
      className={className}
      $disabled={disabled}
      $hasText={hasText}
      $color={color}
      htmlFor={inputProps.id}
    >
      <InputContainer>
        <Input disabled={disabled} $color={color} {...inputProps} />
        <SquaredFrame />
        <Icon type="check" size="sm" />
      </InputContainer>
      <Label as="span" $textStyle={textStyle}>
        {children}
      </Label>
    </Container>
  )
}

export default Checkbox
