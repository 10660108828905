import gql from 'graphql-tag'

import { documentFragment } from '../fragments'

const upsertDocumentMutation = gql`
  mutation upsertDocument(
    $documentId: ID
    $fiscalName: String
    $fiscalAddress: String
    $documentNumber: String!
    $documentTypeId: ID
    $lineOfBusiness: String
  ) {
    upsertDocument: createOrUpdateDocument(
      input: {
        documentId: $documentId
        fiscalName: $fiscalName
        fiscalAddress: $fiscalAddress
        lineOfBusiness: $lineOfBusiness
        documentNumber: $documentNumber
        documentTypeId: $documentTypeId
      }
    ) {
      document {
        ...document
      }
    }
  }
  ${documentFragment}
`

export default upsertDocumentMutation
