import React from 'react'
import styled from 'styled-components'

import Icon from '~/truck/icon'
import Label from '~/truck/label'
import theme from '~/truck/theme'

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.cozy}px;
  padding: ${theme.spacing.comfortable}px 0;
`

interface HeadingProps {
  style?: React.CSSProperties
  className?: string
  iconName: string
  title: string
  children?: React.ReactNode
}

function Heading({ iconName, children, title, style, className }: HeadingProps) {
  return (
    <Container style={style} className={className}>
      <Icon color="black" type={iconName} size="xl" interactive={false} />
      <Label as="span">{title}</Label>
      {children}
    </Container>
  )
}

export default Heading
