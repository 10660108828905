import gql from 'graphql-tag'

import { orderStatusFragment, pageInfoFragment } from '../fragments'

const userOrdersQuery = gql`
  query userOrders($page: Int, $orderStatusId: ID, $from: Date, $to: Date) {
    user {
      id
      orders(page: $page, orderStatusId: $orderStatusId, from: $from, to: $to) {
        items {
          id
          code
          createdAt
          total
          status {
            ...orderStatus
          }
          needsReview
          review {
            id
            rating
            comment
          }
        }
        pageInfo {
          ...pageInfo
        }
      }
    }
  }
  ${orderStatusFragment}
  ${pageInfoFragment}
`

export default userOrdersQuery
