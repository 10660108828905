import React, { Fragment } from 'react'
import styled from 'styled-components'
import { useFlag } from 'toggled'

import AddressLine from '~/components/address-line'
import LocationSelector from '~/components/location-selector'
import Map from '~/components/map'
import useFormatters from '~/hooks/use-formatters'
import { GoogleMapsProvider } from '~/providers'
import Field from '~/truck/field'
import Label from '~/truck/label'
import theme from '~/truck/theme'
import { flags } from '~/utils/constants'

const Container = styled.div`
  height: auto;
  position: relative;

  > input,
  > div {
    margin: 0;
    max-width: none;
    width: 100%;
  }

  > *:not(:last-child) {
    margin-bottom: ${theme.spacing.comfortable}px;
  }
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;

  > * {
    flex: 1;
  }

  > *:not(:last-child) {
    margin-right: ${theme.spacing.comfortable}px;
  }
`

const getInitialCoordinates = address => {
  const coordinates = address?.coordinates

  if (!coordinates) {
    return null
  }

  return {
    lat: coordinates.latitude,
    lng: coordinates.longitude,
  }
}

const UpsertAddressForm = props => {
  const { coverageTree, address } = props

  const disabledCheckbox = address?.isDefault

  const hasMapInCreation = useFlag(flags.MAP_IN_ADDRESS_CREATION)

  const initialCoordinates = getInitialCoordinates(address)

  const Parent = hasMapInCreation ? GoogleMapsProvider : Fragment

  const formatters = useFormatters()

  return (
    <Parent>
      <Container>
        <Label as="p" $textStyle="h5Semibold">
          Dirección
        </Label>
        <LocationSelector coverageTree={coverageTree} />
        <AddressLine />
        {hasMapInCreation && <Map dynamic initialCoordinates={initialCoordinates} />}
        <Field name="reference" placeholder="Referencia" />
        <Row>
          <Field name="contactPerson" placeholder="Contacto" />
          <Field name="contactPhone" placeholder="Celular" parse={formatters.phone} type="tel" />
        </Row>
        <Field as="checkbox" name="isDefault" hasText disabled={disabledCheckbox}>
          Dirección principal
        </Field>
      </Container>
    </Parent>
  )
}

export default UpsertAddressForm
