import type { JSXElementConstructor, PropsWithChildren, ReactElement, ReactNode } from 'react'
import React from 'react'
import Tippy from '@tippyjs/react'
import styled from 'styled-components'
import type { Placement } from 'tippy.js'

type TooltipProps<T> = {
  content: ReactNode
  placement?: Placement
  children: ReactElement<T, string | JSXElementConstructor<T>>
}

const StyledTippy = styled(Tippy)`
  background-color: transparent !important;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.12))
    drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));

  .tippy-content {
    border-radius: ${props => props.theme.spacing.compact}px;
    background-color: ${props => props.theme.colors.black};
    color: ${props => props.theme.colors.white};
    font-weight: 600;
    text-align: center;
    padding: ${({ theme }) => `${theme.spacing.cozy}px ${theme.spacing.comfortable}px`};
  }

  .tippy-arrow {
    color: ${props => props.theme.colors.black};
  }
`

export const Tooltip: <T>(props: PropsWithChildren<TooltipProps<T>>) => JSX.Element = props => {
  return (
    <StyledTippy content={props.content} placement={props.placement || 'bottom'}>
      {props.children}
    </StyledTippy>
  )
}
