import React from 'react'
import { map, take } from 'lodash'

import EntityLines from '~/truck/entity-lines'
import type { ILocation } from '~/types/shipping'

const limitOfLocalities = 2

interface AddressProps {
  id: string
  line: string
  location: ILocation[]
  style?: React.CSSProperties
  className?: string
  selectable?: boolean
  removable?: boolean
  onRemove?: (id: string) => void
}

const getLines = (line: string, location: ILocation[]) => {
  const localities = take(location, limitOfLocalities)

  const localityNames = map(localities, 'name')

  return [line, ...localityNames]
}

const Address = ({ id, line, location, style, className, selectable, removable, onRemove }: AddressProps) => {
  return (
    <EntityLines
      id={id}
      removable={removable}
      selectable={selectable}
      lines={getLines(line, location)}
      style={style}
      className={className}
      onRemove={onRemove}
    />
  )
}

export default Address
