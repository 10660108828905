import styled from 'styled-components'

import theme from '~/truck/theme'

export const ActionContainer = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: ${theme.spacing.cozy}px;
  z-index: 4;

  > span {
    margin-top: ${theme.spacing.compact}px;
  }
`
