import React, { useRef, useState } from 'react'
import styled from 'styled-components'

import Modal from '~/components/modal'
import Wysiwyg from '~/components/wysiwyg'
import { useModalManager } from '~/hooks'
import Icon from '~/truck/icon'
import Overlay from '~/truck/overlay'
import theme from '~/truck/theme'
import { cloudinary } from '~/utils'
import { transformations } from '~/utils/constants'

import type { PopUp } from '../../types'

const StyledModal = styled(Modal.Standalone)`
  border-radius: ${theme.spacing.compact}px;
  height: auto;
  margin: auto 4px;
  max-height: calc(100vh - ${theme.spacing.cozy * 4}px);
  overflow: hidden;
  padding: ${theme.spacing.compact}px;

  width: auto;
`

const StyledOverlay = styled(Overlay)`
  display: flex;
  padding: 0;
`

const ContentContainer = styled.div`
  display: flex;
  overflow: auto;
`

const StyledIcon = styled(Icon)`
  background: ${theme.palette.grayscale40};
  border-radius: 50%;
  box-sizing: content-box;
  padding: ${theme.spacing.cozy}px;
  position: absolute;
  right: ${theme.spacing.cozy}px;
  top: ${theme.spacing.cozy}px;
`

const StyledImage = styled.img`
  display: block;
  height: 100%;
  width: 100%;
`

const StyledA = styled.a`
  height: 100%;
  width: 100%;
`

interface ModalPopUpProps {
  popUp: PopUp
  modalId: number
  onAfterClose?: () => void
}

const ModalPopUp = (props: ModalPopUpProps) => {
  const { popUp, modalId, onAfterClose } = props

  const [show, setShow] = useState(false)

  const containerRef = useRef(null)

  const modalManager = useModalManager()

  const popUpImage = cloudinary.applyTransformations(popUp.image, transformations.POP_UP)

  const handleClose = () => {
    modalManager.closeModal(modalId)

    onAfterClose?.()
  }

  const Image = () => (
    <StyledImage
      src={popUpImage}
      alt="popup-image"
      onLoad={() => {
        setShow(true)
      }}
      onError={() => {
        console.error('Error al cargar la imagen: ', popUp.image)

        modalManager.closeModal(modalId)
      }}
    />
  )

  return (
    <StyledOverlay open style={{ display: show ? 'flex' : 'none' }} blocking={show}>
      <StyledModal>
        <StyledIcon size="sm" type="close" color="black" onClick={handleClose} />
        <Wysiwyg />
        <ContentContainer ref={containerRef} className="wysiwyg">
          {popUp.link ? (
            <StyledA href={popUp.link} target="_blank" rel="noopener noreferrer" onClick={handleClose}>
              <Image />
            </StyledA>
          ) : (
            <Image />
          )}
        </ContentContainer>
      </StyledModal>
    </StyledOverlay>
  )
}

export default ModalPopUp
