import * as React from 'react'
import { isEmpty } from 'lodash'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useFlag } from 'toggled'

import { BulkOrderingModal } from '~/components/bulk-ordering-modal'
import ShippingModal from '~/components/shipping-modal'
import ShippingSelector from '~/components/shipping-selector'
import { useModalManager, useMultiDistributorSession, useUser } from '~/hooks'
import useVendor from '~/hooks/use-vendor'
import Button from '~/truck/button'
import Icon from '~/truck/icon'
import ScrollBlocker from '~/truck/scroll-blocker'
import theme from '~/truck/theme'
import type { ICategory, ISubCategory } from '~/types/category'
import { flags } from '~/utils/constants'

import CategoriesSection from './CategoriesSection'
import { Container } from './elements'
import FeaturedItem from './FeaturedItem'
import ListItem from './ListItem'

interface DrawerProps {
  isOpen: boolean
  closeDrawer: () => void
  categories: Record<string, any>[] // TODO: use category type
}

interface State {
  categoryLevel: number
  categories: Record<string, any>[]
  currentCategory?: ICategory | ISubCategory
}

function Content({ categories, isOpen, closeDrawer }: DrawerProps) {
  const router = useRouter()

  const user = useUser()

  const containerRef = React.useRef<HTMLDivElement>(null)

  const vendor = useVendor()

  const [state, setState] = React.useState<State>({
    categories,
    categoryLevel: 1,
  })

  React.useEffect(() => {
    const onTransitionEnd = () => {
      if (!isOpen) {
        setState(prevState => ({ ...prevState, categories, categoryLevel: 1 }))
      }
    }

    containerRef.current?.addEventListener('transitionend', onTransitionEnd)

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      containerRef.current?.removeEventListener('transitionend', onTransitionEnd)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const hasListStoresFlag = useFlag(flags.LIST_STORES)

  const hasPartners = useFlag(flags.MULTI_VENDOR_NAVIGATION)

  const hasPublicPartnerVendorFF = useFlag(flags.PUBLIC_PARTNER_VENDOR)

  const hasMultiDistributorD2CFF = useFlag(flags.MULTI_DISTRIBUTOR_D2C)

  const { multiDistributorSession } = useMultiDistributorSession()

  const showMyFavorites = useFlag(flags.MY_FAVORITES)

  const allowRepurchase = useFlag(flags.ALLOW_REPURCHASE)

  const hasBulkOrderingFF = useFlag(flags.BULK_ORDERING)

  const modalManager = useModalManager()

  const hidePartnerVendorLink = vendor.manufacturer.hasOnePartner

  const showSubcategories = category => {
    const { subcategories } = category

    const { categoryLevel } = state

    return setState({
      ...state,
      categories: subcategories,
      categoryLevel: categoryLevel + 1,
      currentCategory: category,
    })
  }

  const onCategoryClick = category => {
    const { subcategories } = category

    if (!isEmpty(subcategories)) {
      return showSubcategories(category)
    }

    const { currentCategory } = state

    const { slug: categorySlug, encodedId: categoryEncodedId } = currentCategory

    const { slug: subcategorySlug, encodedId: subcategoryEncodedId } = category

    closeDrawer()

    let route = `/c/${categorySlug}-${categoryEncodedId}/${subcategorySlug}-${subcategoryEncodedId}`

    if (currentCategory.id === category.id) {
      route = `/c/${category.slug}-${category.encodedId}`
    }

    router.push(route)
  }

  const onBackClick = () => {
    setState({ ...state, categories, categoryLevel: 1 })
  }

  const isCategory = state.categoryLevel === 1

  return (
    <>
      {isOpen && <ScrollBlocker />}
      <Container isOpen={isOpen} ref={containerRef}>
        {isCategory && (
          <nav className="flex shrink-0 justify-around py-4">
            {user.isLoggedIn ? (
              <Link href="/mi-cuenta" passHref>
                <FeaturedItem label="Mi cuenta" icon="profile" onClick={closeDrawer} />
              </Link>
            ) : (
              <Link href="/ingresar" passHref>
                <FeaturedItem label="Ingresar" icon="profile" onClick={closeDrawer} />
              </Link>
            )}
            {user.isLoggedIn && showMyFavorites && (
              <Link href="/mi-cuenta/favoritos" passHref>
                <FeaturedItem label="Favoritos" icon="heart" onClick={closeDrawer} />
              </Link>
            )}
            <Link href="/carrito" passHref>
              <FeaturedItem label="Carrito" icon="cart" isPrimary onClick={closeDrawer} />
            </Link>
            <Link href="/mi-cuenta/pedidos" passHref>
              <FeaturedItem label="Mis pedidos" icon="order" onClick={closeDrawer} />
            </Link>
          </nav>
        )}
        <ShippingSelector
          style={{
            padding: `${theme.spacing.cozy}px ${theme.spacing.comfortable}px`,
          }}
        />
        {vendor.beetrackWidgetKey && isCategory && (
          <div className="mx-4 mb-4 shrink-0">
            <Link href="/seguimiento">
              <Button.Ghost color="secondary">
                <Icon type="tracking" />
                <span>Seguir mi pedido</span>
              </Button.Ghost>
            </Link>
          </div>
        )}
        {isCategory && (
          <div className="shrink-0 divide-y border-b border-t">
            {user.isLoggedIn && allowRepurchase && (
              <Link href="/mi-cuenta/mas-comprados" passHref>
                <ListItem name="Volver a comprar" leftIcon="update" />
              </Link>
            )}
            {hasBulkOrderingFF && (
              <ListItem
                name="Compra masiva"
                leftIcon="archive-arrow-up"
                onClick={event => {
                  event.preventDefault()

                  if (hasMultiDistributorD2CFF && !hasPublicPartnerVendorFF && !multiDistributorSession) {
                    modalManager.openModal(ShippingModal)
                  } else {
                    modalManager.openModal(BulkOrderingModal)
                  }

                  closeDrawer()
                }}
              />
            )}
            {hasListStoresFlag && (
              <Link href="/tiendas" passHref>
                <ListItem name="Tiendas" leftIcon="store" />
              </Link>
            )}
            <Link href="/cobertura" passHref>
              <ListItem name="Cobertura" leftIcon="location" />
            </Link>
            {hasPartners && !hidePartnerVendorLink && (
              <Link href="/distribuidores" passHref>
                <ListItem name="Distribuidores" leftIcon="group" />
              </Link>
            )}
          </div>
        )}
        <CategoriesSection
          level={state.categoryLevel}
          currentCategory={state.currentCategory}
          dataSource={state.categories}
          onItemClick={onCategoryClick}
          onBackClick={onBackClick}
        />
      </Container>
    </>
  )
}

export default Content
