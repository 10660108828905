import React from 'react'
import { withGoogleMap, withScriptjs } from 'react-google-maps'

import Loader from '~/components/loader'
import useVendor from '~/hooks/use-vendor'
import { googleMapUrl } from '~/utils'

import BaseMap from '../base-map'

import { Container, MapElement } from './elements'

const MapWidget = withScriptjs(withGoogleMap(BaseMap))

const Map = props => {
  const vendor = useVendor()

  return (
    <MapWidget
      googleMapURL={googleMapUrl(vendor.googleMapsApiKey)}
      loadingElement={<Loader style={{ flexGrow: 1, width: 'unset' }} />}
      containerElement={<Container />}
      mapElement={<MapElement />}
      {...props}
    />
  )
}

export default Map
