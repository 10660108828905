import styled from 'styled-components'

import Image from '~/truck/image'
import theme from '~/truck/theme'

export const StyledImage = styled(Image)<{ $size?: 'small' | 'large' }>`
  height: ${theme.spacing.cozy * 4}px;
  max-width: ${theme.spacing.cozy * 16}px;
  object-fit: contain;
  vertical-align: middle;

  ${props =>
    props.$size === 'large' &&
    `
    height: ${theme.spacing.cozy * 7}px;
    max-width: ${theme.spacing.cozy * 20}px;
  `}
`
