import * as React from 'react'

import { Text } from './elements'

interface TitleProps {
  children: React.ReactNode
  style?: React.CSSProperties
  className?: string
}

function Title(props: TitleProps) {
  const { children, style, className } = props

  return (
    <Text as="p" $textStyle="h5Semibold" style={style} className={className}>
      {children}
    </Text>
  )
}

export default Title
