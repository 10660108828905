import React from 'react'

import { Slider } from '~/components/slider'
import type { IProduct } from '~/types/products'

import { SuggestedProduct } from './SuggestedProduct'

type SuggestedProductsSliderProps = {
  products: IProduct[]
  slidesToScroll?: number
}

const SLIDES_BY_SCROLL = 4

export const SuggestedProductsSlider = (props: SuggestedProductsSliderProps) => {
  if (!props.products) {
    return null
  }

  return (
    <Slider slidesToScroll={SLIDES_BY_SCROLL}>
      {props.products.map(product => (
        <SuggestedProduct {...product} photo={product.defaultPhoto} key={product.slug} />
      ))}
    </Slider>
  )
}
