import gql from 'graphql-tag'

import { productFragment, productPhotoFragment } from '../fragments'

const vendorProductQuery = gql`
  query vendorProduct($slug: String!) {
    vendor {
      id
      product(slug: $slug) {
        ...product
        photos {
          ...productPhoto
        }
        mainVariant {
          description
          category {
            name
            slug
            encodedId
            parent {
              name
              slug
              encodedId
            }
          }
        }
        variants {
          ...product
          photos {
            ...productPhoto
          }
        }
      }
    }
  }
  ${productFragment}
  ${productPhotoFragment}
`

export default vendorProductQuery
