import React from 'react'

import Action from '../action'

import { Container } from './elements'

const FormActions = props => {
  const { cancellable = true, onCancelClick, onCreateClick, style, className } = props

  return (
    <Container style={style} className={className}>
      <Action $disabled={!cancellable} $color="error" onClick={onCancelClick}>
        Cancelar
      </Action>
      <Action $color="info" onClick={onCreateClick}>
        Guardar
      </Action>
    </Container>
  )
}

export default FormActions
