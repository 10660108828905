import { isArray, mergeWith } from 'lodash'

const customizer = (current: any, source: any) => {
  if (isArray(current)) {
    return current.concat(source)
  }
}

const queryUpdater = (previous: Record<string, any>, response: Record<string, any>) => {
  const { fetchMoreResult, variables } = response

  if (variables.page === 1) {
    return fetchMoreResult
  }

  return mergeWith({}, previous, fetchMoreResult, customizer)
}

export default queryUpdater
