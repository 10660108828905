import cookie from 'isomorphic-cookie'
import { pullAt, split } from 'lodash'

import type { ICatalogProduct } from '~/types/catalog'
import type { IProduct } from '~/types/products'
import type { IUser } from '~/types/users'
import type { IVendor } from '~/types/vendors'

import type { AnalyticsProductEntity } from './analytics-types'

export function getAnalyticsClientId() {
  const analyticsCookie = cookie.load('_ga')

  if (!analyticsCookie) {
    return
  }

  // the third and fourth values are the ones that compose the clientId
  const clientIdParts = pullAt(split(analyticsCookie, '.'), [2, 3])

  return clientIdParts.join('.')
}

export function collectGlobalVariables(vendor: IVendor, user: IUser) {
  const dimensions: Record<string, any> = {
    vendor_id: vendor.isPartnerVendor ? vendor.manufacturer.originalId : vendor.originalId,
    vendor_name: vendor.isPartnerVendor ? vendor.manufacturer.name : vendor.name,
    vendor_endpoint: vendor.isPartnerVendor ? vendor.manufacturer.endpoint : vendor.endpoint,
    client_id: getAnalyticsClientId() ?? null,
  }

  if (vendor.isPartnerVendor) {
    dimensions.partner_vendor_id = vendor.originalId

    dimensions.partner_vendor_name = vendor.name

    dimensions.partner_vendor_endpoint = vendor.endpoint
  }

  if (user.isLoggedIn) {
    if (user.originalId) {
      dimensions.user_id = user.originalId
    }

    if (user.vendorCode) {
      dimensions.user_code = user.vendorCode
    }
  }

  dimensions.session_status = user.isLoggedIn ? 'Sesión iniciada' : 'Sesión no iniciada'

  return dimensions
}

function getCategoryHierarchy(product) {
  const subcategory = product.category?.name

  const category = product.category?.parent?.name

  return [category, subcategory].filter(Boolean).join('/')
}

export function formatProduct(product: IProduct | ICatalogProduct): AnalyticsProductEntity {
  const itemPrice = product.pricing?.price || product.price

  const item: AnalyticsProductEntity = {
    item_id: product.sku || product.product?.sku,
    item_name: product.name,
    price: Number(itemPrice),
    // when product is added or removed from cart we receive a lineItem which has an internal product object
    item_category: getCategoryHierarchy(product.product || product),
  }

  if (product.hasVariants) {
    item.item_variant = product.presentation
  }

  return item
}

export function formatLineItem(lineItem) {
  const item = formatProduct(lineItem)

  item.quantity = lineItem.quantity

  if (!item.price) {
    item.price = Number(lineItem.price)
  }

  return item
}

export function getTotalValue(formattedLineItems) {
  return formattedLineItems.reduce((acc, lineItem) => acc + lineItem.price * lineItem.quantity, 0)
}
