import React from 'react'
import styled from 'styled-components'

import theme from '~/truck/theme'

import Header from './header'

interface PanelProps {
  title: string
  isEditable?: boolean
  onEditClick?: () => void
  className?: string
  style?: React.CSSProperties
  headerStyle?: React.CSSProperties
  children: React.ReactNode
}

const Container = styled.section`
  > div {
    margin-bottom: ${theme.spacing.cozy}px;
  }

  > article {
    display: flex;
    flex-direction: column;
  }
`

const Panel = (props: PanelProps) => {
  const { title, isEditable, onEditClick, children, style, headerStyle, className } = props

  return (
    <Container style={style} className={className}>
      <Header style={headerStyle} title={title} isEditable={isEditable} onEditClick={onEditClick} />
      <article>{children}</article>
    </Container>
  )
}

export default Panel
