import gql from 'graphql-tag'

const changePasswordMutation = gql`
  mutation changePassword($newPassword: String!, $confirmPassword: String!) {
    changePassword(input: { newPassword: $newPassword, confirmPassword: $confirmPassword }) {
      status
    }
  }
`

export default changePasswordMutation
