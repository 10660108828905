import React from 'react'

import ScrollBlocker from '../scroll-blocker'

import { Container } from './elements'

interface OverlayProps {
  open: boolean
  blocking?: boolean
  children?: React.ReactNode
  onClick?: () => void
  onMouseDown?: () => void
  style?: React.CSSProperties
  className?: string
}

const Overlay = ({ open, blocking = true, onClick, onMouseDown, style, className, children }: OverlayProps) => {
  const shouldBlock = open && blocking

  return (
    <Container $open={open} onClick={onClick} onMouseDown={onMouseDown} style={style} className={className}>
      {shouldBlock && <ScrollBlocker />}
      {children}
    </Container>
  )
}

export default Overlay
