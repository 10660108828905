import gql from 'graphql-tag'

import { checkoutFragment } from '../fragments'

const setPendingOrderState = gql`
  mutation setPendingOrderState($isPending: Boolean!) {
    setPendingOrderState(input: { isPending: $isPending }) {
      order {
        ...checkout
      }
    }
  }
  ${checkoutFragment}
`

export default setPendingOrderState
