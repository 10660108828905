import * as React from 'react'
import styled from 'styled-components'

import Icon from '~/truck/icon'
import { getTextStyle } from '~/truck/utils'

export interface FloatingHeaderProps {
  onClose: () => void
  title: string
}

const HeaderContainer = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  display: flex;
  height: ${props => props.theme.spacing.cozy * 7}px;
  justify-content: center;
  position: relative;
  text-align: center;

  ${getTextStyle('h4Semibold')};
`

const CloseIconContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: ${props => props.theme.spacing.cozy * 7}px;
  justify-content: center;
  left: 0;
  padding-left: ${props => props.theme.spacing.comfortable}px;
  padding-right: ${props => props.theme.spacing.comfortable}px;
  position: absolute;
`

export function FloatingHeader(props: FloatingHeaderProps) {
  const { title, onClose } = props

  return (
    <HeaderContainer>
      <CloseIconContainer onClick={onClose}>
        <Icon type="close" />
      </CloseIconContainer>
      {title}
    </HeaderContainer>
  )
}
