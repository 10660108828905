import React from 'react'

import Checkbox from '~/truck/checkbox'
import Input from '~/truck/input'
import Label from '~/truck/label'
import Radio from '~/truck/radio'
import Select from '~/truck/select'
import TextArea from '~/truck/text-area'

import { Container } from './elements'

function getField(as: string) {
  if (as === 'select') {
    return Select
  }

  if (as === 'textarea') {
    return TextArea
  }

  if (as === 'checkbox') {
    return Checkbox
  }

  if (as === 'radio') {
    return Radio
  }

  return Input
}

interface ErrorBoundaryProps {
  className?: string
  style?: React.CSSProperties
  as?: string
  component?: React.ElementType
  error?: string

  [key: string]: any
}

const ErrorBoundary = React.forwardRef<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement, ErrorBoundaryProps>(
  ({ className, style, as, component, ...rest }, ref) => {
    const FieldToRender = component || getField(as)

    return (
      <Container style={style} className={className}>
        <FieldToRender {...rest} ref={ref} />
        {rest.error && (
          <Label as="span" $textStyle="h6Regular" $color="error">
            {rest.error}
          </Label>
        )}
      </Container>
    )
  },
)

export default ErrorBoundary
