import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-final-form'
import { isEmpty } from 'lodash'

import { useFieldValue } from '~/hooks'
import useVendor from '~/hooks/use-vendor'
import { defaultCoordinates } from '~/utils/constants'

const baseUrl = 'https://maps.googleapis.com/maps/api/js?libraries=places'

/**
 * @param {Object} options
 * @param {boolean} options.enabled Enable the hook
 * @param {boolean} options.loadAddressComponents Make an initial call to geocoding service to get addressComponents saved to the current address
 */
const useGoogleMap = options => {
  const { enabled, loadAddressComponents } = options

  const [loadingScript, setLoadingScript] = useState(enabled)

  const form = useForm()

  const latitude = useFieldValue('coordinates.latitude')

  const longitude = useFieldValue('coordinates.longitude')

  const getCoordinates = () => {
    if (latitude && longitude) {
      return {
        lat: latitude,
        lng: longitude,
      }
    }
  }

  const initialCoordinates = getCoordinates()

  const divMapRef = useRef()

  const mapRef = useRef()

  const autocompleteRef = useRef()

  const vendor = useVendor()

  useEffect(() => {
    if (!loadingScript || window?.google?.maps) {
      setLoadingScript(false)

      return
    }

    window.mapsInit = () => {
      setLoadingScript(false)
    }

    const script = document.createElement('script')

    script.src = `${baseUrl}&key=${vendor.googleMapsApiKey}&callback=mapsInit`

    script.async = true

    document.head.appendChild(script)
  }, [])

  const initialCenter = vendor?.settings?.shipping?.mapCoordinates ?? defaultCoordinates

  useEffect(() => {
    const isNotReady = loadingScript || !divMapRef.current

    if (isNotReady || !enabled || mapRef.current) {
      return
    }

    const mapOptions = {
      zoom: 13,
      disableDefaultUI: true,
      center: initialCoordinates || initialCenter,
    }

    mapRef.current = new window.google.maps.Map(divMapRef.current, mapOptions)

    const setCustomerLocation = location => {
      const { coords } = location

      const { latitude: lat, longitude: lng } = coords

      const newCenter = { lat, lng }

      mapRef.current.setCenter(newCenter)
    }

    if (!initialCoordinates) {
      navigator.geolocation.getCurrentPosition(setCustomerLocation)
    }
  }, [loadingScript, enabled, divMapRef.current])

  useEffect(() => {
    const isNotReady = loadingScript || !mapRef.current

    if (isNotReady || !enabled || autocompleteRef.current) {
      return
    }

    const node = document.getElementById('google-address-line')

    autocompleteRef.current = new window.google.maps.places.Autocomplete(node, {
      types: ['address'],
    })

    const geocoder = new window.google.maps.Geocoder()

    const fields = ['formatted_address', 'place_id', 'geometry']

    autocompleteRef.current.setFields(fields)

    const setAddressComponents = coordinates => {
      geocoder.geocode(coordinates, responses => {
        if (isEmpty(responses)) {
          return
        }

        const [address] = responses

        form.change('addressComponents', address.address_components)
      })
    }

    const onPlaceChanged = () => {
      const place = autocompleteRef.current.getPlace()

      if (!place.geometry) {
        form.change('hasSelectedLocation', false)

        return
      }

      const { geometry } = place

      const { location } = geometry

      mapRef.current.setCenter(location)

      mapRef.current.setZoom(15)

      form.change('googleAddressLine', '')

      form.change('addressLine', place.formatted_address)

      form.change('coordinates.latitude', location.lat())

      form.change('coordinates.longitude', location.lng())

      const coordinates = {
        location: { lat: location.lat(), lng: location.lng() },
      }

      setAddressComponents(coordinates)
    }

    if (loadAddressComponents && initialCoordinates) {
      setAddressComponents({ location: initialCoordinates })
    }

    autocompleteRef.current.addListener('place_changed', onPlaceChanged)

    const onCenterChanged = () => {
      form.change('hasSelectedLocation', true)
    }

    mapRef.current.addListener('center_changed', onCenterChanged)

    const setAddressOnInput = responses => {
      if (isEmpty(responses)) {
        return
      }

      const [address] = responses

      form.change('googleAddressLine', '')

      form.change('addressLine', address.formatted_address)

      const { geometry } = address

      const { location } = geometry

      form.change('coordinates.latitude', location.lat())

      form.change('coordinates.longitude', location.lng())

      form.change('addressComponents', address.address_components)
    }

    const onDragEnd = () => {
      const currentCenter = mapRef.current.getCenter()

      /* 
        this check is needed because on mobile map movement is handled
        differently and when you are actually dragging the map is not moving,
        you need to use two fingers
      */
      if (currentCenter.lat() === initialCenter.lat && currentCenter.lng() === initialCenter.lng) {
        return
      }

      const location = { latLng: currentCenter }

      geocoder.geocode(location, setAddressOnInput)
    }

    mapRef.current.addListener('dragend', onDragEnd)
  }, [loadingScript, enabled, mapRef.current])

  return { loadingScript, mapRef, divMapRef }
}

export default useGoogleMap
