import { create } from 'zustand'

import type { CounterLastValueStoreState } from './cart-counter-last-value-store-types'

export const useCounterLastValueStore = create<CounterLastValueStoreState>(set => {
  const initialState = {
    map: new Map(),
  }

  return {
    ...initialState,

    setLastValue(id, value) {
      set(state => {
        state.map.set(id, value)

        return state
      })
    },

    getLastValue(id) {
      if (!initialState.map.has(id)) {
        initialState.map.set(id, 0)
      }

      return initialState.map.get(id)
    },
  }
})
