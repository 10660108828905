import styled from 'styled-components'

import theme from '~/truck/theme'

export const InnerContainer = styled.div`
  padding: ${theme.spacing.comfortable}px;
  padding-top: 0;

  > p:nth-child(even) + * {
    margin-top: ${theme.spacing.comfortable}px;
  }
`

export const HtmlContainer = styled.div`
  padding: ${theme.spacing.relaxed}px ${theme.spacing.comfortable}px;
`
