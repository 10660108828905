import gql from 'graphql-tag'

const removeDocumentMutation = gql`
  mutation removeDocument($documentId: ID!) {
    removeDocument(input: { documentId: $documentId }) {
      document {
        id
      }
    }
  }
`

export default removeDocumentMutation
