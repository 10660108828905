import styled, { css } from 'styled-components'

import Field from '~/truck/field'
import Icon from '~/truck/icon'
import Label from '~/truck/label'
import theme from '~/truck/theme'

import { DialogFooter } from '../dialog/footer'
import { DialogHeader } from '../dialog/header'

export const StyledContainer = styled.div<{ $isStandaloneModal: boolean }>`
  background-color: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.dimensions.borderRadius}px;
  display: flex;
  flex-direction: column;
  width: calc(100% - ${props => props.theme.spacing.cozy * 4}px);

  ${props =>
    !props.$isStandaloneModal &&
    css`
      max-width: ${props => props.theme.spacing.comfortable * 25}px;
      padding: ${props => props.theme.spacing.comfortable}px;
      position: abolute;
    `}

  ${props =>
    props.$isStandaloneModal &&
    css`
      max-width: ${props => props.theme.spacing.comfortable * 28}px;
    `}
`

export const StyledDialogHeader = styled(DialogHeader)<{ $isStandaloneModal: boolean; $isMobile: boolean }>`
  align-items: center;
  height: ${props => props.theme.spacing.relaxed * 3}px;
  padding: ${theme.spacing.comfortable}px ${theme.spacing.relaxed}px;

  ${props =>
    !props.$isStandaloneModal &&
    css`
      align-items: flex-start;
      height: ${props => props.theme.spacing.comfortable * 2}px;
      padding: 0;
    `}

  ${props =>
    props.$isStandaloneModal &&
    css`
      > div[role='button'] {
        padding: ${props => props.theme.spacing.cozy}px;
      }
    `}   

  ${props =>
    props.$isStandaloneModal &&
    props.$isMobile &&
    css`
      padding: ${props => props.theme.spacing.cozy}px ${props => props.theme.spacing.comfortable}px;
    `}
`

export const StyledCloseIcon = styled(Icon)`
  margin-bottom: ${props => props.theme.spacing.comfortable}px;
`

export const StyledDialogContent = styled.div<{ $isStandaloneModal: boolean; $isMobile: boolean }>`
  padding: 0;
  ${props =>
    props.$isStandaloneModal &&
    css`
      padding: ${props => props.theme.spacing.relaxed}px;
    `}

  ${props =>
    props.$isStandaloneModal &&
    props.$isMobile &&
    css`
      padding: ${props => props.theme.spacing.relaxed}px ${props => props.theme.spacing.comfortable}px;
    `}
`

export const StyledFieldLabel = styled(Label)`
  margin-top: ${props => props.theme.spacing.cozy}px;
`

export const StyledField = styled(Field)`
  input {
    text-align: center;
  }
`

export const StyledDialogFooter = styled(DialogFooter)<{ $isStandaloneModal: boolean; $isMobile: boolean }>`
  border-top: none;
  margin-top: 0;

  ${props =>
    !props.$isStandaloneModal &&
    css`
      margin-top: ${props => props.theme.spacing.comfortable}px;
      padding: 0;

      > button {
        width: 100%;
      }
    `}

  ${props =>
    props.$isStandaloneModal &&
    css`
      padding: ${props => props.theme.spacing.relaxed}px;

      > button {
        width: auto;
      }
    `}

  ${props =>
    props.$isStandaloneModal &&
    props.$isMobile &&
    css`
      padding: ${props => props.theme.spacing.comfortable}px;

      > button {
        width: 100%;
      }
    `}
`
