import styled from 'styled-components'

import Input from '~/truck/input'

export const StyledPinInputField = styled(Input)`
  height: ${props => props.theme.spacing.relaxed * 2}px;
  padding: 0;
  text-align: center;
  width: ${props => props.theme.spacing.relaxed * 2}px;
`
