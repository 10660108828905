import gql from 'graphql-tag'

import partialProductFragment from './partial-product'
import productAttributesFragment from './product-attributes'

const productFragment = gql`
  fragment product on Product {
    ...partialProduct
    stock
    hasVariants
    jumpFactor
    salesUnitFactor
    minimumSalesQuantity
    maximumSalesQuantity
    favoriteSince
    inventoryUnit {
      id
      code
    }
    reviewStats {
      rating
      numOfReviews
      oneStar
      twoStars
      threeStars
      fourStars
      fiveStars
    }
    variantInStock {
      id
      slug
    }
    pricing {
      oldPrice
      price
      ribbonLabel
      isTaxExempt
    }
    attributes {
      ...productAttributes
    }
    attributesSummary
    tierPricing {
      oldPrice
      price
      ribbonLabel
      minimumQuantity
    }
  }
  ${productAttributesFragment}
  ${partialProductFragment}
`

export default productFragment
