import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { isEmpty, omit } from 'lodash'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import { useFeature } from 'toggled'

import { signupToSeePricesMutation } from '~/gql/mutations'
import useCoverage from '~/hooks/use-coverage'
import { getDivisionLevelsFromLocationSelector } from '~/hooks/use-location-selector'
import useModalManager from '~/hooks/use-modal-manager'
import notifier from '~/truck/notifier'
import { formValidators, handleSubmissionErrors } from '~/utils'
import callbackRedirection, { saveSession } from '~/utils/after-auth'
import { flags } from '~/utils/constants'

import Modal from '../modal'

import {
  AddressPageContent,
  BusinessDocumentPageContent,
  DocumentPageContent,
  UserInfoPageContent,
  Wizard,
} from './components'

export const StyledModal = styled(Modal)`
  max-height: 100vh;
  overflow-y: hidden;
  padding: 0;
`

const StyledContent = styled.div`
  overflow-y: auto;
  padding: ${props => props.theme.spacing.comfortable}px;

  ${up('lg')} {
    padding: ${props => props.theme.spacing.cozy * 4}px;
  }
`

const SignupToSeePricesForm = props => {
  const { coverageTree, allLocalities } = props

  const [signupToSeePrices] = useMutation(signupToSeePricesMutation)

  const { closeModal } = useModalManager()

  const showPriceAfterLoginFF = useFeature(flags.SHOW_PRICE_AFTER_LOGIN)

  const { signupToSeePricesValidators } = formValidators

  const onSubmit = async values => {
    const { localityId, line, documentTypeIdWithSlug, documentNumber, fiscalName, fiscalAddress, ...rest } = omit(
      values,
      ['acceptedPrivacyPolicy'],
    )

    const [documentTypeId] = documentTypeIdWithSlug.split('-')

    const variables = {
      deliveryAddress: null,
      document: null,
      ...rest,
    }

    const divisionLevels = getDivisionLevelsFromLocationSelector(allLocalities, values)

    if (localityId) {
      variables.deliveryAddress = {
        localityId,
        line,
        ...divisionLevels,
      }
    }

    if (documentTypeId) {
      variables.document = {
        documentNumber,
        fiscalName,
        fiscalAddress,
        documentTypeId,
      }
    }

    try {
      const response = await signupToSeePrices({ variables })

      notifier.success('Cuenta creada con éxito!')

      closeModal()

      saveSession(response.data.signup)

      callbackRedirection()
    } catch (err) {
      const submissionErrors = handleSubmissionErrors(err)

      if (!isEmpty(submissionErrors.email)) {
        notifier.error(submissionErrors.email, { timeout: 10 * 1000 })
      }

      return submissionErrors
    }
  }

  if (showPriceAfterLoginFF?.settings?.forBusinessOnly) {
    return (
      <StyledModal onClose={() => closeModal()}>
        <StyledContent>
          <Wizard onSubmit={onSubmit}>
            <Wizard.Page validate={signupToSeePricesValidators.document}>
              <BusinessDocumentPageContent />
            </Wizard.Page>
            <Wizard.Page validate={signupToSeePricesValidators.fiscalAddress}>
              <AddressPageContent coverageTree={coverageTree} forBusinessOnly />
            </Wizard.Page>
            <Wizard.Page validate={signupToSeePricesValidators.userInfo}>
              <UserInfoPageContent forBusinessOnly />
            </Wizard.Page>
          </Wizard>
        </StyledContent>
      </StyledModal>
    )
  }

  return (
    <StyledModal onClose={() => closeModal()}>
      <StyledContent>
        <Wizard onSubmit={onSubmit}>
          <Wizard.Page validate={signupToSeePricesValidators.userInfo}>
            <UserInfoPageContent />
          </Wizard.Page>
          <Wizard.Page validate={signupToSeePricesValidators.deliveryAddress}>
            <AddressPageContent coverageTree={coverageTree} />
          </Wizard.Page>
          <Wizard.Page validate={signupToSeePricesValidators.document}>
            <DocumentPageContent />
          </Wizard.Page>
        </Wizard>
      </StyledContent>
    </StyledModal>
  )
}

function SignupToSeePricesModal() {
  const { loading, coverageTree, allLocalities } = useCoverage()

  if (loading) {
    return null
  }

  return <SignupToSeePricesForm coverageTree={coverageTree} allLocalities={allLocalities} />
}

export default SignupToSeePricesModal
