import { parseCookies, setCookie } from '~/lib/cookies'

import { cookieNames } from './constants'
import isProduction from './is-production'

export interface GetHostnameOptions {
  ctx?: PageContext
  cookies?: Record<string, unknown>
}

const defaultHostname = 'companyname.riqra.com'

function getProductionHostname(req: PageContext['req']) {
  if (!req) {
    return window.location.hostname
  }

  return req.hostname
}

function extractHostname(url: string) {
  try {
    return new URL(url).hostname
  } catch {
    return url
  }
}

function getRequestedHostname(ctx?: PageContext) {
  const requestedHostname = ctx?.query?.[cookieNames.ENDPOINT] as string

  return requestedHostname ? extractHostname(requestedHostname) : null
}

export default function getHostname(options: GetHostnameOptions) {
  const { ctx, cookies = parseCookies(ctx) } = options

  if (isProduction) {
    return getProductionHostname(ctx?.req)
  }

  const savedHostname = cookies[cookieNames.ENDPOINT] as string

  const hostname = getRequestedHostname(ctx) || savedHostname || defaultHostname

  if (hostname !== savedHostname) {
    setCookie(ctx, cookieNames.ENDPOINT, hostname, { secure: false })
  }

  return hostname
}
