function getInitialValues(multiDistributorSession) {
  const deliveryAddress = multiDistributorSession?.deliveryAddress

  const store = multiDistributorSession?.store

  const shippingType = multiDistributorSession?.shippingType

  const initialValues = {
    shippingTypeId: 'delivery',
  }

  if (deliveryAddress) {
    Object.assign(initialValues, {
      coordinates: deliveryAddress.coordinates,
      addressLine: deliveryAddress.line,
      addressId: deliveryAddress.id,
    })
  }

  if (store) {
    Object.assign(initialValues, {
      storeId: store.id,
      storeName: store.name,
    })
  }

  if (shippingType) {
    initialValues.shippingTypeId = shippingType.isDelivery ? 'delivery' : 'pickup'
  }

  return initialValues
}

export default getInitialValues
