import React from 'react'
import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'

import { useVendor } from '~/hooks'
import { getMetaInfo } from '~/utils'

import ProductMeta from './product'

export interface MetaProps {
  title?: string
  description?: string
  image?: string
  url?: string
  siteName?: string
  noRobots?: boolean
  asCanonical?: boolean
  prevPage?: string
  nextPage?: string
}

function getAdditionalLinkTags(metadata) {
  const linkTags = []

  if (metadata.prevPage) {
    linkTags.push({ rel: 'prev', url: `${metadata.url}?page=${metadata.prevPage}` })
  }

  if (metadata.nextPage) {
    linkTags.push({ rel: 'next', url: `${metadata.url}?page=${metadata.nextPage}` })
  }

  return linkTags
}
function Meta(props: MetaProps) {
  const vendor = useVendor()

  const router = useRouter()

  const metaInfo = getMetaInfo({
    vendor,
    asPath: router.asPath,
  })

  const metadata = {
    ...metaInfo,
    ...props,
  }

  return (
    <NextSeo
      title={metadata.title}
      description={metadata.description}
      noindex={metadata.noRobots}
      nofollow={metadata.noRobots}
      canonical={metadata.asCanonical && metadata.url}
      additionalLinkTags={getAdditionalLinkTags(metadata)}
      twitter={{
        cardType: 'summary_large_image',
      }}
      openGraph={{
        url: metadata.url,
        title: metadata.title,
        description: metadata.description,
        type: 'website',
        images: [
          {
            url: metadata.image,
          },
        ],
        site_name: metadata.siteName,
      }}
    />
  )
}

Meta.Product = ProductMeta

export default Meta
