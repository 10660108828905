import React from 'react'
import styled from 'styled-components'
import { useFeature } from 'toggled'

import Anchor from '~/truck/anchor'
import theme from '~/truck/theme'
import cloudinary from '~/utils/cloudinary'
import { flags } from '~/utils/constants'

const Container = styled.div`
  > a {
    align-items: center;
    display: flex;
  }
`

const complaintsBook = cloudinary.getFullUrl('v1616443867/sellers/resources/complaints-book.png')

const CustomerComplaints = props => {
  const { style, className } = props

  const customerComplaintsFF = useFeature(flags.CUSTOMER_COMPLAINTS)

  const url = customerComplaintsFF?.settings?.url ?? '/libro-de-reclamaciones'

  return (
    <Container className={className} style={style}>
      <Anchor href={url} target="_blank" rel="noopener noreferrer" $color="white">
        <img height={theme.spacing.cozy * 7} alt="complaints book" src={complaintsBook} />
      </Anchor>
    </Container>
  )
}

export default CustomerComplaints
