import React, { createContext, useContext, useState } from 'react'

const DebounceHandlerContext = createContext(null)

export const useDebounceHandler = () => {
  return useContext(DebounceHandlerContext)
}

export const DebounceHandlerProvider = ({ children }) => {
  const [isDebouncing, setIsDebouncing] = useState('')

  const handlerStartDebounce = (value: string) => {
    setIsDebouncing(value)
  }

  const handlerEndDebounce = (value: string) => {
    setIsDebouncing(prevValue => (prevValue === value ? '' : prevValue))
  }

  return (
    <DebounceHandlerContext.Provider
      value={{
        isDebouncing: isDebouncing !== '',
        startDebounce: handlerStartDebounce,
        endDebounce: handlerEndDebounce,
      }}
    >
      {children}
    </DebounceHandlerContext.Provider>
  )
}
