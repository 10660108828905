import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { createHttpLink } from 'apollo-link-http'
import { SentryLink } from 'apollo-link-sentry'
import debug from 'debug'
import cookie from 'isomorphic-cookie'
import { isNil } from 'lodash'
import { v4 as uuid4 } from 'uuid'

import resolvers from '~/gql/resolvers'
import typeDefs from '~/gql/type-defs'
import { channel, cookieNames, httpStatusCodes } from '~/utils/constants'
import getEndpoint from '~/utils/get-endpoint'

import isBrowser from './utils/is-browser'
import { isProduction, logout } from './utils'

interface NetworkError extends Error {
  statusCode: number
}

const logger = debug('sellers:hocs:with-app:init-apollo')

const getHeaders = (context: PageContext) => {
  const { res, req, pathname } = context

  const oldEndpoint = cookie.load(cookieNames.ENDPOINT, req)

  const endpoint = getEndpoint(req, res)

  if (!isProduction && !isNil(oldEndpoint) && oldEndpoint !== endpoint) {
    return logout(context)
  }

  const session = cookie.load(cookieNames.SESSION, req) || req.session

  const requestId = uuid4()

  const headers = {
    'X-Request-Id': requestId,
    endpoint,
    channel,
    authorization: `Bearer ${session.token}`,
  }

  const storeId = cookie.load(cookieNames.STORE_ID, req)

  if (storeId) {
    headers['store-id'] = storeId
  }

  const partnerVendor = cookie.load(cookieNames.PARTNER_VENDOR_ENDPOINT, req)

  const isSelectingPartner = pathname === '/partner-vendors'

  if (partnerVendor && !isSelectingPartner) {
    headers[cookieNames.PARTNER_VENDOR_ENDPOINT] = partnerVendor
  }

  const expressDeliverySession = cookie.load(cookieNames.EXPRESS_DELIVERY_SESSION, req)

  if (expressDeliverySession?.shippingType?.slug === 'express-delivery') {
    headers['express-delivery'] = true
  }

  return headers
}

export const createApolloClient = (initialState: NormalizedCacheObject, context = {} as PageContext) => {
  const httpLink = createHttpLink({
    uri: operation => `${process.env.API}/graphql?operationName=${operation.operationName}`,
    credentials: 'same-origin',
  })

  const sentryLink = new SentryLink({
    breadcrumb: {
      enable: true,
      includeQuery: false,
      includeVariables: true,
      includeError: true,
      includeResponse: true,
    },
  })

  const errorLink = onError(error => {
    if (error.graphQLErrors) {
      error.graphQLErrors.forEach(graphQLError => {
        logger('GraphQL error:', graphQLError)

        if (graphQLError.message === 'El cliente está deshabilitado') {
          logout(context)
        }
      })
    }

    if (error.networkError) {
      logger('Network error status:', error.networkError)

      if ((error.networkError as NetworkError).statusCode === httpStatusCodes.UNAUTHORIZED) {
        logout(context)
      }
    }
  })

  const authLink = setContext((_, prevContext) => {
    let headers = getHeaders(context)

    if (prevContext.getHeaders) {
      headers = prevContext.getHeaders(headers)
    }

    return { ...prevContext, headers }
  })

  const cache = new InMemoryCache().restore(initialState)

  return new ApolloClient({
    cache,
    typeDefs,
    resolvers,
    ssrMode: !isBrowser,
    connectToDevTools: isBrowser,
    link: ApolloLink.from([errorLink, sentryLink, authLink, httpLink]),
  })
}
