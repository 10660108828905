import React, { CSSProperties, PropsWithChildren } from 'react'
import { useField } from 'react-final-form'

import { PhoneNumberInput } from '~/components'
import { Tooltip } from '~/components'
import { PinInput, PinInputField } from '~/components/pin-input'
import Label from '~/truck/label'
import theme from '~/truck/theme'
import cloudinary from '~/utils/cloudinary'

import { BaseContainer, ContentContainer, HStack, PinContainer, Separator, StyledLabel, StyledTitle } from './elements'

// TODO: pending to refactor UI package
const QuestionMarkIcon = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 13.8867C10.9518 13.8867 13.75 11.0885 13.75 7.63672C13.75 4.18494 10.9518 1.38672 7.5 1.38672C4.04822 1.38672 1.25 4.18494 1.25 7.63672C1.25 11.0885 4.04822 13.8867 7.5 13.8867Z"
      stroke="#52575C"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.68164 5.76159C5.82858 5.34388 6.11861 4.99165 6.50036 4.76729C6.88211 4.54294 7.33095 4.46092 7.76738 4.53578C8.2038 4.61064 8.59965 4.83754 8.88481 5.17629C9.16998 5.51504 9.32605 5.94379 9.32539 6.38659C9.32539 7.63659 7.45039 8.26159 7.45039 8.26159"
      stroke="#52575C"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M7.5 10.7617H7.50625" stroke="#52575C" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

// TODO: pending to refactor UI package
const ExclamationIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.99967 14.6977C11.6816 14.6977 14.6663 11.7129 14.6663 8.03099C14.6663 4.34909 11.6816 1.36432 7.99967 1.36432C4.31778 1.36432 1.33301 4.34909 1.33301 8.03099C1.33301 11.7129 4.31778 14.6977 7.99967 14.6977Z"
      stroke="#A0A4A8"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8 10.6977V8.03101" stroke="#A0A4A8" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 5.36432H8.00667" stroke="#A0A4A8" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

const InfoText = ({ style, children }: PropsWithChildren<{ style: CSSProperties }>) => (
  <HStack style={{ alignItems: 'center', ...style }}>
    <ExclamationIcon />
    <Label $textStyle="h6Regular" style={{ color: '#A0A4A8', marginLeft: `${theme.spacing.compact}px` }}>
      {children}
    </Label>
  </HStack>
)

export const CulqiYapeForm = () => {
  const { input: phoneInput } = useField('phone')

  const { input: codeInput } = useField('code')

  return (
    <BaseContainer>
      <StyledTitle $textStyle="h5Semibold">Datos de tu yape</StyledTitle>
      <img
        width="32px"
        src={cloudinary.applyTransformations(
          cloudinary.getFullUrl('v1668522071/sellers/resources/culqi-logos/yape-logo.png'),
          'h_64',
        )}
      />
      <InfoText style={{ marginTop: `${theme.spacing.comfortable}px` }}>Se procesan pagos hasta S/ 500</InfoText>
      <Separator />
      <ContentContainer>
        <div>
          <HStack>
            <StyledLabel $textStyle="h6Semibold" style={{ marginRight: `${theme.spacing.compact}px` }}>
              Ingresa tu celular Yape
            </StyledLabel>
            <Tooltip
              content={
                <p>
                  Recuerda no exceder
                  <br />
                  tu monto límite de yapeo
                </p>
              }
            >
              <div>
                <QuestionMarkIcon />
              </div>
            </Tooltip>
          </HStack>
          <PhoneNumberInput
            areaCode="+51"
            placeholder="000 000 000"
            limit={9}
            id="yape[phone]"
            data-culqi="yape[phone]"
            value={phoneInput.value}
            name={phoneInput.name}
            onChange={phoneInput.onChange}
          />
        </div>
        <div style={{ marginTop: `${theme.spacing.comfortable}px` }}>
          <StyledLabel $textStyle="h6Semibold">Código de aprobación</StyledLabel>
          <PinContainer>
            <PinInput
              id="yape[code]"
              data-culqi="yape[code]"
              value={codeInput.value}
              name={codeInput.name}
              onChange={codeInput.onChange}
            >
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          </PinContainer>
        </div>
        <InfoText style={{ marginTop: `${theme.spacing.cozy}px` }}>Encuéntralo en el menú de Yape</InfoText>
      </ContentContainer>
      <Separator style={{ marginBottom: `${theme.spacing.comfortable}px` }} />
      <img
        height="32px"
        style={{ margin: 'auto' }}
        src={cloudinary.applyTransformations(
          cloudinary.getFullUrl('v1668522408/sellers/resources/culqi-logos/logo_culqi_black.png'),
          'h_64',
        )}
      />
    </BaseContainer>
  )
}
