import { useEffect, useState } from 'react'
import { find, isEqual, isNil } from 'lodash'
import { useRouter } from 'next/router'

import type { IProduct } from '~/types/products'

interface State {
  fallbackProduct: IProduct
  activeVariant: IProduct
}

const useVariantManager = (initialState: State, variants: IProduct[]) => {
  const [state, setState] = useState<State>(initialState)

  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0)

  const router = useRouter()

  const { fallbackProduct, activeVariant } = state

  const updateProduct = (newVariant: IProduct) => {
    setState({
      fallbackProduct: activeVariant || fallbackProduct,
      activeVariant: newVariant,
    })
  }

  const onVariantChange = (newVariant: IProduct) => {
    setSelectedPhotoIndex(0)

    updateProduct(newVariant)

    if (newVariant) {
      router.replace(`/p/${newVariant.slug}`, `/p/${newVariant.slug}`, {
        shallow: true,
      })
    }
  }

  useEffect(() => {
    if (isNil(variants)) return

    if (isNil(activeVariant)) return

    const newActiveVariant = find(variants, {
      id: activeVariant.id,
    })

    if (!isNil(newActiveVariant) && !isEqual(newActiveVariant, activeVariant)) {
      setState(prevState => ({
        ...prevState,
        activeVariant: newActiveVariant,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variants])

  return {
    fallbackProduct,
    activeVariant,
    onVariantChange,
    selectedPhotoIndex,
    setSelectedPhotoIndex,
  }
}

export default useVariantManager
