import React, { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import useFieldValue from '~/hooks/use-field-value'
import Field from '~/truck/field'
import FieldSubscription from '~/truck/field-subscription'
import Icon from '~/truck/icon'
import Image from '~/truck/image'
import Label from '~/truck/label'
import theme from '~/truck/theme'
import { baseUrls } from '~/utils/constants'

const Container = styled.div`
  position: relative;

  > :last-child {
    height: ${theme.spacing.cozy * 34}px;
  }
`

const StyledIcon = styled(Icon)`
  height: ${theme.spacing.cozy * 5}px;
  left: calc(50% - ${theme.spacing.cozy * 2.5}px);
  position: absolute;
  top: calc(50% - ${theme.spacing.cozy * 5}px);
  width: ${theme.spacing.cozy * 5}px;
  z-index: 1;
`

const SearchContainer = styled.div`
  left: ${theme.spacing.cozy * 2}px;
  position: absolute;
  right: ${theme.spacing.cozy * 2}px;
  top: ${theme.spacing.cozy * 2}px;
  z-index: 1;

  ${up('md')} {
    left: ${theme.spacing.cozy * 10}px;
    right: ${theme.spacing.cozy * 10}px;
  }
`

const SearchInput = styled(Field)`
  height: ${theme.spacing.cozy * 5}px;
`

const Error = styled(Label).attrs({
  color: 'error',
  textStyle: 'h6Regular',
})`
  margin-left: ${theme.spacing.cozy * 2}px;
`

const Paragraph = styled.p`
  background: ${theme.colors.white};
  border-radius: ${theme.dimensions.borderRadius}px;
  font-size: ${theme.spacing.compact * 3}px;
  margin-top: ${theme.spacing.cozy}px;
  padding: ${theme.spacing.cozy}px ${theme.spacing.cozy * 2}px;
`

const Reminder = styled.div`
  background-color: #d1e2f2;
  border: 1px solid ${theme.colors.white};
  border-radius: ${theme.spacing.cozy}px;
  bottom: ${theme.spacing.cozy * 2}px;
  display: flex;
  padding: ${theme.spacing.cozy}px ${theme.spacing.cozy * 3}px;
  position: absolute;
  right: ${theme.spacing.cozy * 2}px;
  z-index: 1;

  > img {
    height: ${theme.spacing.cozy * 4}px;
    margin-right: ${theme.spacing.compact * 3}px;
    width: ${theme.spacing.cozy * 4}px;
  }

  > label {
    color: #025db9;
    width: ${theme.spacing.cozy * 14}px;
  }
`

const Map = props => {
  const { mapRef, divMapRef } = props

  const [areNoResults, setNoResults] = useState()

  const searchValue = useFieldValue('googleAddressLine')

  const validateResults = useCallback(
    debounce(value => {
      const container = document.getElementsByClassName('pac-container')

      const results = container.item(container.length - 1)?.childElementCount

      if (value && results === 0) {
        setNoResults(true)
      } else {
        setNoResults(false)
      }
    }, 500),
    [],
  )

  useEffect(() => {
    validateResults(searchValue)
  }, [searchValue])

  useEffect(() => {
    if (mapRef?.current) {
      mapRef.current.addListener('dragstart', () => setNoResults(false))
    }
  }, [mapRef?.current])

  return (
    <>
      <Container>
        <SearchContainer>
          <SearchInput
            id="google-address-line"
            name="googleAddressLine"
            placeholder="Busca tu dirección"
            rightItem={<Icon type="search" />}
          />
          {areNoResults && (
            <Paragraph>
              No pudimos encontrar tu dirección, <strong>intenta ubicándola en el mapa</strong>
            </Paragraph>
          )}
        </SearchContainer>
        <StyledIcon type="location" size="xl" color="error" />
        <Reminder>
          <Image src={`${baseUrls.CLOUDINARY}/v1582674687/sellers/resources/map-pin.png`} />
          <Label $textStyle="h6Bold">Te atenderemos en el punto del mapa.</Label>
        </Reminder>
        <div id="map" ref={divMapRef} />
      </Container>
      <FieldSubscription name="hasSelectedLocation">
        {value => value === false && <Error>Selecciona tu ubicación en el mapa</Error>}
      </FieldSubscription>
    </>
  )
}

export default Map
