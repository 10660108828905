import { useMemo } from 'react'
import { join } from 'lodash'
import moment from 'moment-timezone'

import { dateFormats } from '~/utils/constants'

import useVendor from './use-vendor'

function dateFormatter(value: moment.MomentInput, format: string) {
  return moment(value).format(format)
}

function stringFormatter(value: string, format: string | RegExp) {
  return join(value.match(format), '')
}

const createFormatter = (fn: (value: string, format: string) => string, format: string) => {
  return (value: string) => {
    if (!value) {
      return ''
    }

    return fn(value, format)
  }
}

const defaultFormatters = {
  numeric: /[\d]/g,
  billDocumentNumber: /[\d]/g,
  ticketDocumentNumber: /[\d]/g,
  phone: /[\d+ /(/)/+]/g,
  date: dateFormats.dateHumanized,
  basicDate: dateFormats.date,
  wordOnly: /[a-z \u00C0-\u00FF]+/giu,
}

const formattersByCountries = {
  PE: {
    billDocumentNumber: /[\d]/g,
    ticketDocumentNumber: /[\d]/g,
  },
  PA: {
    billDocumentNumber: /[^]/g,
    ticketDocumentNumber: /[^]/g,
  },
  MX: {
    billDocumentNumber: /[\da-zA-Z]/g,
  },
  CL: {
    billDocumentNumber: /[k\d.-]/gi,
    ticketDocumentNumber: /[k\d.-]/gi,
  },
}

const formattersByVendor = {
  'aje-panama': {
    billDocumentNumber: /[^]/g,
    ticketDocumentNumber: /[^]/g,
  },
  'aje-nicaragua': {
    billDocumentNumber: /[^]/g,
    ticketDocumentNumber: /[^]/g,
  },
  'aje-costa-rica': {
    billDocumentNumber: /[^]/g,
    ticketDocumentNumber: /[^]/g,
  },
  'dona-rosita': {
    billDocumentNumber: /[^]/g,
  },
}

function getFormatters(vendor) {
  const { country, slug } = vendor

  const formats = {
    ...defaultFormatters,
    ...formattersByCountries[country.code],
    ...formattersByVendor[slug],
  }

  return {
    numeric: createFormatter(stringFormatter, formats.numeric),
    billDocumentNumber: createFormatter(stringFormatter, formats.billDocumentNumber),
    ticketDocumentNumber: createFormatter(stringFormatter, formats.ticketDocumentNumber),
    phone: createFormatter(stringFormatter, formats.phone),
    date: createFormatter(dateFormatter, formats.date),
    basicDate: createFormatter(dateFormatter, formats.basicDate),
    wordOnly: createFormatter(stringFormatter, formats.wordOnly),
  }
}

const useFormatters = () => {
  const vendor = useVendor()

  return useMemo(() => getFormatters(vendor), [vendor])
}

export default useFormatters
