import _ from 'lodash'

function getFullName(firstName, lastName) {
  const names = []

  if (!_.isNil(firstName)) {
    names.push(firstName)
  }
  if (!_.isNil(lastName)) {
    names.push(lastName)
  }

  return _.join(names, ' ')
}

export function getContactPersonNameAndDocument(contactPersonInfo): string {
  const { firstName = '', lastName = '', document = '' } = contactPersonInfo

  if (!firstName && !lastName) {
    return document
  }

  const fullName = getFullName(firstName, lastName)

  const hasDocument = document && (firstName || lastName)

  return hasDocument ? `${fullName}, ${document}` : fullName
}
