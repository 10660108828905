import { map } from 'lodash'

import type { ICatalogProduct } from '~/types/catalog'
import type { ILineItem } from '~/types/line-items'
import type { IOrder } from '~/types/orders'
import type { IProduct } from '~/types/products'
import { isProduction } from '~/utils'
import { timeouts } from '~/utils/constants'

import { analyticsDataLayer } from './analytics-data-layer'
import { analyticsDebug } from './analytics-debug'
import { analyticsFbq } from './analytics-fbq'
import { analyticsGtag } from './analytics-gtag'
import {
  AnalyticsCollector,
  AnalyticsCollectorAddToCartVariables,
  AnalyticsCollectorBeginCheckoutVariables,
  AnalyticsCollectorCheckoutProgressVariables,
  AnalyticsCollectorCloseSuggestedProductsVariables,
  AnalyticsCollectorContactVariables,
  AnalyticsCollectorLoginVariables,
  AnalyticsCollectorOptions,
  AnalyticsCollectorPageViewVariables,
  AnalyticsCollectorPaymentErrorVariables,
  AnalyticsCollectorPurchaseVariables,
  AnalyticsCollectorRemoveFromCartVariables,
  AnalyticsCollectorSearchVariables,
  AnalyticsCollectorSelectItemOriginData,
  AnalyticsCollectorSelectItemVariables,
  AnalyticsCollectorSelectPromotionVariables,
  AnalyticsCollectorSignUpVariables,
  AnalyticsCollectorSocialMediaVariables,
  AnalyticsCollectorViewCartVariables,
  AnalyticsCollectorViewItemListOriginData,
  AnalyticsCollectorViewItemListVariables,
  AnalyticsCollectorViewItemVariables,
  AnalyticsCollectorViewPromotionVariables,
  AnalyticsContexts,
} from './analytics-types'
import { formatLineItem, formatProduct, getTotalValue } from './analytics-utils'

// timeouts are necessary for analytics to get the correct page urls and titles

const defaultProviders = [analyticsDataLayer, analyticsGtag, analyticsFbq]

const providers = isProduction ? defaultProviders : [...defaultProviders, analyticsDebug]

function dispatch<V = Record<string, any>>(
  method: keyof AnalyticsCollector,
  variables: V,
  options?: AnalyticsCollectorOptions,
) {
  for (const provider of providers) {
    provider[method](variables as any, options)
  }
}

export const analytics = {
  pageView: (options: AnalyticsCollectorOptions) => {
    const variables: AnalyticsCollectorPageViewVariables = {
      title: document.title,
      path: window.location.pathname,
      referrer: document.referrer,
      location: window.location.href,
    }

    setTimeout(() => {
      dispatch<AnalyticsCollectorPageViewVariables>('pageView', variables, options)
    }, timeouts.ANALYTICS_EVENT)
  },
  search: (term: string) => {
    const variables: AnalyticsCollectorSearchVariables = { term }

    dispatch<AnalyticsCollectorSearchVariables>('search', variables)
  },
  selectItem: (product: IProduct | ICatalogProduct, originData: AnalyticsCollectorSelectItemOriginData) => {
    const variables: AnalyticsCollectorSelectItemVariables = {
      product: formatProduct(product),
      itemListId: originData.itemListId,
      itemListName: originData.itemListName,
    }

    setTimeout(() => {
      dispatch<AnalyticsCollectorSelectItemVariables>('selectItem', variables)
    }, timeouts.ANALYTICS_EVENT)
  },
  viewItem: (product: IProduct, options: AnalyticsCollectorOptions) => {
    const formattedProduct = formatProduct(product)

    const variables: AnalyticsCollectorViewItemVariables = {
      product: formattedProduct,
      currency_code: options.vendor.country.currencyCode,
      value: formattedProduct.price,
      context: AnalyticsContexts.VIEW_PRODUCT,
    }

    setTimeout(() => {
      dispatch<AnalyticsCollectorViewItemVariables>('viewItem', variables)
    }, timeouts.ANALYTICS_EVENT)
  },
  viewItemList: (products: IProduct[] | ICatalogProduct[], originData: AnalyticsCollectorViewItemListOriginData) => {
    const formattedProducts = map(products, (product: IProduct | ICatalogProduct, index: number) => {
      const payload = formatProduct(product)

      payload.list_position = index + 1

      return payload
    })

    const variables: AnalyticsCollectorViewItemListVariables = {
      products: formattedProducts,
      context: AnalyticsContexts.VIEW_PRODUCT,
      itemListId: originData.itemListId,
      itemListName: originData.itemListName,
    }

    dispatch<AnalyticsCollectorViewItemListVariables>('viewItemList', variables)
  },
  addToCart: (product: IProduct, quantity: number, options: AnalyticsCollectorOptions) => {
    const formattedProduct = formatProduct(product)

    formattedProduct.quantity = quantity

    const variables: AnalyticsCollectorAddToCartVariables = {
      product: formattedProduct,
      value: formattedProduct.price * quantity,
      context: AnalyticsContexts.ADD_TO_CART,
      currency_code: options.vendor.country.currencyCode,
    }

    dispatch<AnalyticsCollectorAddToCartVariables>('addToCart', variables)
  },
  removeFromCart: (product: IProduct, options: AnalyticsCollectorOptions) => {
    const formattedProduct = formatLineItem(product)

    const variables: AnalyticsCollectorRemoveFromCartVariables = {
      product: formattedProduct,
      value: formattedProduct.price * product.quantity,
      context: AnalyticsContexts.REMOVE_FROM_CART,
      currency_code: options.vendor.country.currencyCode,
    }

    dispatch<AnalyticsCollectorRemoveFromCartVariables>('removeFromCart', variables)
  },
  viewCart: (cartLineItems: ILineItem[], options: AnalyticsCollectorOptions) => {
    const formattedLineItems = map(cartLineItems, formatLineItem)

    const value = getTotalValue(formattedLineItems)

    const variables: AnalyticsCollectorViewCartVariables = {
      products: formattedLineItems,
      value,
      currency_code: options.vendor.country.currencyCode,
    }

    dispatch<AnalyticsCollectorViewCartVariables>('viewCart', variables)
  },
  beginCheckout: (cartLineItems: ILineItem[], stepName: string, options: AnalyticsCollectorOptions) => {
    const formattedLineItems = map(cartLineItems, formatLineItem)

    const value = getTotalValue(formattedLineItems)

    const variables: AnalyticsCollectorBeginCheckoutVariables = {
      step_name: stepName,
      products: formattedLineItems,
      currency_code: options.vendor.country.currencyCode,
      value,
    }

    setTimeout(() => {
      dispatch<AnalyticsCollectorBeginCheckoutVariables>('beginCheckout', variables)
    }, timeouts.ANALYTICS_EVENT)
  },
  checkoutProgress: (stepNumber: number) => {
    const variables: AnalyticsCollectorCheckoutProgressVariables = {
      step_number: stepNumber,
    }

    setTimeout(() => {
      dispatch<AnalyticsCollectorCheckoutProgressVariables>('checkoutProgress', variables)
    }, timeouts.ANALYTICS_EVENT)
  },
  purchase: (order: IOrder, options: AnalyticsCollectorOptions) => {
    const formattedProducts = map(order.lineItems, lineItem => {
      const formattedProduct = formatProduct(lineItem as unknown as IProduct)

      formattedProduct.quantity = lineItem.quantity

      formattedProduct.price = Number(lineItem.price)

      return formattedProduct
    })

    const variables: AnalyticsCollectorPurchaseVariables = {
      order_id: order.id,
      order_total: Number(order.total),
      order_products: formattedProducts,
      order_delivery_cost: Number(order.deliveryCost),
      order_tax: Number(order.tax),
      currency_code: options.vendor.country.currencyCode,
      seller_name: options.vendor.name,
      phone: options.user.phone,
      email: options.user.email,
      context: AnalyticsContexts.PURCHASE,
    }

    dispatch<AnalyticsCollectorPurchaseVariables>('purchase', variables)
  },
  login: (method?: string) => {
    const variables: AnalyticsCollectorLoginVariables = {
      method,
      context: AnalyticsContexts.LOGIN,
    }

    dispatch<AnalyticsCollectorLoginVariables>('login', variables)
  },
  signUp: (method?: string) => {
    const variables: AnalyticsCollectorSignUpVariables = {
      method,
      context: AnalyticsContexts.SIGNUP,
    }

    dispatch<AnalyticsCollectorSignUpVariables>('signUp', variables)
  },
  preSignUpAttempt: () => {
    dispatch('preSignUpAttempt', {})
  },
  paymentError: (eventName: string, errorMessage: string, paymentMethod: string) => {
    const variables: AnalyticsCollectorPaymentErrorVariables = {
      error_message: errorMessage,
      event_name: eventName,
      payment_method: paymentMethod,
      context: AnalyticsContexts.PAYMENT,
    }

    dispatch<AnalyticsCollectorPaymentErrorVariables>('paymentError', variables)
  },
  contact: (type: 'mail' | 'telephone' | 'whatsapp') => {
    const variables: AnalyticsCollectorContactVariables = {
      type,
    }

    dispatch<AnalyticsCollectorContactVariables>('contact', variables)
  },
  socialMedia: (type: string) => {
    const variables: AnalyticsCollectorSocialMediaVariables = {
      type,
    }

    dispatch<AnalyticsCollectorSocialMediaVariables>('socialMedia', variables)
  },
  viewPromotion: (creativeSlot: string) => {
    const variables: AnalyticsCollectorViewPromotionVariables = {
      creative_slot: creativeSlot,
    }

    dispatch<AnalyticsCollectorViewPromotionVariables>('viewPromotion', variables)
  },
  selectPromotion: (creativeSlot: string) => {
    const variables: AnalyticsCollectorSelectPromotionVariables = {
      creative_slot: creativeSlot,
    }

    dispatch<AnalyticsCollectorSelectPromotionVariables>('selectPromotion', variables)
  },
  closeSuggestedProducts: (productsWereAdded: boolean) => {
    const variables: AnalyticsCollectorCloseSuggestedProductsVariables = {
      products_were_added: productsWereAdded,
    }

    setTimeout(() => {
      dispatch<AnalyticsCollectorCloseSuggestedProductsVariables>('closeSuggestedProducts', variables)
    }, timeouts.ANALYTICS_EVENT)
  },
}
