import { useApolloClient } from '@apollo/react-hooks'
import { useFlag } from 'toggled'

import { userCartQuery } from '~/gql/queries'
import { flags } from '~/utils/constants'

import { suggestedProductsQuery } from '../gql/queries'
import { filterProductsByLineItems } from '../utils'

export const useSuggestedProductsPrepare = () => {
  const apolloClient = useApolloClient()

  const showCartInMainLayoutFF = useFlag(flags.SHOW_CART_IN_MAIN_LAYOUT)

  return async () => {
    try {
      const suggestedProductsData = await apolloClient.query({
        query: suggestedProductsQuery,
        fetchPolicy: 'network-only',
      })

      const {
        data: { cart },
      } = await apolloClient.query({
        query: userCartQuery,
        fetchPolicy: 'cache-only',
        variables: {
          runPromotions: !showCartInMainLayoutFF,
        },
      })

      const lineItems = cart?.lineItems || []

      const products = filterProductsByLineItems(suggestedProductsData.data.suggestedProducts, lineItems)

      return products.length > 0
    } catch (error) {
      return false
    }
  }
}
