import * as React from 'react'
import renderHTML from 'react-html-parser'
import dynamic from 'next/dynamic'
import styled, { withTheme } from 'styled-components'
import { useFeature, useFlag } from 'toggled'

import AddToCartBlocker from '~/components/add-to-cart-blocker'
import { UnitTooltip } from '~/components/unit-tooltip'
import { useCartProps, useVendor } from '~/hooks'
import { getQuantityRange } from '~/hooks/use-counter'
import Icon from '~/truck/icon'
import NewButton from '~/truck/new-button/NewButton'
import theme from '~/truck/theme'
import { flags } from '~/utils/constants'
import { stack } from '~/variables'

import Price from '../price'
import RatingStatus from '../rating-status'
import Ribbon from '../ribbon'

import { ActionContainer } from './elements'
import { Container, LabelsContainer, StyledAnchor, StyledCounter } from './elements.desktop'
import ProductImage from './image'
import Overlay from './overlay'
import { getPriceFromTier, OutOfStockLabel, priceVisibility, unitTooltipDuration } from './shared'

const AddToFavoritesButtonCircular = dynamic(
  () => import('../add-to-favorites-button/add-to-favorites-button-circular'),
)

const StyledAddToFavoritesButtonCircularDesktop = styled(AddToFavoritesButtonCircular)`
  bottom: 0;
  display: flex;
  position: absolute;
  z-index: 2;
`

const Desktop = props => {
  const {
    hasVariants,
    hidePrice,
    salesUnitFactor,
    inventoryUnit,
    href,
    name,
    oldPrice,
    onClick,
    onSeePriceClick,
    onFavorite,
    outOfStockLabel,
    photo,
    presentation,
    ribbonLabel,
    unavailable,
    hideCounter,
    reviewStats,
    variantInStock,
    favoriteSince,
    showMyFavorites,
    isTaxExempt,
    tierPricing,
    hidePresentationInCatalog = false,
    attributesSummary,
    disableTaxLabel = false,
  } = props

  const { counterProps, returnedStock: stock } = useCartProps({ ...props })

  const hasReviewFlag = useFlag(flags.HAS_REVIEW)

  const shouldShowPresentation = !hidePresentationInCatalog && presentation

  const showSkuLabelFeature = useFeature(flags.SHOW_SKU_LABEL)

  const shouldShowSkuLabel = showSkuLabelFeature && !hasVariants

  const shouldShowAttributesSummary = attributesSummary && !hasVariants

  const hasUnits = useFlag(flags.UNITS)

  const hasStock = stock > 0

  const [counterValue, setCounterValue] = React.useState(counterProps.value || 0)

  const [isCounterOpen, setIsCounterOpen] = React.useState(false)

  const [counterError, setCounterError] = React.useState(null)

  const vendor = useVendor()

  const showPriceOverOptionsButton = vendor.settings?.products?.showPriceOverOptionsButton

  const showProductStock = useFeature(flags.SHOW_PRODUCT_STOCK)

  const handleStock = useFeature(flags.HANDLE_STOCK)

  const { maxValue: maxStock } = getQuantityRange(counterProps)

  const stockToDisplay = counterProps.maxValue ? maxStock : stock

  const shouldShowStock =
    showProductStock &&
    handleStock &&
    hasStock &&
    (!hasVariants || (hasVariants && showPriceOverOptionsButton)) &&
    counterProps.canJumpAtLeastOnce &&
    !unavailable &&
    !hidePrice

  const renderCounter = () => {
    if (hasVariants && showPriceOverOptionsButton) {
      return (
        <ActionContainer style={{ bottom: 'unset', right: `${theme.spacing.cozy * 2}px` }}>
          <NewButton asChild variant="secondary" size="icon" onClick={onClick}>
            <a href={href}>
              <Icon type="caret-right" color="currentColor" />
            </a>
          </NewButton>
        </ActionContainer>
      )
    }

    if (!hasStock || (hasVariants && !showPriceOverOptionsButton) || hideCounter) {
      return null
    }

    return (
      <AddToCartBlocker component={ActionContainer} style={{ bottom: 'unset', right: `${theme.spacing.cozy * 2}px` }}>
        {!hasUnits ? (
          <StyledCounter
            {...counterProps}
            onOpen={() => setIsCounterOpen(true)}
            onClose={() => setIsCounterOpen(false)}
            onError={setCounterError}
          />
        ) : (
          <UnitTooltip
            visible={isCounterOpen && counterValue > 0}
            duration={unitTooltipDuration}
            salesUnitFactor={salesUnitFactor}
            inventoryUnitCode={inventoryUnit?.code}
            quantity={counterValue}
            zIndex={stack.mainHeader - 1}
          >
            <StyledCounter
              {...counterProps}
              onChange={value => {
                counterProps.onChange(value)

                setCounterValue(value)
              }}
              onOpen={() => setIsCounterOpen(true)}
              onClose={() => setIsCounterOpen(false)}
              onError={setCounterError}
            />
          </UnitTooltip>
        )}
        {counterError && isCounterOpen && <StyledCounter.Error message={counterError} />}
      </AddToCartBlocker>
    )
  }

  const hasReviewEnabled = hasReviewFlag && reviewStats

  const showOptions = hasVariants && !showPriceOverOptionsButton

  const renderPriceContent = () => {
    if (hidePrice) {
      return (
        <NewButton variant="secondary" onClick={onSeePriceClick}>
          Ver precio
        </NewButton>
      )
    }

    if (showOptions) {
      return (
        <NewButton asChild variant="secondary">
          <a href={href} onClick={onClick}>
            Ver opciones
          </a>
        </NewButton>
      )
    }

    const price = getPriceFromTier(props)

    return (
      <Price
        style={{
          justifyContent: 'center',
          marginTop: 'auto',
          visibility: priceVisibility(hasVariants && !showPriceOverOptionsButton),
        }}
        value={price}
        oldValue={oldPrice}
        textStyle="h4Semibold"
        allowTaxLabel={!disableTaxLabel}
        allowDiscountLabel
        isTaxExempt={isTaxExempt}
        tierPricing={tierPricing}
        innerContainerStyle={{
          justifyContent: 'start',
        }}
      />
    )
  }

  const shouldRenderCounter = !hidePrice && stock !== null && counterProps.canJumpAtLeastOnce

  return (
    <Container>
      {isCounterOpen && <Overlay style={{ maxHeight: `${theme.spacing.compact * 56}px` }} />}
      <div style={{ position: 'relative' }} onClick={onClick} onKeyPress={onClick} role="presentation">
        {hasReviewEnabled && <RatingStatus className="absolute bottom-0 right-0 !z-[2]" rating={reviewStats?.rating} />}
        <ProductImage
          photo={photo}
          name={name}
          height={200}
          unavailable={unavailable}
          stock={stock}
          href={href}
          variantInStock={variantInStock}
          canJumpAtLeastOnce={counterProps.canJumpAtLeastOnce}
          label={<OutOfStockLabel outOfStockLabel={outOfStockLabel} textStyle="h3Semibold" />}
          style={{ position: 'unset' }}
        />
        {showMyFavorites && !showOptions && (
          <StyledAddToFavoritesButtonCircularDesktop onClick={onFavorite} favoriteSince={favoriteSince} />
        )}
      </div>
      {shouldRenderCounter && renderCounter()}
      {ribbonLabel && <Ribbon label={ribbonLabel} />}
      <LabelsContainer>
        <StyledAnchor onClick={onClick} href={href} $textStyle="h5Semibold" $color="black">
          {renderHTML(name)}
        </StyledAnchor>
        <div className="space-y-1.5 text-xs">
          {shouldShowPresentation && <p className="truncate text-secondary">{presentation}</p>}
          {shouldShowAttributesSummary && <p className="truncate text-tertiary">{attributesSummary}</p>}
          {shouldShowSkuLabel && <p className="truncate text-tertiary">SKU: {props.sku}</p>}
          {shouldShowStock && (
            <p className="text-green-600">
              <span className="font-semibold">{stockToDisplay > 9999 ? '+9999' : stockToDisplay}</span> en stock
            </p>
          )}
        </div>
      </LabelsContainer>
      {renderPriceContent()}
    </Container>
  )
}

export default withTheme(Desktop)
