import styled from 'styled-components'

import Label from '~/truck/label'

export const StyledContainer = styled.div`
  position: relative;
`

export const TextareaCounter = styled(Label)`
  position: absolute;
  text-align: right;
  transform: translateY(-100%);
  width: 100%;
`
