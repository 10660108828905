import * as React from 'react'
import { map } from 'lodash'
import Link from 'next/link'
import styled from 'styled-components'

import Anchor from '~/truck/anchor'
import Image from '~/truck/image'
import Label from '~/truck/label'
import theme from '~/truck/theme'

const Container = styled.div`
  background: ${theme.colors.grayLight};
  border-radius: ${theme.dimensions.borderRadius}px;
  display: flex;
  padding: ${theme.spacing.relaxed}px;

  > img {
    height: ${theme.spacing.cozy * 10}px;
    margin-right: ${theme.spacing.comfortable}px;
    min-width: ${theme.spacing.cozy * 10}px;
  }
`

const Message = props => {
  const { ordersToBePaid } = props

  function renderReference(order) {
    const orderRoute = `/mi-cuenta/pedidos/${order.id}`

    return (
      <Link href={orderRoute}>
        <Anchor href={orderRoute}>{order.code}</Anchor>
      </Link>
    )
  }

  if (ordersToBePaid?.length === 1) {
    return (
      <Label as="p">
        Tienes un proceso de pago pendiente de confirmación por parte de tu entidad financiera con el código{' '}
        {renderReference(ordersToBePaid[0])}. Por favor espera unos minutos y consulta más tarde para verificar la
        información de tu pago.
      </Label>
    )
  }

  return (
    <Label as="p">
      Tienes algunos procesos de pago pendientes de confirmación por parte de tu entidad financiera con los códigos{' '}
      {map(ordersToBePaid, renderReference)}. Por favor espera unos minutos y consulta más tarde para verificar la
      información de tu pago.
    </Label>
  )
}

const PendingPaymentNotice = props => {
  const { ordersToBePaid } = props

  return (
    <Container>
      <Image src="https://res.cloudinary.com/riqra/image/upload/v1591751254/sellers/resources/business-and-finance.png" />
      <div>
        <Label $textStyle="h5Semibold" as="p">
          Pago Pendiente
        </Label>
        <Message ordersToBePaid={ordersToBePaid} />
      </div>
    </Container>
  )
}

export default PendingPaymentNotice
