import * as React from 'react'

// Ref: https://github.com/w3c/IntersectionObserver/blob/fee9107d70ae632a8195beeacf982d2a579cad37/polyfill/intersection-observer.js#L19-L34
function canIUseIntersectionObserver() {
  if (window.supportsIntersectionObserver) {
    return window.supportsIntersectionObserver
  }

  window.supportsIntersectionObserver = false

  // Exit early if all IntersectionObserver and IntersectionObserverEntry
  // features are natively supported.
  if (
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype
  ) {
    // Minimal polyfill for Edge 15's lack of `isIntersecting`
    // See: https://github.com/w3c/IntersectionObserver/issues/211
    if (!('isIntersecting' in window.IntersectionObserverEntry.prototype)) {
      Object.defineProperty(window.IntersectionObserverEntry.prototype, 'isIntersecting', {
        get() {
          return this.intersectionRatio > 0
        },
      })
    }

    window.supportsIntersectionObserver = true
  }

  return window.supportsIntersectionObserver
}

const useIntersectionObserver = (
  ref: React.MutableRefObject<HTMLDivElement | null>,
  threshold: number,
  rootMargin = '0px',
) => {
  const cleanUpCallRef = React.useRef(false)

  const [isIntersecting, setIntersecting] = React.useState(false)

  React.useEffect(() => {
    const observable = ref.current

    if (!observable) {
      return
    }

    async function observeElement() {
      if (!canIUseIntersectionObserver()) {
        await import('intersection-observer')
      }

      const observer = new IntersectionObserver(
        ([entry]) => {
          if (!cleanUpCallRef.current) {
            setIntersecting(entry.intersectionRatio >= threshold)
          }
        },
        {
          threshold,
          rootMargin,
        },
      )

      observer.observe(observable)

      return () => {
        observer.unobserve(observable)
      }
    }

    const unobservePromise = observeElement()

    return () => {
      cleanUpCallRef.current = true

      unobservePromise.then(value => value())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isIntersecting
}

export default useIntersectionObserver
