import { split } from 'lodash'

import { collectGlobalVariables } from '~/analytics/analytics-utils'
import type { IUser } from '~/types/users'
import type { IVendor } from '~/types/vendors'

const scripts = {
  vendorData: (vendor: IVendor) => {
    const payload = {
      analytics: {
        googleAnalytics4Id: vendor.googleAnalytics4Id ?? null,
      },
    }

    return `window.__VENDOR_DATA__ = ${JSON.stringify(payload)}`
  },
  googleAnalyticsUniversal: {
    head: (gaVendorId: string) => {
      const gaRiqraId = process.env.GOOGLE_ANALYTICS_ID

      return `
        window.gtagDataLayer = window.gtagDataLayer || [];
        
        function _gtag() {
          gtagDataLayer.push(arguments);
        }
  
        _gtag('js', new Date());
  
        ${gaVendorId ? `_gtag('config', '${gaVendorId}', { send_page_view: false });` : ``}
  
        ${gaRiqraId ? `_gtag('config', '${gaRiqraId}', { 'groups': 'riqra', send_page_view: false });` : ``} 
    `
    },
  },
  googleTagManager: {
    head: (id: string) => `
      (function(w, d, s, l, i) {
        w[l] = w[l] || []
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : ''
        j.async = true
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
        f.parentNode.insertBefore(j, f)
      })(window,document,'script','dataLayer','${id}')
    `,
    body: (id: string) => `
      <iframe
        src="https://www.googletagmanager.com/ns.html?
        id=${id}"
        height='0'
        width='0'
        style='display:none;visibility:hidden'
      ></iframe>
    `,
    dataLayerCollector: (vendor: IVendor, user: IUser) => {
      const globalVariables = collectGlobalVariables(vendor, user)

      return `
        window.dataLayer = window.dataLayer || []

        window.collectDataLayer = function(variables) {
          window.dataLayer.push({
            ...(${JSON.stringify(globalVariables)}),
            ...variables
          })
        }
      `
    },
  },
  pixel: (ids: string) => {
    if (!ids) {
      return
    }

    const formatEvent = id => `fbq('init', '${id}');`

    const initIds = split(ids, ',')

    return `
    !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      ${initIds.map(formatEvent).join(' ')}
      fbq('track', 'PageView');
  `
  },
  tawkTo: (id: string) => `
    var Tawk_API = Tawk_API || {},
    Tawk_LoadStart = new Date()
    ;(function() {
      var s1 = document.createElement('script'),
        s0 = document.getElementsByTagName('script')[0]
      s1.async = true
      s1.src = 'https://embed.tawk.to/${id}/default'
      s1.charset = 'UTF-8'
      s1.setAttribute('crossorigin', '*')
      s0.parentNode.insertBefore(s1, s0)
    })()
  `,
  beetrack: (id: string) => `
    <iframe
      title="beetrack-widget"
      width="100%"
      frameBorder="0"
      scrolling="no"
      src=https://app.beetrack.cl/widget/${id}
    />`,
  mixpanel: (
    token: string,
  ) => `(function(f,b){if(!b.__SV){var e,g,i,h;window.mixpanel=b;b._i=[];b.init=function(e,f,c){function g(a,d){var b=d.split(".");2==b.length&&(a=a[b[0]],d=b[1]);a[d]=function(){a.push([d].concat(Array.prototype.slice.call(arguments,0)))}}var a=b;"undefined"!==typeof c?a=b[c]=[]:c="mixpanel";a.people=a.people||[];a.toString=function(a){var d="mixpanel";"mixpanel"!==c&&(d+="."+c);a||(d+=" (stub)");return d};a.people.toString=function(){return a.toString(1)+".people (stub)"};i="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
  for(h=0;h<i.length;h++)g(a,i[h]);var j="set set_once union unset remove delete".split(" ");a.get_group=function(){function b(c){d[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));a.push([e,call2])}}for(var d={},e=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<j.length;c++)b(j[c]);return d};b._i.push([e,f,c])};b.__SV=1.2;e=f.createElement("script");e.type="text/javascript";e.async=!0;e.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?
  MIXPANEL_CUSTOM_LIB_URL:"file:"===f.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\\/\\//)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";g=f.getElementsByTagName("script")[0];g.parentNode.insertBefore(e,g)}})(document,window.mixpanel||[]);

  mixpanel.init("${token}", {batch_requests: true})`,
}

export default scripts
