import styled from 'styled-components'

import Field from '~/truck/field'
import theme from '~/truck/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  > label {
    margin-bottom: ${theme.spacing.compact}px;
  }
`

export const StyledField = styled(Field)<{ hasAdornment: boolean }>`
  > input {
    padding-left: ${props => (props.hasAdornment ? theme.spacing.cozy * 5 : theme.spacing.comfortable)}px;
  }
`

export const Adornment = styled.div`
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: center;
  position: absolute;
  top: 22px;
  width: 48px;
`
