import gql from 'graphql-tag'

const orderCouponsQuery = gql`
  query orderCoupons($orderId: ID!) {
    order: node(id: $orderId) {
      ... on Order {
        id
        coupons {
          id
          code
        }
      }
    }
  }
`

export default orderCouponsQuery
