import React from 'react'

import Icon from '~/truck/icon'
import Label from '~/truck/label'

import { Container } from './elements'

interface OptionButtonProps {
  iconName: string
  text: string
  selected?: boolean
  onClick: () => void
}

function OptionButton(props: OptionButtonProps) {
  const { iconName, text, selected, onClick } = props

  return (
    <Container type="button" $selected={selected} onClick={onClick}>
      <Icon color="black" type={iconName} size="xl" interactive={false} />
      <Label as="span">{text}</Label>
    </Container>
  )
}

export default OptionButton
