import React from 'react'
import { map } from 'lodash'
import styled from 'styled-components'

import LineItem from '~/components/line-item'
import theme from '~/truck/theme'
import type { ILineItem } from '~/types/line-items'
import type { IOrder } from '~/types/orders'

import Panel from '../panel'

export const StyledLineItem = styled(LineItem)`
  border-bottom: 1px solid ${theme.colors.gray};
`

interface LineItemsProps {
  /**
   * @deprecated Use "linesItems" prop instead of this prop
   */
  order?: IOrder
  lineItems?: ILineItem[]
  isEditable?: boolean
  onEditClick?: () => void
  className?: string
  style?: React.CSSProperties
  title?: string
}

const renderLineItem = lineItem => <StyledLineItem {...lineItem} key={lineItem.id} />

const LineItems = (props: LineItemsProps) => {
  const { isEditable, onEditClick, className, style, title } = props

  const lineItems = props.lineItems ?? props.order.lineItems

  return (
    <Panel
      title={title || 'Productos'}
      className={className}
      style={style}
      isEditable={isEditable}
      onEditClick={onEditClick}
    >
      {map(lineItems, renderLineItem)}
    </Panel>
  )
}

export default LineItems
