import * as React from 'react'
import Link from 'next/link'
import styled from 'styled-components'

import useVendor from '~/hooks/use-vendor'
import Button from '~/truck/button'
import cloudinary from '~/utils/cloudinary'
import { baseUrls, httpStatusCodes } from '~/utils/constants'

import { InfoErrorBox } from '../info-error-box'

const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 0%;
  justify-content: center;
`

const ErrorComponent = props => {
  const { statusCode, ...rest } = props

  const { primaryColor = '#0500FF' } = useVendor() || {}

  const replaceColor = `e_replace_color:${primaryColor.slice(1)}`

  let imageUrl = `${baseUrls.CLOUDINARY}/v1592001059/sellers/resources/error-500.png`

  let config = {
    title: 'Error Inesperado',
    description: `Se produjo un error, tu solicitud no pudo completarse. Inténtelo más tarde.`,
    imageSrc: cloudinary.applyTransformations(imageUrl, replaceColor),
    tryAgainButton: true,
  }

  if (statusCode === httpStatusCodes.NOT_FOUND) {
    imageUrl = `${baseUrls.CLOUDINARY}/v1591993492/sellers/resources/error-404.png`

    config = {
      title: 'Página no encontrada',
      description: `No se encontró la página que solicitaste. Si escribiste la URL directamente,
      asegúrate que sea la correcta. Si hiciste click en un enlace para llegar
      aquí, el enlace está desactualizado.`,
      imageSrc: cloudinary.applyTransformations(imageUrl, replaceColor),
      tryAgainButton: false,
    }
  }

  const { imageSrc, title, description, tryAgainButton } = config

  const tryAgain = () => {
    window.location.reload()
  }

  return (
    <StyledContainer {...rest}>
      <InfoErrorBox
        imageSrc={imageSrc}
        imageAlt={title}
        title={title}
        description={description}
        action={
          tryAgainButton ? (
            <Button onClick={tryAgain}>Volver a Intentar</Button>
          ) : (
            <Link href="/">
              <a style={{ flexShrink: 0, textDecoration: 'none' }}>
                <Button>Seguir Comprando</Button>
              </a>
            </Link>
          )
        }
      />
    </StyledContainer>
  )
}

export default ErrorComponent
