import gql from 'graphql-tag'

const createOrderReviewMutation = gql`
  mutation createOrderReview($orderId: ID!, $rating: Int!, $comment: String) {
    createOrderReview(input: { orderId: $orderId, rating: $rating, comment: $comment }) {
      review {
        id
        rating
        comment
      }
    }
  }
`

export default createOrderReviewMutation
