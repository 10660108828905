import gql from 'graphql-tag'

const pageInfoFragment = gql`
  fragment pageInfo on PageInfo {
    total
    page
    hasPrevPage
    hasNextPage
    pages
  }
`

export default pageInfoFragment
