import gql from 'graphql-tag'

const paymentMethodFragment = gql`
  fragment paymentMethod on PaymentMethod {
    id
    name
    slug
    image
    isEnabled
    isCard
    isExternal
    isDeferred
    description
  }
`

export default paymentMethodFragment
