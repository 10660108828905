import { useApolloClient } from '@apollo/react-hooks'
import { assign, find, remove } from 'lodash'
import { useFlagQueryFn } from 'toggled'

import { analytics } from '~/analytics'
import { removeProductFromCartMutation, upsertProductInCartMutation } from '~/gql/mutations'
import { userCartQuery } from '~/gql/queries'
import useVendor from '~/hooks/use-vendor'
import { mixpanel } from '~/metrics'
import { cloudinary } from '~/utils'
import { events, flags } from '~/utils/constants'
import getLineItemsTotal from '~/utils/get-line-items-total'

// This is a preparation for the refactoring of the cart, it is temporary that everything is in the same file.

const mutationNames = {
  upsert: 'addOrUpdateProductInCart',
  remove: 'removeProductFromCart',
}

const getLineItemPredicate = lineItem => {
  return ['product.id', lineItem.product.id]
}

const upsertProduct = (lineItem, lineItems) => {
  const predicate = getLineItemPredicate(lineItem)

  const targetItem = find(lineItems, predicate)

  if (!targetItem) {
    lineItems.unshift(lineItem)
  } else {
    assign(targetItem, {
      quantity: lineItem.quantity,
      originalQuantity: lineItem.originalQuantity,
    })
  }
}

const removeProduct = (lineItem, lineItems) => {
  const predicate = getLineItemPredicate(lineItem)

  remove(lineItems, predicate)
}

const getBaseUpdater = mutationName => {
  if (mutationName === mutationNames.remove) {
    return removeProduct
  }

  return upsertProduct
}

const getUpdater = (mutationName, flagQueryFn) => {
  const baseUpdater = getBaseUpdater(mutationName)

  return (proxy, response) => {
    const {
      data: {
        [mutationName]: { lineItem },
      },
    } = response

    const showCartInMainLayoutFF = flagQueryFn(flags.SHOW_CART_IN_MAIN_LAYOUT)

    const data = proxy.readQuery({
      query: userCartQuery,
      variables: {
        runPromotions: !showCartInMainLayoutFF,
      },
    })

    const {
      user: { cart },
    } = data

    const changeCartData = cartData => {
      baseUpdater(lineItem, cartData.lineItems)

      const lineItemsTotal = getLineItemsTotal(cartData.lineItems)

      const priceLineTotal = find(cartData.priceLines, { type: 'total' })

      assign(cartData, {
        lineItemsTotal,
        total: priceLineTotal.value,
      })
    }

    changeCartData(cart)

    proxy.writeQuery({
      query: userCartQuery,
      variables: {
        runPromotions: !showCartInMainLayoutFF,
      },
      data,
    })
  }
}

const upsertProductInCartUpdate = flagQueryFn => getUpdater(mutationNames.upsert, flagQueryFn)

const removeProductFromCartUpdate = flagQueryFn => getUpdater(mutationNames.remove, flagQueryFn)

// TODO: update __typename TransactionItem to LineItem when the API support it

const useCartUpdater = (props: { product: any; runPromotions?: boolean }) => {
  const client = useApolloClient()

  const vendor = useVendor()

  const flagQueryFn = useFlagQueryFn()

  const getLineItem = (quantity: number) => {
    const { product } = props

    return {
      id: `-${Math.random()}`,
      inventoryQuantity: product.salesUnitFactor ? quantity * product.salesUnitFactor : null,
      inventoryUnitCode: product.inventoryUnit?.code ?? null,
      isTaxExempt: !!product.pricing?.isTaxExempt,
      name: product.name,
      oldPrice: product.oldPrice || product.pricing.oldPrice,
      originalQuantity: quantity,
      photo: cloudinary.removeTransformations(product.defaultPhoto || product.photo),
      presentation: product.presentation,
      price: product.price || product.pricing.price,
      product,
      quantity,
    }
  }

  const getOptimistic = (mutationName: string, quantity: number) => {
    const lineItem = getLineItem(quantity)

    return {
      __typename: 'Mutation',
      [mutationName]: {
        __typename: 'AddUpdateCartPayload',
        lineItem: {
          __typename: 'TransactionItem',
          ...lineItem,
          product: {
            ...lineItem.product,
            __typename: 'Product',
          },
        },
      },
    }
  }

  const getMutationOption = (quantity: number, lastValue: number) => {
    const { product, runPromotions } = props

    const { slug: productSlug } = product

    const shouldRemove = quantity === 0

    const { remove, upsert } = mutationNames

    const refetchQueries = []

    const isIncrementing = quantity > lastValue

    if (runPromotions) {
      refetchQueries.push({
        query: userCartQuery,
        variables: {
          runPromotions: true,
        },
      })
    }

    if (shouldRemove) {
      analytics.removeFromCart(product, { vendor })

      return {
        refetchQueries,
        mutation: removeProductFromCartMutation,
        update: removeProductFromCartUpdate(flagQueryFn),
        variables: { productSlug, quantity },
        optimisticResponse: getOptimistic(remove, quantity),
      }
    }

    if (isIncrementing) {
      const newQuantity = quantity - lastValue
      analytics.addToCart(product, newQuantity, { vendor })
    } else {
      const analyticsProduct = {
        ...product,
        quantity: lastValue - quantity,
      }

      analytics.removeFromCart(analyticsProduct, { vendor })
    }

    if (window.location.pathname === '/mi-cuenta/favoritos') {
      mixpanel.track(events.PRODUCT_ADDED_TO_CART_FROM_MY_FAVORITES_PAGE, {
        id: product.id || product?.product?.id,
        sku: product.sku || product?.product?.sku,
        name: product.name,
        price: product?.pricing?.price,
        category: product.category?.name,
        quantity,
      })
    }

    return {
      refetchQueries,
      mutation: upsertProductInCartMutation,
      update: upsertProductInCartUpdate(flagQueryFn),
      variables: { productSlug, quantity },
      optimisticResponse: getOptimistic(upsert, quantity),
    }
  }

  const update = (quantity: number, lastValue?: number) => {
    const options = getMutationOption(quantity, lastValue)

    return client.mutate(options)
  }

  const remove = () => {
    update(0)
  }

  return { update, remove }
}

export default useCartUpdater
