import React from 'react'
import styled from 'styled-components'

import Anchor from '~/truck/anchor'
import Label from '~/truck/label'
import theme from '~/truck/theme'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: ${theme.spacing.compact}px;

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
  }

  > a {
    flex-shrink: 0;
    margin-left: ${theme.spacing.comfortable}px;
  }
`

const ShippingSelectorMobile = props => {
  const { style, className, title, text, onClick } = props

  return (
    <Container style={style} className={className}>
      <Label as="span" $textStyle="h6Semibold">
        {title}
      </Label>
      <InnerContainer>
        <Label as="span" $textStyle="h5Semibold">
          {text}
        </Label>
        <Anchor onClick={onClick} $textStyle="h5Semibold">
          Cambiar
        </Anchor>
      </InnerContainer>
    </Container>
  )
}

export default ShippingSelectorMobile
