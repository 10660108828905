import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import { filter, some, take } from 'lodash'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import { AutocompleteSearch } from '~/components'
import { catalogSearchQuery } from '~/gql/queries'
import theme from '~/truck/theme'

import SearchItem from './search-item'

const StyledAutocompleteSearch = styled(AutocompleteSearch)`
  margin-left: ${theme.spacing.comfortable}px;
  margin-right: ${theme.spacing.comfortable}px;

  ${up('lg')} {
    margin-left: ${theme.spacing.relaxed}px;
    margin-right: ${theme.spacing.relaxed}px;
  }
`

const SearchProductAutocomplete = props => {
  const { products, onSelect } = props

  const params = { notifyOnNetworkStatusChange: true }

  const [query, queryOptions] = useLazyQuery(catalogSearchQuery, params)

  const { data, loading, refetch, networkStatus } = queryOptions

  const [autocompleteState, setAutocompleteState] = useState({})

  const isFetching = loading || networkStatus === 4

  const onSearch = term => {
    const variables = {
      term,
    }

    if (!term) {
      setAutocompleteState({
        data: undefined,
        searching: false,
      })

      return
    }

    if (data) {
      refetch(variables)

      return
    }

    query({
      variables,
    })
  }

  useEffect(() => {
    setAutocompleteState(prevState => {
      const newState = {
        ...prevState,
        searching: isFetching,
      }

      const newProducts = data?.catalogSearch?.items

      const filterResults = item => !some(products, product => product.id === item.id)

      if (!isFetching) {
        newState.data = data === undefined ? undefined : take(filter(newProducts, filterResults), 8)
      }

      return newState
    })
  }, [isFetching, data])

  return (
    <StyledAutocompleteSearch
      {...autocompleteState}
      ItemComponent={SearchItem}
      onSearch={onSearch}
      onSelect={onSelect}
      placeholder="Buscar productos"
    />
  )
}

export default SearchProductAutocomplete
