import React, { useEffect } from 'react'
import type { CSSObject } from 'styled-components'
import { useFlag } from 'toggled'

import { analytics } from '~/analytics'
import { useCart, useIsMobile, useVendor } from '~/hooks'
import { useUiStore } from '~/modules/shopping-cart/stores/ui-store'
import { flags } from '~/utils/constants'

import { CartView } from './Cart.view'

interface CartProps {
  canReturn?: boolean
  style?: CSSObject
  className?: string
}

export const Cart = (props: CartProps) => {
  const { canReturn, style, className } = props

  const isOpenCartLoadingOverlay = useUiStore(store => store.cart.isOpenLoadingOverlay)

  const vendor = useVendor()

  const isMobile = useIsMobile()

  const showsCartInMainLayout = useFlag(flags.SHOW_CART_IN_MAIN_LAYOUT)

  const { cart } = useCart()

  useEffect(() => {
    if (!showsCartInMainLayout || isMobile) {
      const lineItems = cart?.lineItems ?? []

      analytics.viewCart(lineItems, { vendor })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CartView
      canReturn={canReturn}
      style={style}
      className={className}
      showOverlay={isOpenCartLoadingOverlay}
      cart={cart}
      minimumOrder={vendor.minimumOrder}
    />
  )
}
