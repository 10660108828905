import React from 'react'
import { map } from 'lodash'

import Label from '~/truck/label'

import { Container } from './elements'

interface TextGroupProps {
  className?: string
  style?: React.CSSProperties
  title: string
  titleTextStyle?: string
  contentTextStyle?: string
  textAlign?: string
  lines: string[]
}

function TextGroup({
  contentTextStyle = 'h5Regular',
  title,
  titleTextStyle = 'h6Semibold',
  textAlign,
  lines,
  style,
  className,
}: TextGroupProps) {
  return (
    <Container style={style} className={className}>
      <Label $textStyle={titleTextStyle} $textAlign={textAlign}>
        {title}
      </Label>
      {map(lines, (value, index) => (
        <Label key={index} $textStyle={contentTextStyle} $textAlign={textAlign}>
          {value}
        </Label>
      ))}
    </Container>
  )
}

export default TextGroup
