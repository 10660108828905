import React from 'react'
import renderHtml from 'react-html-parser'

import Label from '~/truck/label'
import theme from '~/truck/theme'

import { Close, Container, types } from './elements'

const Alert = props => {
  const { message, description, closable, onClose, ...rest } = props

  return (
    <Container {...rest}>
      <Label as="p">{renderHtml(message)}</Label>
      {description && (
        <Label as="p" $textStyle="h6Regular" style={{ marginTop: theme.spacing.compact }}>
          {renderHtml(description)}
        </Label>
      )}
      {closable && <Close onClick={onClose} />}
    </Container>
  )
}

Alert.types = types

export default Alert
