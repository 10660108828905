import * as React from 'react'
import { useSelect } from 'downshift'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import { useFiltersState } from '~/hooks/use-filters-state'
import Button from '~/truck/button'
import Icon from '~/truck/icon'
import Label from '~/truck/label'

export enum SortSelectTypes {
  PRODUCT_NAME_ASC = 'product_name_asc',
  PRODUCT_NAME_DESC = 'product_name_desc',
  PRODUCT_PRICE_ASC = 'product_price_asc',
  PRODUCT_PRICE_DESC = 'product_price_desc',
  PRODUCT_CREATED_AT_DESC = 'product_created_at_desc',
}

export const wording = {
  [SortSelectTypes.PRODUCT_NAME_ASC]: 'Alfabéticamente A-Z',
  [SortSelectTypes.PRODUCT_NAME_DESC]: 'Alfabéticamente Z-A',
  [SortSelectTypes.PRODUCT_PRICE_ASC]: 'Menor precio',
  [SortSelectTypes.PRODUCT_PRICE_DESC]: 'Mayor precio',
  [SortSelectTypes.PRODUCT_CREATED_AT_DESC]: 'Más nuevo',
}

const StyledButton = styled(Button)`
  background-color: ${props => props.theme.colors.white};
  color: inherit;
  height: ${props => props.theme.spacing.cozy * 5}px;
  justify-content: space-between;
  min-width: ${props => props.theme.spacing.cozy * 24.875}px;
  width: auto;

  ${up('lg')} {
    min-width: ${props => props.theme.spacing.cozy * 35}px;
  }

  & > svg {
    fill: ${props => props.theme.palette.grayscale80};
  }

  &:hover {
    background-color: ${props => props.theme.colors.white};
    color: inherit;

    & > svg {
      fill: ${props => props.theme.palette.grayscale80};
    }
  }
`

const StyledMenu = styled.ul`
  background-color: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.dimensions.borderRadius}px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  list-style: none;
  margin-top: ${props => props.theme.spacing.cozy / 2}px;
  max-width: ${props => props.theme.spacing.cozy * 35}px;
  padding-bottom: ${props => props.theme.spacing.cozy}px;
  padding-top: ${props => props.theme.spacing.cozy}px;
  position: absolute;
  width: 100%;
  z-index: 10;
`

const StyledMenuItem = styled.li<{ $isHighlighted: boolean }>`
  background-color: ${props => (props.$isHighlighted ? props.theme.palette.primary10 : props.theme.colors.white)};
  padding: ${props => props.theme.spacing.cozy}px ${props => props.theme.spacing.cozy * 2}px;
`

const StyledPrefixLabel = styled(Label)`
  display: none;

  ${up('lg')} {
    display: inline;
  }
`

export function SortSelect() {
  const { sortBy, setSortBy } = useFiltersState()

  const { isOpen, selectedItem, getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
    items: Object.values(SortSelectTypes),
    defaultSelectedItem: sortBy,
    selectedItem: sortBy,
    onSelectedItemChange: event => setSortBy(event.selectedItem),
  })

  return (
    <div>
      <StyledButton role="button" {...getToggleButtonProps()} color="white">
        <span>
          <StyledPrefixLabel $textStyle="h5Semibold" as="span">
            {'Ordenar: '}
          </StyledPrefixLabel>
          <Label $textStyle="h5Regular" as="span">
            {wording[selectedItem]}
          </Label>
        </span>
        <Icon type={isOpen ? 'caret-down' : 'caret-up'} size="sm" color="grayscale80" />
      </StyledButton>
      <div {...getMenuProps()}>
        {isOpen && (
          <StyledMenu>
            {Object.values(SortSelectTypes).map((item, index) => (
              <StyledMenuItem
                key={`${item}${index}`}
                $isHighlighted={highlightedIndex === index}
                {...getItemProps({ item, index })}
              >
                {wording[item]}
              </StyledMenuItem>
            ))}
          </StyledMenu>
        )}
      </div>
      <div tabIndex={0} />
    </div>
  )
}
