import { cloneDeep } from 'lodash'

import { commentsQuery, priceLinesQuery } from '~/gql/queries'
import type { IOrderPriceLine } from '~/types/orders'

type SetPriceLinesArgs = {
  priceLines: IOrderPriceLine[]
}

type SetCommentsArgs = {
  comments?: string
  orderId?: string
}

const mutation = {
  setPriceLines: (_, args: SetPriceLinesArgs, context) => {
    const { client } = context

    const queryOptions = {
      query: priceLinesQuery,
    }

    try {
      const data = cloneDeep(client.readQuery(queryOptions))

      const {
        user: { cart },
      } = data

      cart.priceLines = args.priceLines

      const total = args.priceLines?.find(price => price.label === 'Total')

      if (total) {
        cart.total = total.value
      }

      client.writeQuery({ data, ...queryOptions })

      return true
    } catch (error) {
      console.error(error)
    }
  },
  setComments: (_, args: SetCommentsArgs, context) => {
    const { client } = context

    const queryOptions = {
      query: commentsQuery,
    }

    try {
      const data = cloneDeep(client.readQuery(queryOptions))

      const {
        user: { cart },
      } = data

      cart.comments = args.comments?.trim() ?? null

      client.writeQuery({ data, ...queryOptions })

      return true
    } catch (error) {
      console.error(error)
    }
  },
}

export default mutation
