import React, { useEffect } from 'react'
import { useField } from 'react-final-form'
import getCardInfo, { types as CardTypes } from 'credit-card-type'
import { isEmpty, isEqual } from 'lodash'

import {
  PaymentCardNumber,
  PaymentEmail,
  PaymentExpirationMonth,
  PaymentExpirationYear,
  PaymentOwnerName,
  PaymentSecurityCode,
} from '~/components'
import { useCalculateCartPricing } from '~/hooks'
import Image from '~/truck/image'
import Label from '~/truck/label'
import theme from '~/truck/theme'
import { baseUrls } from '~/utils/constants'

import { formatSecurityCode } from '../utils'

import { Cards, Container, FormContainer, ImageContainer, TwoColumns } from './elements'

const CulqiForm = props => {
  const { paymentMethodId } = props

  const {
    input: { value: cardNumber },
  } = useField('cardNumber')

  const cardInfo = getCardInfo(cardNumber)

  const cardType = cardInfo?.[0]?.type

  const validateCard = type => {
    if (isEqual(type, cardType) && !isEmpty(cardNumber)) {
      return { opacity: 1 }
    }
  }

  const { calculateAndSetCartPricing } = useCalculateCartPricing()

  useEffect(() => {
    const cardFormat = cardNumber?.replace(/\s/g, '') ?? ''

    if (cardFormat.length === 6) {
      calculateAndSetCartPricing({ paymentMethodId, bin: cardFormat.slice(0, 6) })
    }
  }, [cardNumber])

  return (
    <Container>
      <Label $textStyle="h5Semibold">Datos de tu tarjeta</Label>
      <Cards>
        <Image
          style={validateCard(CardTypes.VISA)}
          src={`${baseUrls.CLOUDINARY}/v1603388149/sellers/resources/visa.png`}
        />
        <Image
          style={validateCard(CardTypes.MASTERCARD)}
          src={`${baseUrls.CLOUDINARY}/v1603388149/sellers/resources/mastercard.png`}
        />
        <Image
          style={validateCard(CardTypes.AMERICAN_EXPRESS)}
          src={`${baseUrls.CLOUDINARY}/v1603388149/sellers/resources/amex.png`}
        />
      </Cards>
      <FormContainer>
        <PaymentOwnerName />
        <PaymentCardNumber data-culqi="card[number]" id="card[number]" maxLength={19} />
        <PaymentEmail data-culqi="card[email]" id="card[email]" />
        <TwoColumns>
          <PaymentExpirationMonth data-culqi="card[exp_month]" id="card[exp_month]" />
          <PaymentExpirationYear
            data-culqi="card[exp_year]"
            id="card[exp_year]"
            maxLength={4}
            format={formatSecurityCode}
          />
        </TwoColumns>
        <TwoColumns>
          <PaymentSecurityCode data-culqi="card[cvv]" id="card[cvv]" />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ImageContainer>
              <Image
                style={{ marginRight: theme.spacing.relaxed }}
                src="https://res.cloudinary.com/riqra/image/upload/v1592271029/sellers/resources/card-form/card-2.png"
              />
              <Image src="https://res.cloudinary.com/riqra/image/upload/v1592271029/sellers/resources/card-form/card-1.png" />
            </ImageContainer>
          </div>
        </TwoColumns>
      </FormContainer>
    </Container>
  )
}

export default CulqiForm
