import gql from 'graphql-tag'

import { orderFragment } from '../fragments'

const orderQuery = gql`
  query order($id: ID!) {
    order: node(id: $id) {
      ... on Order {
        ...order
        note
        isEditable
        paymentExternalUrl
        attachments {
          fields
          files {
            name
            link
          }
        }
      }
    }
  }
  ${orderFragment}
`

export default orderQuery
