import gql from 'graphql-tag'

import { checkoutFragment, priceLineFragment } from '../fragments'

const editOrderSetInvoicingMutation = gql`
  mutation editOrderSetInvoicing($documentId: ID!, $orderId: ID) {
    setInvoicing(input: { documentId: $documentId, orderId: $orderId }) {
      order {
        ...checkout
        priceLines {
          ...priceLine
        }
      }
    }
  }
  ${checkoutFragment}
  ${priceLineFragment}
`

export default editOrderSetInvoicingMutation
