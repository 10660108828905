import { useMemo, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import type { HierarchyItem } from '@riqra/hierarchy-parser'
import { find, isString, map, mapValues, pick, pickBy } from 'lodash'

import type { ILocality } from '~/types/localities'

export const divisionLevelsFields = ['divisionLevel1', 'divisionLevel2', 'divisionLevel3', 'divisionLevel4']

export function getDivisionLevelsFromLocationSelector(allLocalities: ILocality[], values: Record<string, unknown>) {
  const normalizedValues = pickBy(pick(values, divisionLevelsFields), isString)

  return mapValues(normalizedValues, localityId => find(allLocalities, { id: localityId }).name)
}

const getBreadths = (level: number, ids: string[], root: HierarchyItem<ILocality>[]) => {
  if (level === 0) {
    return [root]
  }

  const parentLevel = level - 1

  const parentId = ids[parentLevel]

  const breadths = getBreadths(parentLevel, ids, root)

  const parentBreadth = breadths[parentLevel]

  const query = { id: parentId }

  const parent = find(parentBreadth, query)

  const current = parent?.children ?? []

  return [...breadths, current]
}

const toEmptyValue = () => ''

const useLocationSelector = (coverageTree: HierarchyItem<ILocality>[]) => {
  const [divisions, fieldNames] = useMemo(() => {
    let [location] = coverageTree

    const data = [location.division]

    while (location?.children) {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ;[location] = location.children

      data.push(location.division)
    }

    const fields = map(data, (_, index) => divisionLevelsFields[index])

    return [data, fields]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [localityIds, setLocalityIds] = useState(() => {
    return map(divisions, toEmptyValue)
  })

  const [modifiedDivisions, setModifiedDivisions] = useState(() => {
    return map(divisions, () => false)
  })

  const form = useForm()

  const formState = useFormState({
    subscription: {
      initialValues: true,
    },
  })

  const breadths = useMemo(() => {
    const lastLevel = divisions.length - 1

    return getBreadths(lastLevel, localityIds, coverageTree)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, localityIds)

  const onChange = (localityId: string, fieldName: string) => {
    const lastDivisionIndex = divisions.length - 1

    const selectedDivisionIndex = fieldNames.indexOf(fieldName)

    const isLastDivisionLevel = selectedDivisionIndex === lastDivisionIndex

    const restDivisionLevels = map(
      divisionLevelsFields,
      (divisionLevelName, divisionLevelIndex) => divisionLevelIndex > selectedDivisionIndex && divisionLevelName,
    ).filter(Boolean)

    restDivisionLevels.forEach(fieldName => {
      form.change(fieldName, null)
    })

    if (isLastDivisionLevel) {
      form.change('localityId', localityId)
    } else {
      form.change('localityId', null)
    }

    setLocalityIds(ids => {
      const newIds = [...ids]

      newIds[selectedDivisionIndex] = localityId

      for (let i = selectedDivisionIndex + 1; i < newIds.length; i += 1) {
        newIds[i] = toEmptyValue()
      }

      return newIds
    })

    setModifiedDivisions(prevState => {
      // eslint-disable-next-line
      prevState[selectedDivisionIndex] = true

      return [...prevState]
    })

    form.change(fieldName, localityId)
  }

  const getFieldValue = (index: number) => {
    if (modifiedDivisions[index]) {
      return localityIds[index]
    }

    if (!formState.initialValues) {
      return null
    }

    return formState.initialValues[fieldNames[index]]
  }

  const normalize = (division: string, index: number) => {
    return {
      name: division,
      fieldName: fieldNames[index],
      fieldValue: getFieldValue(index),
      localities: breadths[index],
    }
  }

  const normalized = map(divisions, normalize)

  return [normalized, onChange]
}

export default useLocationSelector
