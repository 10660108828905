import type TruckTheme from '~/types/theme'
import type { ThemeColor } from '~/types/theme'

export default function getColor(theme: TruckTheme, colorKey: ThemeColor | 'currentColor') {
  if (colorKey === 'currentColor') {
    return 'currentColor'
  }

  return theme.palette[colorKey] ?? theme.colors[colorKey]
}
