import gql from 'graphql-tag'

import { orderStatusFragment } from '../fragments'

const orderStatusesQuery = gql`
  query orderStatuses($showAll: Boolean) {
    vendor {
      id
      orderStatuses(showAll: $showAll) {
        ...orderStatus
      }
    }
  }
  ${orderStatusFragment}
`

export default orderStatusesQuery
