import gql from 'graphql-tag'

const createCustomerComplaintMutation = gql`
  mutation createCustomerComplaint(
    $code: String
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
    $documentNumber: String!
    $addressLine: String!
    $claimedAmount: Float!
    $assetDescription: String!
    $details: String!
    $customerRequest: String!
    $attachmentFilename: String
    $attachmentKey: String
    $termsAccepted: Boolean!
    $claimedAt: Date!
    $complainType: ComplainEnumType!
    $documentType: DocumentEnumType!
    $assetType: AssetEnumType!
    $localityId: ID!
    $reCaptchaToken: String!
    $isUnderAge: Boolean
    $parentFirstName: String
    $parentLastName: String
    $parentEmail: String
    $parentPhone: String
    $parentDocumentType: String
    $parentDocumentNumber: String
    $purchaseChannel: String
  ) {
    createCustomerComplaint(
      input: {
        code: $code
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        documentNumber: $documentNumber
        addressLine: $addressLine
        claimedAmount: $claimedAmount
        assetDescription: $assetDescription
        details: $details
        customerRequest: $customerRequest
        attachmentFilename: $attachmentFilename
        attachmentKey: $attachmentKey
        termsAccepted: $termsAccepted
        claimedAt: $claimedAt
        complainType: $complainType
        documentType: $documentType
        assetType: $assetType
        localityId: $localityId
        reCaptchaToken: $reCaptchaToken
        isUnderAge: $isUnderAge
        parentFirstName: $parentFirstName
        parentLastName: $parentLastName
        parentEmail: $parentEmail
        parentPhone: $parentPhone
        parentDocumentType: $parentDocumentType
        parentDocumentNumber: $parentDocumentNumber
        purchaseChannel: $purchaseChannel
      }
    ) {
      customerComplaint {
        id
      }
    }
  }
`

export default createCustomerComplaintMutation
