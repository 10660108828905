import gql from 'graphql-tag'

const partialProductFragment = gql`
  fragment partialProduct on Product {
    id
    originalId
    name
    sku
    slug
    seoTitle
    seoDescription
    defaultPhoto
    presentation
    description
    category {
      id
      name
      parent {
        id
        name
        seoDescription
        image
      }
    }
    vendor {
      id
    }
  }
`

export default partialProductFragment
