import { some } from 'lodash'

import type { IVendorFeature } from '~/types/vendors'

const hasFlag = (features: IVendorFeature[], flag: string) => {
  if (!Array.isArray(features)) {
    return false
  }

  if (features.length === 0) {
    return false
  }

  if (typeof features[0][0] === 'string') {
    return features.findIndex(feat => feat[0] === flag) !== -1
  }

  if (typeof features[0] === 'object') {
    return some(features, { slug: flag })
  }

  return false
}

export default hasFlag
