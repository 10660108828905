import styled from 'styled-components'

import theme from '~/truck/theme'

export const InnerContainer = styled.div`
  padding: ${theme.spacing.comfortable}px;
  padding-top: 0;

  > *:not(:first-child) {
    margin-top: ${theme.spacing.compact}px;
  }

  > div {
    padding: 0;
  }
`
