import React, { PointerEvent, ReactNode, Ref, TouchEvent, useCallback, useEffect, useRef, useState } from 'react'

import { StyledSheet } from './BottonSheetStandalone.style'

type BottomSheetStandaloneProps = {
  children: ReactNode
  bodyRef?: React.RefObject<HTMLDivElement>
}

const CLOSE_HEIGHT = 350

// If this component is to be used outside of this module, it must be elevated to
export const BottomSheetStandalone = (props: BottomSheetStandaloneProps) => {
  const dragStartY = useRef(0)

  const bodyRef = props.bodyRef

  const lastHeight = useRef(CLOSE_HEIGHT)

  const isFull = useRef(false)

  const sheetRef: Ref<HTMLDivElement> = useRef(null)

  const [isDragging, setIsDragging] = useState(false)

  const canDrag = useRef(false)

  const pointerDown = useRef(false)

  const handlerTouchMove = useCallback(
    (event: globalThis.PointerEvent & globalThis.TouchEvent) => {
      if (!pointerDown.current) {
        return
      }

      const deltaY = (event.touches?.[0].clientY ?? event.clientY) - dragStartY.current

      const isFullAndDragDown = isFull.current && deltaY > 0

      const isFullAndDragUp = isFull.current && deltaY < 0

      if (canDrag.current && !isDragging && isFullAndDragUp) {
        canDrag.current = false
      }

      if (canDrag.current && !isDragging && (isFullAndDragDown || !isFull.current)) {
        setIsDragging(true)
      }

      if (isDragging) {
        lastHeight.current = Math.max(80, lastHeight.current - deltaY)

        sheetRef.current.style.height = lastHeight.current + 'px'

        if (event.cancelable) {
          event.preventDefault()
        }
      }

      dragStartY.current = event.touches?.[0].clientY ?? event.clientY
    },
    [sheetRef, isDragging],
  )

  const handlerTouchStart = (event: PointerEvent<HTMLDivElement> & TouchEvent<HTMLDivElement>) => {
    dragStartY.current = event.touches?.[0].clientY ?? event.clientY

    lastHeight.current = Number(sheetRef.current.style.height.split('px')[0])

    isFull.current = lastHeight.current > window.innerHeight / 1.3

    const isTopScroll = bodyRef?.current ? bodyRef.current.scrollTop === 0 : sheetRef.current.scrollTop === 0

    if ((isFull.current && isTopScroll) || !isFull.current) {
      canDrag.current = true
    }

    pointerDown.current = true
  }

  const handlerTouchEnd = (event: PointerEvent<HTMLDivElement> & TouchEvent<HTMLDivElement>) => {
    pointerDown.current = false

    canDrag.current = false

    if (!isDragging) {
      return
    }

    const maxHeight = window.innerHeight

    event.currentTarget.scrollTo(0, 0)

    if (lastHeight.current > maxHeight / (isFull.current ? 1.25 : 2.5)) {
      sheetRef.current.style.height = maxHeight + 'px'

      lastHeight.current = maxHeight

      sheetRef.current.style.touchAction = 'auto'
    } else {
      sheetRef.current.style.height = CLOSE_HEIGHT + 'px'

      lastHeight.current = CLOSE_HEIGHT

      sheetRef.current.style.touchAction = 'none'
    }

    setIsDragging(false)
  }

  useEffect(() => {
    if (!sheetRef.current) {
      return
    }

    const sheetCurrent = sheetRef.current

    sheetCurrent.addEventListener('touchmove', handlerTouchMove, { passive: false })
    sheetCurrent.addEventListener('pointermove', handlerTouchMove, { passive: false })

    return () => {
      sheetCurrent.removeEventListener('touchmove', handlerTouchMove)
      sheetCurrent.removeEventListener('pointermove', handlerTouchMove)
    }
  }, [sheetRef, handlerTouchMove])

  return (
    <StyledSheet
      ref={sheetRef}
      style={{ height: CLOSE_HEIGHT }}
      isDragging={isDragging}
      onPointerDown={handlerTouchStart}
      onTouchStart={handlerTouchStart}
      onPointerUp={handlerTouchEnd}
      onTouchEnd={handlerTouchEnd}
      onTouchCancel={handlerTouchEnd}
    >
      {props.children}
    </StyledSheet>
  )
}
