import gql from 'graphql-tag'

const linesOfBusinessQuery = gql`
  query linesOfBusiness {
    vendor {
      linesOfBusiness
    }
  }
`

export default linesOfBusinessQuery
