import { each } from 'lodash'

import getStoreLocation from './get-store-location'

function getBoundingBox(stores: Record<string, any>[]) {
  const bounds = new window.google.maps.LatLngBounds()

  each(stores, store => {
    const location = getStoreLocation(store)
    bounds.extend(location)
  })

  return bounds
}

export default getBoundingBox
