import React, { useMemo } from 'react'
import { get, reduce, replace, startsWith } from 'lodash'

import { StringsContext } from '~/contexts'
import useVendor from '~/hooks/use-vendor'
import { getStrings } from '~/utils'

const StringsProvider = props => {
  const { children } = props

  const vendor = useVendor()

  const providerValue = useMemo(() => {
    const strings = getStrings(vendor)

    const getStringsInNamespace = namespace => {
      const getValues = (result, value, key) => {
        if (!startsWith(key, namespace)) {
          return result
        }

        const newKey = replace(key, `${namespace}.`, '')

        return {
          ...result,
          [newKey]: value,
        }
      }

      return reduce(strings, getValues, {})
    }

    const getString = key => get(strings, key)

    const createNamespace = namespace => {
      return key => getString(`${namespace}.${key}`)
    }

    return {
      createNamespace,
      getString,
      getStringsInNamespace,
    }
  }, [vendor])

  return <StringsContext.Provider value={providerValue}>{children}</StringsContext.Provider>
}

export default StringsProvider
