import React, { MouseEvent } from 'react'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import { useFlag, useFlagQueryFn } from 'toggled'

import { analytics } from '~/analytics'
import Media from '~/components/media'
import Product from '~/components/product'
import { useFavoriteProduct } from '~/hooks'
import useVendor from '~/hooks/use-vendor'
import Label from '~/truck/label'
import theme from '~/truck/theme'
import { getTextStyle } from '~/truck/utils'
import { cloudinary } from '~/utils'
import { analyticsItemListContexts, flags, transformations } from '~/utils/constants'

import { useDebounceHandler } from '../providers'

const getOptimizedPhotos = (originalUrl: string) => {
  return {
    desktop: cloudinary.applyTransformations(originalUrl, transformations.PRODUCT),
    mobile: cloudinary.applyTransformations(originalUrl, transformations.TINY_PRODUCT),
  }
}

const LabelContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  transform: rotate(-11.21deg);
  width: 100%;
`

const StyledLabel = styled(Label)`
  background-color: ${theme.colors.error};
  padding: ${theme.spacing.compact}px;
  text-align: center;
  ${getTextStyle('h6Semibold')}

  > span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    ${getTextStyle('h6Bold')}

    ${up('lg')} {
      ${getTextStyle('h5Bold')}
    }
  }

  ${up('lg')} {
    padding: ${theme.spacing.cozy}px ${theme.spacing.comfortable}px;
    text-transform: uppercase;
    ${getTextStyle('h6Bold')}
  }
`

const CustomLabel = () => (
  <LabelContainer>
    <StyledLabel as="p" $color="white">
      Agotado
      <br />
      <Label as="span" $color="white">
        En reposición
      </Label>
    </StyledLabel>
  </LabelContainer>
)

// TODO: This component is only temporary, this element must be moved to the product module.
export const SuggestedProduct = (props: any) => {
  const { defaultPhoto, pricing, slug } = props

  const { startDebounce, endDebounce } = useDebounceHandler()

  const vendor = useVendor()

  const { onToggleFavorite } = useFavoriteProduct(props)

  const isNotAvailable = useFlag(flags.NOT_AVAILABLE)

  const showCartInMainLayoutFF = useFlag(flags.SHOW_CART_IN_MAIN_LAYOUT)

  const flagQueryFn = useFlagQueryFn()

  const photos = getOptimizedPhotos(defaultPhoto)

  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault()

    analytics.selectItem(props, analyticsItemListContexts.SUGGESTED_PRODUCTS)
  }

  const productProps = {
    ...props,
    ...pricing,
    onClick,
    runPromotions: !showCartInMainLayoutFF,
    hideCounter: isNotAvailable,
    hasVariants: false,
    tierPricing: null,
    outOfStockLabel: vendor.settings.alternateOutOfStockStyle && CustomLabel,
    onFavorite: onToggleFavorite,
    href: `/p/${slug}`,
    showMyFavorites: flagQueryFn(flags.MY_FAVORITES),
    onDebounceStart: startDebounce,
    onDebounceEnd: endDebounce,
    photos: photos,
  }

  return (
    <Media
      desktop={<Product.Desktop {...productProps} photo={productProps.photos.desktop} disableTaxLabel />}
      mobile={<Product.Mobile {...productProps} photo={productProps.photos.desktop} withCounter />}
    />
  )
}
