import { cancellableError } from './cancellableError'

/**
 * Allows a promise to be cancelled by executing the `cancel()` function.
 * Unlike `cancellablePromiseAfter`, this can be cancelled only if the promise is pending,
 * when the promise finishes executing and `cancel()` was not executed, then it is not cancelled.
 *
 * @param promise Promise to call
 *
 * @returns object with promise and cancel function. if is canceled, return `.catch` with `error.isCanceled = true`
 */
export function cancellablePromiseWhile<T>(promise: Promise<T>) {
  let canceled = false

  const wrapper = new Promise<T>((resolve, reject) => {
    promise.then(
      value => {
        return canceled ? reject(cancellableError) : resolve(value)
      },
      error => {
        return canceled ? reject(cancellableError) : reject(error)
      },
    )
  })

  return {
    promise: wrapper,
    cancel() {
      canceled = true
    },
  }
}
