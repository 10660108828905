import gql from 'graphql-tag'

const removeAddressMutation = gql`
  mutation removeAddress($addressId: ID!) {
    removeAddress(input: { addressId: $addressId }) {
      address {
        id
      }
    }
  }
`

export default removeAddressMutation
