import gql from 'graphql-tag'

const userFragment = gql`
  fragment user on User {
    id
    originalId
    email
    firstName
    lastName
    _birthdate: birthdate
    birthdate @client
    needsToBeUpdated
    needsToAcceptTermsAndConditions
    phone
    telephone
    purchaseLimit
    purchaseLimitBalance
    isLoggedIn @client
    role {
      id
      slug
    }
  }
`

export default userFragment
