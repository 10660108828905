import { down, up } from 'styled-breakpoints'
import styled from 'styled-components'

import Grid from '~/truck/grid'
import Icon from '~/truck/icon'
import Label from '~/truck/label'
import theme from '~/truck/theme'

import FormActions from '../form-actions'

export const CircularBackground = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 50%;
  display: flex;
  height: ${theme.spacing.cozy * 2.5}px;
  justify-content: center;
  margin-right: ${theme.spacing.cozy}px;
  width: ${theme.spacing.cozy * 2.5}px;
`

export const StyledFormActions = styled(FormActions)`
  padding: 0 ${theme.spacing.comfortable}px;

  ${up('lg')} {
    margin-top: ${theme.spacing.cozy}px;
  }
`

export const FormWrapper = styled.form`
  padding-top: ${theme.spacing.cozy}px;
`

export const StyledIcon = styled(Icon)`
  height: ${theme.spacing.cozy * 5}px;
  left: calc(50% - ${theme.spacing.cozy * 2.5}px);
  position: absolute;
  top: calc(50% - ${theme.spacing.cozy * 5}px);
  width: ${theme.spacing.cozy * 5}px;
  z-index: 1;
`

export const ErrorLabel = styled(Label).attrs({
  $color: 'error',
  $textStyle: 'h6Regular',
  as: 'span',
})`
  margin-top: ${theme.spacing.compact}px;
  margin-left: ${theme.spacing.cozy * 2}px;
`

export const StyledGridColumn = styled(Grid.Column)`
  ${down('sm')} {
    margin: ${props => props.theme.spacing.cozy}px;
  }
`
