import gql from 'graphql-tag'

const userLineOfCreditEnableQuery = gql`
  query userLineOfCreditEnable {
    user {
      id
      lineOfCredit {
        id
        isEnabled
      }
    }
  }
`

export default userLineOfCreditEnableQuery
