import * as React from 'react'

import Icon from '~/truck/icon'

interface ListItemProps {
  name: string
  leftIcon?: string
  href?: string
  textIsBold?: boolean
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
}

const ListItem = React.forwardRef<HTMLAnchorElement, ListItemProps>(
  ({ name, leftIcon, textIsBold, href, onClick }, ref) => {
    return (
      <a ref={ref} className="flex h-12 items-center gap-2 px-4" href={href} onClick={onClick}>
        {leftIcon && <Icon type={leftIcon} color="black" />}
        <span className={textIsBold && 'font-semibold'}>{name}</span>
        <Icon className="ml-auto" type="caret-right" color="black" size="sm" />
      </a>
    )
  },
)

export default ListItem
