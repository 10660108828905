import * as React from 'react'
import styled from 'styled-components'

import Label from '~/truck/label'

import Price from '../price'

export enum PriceLineType {
  SEPARATOR = 'separator',
  LABEL = 'label',
  DEFAULT = 'default', // price
}

interface SeparatorLineTypeProps {
  type: PriceLineType.SEPARATOR
}

interface LabelLineTypeProps {
  type: PriceLineType.LABEL
  label: string
}

interface DefaultLineTypeProps {
  type: PriceLineType.DEFAULT
  label: string
  price: string | number
  lineStyle?: {
    priceColor?: 'error' | 'black'
    textStyle?: string
  }
}

export type PriceLineProps = DefaultLineTypeProps | LabelLineTypeProps | SeparatorLineTypeProps

interface PriceLineComponentProps {
  lineitem: PriceLineProps
}

const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  > span {
    flex: 1;
    margin-right: ${props => props.theme.spacing.comfortable}px;
  }
`

const StyledSeparator = styled.div`
  background: ${props => props.theme.colors.gray};
  height: 1px;
  margin-bottom: ${props => props.theme.spacing.comfortable}px !important;
  margin-top: ${props => props.theme.spacing.cozy}px;
`

export function PriceLine(props: PriceLineComponentProps) {
  const { lineitem } = props

  if (lineitem.type === PriceLineType.LABEL) {
    return (
      <StyledContainer>
        <Label as="span" $textStyle="h6Regular" $color="grayscale70">
          {lineitem.label}
        </Label>
      </StyledContainer>
    )
  }

  if (lineitem.type === PriceLineType.SEPARATOR) {
    return <StyledSeparator />
  }

  return (
    <StyledContainer>
      <Label as="span" $textStyle={lineitem.lineStyle?.textStyle}>
        {lineitem.label}
      </Label>
      <Price
        value={lineitem?.price}
        textStyle={lineitem?.lineStyle?.textStyle}
        color={lineitem?.lineStyle?.priceColor}
      />
    </StyledContainer>
  )
}
