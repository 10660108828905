import { useFlag } from 'toggled'

import type { IShippingType } from '~/types/shipping'
import { flags } from '~/utils/constants'

export default function useCanSelectTimeRange() {
  const canSelectDeliveryTimeRange = useFlag(flags.SELECT_DELIVERY_TIME_RANGE)

  const canSelectPickupTimeRange = useFlag(flags.SELECT_PICKUP_TIME_RANGE)

  return function canSelectTimeRange(shippingType: IShippingType) {
    if (shippingType.isDelivery) {
      return canSelectDeliveryTimeRange
    }

    if (shippingType.isPickup) {
      return canSelectPickupTimeRange
    }

    return false
  }
}
