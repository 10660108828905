import React from 'react'
import { useFlag } from 'toggled'

import useBuyingInStore from '~/hooks/use-buying-in-store'
import useExpressDeliverySession from '~/hooks/use-express-delivery-session'
import useModalManager from '~/hooks/use-modal-manager'
import useMultiDistributorSession from '~/hooks/use-multi-distributor-session'
import Icon from '~/truck/icon'
import { flags } from '~/utils/constants'

import StoreSelectorModal from '../buying-in-store-selector'
import ShippingSelectorModal from '../shipping-modal'

import Component from './component'

const ShippingSelector = props => {
  const { style, className } = props

  const buyingInStore = useBuyingInStore()

  const isPublicPartnerVendor = useFlag(flags.PUBLIC_PARTNER_VENDOR)

  const hasStoreNavigation = useFlag(flags.STORE_NAVIGATION)

  const { multiDistributorSession } = useMultiDistributorSession()

  const { expressDeliverySession } = useExpressDeliverySession()

  const modalManager = useModalManager()

  const hasExpressDelivery = useFlag(flags.EXPRESS_DELIVERY) && expressDeliverySession

  if (
    (!hasExpressDelivery && !multiDistributorSession && isPublicPartnerVendor) ||
    (!hasStoreNavigation && !hasExpressDelivery && !multiDistributorSession)
  ) {
    return null
  }

  function onSelect() {
    if (props.onClick) {
      props.onClick()
    }

    if (multiDistributorSession || expressDeliverySession) {
      modalManager.openModal(ShippingSelectorModal)
    } else {
      modalManager.openModal(StoreSelectorModal)
    }
  }

  const deliveryAddress = multiDistributorSession?.deliveryAddress

  if (hasExpressDelivery) {
    const shippingType = expressDeliverySession?.shippingType?.name

    const subtitle = expressDeliverySession?.deliveryAddress?.line ?? expressDeliverySession?.store?.name

    if (!subtitle) {
      return null
    }

    return (
      <Component
        style={style}
        className={className}
        title={shippingType}
        icon={<Icon type="location" color="primary" />}
        text={subtitle}
        onClick={onSelect}
      />
    )
  }

  if (deliveryAddress?.line) {
    return (
      <Component
        style={style}
        className={className}
        title="Dirección seleccionada"
        icon={<Icon type="location" color="primary" />}
        text={deliveryAddress.line}
        onClick={onSelect}
      />
    )
  }

  const sessionStore = multiDistributorSession?.store

  if (sessionStore || (hasStoreNavigation && buyingInStore)) {
    const store = sessionStore || buyingInStore

    return (
      <Component
        style={style}
        className={className}
        title={store.makesDelivery ? 'Tienda seleccionada' : 'Tienda de recojo'}
        icon={<Icon type="store" color="primary" />}
        text={store.name}
        onClick={onSelect}
      />
    )
  }

  return null
}

export default ShippingSelector
