import gql from 'graphql-tag'

const addCouponCodeMutation = gql`
  mutation addCouponCode($couponCode: String!) {
    addCouponCode(input: { couponCode: $couponCode }) {
      promotion {
        id
        name
        couponCode
      }
    }
  }
`

export default addCouponCodeMutation
