import React from 'react'

import Loader, { LoaderProps } from '~/truck/loader'

import { Container } from './elements'

interface OwnLoaderProps extends LoaderProps {
  style?: React.CSSProperties
  className?: string
}

function OwnLoader({ style, className, ...loaderProps }: OwnLoaderProps) {
  return (
    <Container style={style} className={className}>
      <Loader {...loaderProps} />
    </Container>
  )
}

export default OwnLoader
