import { whatsappUrl } from '~/utils/urls'

const vendor = {
  whatsappUrl: root => {
    const { whatsapp, country } = root

    if (!whatsapp) {
      return null
    }

    const code = country.callingCode

    const phone = `${code}${whatsapp}`

    return whatsappUrl(phone)
  },
}

export default vendor
