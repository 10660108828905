import React from 'react'

import Media from '../media'

import Desktop from './component.desktop'
import Mobile from './component.mobile'

const ShippingSelector = props => {
  return <Media desktop={<Desktop {...props} />} mobile={<Mobile {...props} />} />
}

export default ShippingSelector
