import * as React from 'react'
import styled from 'styled-components'
import { useFlagQueryFn } from 'toggled'

import { useIsMobile } from '~/hooks'
// TODO(refactor/cart): this is temporal, we need create a controller inside shopping cart module to open cart drawer
import { useUiStore } from '~/modules/shopping-cart/stores/ui-store'
import Button from '~/truck/button'
import { forceReloadTo } from '~/utils'
import { flagQueries, images } from '~/utils/constants'

import { BulkDialogResultContent } from '../bulk-dialog'
import { useDialogState } from '../dialog-provider'

const StyledButton = styled(Button)`
  width: auto;
`

export function DialogSuccessfullyProcessedStepContent() {
  const openCartDrawer = useUiStore(store => store.openCartDrawer)

  const flagQuery = useFlagQueryFn()

  const isMobile = useIsMobile()

  const { onModalClose } = useDialogState()

  const onSeeCartClick = () => {
    if (isMobile || !flagQuery(flagQueries.HAS_FULL_WIDTH)) {
      forceReloadTo('/carrito')
    } else {
      openCartDrawer()
    }

    onModalClose()
  }

  return (
    <BulkDialogResultContent
      imageSrc={images.ILLUSTRATION_CHECK}
      description="Se han agregado al carrito los productos y cantidades solicitadas"
      renderFooter={() => (
        <StyledButton type="button" color="primary" onClick={onSeeCartClick}>
          Ver carrito
        </StyledButton>
      )}
    />
  )
}
