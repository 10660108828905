import gql from 'graphql-tag'

import { storeFragment } from '../fragments'

const buyingInStoreQuery = gql`
  query buyingInStore {
    buyingInStore {
      ...store
    }
  }
  ${storeFragment}
`

export default buyingInStoreQuery
