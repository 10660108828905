import gql from 'graphql-tag'

const loginMutation = gql`
  mutation login($password: String!, $withStore: Boolean!, $email: String, $code: String) {
    login(input: { email: $email, code: $code, password: $password }) {
      token
      role
      user {
        id
        originalId
        firstName
        lastName
        email
        cart @include(if: $withStore) {
          store {
            id
          }
        }
      }
    }
  }
`

export default loginMutation
