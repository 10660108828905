import gql from 'graphql-tag'

const acceptAgreementsMutation = gql`
  mutation acceptAgreements(
    $acceptedPrivacyPolicy: Boolean
    $acceptedTermsAndConditions: Boolean
    $allowAdvertising: Boolean
  ) {
    acceptAgreements(
      input: {
        acceptedPrivacyPolicy: $acceptedPrivacyPolicy
        acceptedTermsAndConditions: $acceptedTermsAndConditions
        allowAdvertising: $allowAdvertising
      }
    ) {
      user {
        id
        acceptedPrivacyPolicy
        acceptedTermsAndConditions
        allowAdvertising
        needsToAcceptTermsAndConditions
      }
    }
  }
`

export default acceptAgreementsMutation
