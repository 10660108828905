import React from 'react'

class ScrollBlocker extends React.Component {
  componentDidMount() {
    document.documentElement.style.overflowY = 'hidden'
  }

  componentWillUnmount() {
    document.documentElement.style.overflowY = 'auto'
  }

  render() {
    return null
  }
}

export default ScrollBlocker
