import * as React from 'react'
import styled from 'styled-components'

import Anchor from '~/truck/anchor'
import Icon from '~/truck/icon'
import Label from '~/truck/label'

export interface DropdownMenuItemProps {
  label: string
  iconType: string
  href?: string
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  onMouseEnter?: () => void
}

const StyledDropdownMenuItemContainer = styled(Anchor)`
  align-items: center;
  display: flex;
  padding: ${props => props.theme.spacing.cozy}px ${props => props.theme.spacing.comfortable}px;
  width: 100%;

  &:hover {
    background-color: ${props => props.theme.palette.primary10};
    cursor: pointer;
  }
`

const StyledIcon = styled(Icon)`
  margin-right: ${props => props.theme.spacing.cozy}px;
`

export const DropdownMenuItem = React.forwardRef<HTMLAnchorElement, DropdownMenuItemProps>(function DropdownMenuItem(
  props: DropdownMenuItemProps,
  ref,
) {
  const { iconType, label, href, onMouseEnter, onClick } = props

  return (
    <StyledDropdownMenuItemContainer href={href} onClick={onClick} onMouseEnter={onMouseEnter} ref={ref}>
      <StyledIcon type={iconType} size="sm" color="grayscale80" />
      <Label as="span" $textStyle="h5Regular">
        {label}
      </Label>
    </StyledDropdownMenuItemContainer>
  )
})
