import React from 'react'

import EntityLines from '~/truck/entity-lines'

interface DocumentProps {
  id: string
  documentNumber: string
  fiscalName?: string
  fiscalAddress?: string
  email?: string
  lineOfBusiness?: string
  selectable?: boolean
  removable?: boolean
  onRemove?: (id: string) => void
  style?: React.CSSProperties
  className?: string
}

const Document = ({
  id,
  documentNumber,
  fiscalName,
  fiscalAddress,
  email,
  lineOfBusiness,
  selectable,
  removable,
  onRemove,
  style,
  className,
}: DocumentProps) => {
  return (
    <EntityLines
      id={id}
      removable={removable}
      selectable={selectable}
      lines={[documentNumber, fiscalName, fiscalAddress, email, lineOfBusiness]}
      style={style}
      className={className}
      onRemove={onRemove}
    />
  )
}

export default Document
