import React from 'react'
import { filter, map } from 'lodash'

import Label from '~/truck/label'

const Adjustments = props => {
  const { adjustments } = props

  const regex = /(\([0-9]{1,} gratis\))$/

  const getFreeAdjustment = adjustment => {
    return adjustment.label.match(regex)
  }

  const freeAdjustments = filter(adjustments, getFreeAdjustment)

  const renderLabel = adjustment => {
    return (
      <Label key={adjustment.label} $textStyle="h6Semibold" $color="secondary">
        {adjustment.label}
      </Label>
    )
  }

  return <>{map(freeAdjustments, renderLabel)}</>
}

export default Adjustments
