import { paymentMethods } from '~/utils/constants'

const paymentMethodsMinimumAmount = {
  [paymentMethods.CULQI_CUOTEALO]: 70,
}

const getPaymentMethodMinimumAmount = paymentMethod => {
  if (!paymentMethod) return 0

  return paymentMethodsMinimumAmount[paymentMethod.slug] ?? 0
}

export default getPaymentMethodMinimumAmount
