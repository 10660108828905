import React, { ChangeEvent, CSSProperties, useState } from 'react'

import Field from '~/truck/field'
import Input from '~/truck/input'

import { StyledLeftItemInput } from './elements'

type PhoneNumberInputProps = {
  areaCode?: string
  value?: string
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void
  limit?: number
  id?: string
  name?: string
  style?: CSSProperties
  className?: string
  placeholder?: string
  [key: string]: any
}

function parseValueToPhoneNumber(value: string | undefined) {
  if (!value) {
    return value
  }

  let result = ''

  for (let i = 0; i < value.length; i += 3) {
    result += `${i !== 0 && i % 3 === 0 ? ' ' : ''}${value.substring(i, i + 3)}`
  }

  return result
}

function validateValue(value: string) {
  return /[0-9]+$/.test(value)
}

function parsePhoneNumberToValue(phoneNumber: string) {
  return phoneNumber.split(' ').join('')
}

export const PhoneNumberInput = ({
  areaCode,
  value,
  onChange,
  limit,
  style,
  className,
  placeholder,
  ...rest
}: PhoneNumberInputProps) => {
  const [_value, setValue] = useState(value)

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = parsePhoneNumberToValue(event.target.value)

    if (newValue.length > 0 && !validateValue(newValue)) {
      return
    }

    if (limit) {
      if (newValue.length > limit) {
        return
      }
    }

    event.target.value = newValue

    onChange?.(event)

    if (!value) {
      setValue(newValue)
    }
  }

  return (
    <>
      <Input
        className={className}
        style={style}
        placeholder={placeholder}
        value={parseValueToPhoneNumber(value ?? _value)}
        onKeyPress={(event: any) => {
          if (!validateValue(event.key)) {
            event.preventDefault()
          }
        }}
        onChange={handleOnChange}
        leftItem={areaCode && <StyledLeftItemInput>{areaCode}</StyledLeftItemInput>}
      />
      <Field hidden value={value ?? _value} {...rest} standalone />
    </>
  )
}
