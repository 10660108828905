import { isNil, omitBy } from 'lodash'

import { upsertParamsToQueryKey } from '~/utils'

const QUERY_KEY_PRODUCT_FILTERS = ['/catalog/products/filters']

export const getProductFiltersQueryKey = (filters: string[], term?: string) => {
  const params = omitBy({ filters: [...filters].sort(), term }, isNil)

  return upsertParamsToQueryKey(QUERY_KEY_PRODUCT_FILTERS, params)
}
