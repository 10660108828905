import React, { useMemo, useState } from 'react'
import cookie from 'isomorphic-cookie'

import { MultiDistributorSessionContext } from '~/contexts'
import { getCookieConfig } from '~/utils'
import { cookieNames } from '~/utils/constants'

const MultiDistributorSessionProvider = props => {
  const { session, children } = props

  const [value, setValue] = useState(session)

  const contextValue = useMemo(() => {
    return {
      multiDistributorSession: value,
      setMultiDistributorSession: newValue => {
        cookie.save(cookieNames.MULTI_DISTRIBUTOR_SESSION, newValue, getCookieConfig())

        setValue(newValue)
      },
    }
  }, [value])

  return (
    <MultiDistributorSessionContext.Provider value={contextValue}>{children}</MultiDistributorSessionContext.Provider>
  )
}

export default MultiDistributorSessionProvider
