import * as React from 'react'

import { formatExpirationDate } from '~/components/payment-methods/utils'
import Field from '~/truck/field'

interface PaymentExpirationYearProps {
  id?: string
  name?: string
  placeholder?: string
  format?: any
  maxLength?: number
  [key: string]: any
}

const DEFAULT_MAX_LENGTH = 2

export default function PaymentExpirationYear(props: PaymentExpirationYearProps) {
  const { id, name, placeholder, format, maxLength, ...rest } = props

  return (
    <Field
      id={id ?? 'year'}
      name={name ?? 'year'}
      placeholder={placeholder ?? 'Año de expiración'}
      maxLength={maxLength ?? DEFAULT_MAX_LENGTH}
      format={format ?? formatExpirationDate}
      {...rest}
    />
  )
}
