import { remove, startsWith } from 'lodash'

import { serializeFilterValues } from './filters-serialize'
import type { Action, FiltersState } from './types'

function filtersReducer(state: FiltersState, action: Action): FiltersState {
  switch (action.type) {
    case 'TOGGLE_LIST_FILTER_VALUE': {
      const [removeFilterValue] = remove(state.serializedFilters, filter => filter === action.serializedFilter)

      if (removeFilterValue) {
        return {
          ...state,
          serializedFilters: [...state.serializedFilters],
        }
      }

      return {
        ...state,
        serializedFilters: [...state.serializedFilters, action.serializedFilter],
      }
    }
    case 'SET_RANGE_FILTER': {
      const { target, minimumValue, maximumValue } = action

      remove(state.serializedFilters, filter => startsWith(filter, `${target}:`))

      if (!minimumValue && !maximumValue) {
        return {
          ...state,
          serializedFilters: [...state.serializedFilters],
        }
      }

      const serialized = serializeFilterValues({
        target,
        values: [String(minimumValue ?? ''), String(maximumValue ?? '')],
      })

      return {
        ...state,
        serializedFilters: [...state.serializedFilters, serialized],
      }
    }
    case 'CLEAR_FILTERS': {
      return {
        ...state,
        serializedFilters: [],
      }
    }
    case 'SET_TOTAL_OF_RESULTS': {
      if (action.totalOfResults === state.totalOfResults) {
        return state
      }

      return {
        ...state,
        totalOfResults: action.totalOfResults,
      }
    }
    case 'SET_NO_FILTERS_AVAILABLE': {
      if (action.noFiltersAvailable === state.noFiltersAvailable) {
        return state
      }

      return {
        ...state,
        noFiltersAvailable: action.noFiltersAvailable,
        serializedFilters: [], // we need to clear selected filters when no filters are available
      }
    }
    case 'SET_SORT_BY': {
      if (action.sortBy === state.sortBy) {
        return state
      }

      return {
        ...state,
        sortBy: action.sortBy,
      }
    }
  }
}

export default filtersReducer
