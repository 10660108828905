import React from 'react'
import { map } from 'lodash'
import styled from 'styled-components'
import { useFlag } from 'toggled'

import Product from '~/components/product'
import theme from '~/truck/theme'
import { cloudinary } from '~/utils'
import { flags, transformations } from '~/utils/constants'

import Header from './header'

const Container = styled.div`
  height: calc(100% - ${theme.spacing.cozy * 8}px);
`

const Content = styled.div`
  height: calc(100% - ${theme.spacing.cozy * 6}px);
  overflow-y: auto;

  > * {
    border-bottom: 1px solid ${theme.colors.gray};
  }
`

const getOptimizedPhoto = originalUrl => {
  return cloudinary.applyTransformations(originalUrl, transformations.TINY_PRODUCT)
}

const Cart = props => {
  const { lineItems, upsertLineItem, removeLineItem, style, className } = props

  const hasHandleStockFlag = useFlag(flags.HANDLE_STOCK)

  const renderLineItem = lineItem => {
    const product = lineItem.product || {}

    const { quantity } = lineItem

    const counterProps = {
      value: quantity,
      onChange: newQuantity => upsertLineItem(lineItem, newQuantity),
    }

    if (hasHandleStockFlag) {
      counterProps.maxValue = product.stock
    }

    return (
      <Product.Mobile
        {...lineItem}
        id={product.id}
        slug={product.slug}
        stock={product.stock}
        photo={getOptimizedPhoto(lineItem.photo)}
        description={product.description}
        pricing={product.pricing}
        key={lineItem.id}
        style={{ flexShrink: 0 }}
        presentation={product.presentation}
        withCounter
        removable
        counterProps={counterProps}
        onRemove={() => removeLineItem(lineItem)}
      />
    )
  }

  return (
    <Container style={style} className={className}>
      <Header numOfProducts={lineItems.length} />
      <Content>{map(lineItems, renderLineItem)}</Content>
    </Container>
  )
}

export default Cart
