import { useEffect, useState } from 'react'
import { groupBy } from 'lodash'

import { useCart } from '~/hooks'
import type { IProduct } from '~/types/products'
import { updateQuantitiesInProducts } from '~/utils'

import { useSuggestedProducts } from '../hooks'
import { filterProductsByLineItems } from '../utils'

export const useInitialSuggestedProducts = () => {
  const { data: suggestedProductsData, loading } = useSuggestedProducts()

  const suggestedProducts = suggestedProductsData?.suggestedProducts

  const [products, setProducts] = useState<IProduct[]>(undefined)

  const [initialProductsGroup, setInitialProductsGroup] = useState(undefined)

  const { cart, lineItems } = useCart()

  useEffect(() => {
    if (products) {
      return
    }

    if (!suggestedProducts || lineItems.length === 0) {
      return
    }

    const productsList = filterProductsByLineItems(suggestedProducts, lineItems).slice(0, 8)

    setProducts(productsList)

    setInitialProductsGroup(groupBy([productsList, ...lineItems.map(item => item.product)], 'id'))
  }, [suggestedProducts, setProducts, products, lineItems])

  useEffect(() => {
    if (!products || !suggestedProducts) {
      return
    }

    const newSuggestedProducts = groupBy(suggestedProducts, 'id')

    setProducts(
      products.map(product =>
        newSuggestedProducts[product.id] ? { ...newSuggestedProducts[product.id][0] } : product,
      ),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestedProducts, setProducts])

  updateQuantitiesInProducts(products, cart)

  return {
    products,
    initialProductsGroup,
    loading,
    lineItems,
  }
}
