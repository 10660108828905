import * as React from 'react'
import renderHTML from 'react-html-parser'
import { filter, map } from 'lodash'
import styled, { withTheme } from 'styled-components'
import { useFeature, useFlag } from 'toggled'

import AddToCartBlocker from '~/components/add-to-cart-blocker'
import { UnitTooltip } from '~/components/unit-tooltip'
import { useCartProps, useCurrencyConfig, useVendor } from '~/hooks'
import { getQuantityRange } from '~/hooks/use-counter'
import { RemoveLineitemButton } from '~/modules/shopping-cart/containers/remove-lineitem-button/RemoveLineitemButton'
import Icon from '~/truck/icon'
import Label from '~/truck/label'
import NewButton from '~/truck/new-button/NewButton'
import theme from '~/truck/theme'
import { formatPrice } from '~/utils'
import { flags } from '~/utils/constants'

import { AddToFavoritesButtonCircular } from '../add-to-favorites-button'
import { Counter } from '../counter'
import Price from '../price'
import RatingStatus from '../rating-status'
import Ribbon from '../ribbon'

import { ActionContainer } from './elements'
import {
  Container,
  ContentContainer,
  ImageContainer,
  InfoContainer,
  LabelsContainer,
  Paragraph,
  StyledAnchor,
  StyledLabel,
  StyledTotalPriceContainer,
} from './elements.mobile'
import ProductImage from './image'
import Overlay from './overlay'
import { getPriceFromTier, OutOfStockLabel, priceVisibility, unitTooltipDuration } from './shared'

const StyledActionContainer = styled(ActionContainer)<{ isLiftedUp: boolean }>`
  bottom: ${theme.spacing.comfortable}px;
  ${props =>
    props.isLiftedUp &&
    `
    bottom: ${theme.spacing.compact * 9}px;
`}
`

const StyledActionButton = styled.button`
  position: absolute;
  top: 100%;
`
const StyledAddToFavoritesButtonCircularMobile = styled(AddToFavoritesButtonCircular)`
  bottom: 0;
  left: 0;
  position: absolute;
`

const StyledNameLabel = styled(Label)<{ isRemovable: boolean }>`
  width: ${props => (props.isRemovable ? `calc(100% - ${theme.spacing.relaxed}px)` : '100%')};
`

const Mobile = props => {
  const {
    style,
    className,
    onClick,
    unavailable,
    salesUnitFactor,
    inventoryUnit,
    name,
    outOfStockLabel,
    photo,
    href,
    presentation,
    hasVariants,
    asLineItem,
    adjustments,
    allowTaxLabel,
    oldPrice,
    reviewStats,
    quantity,
    removable,
    hideCounter,
    lineItemPrice,
    ribbonLabel,
    variantInStock,
    onFavorite,
    favoriteSince,
    showMyFavorites = false,
    isTaxExempt,
    tierPricing,
    allowDiscountLabel,
    hidePresentationInCatalog,
    attributesSummary,
    shouldEllipsisName = true,
    hidePrice: hidePriceBeforeLogin,
    isCart = false,
  } = props

  const { counterProps, returnedStock: stock } = useCartProps({ ...props })

  const hidePrices = useFlag(flags.HIDE_PRICES)

  const hasReviewFF = useFlag(flags.HAS_REVIEW)

  const hasUnits = useFlag(flags.UNITS)

  const shouldShowPresentation = !hidePresentationInCatalog && presentation

  const showSkuLabelFeature = useFeature(flags.SHOW_SKU_LABEL)

  const shouldShowSkuLabel = showSkuLabelFeature && (!hasVariants || isCart)

  const shouldShowAttributesSummary = attributesSummary && (!hasVariants || isCart)

  const [counterValue, setCounterValue] = React.useState(counterProps.value || 0)

  const hasStock = stock > 0

  const hasOwnOrVariantStock = hasStock || variantInStock

  const withAction = hasOwnOrVariantStock && !asLineItem && counterProps.canJumpAtLeastOnce

  const [state, setState] = React.useState({
    isCounterOpen: false,
    counterError: null,
  })

  const vendor = useVendor()

  const showPriceOverOptionsButton = vendor.settings?.products?.showPriceOverOptionsButton

  const showProductStock = useFeature(flags.SHOW_PRODUCT_STOCK)

  const handleStock = useFeature(flags.HANDLE_STOCK)

  const { maxValue: maxStock } = getQuantityRange(counterProps)

  const stockToDisplay = counterProps.maxValue ? maxStock : stock

  const shouldShowStock =
    !isCart &&
    showProductStock &&
    handleStock &&
    hasStock &&
    (!hasVariants || (hasVariants && showPriceOverOptionsButton)) &&
    counterProps.canJumpAtLeastOnce &&
    !unavailable &&
    !hidePriceBeforeLogin

  const onCounterOpen = () => {
    setState(prevState => ({ ...prevState, isCounterOpen: true }))
  }

  const onCounterClose = () => {
    setState(prevState => ({ ...prevState, isCounterOpen: false }))
  }

  const onCounterError = error => {
    setState(prevState => ({ ...prevState, counterError: error }))
  }

  const renderAdjustments = () => {
    const regex = /(\([0-9]{1,} gratis\))$/

    const getFreeAdjustment = adjustment => {
      return adjustment.label.match(regex)
    }

    const freeAdjustments = filter(adjustments, getFreeAdjustment)

    const renderLabel = adjustment => {
      return (
        <Label $textStyle="h6Semibold" $color="secondary">
          {adjustment.label}
        </Label>
      )
    }

    return map(freeAdjustments, renderLabel)
  }

  const shouldShowRating = hasReviewFF && reviewStats

  const price = getPriceFromTier(props)

  const { isCounterOpen, counterError } = state

  const totalPrice = price * quantity

  const currencyConfig = useCurrencyConfig()

  const [valueInt, valueDecimal] = formatPrice(totalPrice, currencyConfig)

  const renderInfo = () => {
    return (
      <InfoContainer>
        <div style={{ minHeight: removable ? `${theme.spacing.compact * 25}px` : null }}>
          {removable && <RemoveLineitemButton product={props} />}
          <StyledAnchor href={href} onClick={onClick} ellipsis={shouldEllipsisName}>
            <StyledNameLabel as="p" $textStyle="h5Regular" $color="black" isRemovable={removable}>
              {renderHTML(name)}
            </StyledNameLabel>
          </StyledAnchor>
          <LabelsContainer>
            {shouldShowPresentation && (
              <StyledLabel $ellipsis as="p" $textStyle="h6Regular" $color="grayscale70">
                {presentation}
              </StyledLabel>
            )}
            {shouldShowAttributesSummary && (
              <StyledLabel $ellipsis as="p" $textStyle="h6Regular" $color="grayscale60">
                {attributesSummary}
              </StyledLabel>
            )}
            {shouldShowSkuLabel && (
              <StyledLabel $ellipsis as="p" $textStyle="h6Regular" $color="grayscale60">
                sku: {props.sku}
              </StyledLabel>
            )}
            {shouldShowStock && (
              <StyledLabel $ellipsis as="p" $textStyle="h6Regular" style={{ color: '#16A34A' }}>
                <Label as="span" $textStyle="h6Semibold" style={{ color: 'currentcolor' }}>
                  {stockToDisplay > 9999 ? '+9999' : stockToDisplay}
                </Label>{' '}
                en stock
              </StyledLabel>
            )}
          </LabelsContainer>
          {!hidePriceBeforeLogin && !hidePrices && (
            <Price
              value={price}
              oldValue={oldPrice}
              style={{ visibility: priceVisibility(hasVariants && !showPriceOverOptionsButton && !isCart) }}
              allowTaxLabel={allowTaxLabel}
              allowDiscountLabel={allowDiscountLabel}
              isTaxExempt={isTaxExempt}
              tierPricing={tierPricing}
            />
          )}
          {asLineItem && renderAdjustments()}
        </div>
        {removable && !hidePrices && (
          <StyledTotalPriceContainer>
            <Label $ellipsis $textStyle="h6Semibold">
              Total producto:
            </Label>
            <Label $ellipsis as="span" $textStyle="h5Semibold">
              {valueInt}
              {valueDecimal && (
                <>
                  {currencyConfig.decimal}
                  <Label as="span" $textStyle="h5Semibold">
                    {valueDecimal}
                  </Label>
                </>
              )}
            </Label>
          </StyledTotalPriceContainer>
        )}
      </InfoContainer>
    )
  }

  const renderQuantity = () => {
    return (
      <Label as="span" $textStyle="h6Regular">
        {`x${quantity}`}
      </Label>
    )
  }

  const renderGoToProductPage = () => {
    return (
      <StyledActionContainer isLiftedUp={removable && !hidePrices}>
        <NewButton asChild variant="secondary" size="icon">
          <a href={href} onClick={onClick}>
            <Icon type="caret-right" color="currentColor" />
          </a>
        </NewButton>
      </StyledActionContainer>
    )
  }

  const renderCounter = () => {
    if (hideCounter) {
      return null
    }

    return (
      <>
        <AddToCartBlocker component={StyledActionContainer} isLiftedUp={removable && !hidePrices}>
          {!hasUnits ? (
            <Counter {...counterProps} onOpen={onCounterOpen} onClose={onCounterClose} onError={onCounterError} />
          ) : (
            <UnitTooltip
              visible={isCounterOpen && counterValue > 0}
              salesUnitFactor={salesUnitFactor}
              inventoryUnitCode={inventoryUnit?.code}
              quantity={counterValue}
              duration={unitTooltipDuration}
              appendTo="parent"
              placement="top"
            >
              <Counter
                {...counterProps}
                onChange={value => {
                  counterProps.onChange(value)

                  setCounterValue(value)
                }}
                onOpen={onCounterOpen}
                onClose={onCounterClose}
                onError={onCounterError}
              />
            </UnitTooltip>
          )}
          {counterError && isCounterOpen && <StyledActionButton message={counterError} as={Counter.Error} />}
        </AddToCartBlocker>
      </>
    )
  }

  const renderAction = () => {
    if (!withAction) {
      return null
    }

    if ((hidePriceBeforeLogin || hasVariants) && !isCart) {
      return renderGoToProductPage()
    }

    return renderCounter()
  }

  const renderUnavailable = () => {
    return (
      <Paragraph>
        <Label as="span" $textStyle="h5Semibold">
          Producto
          <br />
          no disponible
        </Label>
        {removable && <RemoveLineitemButton product={props} />}
      </Paragraph>
    )
  }

  const renderChangedPrice = () => {
    return (
      <Paragraph>
        <Label as="span" $textStyle="h5Semibold">
          Cambio de precio
        </Label>
        <Label as="span" $textStyle="h6Regular">
          {`Antes S/${lineItemPrice}`}
        </Label>
        <Label as="span" $textStyle="h6Semibold" $color="error">
          {`Ahora S/${price}`}
        </Label>
      </Paragraph>
    )
  }

  const renderContent = () => {
    const { lineItemPrice: lineItemPriceProps = price } = props

    if (unavailable) {
      return renderUnavailable()
    }

    if (price !== lineItemPriceProps) {
      return renderChangedPrice()
    }

    return (
      <ContentContainer>
        {renderInfo()}
        {asLineItem && renderQuantity()}
        {renderAction()}
      </ContentContainer>
    )
  }

  const onContainerClick = e => {
    const { isCounterOpen } = state

    const isKnob = e.target.dataset.id === 'knob'

    if (!isKnob && !isCounterOpen && onClick) {
      onClick(e)
    }
  }

  return (
    <Container style={style} className={className} onClick={onContainerClick}>
      <ImageContainer>
        {showMyFavorites && !hasVariants && (
          <StyledAddToFavoritesButtonCircularMobile favoriteSince={favoriteSince} onClick={onFavorite} />
        )}
        {shouldShowRating && <RatingStatus className="absolute bottom-0 right-0 !z-[1]" rating={reviewStats?.rating} />}
        <ProductImage
          onClick={onClick}
          name={name}
          unavailable={unavailable}
          height={theme.spacing.cozy * 15}
          width={theme.spacing.cozy * 16}
          photo={photo}
          asLineItem={asLineItem}
          stock={stock}
          href={href}
          variantInStock={variantInStock}
          canJumpAtLeastOnce={counterProps.canJumpAtLeastOnce}
          label={<OutOfStockLabel outOfStockLabel={outOfStockLabel} textStyle="h5Semibold" />}
          style={{ position: 'unset' }}
        />
      </ImageContainer>
      {ribbonLabel && !asLineItem && <Ribbon label={ribbonLabel} />}
      {isCounterOpen && <Overlay />}
      {renderContent()}
    </Container>
  )
}

export default withTheme(Mobile)
