import React from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'

import EditModal from '~/components/edit-modal'
import { editOrderSetShippingMutation } from '~/gql/mutations'
import { deliveryDateTimeConfigQuery, userAddressesQuery } from '~/gql/queries'
import useModalManager from '~/hooks/use-modal-manager'
import Anchor from '~/truck/anchor'
import Loader from '~/truck/loader'
import notifier from '~/truck/notifier'
import theme from '~/truck/theme'
import { handleSubmissionErrors } from '~/utils'

import UpsertAddressesModal from '../upsert-addresses-modal'

import AddressesDateTimeSelector from './address-date-time-selector'
import Addresses from './addresses'

const StyledAnchor = styled(Anchor)`
  cursor: pointer;
  display: inline-block;
  margin-top: ${theme.spacing.comfortable}px;
`

const Content = props => {
  const { shippingType } = props

  const { openModal } = useModalManager()

  const addressesResult = useQuery(userAddressesQuery)

  const dateTimeConfigResult = useQuery(deliveryDateTimeConfigQuery)

  if (addressesResult.loading || dateTimeConfigResult.loading) {
    return <Loader centered style={{ height: '100%' }} />
  }

  return (
    <>
      <Addresses data={addressesResult.data} />
      <StyledAnchor $color="info" onClick={() => openModal(UpsertAddressesModal)}>
        Agregar dirección
      </StyledAnchor>
      <AddressesDateTimeSelector
        shippingType={shippingType}
        dateTimeConfig={dateTimeConfigResult.data.dateTimeConfig}
      />
    </>
  )
}

const SetShippingModal = props => {
  const { order, ...rest } = props

  const { closeModal } = useModalManager()

  const [setShipping] = useMutation(editOrderSetShippingMutation)

  const { deliveryAddress, deliveryDate, shippingType, deliveryTimeRange } = order

  const initialValues = {
    deliveryDate,
    addressId: deliveryAddress.id,
    deliveryTimeRangeId: deliveryTimeRange.id,
  }

  const onSubmit = async values => {
    const options = {
      variables: {
        orderId: order.id,
        shippingTypeId: shippingType.id,
        deliveryAddressId: values.addressId,
        deliveryDate: values.deliveryDate,
        deliveryTimeRangeId: values.deliveryTimeRangeId,
      },
    }

    try {
      await setShipping(options)

      closeModal()

      notifier.success('Haz actualizado los datos de entrega')
    } catch (error) {
      return notifier.error(handleSubmissionErrors(error))
    }
  }

  return (
    <EditModal title="Editar datos de entrega" initialValues={initialValues} onSubmit={onSubmit} {...rest}>
      <Content shippingType={shippingType} />
    </EditModal>
  )
}

export default SetShippingModal
