import * as React from 'react'
import styled from 'styled-components'

import Icon from '~/truck/icon'
import Label from '~/truck/label'
import type { ThemeColor } from '~/types/theme'

export interface DialogHeaderProps {
  title?: string
  closable?: boolean
  onClose?: () => void
  closeIconSize?: string
  closeIconColor?: ThemeColor
}

export interface DialogTitleProps {
  children?: React.ReactNode
}

export const StyledHeaderContainer = styled.div`
  align-items: center;
  display: flex;
  height: ${props => props.theme.spacing.cozy * 6}px;
  justify-content: space-between;
  padding: ${props => props.theme.spacing.comfortable}px;
  padding-bottom: ${props => props.theme.spacing.cozy}px;

  > div[role='button'] {
    display: flex;

    &:only-child {
      margin-left: auto;
    }
  }
`

export function DialogTitle(props: DialogTitleProps) {
  return <Label as="span" $textStyle="h4Semibold" {...props} />
}

export function DialogHeader(props: DialogHeaderProps) {
  const { title, onClose, closable, closeIconSize = 'sm', closeIconColor = 'black', ...rest } = props

  return (
    <StyledHeaderContainer {...rest}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {closable ? (
        <div role="button" onClick={onClose}>
          <Icon color={closeIconColor} type="close" size={closeIconSize} />
        </div>
      ) : null}
    </StyledHeaderContainer>
  )
}
