import { useQuery } from '@apollo/react-hooks'
import { useFlag } from 'toggled'

import { useIsomorphicLayoutEffect, useModalManager, useUser } from '~/hooks'
import { storage } from '~/utils'
import { flags, storageKeys } from '~/utils/constants'

import ModalPopUp from '../../components/modal-pop-up/ModelPopUp'
import { mainPopUpQuery } from '../../gql/queries'

export const MainPopUp = () => {
  const { openModal } = useModalManager()

  const { data } = useQuery(mainPopUpQuery)

  const popUp = data?.getPopups?.popups?.main

  const user = useUser()

  const hasLoginRequiredFF = useFlag(flags.LOGIN_REQUIRED)

  const isLoggedOutPrivateLogin = hasLoginRequiredFF && !user.isLoggedIn

  useIsomorphicLayoutEffect(() => {
    if (isLoggedOutPrivateLogin || !popUp) {
      return
    }

    const currentId = storage.get(storageKeys.MAIN_POP_UP)

    if (currentId && currentId === popUp.id) {
      return
    }

    openModal(ModalPopUp, {
      popUp,
      onAfterClose: () => {
        storage.set(storageKeys.MAIN_POP_UP, popUp.id, {
          secure: false,
        })
      },
    })
  }, [popUp])

  return null
}
