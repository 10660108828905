import React from 'react'
import { clsx } from 'clsx'

import theme from '~/truck/theme'

import getPasswordStrength from './get-password-strength'

const strengths = [
  {
    text: 'Poco segura',
    color: theme.colors.error,
  },
  {
    text: 'Segura',
    color: theme.colors.warning,
  },
  {
    text: 'Muy Segura',
    color: theme.colors.success,
  },
]

interface PasswordStrengthProps {
  value: string
  className?: string
}

function PasswordStrength({ value, className }: PasswordStrengthProps) {
  const strength = getPasswordStrength(value)

  const isValidStrength = strength > 0

  const item = isValidStrength ? strengths[strength - 1] : ({} as any)

  return (
    <div className={className}>
      <div className="flex items-center justify-between gap-4">
        {strengths.map((current, index) => {
          const currentStrength = index + 1

          const color = currentStrength <= strength ? strengths[strength - 1].color : theme.colors.gray

          return <div key={current.color} className="h-1 w-1/3 rounded" style={{ backgroundColor: color }} />
        })}
      </div>
      <p className={clsx('text-xs font-semibold', !isValidStrength && 'invisible')} style={{ color: item.color }}>
        {isValidStrength ? item.text : 'placeholder'}
      </p>
    </div>
  )
}

export default PasswordStrength
