import * as React from 'react'
import { map } from 'lodash'

import useVendor from '~/hooks/use-vendor'
import Icon from '~/truck/icon'
import type { ICategory, ISubCategory } from '~/types/category'

import ListItem from './ListItem'

interface CategoriesSectionProps {
  level?: number
  dataSource: any
  onItemClick: (category: any) => void
  onBackClick: () => void
  currentCategory: ICategory | ISubCategory
}

function CategoriesSection({
  level = 1,
  dataSource,
  onItemClick,
  onBackClick,
  currentCategory,
}: CategoriesSectionProps) {
  const vendor = useVendor()

  const isSubcategory = level > 1

  let title = 'Categorías'

  if (vendor.settings.categoryAsBrand) {
    title = 'Marcas'
  }

  if (isSubcategory) {
    title = 'Regresar'
  }

  const isSubCategory = level > 1

  return (
    <section>
      <header className="my-4 ml-4 flex items-center gap-2">
        {isSubCategory && <Icon type="arrow-left" color="black" onClick={onBackClick} />}
        <span className="font-semibold">{title}</span>
      </header>
      <ul className="divide-y">
        {isSubcategory && (
          <ListItem
            name={currentCategory.name}
            textIsBold
            onClick={() => onItemClick({ ...currentCategory, subcategories: null })}
          />
        )}
        {map(dataSource, (category: ICategory | ISubCategory) => {
          return <ListItem key={category.id} name={category.name} onClick={() => onItemClick(category)} />
        })}
      </ul>
    </section>
  )
}

export default CategoriesSection
