import React, { ChangeEvent } from 'react'

import TextArea from '~/truck/text-area'

import { StyledContainer, TextareaCounter } from './TextareaWithCounter.style'

type TextareaWithCounterProps = {
  value?: string
  maxLength?: number
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void
  placeholder?: string
}

export const TextareaWithCounter = (props: TextareaWithCounterProps) => {
  return (
    <StyledContainer>
      <TextareaCounter as="p" $textStyle="h6Regular" $color="grayscale60">
        {props.value?.length ?? 0}/{props.maxLength ?? '-'}
      </TextareaCounter>
      <TextArea placeholder="Escribe aquí" style={{ maxWidth: '100%' }} {...props} />
    </StyledContainer>
  )
}
