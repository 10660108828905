import * as React from 'react'
import * as Collapsible from '@radix-ui/react-collapsible'
import { partition } from 'lodash'
import styled from 'styled-components'

import { useNanoid } from '~/hooks'

import Action from '../action'

import { ProductAttributeItem, ProductAttributeItemProps } from './components/product-attribute-item'

export interface ProductAttributesProps {
  className?: string
  attributes: ProductAttributeItemProps[]
  /**
   * @default false
   */
  isCollapsible?: boolean
  /**
   * @default 3
   */
  maxCountBeforeCollapse?: number
}

const StyledListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.cozy}px;
`

export function ProductAttributes(props: ProductAttributesProps) {
  const { attributes, isCollapsible = false, maxCountBeforeCollapse = 3, className } = props

  const id = useNanoid()

  const [isCollapsibleOpen, setIsCollapsibleOpen] = React.useState(false)

  const hasMoreThanMaxCount = attributes.length > maxCountBeforeCollapse

  if (isCollapsible && hasMoreThanMaxCount) {
    const splitAttributes = () => {
      let index = 0

      return () => index++ < maxCountBeforeCollapse
    }

    const [attributesToShow, attributeToHide] = partition(attributes, splitAttributes())

    return (
      <Collapsible.Root
        open={isCollapsibleOpen}
        onOpenChange={open => setIsCollapsibleOpen(open)}
        className={className}
      >
        <StyledListContainer key={id}>
          {attributesToShow.map(attribute => (
            <ProductAttributeItem key={`${id}${attribute.name}`} name={attribute.name} value={attribute.value} />
          ))}
          <Collapsible.Content>
            <StyledListContainer>
              {attributeToHide.map(attribute => (
                <ProductAttributeItem key={`${id}${attribute.name}`} name={attribute.name} value={attribute.value} />
              ))}
            </StyledListContainer>
          </Collapsible.Content>
          <Collapsible.Trigger asChild>
            <Action $color="info" $textStyle="h6Semibold">
              {isCollapsibleOpen ? 'Ver menos' : 'Ver más'}
            </Action>
          </Collapsible.Trigger>
        </StyledListContainer>
      </Collapsible.Root>
    )
  }

  return (
    <StyledListContainer key={id} className={className}>
      {attributes.map((attribute, index) => (
        <ProductAttributeItem key={`${id}${index}`} name={attribute.name} value={attribute.value} />
      ))}
    </StyledListContainer>
  )
}
