import { each, filter } from 'lodash'

// developers.google.com/maps/documentation/geocoding/overview#place-id
type AddressComponent = { long_name: string; short_name: string; types: string[] }

type DivisionLevels = {
  divisionLevel1?: string
  divisionLevel2?: string
  divisionLevel3?: string
  divisionLevel4?: string
}

function getGoogleMapsDivisionLevels(addressComponents: AddressComponent[]) {
  const levelDefinitions = {
    divisionLevel1: ['administrative_area_level_1'],
    divisionLevel2: [
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5',
    ],
    divisionLevel3: ['locality'],
    divisionLevel4: [
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4',
    ],
  }

  const divisionLevels: DivisionLevels = {}

  each(addressComponents, addressComponent => {
    Object.keys(levelDefinitions).forEach(levelName => {
      const [shouldIncludeLevel] = filter(levelDefinitions[levelName], division =>
        addressComponent.types.includes(division),
      )

      if (shouldIncludeLevel) {
        divisionLevels[levelName] = addressComponent.long_name
      }
    })
  })

  return divisionLevels
}

export default getGoogleMapsDivisionLevels
