import gql from 'graphql-tag'

const calculateDeliveryCostQuery = gql`
  query calculateDeliveryCost($shippingTypeId: ID, $localityId: ID, $orderId: ID, $addressId: ID) {
    deliveryCost: calculateDeliveryCost(
      shippingTypeId: $shippingTypeId
      localityId: $localityId
      orderId: $orderId
      addressId: $addressId
    )
  }
`

export default calculateDeliveryCostQuery
