import React, { forwardRef } from 'react'
import { map } from 'lodash'
import styled from 'styled-components'

import Label from '~/truck/label'
import theme from '~/truck/theme'

const MenuContainer = styled.ul`
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.gray};
  border-radius: ${theme.dimensions.borderRadius}px;
  display: ${props => (props.notSearched || !props.isOpen ? 'none' : 'block')};
  max-height: ${theme.spacing.cozy * 40}px;
  overflow-y: auto;
  z-index: 99;

  > :not(:last-child) {
    border-bottom: 1px solid ${theme.colors.gray};
  }
`

const Menu = forwardRef((props, ref) => {
  const { className, data, getItemProps, highlightedIndex, isOpen, ItemComponent, searchTerm, style, ...rest } = props

  const notSearched = data === undefined

  const isEmpty = data && data.length === 0 && searchTerm

  const emptyMessage = 'No se encontraron resultados para tu búsqueda'

  const renderItem = (item, index) => {
    return (
      <ItemComponent
        highlighted={highlightedIndex === index}
        index={index}
        item={item}
        {...getItemProps({ item, index, key: item.id })}
      />
    )
  }

  return (
    <MenuContainer
      className={className}
      isEmpty={isEmpty}
      isOpen={isOpen}
      notSearched={notSearched}
      ref={ref}
      style={style}
      {...rest}
    >
      {isEmpty ? (
        <Label as="p" style={{ padding: theme.spacing.comfortable }}>
          {emptyMessage}
        </Label>
      ) : (
        map(data, renderItem)
      )}
    </MenuContainer>
  )
})

export default Menu
