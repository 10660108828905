import * as React from 'react'
import { Form as FinalForm } from 'react-final-form'
import { Field } from 'react-final-form'
import clsx from 'clsx'

import { useIsMobile } from '~/hooks'
import NewButton from '~/truck/new-button/NewButton'
import TextArea from '~/truck/text-area'
import { defaultFinalFormDecorators } from '~/utils/constants'

import { BulkDialogContent, BulkDialogFooter } from '../bulk-dialog'
import type { FormValues, UpdatedCartItem } from '../dialog-start-process-step-content/types'
import { MAX_NUMBER_OF_LINES, parseInputToJSON } from '../dialog-start-process-step-content/utils'
import { onValidate } from '../dialog-start-process-step-content/utils'

interface BulkOrderingTypeCopyFromExcelProps {
  updateCart(items: UpdatedCartItem[]): void
}

export function BulkOrderingTypeCopyFromExcel({ updateCart }: BulkOrderingTypeCopyFromExcelProps) {
  const isMobile = useIsMobile()

  const [showWarning, setShowWarning] = React.useState(false)

  const onSubmit = (formValues: FormValues) => {
    const { result: inputParsed } = parseInputToJSON(formValues.input)

    updateCart(inputParsed)
  }

  const parseAdmittedValues = value => {
    if (!value) return

    const lines = value.split('\n')

    let endOfSlice = MAX_NUMBER_OF_LINES + 1

    if (lines.length >= endOfSlice) {
      endOfSlice -= 1

      setShowWarning(true)
    } else if (showWarning) {
      setShowWarning(false)
    }

    return lines.slice(0, endOfSlice).join('\n')
  }

  return (
    <FinalForm<FormValues> onSubmit={onSubmit} validate={onValidate} decorators={defaultFinalFormDecorators}>
      {formProps => (
        <form onSubmit={formProps.handleSubmit} autoComplete="off">
          <BulkDialogContent gutterBottom={!isMobile} gutterXAxis={!isMobile}>
            <div className="bg-zinc-50 p-6 pb-2">
              <p className="mb-6 text-secondary">
                Añade productos masivamente a tu carrito: Anda a Excel, copia la tabla sin incluir las cabeceras y pega
                los valores en la caja de texto. Puedes agregar un máximo de 50 registros.{' '}
              </p>
              <p className="mb-6 text-secondary">
                Los productos que agregues y se encuentren en tu carrito serán remplazados con los nuevos valores.
              </p>
              <div className={clsx('mb-1 lg:mb-5', showWarning && 'mb-0 md:mb-0')}>
                <Field name="input" parse={parseAdmittedValues}>
                  {props => {
                    return (
                      <TextArea
                        className="resize-none"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        placeholder={'SKU\tCantidad\nSKU\tCantidad'}
                        error={showWarning}
                      />
                    )
                  }}
                </Field>
                {showWarning ? (
                  <p className="py-2 text-xs text-critical">
                    {`Solo se permiten ${MAX_NUMBER_OF_LINES} productos como máximo.`}
                  </p>
                ) : null}
              </div>
              <BulkDialogFooter isMobile={isMobile}>
                <NewButton
                  className={isMobile ? '!w-full' : undefined}
                  color="primary"
                  type="submit"
                  loading={formProps.submitting}
                  disabled={formProps.invalid}
                >
                  Agregar al carrito
                </NewButton>
              </BulkDialogFooter>
            </div>
          </BulkDialogContent>
        </form>
      )}
    </FinalForm>
  )
}
