import React from 'react'

import { useCartUpdater } from '~/hooks'
import type { IProduct } from '~/types/products'

import { useCounterLastValueStore } from '../../stores/cart-counter-last-value-store'

import { StyledTrashIcon } from './RemoveLineitemButton.style'

interface RemoveLineitemButtonProps {
  // TODO(refactor/cart): this is temporal, we just need to pass the product slug through the props
  product: IProduct
}

export const RemoveLineitemButton = (props: RemoveLineitemButtonProps) => {
  const { product } = props

  const { remove } = useCartUpdater({ product, runPromotions: false })

  const { setLastValue } = useCounterLastValueStore()

  const handleOnClick = () => {
    setLastValue(product.id, 0)

    remove()
  }

  return <StyledTrashIcon color="error" type="trash" onClick={handleOnClick} />
}
