import styled from 'styled-components'

import { headerHeights, stack } from '~/variables'

interface ContainerProps {
  isOpen: boolean
}

function getTranslate(props: ThemeProps<ContainerProps>) {
  const { isOpen } = props

  if (isOpen) {
    return 'translate(0)'
  }

  return 'translate(-100vw)'
}

export const Container = styled.aside<ContainerProps>`
  background: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  height: calc(100% - ${headerHeights.getMainMobile}px);
  overflow: auto;
  position: fixed;
  top: ${headerHeights.getMainMobile}px;
  transform: ${getTranslate};
  transition: transform 0.25s ease-in-out;
  width: 100vw;
  z-index: ${stack.categoriesMenu};
`
