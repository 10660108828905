import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import Button from '~/truck/button'
import theme from '~/truck/theme'

import StoreSelector from '../store-selector'

export const StyledButton = styled(Button)`
  flex-shrink: 0;
  margin-left: auto;
  margin-top: auto;
  width: ${theme.spacing.cozy * 26}px;
`

export const StyledStoreSelector = styled(StoreSelector)`
  margin-bottom: ${theme.spacing.comfortable}px;
  overflow-y: auto;

  ${up('lg')} {
    overflow-y: unset;
  }
`
