import * as React from 'react'
import styled from 'styled-components'

import Loader from '~/components/loader'
import Label from '~/truck/label'

import { BulkDialogCloseButton, BulkDialogContent } from '../bulk-dialog'
import { useDialogState } from '../dialog-provider'

const StyledDialogContent = styled(BulkDialogContent)`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: ${props => props.theme.spacing.cozy * 6}px;
`
const StyledLoader = styled(Loader)`
  margin-bottom: ${props => props.theme.spacing.cozy * 4}px;
`

export function DialogProcessingStepContent() {
  const { onModalClose } = useDialogState()

  return (
    <StyledDialogContent gutterBottom gutterTop gutterXAxis>
      <BulkDialogCloseButton onClick={onModalClose} />
      <StyledLoader />
      <Label $textStyle="h5Semibold" $color="grayscale80">
        Procesando carga
      </Label>
    </StyledDialogContent>
  )
}
