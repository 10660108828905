import React from 'react'
import clsx from 'clsx'

import Icon from '~/truck/icon'

interface RatingStatusProps {
  rating?: number
  className?: string
}

// FIXME: text color
// FIXME: z-index shouldn't be here
function RatingStatus({ rating, className }: RatingStatusProps) {
  return (
    <div className={clsx('z-[8] flex h-6 items-center gap-1 rounded border bg-white p-1 text-xs', className)}>
      {rating ? (
        <>
          <Icon type="star" color="warning" size="sm" interactive={false} />
          <span>{rating}</span>
        </>
      ) : (
        <>
          <Icon type="star-outline" color="none" stroke="grayscale60" size="sm" interactive={false} />
          <span className="text-zinc-400">?</span>
        </>
      )}
    </div>
  )
}

export default RatingStatus
