import React from 'react'

import { useCarousel } from '~/hooks'
import Icon from '~/truck/icon'

import { SliderArrow, StyledSliderContainer, StyledSliderItemsContainer, StyledSliderViewport } from './Slider.styles'

type SliderProps = {
  children: JSX.Element[]
  slidesToScroll: number
  spacing?: number
}

export const Slider = (props: SliderProps) => {
  const carousel = useCarousel({
    slidesToScroll: props.slidesToScroll,
  })

  return (
    <StyledSliderContainer>
      <StyledSliderViewport ref={carousel.ui.viewportRef}>
        <StyledSliderItemsContainer slidesToScroll={props.slidesToScroll} spacing={props.spacing || 16}>
          {props.children}
        </StyledSliderItemsContainer>
      </StyledSliderViewport>
      {carousel.ui.isPrevBtnEnabled && (
        <SliderArrow color="white" style={{ left: 0 }} onClick={carousel.ui.scrollToPrev}>
          <Icon type="caret-left" color="secondary" />
        </SliderArrow>
      )}
      {carousel.ui.isNextBtnEnabled && (
        <SliderArrow color="white" style={{ right: 0 }} onClick={carousel.ui.scrollToNext}>
          <Icon type="caret-right" color="secondary" />
        </SliderArrow>
      )}
    </StyledSliderContainer>
  )
}
