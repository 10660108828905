import * as React from 'react'

import Label from '~/truck/label'

export interface TagLabelProps {
  children: React.ReactNode
}

export function TagLabel(props: TagLabelProps) {
  const { children } = props

  return (
    <Label $color="primary40" $textStyle="h6Semibold" as="span">
      {children}
    </Label>
  )
}
