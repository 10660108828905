import gql from 'graphql-tag'

const createUserFavoriteProductMutation = gql`
  mutation createUserFavoriteProduct($productId: ID!) {
    createUserFavoriteProduct(input: { productId: $productId }) {
      success
    }
  }
`

export default createUserFavoriteProductMutation
