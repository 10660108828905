interface Meta {
  active?: boolean
  submitError?: string
  dirtySinceLastSubmit?: boolean
  touched?: boolean
  error?: string
  pristine?: boolean
  submitFailed?: boolean
}

const getError = (meta: Meta) => {
  const { active, submitError, dirtySinceLastSubmit, touched, error, pristine, submitFailed } = meta

  const leftWithError = error && touched && !active && !pristine

  const failedInSubmission = submitFailed && touched && !!error

  let hasError = leftWithError || failedInSubmission

  if (submitError) {
    hasError = !dirtySinceLastSubmit
  }

  if (hasError) {
    return error || submitError
  }
}

export default getError
