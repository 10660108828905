import gql from 'graphql-tag'

const createPriceQuotationMutation = gql`
  mutation createPriceQuotation(
    $shippingTypeId: ID!
    $deliveryAddressId: ID
    $storeId: ID
    $documentId: ID!
    $paymentMethodId: ID!
    $comments: String
  ) {
    createPriceQuotation(
      input: {
        shippingTypeId: $shippingTypeId
        deliveryAddressId: $deliveryAddressId
        storeId: $storeId
        documentId: $documentId
        paymentMethodId: $paymentMethodId
        comments: $comments
      }
    ) {
      order {
        id
      }
    }
  }
`

export default createPriceQuotationMutation
