import gql from 'graphql-tag'

export const mainPopUpQuery = gql`
  query mainPopUp {
    getPopups {
      popups {
        main {
          id
          image
          link
        }
      }
    }
  }
`
