import gql from 'graphql-tag'

const paymentMethodsImageQuery = gql`
  query paymentMethods {
    vendor {
      id
      paymentMethods {
        id
        image
        isEnabled
      }
    }
  }
`

export default paymentMethodsImageQuery
