import React from 'react'
import { sortedIndexBy } from 'lodash'

import Label from '~/truck/label'

export function OutOfStockLabel(props) {
  const { textStyle, outOfStockLabel: CustomOutOfStockLabel } = props

  if (CustomOutOfStockLabel) {
    return <CustomOutOfStockLabel />
  }

  return (
    <Label as="p" $textStyle={textStyle}>
      Fuera de
      <br />
      stock
    </Label>
  )
}

export function priceVisibility(hasVariants) {
  if (hasVariants) {
    return 'hidden'
  }

  return 'visible'
}

export function getPriceFromTier(product) {
  const { tierPricing, price, quantity } = product

  if (!tierPricing || tierPricing.length <= 1) {
    return price
  }

  let tierIndex = Math.min(
    sortedIndexBy(tierPricing, { minimumQuantity: quantity }, tier => tier.minimumQuantity),
    tierPricing.length - 1,
  )

  if (tierPricing[tierIndex]?.minimumQuantity > quantity && tierIndex > 0) {
    tierIndex -= 1
  }

  return tierPricing[tierIndex].price
}

export const unitTooltipDuration = [500, 0] as [number, number]
