import * as React from 'react'
import { filter } from 'lodash'
import { useFeature, useFlag } from 'toggled'

import Icon from '~/truck/icon'
import { checkoutSteps, flags } from '~/utils/constants'
import omitPurchaseOrder from '~/utils/omit-purchase-order'

import useStrings from './use-strings'

interface Tab {
  label: React.ReactNode
  step: checkoutSteps
}

const getTabs = (strings): Tab[] => {
  return [
    {
      label: <Icon type="gift" />,
      step: checkoutSteps.CUSTOMIZATION,
    },
    {
      label: 'Envio',
      step: checkoutSteps.SHIPPING,
    },
    {
      label: <Icon type="ticket" />,
      step: checkoutSteps.PURCHASE_ORDER,
    },
    {
      label: strings.getString('invoicing.label'),
      step: checkoutSteps.INVOICING,
    },
    {
      label: 'Pago',
      step: checkoutSteps.PAYMENT,
    },
  ]
}

const useCheckoutTabs = (checkoutData: Record<string, unknown>) => {
  const hasCustomizeOrder = useFlag(flags.CUSTOMIZE_ORDER)

  const uploadPurchaseOrderFF = useFeature(flags.UPLOAD_PURCHASE_ORDER)

  const strings = useStrings()

  const checkFlags = (tab: Tab) => {
    switch (tab.step) {
      case checkoutSteps.CUSTOMIZATION: {
        return hasCustomizeOrder
      }
      case checkoutSteps.PURCHASE_ORDER: {
        return !!uploadPurchaseOrderFF && !omitPurchaseOrder(checkoutData, uploadPurchaseOrderFF)
      }
      default: {
        return true
      }
    }
  }

  return React.useMemo(() => {
    const tabs = getTabs(strings)

    return filter(tabs, checkFlags)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useCheckoutTabs
