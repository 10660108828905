import * as React from 'react'

import Overlay from '~/truck/overlay'

import Canvas from './canvas'
import Header from './header'
import Provider from './provider'
import Standalone from './standalone'

interface ModalProps {
  children: React.ReactNode
  style?: React.CSSProperties
  blockScroll?: boolean
  className?: string
  onClose?: () => void
}

const Modal = (props: ModalProps) => {
  const { children, style, blockScroll = true, className, onClose } = props

  return (
    <Overlay open blocking={blockScroll} onClick={onClose}>
      <Standalone style={style} className={className}>
        {children}
      </Standalone>
    </Overlay>
  )
}

Modal.Standalone = Standalone
Modal.Header = Header
Modal.Provider = Provider
Modal.Canvas = Canvas

export default Modal
