import React from 'react'
import { map } from 'lodash'

import { Container, StyledStore } from './elements'

interface StoresProps {
  style?: React.CSSProperties
  className?: string
  dataSource: any[]
  onItemClick: (store: any) => void
  value: string
}

function Stores(props: StoresProps) {
  const { style, className, dataSource, onItemClick, value } = props

  const renderStore = store => {
    return <StyledStore key={store.id} onClick={() => onItemClick(store)} selected={value === store.id} {...store} />
  }

  return (
    <Container style={style} className={className}>
      {map(dataSource, renderStore)}
    </Container>
  )
}

export default Stores
