import styled from 'styled-components'

import Button from '~/truck/button'
import theme from '~/truck/theme'
import { getTextStyle } from '~/truck/utils'

export const StyledContainer = styled.div<{ selectable: boolean }>`
  background-color: ${props => (props.selectable ? 'white' : theme.colors.grayLight)};
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.comfortable}px;
  row-gap: ${theme.spacing.cozy}px;
`

export const StyledRow = styled.div`
  align-items: center;
  column-gap: ${theme.spacing.cozy * 4}px;
  display: flex;
  justify-content: flex-start;
`
export const StyledTitleDescription = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing.compact}px;
`

export const StyledPropertiesRow = styled.div<{ selectable: boolean }>`
  display: flex;
  ${props => props.selectable && `margin-left: ${theme.spacing.cozy * 4}px;`}
`
export const StyledButton = styled(Button.Transparent)`
  ${getTextStyle('h6Regular')}
  color: ${props => props.theme.colors.info};
  height: auto;
  padding: 4px 0px;
  white-space: nowrap;
  width: fit-content;
  > svg {
    fill: ${props => props.theme.colors.info};
  }
`
