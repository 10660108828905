import * as React from 'react'
import { usePopper } from 'react-popper'
import currency from 'currency.js'
import styled from 'styled-components'

import { useCurrencyConfig, useIsMobile } from '~/hooks'
import Icon from '~/truck/icon'
import Label from '~/truck/label'
import { getPriceDiscountPercentage } from '~/utils'
import { stack } from '~/variables'

import DiscountLabel from '../discount-label'

interface TierPrice {
  minimumQuantity: number
  price: string
  oldPrice: string
}

interface Props {
  tierPricing: TierPrice[]
}

const StyledLabel = styled(Label)`
  cursor: pointer;
`

const PopperContainer = styled.div`
  background: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.spacing.compact}px;
  box-shadow:
    0px 6px 10px rgba(0, 0, 0, 0.14),
    0px 1px 18px rgba(0, 0, 0, 0.12),
    0px 3px 5px rgba(0, 0, 0, 0.2);
  padding: ${props => props.theme.spacing.cozy * 3}px;
  width: ${props => props.theme.spacing.cozy * 40}px;
  z-index: ${stack.tieredPricingPopper};
`

const StyledCloseIcon = styled(Icon)``

const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.spacing.cozy * 2}px;
`

const StyledLabelContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  :not(:last-child) {
    margin-bottom: ${props => props.theme.spacing.cozy * 1.5}px;
  }
`

function TierWordingLabel(props: { tier: TierPrice; basePrice: string }) {
  const { tier, basePrice } = props

  const { minimumQuantity, price } = tier

  const currencyConfig = useCurrencyConfig()

  const formattedPrice = currency(Number(price), currencyConfig).format()

  const formattedMinQuantity = Math.max(minimumQuantity, 1)

  const discountPercentage = getPriceDiscountPercentage(basePrice, price)

  return (
    <StyledLabelContainer>
      <Label as="span" $textStyle="h6Regular">
        A partir de <strong>{formattedMinQuantity}</strong> paga <strong>{formattedPrice}</strong>
      </Label>
      {discountPercentage && <DiscountLabel value={discountPercentage} />}
    </StyledLabelContainer>
  )
}

function TierPricing(props: Props) {
  const { tierPricing } = props

  const [triggerElement, setTriggerElement] = React.useState<HTMLSpanElement | null>(null)

  const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null)

  const [isPopperVisible, setIsPopperVisible] = React.useState(false)

  const isMobile = useIsMobile()

  const basePrice = tierPricing[0]

  const { styles, attributes } = usePopper(triggerElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [isMobile ? 0 : -16, 8],
        },
      },
    ],
    placement: 'bottom-start',
    strategy: 'fixed', // to make it work with parent with overflow: hidden
  })

  const onClose = React.useCallback(() => setIsPopperVisible(false), [])

  const onToggle = React.useCallback(() => setIsPopperVisible(prevState => !prevState), [])

  React.useEffect(() => {
    if (!triggerElement || !popperElement) return

    const onClickOutside: EventListener = event => {
      const target = event.composedPath?.()?.[0] || event.target

      if (target instanceof Node) {
        if (!popperElement.contains(target) && !triggerElement.contains(target)) {
          onClose()
        }
      }
    }

    document.addEventListener('mousedown', onClickOutside)

    return () => {
      document.removeEventListener('mousedown', onClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerElement, popperElement])

  return (
    <>
      <StyledLabel as="span" $color="primary40" $textStyle="h6Regular" onClick={onToggle} ref={setTriggerElement}>
        Compra más, paga menos
      </StyledLabel>
      {isPopperVisible && (
        <PopperContainer ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          <Header>
            <Label as="span" $textStyle="h5Semibold">
              Compra más, paga menos
            </Label>
            <StyledCloseIcon type="close" color="grayscale60" size="sm" onClick={onClose} />
          </Header>
          {tierPricing.map((tier, index) => (
            <TierWordingLabel key={index} tier={tier} basePrice={basePrice.oldPrice ?? basePrice.price} />
          ))}
        </PopperContainer>
      )}
    </>
  )
}

export default TierPricing
