import React from 'react'

import useStrings from '~/hooks/use-strings'
import Document from '~/truck/document'
import Label from '~/truck/label'
import type { IDocument } from '~/types/invoicing'

import Heading from '../heading'
import Panel from '../panel'

import { InnerContainer } from './elements'

const Content = props => {
  const {
    order: { document },
  } = props

  if (!document) {
    return null
  }

  const { documentType } = document as IDocument

  return (
    <>
      <Heading iconName={documentType.slug} title={documentType.name} />
      <InnerContainer>
        <Label $textStyle="h6Semibold">Datos de facturación</Label>
        <Document {...document} />
      </InnerContainer>
    </>
  )
}

const Invoicing = props => {
  const { className, style, isEditable, onEditClick, order } = props

  const { getString } = useStrings()

  return (
    <Panel
      title={getString('invoicing.options.title')}
      className={className}
      style={style}
      isEditable={isEditable}
      onEditClick={onEditClick}
    >
      <Content order={order} />
    </Panel>
  )
}

export default Invoicing
