import React from 'react'
import styled from 'styled-components'

import RatingStatus from '~/components/rating-status'
import theme from '~/truck/theme'

type Props = {
  rating?: number
}

const StyledRatingStatus = styled(RatingStatus)`
  > div {
    height: ${theme.spacing.cozy * 2.5}px;
  }
`

const OrderReviewRating = (props: Props) => {
  const { rating } = props

  return <StyledRatingStatus rating={rating} />
}

export default OrderReviewRating
