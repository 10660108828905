import styled from 'styled-components'

import Button from '~/truck/button'
import { stack } from '~/variables'

export const StyledSliderContainer = styled.div`
  position: relative;
`

export const StyledSliderViewport = styled.div`
  overflow: hidden;
`

export const StyledSliderItemsContainer = styled.div<{ slidesToScroll: number; spacing: number }>`
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  display: flex;
  flex-direction: row;
  height: auto;
  user-select: none;

  & > div {
    flex: 0 0 calc(${({ slidesToScroll, spacing }) => `${100 / slidesToScroll}% - ${spacing}px`});
    min-width: 0;
  }

  & > div:not(:first-child) {
    margin-left: ${({ spacing }) => spacing}px;
  }
`

export const SliderArrow = styled(Button.Circular)`
  filter: drop-shadow(0px 0px 0px rgba(63, 63, 68, 0.05)) drop-shadow(0px 1px 3px rgba(63, 63, 68, 0.15));
  margin: 0 ${props => props.theme.spacing.cozy}px;
  position: absolute;
  top: 48%;
  transform: translateY(-58%);
  z-index: ${stack.arrowSlider};

  &:hover {
    background: ${props => props.theme.colors.white};
    filter: drop-shadow(0px 2px 2px rgba(63, 63, 68, 0.2)) drop-shadow(0px 1px 3px rgba(63, 63, 68, 0.15));

    > svg {
      fill: ${props => props.theme.colors.secondary};
    }
  }
`
