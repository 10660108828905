import React from 'react'
import styled from 'styled-components'

import Price from '~/components/price'
import Label from '~/truck/label'
import theme from '~/truck/theme'

const { colors } = theme

const SecondaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > :last-child {
    flex: 0 0 auto;
    margin-left: ${theme.spacing.cozy}px;
  }
`

const Container = styled.div<{ $highlighted: boolean }>`
  background-color: ${props => (props.$highlighted ? colors.gray : colors.white)};
  padding: ${theme.spacing.cozy}px ${theme.spacing.comfortable}px;
  width: 100%;

  > ${SecondaryContainer} {
    margin-top: ${theme.spacing.compact}px;
  }
`

const SearchItem = props => {
  const { item, _index, ...rest } = props

  return (
    <Container {...rest}>
      <Label as="p" $ellipsis>
        {item.name}
      </Label>
      <SecondaryContainer>
        <Label as="p" $ellipsis $textStyle="h6Regular">
          {item.presentation}
        </Label>
        <Price textStyle="h6Semibold" value={+item.pricing.price} />
      </SecondaryContainer>
    </Container>
  )
}

export default SearchItem
