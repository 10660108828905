import React, { useContext, useEffect, useRef } from 'react'
import { useForm } from 'react-final-form'
import { values } from 'lodash'
import styled from 'styled-components'

import { GoogleMapsContext } from '~/contexts'
import useGeocoding from '~/hooks/use-geocoding'
import useVendor from '~/hooks/use-vendor'
import Image from '~/truck/image'
import theme from '~/truck/theme'
import { cloudinary } from '~/utils'
import { stack } from '~/variables'

const Container = styled.div`
  overflow: hidden;
  position: relative;

  > div:last-of-type {
    height: ${theme.spacing.cozy * 34}px;
  }
`

const StyledImage = styled(Image)`
  height: ${theme.spacing.cozy * 5}px;
  left: calc(50% - ${theme.spacing.cozy * 2.5}px);
  position: absolute;
  top: calc(50% - ${theme.spacing.cozy * 5}px);
  width: ${theme.spacing.cozy * 5}px;
  z-index: ${stack.mapMarker};
`

const StaticMap = styled.iframe.attrs({ frameBorder: 0 })`
  border: 0;
  bottom: 0;
  height: ${theme.spacing.cozy * 47}px;
  margin-top: -100px;
  position: absolute;
  width: 100%;
`

const markerIconUrl = cloudinary.getFullUrl('v1590786482/sellers/resources/google-maps-marker.svg')

const Map = props => {
  const { dynamic, initialCoordinates, loadAddressComponent, style, className } = props

  const vendor = useVendor()

  const geocoding = useGeocoding()

  const mapNodeRef = useRef()

  const form = useForm()

  const { loadMap, setMapInstance } = useContext(GoogleMapsContext)

  useEffect(() => {
    return () => setMapInstance(null)
  }, [])

  const onDragEnd = async (lat, lng) => {
    if (geocoding) {
      const { address, zipCode, addressComponents } = await geocoding(lat, lng)

      if (address) {
        form.batch(() => {
          form.change('addressLine', address)

          form.change('addressId', null)

          form.change('addressComponents', addressComponents)

          if (zipCode) {
            form.change('zipCode', zipCode)
          }
        })
      }
    }
  }

  useEffect(() => {
    if (dynamic) {
      loadMap(mapNodeRef.current, initialCoordinates, onDragEnd)
    }

    if (!dynamic && initialCoordinates && loadAddressComponent) {
      loadMap(mapNodeRef.current, initialCoordinates).then(() => {
        const { lat, lng } = initialCoordinates

        onDragEnd(lat, lng)
      })
    }
  }, [])

  if (!dynamic && !initialCoordinates) {
    return null
  }

  const renderMap = () => {
    if (dynamic) {
      return <StyledImage src={markerIconUrl} alt="marker" />
    }

    const centerCoordinates = values(initialCoordinates).join()

    const src = `https://www.google.com/maps/embed/v1/place?key=${vendor.googleMapsApiKey}&q=${centerCoordinates}&zoom=15`

    return (
      <div>
        <StaticMap src={src} />
      </div>
    )
  }

  return (
    <Container style={style} className={className}>
      <div id="map" ref={mapNodeRef} />
      {renderMap()}
    </Container>
  )
}

export default Map
