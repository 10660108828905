import React from 'react'

import Field from '~/truck/field'
import Label from '~/truck/label'

import { Adornment, Container, StyledField } from './elements'

const TextField = props => {
  const { name, label, placeholder, renderAdornment, className, style, ...rest } = props

  const FieldComponent = renderAdornment ? StyledField : Field

  return (
    <Container className={className} style={style}>
      <Label htmlFor={name} $textStyle="h6Regular">
        {label}
      </Label>
      {renderAdornment && <Adornment>{renderAdornment}</Adornment>}
      <FieldComponent id={name} name={name} placeholder={placeholder} hasAdornment={!!renderAdornment} {...rest} />
    </Container>
  )
}

export default TextField
