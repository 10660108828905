import { replace } from 'lodash'

export function whatsappUrl(phone?: string) {
  if (!phone) {
    return null
  }

  const text = 'Hola, me gustaría contactarme con ustedes. Muchas gracias.'

  const sanitizedPhone = replace(phone, /[+()-\s]/g, '')

  return `https://wa.me/${sanitizedPhone}?text=${text}`
}

export function emailUrl(email?: string) {
  return email && `mailto:${email}`
}

export function phoneUrl(phone?: string) {
  return phone && `tel:${phone}`
}

export function googleMapUrl(apiKey: string) {
  const baseUrl = 'https://maps.googleapis.com/maps/api/js'

  return `${baseUrl}?key=${apiKey}&v=3.exp&libraries=geometry`
}
