import * as React from 'react'
import clsx from 'clsx'
import { map } from 'lodash'
import Link from 'next/link'
import { useRouter } from 'next/router'

import Icon from '~/truck/icon'
import type { ICategory } from '~/types/category'

interface TreeProps {
  className?: string
  dataSource: ICategory[]
  setHoveredCategory: (category: ICategory) => void
  hoveredCategory?: ICategory
}

function Categories({ dataSource, className, hoveredCategory, setHoveredCategory }: TreeProps) {
  const router = useRouter()

  return (
    <div className={clsx('flex flex-col overflow-y-auto bg-white px-3 py-2', className)}>
      {map(dataSource, (category, index) => {
        const { encodedId, name, slug, isFeatured } = category

        const path = `${slug}-${encodedId}`

        const isHovered = hoveredCategory?.id === category.id

        const isSelected = router.query.category === path

        return (
          <Link href={`/c/${path}`} passHref key={encodedId}>
            <a
              className={clsx(
                'flex shrink-0 items-center justify-between gap-1 rounded px-4 py-2 text-sm',
                isFeatured && 'text-critical',
                isSelected && 'font-semibold',
                isHovered && 'bg-neutral-200',
              )}
              onMouseEnter={() => setHoveredCategory(category)}
            >
              <span className="overflow-hidden text-ellipsis whitespace-nowrap">{name}</span>
              <Icon className={clsx(isHovered ? 'visible' : 'invisible')} type="caret-right" size="sm" color="black" />
            </a>
          </Link>
        )
      })}
    </div>
  )
}

export default Categories
